import { Classes } from '@blueprintjs/core';
import styled from '@emotion/styled';
import fill from 'lodash/fill';

import { Box, Flex } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import { CARD_MARGIN } from '@/components/pieces/learn/constants';
import { useResponsiveGridSize } from '@/components/pieces/learn/useResponsiveGridSize';
import { LIGHTEST_BACKGROUND } from '@/css/constants';

const GridContainer = styled.div<{ cardsPerRow: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.cardsPerRow}, 1fr);
  gap: ${CARD_MARGIN * 2}px;
  margin: 0 0 48px 0;
  width: 100%;
  max-width: 100%;

  @media (max-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
`;

const GridItem = styled.div`
  min-width: 0; /* Prevents grid items from overflowing */
  height: 100%;
`;

function ArticleListSkeleton({ num = 4 }) {
  const cardsPerRow = useResponsiveGridSize();
  const arr = fill(Array(num), 1).map((x, y) => x + y);
  if (!num) {
    return null;
  }

  return (
    <GridContainer cardsPerRow={cardsPerRow}>
      {arr.map((i) => (
        <GridItem key={i}>
          <Box height={264} className={Classes.SKELETON} />
        </GridItem>
      ))}
    </GridContainer>
  );
}

export function GroupViewSkeleton({ num = 4 }) {
  return (
    <Box width={1}>
      <Flex justifyContent='space-between' mb={3}>
        <Box width={100} height={24} className={Classes.SKELETON} />
        <Box width={100} height={24} className={Classes.SKELETON} />
      </Flex>
      <ArticleListSkeleton num={num} />
    </Box>
  );
}

function LearnBoardSkeleton() {
  return (
    <Box width={1} className='content-page'>
      <Flex width={1} justifyContent='center'>
        <Box mb={4} width='100%' maxWidth={1204} bg={LIGHTEST_BACKGROUND}>
          <Box my={4} width={200} height={24} className={Classes.SKELETON} />
          <ArticleListSkeleton />
          <Box width={100} my={4} height={24} className={Classes.SKELETON} />
          <GroupViewSkeleton />
          <Box width={100} my={4} height={24} className={Classes.SKELETON} />
          <GroupViewSkeleton />
          <Box width={100} my={4} height={24} className={Classes.SKELETON} />
          <GroupViewSkeleton num={8} />
        </Box>
      </Flex>
    </Box>
  );
}

export default function LearnBoardSkeletonContainer() {
  return <LearnBoardSkeleton />;
}
