import React from 'react';

import { Button, Intent, Menu, MenuItem, Position } from '@blueprintjs/core';

import { Popover } from '@/components/helpers/ui/blueprint-overrides/Popover';
import { Box } from '@/components/layout/flexbox';

export default function RoleplayMenu({
  roleplay,
  onEdit,
  onDelete,
  canEdit = false,
  onClone,
}) {
  if (!roleplay) {
    return null;
  }

  const baseMenuItems = [
    {
      text: 'Clone Collection',
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        onClone();
      },
    },
  ];

  const editableMenuItems = [
    {
      text: 'Edit Collection',
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        onEdit();
      },
    },
    ...baseMenuItems,
    {
      text: 'Delete Collection',
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        onDelete();
      },
      intent: Intent.DANGER,
    },
  ];

  const menuItems = (canEdit ? editableMenuItems : baseMenuItems).map(
    (item, index) => <MenuItem key={index} {...item} />,
  );

  return (
    <Box style={{ zIndex: 9999, marginLeft: 5 }}>
      <Popover
        canEscapeKeyClose={true}
        position={Position.BOTTOM_RIGHT}
        onClose={() => {}}
        content={<Menu>{menuItems}</Menu>}
      >
        <Button
          style={{ minHeight: 36 }}
          minimal={true}
          icon='more'
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      </Popover>
    </Box>
  );
}
