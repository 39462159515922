import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { CurrentSubscriptionDocument, Entitlement } from '@/graphql';

export function useSubscription() {
  const { data, loading, error, refetch } = useQuery(
    CurrentSubscriptionDocument,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const subscriptionData = useMemo(() => {
    if (!data || !data.currentSubscription) {
      return null;
    }

    const { currentSubscription } = data;
    const remainingRoleplaySessions =
      calculateRemainingRoleplaySessions(currentSubscription);
    return {
      ...currentSubscription,
      remainingRoleplaySessions: remainingRoleplaySessions,
      allowRoleplaySession: remainingRoleplaySessions !== 0,
      allowCustomRoleplays: hasEntitlement(
        currentSubscription,
        Entitlement.CustomRoleplays,
      ),
    };
  }, [data]);

  return {
    subscriptionData,
    loading,
    error,
    refetch,
  };
}

function calculateRemainingRoleplaySessions(subscription) {
  for (const entitlement of subscription.entitlements) {
    if (entitlement.entitlement === Entitlement.UnlimitedRoleplaySessions) {
      return -1;
    }
    if (entitlement.entitlement === Entitlement.RoleplaySessions) {
      return entitlement.maxUsageValue - entitlement.usageCount;
    }
  }
  return 0;
}

function hasEntitlement(subscription, entitlement: Entitlement) {
  // first, get the entitlements from the subscription
  if (!subscription || !subscription.entitlements) {
    return false;
  }
  const subEntitlement = subscription.entitlements.find(
    (e) => e.entitlement === entitlement,
  );
  if (!subEntitlement) return false;
  if (subEntitlement.booleanValue === true) return true;
  if (subEntitlement.booleanValue === false) return false;
  if (subEntitlement.maxUsageValue === 0) return false;

  if (subEntitlement.usageCount === null) return true;
  if (subEntitlement.usageCount >= subEntitlement.maxUsageValue) return false;
  return true;
}
