import { useState } from 'react';

import { Icon, PopoverInteractionKind, Position } from '@blueprintjs/core';
import { Link, useLocation } from 'react-router-dom';

import { Popover } from '@/components/helpers/ui/blueprint-overrides/Popover';
import { Box } from '@/components/layout/flexbox';
import { Media } from '@/components/layout/Media';
import { LIGHTEST_BACKGROUND, MIDTEXT_COLOR } from '@/css/constants';

import { LinkType } from './PublicNav/types';

interface SubMenuViewListProps {
  label: string;
  subMenus: LinkType[];
  onSubMenusVisible?: () => void;
  path?: string;
}

export default function SubMenuViewList({
  label,
  subMenus,
  onSubMenusVisible,
  path,
}: SubMenuViewListProps) {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const isActivePage =
    subMenus.filter((m) => location.pathname.startsWith(m.path)).length > 0;

  const subMenuView = subMenus.map((subMenu, index) => {
    const { title, description, path, newWindow, href, hidden } = subMenu;
    const targetProp = newWindow ? '_blank' : null;
    const relProp = newWindow ? 'noopener noreferrer' : undefined;
    const isActiveChildPage = location.pathname.startsWith(path);

    if (hidden || !(path || href)) {
      return null;
    }

    if (path) {
      return (
        <Link
          to={path}
          target={targetProp}
          rel={relProp}
          className={`nav-link-sub-menu-item ${
            isActiveChildPage && 'active-child-page'
          }`}
          key={index}
        >
          <b className='nav-link-sub-menu-title'>{title}</b>
          <div className='nav-link-sub-menu-description'>{description}</div>
        </Link>
      );
    }

    if (href) {
      return (
        <a
          href={href}
          target={targetProp}
          rel={relProp}
          className={`nav-link-sub-menu-item ${
            isActiveChildPage && 'active-child-page'
          }`}
          key={index}
        >
          <b className='nav-link-sub-menu-title'>{title}</b>
          <div className='nav-link-sub-menu-description'>{description}</div>
        </a>
      );
    }
    return null;
  });

  const popoverContent = (
    <div
      className={IS_EXEC ? 'exec-page' : ''}
      style={{
        width: 345,
        padding: '20px 15px',
        background: LIGHTEST_BACKGROUND,
        color: MIDTEXT_COLOR,
        border: 'border: 1px solid #F4F1FF',
        borderRadius: 6,
      }}
    >
      <h4 className='nav-link-sub-menu-header'>{label}</h4>
      {subMenuView}
    </div>
  );

  return (
    <li className={isActivePage ? 'active-page' : null}>
      <Media lessThan='md'>
        <hr />
        <Box fontWeight={500} fontSize='1.2em' px={15} py={10}>
          {label}
        </Box>
        {subMenus.map((subMenu, index) => {
          const { title, path, newWindow, href } = subMenu;
          const targetProp = newWindow ? '_blank' : null;
          const relProp = newWindow ? 'noopener noreferrer' : undefined;
          if (path) {
            return (
              <Link
                to={path}
                className='nav-link-sub-menu-item-mobile'
                target={targetProp}
                rel={relProp}
                key={index}
              >
                {title}
              </Link>
            );
          }
          if (href) {
            return (
              <a
                className='nav-link-sub-menu-item-mobile'
                href={href}
                target={targetProp}
                rel={relProp}
                key={index}
              >
                {title}
              </a>
            );
          }
          return null;
        })}
      </Media>
      <Media greaterThan='sm'>
        <a
          className='nav-link-sub-menu'
          onMouseEnter={() => {
            if (!menuOpen) {
              setMenuOpen(true);
              if (onSubMenusVisible) {
                onSubMenusVisible();
              }
            }
          }}
          onMouseLeave={() => {
            if (menuOpen) {
              setMenuOpen(false);
            }
          }}
        >
          <Popover
            content={popoverContent}
            interactionKind={PopoverInteractionKind.CLICK}
            isOpen={menuOpen}
            position={Position.BOTTOM}
            minimal={true}
            modifiers={{
              arrow: { enabled: false },
              flip: { enabled: false },
            }}
          >
            <span className='nav-text'>
              {path ? (
                <Link to={path}>
                  {label} <Icon icon='chevron-down' />
                </Link>
              ) : (
                <>
                  {label}
                  <Icon icon='chevron-down' />
                </>
              )}
            </span>
          </Popover>
        </a>
      </Media>
    </li>
  );
}
