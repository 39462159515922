const allRoutes = [
  // public pages
  {
    name: 'PermissionError',
    path: '/403',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/403'
      );
      return { Component };
    },
  },
  {
    name: 'LogIn',
    path: '/login',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/login'
      );
      return { Component };
    },
  },
  {
    name: 'SSO',
    path: '/sso',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/sso'
      );
      return { Component };
    },
  },
  {
    name: 'About',
    path: '/about-us',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/about'
      );
      return { Component };
    },
  },
  {
    name: 'EnterprisePrivacy',
    path: '/enterprise-privacy',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/enterprise-privacy'
      );
      return { Component };
    },
  },
  {
    name: 'Demo',
    path: '/demo',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/demo'
      );
      return { Component };
    },
  },
  {
    name: 'Redeem',
    path: '/redeem',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/redeem'
      );
      return { Component };
    },
  },
  {
    name: 'Pro',
    path: '/pro',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/home'
      );
      return { Component };
    },
  },
  {
    name: 'LPCoaching',
    path: '/career-coaching',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/lp-coaching'
      );
      return { Component };
    },
  },
  {
    name: 'LPCoaching',
    path: '/life-coaching',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/lp-coaching'
      );
      return { Component };
    },
  },
  {
    name: 'LPCoaching',
    path: '/leadership-coaching',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/lp-coaching'
      );
      return { Component };
    },
  },
  {
    name: 'LPCoaching',
    path: '/job-search-coaching',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/lp-coaching'
      );
      return { Component };
    },
  },
  {
    name: 'LPCoaching',
    path: '/b2b-leadership',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/lp-coaching'
      );
      return { Component };
    },
  },
  {
    name: 'CoachProfile',
    path: '/coaches/:coachSlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/coach'
      );
      return { Component };
    },
  },
  {
    name: 'CoachProfile',
    path: '/:coachSlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/coach'
      );
      return { Component };
    },
  },
  {
    name: 'CoachSpecialtyProfile',
    path: '/coaches/:coachSlug/:specialtySlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/coach'
      );
      return { Component };
    },
  },
  {
    name: 'CoachSpecialtyProfile',
    path: '/:coachSlug/:specialtySlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/coach'
      );
      return { Component };
    },
  },

  {
    name: 'ExploreDetail',
    path: '/explore/:productSlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/explore/detail'
      );
      return { Component };
    },
  },
  {
    name: 'ExploreDetail',
    path: '/explore/:productSlug/:coachSlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/explore/detail'
      );
      return { Component };
    },
  },

  {
    name: 'LPReviews',
    path: '/asset-reviews',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/lp-reviews'
      );
      return { Component };
    },
  },
  {
    name: 'Faq',
    path: '/faq',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/faq'
      );
      return { Component };
    },
  },
  {
    name: 'SignUp',
    path: '/signup',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/signup'
      );
      return { Component };
    },
  },
  {
    name: 'ForgotPassword',
    path: '/reset-password',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/forgot-password'
      );
      return { Component };
    },
  },
  {
    name: 'ForgotPassword',
    path: '/forgot-password',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/forgot-password'
      );
      return { Component };
    },
  },
  {
    name: 'ResetPassword',
    path: '/reset-password/:refreshToken',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/reset-password'
      );
      return { Component };
    },
  },
  {
    name: 'ActivateMembership',
    path: '/activate-membership/:refreshToken',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/activate-membership'
      );
      return { Component };
    },
  },
  {
    name: 'PrivacyPolicy',
    path: '/privacy-policy',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/privacy-policy'
      );
      return { Component };
    },
  },
  {
    name: 'Tos',
    path: '/tos',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/tos'
      );
      return { Component };
    },
  },
  {
    name: 'EnterpriseTos',
    path: '/enterprise-tos',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/enterprise-tos'
      );
      return { Component };
    },
  },
  {
    name: 'SalesCompCalculator',
    path: '/sales-comp',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/sales-comp'
      );
      return { Component };
    },
  },
  {
    name: 'LandingPageVariant',
    path: '/lp/:slug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/landing-page-variant'
      );
      return { Component };
    },
  },

  {
    name: 'Learn',
    path: '/learn',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/learn'
      );
      return { Component };
    },
  },
  {
    name: 'LearnSeries',
    path: '/learn/series/:slug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/article_series'
      );
      return { Component };
    },
  },
  {
    name: 'LearnOtherArticles',
    path: '/learn/other-articles',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/article_others'
      );
      return { Component };
    },
  },
  {
    name: 'LearnArticle',
    path: '/learn/:slug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/article'
      );
      return { Component };
    },
  },

  // authorized pages
  {
    name: 'SetPassword',
    path: '/set-password',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/set-password'
      );
      return { Component };
    },
  },
  {
    name: 'App',
    path: '/app',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/app'
      );
      return { Component };
    },
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/dashboard'
      );
      return { Component };
    },
  },
  {
    name: 'ToDo',
    path: '/todo',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/todo'
      );
      return { Component };
    },
  },
  {
    name: 'Billing',
    path: '/app/billing',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/app/billing'
      );
      return { Component };
    },
  },
  {
    name: 'Referrals',
    path: '/referrals',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/app/referrals'
      );
      return { Component };
    },
  },
  {
    name: 'BuildPackage',
    path: '/build',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/build-package'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceAnalytics',
    path: '/analytics',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace-admin/WorkspaceAnalytics'
      );
      return { Component };
    },
  },
  {
    name: 'Checkout',
    path: '/checkout/:id',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/checkout'
      );
      return { Component };
    },
  },
  {
    name: 'CheckoutNextSteps',
    path: '/checkout/:id/next-steps',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/checkout/next-steps'
      );
      return { Component };
    },
  },
  {
    name: 'CreateOrder',
    path: '/create-order/:coachId/:productId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/checkout/create-order'
      );
      return { Component };
    },
  },
  {
    name: 'BuyCredits',
    path: '/buy',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/buy-credits'
      );
      return { Component };
    },
  },
  {
    name: 'BuyCreditsSuccess',
    path: '/buy/success/:id',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/buy-credits/success'
      );
      return { Component };
    },
  },
  {
    name: 'BuyCreditsProduct',
    path: '/buy/:slug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/buy-credits'
      );
      return { Component };
    },
  },
  {
    name: 'TransferCredits',
    path: '/transfer',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/transfer-credits'
      );
      return { Component };
    },
  },
  {
    name: 'Schedule',
    path: '/schedule',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/schedule'
      );
      return { Component };
    },
  },
  {
    name: 'Dashboard',
    path: '/coaching',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/dashboard'
      );
      return { Component };
    },
  },
  {
    name: 'Payouts',
    path: '/payouts',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/payouts'
      );
      return { Component };
    },
  },
  {
    name: 'Availability',
    path: '/availability',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/availability'
      );
      return { Component };
    },
  },
  {
    name: 'Services',
    path: '/services',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/services'
      );
      return { Component };
    },
  },
  {
    name: 'ServiceDetail',
    path: '/services/:productSlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/services/detail'
      );
      return { Component };
    },
  },
  {
    name: 'Sessions',
    path: '/sessions',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/sessions'
      );
      return { Component };
    },
  },
  {
    name: 'Session',
    path: '/sessions/:meetingId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/session'
      );
      return { Component };
    },
  },
  {
    name: 'Book',
    path: '/book',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/book'
      );
      return { Component };
    },
  },
  {
    name: 'Reviews',
    path: '/reviews',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/reviews'
      );
      return { Component };
    },
  },
  {
    name: 'Review',
    path: '/reviews/:reviewId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/review'
      );
      return { Component };
    },
  },
  {
    name: 'BookReviews',
    path: '/book-reviews',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/book-reviews'
      );
      return { Component };
    },
  },
  {
    name: 'Rate',
    path: '/rate',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/rate'
      );
      return { Component };
    },
  },
  {
    name: 'RateEdit',
    path: '/rate/:ratingId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/rate'
      );
      return { Component };
    },
  },
  {
    name: 'Reschedule',
    path: '/sessions/:meetingId/reschedule',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/reschedule'
      );
      return { Component };
    },
  },
  {
    name: 'AssignedScenarios',
    path: '/roleplays/assigned',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/scenarios/AssignedScenarios'
      );
      return { Component };
    },
  },
  {
    name: 'PersonalScenarios',
    path: '/roleplays/personal',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/scenarios/PersonalScenarios'
      );
      return { Component };
    },
  },
  {
    name: 'Roleplays',
    path: '/roleplays',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays'
      );
      return { Component };
    },
  },
  {
    name: 'RoleplayDetail',
    path: '/roleplays/:slug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/details'
      );
      return { Component };
    },
  },
  {
    name: 'RoleplayDetail',
    path: '/roleplays/create',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/details'
      );
      return { Component };
    },
  },
  {
    name: 'ScenarioCreate',
    path: '/roleplays/scenarios/create',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/details/create'
      );
      return { Component };
    },
  },
  {
    name: 'ScenarioCreate',
    path: '/roleplays/:slug/create',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/details/create'
      );
      return { Component };
    },
  },
  {
    name: 'RoleplaySettings',
    path: '/roleplays/:slug/settings',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/details/settings'
      );
      return { Component };
    },
  },
  {
    name: 'IntakeProcessing',
    path: '/roleplays/intake/:intakeUuid',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/intake'
      );
      return { Component };
    },
  },
  {
    name: 'ScenarioDetail',
    path: '/roleplays/:roleplaySlug/:scenarioSlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/details/scenario'
      );
      return { Component };
    },
  },
  {
    name: 'ScenarioDetail',
    path: '/scenarios/:scenarioSlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/details/scenario'
      );
      return { Component };
    },
  },
  {
    name: 'RoleplaySessionDetail',
    path: '/scenarios/:scenarioSlug/:sessionUuid/:view?',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/details/scenario/session'
      );
      return { Component };
    },
  },
  {
    name: 'RoleplaySessionDetail',
    path: '/roleplays/:roleplaySlug/:scenarioSlug/:sessionUuid/:view?',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/roleplays/details/scenario/session'
      );
      return { Component };
    },
  },
  {
    name: 'Onboard',
    path: '/onboard',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/onboard'
      );
      return { Component };
    },
  },
  {
    name: 'Match',
    path: '/match',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/match'
      );
      return { Component };
    },
  },
  {
    name: 'MatchSpecialty',
    path: '/match/:specialtySlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/match/specialty'
      );
      return { Component };
    },
  },
  {
    name: 'Choose',
    path: '/choose',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/choose'
      );
      return { Component };
    },
  },
  {
    name: 'Choose',
    path: '/choose/:specialtySlug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_flexible/choose'
      );
      return { Component };
    },
  },
  {
    name: 'FulfillmentForm',
    path: '/forms/:orderId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/fulfillment-forms'
      );
      return { Component };
    },
  },
  {
    name: 'TrackSubscription',
    path: '/track/subscribed/',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/subscribed'
      );
      return { Component };
    },
  },
  {
    name: 'Subscribed',
    path: '/subscribed/',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/subscribed'
      );
      return { Component };
    },
  },
  {
    name: 'SubscribeCheckout',
    path: '/subscribe/checkout',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/subscribe-checkout'
      );
      return { Component };
    },
  },

  // Workspace client pages
  {
    name: 'WorkspaceClients',
    path: '/clients',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/clients'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceMessages',
    path: '/messages',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/messages'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceClientProfile',
    path: '/client/:userId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/profile'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceClientSchedule',
    path: '/client/:userId/schedule',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/schedule'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceClientBook',
    path: '/client/:userId/book',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/book'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceClientBuyCredits',
    path: '/client/:userId/buy',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/buy-credits'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceClientBuyCreditsSuccess',
    path: '/client/:userId/buy/success/:id',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/buy-credits/success'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceClientBuyCreditsProduct',
    path: '/client/:userId/buy/:slug',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/buy-credits'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceClientSessions',
    path: '/client/:userId/sessions',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/sessions'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceClientReviews',
    path: '/client/:userId/reviews',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/reviews'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceClientMessages',
    path: '/client/:userId/messages',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/messages/client'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceClientMessagesChannel',
    path: '/client/:userId/messages/:channelId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/messages/client'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceMessagesUser',
    path: '/messages/:userId(/channels)?',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/messages'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceMessagesChannel',
    path: '/messages/:userId/channels/:channelId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/messages'
      );
      return { Component };
    },
  },

  // Workspace admin pages
  {
    name: 'WorkspaceAdmin',
    path: '/workspace/',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace-admin'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceAdmin',
    path: '/workspace/users',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace-admin'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceGroups',
    path: '/workspace/groups',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace-admin/WorkspaceGroups'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceGroup',
    path: '/workspace/groups/:groupId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace-admin/WorkspaceGroup'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceSettings',
    path: '/workspace/settings',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace-admin/WorkspaceSettings'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceBilling',
    path: '/workspace/billing',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace-admin/WorkspaceBilling'
      );
      return { Component };
    },
  },
  {
    name: 'WorkspaceDashboard',
    path: '/workspace-dashboard',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace-admin/WorkspaceDashboard'
      );
      return { Component };
    },
  },
  {
    name: 'OutlookAuthorize',
    path: '/outlook-authorize',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/outlook-authorize'
      );
      return { Component };
    },
  },

  // Assist pages
  {
    name: 'Assist',
    path: '/assist',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/assist'
      );
      return { Component };
    },
  },
  {
    name: 'AssistProfile',
    path: '/assist/:userId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/assist/profile'
      );
      return { Component };
    },
  },
  {
    name: 'AssistSessions',
    path: '/assist/:userId/sessions',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/assist/sessions'
      );
      return { Component };
    },
  },
  {
    name: 'AssistReviews',
    path: '/assist/:userId/reviews',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/assist/reviews'
      );
      return { Component };
    },
  },
  {
    name: 'AssistSchedule',
    path: '/assist/:userId/schedule',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/assist/schedule'
      );
      return { Component };
    },
  },
  {
    name: 'AssistBook',
    path: '/assist/:userId/book',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/assist/book'
      );
      return { Component };
    },
  },
  {
    name: 'AssistSession',
    path: '/assist/:userId/sessions/:meetingId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/assist/session'
      );
      return { Component };
    },
  },
  {
    name: 'AssistReview',
    path: '/assist/:userId/reviews/:reviewId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/assist/review'
      );
      return { Component };
    },
  },
  {
    name: 'AssistReschedule',
    path: '/assist/:userId/sessions/:meetingId/reschedule',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/workspace/assist/reschedule'
      );
      return { Component };
    },
  },
  {
    name: 'AssistCreateOrder',
    path: '/assist/:userId/create-order/:coachId/:productId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/checkout/create-order'
      );
      return { Component };
    },
  },
  {
    name: 'AssistCheckoutNextSteps',
    path: '/assist/:userId/checkout/:id/next-steps',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/checkout/next-steps'
      );
      return { Component };
    },
  },
  {
    name: 'Checkout',
    path: '/assist/:userId/checkout/:id',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/checkout'
      );
      return { Component };
    },
  },
  {
    name: 'AssistFulfillmentForm',
    path: '/assist/:userId/forms/:orderId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/fulfillment-forms'
      );
      return { Component };
    },
  },

  // Program pages
  {
    name: 'Program',
    path: '/programs',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/program/pages/program-list'
      );
      return { Component };
    },
  },
  {
    name: 'ProgramParticipants',
    path: '/programs/:id/participants',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/program/pages/program-participants'
      );
      return { Component };
    },
  },
  {
    name: 'ProgramDashboard',
    path: '/programs/:id',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/program/pages/program-dashboard'
      );
      return { Component };
    },
  },
  {
    name: 'ProgramDetails',
    path: '/programs/:id/:view',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/program/pages/program-details'
      );
      return { Component };
    },
  },
  {
    name: 'ProgramDetails',
    path: '/programs/:id/:view/:componentId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/program/pages/program-details'
      );
      return { Component };
    },
  },
  {
    name: 'ProgramDetails',
    path: '/programs/:id/:view/:componentId/:responseId',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/program/pages/program-details'
      );
      return { Component };
    },
  },
  {
    name: 'ProgramSurvey',
    path: '/surveys/:id',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/program/pages/program-survey'
      );
      return { Component };
    },
  },
  {
    name: 'ActivateParticipant',
    path: '/activate-participant/:programId/:refreshToken',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/program/pages/activate-participant'
      );
      return { Component };
    },
  },
];

const PLACEMENT_ONLY = [
  {
    name: 'Home',
    path: '/',
    redirectToApp: true,
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/home'
      );

      return { Component };
    },
  },
];

const EXEC_ONLY = [
  {
    name: 'ExecHome',
    path: '/',
    redirectToApp: true,
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/exec-home'
      );

      return { Component };
    },
  },
  {
    name: 'Start',
    path: '/start',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/start'
      );

      return { Component };
    },
  },
  {
    name: 'CreateWorkspace',
    path: '/create-workspace',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/create-workspace'
      );

      return { Component };
    },
  },
  {
    name: 'ScheduleDemo',
    path: '/schedule-demo',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/schedule-demo'
      );

      return { Component };
    },
  },
  {
    name: 'GetDemo',
    path: '/get-demo',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_logged_in/get-demo'
      );

      return { Component };
    },
  },
  {
    name: 'ForCoaches',
    path: '/for-coaches',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/for-coaches'
      );

      return { Component };
    },
  },
  {
    name: 'ForIndividuals',
    path: '/for-individuals',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/for-individuals'
      );

      return { Component };
    },
  },
  {
    name: 'ExecDevelopment',
    path: '/exec-development',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/exec-development'
      );

      return { Component };
    },
  },
  {
    name: 'ManagerGrowth',
    path: '/manager-growth',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/manager-growth'
      );

      return { Component };
    },
  },
  {
    name: 'HighPotentials',
    path: '/hipo-program',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/high-potentials'
      );

      return { Component };
    },
  },
  {
    name: 'Outplacement',
    path: '/outplacement',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/outplacement'
      );

      return { Component };
    },
  },
  {
    name: 'Outplacement',
    path: '/outplacement/success',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/outplacement'
      );

      return { Component };
    },
  },
  {
    name: 'Offsites',
    path: '/offsites',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/offsites'
      );

      return { Component };
    },
  },
  {
    name: 'MomsInTech',
    path: '/momsintech',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/moms-in-tech'
      );

      return { Component };
    },
  },
  {
    name: 'AIRoleplays',
    path: '/ai-roleplays',
    lazy: async () => {
      const { default: Component } = await import(
        '@/components/pages_public/role-plays'
      );

      return { Component };
    },
  },
];

const isExec =
  typeof IS_EXEC !== 'undefined' ? IS_EXEC : process.env.PRODUCT === 'exec';
const particularRoutes = isExec ? EXEC_ONLY : PLACEMENT_ONLY;

const routes = [...allRoutes, ...particularRoutes];

export default routes;
