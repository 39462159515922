import { TextArea } from '@blueprintjs/core';

import { Box } from '@/components/layout/flexbox';
import Checkbox from '@/components/pieces/form/Checkbox';

interface NotificationMessageFormProps {
  isNotified: boolean;
  message: string;
  onIsNotifiedChange: (checked: boolean) => void;
  onMessageChange: (message: string) => void;
}

export default function NotificationMessageForm({
  isNotified,
  message,
  onIsNotifiedChange,
  onMessageChange,
}: NotificationMessageFormProps) {
  return (
    <Box mt={3}>
      <Checkbox
        checked={isNotified}
        onChange={onIsNotifiedChange}
        label='Notify people'
        hasBorders={false}
        style={{ fontSize: 16 }}
      />
      {isNotified && (
        <Box mt={2}>
          <TextArea
            fill
            placeholder='Message...'
            value={message}
            onChange={(e) => onMessageChange(e.target.value)}
            rows={3}
          />
        </Box>
      )}
    </Box>
  );
}
