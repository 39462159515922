import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Classes, Collapse } from '@blueprintjs/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import styled from '@emotion/styled';
import classnames from 'classnames';
import idx from 'idx';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import moment from 'moment';
import { Link, useSearchParams } from 'react-router-dom';

import SEOHelmet from '@/components/helpers/seo/SEOHelmet';
import { Box, Flex } from '@/components/layout/flexbox';
import { breakpoints, Media } from '@/components/layout/Media';
import { TOCBox } from '@/components/pages_logged_in/workspace-admin/WorkspaceMemberTable';
import { NotFound } from '@/components/pages_public/404';
import RelatedArticlesList from '@/components/pieces/article/RelatedArticlesList';
import Mp3Player from '@/components/pieces/audio/Mp3Player';
import { Container } from '@/components/pieces/bootstrap';
import FlexibleButton from '@/components/pieces/button/FlexibleButton';
import { ArticleWithReadStatus } from '@/components/pieces/learn/ArticleCard';
import Avatar from '@/components/pieces/users/Avatar';
import {
  BP_NS,
  INNER_BORDER_COLOR,
  LIGHT_BACKGROUND,
  LIGHTEST_BACKGROUND,
  MAINTEXT_COLOR,
  SUBTEXT_COLOR,
} from '@/css/constants';
import { SSRAlgoliaResultsQuery } from '@/graphql/cachedVarsQueries';

import Callout from './Callout';
import GPTBot from './GPTBot';
import Hyperlink from './Hyperlink';
import Prompt from './Prompt';
import TableOfContents, { MIN_TOC_WIDTH } from './TableOfContents';
import VideoEmbed from './VideoEmbed';

export const CARD_IMG_HEIGHT = 365;
export const MAX_CONTENT_WIDTH = 690;

function SidebarCTA({ article }) {
  const { sidebarCta } = article || {};
  if (!sidebarCta) return null;
  const { headline, body, ctaLink, buttonText } = sidebarCta || {};
  return (
    <TOCBox mb={3} minWidth={MIN_TOC_WIDTH}>
      <Box className='header'>{headline}</Box>
      <Box mt={2} mb={3} fontSize={14}>
        {body}
      </Box>
      <FlexibleButton
        as={'a'}
        href={ctaLink}
        fill
        variant='primary'
        target='_parent'
      >
        {buttonText}
      </FlexibleButton>
      <Box className='bbs' my={4}></Box>
    </TOCBox>
  );
}

const slugify = (text) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

function Article({ article }: { article: ArticleWithReadStatus }) {
  const tableOfContents = [];

  const imgUrl = article.heroImage ? `${article.heroImage}?fm=webp` : '';
  const seoImgUrl = article.heroImage ? `https:${article.heroImage}` : '';

  const { data: ssrData } = useQuery(SSRAlgoliaResultsQuery);
  const parsedSsrData = ssrData && JSON.parse(ssrData.algoliaData);

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => {
        const nodeValue = node.content[0].value;
        const nodeId = slugify(nodeValue);
        tableOfContents.push({ id: nodeId, label: nodeValue });
        return <h2 id={nodeId}>{children}</h2>;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        const nodeValue = node.content[0].value;
        const nodeId = slugify(nodeValue);
        tableOfContents.push({ id: nodeId, label: nodeValue, nested: true });
        return <h3 id={nodeId}>{children}</h3>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = node.data.target;
        if (asset && asset.type && asset.type.startsWith('image')) {
          return (
            <img
              src={`${asset.url}?fm=webp`}
              loading='lazy'
              alt={asset.title}
            />
          );
        } else if (
          asset &&
          asset.type &&
          (asset.type.startsWith('audio/mpeg') ||
            (asset.type.startsWith('video/mp4') && asset.url.endsWith('.m4a')))
        ) {
          return (
            <Box
              my={24}
              className='bas round-corners'
              px={18}
              py={24}
              style={{ backgroundColor: '#FFF' }}
            >
              <Mp3Player src={asset.url} />
            </Box>
          );
        } else {
          return null;
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry = node.data.target;
        if (entry.type === 'callout') {
          return <Callout entry={entry} />;
        } else if (entry.type === 'prompt') {
          return <Prompt entry={entry} />;
        } else if (entry.type === 'gptBot') {
          return <GPTBot entry={entry} />;
        } else if (entry.type === 'videoEmbed') {
          return <VideoEmbed entry={entry} />;
        }
        return null;
      },
      [INLINES.HYPERLINK]: (node) => {
        return <Hyperlink node={node} />;
      },
    },
  };

  let jsonData = null;
  try {
    jsonData = JSON.parse(article.body);
  } catch (e) {
    console.error('Error parsing article body JSON', e);
  }

  const [searchParams] = useSearchParams();
  const isBareLayout = searchParams.get('layout') === 'bare';

  if (!jsonData) {
    return <NotFound />;
  }

  const articleContent = documentToReactComponents(jsonData, renderOptions);
  const canonicalDomain = article?.canonicalDomain;

  return (
    <>
      <Box
        backgroundColor='#FFF'
        className='bbs'
        mb={[3, 4]}
        pt={[3, 36]}
        pb={24}
        mt={isBareLayout ? -60 : 0}
      >
        <Container>
          <Header article={article} isFullWidth={true} />
        </Container>
      </Box>
      <Container>
        <ArticleContainer style={isBareLayout ? { marginBottom: 40 } : {}}>
          <SEOHelmet
            title={`${article.title} | ${capitalize(canonicalDomain)} Learn`}
            description={article.summary}
            canonicalPath={`/learn/${article.urlSlug}`}
            canonicalDomain={`https://${lowerCase(canonicalDomain)}.com`}
            image={seoImgUrl}
            bodyAttributes={{ class: 'public-site' }}
            additionalHeadTags={
              article.noindex ? <meta name='robots' content='noindex' /> : null
            }
          />
          <Media lessThan='md'>
            {imgUrl ? (
              <HeroImageBox imgUrl={imgUrl} title={article.title} />
            ) : null}
            <TocMobile tableOfContents={tableOfContents} article={article} />
          </Media>
          <Box>
            <Flex flexWrap='wrap' justifyContent='space-between' width={1}>
              <Box flex='1 1 500px' maxWidth={[null, null, MAX_CONTENT_WIDTH]}>
                <Media greaterThan='sm'>
                  {imgUrl ? (
                    <HeroImageBox imgUrl={imgUrl} title={article.title} />
                  ) : null}
                </Media>
                <ArticleContent>{articleContent}</ArticleContent>
                <Author article={article} />
              </Box>
              <Media greaterThanOrEqual='md'>
                {(mediaClassNames, renderChildren) => {
                  if (renderChildren) {
                    return (
                      <Flex
                        className={mediaClassNames}
                        flex='0 0 280px'
                        justifyContent='end'
                        pl={[24, 24, 24, 24]}
                        flexDirection={'column'}
                        height={'100%'}
                      >
                        <SidebarCTA article={article} />
                        <TableOfContents items={tableOfContents} isXs={false} />
                      </Flex>
                    );
                  }
                }}
              </Media>
            </Flex>
          </Box>
        </ArticleContainer>
        {!isBareLayout && (
          <ArticleContainer>
            <RelatedArticlesList
              hasVisualSpace={true}
              articleSlug={article?.urlSlug}
              primarySeries={article?.primarySeries}
              ssrData={parsedSsrData}
            />
          </ArticleContainer>
        )}
      </Container>
    </>
  );
}

function HeroImageBox({ imgUrl, title }) {
  return (
    <Box
      className='hero-image'
      mb={[0, 24]}
      width={1}
      maxWidth={[null, null, MAX_CONTENT_WIDTH]}
      style={{
        borderRadius: '6px',
        border: `1px solid ${INNER_BORDER_COLOR}`,
        overflow: 'hidden',
      }}
    >
      <img src={imgUrl} alt={title} width='100%' />
    </Box>
  );
}

function TocMobile({ tableOfContents, article }) {
  const [isOpen, setOpen] = useState(false);
  const toggleTOC = () => {
    setOpen((o) => !o);
  };
  return (
    <StyledMobileToc
      className={classnames({ 'toc--is-open': isOpen })}
      minHeight={44}
      width={1}
      my={12}
    >
      <Button
        onClick={toggleTOC}
        text='Table of Contents'
        fill={true}
        rightIcon={isOpen ? 'chevron-up' : 'chevron-down'}
        alignText='left'
        style={{
          backgroundColor: LIGHT_BACKGROUND,
          color: MAINTEXT_COLOR,
        }}
      />
      <Collapse isOpen={isOpen}>
        <Box
          width={1}
          p={3}
          style={{
            transition: 'all 0.3s ease',
            border: `1px solid ${LIGHT_BACKGROUND}`,
            borderTop: 0,
          }}
        >
          <SidebarCTA article={article} />
          <TableOfContents items={tableOfContents} isXs={true} />
        </Box>
      </Collapse>
    </StyledMobileToc>
  );
}

function ArticleBreadcrumb({ article }) {
  const { primarySeries } = article || {};

  if (!primarySeries) return null;
  return (
    <Flex color={SUBTEXT_COLOR}>
      <Link to={`/learn`} style={{ color: MAINTEXT_COLOR }}>
        Learn
      </Link>
      {primarySeries && (
        <>
          <Box mx={1}>/</Box>
          <Link
            to={`/learn/series/${primarySeries?.urlSlug}`}
            style={{ color: SUBTEXT_COLOR }}
          >
            {primarySeries?.title}
          </Link>
        </>
      )}
    </Flex>
  );
}

interface HeaderProps {
  article: any;
  isFullWidth?: boolean;
}

const Header: React.FC<HeaderProps> = React.memo(function ArticleHeader({
  article,
  isFullWidth,
}) {
  const updatedTime = moment(article.updatedAt).format('MMM D, YYYY');
  const { authorName, authorIsCoach, author } = article;
  const coachSlug = idx(author, (_) => _.agentProfile.coachSlug);
  const isCoach = idx(author, (_) => _.isAgent);
  const updateTimeText = `${article.timeToRead} min read • Updated ${updatedTime}`;
  const authorView = (
    <>
      {authorName && (
        <>
          {authorIsCoach && isCoach && coachSlug ? (
            <Link to={`/@${coachSlug}`}>{authorName}</Link>
          ) : (
            authorName
          )}
          {` • `}
        </>
      )}
    </>
  );

  return (
    <>
      <Media greaterThan='xs'>
        {isFullWidth && <ArticleBreadcrumb article={article} />}
        <ArticleH1>{article.title}</ArticleH1>
        <Flex color={SUBTEXT_COLOR} mt={3}>
          {authorView}
          {updateTimeText}
        </Flex>
      </Media>
      <Media at='xs'>
        <ArticleH1 className='title title--mobile'>{article.title}</ArticleH1>
        <Box color={SUBTEXT_COLOR} fontSize={14} my={2}>
          {updateTimeText}
        </Box>
      </Media>
    </>
  );
});

interface AuthorProps {
  article: any;
}

const Author: React.FC<AuthorProps> = React.memo(({ article }) => {
  const {
    authorName,
    authorPhoto,
    authorShortBio,
    authorLink,
    authorIsCoach,
    author,
  } = article;
  const coachSlug = idx(author, (_) => _.agentProfile.coachSlug);
  const isCoach = idx(author, (_) => _.isAgent);
  const canRenderCoachProfileLink = authorIsCoach && isCoach && coachSlug;
  if (!authorName) return null;
  const link =
    authorLink || (canRenderCoachProfileLink ? `/@${coachSlug}` : null);

  return (
    <Flex mt={4} pt={4} className='bts'>
      <Box minWidth={60} mr={24}>
        <Avatar user={{ name: authorName, photo: authorPhoto }} size={60} />
      </Box>
      <Box>
        <Flex flexWrap='wrap'>
          <Box fontWeight={600}>
            {link ? (
              <a href={link} target='_blank' rel='noopener noreferrer nofollow'>
                {authorName}
              </a>
            ) : (
              authorName
            )}
          </Box>
          {authorIsCoach && <Box ml={2}>Coach</Box>}
        </Flex>
        {authorShortBio && <Box mt={1}>{authorShortBio}</Box>}
        {canRenderCoachProfileLink && (
          <Box mt={1}>
            <span>Looking for a Coach ?</span>
            <Link to={`/@${coachSlug}`}>
              <span>{` Work with ${authorName}`}</span>
            </Link>
          </Box>
        )}
      </Box>
    </Flex>
  );
});

const ArticleH1 = styled('h1')`
  font-weight: 600 !important;
  font-size: 48px !important;
  line-height: 120% !important;
  max-width: 750px !important;

  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 36px !important;
  }
`;

const StyledMobileToc = styled(Box)`
  &.toc--is-open button {
    border-radius: 0;
  }

  border: 1px solid #f5f5fb;
  border-radius: 8px;

  & .${Classes.BUTTON}:not([class*='${BP_NS}-intent-']) {
    box-shadow: none;
  }
  & .header {
    display: none;
  }
  & .${Classes.COLLAPSE_BODY} > div > div {
    margin-top: 0 !important;
    color: #f5f5fb;
  }
`;

const ArticleContainer = styled('div')`
  font-size: 18px;
  line-height: 28px;
  margin: 0px auto;
  background-color: ${LIGHTEST_BACKGROUND};

  .hero-image {
    img {
      margin: 0;
      aspect-ratio: attr(width) / attr(height);
    }
  }
  @media screen and (max-width: 425px) {
    .hero-image {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  h1.title {
    margin-top: 30px;
    color: ${MAINTEXT_COLOR};
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 0;
  }
  h1.title.title--mobile {
    font-size: 28px;
    line-height: 36px;
  }
  h1 {
    font-weight: bold;
    font-size: 36px;
  }
  h2 {
    font-size: 30px;
    margin-top: -24px;
    padding-top: 60px;
  }
  h3 {
    font-size: 22px;
    margin-top: -36px;
    padding-top: 60px;
  }
  h4 {
    font-size: 18px;
    margin-top: 24px;
  }
  .${Classes.CARD} img {
    margin: 0;
  }

  h2,
  h3,
  h4 {
    font-weight: 500;
    font-family: Inter;
    line-height: 1.3;
  }

  h1 + h2,
  h1 + h3,
  h1 + h4,
  h2 + h3,
  h2 + h4,
  h3 + h4 {
    margin-top: -30px;
    padding-top: 40px;
  }

  blockquote {
    white-space: break-spaces;
  }
`;

const ArticleContent = styled('div')`
  img {
    max-width: 100%;
    margin: 16px 0;
  }
`;

export default Article;
