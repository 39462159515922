import { Box } from '@/components/layout/flexbox';

export const Directions = {
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export default function SlideContainer(props) {
  const { visible, slideFrom, children } = props;

  let visibleTransform;
  let hiddenTransform;

  if (slideFrom === Directions.LEFT) {
    visibleTransform = 'translateX(0%)';
    hiddenTransform = 'translateX(-100%)';
  } else if (slideFrom === Directions.RIGHT) {
    visibleTransform = 'translateX(0%)';
    hiddenTransform = 'translateX(100%)';
  } else if (slideFrom === Directions.UP) {
    visibleTransform = 'translateY(0%)';
    hiddenTransform = 'translateY(-100%)';
  } else if (slideFrom === Directions.DOWN) {
    visibleTransform = 'translateY(0%)';
    hiddenTransform = 'translateY(120%)';
  }

  return (
    <Box
      {...props}
      style={{
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
        visibility: visible ? 'visible' : 'hidden',
        transform: visible ? visibleTransform : hiddenTransform,
        transition: visible
          ? 'transform 0.4s'
          : 'transform 0.4s, visibility 0s 0.4s',
        ...props.style,
      }}
    >
      {children}
    </Box>
  );
}
