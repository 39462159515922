import { Colors, Icon } from '@blueprintjs/core';
import classnames from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import { Configure, Highlight, Hits, Index } from 'react-instantsearch';
import { useNavigate } from 'react-router-dom';

import { Box, Flex } from '@/components/layout/flexbox';
import Clickable from '@/components/pieces/interaction/Clickable';
import Truncate from '@/components/pieces/Truncate';
import Avatar from '@/components/pieces/users/Avatar';

import { IndexComponentProps, ResultComponentProps } from './search-types';

const EXCLUDE_COACH_SLUGS = ['test-coach', 'sean-testing'];

export default function Coaches({
  indexKey,
  hitsPerPage,
  selectedObjectId,
  onClose,
  onSelect,
}: IndexComponentProps) {
  const excludeCoachesFilter = EXCLUDE_COACH_SLUGS.map(
    (slug) => `NOT coach_slug:'${slug}'`,
  ).join(' AND ');

  return (
    <Index indexName='agent_profile_index' indexId={indexKey}>
      <Configure hitsPerPage={hitsPerPage} filters={excludeCoachesFilter} />
      <Hits
        hitComponent={(props) => (
          <CoachResult
            {...props}
            selected={props.hit.objectID === selectedObjectId}
            onClose={onClose}
            onSelect={onSelect}
          />
        )}
      />
    </Index>
  );
}

export function CoachResult({
  selected,
  hit,
  onClose,
  onSelect,
}: ResultComponentProps) {
  const navigate = useNavigate();
  const containerCls = classnames('resultItem', {
    selected,
  });
  const link = `/@${hit.coach_slug}`;
  function goToProfile() {
    onSelect(hit);
    navigate(link);
    onClose();
  }
  useHotkeys(
    'enter',
    () => {
      if (selected) {
        goToProfile();
      }
    },
    {},
    [selected, link],
  );

  return (
    <Clickable
      onClick={(e) => {
        if ((e.target as HTMLElement).tagName === 'A') {
          onSelect(hit);
          return;
        }
        goToProfile();
      }}
    >
      <Flex alignItems='center' className={containerCls}>
        <Box pl={[2, 3]} py={12}>
          <Avatar
            user={{
              ...hit,
              photo: hit.photo,
            }}
            size={40}
          />
        </Box>
        <Box ml={[2, 3]} py={12} pr={2} flex={1} className='resultItemDetails'>
          <Truncate style={{ color: Colors.GRAY3 }}>
            <Highlight hit={hit} attribute='name' />
          </Truncate>
          <Box className='secondary' style={{ color: Colors.GRAY3 }}>
            <Truncate style={{ color: Colors.GRAY3 }}>
              <Highlight hit={hit} attribute='summary' />
            </Truncate>
          </Box>
        </Box>
        <Box pl={2} pr={3} className='arrow'>
          <Icon icon='arrow-right' />
        </Box>
      </Flex>
    </Clickable>
  );
}
