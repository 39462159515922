import { usePagination, useInstantSearch } from 'react-instantsearch';
import { Link } from 'react-router-dom';

import { Box, Flex } from '@/components/layout/flexbox';
import Pagination from '@/components/pieces/form/Pagination';
import { MAINTEXT_COLOR } from '@/css/constants';

interface ArticleSearchResultsHeaderProps {
  title: string;
  hitsPerPage?: number;
  url?: string;
}

export default function ArticleSearchResultsHeader({
  title,
  hitsPerPage = 4,
  url,
}: ArticleSearchResultsHeaderProps) {
  const { results } = useInstantSearch();
  const { nbPages, currentRefinement, refine, nbHits } = usePagination();

  if (!results || nbPages === 0) {
    return null;
  }

  return (
    <Flex justifyContent='space-between' alignItems='baseline'>
      {title ? (
        <Box pr={2}>
          {url ? (
            <Box fontSize={[18, 24]}>
              <Link
                style={{
                  lineHeight: '120%',
                  fontWeight: 600,
                  background: 'transparent',
                  padding: 0,
                  color: MAINTEXT_COLOR,
                }}
                to={url}
              >
                {title}
              </Link>
            </Box>
          ) : (
            <Box fontSize={[18, 24]} fontWeight={600}>
              {title}
            </Box>
          )}
        </Box>
      ) : null}
      <Flex alignItems='center'>
        <Pagination
          nbPages={nbPages}
          // currentRefinement start from 0 but Pagination start from 1
          currentPage={currentRefinement + 1}
          totalCount={nbHits}
          countPerPage={hitsPerPage}
          onPrev={() => refine(currentRefinement - 1)}
          onNext={() => refine(currentRefinement + 1)}
        />
      </Flex>
    </Flex>
  );
}
