import { Colors } from '@blueprintjs/core';
import styled from '@emotion/styled';

import { Box, Flex } from '@/components/layout/flexbox';
import { Media } from '@/components/layout/Media';
import { Container } from '@/components/pieces/bootstrap';
import PracticeSvg from '@/components/pieces/icons/svg/practice';
import { BRAND_PURPLE, HEADING_FONT, SUBTEXT_COLOR } from '@/css/constants';

export default function PracticeHeader({ showSubTitle = true }) {
  return (
    <Media greaterThan='xs'>
      <Box width={1} className='bbs' py={4} bg={Colors.WHITE}>
        <Container maxWidth={1000}>
          <Flex alignItems='flex-end'>
            <Box css={{ flex: 1 }}>
              <Flex alignItems='center'>
                <PracticeSvg size={26} color={BRAND_PURPLE} />
                <Box ml={3} fontFamily={HEADING_FONT}>
                  <Heading>Roleplays</Heading>
                </Box>
              </Flex>
              {showSubTitle && (
                <Box color={SUBTEXT_COLOR} mt={2}>
                  Practice and improve your communication skills through
                  AI-powered scenarios
                </Box>
              )}
            </Box>
          </Flex>
        </Container>
      </Box>
    </Media>
  );
}

const Heading = styled('h1')`
  font-size: 28px;
  margin: 0;
`;
