import { Classes } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import { Popover } from '@/components/helpers/ui/blueprint-overrides/Popover';
import { Box, Flex } from '@/components/layout/flexbox';
import { SUBTEXT_COLOR } from '@/css/constants';

function GroupList({ groups }) {
  return (
    <Box width={250} style={{ maxWidth: '100%' }}>
      {groups.map((group, i) => (
        <Box key={`group-${group.id}`} mt={i > 0 ? 3 : 0}>
          <Link to={`/workspace/groups/${group.id}`}>{group.name}</Link>
        </Box>
      ))}
    </Box>
  );
}

export default function GroupsWithPopover({ groups }) {
  if (!groups || groups.length === 0) {
    return (
      <Box fontStyle='italic' color={SUBTEXT_COLOR}>
        No groups
      </Box>
    );
  }
  if (groups.length == 1) {
    return (
      <Link to={`/workspace/groups/${groups[0].id}`}>{groups[0].name}</Link>
    );
  }
  return (
    <Popover
      content={<GroupList groups={groups} />}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      interactionKind='click'
      placement='bottom-start'
      modifiers={{
        arrow: { enabled: true },
      }}
    >
      <Flex
        alignItems='center'
        css={{
          width: 'fit-content',
          borderBottom: `1px dashed ${SUBTEXT_COLOR}`,
          cursor: 'help',
          wordBreak: 'break-word',
        }}
        py={1}
      >
        {groups.length} groups
      </Flex>
    </Popover>
  );
}
