import * as Sentry from '@sentry/react';
import flatMap from 'lodash/flatMap';
import { NavigateFunction } from 'react-router-dom';

import { removeSignUpParamsFromLocalStorage } from '@/components/helpers/signup/sign-up-params';
import { setTokens } from '@/components/session/JwtTokenManager';

interface HandleSignUpCompleteParams {
  response: any;
  handleError: (error: any) => void;
  nextPath?: string;
  onCompleted?: ((response: any) => void) | null;
  asWorkspaceAdmin?: boolean;
  navigate: NavigateFunction;
  searchParams: URLSearchParams;
}

export default function handleSignUpComplete({
  response,
  handleError,
  nextPath = '/dashboard',
  onCompleted = null,
  asWorkspaceAdmin = false,
  navigate,
  searchParams,
}: HandleSignUpCompleteParams) {
  const errorMessage = flatMap(response.signUp.errors, 'messages').join('');
  if (errorMessage.includes('this email already exists'))
    throw new Error('this email already exists');

  if (errorMessage.includes('valid email')) throw new Error('invalid email');

  if (errorMessage.includes('gift code')) throw new Error(errorMessage);

  removeSignUpParamsFromLocalStorage();

  setTokens({
    token: response.signUp.token,
    refreshToken: response.signUp.refreshToken,
    success: () => {
      if (onCompleted) onCompleted(response);

      // Send event to Google Tag Manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'signUp',
      });

      if (asWorkspaceAdmin) localStorage.removeItem('workspaceSignedToken');

      if (nextPath) {
        const isSamePage = window.location.pathname.endsWith(nextPath);

        if (isSamePage) {
          window.location.reload();
        } else if (navigate) {
          // Split nextPath into base path and search params
          const [basePath, nextPathSearch] = nextPath.split('?');

          // Create URLSearchParams from current URL params
          const newParams = new URLSearchParams(searchParams);

          // If nextPath had query params, add them to newParams
          if (nextPathSearch) {
            const nextPathParams = new URLSearchParams(nextPathSearch);
            nextPathParams.forEach((value, key) => {
              newParams.set(key, value);
            });
          }

          // Build the full path with all params
          const fullPath = `${basePath}${newParams.toString() ? `?${newParams.toString()}` : ''}`;

          try {
            navigate(fullPath, { replace: true });
          } catch {
            window.location.href = fullPath;
          }
        } else {
          window.location.href = nextPath;
        }
      } else {
        navigate('/dashboard', { replace: true });
      }
    },
    error: () => {
      if (nextPath && navigate) navigate(nextPath, { replace: true });
      handleError(new Error('Failed to save tokens'));
    },
  });
}

export function sendErrorToSentry(error: Error, email: string) {
  Sentry.setUser({ email });
  Sentry.captureException(error);
}
