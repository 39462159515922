import {
  EvaluationCriterionItemType,
  EvaluationCriterionType,
} from '@/graphql';

export interface BehaviorType extends EvaluationCriterionItemType {
  isNew?: boolean;
}

export interface BehaviorCriterionType extends EvaluationCriterionType {
  items: BehaviorType[];
}

export enum EvaluationCriteriaDialogs {
  RemoveCriterion = 'remove-criterion',
  EditCriterion = 'edit-criterion',
  AddCriterion = 'add-criterion',
  SortCriteria = 'sort-criteria',
  ImportFromCollection = 'import-from-collection',
  ConfirmRemoveAllCriteria = 'confirm-remove-all-criteria',
}
