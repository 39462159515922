import { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { APP_REDIRECTION_LINK_COOKIE_NAME } from '@/global/constants';

// Keep the shared listeners array outside the hook
let storageListeners = [];

export function useCurrentSession(): {
  currentSessionLink: string | null;
  sessionLoaded: boolean;
} {
  const [currentSessionLink, setCurrentSessionLink] = useState<string | null>(
    null,
  );
  const [sessionLoaded, setSessionLoaded] = useState<boolean>(false);

  useEffect(() => {
    setCurrentSessionLink(
      localStorage.getItem(APP_REDIRECTION_LINK_COOKIE_NAME),
    );
    setSessionLoaded(true);

    const storageListener = (
      e: StorageEvent | { key: string; newValue: string | null },
    ) => {
      if (e.key === APP_REDIRECTION_LINK_COOKIE_NAME) {
        setCurrentSessionLink(e.newValue);
      }
    };
    storageListeners.push(storageListener);

    window.addEventListener('storage', storageListener);
    return () => {
      window.removeEventListener('storage', storageListener);
      storageListeners = storageListeners.filter((i) => i !== storageListener);
    };
  }, []);

  return {
    currentSessionLink,
    sessionLoaded,
  };
}

export function setSessionLink(link) {
  if (!import.meta.env.SSR) {
    if (link) {
      localStorage.setItem(APP_REDIRECTION_LINK_COOKIE_NAME, link);
      Cookies.set(APP_REDIRECTION_LINK_COOKIE_NAME, link, { expires: 30 });
      storageListeners.forEach((storageListener) => {
        storageListener({
          key: APP_REDIRECTION_LINK_COOKIE_NAME,
          newValue: link,
        });
      });
    } else {
      localStorage.removeItem(APP_REDIRECTION_LINK_COOKIE_NAME);
      Cookies.remove(APP_REDIRECTION_LINK_COOKIE_NAME);
      storageListeners.forEach((storageListener) => {
        storageListener({
          key: APP_REDIRECTION_LINK_COOKIE_NAME,
          newValue: null,
        });
      });
    }
  }
}
