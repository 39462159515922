import idx from 'idx';
import moment from 'moment-timezone';

import { isPendingBookingRequest } from '@/components/helpers/meeting/is-pending-booking-request';
import { BLUE, BRAND_PURPLE, LIGHT_PURPLE } from '@/css/constants';

const IS_SOON_MINS = 5;
const HIGHLIGHT_COLOR_LISTS = [
  '#D8CDB2',
  '#75BCD4',
  '#EB9CAC',
  '#96AFB9',
  '#FCFCD4',
  '#C8A7CF',
  '#B4A7B7',
];

export function isRefundable(meeting) {
  const browserTimezone = moment.tz.guess();
  const browserNow = moment().tz(browserTimezone);
  const refundableUntil =
    meeting.refundableUntil &&
    moment(meeting.refundableUntil).tz(browserTimezone);
  return refundableUntil && refundableUntil > browserNow;
}

export function getMeetingStatus(meeting) {
  const browserTimezone = moment.tz.guess();
  const meetingDate = moment(meeting.eventAt).tz(browserTimezone);
  const now = moment().tz(browserTimezone);

  const duration = moment.duration(now.diff(meetingDate));
  const minsToMeeting = duration.asMinutes();
  const meetingIsSoon =
    minsToMeeting > -IS_SOON_MINS && minsToMeeting < meeting.duration;
  const meetingIsComplete = minsToMeeting > meeting.duration;
  const meetingIsScheduling = isPendingBookingRequest(meeting);

  if (meeting.canceledAt) {
    return 'CANCELED';
  } else if (meetingIsComplete) {
    return 'COMPLETE';
  } else if (meetingIsSoon) {
    return 'SOON';
  } else if (meetingIsScheduling) {
    return 'SCHEDULING';
  } else {
    return 'UPCOMING';
  }
}

export function getMeetingColor(meetingStatus) {
  if (meetingStatus === 'SCHEDULING') {
    return '#f6ad55';
  }
  return meetingStatus === 'CANCELED' || meetingStatus === 'COMPLETE'
    ? LIGHT_PURPLE
    : meetingStatus === 'SOON'
      ? BRAND_PURPLE
      : BLUE;
}

export function buildMeetingUrl(meetingId, customerId = null, view = 'coach') {
  if (customerId && view === 'assist') {
    return `/assist/${customerId}/sessions/${meetingId}`;
  }
  return `/sessions/${meetingId}`;
}

export function getUserBufferSetting(user) {
  return {
    beforeBuffer: idx(user, (_) => _.agentProfile.bufferBefore) || 0,
    afterBuffer: idx(user, (_) => _.agentProfile.bufferAfter) || 0,
  };
}

function isOverlapMeeting(
  firstMeeting,
  secondMeeting,
  beforeBuffer = 10,
  afterBuffer = 10,
) {
  const afterfirstMeetingEnding = moment(firstMeeting.eventAt).add(
    afterBuffer + firstMeeting.duration,
    'minutes',
  );
  const beforeSecondMeetingStart = moment(secondMeeting.eventAt).subtract(
    beforeBuffer,
    'minutes',
  );

  return afterfirstMeetingEnding.isAfter(beforeSecondMeetingStart);
}

export function isOverlapExistInMeetingList(meetingList = [], user = {}) {
  const { beforeBuffer, afterBuffer } = getUserBufferSetting(user);
  const sortedMeetingList = meetingList.sort((meetingA, meetingB) => {
    return meetingA.eventAt > meetingB.eventAt ? 1 : -1;
  });

  for (let index = 0; index < sortedMeetingList.length; index++) {
    if (index === 0) {
      continue;
    }
    if (
      isOverlapMeeting(
        sortedMeetingList[index - 1],
        sortedMeetingList[index],
        beforeBuffer,
        afterBuffer,
      )
    ) {
      return true;
    }
  }

  return false;
}

export function buildOverlapMeetingList(
  meetingLists = [],
  beforeBuffer = 10,
  afterBuffer = 10,
) {
  const sortedMeetingByEventAtAsc = meetingLists.sort((meetingA, meetingB) => {
    return meetingA.eventAt > meetingB.eventAt ? 1 : -1;
  });
  let colorIndex = -1;
  sortedMeetingByEventAtAsc.forEach((meeting, index, meetingArray) => {
    if (index === 0) {
      meeting.backgroundColor = 'transparent';
      return;
    }
    const beforeMeeting = meetingArray[index - 1];
    const afterMeeting = meeting;
    const isOverlap = isOverlapMeeting(
      beforeMeeting,
      afterMeeting,
      beforeBuffer,
      afterBuffer,
    );
    if (isOverlap) {
      if (beforeMeeting.backgroundColor === 'transparent') {
        colorIndex = colorIndex > 6 ? 6 : colorIndex + 1;
        afterMeeting.backgroundColor = HIGHLIGHT_COLOR_LISTS[colorIndex];
        beforeMeeting.backgroundColor = HIGHLIGHT_COLOR_LISTS[colorIndex];
      } else {
        afterMeeting.backgroundColor = beforeMeeting.backgroundColor;
      }
    } else {
      afterMeeting.backgroundColor = 'transparent';
    }
  });
  return meetingLists;
}
