import { Link } from 'react-router-dom';

import { Box, Flex } from '@/components/layout/flexbox';
import PaginationButton from '@/components/pieces/form/PaginationButton';

export default function PaginationHeader({
  page,
  totalPages,
  onNext,
  onPrev,
  viewAllUrl,
  title = null,
}) {
  return (
    <Flex justifyContent='space-between' alignItems='center'>
      {title}
      <Flex
        alignItems='center'
        flexWrap='nowrap'
        style={{ minWidth: 160 }}
        justifyContent='flex-end'
      >
        {viewAllUrl && (
          <Box fontWeight={600} fontSize={16}>
            <Link to={viewAllUrl}>View All</Link>
          </Box>
        )}
        <Box mx={2}>
          <PaginationButton
            icon='chevron-left'
            disabled={page < 2}
            onClick={() => onPrev()}
            style={{ width: '32px', height: '37px', padding: '0' }}
          />
        </Box>
        <Box>
          <PaginationButton
            icon='chevron-right'
            disabled={page >= totalPages}
            onClick={() => onNext()}
            style={{ width: '32px', height: '37px', padding: '0' }}
          />
        </Box>
      </Flex>
    </Flex>
  );
}
