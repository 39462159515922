import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { useInView } from 'react-intersection-observer';

import { Box } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import { BRAND_PURPLE, MIDTEXT_COLOR } from '@/css/constants';

export const MIN_TOC_WIDTH = 100;

function TableOfContents({ items, isXs }) {
  const [activeItem, setActiveItem] = useState();
  const [reversedTOCItems] = useState(() => items.slice().reverse());
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 70;

    for (const item of reversedTOCItems) {
      const dom = document.getElementById(item.id);
      if (!dom) {
        continue;
      }
      if (scrollPosition > dom.offsetTop) {
        if (item.id !== activeItem) {
          setActiveItem(item.id);
        }
        break;
      }
    }
  };

  const smoothScroll = (e, id) => {
    if (e) {
      e.preventDefault();
    }
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    if (!isXs) {
      const scrollHandler = throttle(handleScroll, 200);
      window.addEventListener('scroll', scrollHandler);
      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }
  }, []);

  useEffect(() => {
    if (inView && !isXs && window.location.hash.length > 1) {
      setTimeout(() => smoothScroll(null, window.location.hash.slice(1)), 400);
    }
  }, [inView]);

  if (!items || !items.length) {
    return <div />;
  }

  return (
    <TOC
      className='article-toc'
      ref={ref}
      style={{
        marginTop: isXs ? 60 : null,
      }}
    >
      <Box
        className={classNames({ 'toc-desktop': !isXs })}
        css={{
          position: isXs ? 'static' : 'sticky',
          top: '70px',
          zIndex: 1,
        }}
      >
        <TOCBox minWidth={MIN_TOC_WIDTH}>
          <div className='header'>Contents</div>
          <ul style={{ fontSize: 14 }}>
            {items.map(({ id, label, nested }, index) => (
              <li key={index} className={nested ? 'nested' : null}>
                <a
                  href={`#${id}`}
                  onClick={(e) => smoothScroll(e, id)}
                  className={activeItem === id ? 'active' : null}
                >
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </TOCBox>
      </Box>
    </TOC>
  );
}

export default React.memo(TableOfContents);

const TOCBox = styled(Box)`
  color: ${MIDTEXT_COLOR};
`;

const TOC = styled(Box)`
  min-width: ${MIN_TOC_WIDTH}px;
  max-width: 280px;
  font-size: 16px;
  width: 100%;

  @media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg +
    50}px) {
    font-size: 14px;

    li a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .header {
    color: #384957;
    font-weight: 600;
    font-size: 16px;
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 4px 0;
    display: block;

    &.nested {
      padding-left: 12px;
    }

    a {
      color: #778397;

      &.active {
        color: ${BRAND_PURPLE};

        :hover {
          color: ${BRAND_PURPLE};
        }
      }

      :hover {
        color: #384957;
      }
    }
  }
`;
