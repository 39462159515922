import { Icon, IconName } from '@blueprintjs/core';
import styled from '@emotion/styled';

import { Box } from '@/components/layout/flexbox';
import { ScenarioDifficulty } from '@/graphql';

export default function DifficultyTag({
  difficulty,
}: {
  difficulty: ScenarioDifficulty;
}) {
  const key = difficulty?.toLowerCase()?.replace('_', '');
  const icon = {
    easy: 'learning',
    medium: 'lightning',
    hard: 'flame',
    veryhard: 'crown',
    custom: 'person',
  }[key];
  const label = {
    easy: 'Easy',
    medium: 'Medium',
    hard: 'Hard',
    veryhard: 'Very Hard',
    custom: 'Custom',
  }[key];

  return (
    <DifficultyTagBox className={key} fontSize={12} fontWeight={500}>
      <Icon size={14} icon={icon as IconName} />
      {label}
    </DifficultyTagBox>
  );
}

const DifficultyTagBox = styled(Box)`
  border-radius: 20px;
  padding: 4px 12px;
  display: inline-block;

  span {
    margin-right: 6px;
  }

  &.easy {
    background-color: #dcfce7;
    color: #166534;
  }

  &.medium {
    background-color: #fef9c3;
    color: #854d0e;
  }

  &.hard {
    background-color: #ffead1;
    color: #8e301a;
  }

  &.veryhard {
    background-color: #fee2e2;
    color: #993412;
  }

  &.custom {
    background-color: #d1e9ff;
    color: #281a8e;
  }
`;
