import { useState } from 'react';

import { Icon } from '@blueprintjs/core';
import styled from '@emotion/styled';

import StandardCredentialsDialog from '@/components/pieces/talent-agents/StandardCredentialsDialog';
import { BRAND_PURPLE } from '@/css/constants';

function StandardCredentials({ standardCredentials }) {
  const [visible, setVisible] = useState(false);
  const [standardCredential, setStandardCredential] = useState(null);

  if (!standardCredentials || !standardCredentials.length) return null;

  return (
    <>
      {standardCredentials.map((credential) => (
        <StandardCredentialOption
          onClick={() => {
            setStandardCredential(credential);
            setVisible(true);
          }}
          key={`standard-credential-${credential.id}`}
        >
          {credential.name}
          <Icon
            style={{ marginLeft: '4px' }}
            icon='shield'
            size={18}
            color={BRAND_PURPLE}
          />
        </StandardCredentialOption>
      ))}
      <StandardCredentialsDialog
        visible={standardCredential && visible}
        onClose={setVisible}
        standardCredential={standardCredential}
      />
    </>
  );
}

export default StandardCredentials;

const StandardCredentialOption = styled('li')`
  color: ${BRAND_PURPLE};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
