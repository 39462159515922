import { useEffect, useReducer } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { RichContentRenderer } from '@/components/helpers/ui/RichContentRenderer';
import { buildUrl } from '@/components/helpers/url-utils';
import { Box } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import AuthenticateDialog from '@/components/pieces/auth/AuthenticateDialog';
import { hasValidToken } from '@/components/session/JwtTokenManager';
import { SUBTEXT_COLOR } from '@/css/constants';
import {
  BitsAvailableDocument,
  ExecuteGptPromptDocument,
  GptResponsesDocument,
} from '@/graphql';

import ConfirmEmailModal from './ConfirmEmailModal';
import GPTBotForm from './GPTBotForm';
import GPTResponse from './GPTResponse';
import PurchaseBitsModal from './PurchaseBitsModal';

enum Actions {
  SetResponses = 'SET_RESPONSES',
  SetBitsAvailable = 'SET_BITS_AVAILABLE',
  DeleteResponse = 'DELETE_RESPONSE',
  PromptExecuted = 'PROMPT_EXECUTED',
  ShowForm = 'SHOW_FORM',
  HideForm = 'HIDE_FORM',
  OpenResponse = 'OPEN_RESPONSE',
  ClearAutoScroll = 'CLEAR_AUTO_SCROLL',
  ShowPurchase = 'SHOW_PURCHASE',
  HidePurchase = 'HIDE_PURCHASE',
  ShowSignup = 'SHOW_SIGNUP',
  HideSignup = 'HIDE_SIGNUP',
  ShowConfirm = 'SHOW_CONFIRM',
  HideConfirm = 'HIDE_CONFIRM',
}

function reducer(state, action) {
  switch (action.type) {
    case Actions.SetResponses:
      return {
        ...state,
        gptResponses: action.payload,
      };
    case Actions.SetBitsAvailable: {
      const isConfirmed = action.payload.isConfirmed;
      return {
        ...state,
        bits: action.payload.bitsAvailable
          ? parseInt(action.payload.bitsAvailable)
          : null,
        isConfirmed,
        emailProvider: action.payload.emailProvider,
        showingConfirm: isConfirmed ? false : state.showingConfirm,
      };
    }
    case Actions.PromptExecuted:
      return {
        ...state,
        gptResponses: [...state.gptResponses, action.payload],
        responseOpenStatus: {
          ...state.responseOpenStatus,
          [action.payload.uuid]: true,
        },
        showingForm: false,
        autoScrollUuid: action.payload.uuid,
      };
    case Actions.DeleteResponse:
      return {
        ...state,
        gptResponses: state.gptResponses.filter(
          (r) => r.uuid !== action.payload.uuid,
        ),
      };
    case Actions.ShowForm:
      return {
        ...state,
        showingForm: true,
      };
    case Actions.HideForm:
      return {
        ...state,
        showingForm: false,
      };
    case Actions.OpenResponse:
      return {
        ...state,
        responseOpenStatus: {
          ...state.responseOpenStatus,
          [action.payload.uuid]: !state.responseOpenStatus[action.payload.uuid],
        },
      };
    case Actions.ClearAutoScroll:
      return {
        ...state,
        autoScrollUuid: null,
      };
    case Actions.ShowPurchase:
      return {
        ...state,
        showingPurchase: true,
      };
    case Actions.HidePurchase:
      return {
        ...state,
        showingPurchase: false,
      };
    case Actions.ShowSignup:
      return {
        ...state,
        showingSignup: true,
      };
    case Actions.HideSignup:
      return {
        ...state,
        showingSignup: false,
      };
    case Actions.ShowConfirm:
      return {
        ...state,
        showingConfirm: true,
      };
    case Actions.HideConfirm:
      return {
        ...state,
        showingConfirm: false,
      };
    default:
      console.log('Unknown action: ' + action.type);
      return state;
  }
}

export default function GPTBot({ entry }) {
  const location = useLocation();
  const navigate = useNavigate();
  const loggedIn = !!hasValidToken();
  const [searchParams] = useSearchParams();
  const [state, dispatch] = useReducer(reducer, {
    gptResponses: [],
    bits: null,
    isConfirmed: null,
    responseOpenStatus: {},
    showingForm: true,
    showingPurchase: false,
    showingConfirm: searchParams.has('showConfirm') && loggedIn,
    autoScrollUuid: null,
    emailProvider: null,
  });

  useQuery(GptResponsesDocument, {
    variables: { entryId: entry.id },
    onCompleted: (data) => {
      dispatch({ type: Actions.SetResponses, payload: data.gptResponses });
    },
  });
  const [fetchBits] = useLazyQuery(BitsAvailableDocument, {
    fetchPolicy: 'no-cache',
    variables: {
      loggedIn,
    },
    onCompleted: (data) => {
      dispatch({
        type: Actions.SetBitsAvailable,
        payload: {
          bitsAvailable: data?.userCredit?.bitsAvailable,
          isConfirmed:
            data.optionalCurrentUser && data.optionalCurrentUser.isConfirmed,
          emailProvider:
            data.optionalCurrentUser && data.optionalCurrentUser.emailProvider,
        },
      });
    },
  });

  useEffect(() => {
    fetchBits();
  }, []);

  useEffect(() => {
    if ((!loggedIn || state.isConfirmed) && !!searchParams.has('showConfirm')) {
      // Replace the URL so it has the order in the URL
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('showConfirm');

      // Navigate to same path with updated params
      navigate(
        `${location.pathname}${newParams.toString() ? `?${newParams.toString()}` : ''}`,
        {
          replace: true,
        },
      );
    }
  }, [state.isConfirmed]);

  const [execute, { data, loading, error }] = useMutation(
    ExecuteGptPromptDocument,
    {
      onCompleted: (response) => {
        if (!response.executeGptPrompt.error) {
          fetchBits();
          dispatch({
            type: Actions.PromptExecuted,
            payload: response.executeGptPrompt.gptResponse,
          });
        }
      },
    },
  );
  const submitError =
    error || (data && data.executeGptPrompt && data.executeGptPrompt.error);
  const signupRedirectUrl = buildUrl(location.pathname, {
    data: {
      jsu: 1,
      showConfirm: 1,
    },
  });

  function handleSubmit(answers) {
    execute({
      variables: {
        entryId: entry.id,
        answers,
      },
    });
  }

  function removeGptResponse(gptResponse) {
    dispatch({ type: Actions.DeleteResponse, payload: gptResponse });
  }

  function afterAutoScroll() {
    dispatch({ type: Actions.ClearAutoScroll });
  }

  function showPurchase() {
    dispatch({ type: Actions.ShowPurchase });
  }

  function hidePurchase() {
    dispatch({ type: Actions.HidePurchase });
  }
  function showSignup() {
    dispatch({ type: Actions.ShowSignup });
  }
  function hideSignup() {
    dispatch({ type: Actions.HideSignup });
  }
  function showConfirm() {
    dispatch({ type: Actions.ShowConfirm });
  }
  function hideConfirm() {
    dispatch({ type: Actions.HideConfirm });
  }
  return (
    <PromptCard>
      <Box fontWeight='bold'>{entry.fields.title}</Box>
      {entry.fields.subheader ? (
        <Box mt={1} color={SUBTEXT_COLOR}>
          {entry.fields.subheader}
        </Box>
      ) : null}
      {entry.fields.default_content ? (
        <Box
          mt={3}
          p={3}
          backgroundColor='rgba(255, 255, 255, 0.8)'
          className='round-corners'
          style={{ whiteSpace: 'break-spaces' }}
        >
          <RichContentRenderer content={entry.fields.default_content} />
        </Box>
      ) : null}

      {state.gptResponses.map((gptResponse, i) => (
        <GPTResponse
          key={i}
          open={state.responseOpenStatus[gptResponse.uuid]}
          toggleOpen={() =>
            dispatch({ type: Actions.OpenResponse, payload: gptResponse })
          }
          entry={entry}
          gptResponse={gptResponse}
          onLoadFinished={() => dispatch({ type: Actions.ShowForm })}
          hideForm={() => dispatch({ type: Actions.HideForm })}
          removeGptResponse={removeGptResponse}
          autoScrollUuid={state.autoScrollUuid}
          afterAutoScroll={afterAutoScroll}
        />
      ))}

      <GPTBotForm
        entry={entry}
        error={submitError}
        handleSubmit={handleSubmit}
        loading={loading}
        visible={state.showingForm}
        showPurchase={showPurchase}
        bits={state.bits}
        isConfirmed={state.isConfirmed}
        showSignup={showSignup}
        showConfirm={showConfirm}
      />
      <PurchaseBitsModal
        visible={state.showingPurchase}
        onClose={hidePurchase}
        onSuccess={() => fetchBits()}
        showSignup={() => {
          hidePurchase();
          showSignup();
        }}
      />
      <AuthenticateDialog
        nextPath={signupRedirectUrl}
        isOpen={state.showingSignup}
        onClose={hideSignup}
        signUpToText='to use AI tools'
      />
      <ConfirmEmailModal
        visible={state.showingConfirm}
        onClose={hideConfirm}
        emailProvider={state.emailProvider}
        refetch={fetchBits}
      />
    </PromptCard>
  );
}

const PromptCard = styled('aside')`
  position: relative;
  padding: 24px 32px;
  margin: 36px -32px;
  border-left: 3px solid;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  background: linear-gradient(140.9deg, #e6e7ff 2.58%, #deebff 95.17%);
  border-color: #d0d3fb;

  & + & {
    margin-top: -30px;
  }

  .prompt-title {
    font-size: 16px;
  }
  .prompt-help-text {
    font-size: 16px;
    line-height: 1.4;
  }

  img {
    margin: 0;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    padding: 16px;
  }

  @media screen and (max-width: 1350px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
