import { Icon } from '@blueprintjs/core';

import { Flex } from '@/components/layout/flexbox';
import { YELLOW } from '@/css/constants';

export default function StarRating({ score, size = 16 }) {
  return (
    <Flex>
      {Array.from({ length: 5 }).map((_, i) => {
        if (i + 1 <= score) {
          return (
            <Icon key={`star-${i}`} icon='star' size={size} color={YELLOW} />
          );
        } else {
          return (
            <Icon key={`star-${i}`} icon='star' size={size} color='#E3E3EF' />
          );
        }
      })}
    </Flex>
  );
}
