import { useMemo, useState } from 'react';

import styled from '@emotion/styled';
import moment from 'moment';

import { Box, Flex } from '@/components/layout/flexbox';
import Clickable from '@/components/pieces/interaction/Clickable';
import { mappingGroupedWorkHistories } from '@/components/pieces/talent-agents/workHistoryUtils';
import {
  BRAND_PURPLE,
  HEADING_FONT,
  MAINTEXT_COLOR,
  SUBTEXT_COLOR,
} from '@/css/constants';

export function WorkHistories({ profile }) {
  const [showAllWorkHistories, setShowAllWorkHistories] = useState(false);

  const groupedWorkHistories = useMemo(
    () =>
      mappingGroupedWorkHistories(
        (profile && profile.user && profile.user.workHistories) || [],
      ),
    [profile],
  );

  const renderGroupedWorkHistories = showAllWorkHistories
    ? groupedWorkHistories
    : groupedWorkHistories.slice(0, 3);

  return renderGroupedWorkHistories && renderGroupedWorkHistories.length ? (
    <WorkHistoryContainer
      pb={24}
      flexWrap={['wrap', 'nowrap']}
      flexDirection='column'
    >
      <Box
        color={MAINTEXT_COLOR}
        fontSize={24}
        fontFamily={HEADING_FONT}
        minWidth={140}
        fontWeight={600}
        mb={[2, 0]}
      >
        Work History
      </Box>
      <Box width={['100%', 'auto']} pl={[0, 0]}>
        {renderGroupedWorkHistories.map((workGroup, index) => {
          const renderedTitles = workGroup.work.map((work) => {
            const content = (
              <>
                {work.jobTitles}
                {work.startDate ? (
                  <>
                    <span className='work-hist-dates'>
                      {moment(work.startDate).format('MMM YYYY')}
                      {work.endDate
                        ? ` - ${moment(work.endDate).format('MMM YYYY')}`
                        : ' - Present'}
                    </span>
                  </>
                ) : null}
              </>
            );
            if (workGroup.work.length > 1) {
              return <li key={`work-${work.id}`}>{content}</li>;
            } else {
              return <Box key={`work-${work.id}`}>{content}</Box>;
            }
          });
          return (
            <Flex key={`work-history-${index}`} mt={3} alignItems='start'>
              <img className='work-hist-logo' src={workGroup.logo} />
              <Box css={{ overflowWrap: 'break-word' }} ml={3} flex={1}>
                <Box fontWeight={600}>{workGroup.name}</Box>
                {workGroup.work.length > 1 ? (
                  <StyledUl className='bulleted-list'>
                    {renderedTitles}
                  </StyledUl>
                ) : (
                  renderedTitles
                )}
              </Box>
            </Flex>
          );
        })}
        {groupedWorkHistories.length > 3 && !showAllWorkHistories ? (
          <Box mr={2} mb={2}>
            <Clickable onClick={() => setShowAllWorkHistories(true)}>
              <MoreButton> + more</MoreButton>
            </Clickable>
          </Box>
        ) : null}
      </Box>
    </WorkHistoryContainer>
  ) : null;
}

const WorkHistoryContainer = styled(Flex)`
  .work-hist-dates {
    color: ${SUBTEXT_COLOR};
    margin-left: 8px;
  }
  .work-hist-logo {
    max-height: 40px;
    max-width: 40px;
  }
`;

const MoreButton = styled('span')`
  color: ${BRAND_PURPLE};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const StyledUl = styled('ul')`
  padding-left: 16px;
  margin-bottom: 0;

  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    &::marker {
      color: ${SUBTEXT_COLOR};
    }
  }
`;
