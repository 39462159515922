export default function Error500({ size = 359 }) {
  const height = (251 * size) / 359;
  return (
    <svg
      width={size}
      height={height}
      viewBox='0 0 359 251'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 10C0 4.47715 4.47715 0 10 0H349C354.523 0 359 4.47715 359 10V251H0V10Z'
        fill='#E4ECFF'
      />
      <mask
        id='mask0'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='359'
        height='251'
      >
        <path
          d='M0 10C0 4.47715 4.47715 0 10 0H349C354.523 0 359 4.47715 359 10V251H0V10Z'
          fill='#F5F8FF'
        />
      </mask>
      <g mask='url(#mask0)'>
        <path
          opacity='0.5'
          d='M392.914 234.037C316.324 263.212 25.1506 -8.95722 -34.0597 -0.523177C1.90293 -21.9407 75.4122 -64.5286 81.748 -63.5395C88.0837 -62.5504 352.322 6.60532 483.649 41.0596C478.934 95.6603 454.187 210.697 392.914 234.037Z'
          fill='#F5F8FF'
        />
        <path
          d='M299.833 87.0444L300.166 89.5228L302.645 89.1886C306.781 88.6307 312.127 87.5007 317.922 86.2759L318.01 86.2571C321.418 85.5369 325.003 84.7792 328.658 84.0669C338.898 82.0711 350.24 80.2967 361.565 80.1022C384.217 79.7132 405.357 85.5721 417.966 107.182L419.227 109.343L421.386 108.08L429.865 103.126L432.022 101.866L430.763 99.7077C414.283 71.4646 386.512 64.8476 361.312 65.2804C348.708 65.4969 336.382 67.4589 325.825 69.5164C321.647 70.3308 317.818 71.1422 314.359 71.8752L314.353 71.8767C308.799 73.0536 304.274 74.0108 300.666 74.4975L298.189 74.8315L298.523 77.3085L299.833 87.0444Z'
          fill='#DDD9EE'
          stroke='black'
          strokeWidth='5'
        />
        <path
          d='M55.7885 190.682L54.0486 188.884L52.2522 190.625C41.1218 201.413 25.4417 208.524 9.82175 212.932C-5.73076 217.321 -20.5135 218.833 -29.1461 218.895L-31.6457 218.913L-31.6281 221.412L-31.5589 231.236L-31.5413 233.736L-29.041 233.718C-19.1433 233.647 -3.0398 231.964 13.8434 227.199C30.6593 222.453 48.9643 214.451 62.5618 201.272L64.3557 199.533L62.6182 197.738L55.7885 190.682Z'
          fill='#DDD9EE'
          stroke='black'
          strokeWidth='5'
        />
        <path
          d='M141.937 90.2873L135.744 81.4341L120.995 104.51L120.526 104.315L131.346 62.8215L131.798 62.9339L137.998 71.7266L152.786 48.5187L153.214 48.7138L142.343 90.3639L141.937 90.2873Z'
          fill='#EAAF23'
        />
        <path
          d='M186.891 178.359L193.504 169.813L167.109 162.564L167.156 162.058L209.963 160.088L209.99 160.553L203.437 169.087L229.969 176.335L229.91 176.801L186.938 178.769L186.891 178.359Z'
          fill='#EAAF23'
        />
        <path
          d='M175.786 129.69L177.703 117.767L147.933 124.793L147.709 124.271L188.647 99.4127L188.922 99.8544L187.058 111.735L216.961 104.633L217.153 105.123L176.052 130.067L175.786 129.69Z'
          fill='#EAAF23'
        />
        <path
          d='M109.612 142.482L103.328 134.43L123.335 118.802C125.435 117.162 128.884 117.587 130.502 119.685C132.119 121.783 131.716 125.233 129.619 126.855L109.612 142.482Z'
          fill='#6466EB'
        />
        <path
          d='M128.491 166.696L122.207 158.643L142.214 143.016C144.314 141.376 147.763 141.801 149.381 143.899C150.998 145.997 150.595 149.447 148.498 151.068L128.491 166.696Z'
          fill='#6466EB'
        />
        <path
          d='M65.3286 206.172L49.7099 186.156L64.202 171.47L83.0854 195.669L65.3286 206.172Z'
          fill='#DDD9EE'
        />
        <path
          d='M125.093 178.969L90.5866 134.76C88.9474 132.66 89.3719 129.212 91.4694 127.59C93.5668 125.969 97.0183 126.375 98.6357 128.473L133.141 172.691C134.78 174.792 134.355 178.24 132.258 179.861C130.127 181.757 126.942 181.364 125.092 178.978'
          fill='#6466EB'
        />
        <path
          d='M81.9244 194.173L65.3713 172.961C63.7916 170.919 63.0353 168.358 63.252 165.785C63.4687 163.211 64.6425 160.813 66.5415 159.064L90.5872 134.76L125.092 178.978L95.6836 196.409C93.5268 197.828 90.9164 198.386 88.3687 197.972C85.821 197.558 83.5213 196.202 81.9244 194.173'
          fill='#6466EB'
        />
        <path
          d='M89.6613 175.154L85.6968 170.077C84.6971 168.83 83.9572 167.395 83.5207 165.857C83.0842 164.319 82.96 162.709 83.1554 161.122C83.3508 159.535 83.8618 158.003 84.6583 156.617C85.4548 155.231 86.5207 154.018 87.7932 153.051L98.2624 144.885L117.453 168.849L107.003 177.015C101.33 181.446 93.8833 180.529 89.687 175.151'
          fill='#63D3FD'
        />
        <path
          d='M134.33 123.669C134.205 124.714 133.867 125.723 133.337 126.633C132.807 127.543 132.096 128.335 131.249 128.96L111.239 144.584C110.968 144.805 110.656 144.969 110.32 145.066C109.984 145.163 109.633 145.191 109.286 145.148C108.939 145.106 108.605 144.993 108.302 144.818C108 144.642 107.737 144.407 107.528 144.127L101.244 136.074C101.022 135.803 100.856 135.49 100.758 135.153C100.66 134.816 100.632 134.463 100.674 134.115C100.717 133.767 100.831 133.431 101.007 133.128C101.184 132.825 101.421 132.561 101.703 132.352L121.71 116.722C123.375 115.432 125.465 114.819 127.562 115.003C128.607 115.128 129.615 115.466 130.525 115.996C131.435 116.526 132.226 117.237 132.851 118.085C134.254 119.887 134.592 121.539 134.33 123.669ZM110.066 138.772L127.984 124.776C128.88 124.076 129.077 122.48 128.41 121.319C128.239 121.062 128.019 120.841 127.762 120.67C127.505 120.5 127.216 120.382 126.913 120.325C126.38 120.259 125.55 120.426 125.219 120.926L107.301 134.922L110.066 138.772Z'
          fill='black'
        />
        <path
          d='M153.214 147.854C153.092 148.904 152.755 149.918 152.225 150.833C151.694 151.747 150.981 152.543 150.131 153.17L130.112 168.783C129.842 169.006 129.528 169.172 129.192 169.27C128.855 169.368 128.502 169.397 128.154 169.354C127.806 169.311 127.47 169.198 127.167 169.021C126.864 168.844 126.601 168.607 126.392 168.325L120.109 160.273C119.886 160.002 119.72 159.689 119.622 159.352C119.524 159.015 119.496 158.661 119.538 158.313C119.581 157.965 119.695 157.629 119.871 157.326C120.048 157.023 120.285 156.759 120.567 156.551L140.572 140.917C142.245 139.625 144.346 139.016 146.45 139.21C147.495 139.336 148.504 139.674 149.413 140.204C150.323 140.734 151.114 141.444 151.739 142.292C153.139 144.075 153.475 145.737 153.214 147.854ZM128.951 162.957L146.869 148.961C147.765 148.262 147.961 146.666 147.295 145.504C147.124 145.247 146.904 145.026 146.647 144.855C146.39 144.685 146.101 144.567 145.798 144.51C145.265 144.444 144.434 144.611 144.104 145.111L126.186 159.107L128.951 162.957Z'
          fill='black'
        />
        <path
          d='M65.096 186.162C65.0633 186.515 64.9544 186.856 64.7769 187.162C64.5994 187.468 64.3575 187.731 64.0681 187.934C63.7967 188.157 63.4832 188.322 63.1463 188.419C62.8094 188.517 62.4563 188.545 62.1082 188.502C61.7602 188.459 61.4244 188.346 61.1213 188.17C60.8182 187.993 60.554 187.757 60.3447 187.476L55.2179 180.895C55.0025 180.62 54.8433 180.306 54.7493 179.97C54.6553 179.634 54.6285 179.282 54.6703 178.936C54.7121 178.589 54.8217 178.254 54.9929 177.95C55.1641 177.646 55.3935 177.378 55.668 177.163C55.9425 176.947 56.2568 176.788 56.5928 176.694C56.9289 176.6 57.2801 176.573 57.6266 176.615C57.973 176.656 58.3078 176.766 58.6119 176.937C58.9159 177.108 59.1833 177.338 59.3987 177.613L64.5279 184.2C64.948 184.764 65.1498 185.461 65.096 186.162Z'
          fill='black'
        />
        <path
          d='M76.4789 201.058C76.4466 201.411 76.3379 201.752 76.1603 202.058C75.9828 202.364 75.7407 202.627 75.4509 202.83C74.5224 203.795 72.6623 203.566 71.7276 202.372L66.6066 195.795C66.1744 195.238 65.9809 194.532 66.0688 193.833C66.1567 193.133 66.5188 192.497 67.0754 192.065C67.632 191.632 68.3375 191.439 69.0367 191.526C69.7359 191.614 70.3716 191.977 70.8039 192.533L75.9178 199.09C76.4164 199.433 76.5768 200.263 76.4789 201.058Z'
          fill='black'
        />
        <path
          d='M85.745 195.996C85.6468 196.794 85.0157 197.526 84.4177 197.991L66.6761 208.498C65.5153 209.166 63.9201 208.969 63.2533 207.808L47.6162 187.787C47.1854 187.298 46.9613 186.661 46.9913 186.01C47.0213 185.359 47.303 184.745 47.777 184.297L62.2722 169.611C62.8675 169.144 63.466 168.676 64.273 168.776C65.0614 168.852 65.7892 169.232 66.3028 169.836L85.1769 194.034C85.4074 194.301 85.5775 194.614 85.6755 194.953C85.7736 195.291 85.7973 195.647 85.745 195.996V195.996ZM66.0182 202.743L79.1321 194.909L63.9805 175.493L53.1966 186.316L66.0182 202.743Z'
          fill='black'
        />
        <path
          d='M136.948 176.916C136.823 177.962 136.485 178.971 135.955 179.881C135.425 180.791 134.715 181.582 133.867 182.207C132.194 183.5 130.094 184.11 127.989 183.914C126.944 183.788 125.935 183.45 125.026 182.92C124.116 182.39 123.325 181.68 122.7 180.832L88.2073 136.618C85.642 133.331 86.2641 128.278 89.5507 125.708C91.224 124.416 93.3244 123.806 95.4289 124.002C96.474 124.127 97.4824 124.465 98.3919 124.995C99.3015 125.525 100.093 126.236 100.718 127.083L135.243 171.035C135.913 171.847 136.41 172.788 136.703 173.799C136.997 174.81 137.08 175.871 136.948 176.916V176.916ZM92.1101 131.15C92.0449 131.68 92.2115 132.51 92.7112 132.844L127.18 177.333C127.35 177.59 127.57 177.811 127.828 177.982C128.085 178.153 128.373 178.27 128.677 178.327C129.21 178.393 130.04 178.226 130.37 177.726C131.266 177.026 131.463 175.43 130.796 174.269L96.2906 130.054C96.1198 129.797 95.8994 129.576 95.6424 129.406C95.3853 129.235 95.0967 129.117 94.7935 129.059C94.2607 128.994 93.4305 129.16 93.1 129.661C92.7695 130.161 92.1746 130.626 92.1101 131.15Z'
          fill='black'
        />
        <path
          d='M127.75 179.294C127.619 180.357 127.021 180.824 126.426 181.289L97.0101 198.729C94.3041 200.497 91.0303 201.178 87.8439 200.636C84.6575 200.093 81.7935 198.367 79.8251 195.802L63.2903 174.594C62.2473 173.264 61.4794 171.738 61.0313 170.108C60.5831 168.477 60.4637 166.773 60.68 165.096C61.073 161.904 62.4955 159.111 64.8857 157.244L88.9325 132.93C89.5306 132.466 90.1259 131.998 90.9329 132.098C91.2853 132.129 91.6264 132.238 91.9324 132.415C92.2384 132.593 92.5018 132.836 92.7041 133.126L127.182 177.331C127.413 177.598 127.584 177.911 127.682 178.25C127.78 178.589 127.803 178.945 127.75 179.294ZM65.9901 165.744C65.8539 166.725 65.9137 167.724 66.166 168.682C66.4183 169.641 66.8581 170.539 67.4599 171.326L84.013 192.539C85.187 194.022 86.8807 195.003 88.7509 195.284C90.621 195.564 92.5278 195.122 94.0849 194.049L121.137 178.207L90.3643 138.771L68.4724 160.92C67.0732 162.162 66.188 163.882 65.9901 165.744V165.744Z'
          fill='black'
        />
        <path
          d='M120.089 169.17C120.056 169.522 119.948 169.863 119.77 170.169C119.593 170.475 119.351 170.739 119.061 170.942L108.599 179.096C102.03 184.23 92.7233 183.084 87.8606 176.542L83.9179 171.467C81.5299 168.367 80.4592 164.449 80.9375 160.564C81.4159 156.679 83.4049 153.138 86.4737 150.71L96.927 142.545C97.1984 142.322 97.5119 142.158 97.8488 142.06C98.1857 141.962 98.5388 141.934 98.8869 141.977C99.2349 142.02 99.5707 142.133 99.8738 142.309C100.177 142.486 100.441 142.722 100.65 143.003L119.52 167.21C119.941 167.773 120.143 168.469 120.089 169.17ZM85.9509 161.455C85.6561 163.849 86.4239 166.374 87.79 168.432L91.7541 173.512C92.5115 174.503 93.459 175.333 94.5411 175.954C95.6232 176.574 96.8182 176.972 98.0561 177.125C99.2939 177.277 100.55 177.181 101.75 176.842C102.95 176.502 104.071 175.927 105.046 175.149L113.41 168.619L97.8039 148.602L89.4429 155.135C87.6029 156.798 86.2783 158.796 85.9509 161.455Z'
          fill='black'
        />
        <path
          d='M301.218 69.8954L307.899 94.395L288.864 102.342L280.786 72.7211L301.218 69.8954Z'
          fill='#DDD9EE'
        />
        <path
          d='M235.588 71.8849L250.362 126.006C251.063 128.577 249.341 131.594 246.78 132.279C244.22 132.964 241.193 131.255 240.511 128.695L225.731 74.5846C225.03 72.0133 226.752 68.997 229.312 68.312C232.01 67.3869 234.796 68.9794 235.582 71.8958'
          fill='#6466EB'
        />
        <path
          d='M281.281 74.5468L288.362 100.512C289.031 103.006 288.74 105.661 287.547 107.951C286.354 110.241 284.346 112 281.919 112.879L250.361 126.006L235.587 71.8844L269.445 67.1621C271.981 66.6869 274.604 67.1814 276.794 68.548C278.985 69.9145 280.583 72.0535 281.273 74.5421'
          fill='#6466EB'
        />
        <path
          d='M266.801 89.1037L268.483 95.321C268.924 96.858 269.052 98.4677 268.861 100.055C268.67 101.643 268.163 103.176 267.371 104.564C266.578 105.952 265.516 107.168 264.246 108.139C262.976 109.11 261.525 109.816 259.978 110.217L246.821 113.808L238.743 84.1876L251.913 80.5929C253.449 80.152 255.058 80.0235 256.644 80.2147C258.231 80.4059 259.763 80.9131 261.151 81.7063C262.538 82.4996 263.753 83.5628 264.724 84.8332C265.694 86.1037 266.4 87.5557 266.801 89.1037Z'
          fill='#63D3FD'
        />
        <path
          d='M300.791 99.2825C300.625 99.5948 300.393 99.8672 300.112 100.081C299.83 100.295 299.505 100.444 299.159 100.52C298.823 100.62 298.47 100.651 298.122 100.611C297.774 100.571 297.437 100.46 297.133 100.286C296.828 100.112 296.562 99.8781 296.351 99.5982C296.139 99.3183 295.987 98.9984 295.902 98.6579L293.708 90.6142C293.605 90.2745 293.571 89.9176 293.607 89.5644C293.644 89.2113 293.751 88.8691 293.923 88.5582C294.094 88.2473 294.326 87.9739 294.605 87.7544C294.884 87.5348 295.204 87.3734 295.546 87.2798C295.889 87.1862 296.246 87.1623 296.598 87.2095C296.95 87.2567 297.288 87.374 297.594 87.5545C297.9 87.735 298.166 87.975 298.377 88.2604C298.588 88.5457 298.74 88.8705 298.824 89.2156L301.02 97.2609C301.189 97.9404 301.108 98.6581 300.791 99.2825V99.2825Z'
          fill='black'
        />
        <path
          d='M295.671 81.2285C295.506 81.541 295.274 81.8137 294.992 82.0274C294.71 82.2411 294.385 82.3907 294.039 82.4659C293.703 82.5661 293.35 82.597 293.002 82.5569C292.654 82.5167 292.317 82.4062 292.013 82.2322C291.708 82.0581 291.442 81.8241 291.231 81.5442C291.019 81.2643 290.867 80.9444 290.782 80.6039L288.59 72.5574C288.428 71.8862 288.534 71.1783 288.885 70.584C289.237 69.9897 289.806 69.5557 290.471 69.3743C291.137 69.1929 291.847 69.2782 292.452 69.6122C293.056 69.9462 293.506 70.5026 293.706 71.1632L295.9 79.2069C296.01 79.5404 296.047 79.8938 296.008 80.2428C295.968 80.5918 295.853 80.9281 295.671 81.2285V81.2285Z'
          fill='black'
        />
        <path
          d='M310.222 95.7244C309.891 96.1908 309.459 96.5769 308.959 96.8543L289.921 104.8C289.191 105.001 288.459 105.201 287.753 104.797C287.055 104.422 286.531 103.79 286.29 103.035L278.212 73.4142C278.036 72.6414 278.167 71.8305 278.578 71.1526C278.771 70.8263 279.043 70.5541 279.369 70.3612C279.696 70.1683 280.065 70.061 280.444 70.0492L300.877 67.2207C302.205 67.0539 303.601 67.8517 303.768 69.1805L310.448 93.6829C310.625 94.3681 310.545 95.0946 310.222 95.7244V95.7244ZM290.618 98.7157L304.739 92.8975L299.254 72.7861L284.136 74.9488L290.618 98.7157Z'
          fill='black'
        />
        <path
          d='M252.217 131.407C251.698 132.323 250.997 133.123 250.157 133.758C249.317 134.393 248.356 134.848 247.334 135.097C245.292 135.643 243.119 135.394 241.253 134.4C240.337 133.88 239.538 133.179 238.903 132.338C238.269 131.498 237.813 130.536 237.564 129.513L222.804 75.3811C221.707 71.3571 224.231 66.9363 228.254 65.8359C232.277 64.7354 236.692 67.2667 237.794 71.2895L252.921 125.298C253.229 126.31 253.325 127.375 253.204 128.426C253.083 129.477 252.747 130.491 252.217 131.407V131.407ZM228.518 71.8577C228.253 72.3213 228.086 73.1519 228.419 73.6529L243.046 128.005C243.104 128.308 243.222 128.597 243.393 128.854C243.564 129.111 243.785 129.331 244.043 129.501C244.509 129.768 245.339 129.935 245.837 129.601C246.933 129.301 247.731 127.905 247.564 126.576L233.036 72.5883C232.639 71.1242 231.475 70.4589 230.013 70.8603C229.71 70.9178 229.421 71.0358 229.164 71.2071C228.907 71.3785 228.688 71.5997 228.518 71.8577V71.8577Z'
          fill='black'
        />
        <path
          d='M289.658 109.588C288.063 112.38 285.673 114.407 282.748 115.205L251.185 128.34C250.454 128.537 249.724 128.739 249.018 128.335C248.324 127.96 247.802 127.33 247.564 126.577L233.036 72.5895C232.859 71.8167 232.99 71.0053 233.401 70.3279C233.594 70.0021 233.866 69.7301 234.192 69.537C234.518 69.3439 234.887 69.2359 235.265 69.2229L269.123 64.5006C272.301 63.9149 275.584 64.5518 278.313 66.2839C281.043 68.0159 283.018 70.7153 283.844 73.842L290.924 99.803C291.372 101.436 291.491 103.143 291.274 104.823C291.057 106.503 290.507 108.122 289.658 109.588V109.588ZM252.113 122.389L280.854 110.418C284.773 108.955 286.801 104.865 285.804 101.21L278.723 75.2444C278.212 73.4226 277.029 71.8631 275.412 70.8817C273.795 69.9004 271.866 69.5707 270.015 69.9594L238.949 74.1179L252.113 122.389Z'
          fill='black'
        />
        <path
          d='M269.589 105.832C267.596 109.323 264.473 111.55 260.686 112.781L247.529 116.372C247.192 116.475 246.837 116.507 246.487 116.467C246.136 116.428 245.798 116.317 245.491 116.142C245.185 115.967 244.918 115.731 244.706 115.45C244.494 115.168 244.342 114.845 244.258 114.503L236.18 84.882C236.069 84.546 236.032 84.1897 236.073 83.8382C236.114 83.4866 236.231 83.1482 236.417 82.8467C236.582 82.5342 236.814 82.2615 237.096 82.0478C237.378 81.8341 237.703 81.6845 238.049 81.6093L251.219 78.0258C258.894 75.9309 267.036 80.5853 269.362 88.3971L271.047 94.6273C272.145 98.6513 271.588 102.33 269.589 105.832ZM248.822 110.322L259.422 107.428C264.171 106.132 267.228 100.778 265.932 96.0259L264.236 89.8078C262.842 84.6883 257.723 81.762 252.84 83.293L242.242 86.1879L248.822 110.322Z'
          fill='black'
        />
      </g>
      <path
        d='M0 10C0 4.47715 4.47715 0 10 0H349C354.523 0 359 4.47715 359 10V251H0V10Z'
        fill='#E4ECFF'
      />
      <mask
        id='mask1'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='359'
        height='251'
      >
        <path
          d='M0 10C0 4.47715 4.47715 0 10 0H349C354.523 0 359 4.47715 359 10V251H0V10Z'
          fill='#F5F8FF'
        />
      </mask>
      <g mask='url(#mask1)'>
        <path
          opacity='0.5'
          d='M413.254 223.123C338.564 256.866 31.5 2.75955 -27.0937 14.7505C7.51132 -8.79772 78.3172 -55.743 84.7011 -55.138C91.085 -54.533 361.861 -34.7375 495.028 -8.26936C493.616 46.5164 473.007 196.129 413.254 223.123Z'
          fill='#F5F8FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M317.493 83.8112C311.629 85.0507 306.354 86.1656 302.311 86.711L301 76.975C304.722 76.473 309.352 75.492 314.871 74.3223C318.333 73.5886 322.145 72.7807 326.304 71.9703C336.807 69.9231 348.967 67.9928 361.355 67.78C386.128 67.3546 412.778 73.8453 428.603 100.968L420.125 105.922C406.865 83.1953 384.608 77.2062 361.522 77.6026C349.981 77.8008 338.473 79.6068 328.179 81.6131C324.502 82.3297 320.898 83.0915 317.493 83.8112Z'
          fill='#F3E4F7'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M298.022 92.3347L295.378 72.688L300.332 72.0199C303.826 71.5486 308.245 70.6153 313.834 69.431L313.847 69.4281C317.303 68.6958 321.148 67.8809 325.347 67.0626C335.957 64.9946 348.449 63.0009 361.269 62.7808C386.896 62.3407 415.788 69.0838 432.921 98.4478L435.439 102.764L418.327 112.763L415.806 108.442C403.849 87.9489 383.826 82.2203 361.607 82.6019C350.498 82.7927 339.322 84.5353 329.135 86.5207C325.503 87.2287 321.937 87.9823 318.527 88.7031L318.357 88.739C312.626 89.9502 307.207 91.0958 302.978 91.6661L298.022 92.3347ZM301 76.975C304.722 76.4731 309.351 75.492 314.87 74.3223C318.332 73.5886 322.145 72.7807 326.303 71.9703C336.807 69.9231 348.966 67.9928 361.354 67.7801C384.784 67.3777 409.893 73.1618 425.935 96.7355C426.836 98.0595 427.708 99.4396 428.55 100.878C428.568 100.908 428.585 100.938 428.603 100.968L420.125 105.922C420.11 105.896 420.095 105.871 420.08 105.845C406.814 83.1818 384.582 77.2066 361.522 77.6026C349.98 77.8008 338.472 79.6068 328.179 81.6131C324.502 82.3298 320.898 83.0915 317.493 83.8112C313.788 84.5943 310.318 85.3276 307.246 85.8991C305.455 86.2321 303.799 86.5102 302.31 86.711L301 76.975Z'
          fill='#ACA0ED'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.5008 214.075C27.3226 209.61 43.4456 202.349 54.9922 191.158L61.8219 198.214C48.6405 210.99 30.7784 218.842 14.1644 223.53C-2.49423 228.232 -18.3748 229.886 -28.059 229.956L-28.1281 220.132C-19.282 220.069 -4.27633 218.528 11.5008 214.075Z'
          fill='#F3E4F7'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M55.1032 184.085L68.8876 198.327L65.2998 201.804C51.2861 215.387 32.5383 223.54 15.5205 228.342C-1.58732 233.17 -17.9138 234.883 -28.025 234.956L-33.0256 234.992L-33.1652 215.168L-28.166 215.132C-19.747 215.072 -5.18714 213.589 10.1408 209.263C25.5588 204.912 40.7961 197.952 51.5103 187.567L55.1032 184.085ZM58.1154 201.576C45.4029 212.391 29.2669 219.268 14.1625 223.53C0.119505 227.493 -13.3706 229.292 -23.0619 229.8C-24.867 229.895 -26.5403 229.945 -28.0609 229.956L-28.1301 220.132C-19.284 220.069 -4.27827 218.528 11.4988 214.075C27.2926 209.618 43.3866 202.375 54.9289 191.217C54.9494 191.197 54.9698 191.178 54.9902 191.158L61.8199 198.214C61.7962 198.237 61.7726 198.26 61.7489 198.283C60.5734 199.419 59.3608 200.516 58.1154 201.576Z'
          fill='#ACA0ED'
        />
        <path
          d='M141.937 90.2873L135.744 81.4341L120.995 104.51L120.526 104.315L131.346 62.8215L131.798 62.9339L137.998 71.7266L152.786 48.5187L153.214 48.7138L142.343 90.3639L141.937 90.2873Z'
          fill='#EAAF23'
        />
        <path
          d='M186.891 178.359L193.504 169.813L167.109 162.564L167.156 162.058L209.963 160.088L209.99 160.553L203.437 169.087L229.969 176.335L229.91 176.801L186.938 178.769L186.891 178.359Z'
          fill='#EAAF23'
        />
        <path
          d='M175.786 129.69L177.703 117.767L147.933 124.793L147.709 124.271L188.647 99.4127L188.922 99.8544L187.058 111.735L216.961 104.633L217.153 105.123L176.052 130.067L175.786 129.69Z'
          fill='#EAAF23'
        />
        <path
          d='M109.612 142.482L103.328 134.43L123.335 118.802C125.435 117.162 128.884 117.587 130.502 119.685C132.119 121.783 131.716 125.233 129.619 126.855L109.612 142.482Z'
          fill='#ACA0ED'
        />
        <path
          d='M128.491 166.696L122.207 158.643L142.214 143.016C144.314 141.376 147.763 141.801 149.381 143.899C150.998 145.997 150.595 149.447 148.498 151.068L128.491 166.696Z'
          fill='#ACA0ED'
        />
        <path
          d='M65.3286 206.172L49.7099 186.156L64.202 171.47L83.0854 195.669L65.3286 206.172Z'
          fill='#F3E4F7'
          stroke='#ACA0ED'
          strokeWidth='4'
        />
        <path
          d='M125.093 178.969L90.5866 134.76C88.9474 132.66 89.3719 129.212 91.4694 127.59C93.5668 125.969 97.0183 126.375 98.6357 128.473L133.141 172.691C134.78 174.792 134.355 178.24 132.258 179.861C130.127 181.757 126.942 181.364 125.092 178.978'
          fill='#ACA0ED'
        />
        <path
          d='M81.9244 194.173L65.3713 172.961C63.7916 170.919 63.0353 168.358 63.252 165.785C63.4687 163.211 64.6425 160.813 66.5415 159.064L90.5872 134.76L125.092 178.978L95.6836 196.409C93.5268 197.828 90.9164 198.386 88.3687 197.972C85.821 197.558 83.5213 196.202 81.9244 194.173'
          fill='#ACA0ED'
        />
        <path
          d='M89.6613 175.154L85.6968 170.077C84.6971 168.83 83.9572 167.395 83.5207 165.857C83.0842 164.319 82.96 162.709 83.1554 161.122C83.3508 159.535 83.8618 158.003 84.6583 156.617C85.4548 155.231 86.5207 154.018 87.7932 153.051L98.2624 144.885L117.453 168.849L107.003 177.015C101.33 181.446 93.8833 180.529 89.687 175.151'
          fill='#F3E4F7'
        />
        <path
          d='M65.096 186.162C65.0633 186.515 64.9544 186.856 64.7769 187.162C64.5994 187.468 64.3575 187.731 64.0681 187.934C63.7967 188.157 63.4832 188.322 63.1463 188.419C62.8094 188.517 62.4563 188.545 62.1082 188.502C61.7602 188.459 61.4244 188.346 61.1213 188.17C60.8182 187.993 60.554 187.757 60.3447 187.476L55.2179 180.895C55.0025 180.62 54.8433 180.306 54.7493 179.97C54.6553 179.634 54.6285 179.282 54.6703 178.936C54.7121 178.589 54.8217 178.254 54.9929 177.95C55.1641 177.646 55.3935 177.378 55.668 177.163C55.9425 176.947 56.2568 176.788 56.5928 176.694C56.9289 176.6 57.2801 176.573 57.6266 176.615C57.973 176.656 58.3078 176.766 58.6119 176.937C58.9159 177.108 59.1833 177.338 59.3987 177.613L64.5279 184.2C64.948 184.764 65.1498 185.461 65.096 186.162Z'
          fill='#ACA0ED'
        />
        <path
          d='M76.4789 201.058C76.4466 201.411 76.3379 201.752 76.1603 202.058C75.9828 202.364 75.7407 202.627 75.4509 202.83C74.5224 203.795 72.6623 203.566 71.7276 202.372L66.6066 195.795C66.1744 195.238 65.9809 194.532 66.0688 193.833C66.1567 193.133 66.5188 192.497 67.0754 192.065C67.632 191.632 68.3375 191.439 69.0367 191.526C69.7359 191.614 70.3716 191.977 70.8039 192.533L75.9178 199.09C76.4164 199.433 76.5768 200.263 76.4789 201.058Z'
          fill='#ACA0ED'
        />
        <path
          d='M301.214 69.8954L307.895 94.395L288.86 102.342L280.782 72.7211L301.214 69.8954Z'
          fill='#F3E4F7'
          stroke='#ACA0ED'
          strokeWidth='4'
        />
        <path
          d='M235.588 71.8849L250.362 126.006C251.063 128.577 249.341 131.594 246.78 132.279C244.22 132.964 241.193 131.255 240.511 128.695L225.731 74.5846C225.03 72.0133 226.752 68.997 229.312 68.312C232.01 67.3869 234.796 68.9794 235.582 71.8958'
          fill='#ACA0ED'
        />
        <path
          d='M281.281 74.5468L288.362 100.512C289.031 103.006 288.74 105.661 287.547 107.951C286.354 110.241 284.346 112 281.919 112.879L250.361 126.006L235.587 71.8844L269.445 67.1621C271.981 66.6869 274.604 67.1814 276.794 68.548C278.985 69.9145 280.583 72.0535 281.273 74.5421'
          fill='#ACA0ED'
        />
        <path
          d='M266.801 89.1037L268.483 95.321C268.924 96.858 269.052 98.4677 268.861 100.055C268.67 101.643 268.163 103.176 267.371 104.564C266.578 105.952 265.516 107.168 264.246 108.139C262.976 109.11 261.525 109.816 259.978 110.217L246.821 113.808L238.743 84.1876L251.913 80.5929C253.449 80.152 255.058 80.0235 256.644 80.2147C258.231 80.4059 259.763 80.9131 261.151 81.7063C262.538 82.4996 263.753 83.5628 264.724 84.8332C265.694 86.1037 266.4 87.5557 266.801 89.1037Z'
          fill='#F3E4F7'
        />
        <path
          d='M301.235 98.8324C301.082 99.1228 300.861 99.373 300.589 99.5654C300.317 99.7579 300 99.8881 299.659 99.9471C299.329 100.03 298.98 100.046 298.632 99.9949C298.285 99.9439 297.946 99.8264 297.637 99.6498C297.328 99.4732 297.055 99.241 296.835 98.9674C296.614 98.6937 296.451 98.3842 296.354 98.0576L293.876 90.3365C293.761 90.0101 293.714 89.6698 293.737 89.3357C293.761 89.0017 293.854 88.6807 294.013 88.3918C294.172 88.1028 294.392 87.8518 294.661 87.6536C294.93 87.4554 295.242 87.3141 295.579 87.2379C295.915 87.1617 296.27 87.1523 296.621 87.2102C296.972 87.268 297.313 87.392 297.623 87.5748C297.934 87.7576 298.207 87.9954 298.428 88.2743C298.648 88.5531 298.811 88.8673 298.907 89.1981L301.387 96.9209C301.581 97.5725 301.527 98.2511 301.235 98.8324V98.8324Z'
          fill='#ACA0ED'
        />
        <path
          d='M295.668 81.2285C295.502 81.541 295.27 81.8137 294.988 82.0274C294.706 82.2411 294.381 82.3907 294.035 82.4659C293.699 82.5661 293.347 82.597 292.998 82.5569C292.65 82.5167 292.313 82.4062 292.009 82.2322C291.704 82.0581 291.438 81.8241 291.227 81.5442C291.015 81.2643 290.863 80.9444 290.778 80.6039L288.586 72.5574C288.424 71.8862 288.53 71.1783 288.881 70.584C289.233 69.9897 289.802 69.5557 290.467 69.3743C291.133 69.1929 291.844 69.2782 292.448 69.6122C293.052 69.9462 293.502 70.5026 293.703 71.1632L295.896 79.2069C296.007 79.5404 296.043 79.8938 296.004 80.2428C295.964 80.5918 295.85 80.9281 295.668 81.2285V81.2285Z'
          fill='#ACA0ED'
        />
      </g>
    </svg>
  );
}
