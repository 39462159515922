import { Box } from '@/components/layout/flexbox';
import { SUBTEXT_COLOR } from '@/css/constants';

export default function Subtext(props) {
  return (
    <Box
      {...props}
      style={{ color: SUBTEXT_COLOR, fontSize: 14, ...props.style }}
    >
      {props.children}
    </Box>
  );
}
