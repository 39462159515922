import { useState } from 'react';

import { Icon } from '@blueprintjs/core';

import FlexibleButton from '@/components/pieces/button/FlexibleButton';
import { MIDTEXT_COLOR } from '@/css/constants';

export function AddButton({ isAdded, isLoading, onAdd, onRemove }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <FlexibleButton
      fill={true}
      loading={isLoading}
      style={{
        background: isAdded ? (isHovered ? '#DB3737' : '#27AE60') : '#F5F5F5',
        color: isAdded ? '#FFF' : MIDTEXT_COLOR,
      }}
      onClick={() => {
        if (isAdded) {
          onRemove();
        } else {
          onAdd();
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      rightIcon={
        <Icon
          icon={isAdded ? (isHovered ? 'cross' : 'tick') : 'plus'}
          color={isAdded ? '#FFF' : MIDTEXT_COLOR}
        />
      }
    >
      {isAdded ? (isHovered ? 'Remove' : 'Added') : 'Add'}
    </FlexibleButton>
  );
}
