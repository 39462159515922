import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from '@emotion/styled';

export default function Callout({ entry }) {
  return (
    <CalloutCard className={entry.fields.type}>
      {documentToReactComponents(entry.fields.content)}
    </CalloutCard>
  );
}

const CalloutCard = styled('aside')`
  position: relative;
  padding: 24px 32px;
  margin: 48px -32px;
  border-left: 3px solid;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;

  &.default {
    background-color: #dbeeff;
    border-color: #5092f4;

    a {
      color: #5092f4;
    }
  }

  &.warning {
    background-color: #fff0e3;
    border-color: #d07921;

    a {
      color: #d07921;
    }
  }

  &.success {
    background-color: #e1f9ee;
    border-color: #009d5c;

    a {
      color: #009d5c;
    }
  }

  a:hover {
    color: #000;
  }

  @media screen and (max-width: 1440px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
