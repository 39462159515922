import { useLazyQuery, useMutation } from '@apollo/client';

import {
  CreateEvaluationCriterionDocument,
  DeleteEvaluationCriterionDocument,
  DismissScenarioCriteriaBannerDocument,
  GetEvaluationCriteriaDocument,
  ReorderEvaluationCriteriaDocument,
  UpdateEvaluationCriterionDocument,
} from '@/graphql';

export const useDeleteEvaluationCriterion = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [deleteCriterion, { loading, data }] = useMutation(
    DeleteEvaluationCriterionDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        if (!data || !data.deleteEvaluationCriterion)
          return onError("Couldn't delete criterion");
        const { ok } = data.deleteEvaluationCriterion;
        if (ok) onCompleted();
        else onError("Couldn't delete criterion");
      },
      onError: (error) => {
        onError(error);
      },
    },
  );

  return {
    deleteCriterion: (id) => {
      deleteCriterion({ variables: { id } });
    },
    data,
    loading,
  };
};

export const useCreateEvaluationCriterion = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [createCriterion, { loading, data }] = useMutation(
    CreateEvaluationCriterionDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        if (!data || !data.createEvaluationCriterion)
          return onError('Failed to create criterion');
        const { ok } = data.createEvaluationCriterion;
        if (ok) onCompleted(data);
        else onError('Failed to create criterion');
      },
      onError: () => {
        onError('Failed to create criterion');
      },
    },
  );

  return {
    createCriterion: (input) => {
      createCriterion({ variables: input });
    },
    data,
    loading,
  };
};

export const useReorderEvaluationCriteria = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [reorderCriteria, { loading, data }] = useMutation(
    ReorderEvaluationCriteriaDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        if (!data || !data.reorderEvaluationCriteria)
          return onError('Failed to reorder criteria');
        const { ok } = data.reorderEvaluationCriteria;
        if (ok) onCompleted();
        else onError('Failed to reorder criteria');
      },
      onError: () => {
        onError('Failed to reorder criteria');
      },
    },
  );

  return {
    reorderCriteria: (input, scenarioId, roleplayId) => {
      reorderCriteria({
        variables: {
          items: input,
          scenarioId,
          roleplayId,
        },
      });
    },
    data,
    loading,
  };
};

export const useUpdateEvaluationCriterion = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [updateCriterion, { loading, data }] = useMutation(
    UpdateEvaluationCriterionDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        if (!data || !data.updateEvaluationCriterion)
          return onError('Failed to update criterion');
        const { ok } = data.updateEvaluationCriterion;
        if (ok) onCompleted(data);
        else onError('Failed to update criterion');
      },
      onError: () => {
        onError('Failed to update criterion');
      },
    },
  );

  return {
    updateCriterion: (input) => {
      updateCriterion({ variables: input });
    },
    data,
    loading,
  };
};

export const useGetEvaluationCriteria = ({ onCompleted, onError }) => {
  const [fetch, { data, loading }] = useLazyQuery(
    GetEvaluationCriteriaDocument,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data?.evaluationCriteria) {
          onCompleted(data);
        } else {
          onError('Something went wrong. Please try again.');
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );
  return {
    loading,
    data,
    fetch: (roleplayId, scenarioId, roleplaySlug) =>
      fetch({
        variables: {
          roleplayId: scenarioId ? null : roleplayId,
          scenarioId: scenarioId,
          roleplaySlug: scenarioId ? null : roleplaySlug,
        },
      }),
  };
};

export const useDismissScenarioCriteriaBanner = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [dismissScenarioCriteriaBanner, { data, loading }] = useMutation(
    DismissScenarioCriteriaBannerDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        if (!data || !data.dismissScenarioCriteriaBanner)
          return onError("Couldn't dismiss banner");
        const { ok } = data.dismissScenarioCriteriaBanner;
        if (ok) onCompleted();
        else onError("Couldn't dismiss banner");
      },
      onError: (error) => {
        onError(error);
      },
    },
  );

  return {
    dismissScenarioCriteriaBanner: (scenarioId) => {
      dismissScenarioCriteriaBanner({ variables: { scenarioId } });
    },
    data,
    loading,
  };
};
