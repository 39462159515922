import styled from '@emotion/styled';

import { Box, Flex } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import { StyledLinkBtn } from '@/components/pieces/bootstrap';

export default function ShapesCTA({
  text,
  to = '/match',
  cta = 'Find my coach',
  background = null,
}) {
  return (
    <AppContainer
      className='text-center'
      pt={80}
      pb={80}
      px={16}
      alignItems='center'
      justifyContent='center'
      background={background ? `url('${background}')}` : 'none'}
    >
      <Flex flexDirection='column' alignItems='center'>
        <Box pb={24}>
          <BigText>{text}</BigText>
        </Box>
        <StyledLinkBtn to={to}>
          <Flex py={2} fontSize={22} justifyContent='center'>
            {cta}
          </Flex>
        </StyledLinkBtn>
      </Flex>
    </AppContainer>
  );
}

const BigText = styled('h2')`
  font-size: 50px;
  font-weight: bold;

  @media screen and (max-width: ${breakpoints.ml - 1}px) {
    font-size: 36px;
  }
`;

const AppContainer = styled(Flex)<{ background?: string }>`
  background: ${(props) => props.background};
  background-position: top;
`;
