import { Button, Colors, Icon } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';

export default function Toast({ message, width, onClose }) {
  return (
    <Box margin='0 auto' width={[1, width]}>
      <Box
        m={3}
        style={{
          background:
            'linear-gradient(89.76deg, #26C6CA 0.24%, #14AAAE 99.83%)',
          color: Colors.WHITE,
          borderRadius: 6,
        }}
      >
        <Flex
          alignItems='center'
          justifyContent='space-between'
          style={{ position: 'relative' }}
        >
          <Box py={12} pl={3} pr={40}>
            {message}
          </Box>
          <Box>
            <Button
              minimal={true}
              onClick={onClose}
              icon={<Icon color={Colors.WHITE} icon='cross' />}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
