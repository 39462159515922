import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Colors, Icon } from '@blueprintjs/core';
import styled from '@emotion/styled';
import uniqueId from 'lodash/uniqueId';
import { useHotkeys } from 'react-hotkeys-hook';
import { useMediaQuery } from 'react-responsive';

import lazyFetch from '@/components/helpers/graphql/lazy-fetch';
import { isCoach } from '@/components/helpers/workspace/permissions';
import { Box, Flex } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import Clickable from '@/components/pieces/interaction/Clickable';
import GlobalSearch from '@/components/pieces/search/GlobalSearch';
import { CTA_COLOR, LIGHT_BACKGROUND } from '@/css/constants';
import { SearchPreferenceDocument, SearchPreferenceQuery } from '@/graphql';

let searchButtonTimeoutRef;

export default function SearchButton({
  disabled,
  customerId = undefined,
  forceXs = false,
  color = Colors.GRAY3,
}) {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchKey] = useState(uniqueId());
  const isSm = useMediaQuery({ query: `(max-width: ${breakpoints.lg - 1}px)` });

  const [fetch, { data: searchPreferenceData }] =
    useLazyQuery<SearchPreferenceQuery>(SearchPreferenceDocument, {
      variables: {
        customerId,
      },
    });

  useEffect(() => {
    if (!disabled) {
      if (searchButtonTimeoutRef) {
        clearTimeout(searchButtonTimeoutRef);
      }
      searchButtonTimeoutRef = lazyFetch(fetch);
      return () => clearTimeout(searchButtonTimeoutRef);
    }
  }, [disabled]);

  useEffect(() => {
    if (searchButtonTimeoutRef) {
      return () => {
        clearTimeout(searchButtonTimeoutRef);
      };
    }
  }, []);

  useHotkeys(
    'command+k, control+k',
    (e) => {
      showSearch();
      e.preventDefault();
    },
    {},
    [],
  );

  function showSearch() {
    setSearchVisible(true);
  }

  const opacity = searchVisible || disabled ? 0.3 : 1;

  return (
    <>
      {isSm || forceXs ? (
        <Clickable onClick={showSearch}>
          <Box p={3} pr={1} mr={1}>
            <Icon icon='search' color={color} size={20} style={{ opacity }} />
          </Box>
        </Clickable>
      ) : (
        <Clickable onClick={showSearch}>
          <Container
            alignItems='center'
            px={12}
            py='6px'
            justifyContent='space-between'
            style={{
              color,
              opacity,
            }}
          >
            <Flex alignItems='center'>
              <Icon icon='search' />
              <Box ml={2}>Search</Box>
            </Flex>

            <Box className='hint'>⌘K</Box>
          </Container>
        </Clickable>
      )}

      {searchPreferenceData ? (
        <GlobalSearch
          key={searchKey}
          visible={searchVisible}
          onClose={() => setSearchVisible(false)}
          searchPreference={searchPreferenceData}
          isCoach={isCoach()}
        />
      ) : null}
    </>
  );
}

const Container = styled(Flex)`
  width: 215px;
  background-color: ${LIGHT_BACKGROUND};
  border: 1px solid ${Colors.LIGHT_GRAY3};
  border-radius: 6px;
  transition: all 0.15s linear;

  :hover {
    color: ${CTA_COLOR};

    .hint {
      opacity: 1;
    }
  }

  .hint {
    opacity: 0;
    transition: all 0.15s linear;
  }
`;
