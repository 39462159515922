import { useEffect, useState } from 'react';

import { AnchorButton, Button, Classes, Intent } from '@blueprintjs/core';
import { motion } from 'framer-motion';
import { useRouteError } from 'react-router-dom';

import SEOHelmet from '@/components/helpers/seo/SEOHelmet';
import { Box, Flex } from '@/components/layout/flexbox';
import PageContainer from '@/components/pages_flexible/PageContainer';
import Error500 from '@/components/pieces/icons/svg/500';
import StyledDialog from '@/components/pieces/StyledDialog';

function GlobalErrorNotificationDialog({
  open = false,
  onClose = () => {},
  lastPageUrl = '/',
}) {
  return (
    <StyledDialog
      isOpen={open}
      lazy={true}
      title={<Box fontSize={18}>Page Error Notification</Box>}
      width='480px'
      onClose={() => {
        onClose();
      }}
    >
      <Box py={3} px={24} css={{ lineHeight: '24px' }}>
        <Box>
          <p>Sorry, there was an error loading the page.</p>
          <p>Would you like to try reloading the page?</p>
        </Box>
        <Flex
          mt={3}
          mb={'5px'}
          alignContent={'center'}
          justifyContent={'space-between'}
        >
          <AnchorButton
            intent={Intent.PRIMARY}
            large
            href={lastPageUrl}
            style={{
              borderRadius: '4px',
              backgroundColor: '#1478E3',
            }}
          >
            Yes, Reload last page
          </AnchorButton>
          <Button
            onClick={onClose}
            large
            style={{
              backgroundColor: '#0719560A',
              borderRadius: '4px',
            }}
          >
            Nevermind
          </Button>
        </Flex>
      </Box>
    </StyledDialog>
  );
}

export default function GlobalErrorPage() {
  const error = useRouteError() as Error;

  // render error without imports to prevent vite reloading in dev
  if (import.meta.env.DEV) {
    return (
      <div
        style={{
          padding: '40px',
          maxWidth: '800px',
          margin: '0 auto',
          textAlign: 'left',
          fontFamily: 'system-ui, -apple-system, sans-serif',
        }}
      >
        <h1 style={{ marginBottom: '20px', textAlign: 'center' }}>
          Something went wrong
        </h1>
        <div
          style={{
            padding: '20px',
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            marginBottom: '20px',
          }}
        >
          <h2 style={{ marginBottom: '10px', color: '#dc3545' }}>
            Error Details:
          </h2>
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              margin: '0',
            }}
          >
            {error?.message || 'Unknown error'}
            {error?.stack && '\n\n' + error.stack}
          </pre>
        </div>
        <div style={{ textAlign: 'center' }}>
          <button
            onClick={() => window.location.reload()}
            style={{
              padding: '8px 16px',
              fontSize: '16px',
              cursor: 'pointer',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
            }}
          >
            Refresh Page
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEOHelmet
        title='Whoops, something went wrong!'
        description='There is a technical issue right now. Please try again soon by refreshing the page.'
      />
      <PageContainer sizeNavBreakpoint='md' maxWidth='100%' layout='marketing'>
        <PageError />
      </PageContainer>
    </>
  );
}

export function PageError() {
  const [loaded, setLoaded] = useState(false);
  const [lastVisitedUrl, setLastVisitedUrl] = useState<string | null>(null);

  useEffect(() => {
    // Get the lastVisitedUrl from the cookie
    setLoaded(true);
    const cookies = document.cookie.split('; ');
    const lastUrlCookie = cookies.find((row) =>
      row.startsWith('lastVisitedUrl='),
    );
    if (lastUrlCookie) {
      const lastUrl = decodeURIComponent(lastUrlCookie.split('=')[1]);
      console.log('lastUrl', lastUrl);
      setLastVisitedUrl(lastUrl);
      // Clear the cookie
      document.cookie =
        'lastVisitedUrl=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  }, []);

  return (
    <>
      <Flex
        justifyContent='center'
        css={{
          minHeight: '100vh',
          paddingTop: 100,
        }}
        py={6}
      >
        <div>
          <Box
            backgroundColor='#FFF'
            className={`${Classes.ELEVATION_2} round-corners`}
            p={24}
            maxWidth='100%'
            width={400}
          >
            <Flex justifyContent='center'>
              {loaded ? (
                <motion.div
                  animate={{
                    rotate: [-2, 2, -2],
                    x: [-3, 3, -3],
                  }}
                  transition={{
                    duration: 2.5,
                    repeat: Infinity,
                    repeatType: 'reverse',
                    ease: 'easeInOut',
                  }}
                >
                  <Error500 />
                </motion.div>
              ) : (
                <div
                  css={{ width: 220, height: 220, borderRadius: '100%' }}
                  className={Classes.SKELETON}
                />
              )}
            </Flex>
            <Box mt={3} className={Classes.RUNNING_TEXT}>
              <Box mb={1}>
                <b>Whoops, something went wrong!</b>
              </Box>
              <Box>
                We apologize for the inconvenience. Please try again soon by
                refreshing the page.
              </Box>
            </Box>
          </Box>
        </div>
      </Flex>
      <GlobalErrorNotificationDialog
        open={!!lastVisitedUrl && !lastVisitedUrl.endsWith('/500')}
        onClose={() => setLastVisitedUrl(null)}
        lastPageUrl={lastVisitedUrl || '/'}
      />
    </>
  );
}
