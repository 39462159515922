import { useMutation } from '@apollo/client';
import { Button, FormGroup } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import StyledDialog from '@/components/pieces/StyledDialog';
import { EnableIndividualAccountDocument } from '@/graphql';

function EnableIndividualDialog({ visible, setVisible, afterEnable }) {
  const [enableIndividual, { loading }] = useMutation(
    EnableIndividualAccountDocument,
    {
      onCompleted: () => {
        afterEnable();
      },
    },
  );
  return (
    <StyledDialog
      isOpen={visible}
      onClose={() => setVisible(false)}
      lazy={true}
      title='Add Personal Account'
      width='480px'
    >
      <Box width={1} px={24}>
        <Box py={3}>
          The credits granted to you by your workspace will not be available in
          your personal account. You can purchase credits using your own payment
          details. Do you want to continue?
        </Box>
        <FormGroup style={{ margin: 0 }}>
          <Flex mb={3}>
            <Button
              large
              intent='primary'
              disabled={loading}
              onClick={() => {
                enableIndividual({
                  variables: {
                    hasIndividualAccount: true,
                  },
                });
              }}
            >
              {loading ? 'Sending...' : 'Confirm'}
            </Button>
          </Flex>
        </FormGroup>
      </Box>
    </StyledDialog>
  );
}

export default EnableIndividualDialog;
