import { Button, Icon } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Box, Flex } from '@/components/layout/flexbox';
import {
  buildCreateScenarioUrl,
  buildScenarioUrl,
} from '@/components/pages_logged_in/roleplays/utils';
import { ButtonVariant } from '@/components/pieces/button/FlexibleButton';
import RankIcon, { RankText } from '@/components/pieces/roleplays/RankIcon';
import { CTA_COLOR, MIDTEXT_COLOR } from '@/css/constants';
import { VisibilityScope } from '@/graphql';

interface CSSProperties {
  [key: string]: string | number;
}

export default function ScenarioMiniCard({
  scenario,
  roleplaySlug = null,
  isProgramRoleplay = false,
  showShortDesc = true,
}) {
  const link = buildScenarioUrl(roleplaySlug, scenario.slug);
  const statusText =
    isProgramRoleplay &&
    (scenario.archivedAt
      ? '(Deleted)'
      : scenario.visibilityScope === VisibilityScope.User.toLocaleUpperCase()
        ? '(Owner hided this scenario)'
        : '');

  const ContainerCard = getScenarioCardContainer(statusText ? Box : Link);
  return (
    <ContainerCard to={link} className='bas round-corners'>
      <Box>
        <Box fontWeight={500} fontSize={16} lineHeight='19px'>
          {scenario.name} <span style={{ color: 'red' }}>{statusText}</span>
        </Box>
        {scenario.shortDesc && showShortDesc ? (
          <Box
            mt={1}
            color={MIDTEXT_COLOR}
            minHeight={42}
            maxHeight={42}
            lineHeight='21px'
            display='block' // Changed from -webkit-box which isn't a standard display value
            overflow='hidden'
            fontSize={14}
            css={
              {
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              } as CSSProperties
            }
          >
            {scenario.shortDesc}
          </Box>
        ) : null}
      </Box>
      <Flex alignItems='center' height={32}>
        <Flex alignItems='center'>
          <Flex minWidth={22} alignItems='center'>
            <RankIcon rank={scenario.rank} size={22} />
          </Flex>
          <RankText ml={1} rank={scenario.rank}>
            {scenario.rank || 'Not Started'}
          </RankText>
        </Flex>
      </Flex>
    </ContainerCard>
  );
}

interface CreateScenarioCardProps {
  roleplaySlug?: string | null;
  variant?: ButtonVariant;
}

export function CreateScenarioCard({
  roleplaySlug = null,
}: CreateScenarioCardProps) {
  const ScenarioCardContainer = getScenarioCardContainer(Link);
  return (
    <ScenarioCardContainer
      to={buildCreateScenarioUrl(roleplaySlug)}
      className='bas round-corners'
    >
      <Box>
        <Box fontWeight={500} fontSize={16} lineHeight='19px'>
          Create scenario
        </Box>
        <Box
          mt={1}
          color={MIDTEXT_COLOR}
          minHeight={42}
          maxHeight={42}
          lineHeight='21px'
          fontSize={14}
          overflow='hidden'
          css={
            {
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            } as CSSProperties
          }
        >
          Practice real situations and get detailed feedback
        </Box>
      </Box>
      <Box width={'100%'}>
        <Button
          intent='primary'
          fill={true}
          icon={<Icon icon='small-plus' />}
          onClick={() => null}
          style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            borderRadius: '4px',
            lineHeight: '24px',
          }}
        >
          Create
        </Button>
      </Box>
    </ScenarioCardContainer>
  );
}

const getScenarioCardContainer = (component) => styled(component)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #fff;
  flex-direction: column;
  color: inherit;
  padding: 16px;
  min-height: 147px;
  max-height: 147px;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    border-color: ${CTA_COLOR};
    box-shadow:
      0 1px 1px rgba(24, 32, 38, 0.1),
      0 2px 6px rgba(24, 32, 38, 0.05);
  }
`;
