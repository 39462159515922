import { useRef } from 'react';

import { InputGroup } from '@blueprintjs/core';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';
import { useSearchBox } from 'react-instantsearch';

import { Box, Flex } from '@/components/layout/flexbox';

interface SearchBoxProps {
  autoFocus: boolean;
  throttledDuration?: number;
  searchPlaceholder?: string;
  query: string;
  onQueryChange: (query: string) => void;
}

export default function SearchBox({
  autoFocus,
  throttledDuration = 100,
  searchPlaceholder = 'Type to search...',
  query,
  onQueryChange,
}: SearchBoxProps) {
  const { refine } = useSearchBox();

  const throttledHandler = useRef(
    debounce((value: string) => refine(value), throttledDuration),
  );

  function handleChange(value: string) {
    onQueryChange(value);
    throttledHandler.current(value);
  }

  return (
    <Container justifyContent='space-between'>
      <Box flex={1}>
        <InputGroup
          autoFocus={autoFocus}
          type='search'
          leftIcon='search'
          placeholder={searchPlaceholder}
          fill={true}
          large={true}
          value={query}
          onChange={(e) => handleChange(e.currentTarget.value)}
        />
      </Box>
    </Container>
  );
}

const Container = styled(Flex)`
  input {
    box-shadow: none;
    border: none;
    :focus {
      border: none;
      box-shadow: none;
    }
  }
`;
