import { Button, Callout, Classes, Intent } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import CriteriaBanner from '@/components/pages_logged_in/roleplays/details/evaluation-criteria/CriteriaBanner';
import EvaluationContextButton from '@/components/pages_logged_in/roleplays/details/evaluation-criteria/EvaluationContextButton';
import { EvaluationCriteriaIcon } from '@/components/pages_logged_in/roleplays/details/evaluation-criteria/EvaluationCriteriaIcon';
import EvaluationCriteriaItem from '@/components/pages_logged_in/roleplays/details/evaluation-criteria/EvaluationCriteriaItem';
import { EvaluationCriteriaDialogs } from '@/components/pages_logged_in/roleplays/details/utils';
import CardAboveXs from '@/components/pieces/CardAboveXs';
import IconCircle from '@/components/pieces/IconCircle';
import { DARK_PURPLE, LIGHT_GRAY, MIDTEXT_COLOR } from '@/css/constants';

import { BehaviorCriterionType } from './types';

export default function EvaluationCriteriaCard({
  evaluationCriteria,
  onOpenDialog,
  onDismissBanner,
  canEdit = false,
  canDelete = false,
  isDefault,
  showBanner,
  errorMessage,
}: {
  evaluationCriteria: BehaviorCriterionType[];
  onOpenDialog: (
    dialog: EvaluationCriteriaDialogs,
    criterion?: BehaviorCriterionType,
  ) => void;
  onDismissBanner: () => void;
  canEdit: boolean;
  canDelete: boolean;
  isDefault: boolean;
  showBanner?: boolean;
  errorMessage?: string;
}) {
  const canSort = evaluationCriteria.length > 1;

  return (
    <CardAboveXs>
      <Flex fontWeight={600} fontSize={20} alignItems='center'>
        <IconCircle
          icon={<EvaluationCriteriaIcon />}
          backgroundColor={LIGHT_GRAY}
          color={DARK_PURPLE}
          size={32}
          mr={2}
        />
        <div>{`${isDefault ? 'Default' : ''} Evaluation Criteria`}</div>

        <Box
          css={{
            marginLeft: 'auto',
          }}
        >
          {canEdit && (
            <EvaluationContextButton
              isCriterion={false}
              onOpenDialog={onOpenDialog}
              criterion={undefined}
              canSort={canSort}
              canDelete={canDelete}
            />
          )}
        </Box>
      </Flex>
      <Box mt={2} className={Classes.RUNNING_TEXT} color={MIDTEXT_COLOR}>
        {isDefault
          ? "When you create new scenarios in this collection, they'll get a copy of these evaluation criteria. Changes here won't affect scenarios you've already created."
          : 'Your session will be assessed with feedback in these areas'}
      </Box>

      <Box mt={24}>
        {errorMessage && (
          <Callout intent={Intent.DANGER}>{errorMessage}</Callout>
        )}
        {showBanner && <CriteriaBanner onDismiss={onDismissBanner} />}
        {evaluationCriteria?.map((criterion, index) => (
          <EvaluationCriteriaItem
            key={index}
            criterion={criterion}
            onOpenDialog={onOpenDialog}
            canEdit={canEdit}
            canDelete={canDelete}
          />
        ))}
        {evaluationCriteria.length === 0 && canEdit && (
          <Flex flexDirection='column' alignItems='center'>
            <Box my={3}>No Criteria has been added yet.</Box>
            <Button
              intent='primary'
              onClick={() =>
                onOpenDialog(EvaluationCriteriaDialogs.AddCriterion)
              }
              icon='plus'
            >
              Add Criteria
            </Button>
          </Flex>
        )}
      </Box>
    </CardAboveXs>
  );
}
