import { useEffect, useRef } from 'react';

import styled from '@emotion/styled';

import BulletPointLine from './BulletPointLine';

/**
 * Rich Text Bullet Point Input Component Behavior
 *
 * Note: This component is pretty complicated and has a lot of behavior to implement.
 * The following is a detailed description of the expected behavior of the component.
 * If you try to change it, make sure to test all the behaviors described here!
 *
 * Visual Design and Layout:
 * - The component appears as a form field with a border and rounded corners.
 * - Each bullet point is represented by a dot followed by an expandable text area.
 * - The entire area of the component is clickable, including the icons and spaces between bullet points.
 * - There is no visible separation (margin or border) between individual bullet points.
 * - The component has a focus ring that appears when any part of it is focused.
 * - The mouse cursor changes to a text input cursor when hovering over any part of the component.
 *
 * Text Input and Editing:
 * - Users can type and edit text anywhere within an existing bullet point.
 * - Text wraps naturally within each bullet point, and the text area expands vertically to accommodate content.
 * - The cursor remains where the user places it while typing.
 * - Double-clicking selects a word, and triple-clicking selects the entire bullet point content.
 * - Copy and paste functionality works within and between bullet points.
 * - Pasting multi-line text creates new bullet points for each line.
 *
 * Navigation:
 * - Clicking anywhere within a bullet point (including the dot) places the cursor at that exact position in the text.
 * - Clicking on the dot or to its left focuses the text area and places the cursor at the beginning of that bullet point.
 * - Up and Down arrow keys navigate between bullet points only when the cursor is at the top or bottom of the current bullet point's visible area.
 * - When navigating between bullet points with arrow keys, the horizontal cursor position is maintained where possible.
 * - If the target bullet point is shorter than the current cursor position would imply, the cursor is placed at the end of the line.
 * - Left arrow key at the beginning of a line moves the cursor to the end of the previous line (if there is one).
 * - Right arrow key at the end of a line moves the cursor to the beginning of the next line (if there is one).
 *
 * Bullet Point Creation and Deletion:
 * - Pressing Enter in a non-empty bullet point splits the content at the cursor position, creating a new bullet point below.
 * - The cursor is placed at the start of the newly created bullet point after pressing Enter.
 * - Pressing Enter in an empty bullet point does not create a new bullet point.
 * - Pressing Enter in a bullet point does not create a new bullet point if the bullet point before it is empty. Instead, it moves the cursor to the already empty bullet point above.
 * - Pressing Backspace at the start of a non-empty bullet point merges it with the previous bullet point if there is one.
 * - When merging bullet points with Backspace, the cursor is placed at the merge point (between the content of the two merged lines).
 * - Pressing Backspace at the start of the first bullet point does nothing.
 * - Pressing Backspace in an empty bullet point (except the first one) removes that bullet point and moves the cursor to the end of the previous bullet point.
 * - Pressing Delete at the end of a bullet point merges it with the next bullet point, if one exists.
 *
 * Focus and Blur Behavior:
 * - Clicking outside the entire input area removes any empty bullet points, except when it would remove all bullet points.
 * - If all bullet points are empty when clicking outside, a single empty bullet point is retained.
 * - Clicking back into the input area maintains the existing bullet points.
 *
 * Undo/Redo:
 * - Standard undo/redo operations work for typing, splitting, and merging bullet points.
 *
 * Initial State and Minimum Content:
 * - The component always maintains at least one bullet point, even if it's empty.
 * - On initial render, the component starts with a single empty bullet point.
 *
 * Accessibility and Keyboard Support:
 * - The component is fully keyboard accessible, allowing navigation and editing without a mouse.
 * - Screen readers can interpret the structure and content of the bullet points.
 *
 * Performance:
 * - The component efficiently handles a large number of bullet points without significant performance degradation.
 * - Text input and navigation remain responsive even with extensive content.
 *
 * Constraints and Limitations:
 * - The component does not support nested bullet points or different bullet point styles.
 * - Rich text formatting (bold, italic, etc.) is not supported within bullet points.
 */

function BulletPointInput({ values, onChange }) {
  const inputRefs = useRef([]);
  const containerRef = useRef(null);
  const pendingFocusIndex = useRef<number | null>(null);
  const pendingCursorPosition = useRef<number | null>(null);

  useEffect(() => {
    if (pendingFocusIndex.current !== null) {
      const index = pendingFocusIndex.current;
      const position = pendingCursorPosition.current;
      pendingFocusIndex.current = null;
      pendingCursorPosition.current = null;
      requestAnimationFrame(() => {
        if (inputRefs.current[index]) {
          inputRefs.current[index]!.focus();
          if (position !== null) {
            inputRefs.current[index]!.setSelectionRange(position, position);
          } else {
            inputRefs.current[index]!.setSelectionRange(0, 0);
          }
        }
      });
    }
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        let newValues = values.filter((value) => value.trim() !== '');
        if (newValues.length === 0) {
          newValues = [''];
        }
        if (newValues.length !== values.length) {
          onChange(newValues);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [values, onChange]);

  const handleChange = (index, newValue) => {
    const newValues = [...values];
    newValues[index] = newValue;
    onChange(newValues);
  };

  const isAtTopOfTextarea = (textarea) => {
    const { selectionStart, value } = textarea;
    const textBeforeCursor = value.substring(0, selectionStart);
    const dummyElement = document.createElement('div');
    dummyElement.style.cssText = window.getComputedStyle(
      textarea,
      null,
    ).cssText;
    dummyElement.style.height = 'auto';
    dummyElement.style.position = 'absolute';
    dummyElement.style.visibility = 'hidden';
    dummyElement.textContent = textBeforeCursor;
    document.body.appendChild(dummyElement);
    const isAtTop = dummyElement.offsetHeight <= textarea.clientHeight;
    document.body.removeChild(dummyElement);
    return isAtTop;
  };

  const isAtBottomOfTextarea = (textarea) => {
    const { selectionStart, value } = textarea;
    const textAfterCursor = value.substring(selectionStart);
    const dummyElement = document.createElement('div');
    dummyElement.style.cssText = window.getComputedStyle(
      textarea,
      null,
    ).cssText;
    dummyElement.style.height = 'auto';
    dummyElement.style.position = 'absolute';
    dummyElement.style.visibility = 'hidden';
    dummyElement.textContent = textAfterCursor;
    document.body.appendChild(dummyElement);
    const isAtBottom = dummyElement.offsetHeight <= textarea.clientHeight;
    document.body.removeChild(dummyElement);
    return isAtBottom;
  };

  const getHorizontalCursorPosition = (textarea) => {
    const { selectionStart, value } = textarea;
    const textBeforeCursor = value.substring(0, selectionStart);
    const lines = textBeforeCursor.split('\n');
    return lines[lines.length - 1].length;
  };

  const setSelectionRange = (textarea, position) => {
    const lines = textarea.value.split('\n');
    let totalLength = 0;
    for (let i = 0; i < lines.length; i++) {
      if (totalLength + lines[i].length >= position) {
        const newPosition = Math.min(position - totalLength, lines[i].length);
        textarea.setSelectionRange(
          totalLength + newPosition,
          totalLength + newPosition,
        );
        return;
      }
      totalLength += lines[i].length + 1; // +1 for the newline character
    }
    textarea.setSelectionRange(textarea.value.length, textarea.value.length);
  };

  const handleKeyDown = (e, index) => {
    const textarea = inputRefs.current[index];

    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      if (e.key === 'ArrowUp' && !isAtTopOfTextarea(textarea)) {
        return; // Let default behavior handle scrolling within the textarea
      }
      if (e.key === 'ArrowDown' && !isAtBottomOfTextarea(textarea)) {
        return; // Let default behavior handle scrolling within the textarea
      }

      e.preventDefault();

      const currentPosition = getHorizontalCursorPosition(textarea);

      if (e.key === 'ArrowUp' && index > 0) {
        const prevTextarea = inputRefs.current[index - 1];
        prevTextarea.focus();
        setSelectionRange(prevTextarea, currentPosition);
      } else if (e.key === 'ArrowDown' && index < values.length - 1) {
        const nextTextarea = inputRefs.current[index + 1];
        nextTextarea.focus();
        setSelectionRange(nextTextarea, currentPosition);
      }
    } else if (e.key === 'ArrowLeft') {
      if (
        textarea.selectionStart === 0 &&
        textarea.selectionEnd === 0 &&
        index > 0
      ) {
        e.preventDefault();
        const prevTextarea = inputRefs.current[index - 1];
        prevTextarea.focus();
        prevTextarea.setSelectionRange(
          prevTextarea.value.length,
          prevTextarea.value.length,
        );
      }
    } else if (e.key === 'ArrowRight') {
      if (
        textarea.selectionStart === textarea.value.length &&
        index < values.length - 1
      ) {
        e.preventDefault();
        const nextTextarea = inputRefs.current[index + 1];
        nextTextarea.focus();
        nextTextarea.setSelectionRange(0, 0);
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      const currentValue = values[index].trim();
      const previousValue = index > 0 ? values[index - 1].trim() : null;

      if (currentValue !== '') {
        if (index > 0 && previousValue === '') {
          onChange(values);
          pendingFocusIndex.current = index - 1;
        } else {
          const cursorPosition = e.target.selectionStart;
          const beforeCursor = values[index].slice(0, cursorPosition);
          const afterCursor = values[index].slice(cursorPosition);

          const newValues = [...values];
          newValues[index] = beforeCursor;
          newValues.splice(index + 1, 0, afterCursor);

          onChange(newValues);
          pendingFocusIndex.current = index + 1;
        }
      }
    } else if (e.key === 'Backspace') {
      if (textarea.selectionStart === 0 && textarea.selectionEnd === 0) {
        e.preventDefault();
        if (index > 0) {
          const newValues = [...values];
          const currentValue = newValues[index];
          const previousValue = newValues[index - 1];
          const mergePoint = previousValue.length;
          newValues[index - 1] = previousValue + currentValue;
          newValues.splice(index, 1);
          onChange(newValues);
          pendingFocusIndex.current = index - 1;
          pendingCursorPosition.current = mergePoint;
        }
        // If it's the first bullet point, do nothing (default behavior)
      }
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const lines = pastedText.split('\n');
    if (lines.length > 1) {
      const newValues = [...values];
      newValues.splice(index, 1, ...lines);
      onChange(newValues);
    } else {
      handleChange(index, values[index] + pastedText);
    }
  };

  return (
    <StyledContainer ref={containerRef}>
      {values.map((value, index) => (
        <BulletPointLine
          key={index}
          ref={(el: any) => {
            if (el) inputRefs.current[index] = el;
          }}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          index={index}
        />
      ))}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
  border: none;
  border-radius: 6px;
  box-shadow:
    0 0 0 0 rgba(20, 120, 227, 0),
    0 0 0 0 rgba(20, 120, 227, 0),
    inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.1);
  color: #182026;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
  max-height: 400px;
  overflow-y: auto;

  &:focus-within {
    box-shadow:
      inset 0 0 0 1px #1478e3,
      0 0 0 2px rgba(20, 120, 227, 0.3),
      inset 0 1px 1px rgba(16, 22, 26, 0.1);
  }
`;

export default BulletPointInput;
