import { useEffect, useState } from 'react';

import resumesSvg from '@/../../hypetrain/static/img/resumes.svg';
import { useQuery } from '@apollo/client';
import { Button, Classes, Colors, Icon } from '@blueprintjs/core';

import EmbedSvg from '@/components/helpers/ui/EmbedSvg';
import { Box, Flex } from '@/components/layout/flexbox';
import MenuHeader from '@/components/pieces/menu/MenuHeader';
import MenuPage from '@/components/pieces/menu/MenuPage';
import Uploader from '@/components/pieces/uploader';
import { CTA_COLOR } from '@/css/constants';
import { ResumesDocument } from '@/graphql';

import DeleteConfirm from './DeleteConfirm';
import Resume from './Resume';
import ResumeList from './ResumeList';

interface MenuResumesProps {
  onClose: () => void;
  onBackLink: () => void;
  isOpen: boolean;
}

export default function MenuResumes({
  onClose,
  onBackLink,
  isOpen,
}: MenuResumesProps) {
  const [resumeToEdit, setResumeToEdit] = useState(null);
  const [replacing, setReplacing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  useEffect(() => {
    if (!resumeToEdit) {
      setReplacing(false);
      setDeleting(false);
      setAddingNew(false);
    }
  }, [resumeToEdit]);

  const { data, loading, refetch } = useQuery(ResumesDocument, {
    skip: !isOpen,
  });
  const resumes = (data && data.resumes) || [];

  if (!isOpen) {
    return null;
  }

  function handleBackLink() {
    if (replacing) {
      setReplacing(false);
    } else if (resumeToEdit || addingNew) {
      setResumeToEdit(null);
      setAddingNew(false);
    } else {
      onBackLink();
    }
  }

  function handleClose() {
    setReplacing(false);
    setResumeToEdit(null);
    onClose();
  }

  return (
    <Box width={1}>
      <MenuHeader
        onBackLink={handleBackLink}
        onClose={handleClose}
        label='Resumes'
      />

      <MenuPage visible={!resumeToEdit} slideTo='left'>
        <Box className='text-center' py={3}>
          <Flex justifyContent='center'>
            <EmbedSvg src={resumesSvg} width={169} height={145} />
          </Flex>

          <Box mt={3}>
            <b>Keep organized</b>
          </Box>
          <Box mt={2}>
            Manage the different versions of your
            <br />
            resume in one place
          </Box>
        </Box>

        {loading ? (
          <Skeleton />
        ) : resumes.length < 1 || addingNew ? (
          <Uploader
            endpoint={`${API_HOST}/resumes`}
            fieldName='resume'
            allowMultipleUploads={false}
            onUploadSuccess={(_, response) => {
              setResumeToEdit(response.body.resume);
              refetch();
            }}
          />
        ) : (
          <>
            <ResumeList resumes={resumes} onSelect={setResumeToEdit} />
            <Box my={24} px={3}>
              <Button
                icon={<Icon icon='plus' color={CTA_COLOR} size={20} />}
                large={true}
                fill={true}
                style={{
                  borderRadius: 8,
                  padding: '10px 20px',
                  color: CTA_COLOR,
                }}
                onClick={() => setAddingNew(true)}
              >
                Upload new resume
              </Button>
            </Box>
          </>
        )}
      </MenuPage>

      <MenuPage
        visible={!!resumeToEdit && !replacing}
        slideTo={replacing ? 'left' : 'right'}
      >
        <Resume
          resume={resumeToEdit}
          onUpload={() => setReplacing(true)}
          onDelete={() => setDeleting(true)}
          onSaved={() => setResumeToEdit(null)}
        />
      </MenuPage>

      <MenuPage visible={!!resumeToEdit && replacing}>
        {resumeToEdit ? (
          <Box style={{ backgroundColor: Colors.WHITE }} mt={4} py={24} px={3}>
            <Box mb={3}>
              <b>Replace file for ”{resumeToEdit.name}”</b>
            </Box>
            <Uploader
              endpoint={`${API_HOST}/resumes`}
              fieldName='resume'
              meta={{ resume_id: resumeToEdit.id }}
              allowMultipleUploads={false}
              onUploadSuccess={() => {
                setReplacing(false);
              }}
            />
          </Box>
        ) : null}
      </MenuPage>

      {deleting && resumeToEdit ? (
        <DeleteConfirm
          resume={resumeToEdit}
          onCompleted={() => setResumeToEdit(null)}
          onCancel={() => setDeleting(false)}
        />
      ) : null}
    </Box>
  );
}

function Skeleton() {
  return (
    <Box p={24} style={{ backgroundColor: Colors.WHITE }}>
      <Box className={`${Classes.SKELETON} round-corners`} my={3} height={80} />
      <Box className={`${Classes.SKELETON} round-corners`} my={3} height={80} />
      <Box className={`${Classes.SKELETON} round-corners`} my={3} height={80} />
    </Box>
  );
}
