import * as React from 'react';

import { Utils, Position } from '@blueprintjs/core';
import { DateUtils } from '@blueprintjs/datetime';
import { type DatePickerBaseProps, TimePrecision } from '@blueprintjs/datetime';
import { Placement } from '@floating-ui/react';
import { format, type Locale, parse } from 'date-fns';

import type { DateInput3Props, DateInput3PropsWithDefaults } from './props';

const INVALID_DATE = new Date(undefined!);

/**
 * Lazy-loads a date-fns locale for use in a datetime class component.
 */
export async function loadDateFnsLocale(
  localeCode: string,
): Promise<Locale | undefined> {
  try {
    const localeModule = await import(
      /* webpackChunkName: "date-fns-locale-[request]" */
      /* @vite-ignore */
      `date-fns/locale/${localeCode}/index.js`
    );
    return localeModule.default;
  } catch {
    if (!Utils.isNodeEnv('production')) {
      console.error(
        `[Blueprint] Could not load "${localeCode}" date-fns locale, please check that this locale code is supported: https://github.com/date-fns/date-fns/tree/main/src/locale`,
      );
    }
    return undefined;
  }
}

/**
 * Lazy-loads a date-fns locale for use in a datetime function component.
 */
export function useDateFnsLocale(
  localeOrCode: Locale | string | undefined,
  dateFnsLocaleLoader: DateFnsLocaleLoader = loadDateFnsLocale,
) {
  // make sure to set the locale correctly on first render if it is available
  const [locale, setLocale] = React.useState<Locale | undefined>(
    typeof localeOrCode === 'object' ? localeOrCode : undefined,
  );

  React.useEffect(() => {
    setLocale((prevLocale) => {
      if (typeof localeOrCode === 'string') {
        dateFnsLocaleLoader(localeOrCode).then(setLocale);
        // keep the current locale for now, it will be updated async
        return prevLocale;
      } else {
        return localeOrCode;
      }
    });
  }, [dateFnsLocaleLoader, localeOrCode]);
  return locale;
}

/**
 * Create a date string parser function based on a given locale.
 *
 * Prefer using user-provided `props.parseDate` and `props.dateFnsFormat` if available, otherwise fall back to
 * default formats inferred from time picker props.
 */
export function useDateParser(
  props: DateInput3Props,
  locale: Locale | undefined,
) {
  const {
    dateFnsFormat,
    invalidDateMessage,
    locale: localeFromProps,
    outOfRangeMessage,
    parseDate,
    timePickerProps,
    timePrecision,
  } = props as DateInput3PropsWithDefaults;

  return React.useCallback(
    (dateString: string): Date | null => {
      if (
        dateString === outOfRangeMessage ||
        dateString === invalidDateMessage
      ) {
        return null;
      }
      let newDate: false | Date | null = null;

      if (parseDate !== undefined) {
        // user-provided date parser
        newDate = parseDate(
          dateString,
          locale?.code ?? getLocaleCodeFromProps(localeFromProps),
        );
      } else {
        // use user-provided date-fns format or one of the default formats inferred from time picker props
        const format =
          dateFnsFormat ??
          getDefaultDateFnsFormat({ timePickerProps, timePrecision });
        newDate = getDateFnsParser(format, locale)(dateString);
      }

      return newDate === false ? INVALID_DATE : newDate;
    },
    [
      dateFnsFormat,
      invalidDateMessage,
      locale,
      localeFromProps,
      outOfRangeMessage,
      parseDate,
      timePickerProps,
      timePrecision,
    ],
  );
}

/**
 * @param localeCode - ISO 639-1 + optional country code
 * @returns date-fns `Locale` object
 */
export type DateFnsLocaleLoader = (
  localeCode: string,
) => Promise<Locale | undefined>;

export interface DateFnsLocaleProps {
  /**
   * Optional custom loader function for the date-fns `Locale` which will be used to localize the date picker.
   * This is useful in test environments or in build systems where you wish to customize module loading behavior.
   * If not provided, a default loader will be used which uses dynamic imports to load `date-fns/locale/${localeCode}`
   * modules.
   */
  dateFnsLocaleLoader?: DateFnsLocaleLoader;

  /**
   * date-fns `Locale` object or locale code string ((ISO 639-1 + optional country code) which will be used
   * to localize the date picker.
   *
   * If you provide a locale code string and receive a loading error, please make sure it is included in the list of
   * date-fns' [supported locales](https://github.com/date-fns/date-fns/tree/main/src/locale).
   * See date-fns [Locale](https://date-fns.org/v2.28.0/docs/Locale).
   *
   * @default "en-US"
   */
  locale?: Locale | string;
}

export function getLocaleCodeFromProps(
  localeOrCode: DateFnsLocaleProps['locale'],
): string | undefined {
  return typeof localeOrCode === 'string' ? localeOrCode : localeOrCode?.code;
}

export const DefaultDateFnsFormats = {
  DATE_ONLY: 'yyyy-MM-dd',
  DATE_TIME_MILLISECONDS: 'yyyy-MM-dd HH:mm:ss.SSS',
  DATE_TIME_MINUTES: 'yyyy-MM-dd HH:mm',
  DATE_TIME_SECONDS: 'yyyy-MM-dd HH:mm:ss',
};

export function getDefaultDateFnsFormat(
  props: Pick<DatePickerBaseProps, 'timePickerProps' | 'timePrecision'>,
): string {
  const hasTimePickerProps =
    props.timePickerProps !== undefined &&
    Object.keys(props.timePickerProps).length > 0;
  const precision =
    props.timePrecision ??
    props.timePickerProps?.precision ??
    // if timePickerProps is non-empty but has no precision defined, use the default value of "minute"
    (hasTimePickerProps ? TimePrecision.MINUTE : undefined);

  switch (precision) {
    case TimePrecision.MILLISECOND:
      return DefaultDateFnsFormats.DATE_TIME_MILLISECONDS;
    case TimePrecision.MINUTE:
      return DefaultDateFnsFormats.DATE_TIME_MINUTES;
    case TimePrecision.SECOND:
      return DefaultDateFnsFormats.DATE_TIME_SECONDS;
    default:
      return DefaultDateFnsFormats.DATE_ONLY;
  }
}

export function getDateFnsFormatter(
  formatStr: string,
  locale: Locale | undefined,
) {
  return (date: Date) => format(date, formatStr, { locale });
}

export function getDateFnsParser(
  formatStr: string,
  locale: Locale | undefined,
) {
  return (str: string) => parse(str, formatStr, new Date(), { locale });
}

/**
 * Create a date string parser function based on a given locale.
 *
 * Prefer using user-provided `props.formatDate` and `props.dateFnsFormat` if available, otherwise fall back to
 * default formats inferred from time picker props.
 */
export function useDateFormatter(
  props: DateInput3Props,
  locale: Locale | undefined,
) {
  const {
    dateFnsFormat,
    locale: localeFromProps,
    formatDate,
    invalidDateMessage,
    maxDate,
    minDate,
    outOfRangeMessage,
    timePickerProps,
    timePrecision,
  } = props as DateInput3PropsWithDefaults;

  return React.useCallback(
    (date: Date | undefined) => {
      if (date === undefined) {
        return '';
      }
      if (!DateUtils.isDateValid(date)) {
        return invalidDateMessage;
      } else if (DateUtils.isDayInRange(date, [minDate, maxDate])) {
        if (formatDate !== undefined) {
          // user-provided date formatter
          return formatDate(
            date,
            locale?.code ?? getLocaleCodeFromProps(localeFromProps),
          );
        } else {
          // use user-provided date-fns format or one of the default formats inferred from time picker props
          const format =
            dateFnsFormat ??
            getDefaultDateFnsFormat({ timePickerProps, timePrecision });
          return getDateFnsFormatter(format, locale)(date);
        }
      } else {
        return outOfRangeMessage;
      }
    },
    [
      dateFnsFormat,
      formatDate,
      invalidDateMessage,
      locale,
      localeFromProps,
      maxDate,
      minDate,
      outOfRangeMessage,
      timePickerProps,
      timePrecision,
    ],
  );
}

export const positionToPlacement: Record<Position, Placement> = {
  [Position.TOP]: 'top',
  [Position.TOP_LEFT]: 'top-start',
  [Position.TOP_RIGHT]: 'top-end',
  [Position.BOTTOM]: 'bottom',
  [Position.BOTTOM_LEFT]: 'bottom-start',
  [Position.BOTTOM_RIGHT]: 'bottom-end',
  [Position.LEFT]: 'left',
  [Position.RIGHT]: 'right',
  [Position.LEFT_TOP]: 'left-start',
  [Position.LEFT_BOTTOM]: 'left-end',
  [Position.RIGHT_TOP]: 'right-start',
  [Position.RIGHT_BOTTOM]: 'right-end',
};

/**
 * Returns the transform origin for a popover based on placement
 * This ensures animations start from the point closest to the trigger element
 */
export function getTransformOrigin({
  placement,
}: {
  placement: string;
}): string {
  // Default origins mapped to each placement
  const transformOrigins: Record<string, string> = {
    top: 'bottom center',
    'top-start': 'bottom left',
    'top-end': 'bottom right',
    bottom: 'top center',
    'bottom-start': 'top left',
    'bottom-end': 'top right',
    left: 'right center',
    'left-start': 'right top',
    'left-end': 'right bottom',
    right: 'left center',
    'right-start': 'left top',
    'right-end': 'left bottom',
  };

  // Return the mapped origin or a default if placement is not recognized
  return transformOrigins[placement] || 'center center';
}

export function getFallbackPlacements({
  finalPlacement,
  flipEnabled = true,
}: {
  finalPlacement: Placement | undefined;
  flipEnabled?: boolean;
}): Placement[] {
  if (!flipEnabled || !finalPlacement) return [];

  // All possible placements with alignments
  const allPlacements: Placement[] = [
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ];

  // Remove the current placement from the list of fallbacks
  return allPlacements.filter((placement) => placement !== finalPlacement);
}
