import { BRAND_PURPLE } from '@/css/constants';

export default function ArticleSvg({ size = 28 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 5.10332C23.97 4.67339 23.63 4.29346 23.17 4.22347C19.15 3.57359 16.07 4.34345 14 6.50307C11.93 4.34345 8.85 3.57359 4.84 4.22347C4.37 4.30346 4.03 4.67339 4 5.10332V20.1107C4 20.1807 4 20.2507 4.01 20.3206C4.1 20.8406 4.62 21.2005 5.16 21.1105C9.01 20.4906 11.56 21.2705 13.16 23.5701C13.18 23.5901 13.19 23.6101 13.21 23.6401C13.23 23.6601 13.25 23.6801 13.27 23.7101L13.28 23.72C13.3 23.74 13.32 23.76 13.35 23.78C13.41 23.83 13.48 23.87 13.56 23.91C13.57 23.91 13.57 23.92 13.58 23.92C13.61 23.93 13.65 23.95 13.68 23.96C13.69 23.96 13.7 23.97 13.72 23.97C13.75 23.98 13.79 23.99 13.82 23.99C13.83 23.99 13.84 23.99 13.86 24C13.91 24 13.95 24 14 24C14.04 24 14.09 24 14.13 23.99C14.14 23.99 14.16 23.99 14.17 23.98C14.2 23.97 14.23 23.97 14.27 23.96C14.28 23.96 14.3 23.95 14.31 23.95C14.34 23.94 14.38 23.93 14.41 23.91C14.42 23.91 14.43 23.9 14.44 23.9C14.51 23.87 14.57 23.83 14.63 23.79C14.64 23.79 14.64 23.78 14.65 23.78C14.67 23.76 14.69 23.75 14.71 23.73C14.72 23.72 14.73 23.7101 14.74 23.7101C14.76 23.6901 14.77 23.6801 14.79 23.6601C14.8 23.6501 14.81 23.6401 14.81 23.6301C14.82 23.6101 14.83 23.6001 14.85 23.5801C16.46 21.2805 19 20.4906 22.85 21.1205C23.39 21.2105 23.91 20.8606 24 20.3306C23.99 20.2807 24 20.2407 24 20.2007V5.10332ZM13 20.6306C11.22 19.3208 8.88 18.8009 6 19.0809V6.00316C9.26 5.63323 11.51 6.39309 13 8.35275V20.6306ZM22 19.0709C19.12 18.7909 16.78 19.3108 15 20.6206V8.34275C16.49 6.38309 18.74 5.63323 22 5.99316V19.0709Z'
        fill='#EAAF23'
      />
    </svg>
  );
}

export function ArticleFilledSvg({ size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.004 0.268215C23.556 0.352215 23.964 0.808215 24 1.32421V19.4442C24 19.4922 23.988 19.5402 24 19.6002C23.892 20.2362 23.268 20.6562 22.62 20.5482C18 19.7922 14.952 20.7402 13.02 23.5002C12.996 23.5242 12.984 23.5362 12.972 23.5602C12.972 23.5722 12.96 23.5842 12.948 23.5962L12.888 23.6562C12.876 23.6562 12.864 23.6682 12.852 23.6802C12.828 23.7042 12.804 23.7162 12.78 23.7402C12.768 23.7402 12.768 23.7522 12.756 23.7522C12.684 23.8002 12.612 23.8482 12.528 23.8842C12.516 23.8842 12.504 23.8962 12.492 23.8962C12.456 23.9202 12.408 23.9322 12.372 23.9442C12.36 23.9442 12.336 23.9562 12.324 23.9562C12.276 23.9682 12.24 23.9682 12.204 23.9802C12.192 23.9922 12.168 23.9922 12.156 23.9922C12.108 24.0042 12.048 24.0042 12 24.0042H11.832C11.808 23.9922 11.796 23.9922 11.784 23.9922C11.748 23.9922 11.7 23.9802 11.664 23.9682C11.64 23.9682 11.628 23.9562 11.616 23.9562C11.58 23.9442 11.532 23.9202 11.496 23.9082C11.484 23.9082 11.484 23.8962 11.472 23.8962C11.376 23.8482 11.292 23.8002 11.22 23.7402C11.184 23.7162 11.16 23.6922 11.136 23.6682L11.124 23.6562C11.1 23.6202 11.076 23.5962 11.052 23.5722C11.028 23.5362 11.016 23.5122 10.992 23.4882C9.072 20.7282 6.012 19.7922 1.392 20.5362C0.744 20.6442 0.12 20.2122 0.012 19.5882C0 19.5042 0 19.4202 0 19.3362V1.32421C0.036 0.808215 0.444 0.364215 1.008 0.268215C4.76761 -0.341198 7.84598 0.0895573 10.1973 1.54331C10.7222 1.86783 11 2.45934 11 3.07643V19C11 19 11 20 12 20C13 20 13 19 13 19V3.07175C13 2.45463 13.2778 1.86782 13.8028 1.54333C16.1546 0.0895618 19.235 -0.341201 23.004 0.268215Z'
        fill='#EAAF23'
      />
    </svg>
  );
}

const ACTIVE_COLORS = [BRAND_PURPLE, BRAND_PURPLE];
const INACTIVE_COLORS = ['#E2E2E2', '#BFBFBF'];

export function ArticleFilledSvg2Color({
  size = 24,
  twoColors = ACTIVE_COLORS,
}) {
  const [primaryColor, secondaryColor] = twoColors;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M23.004 0.268215C23.556 0.352215 23.964 0.808215 24 1.32421V19.4442C24 19.4922 23.988 19.5402 24 19.6002C23.892 20.2362 23.268 20.6562 22.62 20.5482C18 19.7922 14.952 20.7402 13.02 23.5002C12.996 23.5242 12.984 23.5362 12.972 23.5602C12.972 23.5722 12.96 23.5842 12.948 23.5962L12.888 23.6562C12.876 23.6562 12.864 23.6682 12.852 23.6802C12.828 23.7042 12.804 23.7162 12.78 23.7402C12.768 23.7402 12.768 23.7522 12.756 23.7522C12.684 23.8002 12.612 23.8482 12.528 23.8842C12.516 23.8842 12.504 23.8962 12.492 23.8962C12.456 23.9202 12.408 23.9322 12.372 23.9442C12.36 23.9442 12.336 23.9562 12.324 23.9562C12.276 23.9682 12.24 23.9682 12.204 23.9802C12.192 23.9922 12.168 23.9922 12.156 23.9922C12.108 24.0042 12.048 24.0042 12 24.0042H11.832C11.808 23.9922 11.796 23.9922 11.784 23.9922C11.748 23.9922 11.7 23.9802 11.664 23.9682C11.64 23.9682 11.628 23.9562 11.616 23.9562C11.58 23.9442 11.532 23.9202 11.496 23.9082C11.484 23.9082 11.484 23.8962 11.472 23.8962C11.376 23.8482 11.292 23.8002 11.22 23.7402C11.184 23.7162 11.16 23.6922 11.136 23.6682L11.124 23.6562C11.1 23.6202 11.076 23.5962 11.052 23.5722C11.028 23.5362 11.016 23.5122 10.992 23.4882C9.072 20.7282 6.012 19.7922 1.392 20.5362C0.744 20.6442 0.12 20.2122 0.012 19.5882C0 19.5042 0 19.4202 0 19.3362V1.32421C0.036 0.808215 0.444 0.364215 1.008 0.268215C4.76761 -0.341198 7.84598 0.0895573 10.1973 1.54331C10.7222 1.86783 11 2.45934 11 3.07643V19C11 19 11 20 12 20C13 20 13 19 13 19V3.07175C13 2.45463 13.2778 1.86782 13.8028 1.54333C16.1546 0.0895618 19.235 -0.341201 23.004 0.268215Z'
        fill={primaryColor}
      />
      <path
        d='M0 1.32421C0.036 0.808215 0.444 0.364215 1.008 0.268215C4.76761 -0.341198 7.84598 0.0895573 10.1973 1.54331C10.7222 1.86783 11 2.45934 11 3.07643V19C11 19 11 20 12 20L12 24.0042H11.832C11.808 23.9922 11.796 23.9922 11.784 23.9922C11.748 23.9922 11.7 23.9802 11.664 23.9682C11.64 23.9682 11.628 23.9562 11.616 23.9562C11.58 23.9442 11.532 23.9202 11.496 23.9082C11.484 23.9082 11.484 23.8962 11.472 23.8962C11.376 23.8482 11.292 23.8002 11.22 23.7402C11.184 23.7162 11.16 23.6922 11.136 23.6682L11.124 23.6562C11.1 23.6202 11.076 23.5962 11.052 23.5722C11.028 23.5362 11.016 23.5122 10.992 23.4882C9.072 20.7282 6.012 19.7922 1.392 20.5362C0.744 20.6442 0.12 20.2122 0.012 19.5882C0 19.5042 0 19.4202 0 19.3362V1.32421Z'
        fill={secondaryColor}
      />
    </svg>
  );
}

export function ActiveArticleSvgIcon({ size }) {
  return <ArticleFilledSvg2Color size={size} twoColors={ACTIVE_COLORS} />;
}

export function InactiveArticleSvgIcon({ size }) {
  return <ArticleFilledSvg2Color size={size} twoColors={INACTIVE_COLORS} />;
}
