import { useState, useCallback } from 'react';

import { Button, Classes, Icon, MenuItem } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';

import EmbedSvg from '@/components/helpers/ui/EmbedSvg';
import SvgIcon from '@/components/helpers/ui/SvgIcon';
import { Box, Flex } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import { RoleplayScopeIcon } from '@/components/pages_logged_in/roleplays/RoleplayDetails';
import {
  buildCreateScenarioUrl,
  buildScenarioUrl,
} from '@/components/pages_logged_in/roleplays/utils';
import FlexibleButton from '@/components/pieces/button/FlexibleButton';
import { ConfirmDialog } from '@/components/pieces/dialog/ConfirmDialog';
import EmojiCircle from '@/components/pieces/EmojiCircle';
import IconCircle from '@/components/pieces/IconCircle';
import { MoreMenu } from '@/components/pieces/more/MoreMenu';
import DifficultyTag from '@/components/pieces/roleplays/DifficultyTag';
import RankIcon, { RankText } from '@/components/pieces/roleplays/RankIcon';
import ScenarioMenu from '@/components/pieces/roleplays/ScenarioMenu';
import AvatarWithPrettyNull from '@/components/pieces/users/AvatarWithPrettyNull';
import { BP_NS, CTA_COLOR, MIDTEXT_COLOR } from '@/css/constants';
import {
  RoleplayDocument,
  ScenarioDocument,
  useRemoveCollectionScenarioMutation,
  VisibilityScope,
} from '@/graphql';

export default function ScenarioCard({
  scenario,
  roleplay = null,
  iconSize = 100,
  showPlayButton = true,
  showRank = true,
  showDifficulty = true,
  showShortDesc = true,
  showRoleplay = true,
  showScope = false,
  showAction = false,
  showRemoveFromCollection = false,
  isProgramRoleplay = false,
  isProgramLead = false,
  allowClickOnCard = true,
  rightButton = null,
  onEditCharacter = () => {},
  onDelete = () => {},
  onCloneScenario = () => {},
  onAddToCollection = () => {},
  onEditScenario = () => {},
}) {
  const link = buildScenarioUrl(roleplay?.slug, scenario.slug);
  const statusText =
    isProgramRoleplay &&
    (scenario.archivedAt ||
    scenario.visibilityScope === VisibilityScope.User.toLocaleUpperCase()
      ? 'Scenario no longer available for program'
      : null);

  const ContainerCard = getScenarioCardContainer(
    statusText || !allowClickOnCard ? Box : Link,
  );
  const [removeFromCollection] = useRemoveCollectionScenarioMutation();
  const [collectionToRemove, setCollectionToRemove] = useState(null);
  const handleRemoveFromCollection = useCallback(
    (collection) => {
      setCollectionToRemove(collection);
    },
    [setCollectionToRemove],
  );

  const handleConfirmRemoveFromCollection = useCallback(() => {
    setCollectionToRemove(null);
    removeFromCollection({
      variables: {
        scenarioIds: [scenario.id],
        roleplaySlug: collectionToRemove.slug,
      },
      refetchQueries: [
        {
          query: ScenarioDocument,
          variables: { scenarioSlug: scenario.slug },
        },
        {
          query: RoleplayDocument,
          variables: { slug: roleplay.slug },
        },
      ],
    });
  }, [
    removeFromCollection,
    collectionToRemove,
    scenario,
    setCollectionToRemove,
  ]);

  // Don't show the card if the user is not a program lead and the scenario is not available
  if (isProgramRoleplay && !isProgramLead && statusText) {
    return null;
  }

  return (
    <ContainerCard
      to={link}
      className={`bas round-corners ${
        allowClickOnCard ? 'allow-click-card' : ''
      } ${statusText ? 'disabled' : ''}`}
    >
      <Flex alignItems='center' flexDirection={['column', 'row']} width='100%'>
        <Box minWidth={iconSize}>
          <AvatarWithPrettyNull
            user={{
              name: scenario.personaName,
              photo: scenario.personaPhotoUrl,
            }}
            size={iconSize}
          />
        </Box>
        <Box mx={[0, 3]} textAlign={['center', 'left']} mt={[3, 0]} flex={1}>
          {roleplay && showRoleplay && (
            <Flex alignItems='center'>
              <EmojiCircle
                unified={roleplay.emojiUnicode.toLowerCase()}
                size={28}
              />
              <Box fontSize={14}>{roleplay?.name}</Box>
            </Flex>
          )}
          <Flex justifyContent={'space-between'} alignItems='center'>
            <Box fontWeight={500} fontSize={18}>
              {scenario.name}
            </Box>
            <Flex alignItems='center'>
              {showScope && (
                <RoleplayScopeIcon scope={scenario?.visibilityScope} />
              )}
              {showAction && (
                <Box ml={2}>
                  <ScenarioMenu
                    scenario={scenario}
                    onDelete={onDelete}
                    onEditCharacter={onEditCharacter}
                    onCloneScenario={onCloneScenario}
                    onAddToCollection={onAddToCollection}
                    onEditScenario={onEditScenario}
                  />
                </Box>
              )}
            </Flex>
          </Flex>
          {scenario.shortDesc && showShortDesc ? (
            <Box mt={1} color={MIDTEXT_COLOR}>
              {scenario.shortDesc}
            </Box>
          ) : null}
          <Flex
            alignItems='center'
            mt={3}
            justifyContent={['center', 'flex-start']}
            height={24}
            style={{ display: showDifficulty || showRank ? 'flex' : 'none' }}
          >
            {showDifficulty && (
              <DifficultyTag difficulty={scenario.difficulty} />
            )}

            {showRank && (
              <Flex alignItems='center'>
                <Box mx={3} width='1px' height='22px' bg={'#E2E2E2'} />
                <Flex minWidth={22} alignItems='center'>
                  <RankIcon rank={scenario.rank} size={22} />
                </Flex>
                <RankText ml={1} rank={scenario.rank}>
                  {scenario.rank || 'Not Started'}
                </RankText>
              </Flex>
            )}
          </Flex>
        </Box>
      </Flex>
      {statusText && (
        <Box
          minWidth={120}
          width={['100%', 120]}
          mt={[24, 0]}
          fontSize={14}
          color={MIDTEXT_COLOR}
        >
          {statusText}
        </Box>
      )}
      {showRemoveFromCollection && (
        <Flex alignSelf='flex-start'>
          <MoreMenu>
            <MenuItem
              intent='danger'
              text='Remove from Collection'
              onClick={() => {
                handleRemoveFromCollection(roleplay);
              }}
            />
          </MoreMenu>
        </Flex>
      )}
      {collectionToRemove && (
        <ConfirmDialog
          onConfirm={handleConfirmRemoveFromCollection}
          onCancel={() => setCollectionToRemove(null)}
          confirmLabel='Remove'
          cancelLabel='Nevermind'
        >
          <p>
            Are you sure you want to remove <b>{scenario.name}</b> from{' '}
            <b>{collectionToRemove?.name}</b>?
          </p>
        </ConfirmDialog>
      )}
      {(showPlayButton || rightButton) && !showRemoveFromCollection && (
        <Box minWidth={115} width={['100%', 115]} mt={[24, 0]}>
          {rightButton}
          {showPlayButton &&
            (scenario?.rank ? (
              <FlexibleButton
                fill={true}
                onClick={() => null}
                icon={<Icon icon='reset' />}
              >
                Retry
              </FlexibleButton>
            ) : (
              <FlexibleButton
                fill={true}
                onClick={() => null}
                icon={<Icon icon='play' />}
                variant='primary'
              >
                Start
              </FlexibleButton>
            ))}
        </Box>
      )}
    </ContainerCard>
  );
}

interface CreateScenarioCardProps {
  roleplaySlug?: string | null;
  hadScenarios: boolean;
  onAddExist?: () => void;
}

function ScenarioActionPanel({
  onClick,
  title,
  description,
  iconName,
  buttonText,
}: {
  onClick: () => void;
  title: string;
  description: string;
  iconName: string;
  buttonText: string;
}) {
  return (
    <Flex
      flexDirection='column'
      style={{
        gap: 24,
        border: '1px dashed #E2E2E2',
        padding: 24,
        borderRadius: '6px',
        flex: 1,
      }}
      justifyContent='center'
      alignItems='center'
    >
      <StyledCircle>
        <EmbedSvg width={16} src={SvgIcon({ name: iconName })} />
      </StyledCircle>
      <Flex flexDirection='column' justifyContent='center' alignItems='center'>
        <Box fontWeight={600}>{title}</Box>
        <Box fontWeight={400} mt={12} color='#394B59' textAlign='center'>
          {description}
        </Box>
      </Flex>
      <EmptyStyledButton onClick={onClick} intent='primary' icon='plus'>
        {buttonText}
      </EmptyStyledButton>
    </Flex>
  );
}

function ScenarioActionButton({
  onClick,
  iconName,
  children,
}: {
  onClick: () => void;
  iconName: string;
  children: string;
}) {
  return (
    <StyledButton
      onClick={onClick}
      icon={<EmbedSvg width={16} src={SvgIcon({ name: iconName })} />}
    >
      {children}
    </StyledButton>
  );
}

export function CreateScenarioCard({
  roleplaySlug = null,
  hadScenarios = false,
  onAddExist = null,
}: CreateScenarioCardProps) {
  const navigate = useNavigate();
  const ScenarioCardContainer = getScenarioCardContainer(Link);
  if (!onAddExist) {
    return (
      <ScenarioCardContainer
        to={buildCreateScenarioUrl(roleplaySlug)}
        className='bas round-corners'
      >
        <Flex alignItems='center'>
          <IconCircle icon='clean' size={50} />
          <Box ml={3}>
            <Box fontWeight={500} fontSize={18}>
              Custom Scenario
            </Box>
            <Box mt={1} color={MIDTEXT_COLOR}>
              Practice your own real-life situation and get detailed feedback
            </Box>
          </Box>
        </Flex>
        <Box minWidth={115} width={['100%', 115]} mt={[24, 0]}>
          <FlexibleButton
            fill={true}
            onClick={() => {
              navigate(buildCreateScenarioUrl(roleplaySlug));
            }}
            icon={<Icon icon='small-plus' />}
          >
            Create
          </FlexibleButton>
        </Box>
      </ScenarioCardContainer>
    );
  }

  return hadScenarios ? (
    <Box
      px={16}
      py={24}
      style={{
        border: '1px dashed #E2E2E2',
        borderRadius: '8px',
        backgroundColor: 'transparent',
      }}
    >
      <Flex
        alignItems='center'
        justifyContent='space-between'
        style={{ gap: 16 }}
      >
        {onAddExist && (
          <ScenarioActionButton iconName='clone' onClick={() => onAddExist()}>
            Add Existing Scenario
          </ScenarioActionButton>
        )}
        <ScenarioActionButton
          iconName='editpen'
          onClick={() => {
            navigate(buildCreateScenarioUrl(roleplaySlug));
          }}
        >
          Create New Scenario
        </ScenarioActionButton>
      </Flex>
    </Box>
  ) : (
    <Flex
      px={24}
      py={30}
      style={{
        borderRadius: '8px',
        backgroundColor: 'white',
        gap: 24,
      }}
    >
      {onAddExist && (
        <ScenarioActionPanel
          onClick={() => onAddExist()}
          title={'Add Existing Scenario'}
          description={'Add a scenario from your library to this category'}
          iconName={'clone'}
          buttonText={'Add Existing'}
        />
      )}
      <ScenarioActionPanel
        onClick={() => navigate(buildCreateScenarioUrl(roleplaySlug))}
        title={'Create New Scenario'}
        description={'Build a new scenario from scratch'}
        iconName={'editpen'}
        buttonText={'Create New'}
      />
    </Flex>
  );
}

const getScenarioCardContainer = (component) => styled(component)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  flex-direction: column;
  color: inherit;
  padding: 16px;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }

  &:not(.disabled) {
    &:hover,
    &:focus,
    &:active {
      &.allow-click-card {
        border-color: ${CTA_COLOR};
        cursor: pointer;
        box-shadow:
          0 1px 1px rgba(24, 32, 38, 0.1),
          0 2px 6px rgba(24, 32, 38, 0.05);
      }
    }
  }
  &.disabled {
    opacity: 0.5;
  }
`;

const StyledButton = styled(Button)`
  &.${Classes.BUTTON}:not([class*='${BP_NS}-intent-']) {
    box-shadow: none;
    height: 48px;
    width: 100%;
    border: 1px solid #e2e2e2;
  }
`;
const StyledCircle = styled(Box)`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5f2ff;
`;

const EmptyStyledButton = styled(Button)`
  width: 200px;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  border-radius: 4px;

  &.${Classes.BUTTON}:not([class*='${BP_NS}-intent-']) {
    box-shadow: none;
    background-image: none;
  }
`;
