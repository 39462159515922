export default function AnalyticsSvg({ size = 26, mainColor, lighterColor }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.5 0C21.2239 0 21 0.223857 21 0.5V17.6565L23 19.8272V0.5C23 0.223858 22.7761 0 22.5 0H21.5Z'
        fill={lighterColor}
      />
      <path
        d='M22.3579 22L22.6879 21.6418C22.8642 21.4504 22.8642 21.1557 22.6879 20.9642L21 19.1323V21.5C21 21.7761 21.2239 22 21.5 22H22.3579Z'
        fill={lighterColor}
      />
      <path
        d='M18 9.26372V19.2701L16.7004 17.8595C16.4777 18.0168 16.2437 18.1576 16 18.2802V15.4116C16.5923 14.7706 16.9574 13.8933 16.9574 12.9258C16.9574 11.9583 16.5923 11.0811 16 10.44V7.57144C16.7844 7.96634 17.4683 8.54867 18 9.26372Z'
        fill={lighterColor}
      />
      <path
        d='M18 7.76494V3.5C18 3.22386 17.7761 3 17.5 3H16.5C16.2239 3 16 3.22386 16 3.5V6.47089C16.7437 6.77548 17.419 7.21727 18 7.76494Z'
        fill={lighterColor}
      />
      <path
        d='M8 9.26097V7.5C8 7.22386 7.77614 7 7.5 7H6.5C6.22386 7 6 7.22386 6 7.5V21.5C6 21.7761 6.22386 22 6.5 22H7.5C7.77614 22 8 21.7761 8 21.5V16.5907C7.3646 15.5219 7 14.2625 7 12.9258C7 11.5891 7.3646 10.3298 8 9.26097Z'
        fill={lighterColor}
      />
      <path
        d='M11 19.2963V22H13V19.8239C12.293 19.7577 11.621 19.5755 11 19.2963Z'
        fill={lighterColor}
      />
      <path
        d='M13 18.8182C12.2844 18.7378 11.6092 18.5146 11 18.1761V18H13V18.8182Z'
        fill={lighterColor}
      />
      <path
        d='M16 19.3785V21.5C16 21.7761 16.2239 22 16.5 22H17.5C17.7761 22 18 21.7761 18 21.5V20.7459L16.5187 19.1381C16.3498 19.2257 16.1768 19.306 16 19.3785Z'
        fill={lighterColor}
      />
      <path
        d='M1 12.5C1 12.2239 1.22386 12 1.5 12H2.5C2.77614 12 3 12.2239 3 12.5V21.5C3 21.7761 2.77614 22 2.5 22H1.5C1.22386 22 1 21.7761 1 21.5V12.5Z'
        fill={lighterColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2393 12.9258C10.2393 10.9622 11.7432 9.37034 13.5984 9.37034C15.4535 9.37034 16.9574 10.9622 16.9574 12.9258C16.9574 14.8895 15.4535 16.4813 13.5984 16.4813C11.7432 16.4813 10.2393 14.8895 10.2393 12.9258ZM13.5984 7C10.5065 7 8 9.65309 8 12.9258C8 16.1986 10.5065 18.8517 13.5984 18.8517C14.7457 18.8517 15.8125 18.4863 16.7004 17.8595L21.0688 22.6009C21.2668 22.8158 21.6062 22.8158 21.8042 22.6009L22.6879 21.6418C22.8642 21.4504 22.8642 21.1557 22.6879 20.9642L18.2787 16.1786C18.859 15.2449 19.1967 14.1271 19.1967 12.9258C19.1967 9.65309 16.6902 7 13.5984 7Z'
        fill={mainColor}
      />
    </svg>
  );
}
