import styled from '@emotion/styled';

import { Box, Flex } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import ExecSvg from '@/components/pieces/icons/svg/exec';
import PlacementSvg from '@/components/pieces/icons/svg/placement';
import Clickable from '@/components/pieces/interaction/Clickable';
import PageTitle from '@/components/pieces/onboarding/PageTitle';
import { LIGHT_PURPLE } from '@/css/constants';

export function WorkspaceSelector({ onClick, items = [], inlineForm = false }) {
  return (
    <Flex
      flexDirection='column'
      width={['95%', inlineForm ? '100%' : 700]}
      margin='0 auto'
    >
      <PageTitle
        style={{
          textAlign: 'center',
          fontSize: inlineForm ? 20 : 36,
          marginBottom: inlineForm ? 0 : 36,
        }}
        title='Choose workspace'
      />
      <Flex flexDirection={['column', 'row']} flexWrap='wrap'>
        {items.map((item) => (
          <Clickable key={item.name} onClick={() => onClick(item)}>
            <WorkspaceBox
              flex={'1 1 50%'}
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              maxWidth={inlineForm ? 140 : 330}
            >
              <Box className='workspace-logo'>
                {item.logo ? (
                  <img
                    height={inlineForm ? 40 : 80}
                    src={
                      item.logo.startsWith('/media')
                        ? `${API_HOST}${item.logo}`
                        : item.logo
                    }
                  />
                ) : IS_EXEC ? (
                  <ExecSvg size={inlineForm ? 40 : 80} />
                ) : (
                  <PlacementSvg size={inlineForm ? 40 : 80} />
                )}
              </Box>
              <Box fontSize={inlineForm ? 16 : 24} className='workspace-name'>
                {item.name}
              </Box>
            </WorkspaceBox>
          </Clickable>
        ))}
      </Flex>
    </Flex>
  );
}

const WorkspaceBox = styled(Flex)`
  background: #fff;
  border: 1px solid ${LIGHT_PURPLE};
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding: 30px 10px;
  margin: 10px;
  justify-content: space-between;
  @media screen and (max-width: ${breakpoints.sm}px) {
    flex: 1;
    max-width: 100%;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .workspace-name {
    font-style: normal;
    font-weight: 600;

    margin-top: 10px;
    text-align: center;
  }
  .workspace-logo {
    img {
      display: block;
    }
  }
`;
