import { createContext, ReactNode } from 'react';

import { Request, Response } from 'express';

export interface ResponseContextType {
  req: Request;
  res: Response;
}

export const ResponseContext = createContext<ResponseContextType | null>(null);

interface ResponseContextProviderProps {
  req: Request;
  res: Response;
  children: ReactNode;
}

export function ResponseContextProvider({
  req,
  res,
  children,
}: ResponseContextProviderProps) {
  return (
    <ResponseContext.Provider value={{ req, res }}>
      {children}
    </ResponseContext.Provider>
  );
}
