import { useState } from 'react';

import { Colors, Icon, Portal } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';

import { Box, Flex } from '@/components/layout/flexbox';
import { isXsQuery, Media, SizeBreakpoint } from '@/components/layout/Media';
import LoggedInMenu from '@/components/nav/LoggedInMenu';
import Clickable from '@/components/pieces/interaction/Clickable';
import Avatar from '@/components/pieces/users/Avatar';
import {
  INNER_BORDER_COLOR,
  LIGHT_BRAND_PURPLE,
  NAV_Z_INDEX,
} from '@/css/constants';
import { LoggedInNavDataQueryQuery, WorkspaceNavQueryQuery } from '@/graphql';

import WorkspaceMenu from './WorkspaceMenu';

interface HeaderProfileProps {
  data: LoggedInNavDataQueryQuery | WorkspaceNavQueryQuery;
  pageLoading: boolean;
  sizeNavBreakpoint: SizeBreakpoint;
  prerenderNavSection: string;
}

export default function HeaderProfile({
  data,
  pageLoading,
  sizeNavBreakpoint,
  prerenderNavSection,
}: HeaderProfileProps) {
  const [isOpen, setIsOpen] = useState(!!prerenderNavSection);
  const isXs = useMediaQuery(isXsQuery);

  let menuTransform = isOpen ? 'translateX(0%)' : 'translateX(100%)';

  if (isXs) {
    menuTransform = isOpen ? 'translateY(0%)' : 'translateY(130%)';
  }

  const zIndex = NAV_Z_INDEX + 1;
  const menuIcon = (
    <Flex justifyContent='center' alignItems='center'>
      <Icon
        icon='menu'
        color={data ? Colors.GRAY3 : Colors.LIGHT_GRAY3}
        size={20}
      />
    </Flex>
  );

  return (
    <>
      <Helmet bodyAttributes={{ overflow: isOpen ? 'hidden' : 'visible' }} />
      <Clickable onClick={data ? () => setIsOpen(true) : null}>
        <Box p={3} pl={2} mr={-24}>
          {isXs || !(data && data.currentUser) ? (
            <MobileContainer>{menuIcon}</MobileContainer>
          ) : (
            <Container
              alignItems='center'
              px='12px'
              py='8px'
              className='bas round-corners'
            >
              <Avatar size={24} user={data.currentUser} />
              <Box ml={2} fontSize={14} fontWeight={600}>
                Me
              </Box>
            </Container>
          )}
        </Box>
      </Clickable>

      {data ? (
        <Portal>
          <Media greaterThan='xs'>
            <Clickable onClick={() => setIsOpen(false)}>
              <Box
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex,
                  backgroundColor: Colors.BLACK,
                  opacity: 0.5,
                  display: isOpen ? 'inherit' : 'none',
                }}
              />
            </Clickable>
          </Media>
          <Box
            style={{
              visibility: isOpen ? 'visible' : 'hidden',
              position: 'fixed',
              top: 0,
              left: isXs ? 0 : null,
              bottom: 0,
              right: 0,
              transform: menuTransform,
              transition: isOpen
                ? 'transform 0.4s'
                : 'transform 0.4s, visibility 0s 0.4s',
              zIndex,
            }}
          >
            <Flex justifyContent='center'>
              <Box width={[1, 385]}>
                {'currentUserWorkspaceMember' in data ? (
                  <WorkspaceMenu
                    data={data}
                    onClose={() => setIsOpen(false)}
                    prerenderNavSection={prerenderNavSection}
                    isOpen={isOpen}
                    pageLoading={pageLoading}
                    sizeNavBreakpoint={sizeNavBreakpoint}
                  />
                ) : (
                  <LoggedInMenu
                    user={data.currentUser}
                    onClose={() => setIsOpen(false)}
                    onRefetch={() => {}}
                    prerenderNavSection={prerenderNavSection}
                    isOpen={isOpen}
                    pageLoading={pageLoading}
                    sizeNavBreakpoint={sizeNavBreakpoint}
                  />
                )}

                <Clickable onClick={null}>
                  <Box>
                    <Avatar user={data.currentUser} size={34} />
                  </Box>
                </Clickable>
              </Box>
            </Flex>
          </Box>
        </Portal>
      ) : null}
    </>
  );
}

const Container = styled(Flex)`
  &:hover {
    box-shadow:
      0 1px 1px rgba(24, 32, 38, 0.1),
      0 2px 6px rgba(24, 32, 38, 0.05);
    background-color: #f8fafe;
  }
`;

const MobileContainer = styled.div`
  &:hover {
    box-shadow:
      0 1px 1px rgba(24, 32, 38, 0.1),
      0 2px 6px rgba(24, 32, 38, 0.05);
    color: ${LIGHT_BRAND_PURPLE};
    background-color: #f8fafe;
  }
  color: ${Colors.GRAY5};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid ${INNER_BORDER_COLOR};
`;
