/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateRange: { input: any; output: any };
  DateTime: { input: any; output: any };
  Decimal: { input: any; output: any };
  GenericScalar: { input: any; output: any };
  JSONString: { input: any; output: any };
};

export type AcceptBookingRequest = {
  __typename?: 'AcceptBookingRequest';
  meeting?: Maybe<MeetingType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Accepts a review */
export type AcceptReview = {
  __typename?: 'AcceptReview';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  review?: Maybe<ReviewType>;
};

/** An enumeration. */
export enum AccessScope {
  Global = 'GLOBAL',
  Specific = 'SPECIFIC',
  Workspace = 'WORKSPACE',
}

export enum AccessScopeEnum {
  Specific = 'SPECIFIC',
  Workspace = 'WORKSPACE',
}

export type AccountLink = {
  __typename?: 'AccountLink';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  url?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum ActionTypes {
  Add = 'Add',
  Delete = 'Delete',
  Update = 'Update',
}

export type ActivateWorkspaceMembership = {
  __typename?: 'ActivateWorkspaceMembership';
  ok?: Maybe<Scalars['Boolean']['output']>;
  sesameQueryParam?: Maybe<Scalars['String']['output']>;
};

/** Add Scenario To Collection */
export type AddCollectionScenario = {
  __typename?: 'AddCollectionScenario';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AddMeetingAttendees = {
  __typename?: 'AddMeetingAttendees';
  meetingAttendees?: Maybe<Array<Maybe<MeetingAttendeeType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  long_name?: Maybe<Scalars['String']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AgentAvailabilityType = {
  __typename?: 'AgentAvailabilityType';
  date?: Maybe<Scalars['Date']['output']>;
  slots?: Maybe<Array<Maybe<Scalars['DateRange']['output']>>>;
};

/** An enumeration. */
export enum AgentProfileMemberTier {
  /** Elite Member */
  EliteMember = 'ELITE_MEMBER',
  /** Member */
  Member = 'MEMBER',
  /** New Member */
  NewMember = 'NEW_MEMBER',
  /** Preferred Member */
  PreferredMember = 'PREFERRED_MEMBER',
}

/** An enumeration. */
export enum AgentProfilePrimarySite {
  /** Exec */
  Exec = 'EXEC',
  /** Placement */
  Placement = 'PLACEMENT',
}

/** DjangoObjectType to access the AgentProfile model. */
export type AgentProfileType = {
  __typename?: 'AgentProfileType';
  about?: Maybe<Scalars['String']['output']>;
  acceptingNewBookings?: Maybe<Scalars['Boolean']['output']>;
  acceptingNewClients: Scalars['Boolean']['output'];
  agentSpecialtyProfiles: Array<AgentSpecialtyProfileType>;
  availability?: Maybe<Array<Maybe<AgentAvailabilityType>>>;
  averageRating?: Maybe<Scalars['Float']['output']>;
  bufferAfter?: Maybe<Scalars['Int']['output']>;
  bufferBefore?: Maybe<Scalars['Int']['output']>;
  canHaveIntroSession?: Maybe<Scalars['Boolean']['output']>;
  coachSlug: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /**
   *
   *             List any relevant degrees, certifications, or experiences
   *
   */
  credentials?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  execSeoPicture?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         Which of our structured options is this coach best at?
   *
   */
  expertise?: Maybe<Array<Scalars['String']['output']>>;
  expertiseLabels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  faqAnswers?: Maybe<Array<Maybe<FaqAnswerType>>>;
  firstBookedMeetingAt?: Maybe<Scalars['DateTime']['output']>;
  firstCompletedSessionAt?: Maybe<Scalars['DateTime']['output']>;
  firstConverationRateAt?: Maybe<Scalars['DateTime']['output']>;
  firstFiveStarsFeedbackAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  introVideo?: Maybe<Scalars['String']['output']>;
  introVideoThumbnail?: Maybe<Scalars['String']['output']>;
  lastBookedMeetingAt?: Maybe<Scalars['DateTime']['output']>;
  matchingIndustries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  matchingJobFunctions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  matchingJobTitles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   *
   *         Which of our structured options is this coach best at?
   *
   */
  matchingOptions: Array<MatchingOptionType>;
  matchingProficiencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   *
   *         New Members DO NO show up in the Match flow.
   *         Members rank low in the match flow.
   *         Preferred Members rank highly in the match flow.
   *         Elite Members rank very highly in the match flow.
   *
   */
  memberTier: AgentProfileMemberTier;
  /**
   *
   *         How many MINUTES of notice does this coach need before a booking?
   *         Meetings will not be allowed to be scheduled sooner than this.
   *
   */
  minimumMeetingNotice: Scalars['Int']['output'];
  minuteIncrement?: Maybe<Scalars['Int']['output']>;
  numRatings: Scalars['Int']['output'];
  paymentOnboardingReady?: Maybe<Scalars['Boolean']['output']>;
  payoutChannel?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         This coach might be displayed on multiple sites. This causes their
   *         profile to be SEO indexed with the same content in multiple places.
   *         To prevent cross-site canibalization we choose a primary site and set
   *         it to the canonical location.
   *
   */
  primarySite: AgentProfilePrimarySite;
  publicProfile: Scalars['Boolean']['output'];
  /**
   *
   *             Which industries does this coach know best? This won't exclude them
   *             from working with people in other industries.
   *
   */
  relevantIndustries: Array<IndustryGraphqlType>;
  /**
   *
   *             Which job function is this coach most experienced with? This won't exclude
   *             them from working with people targetting functions that aren't included.
   *
   */
  relevantJobFunctions: Array<JobFunctionGraphqlType>;
  /**
   *
   *             Which job titles is this coach most experienced with? This won't exclude
   *             them from working with people targetting titles that aren't included.
   *
   */
  relevantJobTitles: Array<JobTitleType>;
  /**
   *
   *             Which places does this coach know best? This won't exclude them
   *             from working with people targetting titles that aren't included.
   *
   */
  relevantPlaces: Array<PlaceType>;
  /**
   *
   *             Which standard credential is this coach have?
   *
   */
  relevantStandardCredentials: Array<StandardCredentialType>;
  seoIndex?: Maybe<Scalars['Boolean']['output']>;
  seoPhoto?: Maybe<Scalars['String']['output']>;
  seoPicture?: Maybe<Scalars['String']['output']>;
  standardCredentials?: Maybe<Array<Maybe<StandardCredentialType>>>;
  summary?: Maybe<Scalars['String']['output']>;
  testimonials?: Maybe<Array<Maybe<TestimonialType>>>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<AgentType>;
};

export type AgentSpecialtyProfileType = {
  __typename?: 'AgentSpecialtyProfileType';
  about?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  introVideoThumbnail?: Maybe<Scalars['String']['output']>;
  specialty?: Maybe<SpecialtyType>;
  summary?: Maybe<Scalars['String']['output']>;
};

/** DjangoObjectType to access the User model as an agent. */
export type AgentType = {
  __typename?: 'AgentType';
  activeCoachProducts?: Maybe<Array<Maybe<CoachProductType>>>;
  agentProfile?: Maybe<AgentProfileType>;
  agentSpecialtyProfile?: Maybe<AgentSpecialtyProfileType>;
  coachProducts?: Maybe<Array<Maybe<CoachProductType>>>;
  coachSlug?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  workHistories?: Maybe<Array<Maybe<UserWorkHistoryType>>>;
};

export type AnswerSurveyParticipantType = {
  __typename?: 'AnswerSurveyParticipantType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
};

export type AnswerType = {
  __typename?: 'AnswerType';
  createdAt: Scalars['DateTime']['output'];
  formField?: Maybe<FieldType>;
  fulfillmentForm?: Maybe<FulfillmentFormType>;
  id: Scalars['ID']['output'];
  meeting?: Maybe<ChatMeetingType>;
  optionValue?: Maybe<FormFieldOptionAnalyticsType>;
  order?: Maybe<OrderType>;
  orderItem?: Maybe<OrderItemType>;
  programComponentResponse?: Maybe<ProgramComponentResponseType>;
  programSurvey?: Maybe<ProgramSurveyType>;
  review?: Maybe<ChatReviewType>;
  roleplaySession?: Maybe<RoleplaySessionType>;
  scenario?: Maybe<ScenarioType>;
  updatedAt: Scalars['DateTime']['output'];
  /** The user who answered this question, currently aplying to a program survey */
  user?: Maybe<UserType>;
  userFile?: Maybe<UserFileType>;
  userId?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ArchiveProgram = {
  __typename?: 'ArchiveProgram';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Archives an roleplay session */
export type ArchiveRoleplaySession = {
  __typename?: 'ArchiveRoleplaySession';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Archives a scenario */
export type ArchiveScenario = {
  __typename?: 'ArchiveScenario';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type Article = {
  __typename?: 'Article';
  author?: Maybe<UserType>;
  authorIsCoach?: Maybe<Scalars['Boolean']['output']>;
  authorName?: Maybe<Scalars['String']['output']>;
  authorPhoto?: Maybe<Scalars['String']['output']>;
  authorShortBio?: Maybe<Scalars['String']['output']>;
  authorUrl?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['JSONString']['output']>;
  /**
   *
   *         The domain that this article should be considered canonical on.
   *
   */
  canonicalDomain: ArticleCanonicalDomain;
  createdAt: Scalars['DateTime']['output'];
  externalId: Scalars['String']['output'];
  heroImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  /** Should this article be included in search and recommendations? */
  isSearchable: Scalars['Boolean']['output'];
  noindex?: Maybe<Scalars['Boolean']['output']>;
  primarySeries?: Maybe<ArticleSeries>;
  public?: Maybe<Scalars['Boolean']['output']>;
  readStatuses: Array<ArticleReadStatus>;
  sidebarCta?: Maybe<ArticleSidebarCta>;
  summary?: Maybe<Scalars['String']['output']>;
  timeToRead?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  urlSlug?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         The domains that this article should be visible on.
   *
   */
  visibleOn: Array<Scalars['String']['output']>;
};

/** An enumeration. */
export enum ArticleCanonicalDomain {
  /** Exec */
  Exec = 'EXEC',
  /** Placement */
  Placement = 'PLACEMENT',
}

export type ArticleReadStatus = {
  __typename?: 'ArticleReadStatus';
  article: Article;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  readStatus: ArticleReadStatusReadStatus;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
};

/** An enumeration. */
export enum ArticleReadStatusReadStatus {
  /** Readingcompleted */
  ReadingCompleted = 'READING_COMPLETED',
  /** Readinginprogress */
  ReadingInProgress = 'READING_IN_PROGRESS',
}

export type ArticleReadStatusType = {
  __typename?: 'ArticleReadStatusType';
  article: Article;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  readStatus?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
};

export type ArticleSeries = {
  __typename?: 'ArticleSeries';
  articles?: Maybe<Array<Maybe<Article>>>;
  /**
   *
   *         The domain that this article should be considered canonical on.
   *
   */
  canonicalDomain: ArticleSeriesCanonicalDomain;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  isCoachOnlyContent: Scalars['Boolean']['output'];
  isJobSearchContent: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  popularityPoints: Scalars['Int']['output'];
  primaryArticles: Array<Article>;
  relatedSeries: Array<ArticleSeries>;
  relatedTo: Array<ArticleSeries>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  urlSlug?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         The domains that this article series should be visible on.
   *
   */
  visibleOn: Array<Scalars['String']['output']>;
};

/** An enumeration. */
export enum ArticleSeriesCanonicalDomain {
  /** Exec */
  Exec = 'EXEC',
  /** Placement */
  Placement = 'PLACEMENT',
}

export type ArticleSeriesReadStatus = {
  __typename?: 'ArticleSeriesReadStatus';
  articleSeriesId?: Maybe<Scalars['Int']['output']>;
  readCompletedArticleIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  readInprogressArticleIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  totalItemCount?: Maybe<Scalars['Int']['output']>;
};

export type ArticleSidebarCta = {
  __typename?: 'ArticleSidebarCTA';
  body?: Maybe<Scalars['String']['output']>;
  buttonText?: Maybe<Scalars['String']['output']>;
  ctaLink?: Maybe<Scalars['String']['output']>;
  headline: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ArticlesPaginationType = {
  __typename?: 'ArticlesPaginationType';
  items?: Maybe<Array<Maybe<Article>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type AssignAssistant = {
  __typename?: 'AssignAssistant';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  workspaceAssistantAssignment?: Maybe<WorkspaceAssistantAssignmentType>;
};

export type AssignCoaches = {
  __typename?: 'AssignCoaches';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AssignInitialWorkspaceAdmin = {
  __typename?: 'AssignInitialWorkspaceAdmin';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  urlSlug?: Maybe<Scalars['String']['output']>;
};

export type AssignWorkspaceGroup = {
  __typename?: 'AssignWorkspaceGroup';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AssignmentType = {
  __typename?: 'AssignmentType';
  chatChannelId?: Maybe<Scalars['String']['output']>;
  coach?: Maybe<MiniCoachType>;
  customer?: Maybe<MiniUserType>;
  id: Scalars['ID']['output'];
  unreadMessages?: Maybe<Scalars['Int']['output']>;
  workspace?: Maybe<WorkspaceType>;
};

/** An enumeration. */
export enum AttendingStatus {
  Attending = 'Attending',
  MayBe = 'MayBe',
  NotAttending = 'NotAttending',
}

export type AuthCalendarAccount = {
  __typename?: 'AuthCalendarAccount';
  calendarAccount?: Maybe<CalendarAccountType>;
};

export type BillingSession = {
  __typename?: 'BillingSession';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Books a review */
export type BookReview = {
  __typename?: 'BookReview';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  review?: Maybe<ReviewType>;
};

export type CmsFaqQuestionType = {
  __typename?: 'CMSFaqQuestionType';
  answer?: Maybe<Scalars['JSONString']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<Scalars['String']['output']>;
};

export type CachedEntitlementType = {
  __typename?: 'CachedEntitlementType';
  booleanValue?: Maybe<Scalars['Boolean']['output']>;
  entitlement?: Maybe<Entitlement>;
  id?: Maybe<Scalars['ID']['output']>;
  maxUsageValue?: Maybe<Scalars['Int']['output']>;
  usageCount?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum CalendarAccountAccountType {
  /** Google */
  Google = 'GOOGLE',
  /** Outlook */
  Outlook = 'OUTLOOK',
}

export type CalendarAccountType = {
  __typename?: 'CalendarAccountType';
  accountEmail?: Maybe<Scalars['String']['output']>;
  accountType: CalendarAccountAccountType;
  calendars?: Maybe<Array<Maybe<CalendarType>>>;
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  scopes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CalendarInputType = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CalendarQueryType = {
  __typename?: 'CalendarQueryType';
  calendarId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CalendarSettingGraphqlType = {
  __typename?: 'CalendarSettingGraphqlType';
  createdAt: Scalars['DateTime']['output'];
  fridayTimeslots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  mondayTimeslots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  saturdayTimeslots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sundayTimeslots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  thursdayTimeslots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tuesdayTimeslots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
  wednesdayTimeslots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  weeklyHours: Scalars['Int']['output'];
};

export type CalendarSettingInput = {
  fridayTimeslots?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mondayTimeslots?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  saturdayTimeslots?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sundayTimeslots?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  thursdayTimeslots?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  tuesdayTimeslots?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wednesdayTimeslots?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type CalendarType = {
  __typename?: 'CalendarType';
  calendarId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CancelAttendeesSchedule = {
  __typename?: 'CancelAttendeesSchedule';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CancelBookingRequest = {
  __typename?: 'CancelBookingRequest';
  meeting?: Maybe<MeetingType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CancelCoachMeeting = {
  __typename?: 'CancelCoachMeeting';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Cancel the scheduled clients invites of current workspace */
export type CancelInviteClientSchedule = {
  __typename?: 'CancelInviteClientSchedule';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Possibly cancels a review */
export type CancelReview = {
  __typename?: 'CancelReview';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  review?: Maybe<ReviewType>;
};

/** Cancels an unpaid order */
export type CancelUnpaidOrder = {
  __typename?: 'CancelUnpaidOrder';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ChatCustomerType = {
  __typename?: 'ChatCustomerType';
  chatChannelId?: Maybe<Scalars['String']['output']>;
  coach?: Maybe<MiniCoachType>;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<TaggableUserType>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  fulfillmentForms: Array<FulfillmentFormType>;
  id: Scalars['ID']['output'];
  intakeForms: Array<IntakeFormType>;
  streamChannelCreated: Scalars['Boolean']['output'];
  streamChannelFrozen: Scalars['Boolean']['output'];
  unreadMessages?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  workspace?: Maybe<WorkspaceType>;
};

export type ChatDirectMessageType = {
  __typename?: 'ChatDirectMessageType';
  chatChannelId?: Maybe<Scalars['String']['output']>;
  coach?: Maybe<TaggableUserType>;
  customer?: Maybe<TaggableUserType>;
  id: Scalars['ID']['output'];
  unreadMessages?: Maybe<Scalars['Int']['output']>;
};

export type ChatMeetingType = {
  __typename?: 'ChatMeetingType';
  allowGuests?: Maybe<Scalars['Boolean']['output']>;
  allowInvitation: Scalars['Boolean']['output'];
  attendee?: Maybe<MeetingAttendeeType>;
  attendees: Array<MeetingAttendeeType>;
  canLeaveRating?: Maybe<Scalars['Boolean']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledBy?: Maybe<UserType>;
  chatChannelId?: Maybe<Scalars['String']['output']>;
  chatChannelTitle?: Maybe<Scalars['String']['output']>;
  coach?: Maybe<AgentType>;
  createdAt: Scalars['DateTime']['output'];
  createdWithoutCustomerOptIn: Scalars['Boolean']['output'];
  creditPrice?: Maybe<Scalars['Decimal']['output']>;
  customLocation?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<UserType>;
  customerId?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  eventAt?: Maybe<Scalars['DateTime']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  formFieldAnswers?: Maybe<Array<Maybe<AnswerType>>>;
  fulfillmentForm?: Maybe<FulfillmentFormType>;
  googleEventId?: Maybe<Scalars['String']['output']>;
  /** List of guests that are invited to the meeting. */
  guests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  intakeForms: Array<IntakeFormType>;
  isBookingRequest?: Maybe<Scalars['Boolean']['output']>;
  isInternalMeeting?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  numMessages?: Maybe<Scalars['Int']['output']>;
  orderItem?: Maybe<OrderItemType>;
  paymentStatus?: Maybe<PaymentStatusTypes>;
  product?: Maybe<ProductType>;
  programComponent?: Maybe<ProgramComponentType>;
  rating?: Maybe<RatingType>;
  rawCalendarData?: Maybe<Scalars['JSONString']['output']>;
  /**
   *
   *             Usually we don't grant refunds if the customer no-shows or reschedules last
   *             minute. When this field is populated, we will refund the customer and
   *             clawback the credit from the coach.
   *
   */
  refundExceptionGrantedAt?: Maybe<Scalars['DateTime']['output']>;
  refundExceptionGrantedBy?: Maybe<UserType>;
  refundableUntil?: Maybe<Scalars['DateTime']['output']>;
  shouldCollectRating: Scalars['Boolean']['output'];
  streamChannelCreated: Scalars['Boolean']['output'];
  streamChannelFrozen: Scalars['Boolean']['output'];
  /** List of timeslot candidates that the customer has selected if the meeting is a request booking meeting. */
  timeslotCandidates: Array<Scalars['String']['output']>;
  topAttendees?: Maybe<Array<Maybe<MeetingAttendeeType>>>;
  unreadMessages?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedAllTimeslot: Scalars['Boolean']['output'];
  workspace?: Maybe<WorkspaceType>;
};

export type ChatReviewType = {
  __typename?: 'ChatReviewType';
  acceptanceDeadline?: Maybe<Scalars['DateTime']['output']>;
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  canLeaveRating?: Maybe<Scalars['Boolean']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledBy?: Maybe<UserType>;
  chatChannelId?: Maybe<Scalars['String']['output']>;
  chatChannelTitle?: Maybe<Scalars['String']['output']>;
  coach?: Maybe<AgentType>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creditPrice?: Maybe<Scalars['Decimal']['output']>;
  customer?: Maybe<UserType>;
  customerId?: Maybe<Scalars['Int']['output']>;
  finalCheckDeadline?: Maybe<Scalars['DateTime']['output']>;
  finalCheckRevisionDeadline?: Maybe<Scalars['DateTime']['output']>;
  finalCheckRevisionSentAt?: Maybe<Scalars['DateTime']['output']>;
  finalCheckRevisionSentBy?: Maybe<UserType>;
  formFieldAnswers?: Maybe<Array<Maybe<AnswerType>>>;
  formReceivedAt?: Maybe<Scalars['DateTime']['output']>;
  fulfillmentForm?: Maybe<FulfillmentFormType>;
  id: Scalars['ID']['output'];
  nextDate?: Maybe<Scalars['String']['output']>;
  numMessages?: Maybe<Scalars['Int']['output']>;
  numRounds: Scalars['Int']['output'];
  numRoundsComplete: Scalars['Int']['output'];
  orderItem?: Maybe<OrderItemType>;
  product?: Maybe<ProductType>;
  programComponent?: Maybe<ProgramComponentType>;
  rating?: Maybe<RatingType>;
  /**
   *
   *             Usually we don't grant refunds after a coach has already accepted the
   *             review. When this field is populated, we will refund the customer and
   *             clawback the credit from the coach.
   *
   */
  refundExceptionGrantedAt?: Maybe<Scalars['DateTime']['output']>;
  refundExceptionGrantedBy?: Maybe<UserType>;
  refundedAt?: Maybe<Scalars['DateTime']['output']>;
  rounds?: Maybe<Array<Maybe<ReviewRoundType>>>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  streamChannelCreated: Scalars['Boolean']['output'];
  streamChannelFrozen: Scalars['Boolean']['output'];
  unreadMessages?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientPaginationType = {
  __typename?: 'ClientPaginationType';
  currentPage?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<ClientStatisticType>>>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export type ClientStatisticType = {
  __typename?: 'ClientStatisticType';
  coachId?: Maybe<Scalars['ID']['output']>;
  coachName?: Maybe<Scalars['String']['output']>;
  coachPhoto?: Maybe<Scalars['String']['output']>;
  coachSlug?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastMeetingDuration?: Maybe<Scalars['Int']['output']>;
  lastMeetingEventAt?: Maybe<Scalars['DateTime']['output']>;
  lastMeetingId?: Maybe<Scalars['ID']['output']>;
  lastMeetingProductName?: Maybe<Scalars['String']['output']>;
  messagesCid?: Maybe<Scalars['String']['output']>;
  messagesCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  messagesHtml?: Maybe<Scalars['String']['output']>;
  messagesId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextMeetingDuration?: Maybe<Scalars['Int']['output']>;
  nextMeetingEventAt?: Maybe<Scalars['DateTime']['output']>;
  nextMeetingId?: Maybe<Scalars['ID']['output']>;
  nextMeetingProductName?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  recentReviewCreateAt?: Maybe<Scalars['DateTime']['output']>;
  recentReviewId?: Maybe<Scalars['ID']['output']>;
  recentReviewProductName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  workspaceId?: Maybe<Scalars['ID']['output']>;
  workspaceName?: Maybe<Scalars['String']['output']>;
};

/** Clone a scenario */
export type CloneCollection = {
  __typename?: 'CloneCollection';
  collection?: Maybe<RoleplayType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CloneProgram = {
  __typename?: 'CloneProgram';
  error?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  program?: Maybe<ProgramType>;
};

/** Clone a scenario */
export type CloneScenario = {
  __typename?: 'CloneScenario';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  scenario?: Maybe<ScenarioType>;
};

export type CloseProgramComponent = {
  __typename?: 'CloseProgramComponent';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CoachAvailableObject = {
  __typename?: 'CoachAvailableObject';
  timeslots?: Maybe<Array<Maybe<Scalars['DateTime']['output']>>>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type CoachCustomerType = {
  __typename?: 'CoachCustomerType';
  currentPriceOption?: Maybe<CustomPriceOptionType>;
  lastPurchaseAt?: Maybe<Scalars['DateTime']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  userPhoto?: Maybe<Scalars['String']['output']>;
};

export type CoachCustomerTypeCurrentPriceOptionArgs = {
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type CoachPayoutConfigType = {
  __typename?: 'CoachPayoutConfigType';
  dollarPerCredit?: Maybe<Scalars['Decimal']['output']>;
  payoutChannel?: Maybe<Scalars['String']['output']>;
};

export type CoachPayoutEntryType = {
  __typename?: 'CoachPayoutEntryType';
  coachPayout: CoachPayoutType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isPayoutEntry: Scalars['Boolean']['output'];
  ledgerAccountEntry: LedgerAccountEntryType;
};

export type CoachPayoutType = {
  __typename?: 'CoachPayoutType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  paidThrough: Scalars['DateTime']['output'];
  totalCredits: Scalars['Float']['output'];
  totalPayout: Scalars['Float']['output'];
};

export type CoachProductType = {
  __typename?: 'CoachProductType';
  acceptingNewClients?: Maybe<Scalars['Boolean']['output']>;
  coachCreditValue?: Maybe<Scalars['Decimal']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  invalidPrices?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  isExistingCustomer?: Maybe<Scalars['Boolean']['output']>;
  locked?: Maybe<Scalars['Boolean']['output']>;
  maxPrice?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<ProductType>;
  requireBookingRequest?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usdPrice?: Maybe<Scalars['Decimal']['output']>;
  user?: Maybe<AgentProfileType>;
  validPrices?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type CoachSpecialtyConfigurationType = {
  __typename?: 'CoachSpecialtyConfigurationType';
  coach?: Maybe<MiniCoachType>;
  coachId?: Maybe<Scalars['ID']['output']>;
  specialty?: Maybe<SpecialtyType>;
  specialtyId?: Maybe<Scalars['ID']['output']>;
};

/** An enumeration. */
export enum CompanyApplicationMethod {
  /** Apply And Reach Out */
  ApplyAndReachOut = 'APPLY_AND_REACH_OUT',
  /** Cold Apply */
  ColdApply = 'COLD_APPLY',
  /** Wait For Placement */
  WaitForPlacement = 'WAIT_FOR_PLACEMENT',
}

/** An enumeration. */
export enum CompanyEnrichedSource {
  /** Angelco */
  Angelco = 'ANGELCO',
  /** Clearbit */
  Clearbit = 'CLEARBIT',
  /** Crunchbase */
  Crunchbase = 'CRUNCHBASE',
}

/** An enumeration. */
export enum CompanyRelationshipStatus {
  /** Connected */
  Connected = 'CONNECTED',
  /** No Relationship */
  NoRelationship = 'NO_RELATIONSHIP',
  /** Partnership */
  Partnership = 'PARTNERSHIP',
}

/** DjangoObjectType to access the Company model. */
export type CompanyType = {
  __typename?: 'CompanyType';
  /** The default way we recommend our customers to apply to this company */
  applicationMethod: CompanyApplicationMethod;
  approvalRatio: Scalars['Float']['output'];
  canonicalIndustry?: Maybe<IndustryGraphqlType>;
  clearbit?: Maybe<Scalars['JSONString']['output']>;
  companyLogo?: Maybe<Scalars['String']['output']>;
  companySeoSlug?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Please include the entire URL, with the https:// */
  crunchbase?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Please **do not** include the https:// portion or the www portion */
  domain?: Maybe<Scalars['String']['output']>;
  endorsements: Array<ProductEndorsementType>;
  enrichedDate?: Maybe<Scalars['DateTime']['output']>;
  enrichedSource?: Maybe<CompanyEnrichedSource>;
  /** Please include the entire URL, with the https:// */
  facebook?: Maybe<Scalars['String']['output']>;
  followers: Array<FollowCompanyType>;
  founded?: Maybe<Scalars['Int']['output']>;
  /**
   *
   *         When checked, all new listings from this company will be marked
   *         as remote okay.
   *
   */
  fullyRemote: Scalars['Boolean']['output'];
  headquarters?: Maybe<PlaceType>;
  id: Scalars['ID']['output'];
  inPlacementNetwork?: Maybe<Scalars['Boolean']['output']>;
  industry?: Maybe<IndustryGraphqlType>;
  industryDeprecated?: Maybe<Scalars['String']['output']>;
  /** Please include the entire URL, with the https:// */
  jobsPage?: Maybe<Scalars['String']['output']>;
  /** Please include the entire URL, with the https:// */
  linkedin?: Maybe<Scalars['String']['output']>;
  listingCount: Scalars['Int']['output'];
  listingCountLastUpdated: Scalars['DateTime']['output'];
  listingInWeekCount: Scalars['Int']['output'];
  listingInWeekCountLastUpdated: Scalars['DateTime']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  numPositives: Scalars['Int']['output'];
  numPositivesLastUpdated: Scalars['DateTime']['output'];
  popularityQuartile: Scalars['Int']['output'];
  popularityQuartileLastUpdated: Scalars['DateTime']['output'];
  popularityRank: Scalars['Float']['output'];
  relationshipOwner?: Maybe<UserType>;
  relationshipStatus: CompanyRelationshipStatus;
  size?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         When checked, we will ignore this company most places.
   *         Usually used for staffing / recruiting companies
   *
   */
  skipListings: Scalars['Boolean']['output'];
  /** Please include the entire URL, with the https:// */
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /**
   *
   *         An indicator of how trustworthy this data is.
   *         <ul>
   *             <li><Unverified = We scraped off the internet</li>
   *             <li>Enriched = We used a third party to supplement the data</li>
   *             <li>Verified = A trained Exec rep looked at the data and approved it</li>
   *             <li>Claimed = The company itself now manages its data</li>
   *         </ul>
   *
   */
  verificationStatus: CompanyVerificationStatus;
  weightedApprovalRatio: Scalars['Float']['output'];
  workHistories: Array<UserWorkHistoryType>;
};

/** An enumeration. */
export enum CompanyVerificationStatus {
  /** Claimed */
  Claimed = 'CLAIMED',
  /** Enriched */
  Enriched = 'ENRICHED',
  /** Unverified */
  Unverified = 'UNVERIFIED',
  /** Verified */
  Verified = 'VERIFIED',
}

export type CompleteAddToCalendar = {
  __typename?: 'CompleteAddToCalendar';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CompleteProgram = {
  __typename?: 'CompleteProgram';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Marks a review round complete */
export type CompleteReviewRound = {
  __typename?: 'CompleteReviewRound';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  review?: Maybe<ReviewType>;
};

export type CompleteSetupProgramComponents = {
  __typename?: 'CompleteSetupProgramComponents';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ComponentParticipantPaginationType = {
  __typename?: 'ComponentParticipantPaginationType';
  currentPage?: Maybe<Scalars['Int']['output']>;
  participants?: Maybe<Array<Maybe<ComponentParticipantType>>>;
  size?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  totalParticipants?: Maybe<Scalars['Int']['output']>;
};

export type ComponentParticipantType = {
  __typename?: 'ComponentParticipantType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isEligible?: Maybe<Scalars['Boolean']['output']>;
  lastReminderAt?: Maybe<Scalars['DateTime']['output']>;
  programComponentResponseId?: Maybe<Scalars['ID']['output']>;
  roles?: Maybe<Array<Maybe<ProgramUserRole>>>;
  user?: Maybe<MiniUserType>;
};

/** An enumeration. */
export enum ComponentTypeEnum {
  AiReinforcementCoaching = 'AIReinforcementCoaching',
  AiRoleplay = 'AIRoleplay',
  ActionItem = 'ActionItem',
  Announcement = 'Announcement',
  Article = 'Article',
  GroupActivity = 'GroupActivity',
  GroupMeeting = 'GroupMeeting',
  OneOneCoachingIntro = 'OneOneCoachingIntro',
  OneOneCoachingMaster = 'OneOneCoachingMaster',
  OneOneCoachingSession = 'OneOneCoachingSession',
  OneOneSession = 'OneOneSession',
  Seminars = 'Seminars',
  SoloActivity = 'SoloActivity',
  StakeholderInsight = 'StakeholderInsight',
  Survey = 'Survey',
  Video = 'Video',
  Workshops = 'Workshops',
}

export type ConfigurationInput = {
  action: ActionTypes;
  configId?: InputMaybe<Scalars['ID']['input']>;
  enableJobSearch: Scalars['Boolean']['input'];
  enableReviews: Scalars['Boolean']['input'];
  maximumCreditPrice?: InputMaybe<Scalars['Decimal']['input']>;
  specialtyExcludeIds: Array<InputMaybe<Scalars['String']['input']>>;
  specialtyIncludeIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type ConfigurationType = {
  __typename?: 'ConfigurationType';
  coachSpecExcludes?: Maybe<Array<Maybe<CoachSpecialtyConfigurationType>>>;
  coachSpecIncludes?: Maybe<Array<Maybe<CoachSpecialtyConfigurationType>>>;
  configId?: Maybe<Scalars['ID']['output']>;
  configType?: Maybe<Scalars['String']['output']>;
  enableJobSearch?: Maybe<Scalars['Boolean']['output']>;
  enableReviews?: Maybe<Scalars['Boolean']['output']>;
  maximumCreditPrice?: Maybe<Scalars['Decimal']['output']>;
  specialtyExcludeIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  specialtyExcludes?: Maybe<Array<Maybe<SpecialtyType>>>;
  specialtyIncludeIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  specialtyIncludes?: Maybe<Array<Maybe<SpecialtyType>>>;
  version?: Maybe<Scalars['String']['output']>;
};

/** Confirm email and retrieve refresh_token */
export type ConfirmEmailRedirect = {
  __typename?: 'ConfirmEmailRedirect';
  isPasswordSet?: Maybe<Scalars['Boolean']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  workspaceMember?: Maybe<WorkspaceMemberType>;
};

/** Confirms a pending order */
export type ConfirmOrder = {
  __typename?: 'ConfirmOrder';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Copies evaluation criteria to a collection */
export type CopyEvaluationCriteriaToCollection = {
  __typename?: 'CopyEvaluationCriteriaToCollection';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Copies evaluation criteria to a scenario */
export type CopyEvaluationCriteriaToScenario = {
  __typename?: 'CopyEvaluationCriteriaToScenario';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateCoachMeeting = {
  __typename?: 'CreateCoachMeeting';
  meeting?: Maybe<MeetingType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<CreateMeetingStatus>;
};

/** Creates an evaluation criterion with associated evaluation items */
export type CreateEvaluationCriterion = {
  __typename?: 'CreateEvaluationCriterion';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  evaluationCriterion?: Maybe<EvaluationCriterionType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum CreateMeetingStatus {
  AlreadyHaveIntro = 'AlreadyHaveIntro',
  BalanceNotEnough = 'BalanceNotEnough',
  CoachNotAvailable = 'CoachNotAvailable',
  Error = 'Error',
  ExceedingMaximumCreditPrice = 'ExceedingMaximumCreditPrice',
  MeetingOverlap = 'MeetingOverlap',
  RequiredFieldIsMissing = 'RequiredFieldIsMissing',
  Success = 'Success',
  WorkspaceBalanceNotEnough = 'WorkspaceBalanceNotEnough',
}

/** Creates a new pending order */
export type CreateOrder = {
  __typename?: 'CreateOrder';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<OrderType>;
};

/** Creates a place */
export type CreatePlace = {
  __typename?: 'CreatePlace';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  placeId?: Maybe<Scalars['Int']['output']>;
};

export type CreateProgram = {
  __typename?: 'CreateProgram';
  ok?: Maybe<Scalars['Boolean']['output']>;
  program?: Maybe<ProgramMiniType>;
};

export type CreateProgramUsers = {
  __typename?: 'CreateProgramUsers';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  programUsers?: Maybe<Array<Maybe<ProgramUserType>>>;
};

/** Submits a rating */
export type CreateRating = {
  __typename?: 'CreateRating';
  rating?: Maybe<RatingType>;
};

/** Create new resource permissions for users, groups, or programs */
export type CreateResourcePermissions = {
  __typename?: 'CreateResourcePermissions';
  createdCount?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  invalidEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  invalidGroupIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  invalidProgramIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateResourcePermissionsInput = {
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  permissionLevel: PermissionLevel;
  programIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  resourceId: Scalars['ID']['input'];
  resourceType: ResourceType;
  userEmails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Save roleplay session */
export type CreateRoleplaySession = {
  __typename?: 'CreateRoleplaySession';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  roleplaySession?: Maybe<RoleplaySessionType>;
};

/** Save scenario intake */
export type CreateScenarioIntake = {
  __typename?: 'CreateScenarioIntake';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  scenarioIntake?: Maybe<ScenarioIntakeType>;
};

export type CreateStripeCustomer = {
  __typename?: 'CreateStripeCustomer';
  error?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateWorkspace = {
  __typename?: 'CreateWorkspace';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  workspace?: Maybe<WorkspaceGraphqlType>;
  workspaceSignedToken?: Maybe<Scalars['String']['output']>;
};

export type CreateWorkspaceGroup = {
  __typename?: 'CreateWorkspaceGroup';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  group?: Maybe<WorkspaceGroupType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomPriceOptionType = {
  __typename?: 'CustomPriceOptionType';
  coachCreditValue?: Maybe<Scalars['Decimal']['output']>;
  coachProduct?: Maybe<CoachProductType>;
  customer?: Maybe<MiniUserType>;
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  workspace?: Maybe<WorkspaceType>;
};

export type CustomerFeedbackInput = {
  content: Scalars['String']['input'];
  sentFromPage?: InputMaybe<Scalars['String']['input']>;
};

/** Deactivates workspace members (soft delete) */
export type DeactivateWorkspaceMembers = {
  __typename?: 'DeactivateWorkspaceMembers';
  deactivatedMembers?: Maybe<Array<Maybe<WorkspaceMemberType>>>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteClientNote = {
  __typename?: 'DeleteClientNote';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Deletes an evaluation criterion and its associated items */
export type DeleteEvaluationCriterion = {
  __typename?: 'DeleteEvaluationCriterion';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteGptResponse = {
  __typename?: 'DeleteGPTResponse';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteGroupMember = {
  __typename?: 'DeleteGroupMember';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Deletes a rating */
export type DeleteRating = {
  __typename?: 'DeleteRating';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Deletes a schedule override */
export type DeleteScheduleOverride = {
  __typename?: 'DeleteScheduleOverride';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Deletes a todo */
export type DeleteTodo = {
  __typename?: 'DeleteTodo';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  previousId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteWorkspaceGroup = {
  __typename?: 'DeleteWorkspaceGroup';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum DetailGroupTypes {
  Check = 'Check',
  Numbered = 'Numbered',
  Timeline = 'Timeline',
}

/** Dismiss the banner for a scenario */
export type DismissScenarioCriteriaBanner = {
  __typename?: 'DismissScenarioCriteriaBanner';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Edit a scenario */
export type EditScenario = {
  __typename?: 'EditScenario';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/**
 *
 *     Represents the various entitlements that can be associated with a subscription plan.
 *
 *     If you add a new one, make sure to update the label method in PlanEntitlement to
 *     display the entitlement in a human-readable format.
 *
 */
export enum Entitlement {
  Admins = 'Admins',
  CustomRoleplays = 'CustomRoleplays',
  Programs = 'Programs',
  RoleplaySessions = 'RoleplaySessions',
  UnlimitedAdmins = 'UnlimitedAdmins',
  UnlimitedRoleplaySessions = 'UnlimitedRoleplaySessions',
}

/** Represents an error in the input of a mutation. */
export type Error = {
  __typename?: 'Error';
  /**
   * Name of a field that caused the error. A value of
   *         `null` indicates that the error isn't associated with a particular
   *         field.
   */
  field?: Maybe<Scalars['String']['output']>;
  /** The error message. */
  message?: Maybe<Scalars['String']['output']>;
};

export type ErrorType = {
  __typename?: 'ErrorType';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type EvaluationCriterionFeedbackExampleType = {
  __typename?: 'EvaluationCriterionFeedbackExampleType';
  createdAt: Scalars['DateTime']['output'];
  feedback?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<RoleplayEvalGrade>;
  id: Scalars['ID']['output'];
  quote: Scalars['String']['output'];
  salience?: Maybe<RoleplaySessionEvaluationInstanceSalienceLevel>;
  suggestionQuote?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type EvaluationCriterionItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type EvaluationCriterionItemType = {
  __typename?: 'EvaluationCriterionItemType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  evaluationCriterion: EvaluationCriterionType;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Order in which this criterion should be displayed */
  order: Scalars['Int']['output'];
  roleplaySessions: Array<RoleplaySessionCriterionType>;
  trigger?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type EvaluationCriterionType = {
  __typename?: 'EvaluationCriterionType';
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Maybe<EvaluationCriterionItemType>>>;
  name: Scalars['String']['output'];
  /** Order in which this criterion should be displayed */
  order: Scalars['Int']['output'];
};

export type ExecuteGptPrompt = {
  __typename?: 'ExecuteGPTPrompt';
  error?: Maybe<ErrorType>;
  gptResponse?: Maybe<GptResponseType>;
};

/** DjangoObjectType to access the FailedLoginHistory model. */
export type FailedLoginHistoryType = {
  __typename?: 'FailedLoginHistoryType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ipAddress: Scalars['String']['output'];
  user: UserType;
};

export type FaqAnswerType = {
  __typename?: 'FaqAnswerType';
  coach?: Maybe<AgentProfileType>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  faqQuestion?: Maybe<FaqQuestionType>;
  id: Scalars['ID']['output'];
  product?: Maybe<ProductType>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserType>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type FaqQuestionType = {
  __typename?: 'FaqQuestionType';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type FaqType = {
  __typename?: 'FaqType';
  category?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  questions?: Maybe<Array<Maybe<CmsFaqQuestionType>>>;
};

export type FieldType = {
  __typename?: 'FieldType';
  /** What type of answer is this? */
  answerType: FormFieldAnswerType;
  answers: Array<FormFieldAnswerAnalyticsType>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  cloneByFormField?: Maybe<FormFieldAnalyticsType>;
  cloneByTemplate?: Maybe<SurveyTemplateType>;
  clones: Array<FormFieldAnalyticsType>;
  createdAt: Scalars['DateTime']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSONString']['output']>;
  options: Array<FormFieldOptionAnalyticsType>;
  order: Scalars['Int']['output'];
  product?: Maybe<ProductType>;
  programSurveys: Array<ProgramSurveyType>;
  prompt: Scalars['String']['output'];
  /** Does the user have to answer this field? */
  required: Scalars['Boolean']['output'];
  scenario?: Maybe<ScenarioType>;
  surveyTemplates: Array<SurveyTemplateType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FindMyAgentCheckout = {
  __typename?: 'FindMyAgentCheckout';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  id?: Maybe<Scalars['String']['output']>;
};

export type FollowCompanyPaginationType = {
  __typename?: 'FollowCompanyPaginationType';
  currentPage?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<FollowCompanyType>>>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export type FollowCompanyType = {
  __typename?: 'FollowCompanyType';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  filterCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
};

export type FormFieldAnalyticsType = {
  __typename?: 'FormFieldAnalyticsType';
  /** What type of answer is this? */
  answerType: FormFieldAnswerType;
  answers?: Maybe<Array<Maybe<FormFieldAnswerAnalyticsType>>>;
  average?: Maybe<Scalars['Float']['output']>;
  favorable?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<LinkFieldType>;
  options?: Maybe<Array<Maybe<FormFieldOptionAnalyticsType>>>;
  order: Scalars['Int']['output'];
  participants?: Maybe<Array<Maybe<AnswerSurveyParticipantType>>>;
  prompt: Scalars['String']['output'];
  totalParticipants?: Maybe<Scalars['Int']['output']>;
  totalResponses?: Maybe<Scalars['Int']['output']>;
};

export type FormFieldAnswerAnalyticsType = {
  __typename?: 'FormFieldAnswerAnalyticsType';
  formField?: Maybe<FormFieldType>;
  id: Scalars['ID']['output'];
  optionValue?: Maybe<FormFieldOptionAnalyticsType>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<MiniUserType>;
  userFile?: Maybe<UserFileType>;
  userId?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum FormFieldAnswerType {
  /** Book Meeting */
  BookMeeting = 'BOOK_MEETING',
  /** File */
  File = 'FILE',
  /** Linkedin */
  Linkedin = 'LINKEDIN',
  /** Multi Line */
  MultiLine = 'MULTI_LINE',
  /** Rating Scale */
  RatingScale = 'RATING_SCALE',
  /** Resume */
  Resume = 'RESUME',
  /** Single Line */
  SingleLine = 'SINGLE_LINE',
  /** Task */
  Task = 'TASK',
  /** Url */
  Url = 'URL',
}

export type FormFieldInput = {
  answerType: Scalars['String']['input'];
  cloneByFormFieldId?: InputMaybe<Scalars['ID']['input']>;
  cloneByTemplateId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<FormFieldInputMetadata>;
  options?: InputMaybe<Array<InputMaybe<FormFieldOptionInput>>>;
  order: Scalars['Int']['input'];
  prompt: Scalars['String']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FormFieldInputMetadata = {
  imageSrc?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type FormFieldOptionAnalyticsType = {
  __typename?: 'FormFieldOptionAnalyticsType';
  /** Explanation for the correct option */
  explanation?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  totalResponses?: Maybe<Scalars['Int']['output']>;
  /**
   *
   *         The value that will be stored in the database;
   *         in case you want the value could be sort like 1, 1.5
   *         let's use the zero as prefix, like 01, 1.5
   *
   */
  value: Scalars['String']['output'];
  valueProportion?: Maybe<Scalars['Float']['output']>;
};

export type FormFieldOptionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type FormFieldOptionType = {
  __typename?: 'FormFieldOptionType';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  /**
   *
   *         The value that will be stored in the database;
   *         in case you want the value could be sort like 1, 1.5
   *         let's use the zero as prefix, like 01, 1.5
   *
   */
  value: Scalars['String']['output'];
};

export type FormFieldType = {
  __typename?: 'FormFieldType';
  /** What type of answer is this? */
  answerType: FormFieldAnswerType;
  cloneByFormField?: Maybe<FormFieldAnalyticsType>;
  cloneByFormFieldId?: Maybe<Scalars['ID']['output']>;
  cloneByTemplate?: Maybe<SurveyTemplateType>;
  cloneByTemplateId?: Maybe<Scalars['ID']['output']>;
  filteredAnswers?: Maybe<Array<Maybe<AnswerType>>>;
  formFieldOptions?: Maybe<Array<Maybe<FormFieldOptionType>>>;
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<LinkFieldType>;
  order: Scalars['Int']['output'];
  prompt: Scalars['String']['output'];
  /** Does the user have to answer this field? */
  required: Scalars['Boolean']['output'];
};

export type FulfillmentFormAnswersInput = {
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type FulfillmentFormType = {
  __typename?: 'FulfillmentFormType';
  coach?: Maybe<MiniCoachType>;
  formFieldAnswers?: Maybe<Array<Maybe<AnswerType>>>;
  id: Scalars['ID']['output'];
  meetingId?: Maybe<Scalars['Int']['output']>;
  missingRequiredAnswers?: Maybe<Scalars['Boolean']['output']>;
  orderItem?: Maybe<OrderItemType>;
  product?: Maybe<ProductType>;
  user?: Maybe<MiniUserType>;
};

/** An enumeration. */
export enum FulfillmentStatusTypes {
  Fulfilled = 'Fulfilled',
  Pending = 'Pending',
}

/** An enumeration. */
export enum GptResponseErrorCode {
  /** Genericerror */
  GenericError = 'GENERIC_ERROR',
  /** Overwhelmed */
  Overwhelmed = 'OVERWHELMED',
  /** Privateoutofbits */
  PrivateOutOfBits = 'PRIVATE_OUT_OF_BITS',
  /** Publicoutofbits */
  PublicOutOfBits = 'PUBLIC_OUT_OF_BITS',
}

/** DjangoObjectType to access the GPTResponse model. */
export type GptResponseType = {
  __typename?: 'GPTResponseType';
  answers?: Maybe<Scalars['JSONString']['output']>;
  createdAt: Scalars['DateTime']['output'];
  errorCode?: Maybe<GptResponseErrorCode>;
  id: Scalars['ID']['output'];
  rating?: Maybe<Scalars['Boolean']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type GeometryType = {
  __typename?: 'GeometryType';
  location?: Maybe<LocationType>;
  viewport?: Maybe<ViewPortType>;
};

export type GooglePlaceAutocomplete = {
  __typename?: 'GooglePlaceAutocomplete';
  predictions?: Maybe<Array<Maybe<PredictionType>>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type GooglePlaceDetail = {
  __typename?: 'GooglePlaceDetail';
  html_attributions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  result?: Maybe<PlaceDetailType>;
  status?: Maybe<Scalars['String']['output']>;
};

/** Regrade a roleplay session */
export type GradeRoleplaySession = {
  __typename?: 'GradeRoleplaySession';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  roleplaySession?: Maybe<RoleplaySessionType>;
};

/** DjangoObjectType to access the Skill model. */
export type IndustryGraphqlType = {
  __typename?: 'IndustryGraphqlType';
  aliases: Array<IndustryGraphqlType>;
  canon?: Maybe<IndustryGraphqlType>;
  companies: Array<CompanyType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  intakeForms: Array<IntakeFormType>;
  name: Scalars['String']['output'];
  /**
   *
   *             Which industries does this coach know best? This won't exclude them
   *             from working with people in other industries.
   *
   */
  relevantAgents: Array<AgentProfileType>;
  /**
   *
   *             Which industries is this product most relevant for?
   *
   */
  relevantProducts: Array<ProductType>;
  updatedAt: Scalars['DateTime']['output'];
};

/** An enumeration. */
export enum IntakeFormCareerStage {
  /** Earlycareer */
  EarlyCareer = 'EARLY_CAREER',
  /** Executive */
  Executive = 'EXECUTIVE',
  /** Experiencedpeoplemanager */
  ExperiencedPeopleManager = 'EXPERIENCED_PEOPLE_MANAGER',
  /** Experiencedindividualcontributor */
  MidCareer = 'MID_CAREER',
  /** Newgrad */
  NewGrad = 'NEW_GRAD',
  /** Newpeoplemanager */
  NewPeopleManager = 'NEW_PEOPLE_MANAGER',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type IntakeFormInput = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  industriesSkipped?: InputMaybe<Scalars['Boolean']['input']>;
  industryIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobFunctionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobFunctionsSkipped?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobTitlesSkipped?: InputMaybe<Scalars['Boolean']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  matchingCategoryId?: InputMaybe<Scalars['ID']['input']>;
  matchingOptionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noteForCoach?: InputMaybe<Scalars['String']['input']>;
  questionnaireSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
};

/** DjangoObjectType to access the IntakeForm model. */
export type IntakeFormType = {
  __typename?: 'IntakeFormType';
  assignment?: Maybe<ChatDirectMessageType>;
  careerStage?: Maybe<IntakeFormCareerStage>;
  coach?: Maybe<AgentType>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  firstMeeting?: Maybe<ChatMeetingType>;
  helpWanted?: Maybe<Array<Scalars['String']['output']>>;
  helpWantedOther?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  industries: Array<IndustryGraphqlType>;
  /** Indicates whether the user skipped adding industries */
  industriesSkipped: Scalars['Boolean']['output'];
  jobFunctions: Array<JobFunctionGraphqlType>;
  /** Indicates whether the user skipped adding job functions */
  jobFunctionsSkipped: Scalars['Boolean']['output'];
  jobTitles: Array<JobTitleType>;
  /** Indicates whether the user skipped adding job titles */
  jobTitlesSkipped: Scalars['Boolean']['output'];
  /** Which of our structured options is this user looking for? */
  matchingOptions: Array<MatchingOptionType>;
  noteForCoach?: Maybe<Scalars['String']['output']>;
  /** Indicates whether the user submitted the after-signup questionnaire */
  questionnaireSubmitted: Scalars['Boolean']['output'];
  specialty?: Maybe<SpecialtyType>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserType>;
  workspaceAssignment?: Maybe<WorkspaceAssignmentType>;
};

/** Mark a feature as introduced */
export type IntroduceFeature = {
  __typename?: 'IntroduceFeature';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum InvitationStatus {
  Failed = 'Failed',
  Init = 'Init',
  Invited = 'Invited',
  Inviting = 'Inviting',
}

/** Invites clients to current workspace */
export type InviteClients = {
  __typename?: 'InviteClients';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  existedEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  invalidEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  toBeInvitedEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Invites staff members to current workspace */
export type InviteStaff = {
  __typename?: 'InviteStaff';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Invite users to a roleplay session */
export type InviteUsersToSession = {
  __typename?: 'InviteUsersToSession';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type InvoiceType = {
  __typename?: 'InvoiceType';
  created?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethodType>;
  periodEnd?: Maybe<Scalars['Int']['output']>;
  periodStart?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Int']['output']>;
};

/** DjangoObjectType to access the Job Function model. */
export type JobFunctionGraphqlType = {
  __typename?: 'JobFunctionGraphqlType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  intakeForms: Array<IntakeFormType>;
  keywords: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /**
   *
   *             Which job function is this coach most experienced with? This won't exclude
   *             them from working with people targetting functions that aren't included.
   *
   */
  relevantAgents: Array<AgentProfileType>;
  /**
   *
   *             Which job function is this product most relevant for?
   *
   */
  relevantProducts: Array<ProductType>;
  /**
   *
   *         Which job functions are import to this Workspace? We will promote
   *         these job functions in the app.
   *
   */
  relevantWorkspaces: Array<PublicWorkspaceGraphqlType>;
  updatedAt: Scalars['DateTime']['output'];
};

/** An enumeration. */
export enum JobStatus {
  AutoTriggered = 'AutoTriggered',
  Canceled = 'Canceled',
  Error = 'Error',
  Handling = 'Handling',
  ManualTriggered = 'ManualTriggered',
  Scheduled = 'Scheduled',
}

export type JobTitleType = {
  __typename?: 'JobTitleType';
  algoliaIndexAllowance: Scalars['Boolean']['output'];
  aliases: Array<JobTitleType>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  intakeForms: Array<IntakeFormType>;
  lemma?: Maybe<JobTitleType>;
  lemmaLastUpdated?: Maybe<Scalars['DateTime']['output']>;
  normalizedTitle: Scalars['String']['output'];
  relatedTitles?: Maybe<Array<Maybe<JobTitleType>>>;
  /**
   *
   *             Which job titles is this coach most experienced with? This won't exclude
   *             them from working with people targetting titles that aren't included.
   *
   */
  relevantAgents: Array<AgentProfileType>;
  sameLevelTitles?: Maybe<Array<Maybe<JobTitleType>>>;
  /** If this is checked, we will automatically scrape job listings for this job title */
  scrapeListings: Scalars['Boolean']['output'];
  /**
   * We use this field to add extra keywords to boost it in search.
   *         For example, 'Vice President' if the title includes VP
   */
  searchHelpers?: Maybe<Scalars['String']['output']>;
  seoSlug?: Maybe<Scalars['String']['output']>;
  similarLevelTitles?: Maybe<Array<Maybe<JobTitleType>>>;
  title: Scalars['String']['output'];
  titleSeoSlug?: Maybe<Scalars['String']['output']>;
  titlesExpandedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usageCount?: Maybe<Scalars['Int']['output']>;
  usageCountLastUpdated?: Maybe<Scalars['DateTime']['output']>;
};

/** An enumeration. */
export enum JobType {
  AddAttendeesToGroupMeeting = 'AddAttendeesToGroupMeeting',
  InviteClientsToWorkspace = 'InviteClientsToWorkspace',
  Sample = 'Sample',
}

export type JournalEntryType = {
  __typename?: 'JournalEntryType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  memo: Scalars['String']['output'];
};

/** An enumeration. */
export enum LandingPageVariantBasePage {
  /** Careercoaching */
  CareerCoaching = 'CAREER_COACHING',
  /** Home */
  Home = 'HOME',
  /** Lifecoaching */
  LifeCoaching = 'LIFE_COACHING',
  /** Pro */
  Pro = 'PRO',
}

/** DjangoObjectType to access the LandingPageVariant model. */
export type LandingPageVariantGraphqlType = {
  __typename?: 'LandingPageVariantGraphqlType';
  basePage: LandingPageVariantBasePage;
  createdAt: Scalars['DateTime']['output'];
  /**
   *
   *         Any HTML will be rendered directly.
   *
   */
  headline: Scalars['String']['output'];
  /**
   *
   *         Headline font font in pixels. For example, 70px should be entered as 70.
   *
   */
  headlineFontSize?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /**
   *
   *         Any HTML will be rendered directly.
   *
   */
  subHeader: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /**
   *
   *         All lower case short name for URL slug. Will look like:
   *
   *         https://www.placement.com/lp/[your-slug-here]
   *
   */
  urlSlug: Scalars['String']['output'];
};

export type LaunchProgram = {
  __typename?: 'LaunchProgram';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type LearnBoardReadStatus = {
  __typename?: 'LearnBoardReadStatus';
  articleSeriesReadStatusItems?: Maybe<Array<Maybe<ArticleSeriesReadStatus>>>;
  readCompletedArticleIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  readInprogressArticleIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type LearnBoardSeries = {
  __typename?: 'LearnBoardSeries';
  otherSeriesItems?: Maybe<Array<Maybe<ArticleSeries>>>;
  popularSeriesItems?: Maybe<Array<Maybe<ArticleSeries>>>;
  recentSeriesItems?: Maybe<Array<Maybe<ArticleSeries>>>;
};

export type LedgerAccountEntryType = {
  __typename?: 'LedgerAccountEntryType';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  journalEntry: JournalEntryType;
};

/** An enumeration. */
export enum LedgerAccountName {
  /** Available */
  Available = 'AVAILABLE',
  /** Bitsavailable */
  BitsAvailable = 'BITS_AVAILABLE',
  /** Bitsdeliverable */
  BitsDeliverable = 'BITS_DELIVERABLE',
  /** Budgetavailable */
  BudgetAvailable = 'BUDGET_AVAILABLE',
  /** Budgetdeliverable */
  BudgetDeliverable = 'BUDGET_DELIVERABLE',
  /** Deliverable */
  Deliverable = 'DELIVERABLE',
  /** Payable */
  Payable = 'PAYABLE',
  /** Receivable */
  Receivable = 'RECEIVABLE',
  /** Reserved */
  Reserved = 'RESERVED',
}

export type LedgerAccountType = {
  __typename?: 'LedgerAccountType';
  balance: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: LedgerAccountName;
  ownerType?: Maybe<Scalars['String']['output']>;
};

export type LinkFieldType = {
  __typename?: 'LinkFieldType';
  imageSrc?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LinkMetadataType = {
  __typename?: 'LinkMetadataType';
  imageSrc?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** DjangoObjectType to access the Link model. */
export type LinkType = {
  __typename?: 'LinkType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  failedCrawlAttempts: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lastParsedAt?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type LocationType = {
  __typename?: 'LocationType';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type Login = {
  __typename?: 'Login';
  currentWorkspaceDomain?: Maybe<Scalars['String']['output']>;
  currentWorkspaceRole?: Maybe<Scalars['String']['output']>;
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  sesameQuery?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  user?: Maybe<UserType>;
  workspaces?: Maybe<Array<Maybe<MiniWorkspace>>>;
};

export type MakePrimaryCalendarAccount = {
  __typename?: 'MakePrimaryCalendarAccount';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MatchedItemType = {
  __typename?: 'MatchedItemType';
  length?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum MatchingCategoryMatchingStrategy {
  /** Exactmatch */
  ExactMatch = 'EXACT_MATCH',
  /** Partialmatch */
  PartialMatch = 'PARTIAL_MATCH',
  /** Productmatch */
  ProductMatch = 'PRODUCT_MATCH',
  /** Rankingsignal */
  RankingSignal = 'RANKING_SIGNAL',
}

/** An enumeration. */
export enum MatchingCategorySelectionType {
  /** Industries */
  Industries = 'INDUSTRIES',
  /** Job Functions */
  JobFunctions = 'JOB_FUNCTIONS',
  /** Job Titles */
  JobTitles = 'JOB_TITLES',
  /** Multiple Choice */
  MultipleChoice = 'MULTIPLE_CHOICE',
  /** Single Choice */
  SingleChoice = 'SINGLE_CHOICE',
}

export type MatchingCategoryType = {
  __typename?: 'MatchingCategoryType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  matchingOptions: Array<MatchingOptionType>;
  matchingStrategy: MatchingCategoryMatchingStrategy;
  name: Scalars['String']['output'];
  selectionType: MatchingCategorySelectionType;
  specialties: Array<SpecialtyType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MatchingOptionType = {
  __typename?: 'MatchingOptionType';
  /**
   *
   *         Which of our structured options is this coach best at?
   *
   */
  agentProfiles: Array<AgentProfileType>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Which of our structured options is this user looking for? */
  intakeForms: Array<IntakeFormType>;
  matchingCategories: Array<MatchingCategoryType>;
  name: Scalars['String']['output'];
  products: Array<ProductType>;
  updatedAt: Scalars['DateTime']['output'];
  /** Is this option visible in the client intake form.? */
  visible: Scalars['Boolean']['output'];
};

export type MeetingAttendeeType = {
  __typename?: 'MeetingAttendeeType';
  attendingStatus?: Maybe<AttendingStatus>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invitationBatchId?: Maybe<Scalars['Int']['output']>;
  meetingId?: Maybe<Scalars['Int']['output']>;
  scheduledJobId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<InvitationStatus>;
  user?: Maybe<MiniUserType>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type MeetingAttendeesQueryResponse = {
  __typename?: 'MeetingAttendeesQueryResponse';
  attendees?: Maybe<Array<Maybe<MeetingAttendeeType>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  owner?: Maybe<MiniUserType>;
  start?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MeetingType = {
  __typename?: 'MeetingType';
  allowGuests?: Maybe<Scalars['Boolean']['output']>;
  allowInvitation: Scalars['Boolean']['output'];
  attendee?: Maybe<MeetingAttendeeType>;
  canLeaveRating?: Maybe<Scalars['Boolean']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  chatChannelId?: Maybe<Scalars['String']['output']>;
  chatChannelTitle?: Maybe<Scalars['String']['output']>;
  coach?: Maybe<AgentType>;
  createdAt: Scalars['DateTime']['output'];
  creditPrice?: Maybe<Scalars['Decimal']['output']>;
  customLocation?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<UserType>;
  customerId?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  eventAt?: Maybe<Scalars['DateTime']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  formFieldAnswers?: Maybe<Array<Maybe<AnswerType>>>;
  googleEventId?: Maybe<Scalars['String']['output']>;
  /** List of guests that are invited to the meeting. */
  guests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  isBookingRequest?: Maybe<Scalars['Boolean']['output']>;
  isInternalMeeting?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  numMessages?: Maybe<Scalars['Int']['output']>;
  paymentStatus?: Maybe<PaymentStatusTypes>;
  product?: Maybe<ProductType>;
  rating?: Maybe<RatingType>;
  refundableUntil?: Maybe<Scalars['DateTime']['output']>;
  topAttendees?: Maybe<Array<Maybe<MeetingAttendeeType>>>;
  workspace?: Maybe<WorkspaceType>;
};

export type MemberMeetingPaginationType = {
  __typename?: 'MemberMeetingPaginationType';
  currentPage?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<MeetingType>>>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export type MiniCoachType = {
  __typename?: 'MiniCoachType';
  coachSlug?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
};

export type MiniUserType = {
  __typename?: 'MiniUserType';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
};

export type MiniWorkspace = {
  __typename?: 'MiniWorkspace';
  domain?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptBookingRequest?: Maybe<AcceptBookingRequest>;
  /** Accepts a review */
  acceptReview?: Maybe<AcceptReview>;
  accountLink?: Maybe<AccountLink>;
  activateWorkspaceMembership?: Maybe<ActivateWorkspaceMembership>;
  /** Add Scenario To Collection */
  addCollectionScenario?: Maybe<AddCollectionScenario>;
  addMeetingAttendees?: Maybe<AddMeetingAttendees>;
  archiveProgram?: Maybe<ArchiveProgram>;
  /** Archives an roleplay session */
  archiveRoleplaySession?: Maybe<ArchiveRoleplaySession>;
  /** Archives a scenario */
  archiveScenario?: Maybe<ArchiveScenario>;
  assignAssistant?: Maybe<AssignAssistant>;
  assignCoaches?: Maybe<AssignCoaches>;
  assignInitialWorkspaceAdmin?: Maybe<AssignInitialWorkspaceAdmin>;
  assignWorkspaceGroup?: Maybe<AssignWorkspaceGroup>;
  authCalendarAccount?: Maybe<AuthCalendarAccount>;
  billingSession?: Maybe<BillingSession>;
  /** Books a review */
  bookReview?: Maybe<BookReview>;
  cancelAttendeesSchedule?: Maybe<CancelAttendeesSchedule>;
  cancelBookingRequest?: Maybe<CancelBookingRequest>;
  cancelCoachMeeting?: Maybe<CancelCoachMeeting>;
  /** Cancel the scheduled clients invites of current workspace */
  cancelInviteClientSchedule?: Maybe<CancelInviteClientSchedule>;
  /** Possibly cancels a review */
  cancelReview?: Maybe<CancelReview>;
  /** Cancels an unpaid order */
  cancelUnpaidOrder?: Maybe<CancelUnpaidOrder>;
  /** Clone a scenario */
  cloneCollection?: Maybe<CloneCollection>;
  cloneProgram?: Maybe<CloneProgram>;
  /** Clone a scenario */
  cloneScenario?: Maybe<CloneScenario>;
  closeProgramComponent?: Maybe<CloseProgramComponent>;
  completeAddToCalendar?: Maybe<CompleteAddToCalendar>;
  completeProgram?: Maybe<CompleteProgram>;
  /** Marks a review round complete */
  completeReviewRound?: Maybe<CompleteReviewRound>;
  completeSetupProgramComponents?: Maybe<CompleteSetupProgramComponents>;
  /** Confirm email and retrieve refresh_token */
  confirmEmailRedirect?: Maybe<ConfirmEmailRedirect>;
  /** Confirms a pending order */
  confirmOrder?: Maybe<ConfirmOrder>;
  /** Copies evaluation criteria to a collection */
  copyEvaluationCriteriaToCollection?: Maybe<CopyEvaluationCriteriaToCollection>;
  /** Copies evaluation criteria to a scenario */
  copyEvaluationCriteriaToScenario?: Maybe<CopyEvaluationCriteriaToScenario>;
  createCoachMeeting?: Maybe<CreateCoachMeeting>;
  /** Creates an evaluation criterion with associated evaluation items */
  createEvaluationCriterion?: Maybe<CreateEvaluationCriterion>;
  /** Creates a new pending order */
  createOrder?: Maybe<CreateOrder>;
  /** Creates a place */
  createPlace?: Maybe<CreatePlace>;
  createProgram?: Maybe<CreateProgram>;
  createProgramUsers?: Maybe<CreateProgramUsers>;
  /** Submits a rating */
  createRating?: Maybe<CreateRating>;
  /** Create new resource permissions for users, groups, or programs */
  createResourcePermissions?: Maybe<CreateResourcePermissions>;
  /** Save roleplay session */
  createRoleplaySession?: Maybe<CreateRoleplaySession>;
  /** Save scenario intake */
  createScenarioIntake?: Maybe<CreateScenarioIntake>;
  createStripeCustomer?: Maybe<CreateStripeCustomer>;
  createWorkspace?: Maybe<CreateWorkspace>;
  createWorkspaceGroup?: Maybe<CreateWorkspaceGroup>;
  /** Deactivates workspace members (soft delete) */
  deactivateWorkspaceMembers?: Maybe<DeactivateWorkspaceMembers>;
  deleteClientNote?: Maybe<DeleteClientNote>;
  /** Deletes an evaluation criterion and its associated items */
  deleteEvaluationCriterion?: Maybe<DeleteEvaluationCriterion>;
  deleteGptResponse?: Maybe<DeleteGptResponse>;
  deleteGroupMember?: Maybe<DeleteGroupMember>;
  /** Deletes a rating */
  deleteRating?: Maybe<DeleteRating>;
  /** Deletes a schedule override */
  deleteScheduleOverride?: Maybe<DeleteScheduleOverride>;
  /** Deletes a todo */
  deleteTodo?: Maybe<DeleteTodo>;
  deleteWorkspace?: Maybe<WorkspaceDelete>;
  deleteWorkspaceGroup?: Maybe<DeleteWorkspaceGroup>;
  /** Dismiss the banner for a scenario */
  dismissScenarioCriteriaBanner?: Maybe<DismissScenarioCriteriaBanner>;
  /** Edit a scenario */
  editScenario?: Maybe<EditScenario>;
  executeGptPrompt?: Maybe<ExecuteGptPrompt>;
  findMyAgentCheckout?: Maybe<FindMyAgentCheckout>;
  /** Regrade a roleplay session */
  gradeRoleplaySession?: Maybe<GradeRoleplaySession>;
  /** Mark a feature as introduced */
  introduceFeature?: Maybe<IntroduceFeature>;
  /** Invites clients to current workspace */
  inviteClients?: Maybe<InviteClients>;
  /** Invites staff members to current workspace */
  inviteStaff?: Maybe<InviteStaff>;
  /** Invite users to a roleplay session */
  inviteUsersToSession?: Maybe<InviteUsersToSession>;
  launchProgram?: Maybe<LaunchProgram>;
  login?: Maybe<Login>;
  makePrimaryCalendarAccount?: Maybe<MakePrimaryCalendarAccount>;
  newSubscription?: Maybe<NewSubscription>;
  parseLinkMetadata?: Maybe<ParseLinkMetadata>;
  plusCheckout?: Maybe<PlusCheckout>;
  processCreditCard?: Maybe<ProcessCreditCard>;
  /** Quick create scenario intake from text */
  quickCreateScenarioIntake?: Maybe<QuickCreateScenarioIntake>;
  rateGptResponse?: Maybe<RateGptResponse>;
  reInvFailedMeetingAttendees?: Maybe<ReInviteFailedMeetingAttendees>;
  /** Attempts to redeem a gift code */
  redeemGiftCode?: Maybe<RedeemGiftCode>;
  redeemPromoCode?: Maybe<RedeemPromoCode>;
  refreshToken?: Maybe<Refresh>;
  /** Removes all evaluation criteria from a target */
  removeAllEvaluationCriteriaFromTarget?: Maybe<RemoveAllEvaluationCriteriaFromTarget>;
  removeAssignment?: Maybe<RemoveAssignment>;
  removeAssistant?: Maybe<RemoveAssistant>;
  removeCalendarAccount?: Maybe<RemoveCalendarAccount>;
  /** Remove an existing collection */
  removeCollection?: Maybe<RemoveCollection>;
  /** Add Scenario To Collection */
  removeCollectionScenario?: Maybe<RemoveCollectionScenario>;
  removeMeetingAttendees?: Maybe<RemoveMeetingAttendees>;
  removePaymentMethod?: Maybe<RemovePaymentMethod>;
  removeProgramComponent?: Maybe<RemoveProgramComponent>;
  removeProgramLead?: Maybe<RemoveProgramLead>;
  removeProgramParticipant?: Maybe<RemoveProgramParticipant>;
  /** Reorders evaluation criteria */
  reorderEvaluationCriteria?: Maybe<ReorderEvaluationCriteria>;
  /** Reorders evaluation criterion items */
  reorderEvaluationCriterionItems?: Maybe<ReorderEvaluationCriterionItems>;
  rescheduleCoachMeeting?: Maybe<RescheduleCoachMeeting>;
  resendEmailConfirmation?: Maybe<ResendEmailConfirmation>;
  resetPassword?: Maybe<ResetPassword>;
  resetPasswordConfirm?: Maybe<ResetPasswordConfirm>;
  /** Retrieve a refresh token from uid and user token */
  resetPasswordRedirect?: Maybe<ResetPasswordRedirect>;
  retryGptResponse?: Maybe<RetryGptResponse>;
  /** Marks a review revision as sent */
  returnReviewRevision?: Maybe<ReturnReviewRevision>;
  /** Revoke access from a roleplay session */
  revokeAccessFromSession?: Maybe<RevokeAccessFromSession>;
  /** Revoke a session */
  revokeSession?: Maybe<RevokeSession>;
  revokeToken?: Maybe<Revoke>;
  saveArticleReadStatus?: Maybe<SaveArticleReadStatus>;
  saveAttendingStatus?: Maybe<SaveAttendingStatus>;
  /** Saves user's calendar settings */
  saveCalendarSettings?: Maybe<SaveCalendarSettings>;
  saveClientNote?: Maybe<SaveClientNote>;
  /** Create a new collection */
  saveCollection?: Maybe<SaveCollection>;
  /** Saves user's feedback */
  saveCustomerFeedback?: Maybe<SaveCustomerFeedback>;
  saveFollowCompany?: Maybe<SaveFollowCompany>;
  /** Saves a product fulfillment form for current user */
  saveFulfillmentForm?: Maybe<SaveFulfillmentForm>;
  /** Saves the intake form for current user */
  saveIntakeForm?: Maybe<SaveIntakeForm>;
  saveMeetingAllowGuests?: Maybe<SaveMeetingAllowGuests>;
  saveMeetingGuests?: Maybe<SaveMeetingGuests>;
  saveMeetingLocation?: Maybe<SaveMeetingLocation>;
  /** Saves user's notification preferences */
  saveNotificationPreferences?: Maybe<SaveNotificationPreferences>;
  saveProgramComponent?: Maybe<SaveProgramComponent>;
  saveProgramSurveyAnswer?: Maybe<SaveProgramSurveyAnswer>;
  /** Saves a prompt answer */
  savePromptAnswer?: Maybe<SavePromptAnswer>;
  /** Save user's quiz answers */
  saveQuizAnswer?: Maybe<SaveQuizAnswer>;
  /** Saves a resume for current user */
  saveResume?: Maybe<SaveResume>;
  /** save user's notes about a scenario */
  saveScenarioNotes?: Maybe<SaveScenarioNotes>;
  /** Saves a set of schedule overrides */
  saveScheduleOverrides?: Maybe<SaveScheduleOverrides>;
  /** Saves a search attempt for current user */
  saveSearch?: Maybe<SaveSearch>;
  /** Saves a session */
  saveSession?: Maybe<SaveSession>;
  /** Saves a todo */
  saveTodo?: Maybe<SaveTodo>;
  sendGoogleInviteCoachMeeting?: Maybe<SendGoogleInviteCoachMeeting>;
  sendNudgeSurvey?: Maybe<SendNudgeSurvey>;
  /** Retrieve refresh_token with sesame authentication */
  sesameRedirect?: Maybe<SesameRedirect>;
  /** Updates the product price charged by a coach for a product */
  setCoachProductPrice?: Maybe<SetCoachProductPrice>;
  /** Sets the price that a customer pays for a product wth a coach */
  setCustomPriceOption?: Maybe<SetCustomPriceOption>;
  setExceptionalRefund?: Maybe<SetExceptionalRefund>;
  setExternalCalendar?: Maybe<SetExternalCalendar>;
  setExtraCredits?: Maybe<SetExtraCredits>;
  setPassword?: Maybe<SetPassword>;
  /** Set credits for workspace group from workspace credits */
  setWorkspaceGroupCredits?: Maybe<SetWorkspaceGroupCredits>;
  /** Set credits for workspace member from workspace credits */
  setWorkspaceMemberCredits?: Maybe<SetWorkspaceMemberCredits>;
  signUp?: Maybe<SignUp>;
  ssoGenerateUrl?: Maybe<SsoGenerateAuthUrl>;
  /** Submits an RFQ */
  submitRfq?: Maybe<SubmitRfq>;
  /** Switch the personality mode of a scenario */
  switchPersonalityMode?: Maybe<SwitchPersonalityMode>;
  toggleAcceptingNewClientsForCoachProduct?: Maybe<ToggleAcceptingNewClientsForCoachProduct>;
  toggleCoachProduct?: Maybe<ToggleCoachProduct>;
  toggleRequireBookingRequestForCoachProduct?: Maybe<ToggleRequireBookingRequestForCoachProduct>;
  /** Toggles a todo */
  toggleTodo?: Maybe<ToggleTodo>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  trackEvent?: Maybe<TrackCustomerEvent>;
  /** Transfers credits from the current user's receivable account to their available account */
  transferCredits?: Maybe<TransferCredits>;
  unassignWorkspaceGroup?: Maybe<UnassignWorkspaceGroup>;
  unfollowCompany?: Maybe<UnfollowCompany>;
  updateAttendeesSchedule?: Maybe<UpdateAttendeeSchedule>;
  updateBufferSettings?: Maybe<UpdateCoachBufferSettings>;
  updateCoachInfo?: Maybe<UpdateCoachInfo>;
  updateCoachProfile?: Maybe<UpdateCoachProfile>;
  /** Allow workspace admin to update collection visibility scope */
  updateCollectionVisibilityScope?: Maybe<UpdateCollectionVisibilityScope>;
  /** Updates an evaluation criterion */
  updateEvaluationCriterion?: Maybe<UpdateEvaluationCriterion>;
  updateGroupRole?: Maybe<UpdateGroupRole>;
  /** Update a member's role */
  updateMemberRole?: Maybe<UpdateMemberRole>;
  /** Updates a pending order */
  updateOrder?: Maybe<UpdateOrder>;
  updateProgram?: Maybe<UpdateProgram>;
  /** Updates a rating */
  updateRating?: Maybe<UpdateRating>;
  /** Updates a rating featured status */
  updateRatingFeatured?: Maybe<UpdateRatingFeatured>;
  /** Update or remove existing resource permissions for users and groups, or change access scope */
  updateResourcePermissions?: Maybe<UpdateResourcePermissions>;
  /** Edit the character of a scenario */
  updateScenarioCharacter?: Maybe<UpdateScenarioCharacter>;
  /** Allow workspace admin to update scenario visibility scope */
  updateScenarioVisibilityScope?: Maybe<UpdateScenarioVisibilityScope>;
  updateStripeAccount?: Maybe<UpdateStripeAccount>;
  /** Updates the current user */
  updateUser?: Maybe<UpdateUser>;
  updateWorkspaceGroup?: Maybe<UpdateWorkspaceGroup>;
  /** Invites clients to current workspace */
  updateWorkspaceInvitationSchedule?: Maybe<UpdateWorkspaceInvitationSchedule>;
  updateWorkspaceName?: Maybe<UpdateWorkspaceName>;
  updateWorkspaceUrlSlug?: Maybe<UpdateWorkspaceUrlSlug>;
  verifyToken?: Maybe<Verify>;
};

export type MutationAcceptBookingRequestArgs = {
  meetingId: Scalars['ID']['input'];
  timeslot: Scalars['DateTime']['input'];
};

export type MutationAcceptReviewArgs = {
  accepted?: InputMaybe<Scalars['Boolean']['input']>;
  reviewId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAccountLinkArgs = {
  returnUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationActivateWorkspaceMembershipArgs = {
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  workspace: Scalars['String']['input'];
};

export type MutationAddCollectionScenarioArgs = {
  roleplaySlug: Scalars['String']['input'];
  scenarioIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationAddMeetingAttendeesArgs = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  meetingId: Scalars['ID']['input'];
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationArchiveProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationArchiveRoleplaySessionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationArchiveScenarioArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAssignAssistantArgs = {
  assistantMemberId: Scalars['ID']['input'];
  clientMemberId: Scalars['ID']['input'];
};

export type MutationAssignCoachesArgs = {
  coachIds: Array<InputMaybe<Scalars['ID']['input']>>;
  customerId: Scalars['ID']['input'];
};

export type MutationAssignInitialWorkspaceAdminArgs = {
  workspaceSignedToken: Scalars['String']['input'];
};

export type MutationAssignWorkspaceGroupArgs = {
  groupId: Scalars['ID']['input'];
  memberIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationAuthCalendarAccountArgs = {
  accountType: Scalars['String']['input'];
  authCode?: InputMaybe<Scalars['String']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};

export type MutationBillingSessionArgs = {
  returnUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationBookReviewArgs = {
  coachId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
  formData?: InputMaybe<Scalars['GenericScalar']['input']>;
  ledgerAccountId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethod?: InputMaybe<PaymentMethodTypes>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCancelAttendeesScheduleArgs = {
  meetingId: Scalars['ID']['input'];
  scheduledJobId: Scalars['ID']['input'];
};

export type MutationCancelBookingRequestArgs = {
  meetingId: Scalars['ID']['input'];
};

export type MutationCancelCoachMeetingArgs = {
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  meetingId: Scalars['ID']['input'];
};

export type MutationCancelInviteClientScheduleArgs = {
  scheduleJobId: Scalars['ID']['input'];
};

export type MutationCancelReviewArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  reviewId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCancelUnpaidOrderArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCloneCollectionArgs = {
  collectionId: Scalars['ID']['input'];
  emojiUnicode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  visibilityScope: VisibilityScope;
};

export type MutationCloneProgramArgs = {
  name: Scalars['String']['input'];
  programId: Scalars['ID']['input'];
  targetLaunchDate: Scalars['DateTime']['input'];
};

export type MutationCloneScenarioArgs = {
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  scenarioId: Scalars['ID']['input'];
  visibilityScope: VisibilityScope;
};

export type MutationCloseProgramComponentArgs = {
  isReleaseDependentComponents?: InputMaybe<Scalars['Boolean']['input']>;
  programComponentId: Scalars['ID']['input'];
  programId: Scalars['ID']['input'];
};

export type MutationCompleteAddToCalendarArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationCompleteProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationCompleteReviewRoundArgs = {
  reviewId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCompleteSetupProgramComponentsArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationConfirmEmailRedirectArgs = {
  confirmationKey: Scalars['String']['input'];
  workspace?: InputMaybe<Scalars['String']['input']>;
};

export type MutationConfirmOrderArgs = {
  affirmCheckoutToken?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ledgerAccountId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethod?: InputMaybe<PaymentMethodTypes>;
};

export type MutationCopyEvaluationCriteriaToCollectionArgs = {
  collectionId: Scalars['ID']['input'];
  evaluationCriterionIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationCopyEvaluationCriteriaToScenarioArgs = {
  evaluationCriterionIds: Array<InputMaybe<Scalars['ID']['input']>>;
  scenarioId: Scalars['ID']['input'];
};

export type MutationCreateCoachMeetingArgs = {
  coachId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
  formData?: InputMaybe<Scalars['GenericScalar']['input']>;
  fulfillmentFormId?: InputMaybe<Scalars['ID']['input']>;
  ledgerAccountId?: InputMaybe<Scalars['ID']['input']>;
  orderItemId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
  startTime: Scalars['DateTime']['input'];
  timeslotsStrings?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  usedAllTimeslot?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateEvaluationCriterionArgs = {
  evaluationCriterionItems: Array<InputMaybe<EvaluationCriterionItemInput>>;
  name: Scalars['String']['input'];
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  scenarioId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateOrderArgs = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  productQuantities: Scalars['GenericScalar']['input'];
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  timeslotsStrings?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  usedAllTimeslot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreatePlaceArgs = {
  input: PlaceInput;
};

export type MutationCreateProgramArgs = {
  name: Scalars['String']['input'];
  targetLaunchDate: Scalars['DateTime']['input'];
};

export type MutationCreateProgramUsersArgs = {
  credit?: InputMaybe<Scalars['Decimal']['input']>;
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  programId: Scalars['ID']['input'];
  role?: InputMaybe<ProgramUserRole>;
};

export type MutationCreateRatingArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  meetingId?: InputMaybe<Scalars['ID']['input']>;
  privateComment?: InputMaybe<Scalars['String']['input']>;
  reviewId?: InputMaybe<Scalars['ID']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateResourcePermissionsArgs = {
  input: CreateResourcePermissionsInput;
};

export type MutationCreateRoleplaySessionArgs = {
  callId: Scalars['String']['input'];
  roleplaySlug?: InputMaybe<Scalars['String']['input']>;
  scenarioId: Scalars['ID']['input'];
};

export type MutationCreateScenarioIntakeArgs = {
  callId: Scalars['String']['input'];
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateWorkspaceArgs = {
  name: Scalars['String']['input'];
  refCode?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type MutationCreateWorkspaceGroupArgs = {
  config?: InputMaybe<ConfigurationInput>;
  name: Scalars['String']['input'];
};

export type MutationDeactivateWorkspaceMembersArgs = {
  workspaceMemberIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDeleteClientNoteArgs = {
  noteId: Scalars['ID']['input'];
};

export type MutationDeleteEvaluationCriterionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteGptResponseArgs = {
  gptResponseUuid: Scalars['String']['input'];
};

export type MutationDeleteGroupMemberArgs = {
  groupMemberId: Scalars['ID']['input'];
};

export type MutationDeleteRatingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteScheduleOverrideArgs = {
  date: Scalars['Date']['input'];
};

export type MutationDeleteTodoArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  todoId: Scalars['ID']['input'];
};

export type MutationDeleteWorkspaceGroupArgs = {
  groupId: Scalars['ID']['input'];
};

export type MutationDismissScenarioCriteriaBannerArgs = {
  scenarioId: Scalars['ID']['input'];
};

export type MutationEditScenarioArgs = {
  context: Scalars['String']['input'];
  name: Scalars['String']['input'];
  objective: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type MutationExecuteGptPromptArgs = {
  answers: Scalars['GenericScalar']['input'];
  entryId: Scalars['ID']['input'];
};

export type MutationFindMyAgentCheckoutArgs = {
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  ctaSource?: InputMaybe<Scalars['String']['input']>;
  ctaType?: InputMaybe<Scalars['String']['input']>;
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationGradeRoleplaySessionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationIntroduceFeatureArgs = {
  featureType?: InputMaybe<Scalars['String']['input']>;
};

export type MutationInviteClientsArgs = {
  coachIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  credit?: InputMaybe<Scalars['Decimal']['input']>;
  emails: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['ID']['input']>;
  isSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationInviteStaffArgs = {
  emails: Scalars['String']['input'];
};

export type MutationInviteUsersToSessionArgs = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  isNotified?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['ID']['input'];
};

export type MutationLaunchProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  workspace?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMakePrimaryCalendarAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationNewSubscriptionArgs = {
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  ctaSource?: InputMaybe<Scalars['String']['input']>;
  ctaType?: InputMaybe<Scalars['String']['input']>;
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationParseLinkMetadataArgs = {
  link: Scalars['String']['input'];
};

export type MutationPlusCheckoutArgs = {
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  ctaSource?: InputMaybe<Scalars['String']['input']>;
  ctaType?: InputMaybe<Scalars['String']['input']>;
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationProcessCreditCardArgs = {
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationQuickCreateScenarioIntakeArgs = {
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  scenarioPrompt: Scalars['String']['input'];
};

export type MutationRateGptResponseArgs = {
  gptResponseUuid: Scalars['String']['input'];
  rating: Scalars['Boolean']['input'];
};

export type MutationReInvFailedMeetingAttendeesArgs = {
  meetingId: Scalars['ID']['input'];
};

export type MutationRedeemGiftCodeArgs = {
  code: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRedeemPromoCodeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRemoveAllEvaluationCriteriaFromTargetArgs = {
  targetId: Scalars['ID']['input'];
  targetType: TargetType;
};

export type MutationRemoveAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveAssistantArgs = {
  assistantMemberId: Scalars['ID']['input'];
  clientMemberId: Scalars['ID']['input'];
};

export type MutationRemoveCalendarAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRemoveCollectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveCollectionScenarioArgs = {
  roleplaySlug: Scalars['String']['input'];
  scenarioIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationRemoveMeetingAttendeesArgs = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  meetingId: Scalars['ID']['input'];
};

export type MutationRemovePaymentMethodArgs = {
  stripeId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRemoveProgramComponentArgs = {
  isReleaseDependentComponents?: InputMaybe<Scalars['Boolean']['input']>;
  programComponentId: Scalars['ID']['input'];
  programId: Scalars['ID']['input'];
};

export type MutationRemoveProgramLeadArgs = {
  programId: Scalars['ID']['input'];
  programUserId: Scalars['ID']['input'];
};

export type MutationRemoveProgramParticipantArgs = {
  programId: Scalars['ID']['input'];
  programUserId: Scalars['ID']['input'];
};

export type MutationReorderEvaluationCriteriaArgs = {
  items: Array<InputMaybe<ReorderItemInput>>;
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  scenarioId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationReorderEvaluationCriterionItemsArgs = {
  items: Array<InputMaybe<ReorderItemInput>>;
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  scenarioId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRescheduleCoachMeetingArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  forceRefund?: InputMaybe<Scalars['Boolean']['input']>;
  meetingId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['DateTime']['input'];
  usedAllTimeslot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationResetPasswordArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  nextPath?: InputMaybe<Scalars['String']['input']>;
};

export type MutationResetPasswordConfirmArgs = {
  newPassword1: Scalars['String']['input'];
  newPassword2: Scalars['String']['input'];
  uidb64: Scalars['String']['input'];
};

export type MutationResetPasswordRedirectArgs = {
  token: Scalars['String']['input'];
  uidb64: Scalars['String']['input'];
};

export type MutationRetryGptResponseArgs = {
  gptResponseUuid: Scalars['String']['input'];
};

export type MutationReturnReviewRevisionArgs = {
  reviewId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRevokeAccessFromSessionArgs = {
  sessionId: Scalars['ID']['input'];
  workspaceMemberId: Scalars['ID']['input'];
};

export type MutationRevokeSessionArgs = {
  sessionId: Scalars['String']['input'];
};

export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveArticleReadStatusArgs = {
  articleId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type MutationSaveAttendingStatusArgs = {
  attendeeId: Scalars['ID']['input'];
  attendingStatus: AttendingStatus;
};

export type MutationSaveCalendarSettingsArgs = {
  input: CalendarSettingInput;
};

export type MutationSaveClientNoteArgs = {
  customerId: Scalars['ID']['input'];
  noteContent: Scalars['String']['input'];
  noteId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationSaveCollectionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  emojiUnicode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  introduction?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type MutationSaveCustomerFeedbackArgs = {
  input: CustomerFeedbackInput;
};

export type MutationSaveFollowCompanyArgs = {
  companyId: Scalars['ID']['input'];
  customerId: Scalars['ID']['input'];
};

export type MutationSaveFulfillmentFormArgs = {
  answerData?: InputMaybe<Array<InputMaybe<FulfillmentFormAnswersInput>>>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  formId: Scalars['ID']['input'];
};

export type MutationSaveIntakeFormArgs = {
  input: IntakeFormInput;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  productSlug?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveMeetingAllowGuestsArgs = {
  allowGuests: Scalars['Boolean']['input'];
  meetingId: Scalars['ID']['input'];
};

export type MutationSaveMeetingGuestsArgs = {
  guests?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meetingId: Scalars['ID']['input'];
};

export type MutationSaveMeetingLocationArgs = {
  customLocation?: InputMaybe<Scalars['String']['input']>;
  meetingId: Scalars['ID']['input'];
};

export type MutationSaveNotificationPreferencesArgs = {
  input: NotificationPreferenceInput;
};

export type MutationSaveProgramComponentArgs = {
  input: ProgramComponentInput;
  programId: Scalars['ID']['input'];
};

export type MutationSaveProgramSurveyAnswerArgs = {
  answerData?: InputMaybe<Array<InputMaybe<SurveyFormFieldAnswersInput>>>;
  surveyId: Scalars['ID']['input'];
};

export type MutationSavePromptAnswerArgs = {
  answer: Scalars['String']['input'];
  entryId: Scalars['ID']['input'];
};

export type MutationSaveQuizAnswerArgs = {
  formFieldId: Scalars['ID']['input'];
  optionValueId: Scalars['ID']['input'];
  roleplaySessionId: Scalars['ID']['input'];
};

export type MutationSaveResumeArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveScenarioNotesArgs = {
  content: Scalars['String']['input'];
  scenarioId: Scalars['ID']['input'];
};

export type MutationSaveScheduleOverridesArgs = {
  dates: Array<InputMaybe<Scalars['Date']['input']>>;
  timeslots: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationSaveSearchArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  selectedResult?: InputMaybe<Scalars['JSONString']['input']>;
  selectedResultType?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveSessionArgs = {
  impersonatorId?: InputMaybe<Scalars['Int']['input']>;
  sessionId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  workspaceSlug?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveTodoArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  input: TodoInput;
};

export type MutationSendGoogleInviteCoachMeetingArgs = {
  meetingId: Scalars['ID']['input'];
};

export type MutationSendNudgeSurveyArgs = {
  componentId: Scalars['ID']['input'];
  participantId: Scalars['ID']['input'];
};

export type MutationSesameRedirectArgs = {
  sesame?: InputMaybe<Scalars['String']['input']>;
  urlAuthToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSetCoachProductPriceArgs = {
  legacyCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  price: Scalars['Decimal']['input'];
  productId: Scalars['Int']['input'];
};

export type MutationSetCustomPriceOptionArgs = {
  customerId: Scalars['ID']['input'];
  price?: InputMaybe<Scalars['Decimal']['input']>;
  productId: Scalars['ID']['input'];
  useDefault?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationSetExceptionalRefundArgs = {
  meetingId: Scalars['ID']['input'];
};

export type MutationSetExternalCalendarArgs = {
  calendarAccountId?: InputMaybe<Scalars['ID']['input']>;
  calendars?: InputMaybe<Array<InputMaybe<CalendarInputType>>>;
};

export type MutationSetExtraCreditsArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  numCredits?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationSetPasswordArgs = {
  newPassword1: Scalars['String']['input'];
  newPassword2: Scalars['String']['input'];
};

export type MutationSetWorkspaceGroupCreditsArgs = {
  amount: Scalars['Decimal']['input'];
  groupIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationSetWorkspaceMemberCreditsArgs = {
  amount: Scalars['Decimal']['input'];
  clientIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationSignUpArgs = {
  asWorkspaceAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  coach?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstPage?: InputMaybe<Scalars['String']['input']>;
  forcePlacementPricing?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  giftCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  refcode?: InputMaybe<Scalars['String']['input']>;
  returnToken?: InputMaybe<Scalars['Boolean']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
  workspaceSignedToken?: InputMaybe<Scalars['String']['input']>;
  wsToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSsoGenerateUrlArgs = {
  workspaceSlug: Scalars['String']['input'];
};

export type MutationSubmitRfqArgs = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  formData?: InputMaybe<Scalars['GenericScalar']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationSwitchPersonalityModeArgs = {
  slug: Scalars['String']['input'];
};

export type MutationToggleAcceptingNewClientsForCoachProductArgs = {
  productSlug: Scalars['String']['input'];
};

export type MutationToggleCoachProductArgs = {
  productSlug: Scalars['String']['input'];
};

export type MutationToggleRequireBookingRequestForCoachProductArgs = {
  productSlug: Scalars['String']['input'];
};

export type MutationToggleTodoArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  todoId: Scalars['ID']['input'];
};

export type MutationTokenAuthArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationTrackEventArgs = {
  input: TrackCustomerEventInput;
};

export type MutationTransferCreditsArgs = {
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  fromId?: InputMaybe<Scalars['ID']['input']>;
  fromKey?: InputMaybe<Scalars['String']['input']>;
  toId?: InputMaybe<Scalars['ID']['input']>;
  toKey?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUnassignWorkspaceGroupArgs = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  memberIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationUnfollowCompanyArgs = {
  companyId: Scalars['ID']['input'];
  customerId: Scalars['ID']['input'];
};

export type MutationUpdateAttendeesScheduleArgs = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  meetingId: Scalars['ID']['input'];
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledJobId: Scalars['ID']['input'];
};

export type MutationUpdateBufferSettingsArgs = {
  bufferAfter: Scalars['Int']['input'];
  bufferBefore: Scalars['Int']['input'];
  minimumMeetingNotice: Scalars['Int']['input'];
  minuteIncrement: Scalars['Int']['input'];
};

export type MutationUpdateCoachInfoArgs = {
  about: Scalars['String']['input'];
  industryIds: Array<InputMaybe<Scalars['String']['input']>>;
  jobFunctionIds: Array<InputMaybe<Scalars['String']['input']>>;
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
  summary: Scalars['String']['input'];
};

export type MutationUpdateCoachProfileArgs = {
  acceptingNewBookings?: InputMaybe<Scalars['Boolean']['input']>;
  acceptingNewClients?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateCollectionVisibilityScopeArgs = {
  collectionId: Scalars['ID']['input'];
  visibilityScope: VisibilityScope;
};

export type MutationUpdateEvaluationCriterionArgs = {
  evaluationCriterionItems: Array<InputMaybe<EvaluationCriterionItemInput>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  scenarioId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateGroupRoleArgs = {
  groupMemberId: Scalars['ID']['input'];
  isAdmin: Scalars['Boolean']['input'];
};

export type MutationUpdateMemberRoleArgs = {
  memberId: Scalars['ID']['input'];
  role: Scalars['String']['input'];
};

export type MutationUpdateOrderArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  productQuantities: Scalars['GenericScalar']['input'];
};

export type MutationUpdateProgramArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  programId: Scalars['ID']['input'];
  scheduleLaunchEmail?: InputMaybe<Scalars['Boolean']['input']>;
  targetLaunchDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationUpdateRatingArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  privateComment?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateRatingFeaturedArgs = {
  id: Scalars['ID']['input'];
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateResourcePermissionsArgs = {
  input: UpdateResourcePermissionsInput;
};

export type MutationUpdateScenarioCharacterArgs = {
  personaName?: InputMaybe<Scalars['String']['input']>;
  personalityCharacteristics?: InputMaybe<Scalars['String']['input']>;
  personalityTraits?: InputMaybe<Array<InputMaybe<PersonalityTraitInput>>>;
  slug: Scalars['String']['input'];
  voiceId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateScenarioVisibilityScopeArgs = {
  scenarioId: Scalars['ID']['input'];
  visibilityScope: VisibilityScope;
};

export type MutationUpdateUserArgs = {
  input: UserInput;
};

export type MutationUpdateWorkspaceGroupArgs = {
  config?: InputMaybe<ConfigurationInput>;
  groupId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type MutationUpdateWorkspaceInvitationScheduleArgs = {
  coachIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  credit?: InputMaybe<Scalars['Decimal']['input']>;
  emails: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['ID']['input']>;
  isSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledJobId: Scalars['ID']['input'];
};

export type MutationUpdateWorkspaceNameArgs = {
  name: Scalars['String']['input'];
};

export type MutationUpdateWorkspaceUrlSlugArgs = {
  slug: Scalars['String']['input'];
};

export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type NewSubscription = {
  __typename?: 'NewSubscription';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  url?: Maybe<Scalars['String']['output']>;
};

/** DjangoObjectType to access the NotificationPreference model. */
export type NotificationPreferenceGraphqlType = {
  __typename?: 'NotificationPreferenceGraphqlType';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastSentAt?: Maybe<Scalars['DateTime']['output']>;
  sms: Scalars['Boolean']['output'];
  type: NotificationPreferenceType;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
};

export type NotificationPreferenceInput = {
  announcementsEnabled: Scalars['Boolean']['input'];
  directMessagesEnabled: Scalars['Boolean']['input'];
  generalChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  meetingMessagesEnabled: Scalars['Boolean']['input'];
  reviewMessagesEnabled: Scalars['Boolean']['input'];
};

/** An enumeration. */
export enum NotificationPreferenceType {
  /** Announcements */
  Announcements = 'ANNOUNCEMENTS',
  /** Directmessage */
  DirectMessage = 'DIRECT_MESSAGE',
  /** Followedboard */
  FollowedBoard = 'FOLLOWED_BOARD',
  /** Followedcompany */
  FollowedCompany = 'FOLLOWED_COMPANY',
  /** Generalchat */
  GeneralChat = 'GENERAL_CHAT',
  /** Groupchat */
  GroupChat = 'GROUP_CHAT',
  /** Meetingmessage */
  MeetingMessage = 'MEETING_MESSAGE',
  /** Oppcomments */
  OppComments = 'OPP_COMMENTS',
  /** Practicefeedback */
  PracticeFeedback = 'PRACTICE_FEEDBACK',
  /** Reviewmessage */
  ReviewMessage = 'REVIEW_MESSAGE',
  /** Weeklyplanning */
  WeeklyPlanning = 'WEEKLY_PLANNING',
  /** Weeklyrecap */
  WeeklyRecap = 'WEEKLY_RECAP',
}

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** An enumeration. */
export enum OrderItemRefundMethodType {
  /** Affirm */
  Affirm = 'AFFIRM',
  /** Credits */
  Credits = 'CREDITS',
  /** Stripe */
  Stripe = 'STRIPE',
  /** Stripesubscription */
  StripeSubscription = 'STRIPE_SUBSCRIPTION',
}

/** An enumeration. */
export enum OrderItemStatus {
  /** Clawedback */
  ClawedBack = 'CLAWED_BACK',
  /** Deducted */
  Deducted = 'DEDUCTED',
  /** Distributed */
  Distributed = 'DISTRIBUTED',
  /** Refunded */
  Refunded = 'REFUNDED',
  /** Unprocessed */
  Unprocessed = 'UNPROCESSED',
}

export type OrderItemType = {
  __typename?: 'OrderItemType';
  bitDistributionJournalEntry?: Maybe<JournalEntryType>;
  clawbackJournalEntry?: Maybe<JournalEntryType>;
  coachClawbackJournalEntry?: Maybe<JournalEntryType>;
  coachCreditValue?: Maybe<Scalars['Float']['output']>;
  coachDistributionJournalEntry?: Maybe<JournalEntryType>;
  createdAt: Scalars['DateTime']['output'];
  creditPrice?: Maybe<Scalars['Float']['output']>;
  creditTotal?: Maybe<Scalars['Float']['output']>;
  creditTotalInDollars?: Maybe<Scalars['Float']['output']>;
  customerAcquisitionBonusPaidAt?: Maybe<Scalars['DateTime']['output']>;
  customerConversionBonusPaidAt?: Maybe<Scalars['DateTime']['output']>;
  deductionJournalEntry?: Maybe<JournalEntryType>;
  distributionJournalEntry?: Maybe<JournalEntryType>;
  formFieldAnswers: Array<FormFieldAnswerAnalyticsType>;
  fulfillmentForm?: Maybe<FulfillmentFormType>;
  fulfillmentStatus: FulfillmentStatusTypes;
  grossCreditTotal?: Maybe<Scalars['Float']['output']>;
  grossCreditTotalInDollars?: Maybe<Scalars['Float']['output']>;
  grossTotal?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  latestMeetingId?: Maybe<Scalars['ID']['output']>;
  latestReviewId?: Maybe<Scalars['ID']['output']>;
  meetings: Array<ChatMeetingType>;
  order: OrderType;
  orderId?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  product: ProductType;
  productName?: Maybe<Scalars['String']['output']>;
  promoCreditDiscount?: Maybe<Scalars['Float']['output']>;
  promoCreditTotalInDollarsDiscount?: Maybe<Scalars['Float']['output']>;
  promoDiscount?: Maybe<Scalars['Float']['output']>;
  refundJournalEntry?: Maybe<JournalEntryType>;
  refundMethodType?: Maybe<OrderItemRefundMethodType>;
  reviews: Array<ChatReviewType>;
  status: OrderItemStatus;
  total?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** An enumeration. */
export enum OrderPaymentStatus {
  /** Paid */
  Paid = 'PAID',
  /** Unpaid */
  Unpaid = 'UNPAID',
}

/** An enumeration. */
export enum OrderStatus {
  /** Canceled */
  Canceled = 'CANCELED',
  /** Confirmed */
  Confirmed = 'CONFIRMED',
  /** Pending */
  Pending = 'PENDING',
}

export type OrderType = {
  __typename?: 'OrderType';
  cancelIfUnpaidBy?: Maybe<Scalars['DateTime']['output']>;
  coach?: Maybe<AgentType>;
  creditTotal?: Maybe<Scalars['Float']['output']>;
  creditTotalInDollars?: Maybe<Scalars['Float']['output']>;
  errorMessages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  grossCreditTotal?: Maybe<Scalars['Float']['output']>;
  grossCreditTotalInDollars?: Maybe<Scalars['Float']['output']>;
  grossTotal?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  incompleteFulfillmentForms?: Maybe<Array<Maybe<FulfillmentFormType>>>;
  key?: Maybe<Scalars['String']['output']>;
  ledgerAccount?: Maybe<LedgerAccountType>;
  ledgerAccountId?: Maybe<Scalars['ID']['output']>;
  orderItems?: Maybe<Array<Maybe<OrderItemType>>>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  paymentStatus: OrderPaymentStatus;
  paymentType?: Maybe<Scalars['String']['output']>;
  promoCode?: Maybe<PromoCodeType>;
  promoCreditDiscount?: Maybe<Scalars['Float']['output']>;
  promoCreditTotalInDollarsDiscount?: Maybe<Scalars['Float']['output']>;
  promoDiscount?: Maybe<Scalars['Float']['output']>;
  recommendations?: Maybe<Array<Maybe<ProductType>>>;
  status: OrderStatus;
  stripeClientSecret?: Maybe<Scalars['String']['output']>;
  stripePaymentIntentId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  unmatchedFulfillmentForms?: Maybe<Array<Maybe<FulfillmentFormType>>>;
  user?: Maybe<UserType>;
  workspace?: Maybe<PublicWorkspaceGraphqlType>;
};

export type ParseLinkMetadata = {
  __typename?: 'ParseLinkMetadata';
  data?: Maybe<LinkMetadataType>;
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum PaymentMethodPaymentMethodType {
  /** Achdebit */
  AchDebit = 'ACH_DEBIT',
  /** Creditcard */
  CreditCard = 'CREDIT_CARD',
  /** Plaid */
  Plaid = 'PLAID',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  accessToken?: Maybe<Scalars['String']['output']>;
  accountMask?: Maybe<Scalars['String']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  chargeFailedAt?: Maybe<Scalars['DateTime']['output']>;
  chargeFailureReason?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  hasPaymentIssues?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  paymentMethodType?: Maybe<PaymentMethodPaymentMethodType>;
  plaidItemId?: Maybe<Scalars['String']['output']>;
  stripeBankAccountToken?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
};

/** An enumeration. */
export enum PaymentMethodTypes {
  Affirm = 'Affirm',
  Credits = 'Credits',
  Stripe = 'Stripe',
  StripeSubscription = 'StripeSubscription',
}

/** An enumeration. */
export enum PaymentStatusTypes {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
}

/** An enumeration. */
export enum PermissionLevel {
  Edit = 'EDIT',
  Share = 'SHARE',
  View = 'VIEW',
}

export type PermissionUpdateInput = {
  id: Scalars['ID']['input'];
  permissionLevel?: InputMaybe<PermissionLevel>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PersonalityAttributeLevelType = {
  __typename?: 'PersonalityAttributeLevelType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PersonalityAttributeType = {
  __typename?: 'PersonalityAttributeType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  levels?: Maybe<Array<Maybe<PersonalityAttributeLevelType>>>;
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PersonalityTraitInput = {
  attributeId?: InputMaybe<Scalars['String']['input']>;
  levelId?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoType = {
  __typename?: 'PhotoType';
  height?: Maybe<Scalars['Int']['output']>;
  html_attributions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  photo_reference?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum PlaceCountryCode {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syria */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Türkiye */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}

export type PlaceDetailType = {
  __typename?: 'PlaceDetailType';
  address_components?: Maybe<Array<Maybe<AddressType>>>;
  adr_address?: Maybe<Scalars['String']['output']>;
  formatted_address?: Maybe<Scalars['String']['output']>;
  geometry?: Maybe<GeometryType>;
  icon?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photos?: Maybe<Array<Maybe<PhotoType>>>;
  place_id?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  url?: Maybe<Scalars['String']['output']>;
  utc_offset?: Maybe<Scalars['Int']['output']>;
  vicinity?: Maybe<Scalars['String']['output']>;
};

export type PlaceInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  googlePlaceId: Scalars['ID']['input'];
  location?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  seoSlug?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** DjangoObjectType to access the Place model. */
export type PlaceType = {
  __typename?: 'PlaceType';
  aliases: Array<Maybe<Scalars['String']['output']>>;
  bigImage?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  coli?: Maybe<Scalars['Float']['output']>;
  companies: Array<CompanyType>;
  countryCode: PlaceCountryCode;
  county?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  displayPhoto?: Maybe<Scalars['String']['output']>;
  googlePlaceId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  /** If this is checked, The place is only for working remotely. */
  isRemote: Scalars['Boolean']['output'];
  location?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  placeSeoSlug?: Maybe<Scalars['String']['output']>;
  /**
   *
   *             Which places does this coach know best? This won't exclude them
   *             from working with people targetting titles that aren't included.
   *
   */
  relevantAgents: Array<AgentProfileType>;
  seoImage?: Maybe<Scalars['String']['output']>;
  seoPhoto?: Maybe<Scalars['String']['output']>;
  seoSlug?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type PlanEntitlementType = {
  __typename?: 'PlanEntitlementType';
  booleanValue?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  maxUsageValue?: Maybe<Scalars['Int']['output']>;
};

export type PlusCheckout = {
  __typename?: 'PlusCheckout';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  id?: Maybe<Scalars['String']['output']>;
};

export type PredictionType = {
  __typename?: 'PredictionType';
  description?: Maybe<Scalars['String']['output']>;
  matched_substrings?: Maybe<Array<Maybe<MatchedItemType>>>;
  place_id?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  structured_formating?: Maybe<Array<Maybe<StructuredFormattingType>>>;
  terms?: Maybe<Array<Maybe<MatchedItemType>>>;
  types?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ProcessCreditCard = {
  __typename?: 'ProcessCreditCard';
  error?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum ProductCategories {
  Bits = 'Bits',
  Credits = 'Credits',
  ExtraCredits = 'ExtraCredits',
  GroupMeeting = 'GroupMeeting',
  Meeting = 'Meeting',
  Review = 'Review',
}

/** An enumeration. */
export enum ProductCategory {
  /** Bits */
  Bits = 'BITS',
  /** Credits */
  Credits = 'CREDITS',
  /** Extra Credits */
  ExtraCredits = 'EXTRA_CREDITS',
  /** Group Meeting */
  GroupMeeting = 'GROUP_MEETING',
  /** Meeting */
  Meeting = 'MEETING',
  /** Review */
  Review = 'REVIEW',
}

export type ProductDetailGroupType = {
  __typename?: 'ProductDetailGroupType';
  displayType?: Maybe<DetailGroupTypes>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type ProductDetailLineType = {
  __typename?: 'ProductDetailLineType';
  detailGroupId?: Maybe<Scalars['ID']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type ProductEndorsementType = {
  __typename?: 'ProductEndorsementType';
  company?: Maybe<CompanyType>;
  id: Scalars['ID']['output'];
};

/** An enumeration. */
export enum ProductLockReason {
  AboveMaxPrice = 'AboveMaxPrice',
  AlreadyHadIntro = 'AlreadyHadIntro',
  NotAcceptingBookings = 'NotAcceptingBookings',
  OnlyExistingCustomers = 'OnlyExistingCustomers',
}

export type ProductPriceRangeType = {
  __typename?: 'ProductPriceRangeType';
  maxCreditPrice?: Maybe<Scalars['Decimal']['output']>;
  maxUsdPrice?: Maybe<Scalars['Decimal']['output']>;
  minCreditPrice?: Maybe<Scalars['Decimal']['output']>;
  minUsdPrice?: Maybe<Scalars['Decimal']['output']>;
};

/** An enumeration. */
export enum ProductPrimarySite {
  /** Exec */
  Exec = 'EXEC',
  /** Placement */
  Placement = 'PLACEMENT',
}

export type ProductType = {
  __typename?: 'ProductType';
  activeFormFields?: Maybe<Array<Maybe<FormFieldType>>>;
  averageRating?: Maybe<Scalars['Float']['output']>;
  /**
   *
   *         What is the minimum number of people required for this to be successful?
   *         This is also how many people are assumed to be included in the base price.
   *
   */
  baseParticipants?: Maybe<Scalars['Int']['output']>;
  /**
   *
   *         How many bits does the customer get when they _buy_ this product?
   *
   */
  bitValue?: Maybe<Scalars['Float']['output']>;
  /**
   *
   *         How much notice (in hours) must the user give to receive a refund
   *         when canceling or rescheduling this meeting type? Our company default is
   *         6 hours.
   *
   */
  cancelationWindow?: Maybe<Scalars['Int']['output']>;
  /** Which category should this product be in? */
  category?: Maybe<ProductCategory>;
  coach?: Maybe<MiniCoachType>;
  coachEnableBookingRequest?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   *
   *         What is the base credit price of this product?
   *
   */
  creditPrice?: Maybe<Scalars['Float']['output']>;
  /**
   *
   *         How many credits does the customer get when they _buy_ this product?
   *
   */
  creditValue?: Maybe<Scalars['Float']['output']>;
  /** You can use markdown for basic styling */
  description?: Maybe<Scalars['String']['output']>;
  detailGroups?: Maybe<Array<Maybe<ProductDetailGroupType>>>;
  detailLines?: Maybe<Array<Maybe<ProductDetailLineType>>>;
  /** How long is this meeting type (in minutes)? */
  duration?: Maybe<Scalars['Int']['output']>;
  /** Enable booking request feature on this product ? */
  enableBookingRequest: Scalars['Boolean']['output'];
  /** Does this product allow guests? */
  enableGuests?: Maybe<Scalars['Boolean']['output']>;
  /** Does this product allow an RFQ? */
  enableRfq: Scalars['Boolean']['output'];
  endorsements?: Maybe<Array<Maybe<ProductEndorsementType>>>;
  faqAnswers?: Maybe<Array<Maybe<FaqAnswerType>>>;
  id: Scalars['ID']['output'];
  /**
   *
   *             In addition to the rounds of review about, does the reviewer provide a final
   *             check?
   *
   */
  includesFinalCheck: Scalars['Boolean']['output'];
  /** Can this product only be booked once per coach? */
  introProduct: Scalars['Boolean']['output'];
  /**
   *
   *         What is the maximum number of people required for this to be successful?
   *         If left blank, it is assumed there is no maximum.
   *
   */
  maxParticipants?: Maybe<Scalars['Int']['output']>;
  metaImageUrl?: Maybe<Scalars['String']['output']>;
  msrp?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  numRatings: Scalars['Int']['output'];
  /** How many rounds of review does this include? */
  numRounds?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceRange?: Maybe<ProductPriceRangeType>;
  /**
   *
   *         This product might be displayed on multiple sites. This causes its
   *         profile to be SEO indexed with the same content in multiple places.
   *         To prevent cross-site canibalization we choose a primary site and set
   *         it to the canonical location.
   *
   */
  primarySite: ProductPrimarySite;
  /** A short description. Less than 160 characters. */
  shortDescription?: Maybe<Scalars['String']['output']>;
  slogan?: Maybe<Scalars['String']['output']>;
  /** Leave blank to have the system generate one for you */
  slug: Scalars['String']['output'];
  specialty?: Maybe<SpecialtyType>;
  specialtyId?: Maybe<Scalars['ID']['output']>;
  /**
   *
   *         What is the maximum number of business days it'll take for us to
   *         finish this review?
   *
   */
  turnaroundTime?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userCoachProducts?: Maybe<Array<Maybe<UserCoachProductType>>>;
};

/** An enumeration. */
export enum ProgramComponentFrequencyType {
  EveryOtherWeek = 'EveryOtherWeek',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}

export type ProgramComponentInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  coachGuidance?: InputMaybe<Scalars['String']['input']>;
  coaches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  forAllParticipants?: InputMaybe<Scalars['Boolean']['input']>;
  formFields?: InputMaybe<Array<InputMaybe<FormFieldInput>>>;
  frequency?: InputMaybe<ProgramComponentFrequencyType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isCurateCoaches?: InputMaybe<Scalars['Boolean']['input']>;
  isReleaseDependentComponents?: InputMaybe<Scalars['Boolean']['input']>;
  isSendMeetingUpdateEmail?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByExec?: InputMaybe<Scalars['Boolean']['input']>;
  isSetupComplete?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  participantGuidance?: InputMaybe<Scalars['String']['input']>;
  participantPrelaunchVisibility?: InputMaybe<Scalars['Boolean']['input']>;
  participants?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  pendingParticipantEmails?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  prerequisiteComponentId?: InputMaybe<Scalars['ID']['input']>;
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requiredCompleteSurvey?: InputMaybe<Scalars['Boolean']['input']>;
  requiredSendDueEmail?: InputMaybe<Scalars['Boolean']['input']>;
  requiredSendLaunchEmail?: InputMaybe<Scalars['Boolean']['input']>;
  requiredSendPastDueEmail?: InputMaybe<Scalars['Boolean']['input']>;
  schedulerByProgramLeads?: InputMaybe<Scalars['Boolean']['input']>;
  schedulerUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  selectedScenarioIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sessionNum?: InputMaybe<Scalars['Int']['input']>;
  specialty?: InputMaybe<Scalars['ID']['input']>;
  targetDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  targetEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  targetStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<ComponentTypeEnum>;
  visibleUpcomingQuestions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgramComponentResponseType = {
  __typename?: 'ProgramComponentResponseType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  formFieldAnswers: Array<FormFieldAnswerAnalyticsType>;
  id: Scalars['ID']['output'];
  programComponent: ProgramComponentType;
  programSurvey?: Maybe<ProgramSurveyType>;
  roleplayScenarios?: Maybe<Array<Maybe<ProgramRoleplayScenarioType>>>;
  survey?: Maybe<ProgramSurveyType>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<MiniUserType>;
};

export type ProgramComponentType = {
  __typename?: 'ProgramComponentType';
  body?: Maybe<Scalars['String']['output']>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  coachGuidance?: Maybe<Scalars['String']['output']>;
  coaches?: Maybe<Array<Maybe<MiniCoachType>>>;
  completedCount?: Maybe<Scalars['Int']['output']>;
  dependentComponents?: Maybe<Array<Maybe<ProgramComponentType>>>;
  forAllParticipants: Scalars['Boolean']['output'];
  frequency?: Maybe<ProgramComponentFrequencyType>;
  id: Scalars['ID']['output'];
  /** Express if components have a specific curate of coach */
  isCurateCoaches: Scalars['Boolean']['output'];
  isGroupMeetingSetupDone?: Maybe<Scalars['Boolean']['output']>;
  isParticipantRoleplay?: Maybe<Scalars['Boolean']['output']>;
  isServicedByExec: Scalars['Boolean']['output'];
  isSetupComplete: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  participantGuidance?: Maybe<Scalars['String']['output']>;
  participantPrelaunchVisibility?: Maybe<Scalars['Boolean']['output']>;
  participantSubmitAt?: Maybe<Scalars['DateTime']['output']>;
  participants?: Maybe<Array<Maybe<ProgramUserType>>>;
  pendingParticipantEmails?: Maybe<Array<Scalars['String']['output']>>;
  prerequisiteComponent?: Maybe<ProgramComponentType>;
  products?: Maybe<Array<Maybe<ProductType>>>;
  program?: Maybe<ProgramMiniType>;
  programId?: Maybe<Scalars['ID']['output']>;
  requiredCompleteSurvey: Scalars['Boolean']['output'];
  requiredSendDueEmail: Scalars['Boolean']['output'];
  requiredSendLaunchEmail: Scalars['Boolean']['output'];
  requiredSendPastDueEmail: Scalars['Boolean']['output'];
  roleplayScenarios?: Maybe<Array<Maybe<ProgramRoleplayScenarioType>>>;
  roleplayScenariosRank?: Maybe<Array<Maybe<ProgramRoleplayScenarioRankType>>>;
  roleplaySessions?: Maybe<Array<Maybe<ProgramRoleplaySessionType>>>;
  schedulerByProgramLeads: Scalars['Boolean']['output'];
  schedulerUsers?: Maybe<Array<Maybe<ProgramUserType>>>;
  sessionNum: Scalars['Int']['output'];
  specialty?: Maybe<SpecialtyType>;
  startedCount?: Maybe<Scalars['Int']['output']>;
  survey?: Maybe<ProgramSurveyType>;
  targetDueDate?: Maybe<Scalars['DateTime']['output']>;
  targetEndDate?: Maybe<Scalars['DateTime']['output']>;
  targetStartDate?: Maybe<Scalars['DateTime']['output']>;
  totalParticipants?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<ComponentTypeEnum>;
  /** Action item visible to participants before launch */
  visibleUpcomingQuestions: Scalars['Boolean']['output'];
};

export type ProgramComponentsQueryResult = {
  __typename?: 'ProgramComponentsQueryResult';
  leadsComponents?: Maybe<Array<Maybe<ProgramComponentType>>>;
  participantsComponents?: Maybe<Array<Maybe<ProgramComponentType>>>;
};

export type ProgramCostType = {
  __typename?: 'ProgramCostType';
  maxCredits?: Maybe<Scalars['Float']['output']>;
  minCredits?: Maybe<Scalars['Float']['output']>;
};

export type ProgramMiniType = {
  __typename?: 'ProgramMiniType';
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  closedBy?: Maybe<MiniUserType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isProgramLead?: Maybe<Scalars['Boolean']['output']>;
  isProgramParticipant?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  participantCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ProgramStatus>;
  targetEndDate?: Maybe<Scalars['DateTime']['output']>;
  targetLaunchDate?: Maybe<Scalars['DateTime']['output']>;
  totalParticipantOngoingSurveys?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProgramQueryResult = {
  __typename?: 'ProgramQueryResult';
  data?: Maybe<Array<Maybe<ProgramMiniType>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProgramRoleplayScenarioRankType = {
  __typename?: 'ProgramRoleplayScenarioRankType';
  rank?: Maybe<RoleplayRank>;
  scenarioId?: Maybe<Scalars['ID']['output']>;
};

export type ProgramRoleplayScenarioType = {
  __typename?: 'ProgramRoleplayScenarioType';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Vapi ID of the assistant associated with the scenario */
  assistantId?: Maybe<Scalars['String']['output']>;
  bestQuizzes?: Maybe<Array<Maybe<FormFieldType>>>;
  /** Background context for the scenario */
  context?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  difficulty: ScenarioDifficulty;
  evaluationCriteria: Array<EvaluationCriterionType>;
  formFieldAnswers: Array<FormFieldAnswerAnalyticsType>;
  formFields: Array<FormFieldAnalyticsType>;
  id: Scalars['ID']['output'];
  introductionAudio?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** Objective of the scenario */
  objective?: Maybe<Scalars['String']['output']>;
  /** Order in which this criterion should be displayed */
  order: Scalars['Int']['output'];
  owner?: Maybe<UserType>;
  /** Name of the persona in the scenario */
  personaName?: Maybe<Scalars['String']['output']>;
  /** Opening line for the persona in the scenario */
  personaOpeningLine?: Maybe<Scalars['String']['output']>;
  personaPhoto?: Maybe<Scalars['String']['output']>;
  personaPhotoUrl?: Maybe<Scalars['String']['output']>;
  /** Prompt for the persona in the scenario */
  personaPrompt?: Maybe<Scalars['String']['output']>;
  /** Personality characteristics of the persona in the scenario */
  personalityCharacteristics?: Maybe<Scalars['String']['output']>;
  personalityTraits: Array<ScenarioPersonalityTraitType>;
  /** Selected scenarios for roleplay */
  programComponents: Array<ProgramComponentType>;
  rank?: Maybe<RoleplayRank>;
  roleplay?: Maybe<RoleplayType>;
  roleplaySessions?: Maybe<Array<Maybe<ProgramRoleplaySessionType>>>;
  roleplays: Array<RoleplayType>;
  scenarioIntakes: Array<ScenarioIntakeType>;
  /** Short description of the scenario */
  shortDesc?: Maybe<Scalars['String']['output']>;
  /** Leave blank to have the system generate one for you */
  slug: Scalars['String']['output'];
  totalParticipants?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userscenarionoteSet: Array<UserScenarioNoteType>;
  vapiAssistantSyncStatus: ScenarioVapiAssistantSyncStatus;
  visibilityScope: ScenarioVisibilityScope;
  visibleCriteriaBanner: Scalars['Boolean']['output'];
  /** Voice ID for the persona in the scenario */
  voiceId?: Maybe<Scalars['String']['output']>;
  /** Voice provider for the persona in the scenario */
  voiceProvider?: Maybe<Scalars['String']['output']>;
  workspace?: Maybe<PublicWorkspaceGraphqlType>;
};

/** DjangoObjectType to access the RoleplaySession model. */
export type ProgramRoleplaySessionType = {
  __typename?: 'ProgramRoleplaySessionType';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  callId?: Maybe<Scalars['String']['output']>;
  constructiveFeedback?: Maybe<Scalars['String']['output']>;
  correctAnswerCount?: Maybe<Scalars['Int']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  endedReason?: Maybe<Scalars['String']['output']>;
  evaluationCriteria: Array<RoleplaySessionCriterionType>;
  file: Scalars['String']['output'];
  formFieldAnswers: Array<FormFieldAnswerAnalyticsType>;
  formFieldCount?: Maybe<Scalars['Int']['output']>;
  formFields?: Maybe<Array<Maybe<FormFieldType>>>;
  id: Scalars['ID']['output'];
  isValidAttempt: Scalars['Boolean']['output'];
  isValidAttemptExplanation?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Scalars['JSONString']['output']>;
  outcomeFeedback?: Maybe<Scalars['String']['output']>;
  positiveFeedback?: Maybe<Scalars['String']['output']>;
  processingStatus?: Maybe<RoleplaySessionProcessingStatus>;
  programComponents: Array<ProgramComponentType>;
  rank?: Maybe<RoleplaySessionRank>;
  recordingUrl?: Maybe<Scalars['String']['output']>;
  roleplay?: Maybe<ProgramRoleplayType>;
  scenario: ScenarioType;
  scenarioId?: Maybe<Scalars['ID']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  sessionComments: Array<RoleplaySessionCommentType>;
  sessionCriterionFeedbackExamples: Array<EvaluationCriterionFeedbackExampleType>;
  sharedMembers: Array<TaggableWorkspaceMemberType>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  stereoRecordingUrl?: Maybe<Scalars['String']['output']>;
  transcript?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<MiniUserType>;
  userId?: Maybe<Scalars['ID']['output']>;
  uuid: Scalars['String']['output'];
  workspace?: Maybe<PublicWorkspaceGraphqlType>;
};

/** DjangoObjectType to access the Roleplay model. */
export type ProgramRoleplayType = {
  __typename?: 'ProgramRoleplayType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  embedding?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Unicode emoji to display next to the roleplay name (https://apps.timwhitlock.info/emoji/tables/unicode) */
  emojiUnicode: Scalars['String']['output'];
  evaluationCriteria: Array<EvaluationCriterionType>;
  id: Scalars['ID']['output'];
  introduction?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  oldScenarios: Array<ScenarioType>;
  owner?: Maybe<UserType>;
  /** Higher priority will make the roleplay show up first for customers */
  priority: Scalars['Int']['output'];
  roleplaySessions: Array<RoleplaySessionType>;
  scenarioIntakes: Array<ScenarioIntakeType>;
  scenarios: Array<ScenarioType>;
  /** Leave blank to have the system generate one for you */
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  visibilityScope: RoleplayVisibilityScope;
  workspace?: Maybe<PublicWorkspaceGraphqlType>;
};

/** An enumeration. */
export enum ProgramStatus {
  Archived = 'Archived',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  InDesign = 'InDesign',
  LaunchReady = 'LaunchReady',
  Live = 'Live',
  Pause = 'Pause',
}

export type ProgramSurveyType = {
  __typename?: 'ProgramSurveyType';
  completedCount?: Maybe<Scalars['Int']['output']>;
  formFieldAnswers?: Maybe<Array<Maybe<AnswerType>>>;
  formFields?: Maybe<Array<Maybe<FormFieldType>>>;
  id: Scalars['ID']['output'];
  isParticipantSurvey?: Maybe<Scalars['Boolean']['output']>;
  participantSubmitAt?: Maybe<Scalars['DateTime']['output']>;
  programComponent?: Maybe<ProgramComponentType>;
  startedCount?: Maybe<Scalars['Int']['output']>;
  totalParticipants?: Maybe<Scalars['Int']['output']>;
};

export type ProgramType = {
  __typename?: 'ProgramType';
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  closedBy?: Maybe<MiniUserType>;
  createdAt: Scalars['DateTime']['output'];
  currentProgramUser?: Maybe<ProgramUserType>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  hasCompletedSetupComponents?: Maybe<Scalars['Boolean']['output']>;
  hasComponents?: Maybe<Scalars['Boolean']['output']>;
  hasParticipants?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  ledgerAccounts?: Maybe<Array<Maybe<LedgerAccountType>>>;
  name: Scalars['String']['output'];
  participantNumber?: Maybe<Scalars['Int']['output']>;
  participantsSyncedOnLaunchAt?: Maybe<Scalars['DateTime']['output']>;
  programComponents: Array<ProgramComponentType>;
  programCost?: Maybe<ProgramCostType>;
  programOwners?: Maybe<Array<Maybe<ProgramUserType>>>;
  programUsers: Array<ComponentParticipantType>;
  resourcePermissions: Array<ResourcePermissionType>;
  scheduleLaunchEmail: Scalars['Boolean']['output'];
  sendEnrollmentEmailAt?: Maybe<Scalars['DateTime']['output']>;
  sentClosedEmailAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<ProgramStatus>;
  targetEndDate?: Maybe<Scalars['DateTime']['output']>;
  targetLaunchDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  workspace: PublicWorkspaceGraphqlType;
  workspaceInvitationBatches: Array<WorkspaceInvitationBatchType>;
};

export type ProgramUserQueryResult = {
  __typename?: 'ProgramUserQueryResult';
  data?: Maybe<Array<Maybe<ProgramUserType>>>;
  page?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum ProgramUserRole {
  Lead = 'Lead',
  Participant = 'Participant',
}

export type ProgramUserType = {
  __typename?: 'ProgramUserType';
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  enrollEmailSentAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isWorkspaceMemberActive?: Maybe<Scalars['Boolean']['output']>;
  program?: Maybe<ProgramMiniType>;
  programComponents: Array<ProgramComponentType>;
  roles?: Maybe<Array<Maybe<ProgramUserRole>>>;
  schedulerUsersProgramComponents: Array<ProgramComponentType>;
  summary?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<MiniUserType>;
};

/** DjangoObjectType to access the PromoCode model. */
export type PromoCodeGraphqlType = {
  __typename?: 'PromoCodeGraphqlType';
  /**
   *
   *             The fixed amount that will be removed from the order total.
   *             CANNOT be used in conjunction with "Percent Off".
   *
   */
  amountOff?: Maybe<Scalars['Float']['output']>;
  /**
   *
   *         A short code that the user will use to access the deal.
   *
   */
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /**
   *
   *         If this Promo Code should only be used a limited number of times, input
   *         the number of times it can be used here. This applies across ALL users.
   *
   */
  maxUses?: Maybe<Scalars['Int']['output']>;
  /**
   *
   *         If this Promo Code should only be used a limited number of times per user, input
   *         the number of times it can be used here. This is PER user.
   *
   */
  maxUsesPerUser?: Maybe<Scalars['Int']['output']>;
  /**
   *
   *             Set this value if you only orders above a certain price threshold qualify
   *             for the promo code.
   *
   */
  minOrderAmount?: Maybe<Scalars['Float']['output']>;
  /**
   *
   *         The number of times this promo code has been used. This is managed
   *         automatically by the system.
   *
   */
  numUses?: Maybe<Scalars['Int']['output']>;
  orders: Array<OrderType>;
  /**
   *
   *         Optionally, include a logo to be displayed on the homepage above the hero text.
   *         This logo will be display at 200px wide so please upload a reasonable sized
   *         file.
   *
   */
  partnerLogo?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         Optionally, include a message to be displayed on the homepage above the hero
   *         text. If a logo is provided, this will be display below the logo.
   *
   */
  partnerMessage?: Maybe<Scalars['String']['output']>;
  /**
   *
   *             The discount percent that will be applied to all eligible items.
   *             CANNOT be used in conjunction with "Amount Off". Use whole numbers
   *             ie. (For 20% off input 20.0)
   *
   */
  percentOff?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** If a user signed up using a Promo Code, it's displayed here. */
  users: Array<UserType>;
  /** Leave blank to allow all */
  validForPaymentTypes?: Maybe<Array<Scalars['String']['output']>>;
  /**
   *
   *             Which products does this promo code apply to? It won't
   *             apply to orders that don't contain at least one of these
   *             products and won't discount products other than these
   *             products. Leave blank to apply to all.
   *
   */
  validForProducts: Array<ProductType>;
  /**
   *
   *         The date after which this Promo Code will no longer work.
   *
   */
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export type PromoCodeType = {
  __typename?: 'PromoCodeType';
  /**
   *
   *             The fixed amount that will be removed from the order total.
   *             CANNOT be used in conjunction with "Percent Off".
   *
   */
  amountOff?: Maybe<Scalars['Float']['output']>;
  /**
   *
   *         A short code that the user will use to access the deal.
   *
   */
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /**
   *
   *             The discount percent that will be applied to all eligible items.
   *             CANNOT be used in conjunction with "Amount Off". Use whole numbers
   *             ie. (For 20% off input 20.0)
   *
   */
  percentOff?: Maybe<Scalars['Float']['output']>;
};

/** DjangoObjectType to access the PromptAnswer model. */
export type PromptAnswerType = {
  __typename?: 'PromptAnswerType';
  answer: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entryId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
};

export type PublicProgramType = {
  __typename?: 'PublicProgramType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PublicRatingType = {
  __typename?: 'PublicRatingType';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  featured: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  score: Scalars['Int']['output'];
  truncatedName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** DjangoObjectType to access the Workspace model for public data. */
export type PublicWorkspaceGraphqlType = {
  __typename?: 'PublicWorkspaceGraphqlType';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inviteToken?: Maybe<Scalars['String']['output']>;
  isUsingSso?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  numMembers?: Maybe<Scalars['Int']['output']>;
  totalPendingInvitations?: Maybe<Scalars['Int']['output']>;
  /** All lower case short name for the workspace url on exec.com */
  urlSlug: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  activeCoachProducts?: Maybe<Array<Maybe<CoachProductType>>>;
  activeCoachProductsForCustomer?: Maybe<Array<Maybe<CoachProductType>>>;
  agentProfile?: Maybe<AgentProfileType>;
  agentProfiles?: Maybe<Array<Maybe<AgentProfileType>>>;
  allSeries?: Maybe<Array<Maybe<ArticleSeries>>>;
  article?: Maybe<Article>;
  articleReadStatus?: Maybe<ArticleReadStatus>;
  articleSeries?: Maybe<ArticleSeries>;
  articleSeriesByArticle?: Maybe<Array<Maybe<ArticleSeries>>>;
  articleSeriesReadStatus?: Maybe<ArticleSeriesReadStatus>;
  articles?: Maybe<Array<Maybe<Article>>>;
  assignment?: Maybe<AssignmentType>;
  assignments?: Maybe<Array<Maybe<AssignmentType>>>;
  bitPackages?: Maybe<Array<Maybe<ProductType>>>;
  bookingRequestMeetings?: Maybe<Array<Maybe<MeetingType>>>;
  bookingRequestTimeslots?: Maybe<Array<Maybe<TimeslotsType>>>;
  calendarSetting?: Maybe<CalendarSettingGraphqlType>;
  calendars?: Maybe<Array<Maybe<CalendarQueryType>>>;
  chatChannelId?: Maybe<Scalars['String']['output']>;
  chatCustomers?: Maybe<Array<Maybe<ChatCustomerType>>>;
  chatMeetings?: Maybe<Array<Maybe<ChatMeetingType>>>;
  chatReviews?: Maybe<Array<Maybe<ChatReviewType>>>;
  clientNotes?: Maybe<Array<Maybe<WorkspaceNoteType>>>;
  clientStatistics?: Maybe<ClientPaginationType>;
  clientWorkspaces?: Maybe<Array<Maybe<PublicWorkspaceGraphqlType>>>;
  coach?: Maybe<AgentType>;
  coachAvailableDays?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  coachAvailableTimeslotByDay?: Maybe<
    Array<Maybe<Scalars['DateTime']['output']>>
  >;
  coachAvailableTimeslots?: Maybe<CoachAvailableObject>;
  coachCustomers?: Maybe<Array<Maybe<CoachCustomerType>>>;
  coachPayoutConfig?: Maybe<CoachPayoutConfigType>;
  coachPayoutEntries?: Maybe<Array<Maybe<CoachPayoutEntryType>>>;
  coachPayouts?: Maybe<Array<Maybe<CoachPayoutType>>>;
  coachPersonalAvailableTimeslots?: Maybe<CoachAvailableObject>;
  coachProduct?: Maybe<CoachProductType>;
  coachProducts?: Maybe<Array<Maybe<CoachProductType>>>;
  coachRatings?: Maybe<Array<Maybe<PublicRatingType>>>;
  coachWorkspacePrices?: Maybe<Array<Maybe<CustomPriceOptionType>>>;
  coaches?: Maybe<Array<Maybe<MiniCoachType>>>;
  company?: Maybe<CompanyType>;
  creditPackages?: Maybe<Array<Maybe<ProductType>>>;
  currentSubscription?: Maybe<SubscriptionDetailsType>;
  currentUser?: Maybe<UserType>;
  currentUserWorkspaceMember?: Maybe<WorkspaceMemberType>;
  currentWorkspace?: Maybe<WorkspaceGraphqlType>;
  currentWorkspaceBudgets?: Maybe<Scalars['Decimal']['output']>;
  currentWorkspaceCredits?: Maybe<Scalars['Decimal']['output']>;
  currentWorkspaceRole?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<UserType>;
  directMessages?: Maybe<Array<Maybe<ChatDirectMessageType>>>;
  /** Get evaluation criteria by roleplay_slug or scenario_id */
  evaluationCriteria?: Maybe<Array<Maybe<EvaluationCriterionType>>>;
  failedLoginHistory?: Maybe<Array<Maybe<FailedLoginHistoryType>>>;
  faq?: Maybe<FaqType>;
  faqAnswers?: Maybe<Array<Maybe<FaqAnswerType>>>;
  faqs?: Maybe<Array<Maybe<FaqType>>>;
  featureIntroduced?: Maybe<Scalars['Boolean']['output']>;
  followCompanies?: Maybe<FollowCompanyPaginationType>;
  followCompany?: Maybe<FollowCompanyType>;
  googlePlaceAutocomplete?: Maybe<GooglePlaceAutocomplete>;
  googlePlaceDetail?: Maybe<GooglePlaceDetail>;
  gptResponse?: Maybe<GptResponseType>;
  gptResponses?: Maybe<Array<Maybe<GptResponseType>>>;
  hasChatChannels?: Maybe<Scalars['Boolean']['output']>;
  inactiveProducts?: Maybe<Array<Maybe<ProductType>>>;
  introProduct?: Maybe<ProductType>;
  invoices?: Maybe<Array<Maybe<InvoiceType>>>;
  isLastActiveComponent?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<JobTitleType>;
  keyPlaces?: Maybe<Array<Maybe<PlaceType>>>;
  landingPageVariant?: Maybe<LandingPageVariantGraphqlType>;
  learnBoardArticleSeries?: Maybe<LearnBoardSeries>;
  learnBoardReadStatus?: Maybe<LearnBoardReadStatus>;
  ledgerAccount?: Maybe<LedgerAccountType>;
  ledgerAccounts?: Maybe<Array<Maybe<LedgerAccountType>>>;
  matchingCategories?: Maybe<Array<Maybe<MatchingCategoryType>>>;
  meeting?: Maybe<MeetingType>;
  meetingAttendees?: Maybe<MeetingAttendeesQueryResponse>;
  meetingTypes?: Maybe<Array<Maybe<ProductType>>>;
  meetings?: Maybe<Array<Maybe<MeetingType>>>;
  memberMeetings?: Maybe<MemberMeetingPaginationType>;
  myCollections?: Maybe<Array<Maybe<RoleplayType>>>;
  notificationCount?: Maybe<Scalars['Int']['output']>;
  optionalCurrentUser?: Maybe<UserType>;
  order?: Maybe<OrderType>;
  orderItemMeeting?: Maybe<MeetingType>;
  paginatedRoleplays?: Maybe<RoleplayPageType>;
  participatedWorkspaces?: Maybe<Array<Maybe<PublicWorkspaceGraphqlType>>>;
  paymentMethods?: Maybe<Array<Maybe<StripePaymentMethodType>>>;
  pendingOrder?: Maybe<OrderType>;
  pendingParticipantEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  personalityAttributes?: Maybe<Array<Maybe<PersonalityAttributeType>>>;
  place?: Maybe<PlaceType>;
  popularIndustries?: Maybe<Array<Maybe<IndustryGraphqlType>>>;
  popularJobFunctions?: Maybe<Array<Maybe<JobFunctionGraphqlType>>>;
  popularPlaces?: Maybe<Array<Maybe<PlaceType>>>;
  popularTitles?: Maybe<Array<Maybe<JobTitleType>>>;
  product?: Maybe<ProductType>;
  products?: Maybe<Array<Maybe<ProductType>>>;
  productsForConfig?: Maybe<Array<Maybe<ProductType>>>;
  program?: Maybe<ProgramType>;
  programComponent?: Maybe<ProgramComponentType>;
  programComponentForStatistic?: Maybe<ProgramComponentType>;
  programComponentParticipants?: Maybe<ComponentParticipantPaginationType>;
  programComponentResponse?: Maybe<ProgramComponentResponseType>;
  programComponents?: Maybe<ProgramComponentsQueryResult>;
  programRoleplayScenarioParticipants?: Maybe<
    Array<Maybe<RoleplaySessionParticipantType>>
  >;
  programRoleplayScenariosAnalytics?: Maybe<
    Array<Maybe<ProgramRoleplayScenarioType>>
  >;
  programStatusFilter?: Maybe<Array<Maybe<ProgramStatus>>>;
  programSurvey?: Maybe<ProgramSurveyType>;
  programSurveyQuestionParticipants?: Maybe<
    Array<Maybe<AnswerSurveyParticipantType>>
  >;
  programSurveyQuestionsAnalytics?: Maybe<Array<Maybe<FormFieldAnalyticsType>>>;
  programUsers?: Maybe<ProgramUserQueryResult>;
  programs?: Maybe<ProgramQueryResult>;
  promoCode?: Maybe<PromoCodeGraphqlType>;
  promptAnswer?: Maybe<PromptAnswerType>;
  publicProgram?: Maybe<PublicProgramType>;
  publicRoleplays?: Maybe<Array<Maybe<RoleplayType>>>;
  publicWorkspace?: Maybe<PublicWorkspaceGraphqlType>;
  rating?: Maybe<RatingType>;
  recentArticles?: Maybe<ArticlesPaginationType>;
  recentSearches?: Maybe<Array<Maybe<SearchType>>>;
  relatedTitles?: Maybe<Array<Maybe<JobTitleType>>>;
  resourcePermissions?: Maybe<Array<Maybe<ResourcePermissionType>>>;
  resumes?: Maybe<Array<Maybe<ResumeGraphqlType>>>;
  review?: Maybe<ReviewType>;
  reviewTypes?: Maybe<Array<Maybe<ProductType>>>;
  reviews?: Maybe<Array<Maybe<ReviewType>>>;
  roleplay?: Maybe<RoleplayType>;
  roleplayScenarios?: Maybe<Array<Maybe<ScenarioType>>>;
  roleplaySession?: Maybe<RoleplaySessionType>;
  roleplayVoices?: Maybe<Array<Maybe<RoleplayVoiceType>>>;
  scenario?: Maybe<ScenarioType>;
  scenarioIntake?: Maybe<ScenarioIntakeType>;
  scenarioNotes?: Maybe<UserScenarioNoteType>;
  scenarios?: Maybe<ScenariosResult>;
  scheduleOverrides?: Maybe<Array<Maybe<ScheduleOverrideGraphqlType>>>;
  scheduledJobs?: Maybe<Array<Maybe<ScheduledJobSchemaType>>>;
  selectableProgramComponents?: Maybe<Array<Maybe<ProgramComponentType>>>;
  seoAgentProfiles?: Maybe<Array<Maybe<AgentProfileType>>>;
  seoTopTitles?: Maybe<Array<Maybe<JobTitleType>>>;
  sesameTokenForWorkspace?: Maybe<Scalars['String']['output']>;
  session?: Maybe<SessionType>;
  simpleProgramUsers?: Maybe<Array<Maybe<SimpleProgramUserType>>>;
  sitemapProducts?: Maybe<Array<Maybe<ProductType>>>;
  slideshow?: Maybe<SlideshowType>;
  specialties?: Maybe<Array<Maybe<SpecialtyType>>>;
  specialtiesForConfig?: Maybe<Array<Maybe<SpecialtyType>>>;
  specialty?: Maybe<SpecialtyType>;
  surveyTemplates?: Maybe<Array<Maybe<SurveyTemplateType>>>;
  taggableUsers?: Maybe<Array<Maybe<TaggableUserType>>>;
  todoTemplates?: Maybe<Array<Maybe<TodoTemplateType>>>;
  todos?: Maybe<Array<Maybe<TodoType>>>;
  topArticleSeries?: Maybe<Array<Maybe<ArticleSeries>>>;
  topArticles?: Maybe<Array<Maybe<Article>>>;
  topTitles?: Maybe<Array<Maybe<JobTitleType>>>;
  userCredit?: Maybe<UserCreditType>;
  userFromSessionId?: Maybe<UserType>;
  validSlug?: Maybe<SlugValidationResultType>;
  validateInviteEmails?: Maybe<ValidateInviteEmailResultType>;
  workspaceAgents?: Maybe<Array<Maybe<WorkspaceMemberType>>>;
  workspaceAnalytics?: Maybe<WorkspaceAnalyticsType>;
  workspaceAssistant?: Maybe<Array<Maybe<WorkspaceAssistantAssignmentType>>>;
  workspaceClientStatistics?: Maybe<WorkspaceClientPageType>;
  workspaceClients?: Maybe<Array<Maybe<WorkspaceMemberType>>>;
  workspaceGroup?: Maybe<WorkspaceGroupType>;
  workspaceGroupMembers?: Maybe<Array<Maybe<WorkspaceGroupMemberType>>>;
  workspaceGroups?: Maybe<Array<Maybe<WorkspaceGroupType>>>;
  workspaceInvitationBatch?: Maybe<WorkspaceInvitationBatchType>;
  workspaceInvitationBatches?: Maybe<
    Array<Maybe<WorkspaceInvitationBatchType>>
  >;
  workspaceInvitationBatchesForProgramStatus?: Maybe<WorkspaceInvitationBatchesStatus>;
  workspaceInvitationBatchesStatus?: Maybe<WorkspaceInvitationBatchesStatus>;
  workspaceMember?: Maybe<WorkspaceMemberType>;
  workspaceMembers?: Maybe<Array<Maybe<WorkspaceMemberType>>>;
  workspaces?: Maybe<Array<Maybe<PublicWorkspaceGraphqlType>>>;
};

export type QueryActiveCoachProductsForCustomerArgs = {
  coachId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAgentProfileArgs = {
  coachSlug?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  specialtyId?: InputMaybe<Scalars['ID']['input']>;
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAgentProfilesArgs = {
  includeCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  intakeFormToken?: InputMaybe<Scalars['String']['input']>;
  numCoaches?: InputMaybe<Scalars['Int']['input']>;
  productSlug: Scalars['String']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type QueryArticleArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryArticleReadStatusArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryArticleSeriesArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryArticleSeriesByArticleArgs = {
  articleSlug: Scalars['String']['input'];
};

export type QueryArticleSeriesReadStatusArgs = {
  slug: Scalars['String']['input'];
};

export type QueryArticlesArgs = {
  public?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAssignmentsArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryBookingRequestMeetingsArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  index?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBookingRequestTimeslotsArgs = {
  isConflictCheck?: InputMaybe<Scalars['Boolean']['input']>;
  meetingId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCalendarsArgs = {
  calendarAccountId: Scalars['ID']['input'];
};

export type QueryChatChannelIdArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryChatCustomersArgs = {
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nonWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  workspaces?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryChatMeetingsArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryChatReviewsArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryClientNotesArgs = {
  customerId: Scalars['ID']['input'];
};

export type QueryClientStatisticsArgs = {
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  nonAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  nonWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  workspaces?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryClientWorkspacesArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCoachArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCoachAvailableDaysArgs = {
  coachId: Scalars['ID']['input'];
};

export type QueryCoachAvailableTimeslotByDayArgs = {
  coachId: Scalars['ID']['input'];
  lookupDay: Scalars['Date']['input'];
  productSlug: Scalars['String']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCoachAvailableTimeslotsArgs = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  coachSlug?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  ignoreMeetingId?: InputMaybe<Scalars['ID']['input']>;
  productSlug: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
  usedAllTimeslot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCoachCustomersArgs = {
  excludeWorkspaceCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  includeCustomersOfOtherProducts?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCoachPayoutEntriesArgs = {
  coachPayoutId: Scalars['ID']['input'];
};

export type QueryCoachPersonalAvailableTimeslotsArgs = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
  timeslotDuration: Scalars['Int']['input'];
};

export type QueryCoachProductArgs = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  productSlug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCoachProductsArgs = {
  coachUserId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  specialtyId?: InputMaybe<Scalars['ID']['input']>;
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCoachRatingsArgs = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCoachWorkspacePricesArgs = {
  productId: Scalars['ID']['input'];
};

export type QueryCoachesArgs = {
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  programId?: InputMaybe<Scalars['ID']['input']>;
  specialtyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryCompanyArgs = {
  companyId: Scalars['ID']['input'];
};

export type QueryCreditPackagesArgs = {
  site?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCustomerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDirectMessagesArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEvaluationCriteriaArgs = {
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  roleplaySlug?: InputMaybe<Scalars['String']['input']>;
  scenarioId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryFailedLoginHistoryArgs = {
  userId: Scalars['String']['input'];
};

export type QueryFaqArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFaqAnswersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryFaqsArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFeatureIntroducedArgs = {
  featureType?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFollowCompaniesArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFollowCompanyArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGooglePlaceAutocompleteArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGooglePlaceDetailArgs = {
  placeId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGptResponseArgs = {
  gptResponseUuid: Scalars['String']['input'];
};

export type QueryGptResponsesArgs = {
  entryId: Scalars['String']['input'];
};

export type QueryHasChatChannelsArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryIntroProductArgs = {
  specialtySlug: Scalars['String']['input'];
};

export type QueryIsLastActiveComponentArgs = {
  componentId: Scalars['ID']['input'];
  programId: Scalars['ID']['input'];
};

export type QueryJobTitleArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLandingPageVariantArgs = {
  urlSlug: Scalars['String']['input'];
};

export type QueryLearnBoardArticleSeriesArgs = {
  public?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLedgerAccountArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLedgerAccountsArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryMatchingCategoriesArgs = {
  specialtySlug: Scalars['String']['input'];
};

export type QueryMeetingArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMeetingAttendeesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  meetingId?: InputMaybe<Scalars['ID']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<InvitationStatus>;
  statuses?: InputMaybe<Array<InputMaybe<InvitationStatus>>>;
};

export type QueryMeetingTypesArgs = {
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMeetingsArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMemberMeetingsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  withoutCanceled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOrderArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOrderItemMeetingArgs = {
  orderItemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryPaginatedRoleplaysArgs = {
  currentPage: Scalars['Int']['input'];
  excludedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeOwnerData?: InputMaybe<Scalars['Boolean']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  pageSize: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<VisibilityScope>;
  workspaceOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPaymentMethodsArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryPendingOrderArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  pendingOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPendingParticipantEmailsArgs = {
  programId: Scalars['ID']['input'];
};

export type QueryPlaceArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProductArgs = {
  coachSlugs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProductsArgs = {
  orderId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  slugs?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProductsForConfigArgs = {
  categories?: InputMaybe<Array<InputMaybe<ProductCategories>>>;
  specialtyId: Scalars['ID']['input'];
};

export type QueryProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type QueryProgramComponentArgs = {
  programComponentId: Scalars['ID']['input'];
  programId: Scalars['ID']['input'];
};

export type QueryProgramComponentForStatisticArgs = {
  programComponentId: Scalars['ID']['input'];
};

export type QueryProgramComponentParticipantsArgs = {
  orderField?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  programComponentId: Scalars['ID']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProgramComponentResponseArgs = {
  responseId: Scalars['ID']['input'];
};

export type QueryProgramComponentsArgs = {
  programId: Scalars['ID']['input'];
};

export type QueryProgramRoleplayScenarioParticipantsArgs = {
  programComponentId: Scalars['ID']['input'];
  scenarioId: Scalars['ID']['input'];
};

export type QueryProgramRoleplayScenariosAnalyticsArgs = {
  programComponentId: Scalars['ID']['input'];
};

export type QueryProgramSurveyArgs = {
  programSurveyId: Scalars['ID']['input'];
};

export type QueryProgramSurveyQuestionParticipantsArgs = {
  formFieldId: Scalars['ID']['input'];
  programSurveyId: Scalars['ID']['input'];
};

export type QueryProgramSurveyQuestionsAnalyticsArgs = {
  programSurveyId: Scalars['ID']['input'];
};

export type QueryProgramUsersArgs = {
  orderField?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  programId: Scalars['ID']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<ProgramUserRole>>>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProgramsArgs = {
  orderField?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<ProgramStatus>>>;
};

export type QueryPromoCodeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPromptAnswerArgs = {
  entryId: Scalars['ID']['input'];
};

export type QueryPublicProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type QueryPublicWorkspaceArgs = {
  urlSlug: Scalars['String']['input'];
};

export type QueryRatingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  meetingId?: InputMaybe<Scalars['ID']['input']>;
  reviewId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryRecentArticlesArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  public?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRelatedTitlesArgs = {
  jobTitle: Scalars['String']['input'];
};

export type QueryResourcePermissionsArgs = {
  resourceId: Scalars['ID']['input'];
  resourceType: ResourceType;
};

export type QueryResumesArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryReviewArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryReviewTypesArgs = {
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryReviewsArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryRoleplayArgs = {
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRoleplayScenariosArgs = {
  roleplayId: Scalars['ID']['input'];
  workspaceOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRoleplaySessionArgs = {
  uuid: Scalars['String']['input'];
};

export type QueryScenarioArgs = {
  scenarioSlug: Scalars['String']['input'];
};

export type QueryScenarioIntakeArgs = {
  uuid: Scalars['String']['input'];
};

export type QueryScenarioNotesArgs = {
  scenarioId: Scalars['ID']['input'];
};

export type QueryScenariosArgs = {
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  isAssignee?: InputMaybe<Scalars['Boolean']['input']>;
  isOrphaned?: InputMaybe<Scalars['Boolean']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<QueryScenariosOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<VisibilityScope>;
  status?: InputMaybe<QueryScenariosStatus>;
  workspaceOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryScheduleOverridesArgs = {
  onlyFuture?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryScheduledJobsArgs = {
  jobTypes?: InputMaybe<Array<InputMaybe<JobType>>>;
  meetingId?: InputMaybe<Scalars['Int']['input']>;
  programId?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<JobStatus>>>;
};

export type QuerySelectableProgramComponentsArgs = {
  programId: Scalars['ID']['input'];
};

export type QuerySesameTokenForWorkspaceArgs = {
  sessionId: Scalars['String']['input'];
  workspace: Scalars['String']['input'];
};

export type QuerySessionArgs = {
  sessionId: Scalars['String']['input'];
};

export type QuerySimpleProgramUsersArgs = {
  programId: Scalars['ID']['input'];
  roles?: InputMaybe<Array<InputMaybe<ProgramUserRole>>>;
};

export type QuerySlideshowArgs = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySpecialtiesArgs = {
  coachSlug?: InputMaybe<Scalars['String']['input']>;
  slugs?: InputMaybe<Scalars['String']['input']>;
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySpecialtiesForConfigArgs = {
  categories?: InputMaybe<Array<InputMaybe<ProductCategories>>>;
};

export type QuerySpecialtyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySurveyTemplatesArgs = {
  programId: Scalars['ID']['input'];
};

export type QueryTodoTemplatesArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  tracks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryTodosArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  forDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  includeComplete?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryTopArticleSeriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryTopArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUserFromSessionIdArgs = {
  sessionId: Scalars['String']['input'];
};

export type QueryValidSlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryValidateInviteEmailsArgs = {
  emails: Scalars['String']['input'];
  programId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryWorkspaceClientStatisticsArgs = {
  currentPage: Scalars['Int']['input'];
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageSize: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWorkspaceGroupArgs = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryWorkspaceGroupMembersArgs = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryWorkspaceGroupsArgs = {
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWorkspaceInvitationBatchArgs = {
  workspaceInvitationBatchId: Scalars['ID']['input'];
};

export type QueryWorkspaceInvitationBatchesArgs = {
  programId: Scalars['ID']['input'];
};

export type QueryWorkspaceInvitationBatchesForProgramStatusArgs = {
  programId: Scalars['ID']['input'];
};

export type QueryWorkspaceMemberArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
};

/** An enumeration. */
export enum QueryScenariosOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
}

/** An enumeration. */
export enum QueryScenariosStatus {
  All = 'ALL',
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
}

/** Quick create scenario intake from text */
export type QuickCreateScenarioIntake = {
  __typename?: 'QuickCreateScenarioIntake';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  scenarioIntake?: Maybe<ScenarioIntakeType>;
};

export type RateGptResponse = {
  __typename?: 'RateGPTResponse';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RatingType = {
  __typename?: 'RatingType';
  coach?: Maybe<AgentType>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<AgentType>;
  featured: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  meeting?: Maybe<MeetingType>;
  privateComment?: Maybe<Scalars['String']['output']>;
  review?: Maybe<ReviewType>;
  score: Scalars['Int']['output'];
  updateAllowed?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ReInviteFailedMeetingAttendees = {
  __typename?: 'ReInviteFailedMeetingAttendees';
  meetingAttendees?: Maybe<Array<Maybe<MeetingAttendeeType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Attempts to redeem a gift code */
export type RedeemGiftCode = {
  __typename?: 'RedeemGiftCode';
  creditsRedeemed?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<OrderType>;
};

export type RedeemPromoCode = {
  __typename?: 'RedeemPromoCode';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<OrderType>;
};

/** DjangoObjectType to access the Referral model. */
export type ReferralType = {
  __typename?: 'ReferralType';
  acquisitionBonusEligibleAt?: Maybe<Scalars['DateTime']['output']>;
  acquisitionBonusPaidAmount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  payedOut: Scalars['Boolean']['output'];
  referralBonusPaid: Scalars['Boolean']['output'];
  referralType?: Maybe<Scalars['String']['output']>;
  referredBy: UserType;
  updatedAt: Scalars['DateTime']['output'];
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** Removes all evaluation criteria from a target */
export type RemoveAllEvaluationCriteriaFromTarget = {
  __typename?: 'RemoveAllEvaluationCriteriaFromTarget';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveAssignment = {
  __typename?: 'RemoveAssignment';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveAssistant = {
  __typename?: 'RemoveAssistant';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  workspaceAssistantAssignment?: Maybe<WorkspaceAssistantAssignmentType>;
};

export type RemoveCalendarAccount = {
  __typename?: 'RemoveCalendarAccount';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Remove an existing collection */
export type RemoveCollection = {
  __typename?: 'RemoveCollection';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Add Scenario To Collection */
export type RemoveCollectionScenario = {
  __typename?: 'RemoveCollectionScenario';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveMeetingAttendees = {
  __typename?: 'RemoveMeetingAttendees';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemovePaymentMethod = {
  __typename?: 'RemovePaymentMethod';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveProgramComponent = {
  __typename?: 'RemoveProgramComponent';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveProgramLead = {
  __typename?: 'RemoveProgramLead';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveProgramParticipant = {
  __typename?: 'RemoveProgramParticipant';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Reorders evaluation criteria */
export type ReorderEvaluationCriteria = {
  __typename?: 'ReorderEvaluationCriteria';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  evaluationCriteria?: Maybe<Array<Maybe<EvaluationCriterionType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Reorders evaluation criterion items */
export type ReorderEvaluationCriterionItems = {
  __typename?: 'ReorderEvaluationCriterionItems';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  evaluationCriterionItems?: Maybe<Array<Maybe<EvaluationCriterionItemType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ReorderItemInput = {
  id: Scalars['ID']['input'];
  order: Scalars['Int']['input'];
};

export type RescheduleCoachMeeting = {
  __typename?: 'RescheduleCoachMeeting';
  meeting?: Maybe<MeetingType>;
  status?: Maybe<RescheduleStatus>;
};

/** An enumeration. */
export enum RescheduleStatus {
  AttendeesScheduleBlock = 'AttendeesScheduleBlock',
  BalanceNotEnough = 'BalanceNotEnough',
  Error = 'Error',
  MeetingOverlap = 'MeetingOverlap',
  NotCompleted = 'NotCompleted',
  Success = 'Success',
  WorkspaceBalanceNotEnough = 'WorkspaceBalanceNotEnough',
  WrongWorkspace = 'WrongWorkspace',
}

export type ResendEmailConfirmation = {
  __typename?: 'ResendEmailConfirmation';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ResetPasswordConfirm = {
  __typename?: 'ResetPasswordConfirm';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Retrieve a refresh token from uid and user token */
export type ResetPasswordRedirect = {
  __typename?: 'ResetPasswordRedirect';
  refreshToken?: Maybe<Scalars['String']['output']>;
};

/** DjangoObjectType to access the ResourcePermission model. */
export type ResourcePermissionType = {
  __typename?: 'ResourcePermissionType';
  accessScope?: Maybe<AccessScope>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  owner?: Maybe<MiniUserType>;
  permissionLevel?: Maybe<PermissionLevel>;
  program?: Maybe<ProgramType>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  resourceType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<MiniUserType>;
  workspace?: Maybe<WorkspaceType>;
  workspaceGroup?: Maybe<WorkspaceGroupType>;
};

/**
 *
 *     Enum representing resource types that can have permissions assigned.
 *     Values must match the model name (lowercase) in Django content_type table.
 *
 */
export enum ResourceType {
  Scenario = 'SCENARIO',
}

/** DjangoObjectType to access the Resume model. */
export type ResumeGraphqlType = {
  __typename?: 'ResumeGraphqlType';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
};

export type RetryGptResponse = {
  __typename?: 'RetryGPTResponse';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Marks a review revision as sent */
export type ReturnReviewRevision = {
  __typename?: 'ReturnReviewRevision';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  review?: Maybe<ReviewType>;
};

export type ReviewRoundType = {
  __typename?: 'ReviewRoundType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  revisionSentAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReviewType = {
  __typename?: 'ReviewType';
  acceptanceDeadline?: Maybe<Scalars['DateTime']['output']>;
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  canLeaveRating?: Maybe<Scalars['Boolean']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  chatChannelId?: Maybe<Scalars['String']['output']>;
  chatChannelTitle?: Maybe<Scalars['String']['output']>;
  coach?: Maybe<AgentType>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creditPrice?: Maybe<Scalars['Decimal']['output']>;
  customer?: Maybe<UserType>;
  customerId?: Maybe<Scalars['Int']['output']>;
  finalCheckRevisionSentAt?: Maybe<Scalars['DateTime']['output']>;
  formFieldAnswers?: Maybe<Array<Maybe<AnswerType>>>;
  formReceivedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  nextDate?: Maybe<Scalars['String']['output']>;
  numMessages?: Maybe<Scalars['Int']['output']>;
  numRounds: Scalars['Int']['output'];
  numRoundsComplete: Scalars['Int']['output'];
  product?: Maybe<ProductType>;
  rating?: Maybe<RatingType>;
  rounds?: Maybe<Array<Maybe<ReviewRoundType>>>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Revoke = {
  __typename?: 'Revoke';
  revoked: Scalars['Int']['output'];
};

/** Revoke access from a roleplay session */
export type RevokeAccessFromSession = {
  __typename?: 'RevokeAccessFromSession';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Revoke a session */
export type RevokeSession = {
  __typename?: 'RevokeSession';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum RoleplayEvalGrade {
  Bad = 'Bad',
  Good = 'Good',
  Mid = 'Mid',
  NotRelevant = 'NotRelevant',
}

export type RoleplayPageType = {
  __typename?: 'RoleplayPageType';
  currentPage?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<RoleplayType>>>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum RoleplayRank {
  Bronze = 'Bronze',
  Gold = 'Gold',
  Silver = 'Silver',
  Unranked = 'Unranked',
}

/** DjangoObjectType to access the RoleplaySessionComment model. */
export type RoleplaySessionCommentType = {
  __typename?: 'RoleplaySessionCommentType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  roleplaySession?: Maybe<ProgramRoleplaySessionType>;
  text: Scalars['String']['output'];
  transcriptLine?: Maybe<RoleplaySessionTranscriptLineType>;
  updatedAt: Scalars['DateTime']['output'];
  user: Scalars['String']['output'];
};

/** DjangoObjectType to access the RoleplaySessionCriterion model. */
export type RoleplaySessionCriterionType = {
  __typename?: 'RoleplaySessionCriterionType';
  createdAt: Scalars['DateTime']['output'];
  evaluationCriterionItem?: Maybe<EvaluationCriterionItemType>;
  feedback?: Maybe<Scalars['String']['output']>;
  feedbackExamples?: Maybe<
    Array<Maybe<EvaluationCriterionFeedbackExampleType>>
  >;
  grade?: Maybe<RoleplayEvalGrade>;
  id: Scalars['ID']['output'];
  roleplaySession: RoleplaySessionType;
  summary?: Maybe<Scalars['JSONString']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** DjangoObjectType to access the RoleplaySessionCriterion model. */
export type RoleplaySessionCriterionTypeFeedbackExamplesArgs = {
  salience?: InputMaybe<
    Array<InputMaybe<RoleplaySessionEvaluationInstanceSalienceLevel>>
  >;
};

/** An enumeration. */
export enum RoleplaySessionEvaluationInstanceSalienceLevel {
  High = 'High',
  Low = 'Low',
  Mid = 'Mid',
}

export type RoleplaySessionParticipantType = {
  __typename?: 'RoleplaySessionParticipantType';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  programComponentResponseId?: Maybe<Scalars['ID']['output']>;
  roleplaySessions?: Maybe<Array<Maybe<ProgramRoleplaySessionType>>>;
};

/** An enumeration. */
export enum RoleplaySessionProcessingStatus {
  Cleaned = 'Cleaned',
  Cleaning = 'Cleaning',
  Failed = 'Failed',
  NotSynced = 'NotSynced',
  Processed = 'Processed',
  Processing = 'Processing',
  Summarizing = 'Summarizing',
  Synced = 'Synced',
  Syncing = 'Syncing',
}

/** An enumeration. */
export enum RoleplaySessionRank {
  /** Bronze */
  Bronze = 'BRONZE',
  /** Gold */
  Gold = 'GOLD',
  /** Silver */
  Silver = 'SILVER',
  /** Unranked */
  Unranked = 'UNRANKED',
}

export type RoleplaySessionTranscriptLineEvaluatedCriterionItemType = {
  __typename?: 'RoleplaySessionTranscriptLineEvaluatedCriterionItemType';
  feedbacks?: Maybe<
    Array<
      Maybe<RoleplaySessionTranscriptLineEvaluatedCriterionRoleplaySessionType>
    >
  >;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type RoleplaySessionTranscriptLineEvaluatedCriterionRoleplaySessionType =
  {
    __typename?: 'RoleplaySessionTranscriptLineEvaluatedCriterionRoleplaySessionType';
    feedback?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['ID']['output']>;
  };

export type RoleplaySessionTranscriptLineEvaluatedCriterionType = {
  __typename?: 'RoleplaySessionTranscriptLineEvaluatedCriterionType';
  id?: Maybe<Scalars['ID']['output']>;
  items?: Maybe<
    Array<Maybe<RoleplaySessionTranscriptLineEvaluatedCriterionItemType>>
  >;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type RoleplaySessionTranscriptLineType = {
  __typename?: 'RoleplaySessionTranscriptLineType';
  comments?: Maybe<Array<Maybe<RoleplaySessionCommentType>>>;
  duration?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  endTimestamp?: Maybe<Scalars['DateTime']['output']>;
  evaluatedCriterion?: Maybe<
    Array<Maybe<RoleplaySessionTranscriptLineEvaluatedCriterionType>>
  >;
  feedbacks?: Maybe<Array<Maybe<EvaluationCriterionFeedbackExampleType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  secondsFromStart?: Maybe<Scalars['Int']['output']>;
  speaker?: Maybe<MiniUserType>;
  speakerId?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['DateTime']['output']>;
};

export type RoleplaySessionTranscriptLineTypeCommentsArgs = {
  user?: InputMaybe<Scalars['String']['input']>;
};

export type RoleplaySessionTranscriptLineTypeEvaluatedCriterionArgs = {
  salience?: InputMaybe<
    Array<InputMaybe<RoleplaySessionEvaluationInstanceSalienceLevel>>
  >;
};

export type RoleplaySessionTranscriptLineTypeFeedbacksArgs = {
  salience?: InputMaybe<
    Array<InputMaybe<RoleplaySessionEvaluationInstanceSalienceLevel>>
  >;
};

/** DjangoObjectType to access the RoleplaySession model. */
export type RoleplaySessionType = {
  __typename?: 'RoleplaySessionType';
  callId?: Maybe<Scalars['String']['output']>;
  cleanMessages?: Maybe<Array<Maybe<Scalars['JSONString']['output']>>>;
  constructiveFeedback?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  evaluations?: Maybe<Array<Maybe<RoleplaySessionCriterionType>>>;
  file: Scalars['String']['output'];
  formFields?: Maybe<Array<Maybe<FormFieldType>>>;
  id: Scalars['ID']['output'];
  isValidAttempt: Scalars['Boolean']['output'];
  isValidAttemptExplanation?: Maybe<Scalars['String']['output']>;
  outcomeFeedback?: Maybe<Scalars['String']['output']>;
  positiveFeedback?: Maybe<Scalars['String']['output']>;
  processingStatus?: Maybe<RoleplaySessionProcessingStatus>;
  programs?: Maybe<Array<Maybe<ProgramType>>>;
  rank?: Maybe<RoleplayRank>;
  recordingUrl?: Maybe<Scalars['String']['output']>;
  roleplay?: Maybe<RoleplayType>;
  scenario: ScenarioType;
  score?: Maybe<Scalars['Float']['output']>;
  sharedMembers?: Maybe<Array<Maybe<TaggableWorkspaceMemberType>>>;
  transcript?: Maybe<Scalars['String']['output']>;
  transcripts?: Maybe<Array<Maybe<RoleplaySessionTranscriptLineType>>>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  user: UserType;
  uuid: Scalars['String']['output'];
};

/** DjangoObjectType to access the Roleplay model. */
export type RoleplayType = {
  __typename?: 'RoleplayType';
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Unicode emoji to display next to the roleplay name (https://apps.timwhitlock.info/emoji/tables/unicode) */
  emojiUnicode: Scalars['String']['output'];
  evaluationCriteria?: Maybe<Array<Maybe<EvaluationCriterionType>>>;
  id: Scalars['ID']['output'];
  introduction?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  owner?: Maybe<UserType>;
  /** Higher priority will make the roleplay show up first for customers */
  priority: Scalars['Int']['output'];
  scenarios?: Maybe<Array<Maybe<ScenarioType>>>;
  /** Leave blank to have the system generate one for you */
  slug: Scalars['String']['output'];
  visibilityScope?: Maybe<VisibilityScope>;
  workspace?: Maybe<PublicWorkspaceGraphqlType>;
};

/** An enumeration. */
export enum RoleplayVisibilityScope {
  /** Global */
  Global = 'GLOBAL',
  /** User */
  User = 'USER',
  /** Workspace */
  Workspace = 'WORKSPACE',
}

/** An enumeration. */
export enum RoleplayVoiceGender {
  /** Ambiguous */
  Ambiguous = 'AMBIGUOUS',
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
}

export type RoleplayVoiceType = {
  __typename?: 'RoleplayVoiceType';
  createdAt: Scalars['DateTime']['output'];
  gender?: Maybe<RoleplayVoiceGender>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  sampleUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  voiceId: Scalars['String']['output'];
};

export type SsoGenerateAuthUrl = {
  __typename?: 'SSOGenerateAuthURL';
  authUrl?: Maybe<Scalars['String']['output']>;
};

export type SaveArticleReadStatus = {
  __typename?: 'SaveArticleReadStatus';
  articleReadStatus?: Maybe<ArticleReadStatusType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SaveAttendingStatus = {
  __typename?: 'SaveAttendingStatus';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves user's calendar settings */
export type SaveCalendarSettings = {
  __typename?: 'SaveCalendarSettings';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SaveClientNote = {
  __typename?: 'SaveClientNote';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  note?: Maybe<WorkspaceNoteType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Create a new collection */
export type SaveCollection = {
  __typename?: 'SaveCollection';
  collection?: Maybe<RoleplayType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves user's feedback */
export type SaveCustomerFeedback = {
  __typename?: 'SaveCustomerFeedback';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SaveFollowCompany = {
  __typename?: 'SaveFollowCompany';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves a product fulfillment form for current user */
export type SaveFulfillmentForm = {
  __typename?: 'SaveFulfillmentForm';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  fulfillmentForm?: Maybe<FulfillmentFormType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves the intake form for current user */
export type SaveIntakeForm = {
  __typename?: 'SaveIntakeForm';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  orderId?: Maybe<Scalars['ID']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type SaveMeetingAllowGuests = {
  __typename?: 'SaveMeetingAllowGuests';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SaveMeetingGuests = {
  __typename?: 'SaveMeetingGuests';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SaveMeetingLocation = {
  __typename?: 'SaveMeetingLocation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves user's notification preferences */
export type SaveNotificationPreferences = {
  __typename?: 'SaveNotificationPreferences';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SaveProgramComponent = {
  __typename?: 'SaveProgramComponent';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  programComponent?: Maybe<ProgramComponentType>;
};

export type SaveProgramSurveyAnswer = {
  __typename?: 'SaveProgramSurveyAnswer';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves a prompt answer */
export type SavePromptAnswer = {
  __typename?: 'SavePromptAnswer';
  created?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  promptAnswer?: Maybe<PromptAnswerType>;
};

/** Save user's quiz answers */
export type SaveQuizAnswer = {
  __typename?: 'SaveQuizAnswer';
  explanation?: Maybe<Scalars['String']['output']>;
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves a resume for current user */
export type SaveResume = {
  __typename?: 'SaveResume';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** save user's notes about a scenario */
export type SaveScenarioNotes = {
  __typename?: 'SaveScenarioNotes';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves a set of schedule overrides */
export type SaveScheduleOverrides = {
  __typename?: 'SaveScheduleOverrides';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves a search attempt for current user */
export type SaveSearch = {
  __typename?: 'SaveSearch';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves a session */
export type SaveSession = {
  __typename?: 'SaveSession';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Saves a todo */
export type SaveTodo = {
  __typename?: 'SaveTodo';
  created?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  todo?: Maybe<TodoType>;
  todoId?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum ScenarioDifficulty {
  /** Custom */
  Custom = 'CUSTOM',
  /** Easy */
  Easy = 'EASY',
  /** Hard */
  Hard = 'HARD',
  /** Medium */
  Medium = 'MEDIUM',
  /** Very Hard */
  VeryHard = 'VERY_HARD',
}

/** An enumeration. */
export enum ScenarioIntakeProcessingStatus {
  Failed = 'Failed',
  NotSynced = 'NotSynced',
  Processed = 'Processed',
  Processing = 'Processing',
  Synced = 'Synced',
  Syncing = 'Syncing',
}

/** DjangoObjectType to access the ScenarioIntake model. */
export type ScenarioIntakeType = {
  __typename?: 'ScenarioIntakeType';
  callId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  processingStatus?: Maybe<ScenarioIntakeProcessingStatus>;
  roleplay?: Maybe<RoleplayType>;
  scenario?: Maybe<ScenarioType>;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
  uuid: Scalars['String']['output'];
};

export type ScenarioPersonalityTraitType = {
  __typename?: 'ScenarioPersonalityTraitType';
  attribute: PersonalityAttributeType;
  attributeId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  level: PersonalityAttributeLevelType;
  levelId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** DjangoObjectType to access the Scenario model. */
export type ScenarioType = {
  __typename?: 'ScenarioType';
  /** Vapi ID of the assistant associated with the scenario */
  assistantId?: Maybe<Scalars['String']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  collections?: Maybe<Array<Maybe<RoleplayType>>>;
  /** Background context for the scenario */
  context?: Maybe<Scalars['String']['output']>;
  difficulty: ScenarioDifficulty;
  evaluationCriteria?: Maybe<Array<Maybe<EvaluationCriterionType>>>;
  id: Scalars['ID']['output'];
  introductionAudioUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  numberOfSessions?: Maybe<Scalars['Int']['output']>;
  /** Objective of the scenario */
  objective?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<MiniUserType>;
  personaFirstName?: Maybe<Scalars['String']['output']>;
  personaLastName?: Maybe<Scalars['String']['output']>;
  /** Name of the persona in the scenario */
  personaName?: Maybe<Scalars['String']['output']>;
  personaPhotoUrl?: Maybe<Scalars['String']['output']>;
  /** Personality characteristics of the persona in the scenario */
  personalityCharacteristics?: Maybe<Scalars['String']['output']>;
  personalityTraits: Array<ScenarioPersonalityTraitType>;
  rank?: Maybe<RoleplayRank>;
  roleplaySessions?: Maybe<Array<Maybe<RoleplaySessionType>>>;
  /** Short description of the scenario */
  shortDesc?: Maybe<Scalars['String']['output']>;
  /** Leave blank to have the system generate one for you */
  slug: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  visibilityScope?: Maybe<VisibilityScope>;
  visibleCriteriaBanner?: Maybe<Scalars['Boolean']['output']>;
  /** Voice ID for the persona in the scenario */
  voiceId?: Maybe<Scalars['String']['output']>;
  workspace?: Maybe<PublicWorkspaceGraphqlType>;
};

/** An enumeration. */
export enum ScenarioVapiAssistantSyncStatus {
  /** Failed */
  Failed = 'FAILED',
  /** Not Synced */
  NotSynced = 'NOT_SYNCED',
  /** Synced */
  Synced = 'SYNCED',
  /** Syncing */
  Syncing = 'SYNCING',
}

/** An enumeration. */
export enum ScenarioVisibilityScope {
  /** Global */
  Global = 'GLOBAL',
  /** User */
  User = 'USER',
  /** Workspace */
  Workspace = 'WORKSPACE',
}

export type ScenariosResult = {
  __typename?: 'ScenariosResult';
  data?: Maybe<Array<Maybe<ScenarioType>>>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export type ScheduleOverrideGraphqlType = {
  __typename?: 'ScheduleOverrideGraphqlType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  overrideDate: Scalars['Date']['output'];
  timeslots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  totalHours: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
};

/** An enumeration. */
export enum ScheduledJobJobType {
  /** Addattendeestogroupmeeting */
  AddAttendeesToGroupMeeting = 'ADD_ATTENDEES_TO_GROUP_MEETING',
  /** Inviteclientstoworkspace */
  InviteClientsToWorkspace = 'INVITE_CLIENTS_TO_WORKSPACE',
  /** Sample */
  Sample = 'SAMPLE',
}

/** An enumeration. */
export enum ScheduledJobResultStatus {
  /** Failed */
  Failed = 'FAILED',
  /** Success */
  Success = 'SUCCESS',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type ScheduledJobSchemaType = {
  __typename?: 'ScheduledJobSchemaType';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledBy?: Maybe<MiniUserType>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<MiniUserType>;
  eventType?: Maybe<JobType>;
  id: Scalars['ID']['output'];
  jobPayload: Scalars['JSONString']['output'];
  jobType: ScheduledJobJobType;
  meetingAttendees: Array<MeetingAttendeeType>;
  resultStatus: ScheduledJobResultStatus;
  scheduledAt: Scalars['DateTime']['output'];
  status?: Maybe<JobStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']['output']>;
  triggeredBy?: Maybe<MiniUserType>;
  updatedAt: Scalars['DateTime']['output'];
  workspace?: Maybe<WorkspaceType>;
};

/** An enumeration. */
export enum SearchSelectedResultType {
  /** Article */
  Article = 'ARTICLE',
  /** Chat */
  Chat = 'CHAT',
  /** Coach */
  Coach = 'COACH',
  /** Company */
  Company = 'COMPANY',
  /** Customer */
  Customer = 'CUSTOMER',
  /** Interviewquestion */
  InterviewQuestion = 'INTERVIEW_QUESTION',
  /** Interviewquestionpack */
  InterviewQuestionPack = 'INTERVIEW_QUESTION_PACK',
  /** Jobtitle */
  JobTitle = 'JOB_TITLE',
  /** Letter */
  Letter = 'LETTER',
  /** Note */
  Note = 'NOTE',
  /** Program */
  Program = 'PROGRAM',
  /** Roleplay */
  Roleplay = 'ROLEPLAY',
  /** Scenario */
  Scenario = 'SCENARIO',
}

/** DjangoObjectType to access the Search model. */
export type SearchType = {
  __typename?: 'SearchType';
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<UserType>;
  id: Scalars['ID']['output'];
  query: Scalars['String']['output'];
  selectedResult?: Maybe<Scalars['JSONString']['output']>;
  selectedResultType?: Maybe<SearchSelectedResultType>;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
  workspace?: Maybe<PublicWorkspaceGraphqlType>;
};

export type SendGoogleInviteCoachMeeting = {
  __typename?: 'SendGoogleInviteCoachMeeting';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SendNudgeSurvey = {
  __typename?: 'SendNudgeSurvey';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Retrieve refresh_token with sesame authentication */
export type SesameRedirect = {
  __typename?: 'SesameRedirect';
  refreshToken?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum SessionAppType {
  /** Web */
  Web = 'WEB',
}

/** DjangoObjectType to access the Session model. */
export type SessionType = {
  __typename?: 'SessionType';
  algoliaTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  appType: SessionAppType;
  chatToken?: Maybe<Scalars['String']['output']>;
  configuration?: Maybe<ConfigurationType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  impersonator?: Maybe<UserSessionType>;
  isImpersonating?: Maybe<Scalars['Boolean']['output']>;
  sesameQuery?: Maybe<Scalars['String']['output']>;
  sessionId: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserSessionType>;
  userEmail?: Maybe<Scalars['String']['output']>;
  workspace?: Maybe<PublicWorkspaceGraphqlType>;
  workspaceRole?: Maybe<Scalars['String']['output']>;
  workspaces?: Maybe<Array<Maybe<WorkspaceType>>>;
};

/** Updates the product price charged by a coach for a product */
export type SetCoachProductPrice = {
  __typename?: 'SetCoachProductPrice';
  coachProduct?: Maybe<CoachProductType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Sets the price that a customer pays for a product wth a coach */
export type SetCustomPriceOption = {
  __typename?: 'SetCustomPriceOption';
  customPriceOption?: Maybe<CustomPriceOptionType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SetExceptionalRefund = {
  __typename?: 'SetExceptionalRefund';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SetExternalCalendar = {
  __typename?: 'SetExternalCalendar';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SetExtraCredits = {
  __typename?: 'SetExtraCredits';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<OrderType>;
};

export type SetPassword = {
  __typename?: 'SetPassword';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Set credits for workspace group from workspace credits */
export type SetWorkspaceGroupCredits = {
  __typename?: 'SetWorkspaceGroupCredits';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  success?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Set credits for workspace member from workspace credits */
export type SetWorkspaceMemberCredits = {
  __typename?: 'SetWorkspaceMemberCredits';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  success?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SignUp = {
  __typename?: 'SignUp';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type SimpleProgramUserType = {
  __typename?: 'SimpleProgramUserType';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<ProgramUserRole>>>;
};

export type SlideType = {
  __typename?: 'SlideType';
  buttonLink?: Maybe<Scalars['String']['output']>;
  buttonText?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  lengthInSeconds?: Maybe<Scalars['Int']['output']>;
  secondaryButtonLink?: Maybe<Scalars['String']['output']>;
  secondaryButtonText?: Maybe<Scalars['String']['output']>;
  subtext?: Maybe<Scalars['String']['output']>;
};

export type SlideshowType = {
  __typename?: 'SlideshowType';
  slides?: Maybe<Array<Maybe<SlideType>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SlugValidationResultType = {
  __typename?: 'SlugValidationResultType';
  errorMessage?: Maybe<Scalars['String']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type SpecialtyType = {
  __typename?: 'SpecialtyType';
  agentSpecialtyProfiles: Array<AgentSpecialtyProfileType>;
  createdAt: Scalars['DateTime']['output'];
  /** You can use markdown for basic styling */
  description?: Maybe<Scalars['String']['output']>;
  faqQuestions: Array<FaqQuestionType>;
  /** Is this specialty hidden in the match flow? */
  hiddenInMatchFlow: Scalars['Boolean']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intakeForms: Array<IntakeFormType>;
  introProduct?: Maybe<ProductType>;
  matchingCategories: Array<MatchingCategoryType>;
  matchingQuestions?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  products: Array<ProductType>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** Leave blank to have the system generate one for you */
  slug: Scalars['String']['output'];
  testimonials: Array<TestimonialType>;
  updatedAt: Scalars['DateTime']['output'];
  /** Is this specialty visible to end-users on Placement? */
  visible: Scalars['Boolean']['output'];
};

export type StandardCredentialType = {
  __typename?: 'StandardCredentialType';
  badgeUrl?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  issuer: Scalars['String']['output'];
  name: Scalars['String']['output'];
  requirements?: Maybe<Scalars['String']['output']>;
};

export type StripePaymentMethodType = {
  __typename?: 'StripePaymentMethodType';
  brand?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
};

export type StructuredFormattingType = {
  __typename?: 'StructuredFormattingType';
  main_text?: Maybe<Scalars['String']['output']>;
  main_text_matched_substrings?: Maybe<Array<Maybe<MatchedItemType>>>;
  secondary_text?: Maybe<Scalars['String']['output']>;
};

/** Submits an RFQ */
export type SubmitRfq = {
  __typename?: 'SubmitRFQ';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SubscriptionDetailsType = {
  __typename?: 'SubscriptionDetailsType';
  billingCycleEnd?: Maybe<Scalars['DateTime']['output']>;
  billingCycleStart?: Maybe<Scalars['DateTime']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  entitlements?: Maybe<Array<Maybe<CachedEntitlementType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  planId?: Maybe<Scalars['ID']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
  workspaceId?: Maybe<Scalars['ID']['output']>;
};

export type SubscriptionType = {
  __typename?: 'SubscriptionType';
  billingCycleEnd?: Maybe<Scalars['DateTime']['output']>;
  billingCycleStart?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  planDescription?: Maybe<Scalars['String']['output']>;
  planEntitlements?: Maybe<Array<Maybe<PlanEntitlementType>>>;
  planName?: Maybe<Scalars['String']['output']>;
  /** Number of seats (quantity) for this subscription. */
  quantity: Scalars['Int']['output'];
  status?: Maybe<Scalars['String']['output']>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export type SurveyFormFieldAnswersInput = {
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyTemplateType = {
  __typename?: 'SurveyTemplateType';
  description?: Maybe<Scalars['String']['output']>;
  formFields?: Maybe<Array<Maybe<FormFieldType>>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  participantGuidance?: Maybe<Scalars['String']['output']>;
};

/** Switch the personality mode of a scenario */
export type SwitchPersonalityMode = {
  __typename?: 'SwitchPersonalityMode';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** DjangoObjectType to access the User model as a taggable user. */
export type TaggableUserType = {
  __typename?: 'TaggableUserType';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
};

export type TaggableWorkspaceMemberType = {
  __typename?: 'TaggableWorkspaceMemberType';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAgent?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  user?: Maybe<TaggableUserType>;
  userId?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum TargetType {
  Collection = 'Collection',
  Scenario = 'Scenario',
}

export type TestimonialType = {
  __typename?: 'TestimonialType';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TimeslotsType = {
  __typename?: 'TimeslotsType';
  isConflict?: Maybe<Scalars['Boolean']['output']>;
  preferred?: Maybe<Scalars['Boolean']['output']>;
  timeslot?: Maybe<Scalars['DateTime']['output']>;
};

export type TodoInput = {
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  hideFromDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  todoTemplateId?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** DjangoObjectType to access the Todo model. */
export type TodoTemplateType = {
  __typename?: 'TodoTemplateType';
  createdAt: Scalars['DateTime']['output'];
  hideFromDashboard: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<LinkType>;
  order: Scalars['Int']['output'];
  /** Leave blank to have the system generate one for you */
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  todos: Array<TodoType>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  workspace?: Maybe<PublicWorkspaceGraphqlType>;
};

/** DjangoObjectType to access the Todo model. */
export type TodoType = {
  __typename?: 'TodoType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  hideFromDashboard: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<LinkType>;
  title: Scalars['String']['output'];
  todoTemplate?: Maybe<TodoTemplateType>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  user: UserType;
};

export type ToggleAcceptingNewClientsForCoachProduct = {
  __typename?: 'ToggleAcceptingNewClientsForCoachProduct';
  coachProduct?: Maybe<CoachProductType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ToggleCoachProduct = {
  __typename?: 'ToggleCoachProduct';
  coachProduct?: Maybe<CoachProductType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ToggleRequireBookingRequestForCoachProduct = {
  __typename?: 'ToggleRequireBookingRequestForCoachProduct';
  coachProduct?: Maybe<CoachProductType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Toggles a todo */
export type ToggleTodo = {
  __typename?: 'ToggleTodo';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  todo?: Maybe<TodoType>;
};

export type TrackCustomerEvent = {
  __typename?: 'TrackCustomerEvent';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type TrackCustomerEventInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  event: Scalars['String']['input'];
};

/** Transfers credits from the current user's receivable account to their available account */
export type TransferCredits = {
  __typename?: 'TransferCredits';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UnassignWorkspaceGroup = {
  __typename?: 'UnassignWorkspaceGroup';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UnfollowCompany = {
  __typename?: 'UnfollowCompany';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateAttendeeSchedule = {
  __typename?: 'UpdateAttendeeSchedule';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateCoachBufferSettings = {
  __typename?: 'UpdateCoachBufferSettings';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateCoachInfo = {
  __typename?: 'UpdateCoachInfo';
  agentProfile?: Maybe<AgentProfileType>;
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateCoachProfile = {
  __typename?: 'UpdateCoachProfile';
  agentProfile?: Maybe<AgentProfileType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Allow workspace admin to update collection visibility scope */
export type UpdateCollectionVisibilityScope = {
  __typename?: 'UpdateCollectionVisibilityScope';
  collection?: Maybe<RoleplayType>;
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Updates an evaluation criterion */
export type UpdateEvaluationCriterion = {
  __typename?: 'UpdateEvaluationCriterion';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  evaluationCriterion?: Maybe<EvaluationCriterionType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateGroupRole = {
  __typename?: 'UpdateGroupRole';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Update a member's role */
export type UpdateMemberRole = {
  __typename?: 'UpdateMemberRole';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  updatedMember?: Maybe<WorkspaceMemberType>;
};

/** Updates a pending order */
export type UpdateOrder = {
  __typename?: 'UpdateOrder';
  order?: Maybe<OrderType>;
};

export type UpdateProgram = {
  __typename?: 'UpdateProgram';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  program?: Maybe<ProgramMiniType>;
};

/** Updates a rating */
export type UpdateRating = {
  __typename?: 'UpdateRating';
  rating?: Maybe<RatingType>;
};

/** Updates a rating featured status */
export type UpdateRatingFeatured = {
  __typename?: 'UpdateRatingFeatured';
  ok?: Maybe<Scalars['Boolean']['output']>;
  rating?: Maybe<PublicRatingType>;
};

/** Update or remove existing resource permissions for users and groups, or change access scope */
export type UpdateResourcePermissions = {
  __typename?: 'UpdateResourcePermissions';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  invalidGroupIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  invalidUserIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  notFoundCount?: Maybe<Scalars['Int']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  removedCount?: Maybe<Scalars['Int']['output']>;
  updatedCount?: Maybe<Scalars['Int']['output']>;
};

export type UpdateResourcePermissionsInput = {
  accessScope?: InputMaybe<AccessScopeEnum>;
  groupPermissions?: InputMaybe<Array<InputMaybe<PermissionUpdateInput>>>;
  resourceId: Scalars['ID']['input'];
  resourceType: ResourceType;
  userPermissions?: InputMaybe<Array<InputMaybe<PermissionUpdateInput>>>;
};

/** Edit the character of a scenario */
export type UpdateScenarioCharacter = {
  __typename?: 'UpdateScenarioCharacter';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Allow workspace admin to update scenario visibility scope */
export type UpdateScenarioVisibilityScope = {
  __typename?: 'UpdateScenarioVisibilityScope';
  msg?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  scenario?: Maybe<ScenarioType>;
};

export type UpdateStripeAccount = {
  __typename?: 'UpdateStripeAccount';
  ready?: Maybe<Scalars['Boolean']['output']>;
  submitted?: Maybe<Scalars['Boolean']['output']>;
};

/** Updates the current user */
export type UpdateUser = {
  __typename?: 'UpdateUser';
  /** List of errors that occurred executing the mutation. */
  errors?: Maybe<Array<Maybe<Error>>>;
  user?: Maybe<UserType>;
};

export type UpdateWorkspaceGroup = {
  __typename?: 'UpdateWorkspaceGroup';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** Invites clients to current workspace */
export type UpdateWorkspaceInvitationSchedule = {
  __typename?: 'UpdateWorkspaceInvitationSchedule';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  existedEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  invalidEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  toBeInvitedEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UpdateWorkspaceName = {
  __typename?: 'UpdateWorkspaceName';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  updatedWorkspace?: Maybe<WorkspaceGraphqlType>;
};

export type UpdateWorkspaceUrlSlug = {
  __typename?: 'UpdateWorkspaceUrlSlug';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  updatedWorkspace?: Maybe<WorkspaceGraphqlType>;
};

export type UserCoachProductType = {
  __typename?: 'UserCoachProductType';
  coach?: Maybe<AgentType>;
  creditPrice?: Maybe<Scalars['Decimal']['output']>;
  lockReason?: Maybe<ProductLockReason>;
  locked?: Maybe<Scalars['Boolean']['output']>;
  requireBookingRequest?: Maybe<Scalars['Boolean']['output']>;
  usdPrice?: Maybe<Scalars['Decimal']['output']>;
};

export type UserCreditType = {
  __typename?: 'UserCreditType';
  bitsAvailable?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  receivableCredits?: Maybe<Scalars['Decimal']['output']>;
};

/** An enumeration. */
export enum UserCurrentSite {
  /** Exec */
  Exec = 'EXEC',
  /** Placement */
  Placement = 'PLACEMENT',
}

export type UserFileType = {
  __typename?: 'UserFileType';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum UserForcedPricingSite {
  /** Exec */
  Exec = 'EXEC',
  /** Placement */
  Placement = 'PLACEMENT',
}

export type UserInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hasIndividualAccount?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  linkedinAccount?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

/** DjangoObjectType to access the UserScenarioNote model. */
export type UserScenarioNoteType = {
  __typename?: 'UserScenarioNoteType';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type UserSessionType = {
  __typename?: 'UserSessionType';
  agentProfile?: Maybe<AgentProfileType>;
  agentSpecialtyProfiles: Array<AgentSpecialtyProfileType>;
  articleReadStatuses: Array<ArticleReadStatus>;
  articles: Array<Article>;
  assistOnUserIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  authorNotes: Array<WorkspaceNoteType>;
  /**
   *
   *             Indicates whether we are ready to collect the customer's bank account
   *             information and charge them. This field will eventually be automated.
   *
   */
  billingReady: Scalars['Boolean']['output'];
  calendarAccounts: Array<CalendarAccountType>;
  calendarSetting?: Maybe<CalendarSettingGraphqlType>;
  canceledMeetingsByMe: Array<ChatMeetingType>;
  canceledReviews: Array<ChatReviewType>;
  canceledScheduledJobs: Array<ScheduledJobSchemaType>;
  closedProgramComponents: Array<ProgramComponentType>;
  closedPrograms: Array<ProgramType>;
  coachAssignments: Array<ChatDirectMessageType>;
  coachPayouts: Array<CoachPayoutType>;
  coachProducts: Array<CoachProductType>;
  /**
   *
   *         Indicates whether we prompt user to report their commissions every month.
   *
   */
  commissionReportEnabled: Scalars['Boolean']['output'];
  companyRelationships: Array<CompanyType>;
  componentResponses: Array<ProgramComponentResponseType>;
  confirmationKey?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdRoleplays: Array<RoleplayType>;
  createdScenarios: Array<ScenarioType>;
  createdScheduledJobs: Array<ScheduledJobSchemaType>;
  createdTodos: Array<TodoType>;
  currentChatChannelId?: Maybe<Scalars['String']['output']>;
  currentSite: UserCurrentSite;
  currentWorkspace?: Maybe<WorkspaceType>;
  customerAssignments: Array<ChatDirectMessageType>;
  customerContextSearches: Array<SearchType>;
  customerMeetings: Array<ChatMeetingType>;
  customerNote?: Maybe<Scalars['String']['output']>;
  customerNotes: Array<WorkspaceNoteType>;
  email?: Maybe<Scalars['String']['output']>;
  emailProvider: Scalars['String']['output'];
  failedLoginHistory: Array<FailedLoginHistoryType>;
  faqAnswers: Array<FaqAnswerType>;
  files: Array<ResumeGraphqlType>;
  finalCheckRevisions: Array<ChatReviewType>;
  firstName?: Maybe<Scalars['String']['output']>;
  followCompanies: Array<FollowCompanyType>;
  /**
   *
   *         This usually is not set, but if we want to force a user to see the pricing
   *         from a specific site, we can set this field. This will show them the pricing
   *         from the site they are assigned to, rather than the site they are currently
   *         viewing.
   *
   */
  forcedPricingSite?: Maybe<UserForcedPricingSite>;
  /** The user who answered this question, currently aplying to a program survey */
  formFieldAnswers: Array<FormFieldAnswerAnalyticsType>;
  forwardingUsername?: Maybe<Scalars['String']['output']>;
  fulfillmentForms: Array<FulfillmentFormType>;
  fulfillmentLeads: Array<FulfillmentFormType>;
  gclid?: Maybe<Scalars['String']['output']>;
  gptResponses: Array<GptResponseType>;
  /** Indicates whether the user has individual_account */
  hasIndividualAccount: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  impersonatingSessions: Array<SessionType>;
  intakeForms: Array<IntakeFormType>;
  /** Indicates whether the user can login */
  isActive: Scalars['Boolean']['output'];
  /** Indicates whether the user is available as a coach to customers */
  isAgent: Scalars['Boolean']['output'];
  isAssist?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the user has confirmed their email */
  isConfirmed: Scalars['Boolean']['output'];
  /** Indicates whether this user has set their own password */
  isPasswordSet: Scalars['Boolean']['output'];
  /** Indicates whether the user can acess this admin site */
  isStaff: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  isWorkspaceAgent?: Maybe<Scalars['Boolean']['output']>;
  isWorkspaceGroupAdmin?: Maybe<Scalars['Boolean']['output']>;
  isWorkspaceUser?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  journalEntries: Array<JournalEntryType>;
  lastModifiedPermissions: Array<ResourcePermissionType>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastNudgeSentAt?: Maybe<Scalars['DateTime']['output']>;
  leads: Array<IntakeFormType>;
  ledgerAccountEntries: Array<LedgerAccountEntryType>;
  ledgerAccounts: Array<LedgerAccountType>;
  linkedinAccount?: Maybe<Scalars['String']['output']>;
  listingsLastRankedAt?: Maybe<Scalars['DateTime']['output']>;
  listingsLastScoredAt?: Maybe<Scalars['DateTime']['output']>;
  meetingAttendances: Array<MeetingAttendeeType>;
  name?: Maybe<Scalars['String']['output']>;
  notificationPreferences: Array<NotificationPreferenceGraphqlType>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  orders: Array<OrderType>;
  ownedProgramIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  ownedResources: Array<ResourcePermissionType>;
  participantProgramIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  paymentMethods: Array<PaymentMethodType>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         If we helped this person get a job, list approximately when they were hired.
   *
   */
  placedAt?: Maybe<Scalars['Date']['output']>;
  primaryCalendarAccount?: Maybe<CalendarAccountType>;
  /** If this is a custom product for a single coach, set them here */
  products: Array<ProductType>;
  programLeadWorkspaces?: Maybe<Array<Maybe<WorkspaceType>>>;
  programUsers: Array<ComponentParticipantType>;
  /**
   *
   *         Indicates whether the user actually claimed the promo code attached to their
   *         account. If the promo code is for a Pro or Plus discount, they may have a
   *         code attached but did not ultimately convert.
   *
   */
  promoCodeClaimed: Scalars['Boolean']['output'];
  /** If a user signed up using a Promo Code, it's displayed here. */
  promoCodeUsed?: Maybe<PromoCodeGraphqlType>;
  promptAnswers: Array<PromptAnswerType>;
  ratings: Array<PublicRatingType>;
  ratingsGiven: Array<PublicRatingType>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referrals: Array<ReferralType>;
  referredBys: Array<ReferralType>;
  resourcePermissions: Array<ResourcePermissionType>;
  reviewRefundExceptionsGranted: Array<ChatReviewType>;
  reviewRoundsCompleted: Array<ReviewRoundType>;
  reviewRoundsResponded: Array<ReviewRoundType>;
  reviews: Array<ChatReviewType>;
  roleplaySessions: Array<RoleplaySessionType>;
  scenarioIntakes: Array<ScenarioIntakeType>;
  scheduleOverrides: Array<ScheduleOverrideGraphqlType>;
  searches: Array<SearchType>;
  streamChannelCreated?: Maybe<Scalars['Boolean']['output']>;
  streamChannelFrozen?: Maybe<Scalars['Boolean']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<SubscriptionType>;
  testimonials: Array<TestimonialType>;
  timeZone?: Maybe<Scalars['String']['output']>;
  todos: Array<TodoType>;
  triggeredScheduledJobs: Array<ScheduledJobSchemaType>;
  updatedAt: Scalars['DateTime']['output'];
  /** Indicates whether internal calendars are available for the user */
  useInternalCalendar: Scalars['Boolean']['output'];
  userscenarionoteSet: Array<UserScenarioNoteType>;
  utmCampaign?: Maybe<Scalars['String']['output']>;
  utmContent?: Maybe<Scalars['String']['output']>;
  utmMedium?: Maybe<Scalars['String']['output']>;
  utmSource?: Maybe<Scalars['String']['output']>;
  utmTerm?: Maybe<Scalars['String']['output']>;
  workHistories: Array<UserWorkHistoryType>;
  /** Search for existing user by email */
  workspaceMembers: Array<TaggableWorkspaceMemberType>;
};

/** DjangoObjectType to access the User model. */
export type UserType = {
  __typename?: 'UserType';
  agentProfile?: Maybe<AgentProfileType>;
  agentSpecialtyProfiles: Array<AgentSpecialtyProfileType>;
  articleReadStatuses: Array<ArticleReadStatus>;
  articles: Array<Article>;
  authorNotes: Array<WorkspaceNoteType>;
  availableCredits?: Maybe<Scalars['Decimal']['output']>;
  billingReady?: Maybe<Scalars['Boolean']['output']>;
  bookingRequestMeetings?: Maybe<Array<Maybe<MeetingType>>>;
  bufferAfter?: Maybe<Scalars['Int']['output']>;
  bufferBefore?: Maybe<Scalars['Int']['output']>;
  calendarAccounts: Array<CalendarAccountType>;
  calendarEnabled?: Maybe<Scalars['Boolean']['output']>;
  calendarSetting?: Maybe<CalendarSettingGraphqlType>;
  canceledMeetingsByMe: Array<ChatMeetingType>;
  canceledReviews: Array<ChatReviewType>;
  canceledScheduledJobs: Array<ScheduledJobSchemaType>;
  chatToken?: Maybe<Scalars['String']['output']>;
  closedProgramComponents: Array<ProgramComponentType>;
  closedPrograms: Array<ProgramType>;
  coachAssignments: Array<ChatDirectMessageType>;
  coachPayouts: Array<CoachPayoutType>;
  coachProducts: Array<CoachProductType>;
  coaches?: Maybe<Array<Maybe<AgentType>>>;
  /**
   *
   *         Indicates whether we prompt user to report their commissions every month.
   *
   */
  commissionReportEnabled: Scalars['Boolean']['output'];
  companyRelationships: Array<CompanyType>;
  componentResponses: Array<ProgramComponentResponseType>;
  configuration?: Maybe<ConfigurationType>;
  confirmationKey?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdRoleplays: Array<RoleplayType>;
  createdScenarios: Array<ScenarioType>;
  createdScheduledJobs: Array<ScheduledJobSchemaType>;
  createdTodos: Array<TodoType>;
  currentChatChannelId?: Maybe<Scalars['String']['output']>;
  currentSite: UserCurrentSite;
  customerAssignments: Array<ChatDirectMessageType>;
  customerContextSearches: Array<SearchType>;
  customerMeetings: Array<ChatMeetingType>;
  customerNote?: Maybe<Scalars['String']['output']>;
  customerNotes: Array<WorkspaceNoteType>;
  desiredPlaces?: Maybe<Array<Maybe<PlaceType>>>;
  email: Scalars['String']['output'];
  emailProvider: Scalars['String']['output'];
  failedLoginHistory: Array<FailedLoginHistoryType>;
  faqAnswers: Array<FaqAnswerType>;
  files?: Maybe<Array<Maybe<UserFileType>>>;
  finalCheckRevisions: Array<ChatReviewType>;
  firstName?: Maybe<Scalars['String']['output']>;
  followCompanies: Array<FollowCompanyType>;
  /**
   *
   *         This usually is not set, but if we want to force a user to see the pricing
   *         from a specific site, we can set this field. This will show them the pricing
   *         from the site they are assigned to, rather than the site they are currently
   *         viewing.
   *
   */
  forcedPricingSite?: Maybe<UserForcedPricingSite>;
  /** The user who answered this question, currently aplying to a program survey */
  formFieldAnswers: Array<FormFieldAnswerAnalyticsType>;
  forwardingUsername?: Maybe<Scalars['String']['output']>;
  fulfillmentForms: Array<FulfillmentFormType>;
  fulfillmentLeads: Array<FulfillmentFormType>;
  futurePlaces?: Maybe<Array<Maybe<PlaceType>>>;
  gclid?: Maybe<Scalars['String']['output']>;
  gptResponses: Array<GptResponseType>;
  hasIndividualAccount?: Maybe<Scalars['Boolean']['output']>;
  hasPassword?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  impersonatingSessions: Array<SessionType>;
  intakeForm?: Maybe<IntakeFormType>;
  intakeForms: Array<IntakeFormType>;
  /** Indicates whether the user can login */
  isActive: Scalars['Boolean']['output'];
  /** Indicates whether the user is available as a coach to customers */
  isAgent: Scalars['Boolean']['output'];
  /** Indicates whether the user has confirmed their email */
  isConfirmed: Scalars['Boolean']['output'];
  /** Indicates whether this user has set their own password */
  isPasswordSet: Scalars['Boolean']['output'];
  /** Indicates whether the user can acess this admin site */
  isStaff: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  isWorkspaceAgent?: Maybe<Scalars['Boolean']['output']>;
  isWorkspaceUser?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  journalEntries: Array<JournalEntryType>;
  lastModifiedPermissions: Array<ResourcePermissionType>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastNudgeSentAt?: Maybe<Scalars['DateTime']['output']>;
  leads: Array<IntakeFormType>;
  ledgerAccountEntries: Array<LedgerAccountEntryType>;
  ledgerAccounts?: Maybe<Array<Maybe<LedgerAccountType>>>;
  linkedinAccount?: Maybe<Scalars['String']['output']>;
  listingsLastRankedAt?: Maybe<Scalars['DateTime']['output']>;
  listingsLastScoredAt?: Maybe<Scalars['DateTime']['output']>;
  livePrograms?: Maybe<ProgramQueryResult>;
  meetingAttendances: Array<MeetingAttendeeType>;
  meetings?: Maybe<Array<Maybe<MeetingType>>>;
  name?: Maybe<Scalars['String']['output']>;
  notificationPreferences: Array<NotificationPreferenceGraphqlType>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  ongoingReviews?: Maybe<Array<Maybe<ReviewType>>>;
  openToRemote?: Maybe<Scalars['Boolean']['output']>;
  orders: Array<OrderType>;
  otherCalendarAccounts?: Maybe<Array<Maybe<CalendarAccountType>>>;
  ownedResources: Array<ResourcePermissionType>;
  paymentMethod?: Maybe<PaymentMethodType>;
  paymentMethods: Array<PaymentMethodType>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         If we helped this person get a job, list approximately when they were hired.
   *
   */
  placedAt?: Maybe<Scalars['Date']['output']>;
  primaryCalendarAccount?: Maybe<CalendarAccountType>;
  /** If this is a custom product for a single coach, set them here */
  products: Array<ProductType>;
  programUsers: Array<ComponentParticipantType>;
  /**
   *
   *         Indicates whether the user actually claimed the promo code attached to their
   *         account. If the promo code is for a Pro or Plus discount, they may have a
   *         code attached but did not ultimately convert.
   *
   */
  promoCodeClaimed: Scalars['Boolean']['output'];
  /** If a user signed up using a Promo Code, it's displayed here. */
  promoCodeUsed?: Maybe<PromoCodeGraphqlType>;
  promptAnswers: Array<PromptAnswerType>;
  ratings: Array<PublicRatingType>;
  ratingsGiven: Array<PublicRatingType>;
  receivableCredits?: Maybe<Scalars['Decimal']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referrals?: Maybe<Array<Maybe<ReferralType>>>;
  referredBys: Array<ReferralType>;
  resourcePermissions: Array<ResourcePermissionType>;
  resumes?: Maybe<Array<Maybe<ResumeGraphqlType>>>;
  reviewRefundExceptionsGranted: Array<ChatReviewType>;
  reviewRoundsCompleted: Array<ReviewRoundType>;
  reviewRoundsResponded: Array<ReviewRoundType>;
  reviews?: Maybe<Array<Maybe<ReviewType>>>;
  roleplaySessions: Array<RoleplaySessionType>;
  scenarioIntakes: Array<ScenarioIntakeType>;
  scheduleOverrides: Array<ScheduleOverrideGraphqlType>;
  searches: Array<SearchType>;
  streamChannelCreated?: Maybe<Scalars['Boolean']['output']>;
  streamChannelFrozen?: Maybe<Scalars['Boolean']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<SubscriptionType>;
  testimonials: Array<TestimonialType>;
  timeZone?: Maybe<Scalars['String']['output']>;
  todos: Array<TodoType>;
  triggeredScheduledJobs: Array<ScheduledJobSchemaType>;
  unfulfilledOrders?: Maybe<Array<Maybe<OrderType>>>;
  upcomingMeetings?: Maybe<Array<Maybe<MeetingType>>>;
  updatedAt: Scalars['DateTime']['output'];
  /** Indicates whether internal calendars are available for the user */
  useInternalCalendar: Scalars['Boolean']['output'];
  userscenarionoteSet: Array<UserScenarioNoteType>;
  utmCampaign?: Maybe<Scalars['String']['output']>;
  utmContent?: Maybe<Scalars['String']['output']>;
  utmMedium?: Maybe<Scalars['String']['output']>;
  utmSource?: Maybe<Scalars['String']['output']>;
  utmTerm?: Maybe<Scalars['String']['output']>;
  workHistories: Array<UserWorkHistoryType>;
  /** Search for existing user by email */
  workspaceMembers: Array<TaggableWorkspaceMemberType>;
};

export type UserWorkHistoryType = {
  __typename?: 'UserWorkHistoryType';
  company?: Maybe<CompanyType>;
  /**
   *
   *         The label in case company is empty. Leave it empty if company is not empty.
   *
   */
  emptyCompanyLabel?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         The end date of the jobs. Leave it empty if user still working there.
   *
   */
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  jobTitles: Scalars['String']['output'];
  /** The start date of the jobs */
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type ValidateInviteEmailResultType = {
  __typename?: 'ValidateInviteEmailResultType';
  canBeInvitedEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  existedEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  existedProgramUserEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  invalidEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pendingInvEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar']['output'];
};

export type ViewPortType = {
  __typename?: 'ViewPortType';
  northeast?: Maybe<LocationType>;
  southwest?: Maybe<LocationType>;
};

/** An enumeration. */
export enum VisibilityScope {
  Global = 'Global',
  User = 'User',
  Workspace = 'Workspace',
}

export type WorkspaceAgentType = {
  __typename?: 'WorkspaceAgentType';
  agentAssignments: Array<WorkspaceAssignmentType>;
  assistantClients: Array<WorkspaceAssistantAssignmentType>;
  assistants: Array<WorkspaceAssistantAssignmentType>;
  availableCredits?: Maybe<Scalars['Decimal']['output']>;
  clientAssignments: Array<WorkspaceAssignmentType>;
  configuration?: Maybe<ConfigurationType>;
  createdAt: Scalars['DateTime']['output'];
  createdGroups: Array<WorkspaceGroupType>;
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  groups: Array<WorkspaceGroupType>;
  id: Scalars['ID']['output'];
  inviteAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  isAgent?: Maybe<Scalars['Boolean']['output']>;
  ledgerAccounts?: Maybe<Array<Maybe<LedgerAccountType>>>;
  memberships: Array<WorkspaceGroupMemberType>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  requiredInvitationEmail: Scalars['Boolean']['output'];
  role: WorkspaceMemberRole;
  roleplaySessions: Array<RoleplaySessionType>;
  totalBalance?: Maybe<Scalars['Decimal']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<AgentType>;
  workosId?: Maybe<Scalars['String']['output']>;
  workspace: PublicWorkspaceGraphqlType;
};

export type WorkspaceAnalyticsType = {
  __typename?: 'WorkspaceAnalyticsType';
  id?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type WorkspaceAssignmentType = {
  __typename?: 'WorkspaceAssignmentType';
  agent?: Maybe<WorkspaceAgentType>;
  chatChannelId?: Maybe<Scalars['String']['output']>;
  client?: Maybe<WorkspaceMemberType>;
  id: Scalars['ID']['output'];
  unreadMessages?: Maybe<Scalars['Int']['output']>;
};

export type WorkspaceAssistantAssignmentType = {
  __typename?: 'WorkspaceAssistantAssignmentType';
  assistant?: Maybe<WorkspaceMemberType>;
  client?: Maybe<WorkspaceMemberType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workspace?: Maybe<WorkspaceType>;
};

export type WorkspaceClientPageType = {
  __typename?: 'WorkspaceClientPageType';
  currentPage?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<WorkspaceMemberType>>>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  totalMembers?: Maybe<Scalars['Int']['output']>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum WorkspaceCreditMode {
  /** Budget */
  Budget = 'BUDGET',
  /** Credit */
  Credit = 'CREDIT',
}

export type WorkspaceDelete = {
  __typename?: 'WorkspaceDelete';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** DjangoObjectType to access the Workspace model. */
export type WorkspaceGraphqlType = {
  __typename?: 'WorkspaceGraphqlType';
  active: Scalars['Boolean']['output'];
  /**
   *
   *         The ID of the dashboard to show in the Analytics tab. Do not change this
   *         unless you know what you're doing.
   *
   */
  analyticsDashboardId: Scalars['Int']['output'];
  /**
   *
   *         When this field is checked, workspace admins will get an "Analytics" tab
   *
   */
  analyticsEnabled: Scalars['Boolean']['output'];
  assignmentSet: Array<ChatDirectMessageType>;
  createdAt: Scalars['DateTime']['output'];
  /** <span style="color: #cd4246;">If we update from BUDGET mode => CREDIT mode.We will revoke all member's budget credits. The member's credit will be reset to ZERO.</span> */
  creditMode: WorkspaceCreditMode;
  customRoleplays: Array<RoleplayType>;
  /**
   *
   *         When this field is checked, we will not add the Placement default
   *         new user playbooks. If the workspace has their own new user playbooks,
   *         those will be added irrespective of this field.
   *
   */
  disableDefaultNewUserPlaybooks: Scalars['Boolean']['output'];
  /**
   *
   *         When this field is checked, we will not add the Exec default
   *         opportunity playbooks. If the workspace has their own default opp
   *          playbooks, those will be added irrespective of this field.
   *
   */
  disableDefaultOppPlaybooks: Scalars['Boolean']['output'];
  groups: Array<WorkspaceGroupType>;
  id: Scalars['ID']['output'];
  inviteToken?: Maybe<Scalars['String']['output']>;
  ledgerAccounts: Array<LedgerAccountType>;
  logo?: Maybe<Scalars['String']['output']>;
  meetings: Array<ChatMeetingType>;
  members: Array<TaggableWorkspaceMemberType>;
  name: Scalars['String']['output'];
  numMembers?: Maybe<Scalars['Int']['output']>;
  orders: Array<OrderType>;
  programs: Array<ProgramType>;
  /** Toggle to enable/disable the program feature in this workspace. */
  programsEnabled: Scalars['Boolean']['output'];
  referredBys: Array<ReferralType>;
  /**
   *
   *         Which industries are this Workspace in? We will promote
   *         these industries in the app.
   *
   */
  relevantIndustries: Array<IndustryGraphqlType>;
  /**
   *
   *         Which job functions are import to this Workspace? We will promote
   *         these job functions in the app.
   *
   */
  relevantJobFunctions: Array<JobFunctionGraphqlType>;
  resourcepermissionSet: Array<ResourcePermissionType>;
  roleplaySessions: Array<RoleplaySessionType>;
  /** Toggle to enable/disable the roleplay feature in this workspace. */
  roleplaysEnabled: Scalars['Boolean']['output'];
  scenarioIntakes: Array<ScenarioIntakeType>;
  scenarios: Array<ScenarioType>;
  scheduledJobs: Array<ScheduledJobSchemaType>;
  searches: Array<SearchType>;
  sessions: Array<SessionType>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<SubscriptionType>;
  todoTemplate: Array<TodoTemplateType>;
  totalPendingInvitations?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** All lower case short name for the workspace url on exec.com */
  urlSlug: Scalars['String']['output'];
  /** WorkOS Organization ID for SSO */
  workosOrganizationId?: Maybe<Scalars['String']['output']>;
  workspace: Array<WorkspaceAssistantAssignmentType>;
  workspaceInvitationBatches: Array<WorkspaceInvitationBatchType>;
};

/** An enumeration. */
export enum WorkspaceGroupMemberRole {
  /** Admin */
  Admin = 'ADMIN',
  /** Member */
  Member = 'MEMBER',
}

export type WorkspaceGroupMemberType = {
  __typename?: 'WorkspaceGroupMemberType';
  availableCredits?: Maybe<Scalars['Decimal']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  ledgerAccounts: Array<LedgerAccountType>;
  role: WorkspaceGroupMemberRole;
  updatedAt: Scalars['DateTime']['output'];
  workspaceGroup?: Maybe<WorkspaceGroupType>;
  workspaceMember?: Maybe<WorkspaceMemberType>;
};

export type WorkspaceGroupType = {
  __typename?: 'WorkspaceGroupType';
  availableCredits?: Maybe<Scalars['Decimal']['output']>;
  configuration?: Maybe<ConfigurationType>;
  currentUserRole?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  memberCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

/** An enumeration. */
export enum WorkspaceInvitationBatchOnCompleteTaskStatus {
  /** Completed */
  Completed = 'COMPLETED',
  /** Failed */
  Failed = 'FAILED',
}

/** An enumeration. */
export enum WorkspaceInvitationBatchStatus {
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Completed */
  Completed = 'COMPLETED',
  /** New */
  New = 'NEW',
  /** Pending */
  Pending = 'PENDING',
  /** Running */
  Running = 'RUNNING',
}

export type WorkspaceInvitationBatchType = {
  __typename?: 'WorkspaceInvitationBatchType';
  coachIds: Array<Maybe<Scalars['Int']['output']>>;
  coaches?: Maybe<Array<Maybe<MiniCoachType>>>;
  createdAt: Scalars['DateTime']['output'];
  credit: Scalars['Float']['output'];
  group?: Maybe<WorkspaceGroupType>;
  id: Scalars['ID']['output'];
  meetingAttendees: Array<MeetingAttendeeType>;
  onCompleteTaskName?: Maybe<Scalars['String']['output']>;
  onCompleteTaskPayload: Scalars['JSONString']['output'];
  onCompleteTaskStatus?: Maybe<WorkspaceInvitationBatchOnCompleteTaskStatus>;
  onFailedTaskName?: Maybe<Scalars['String']['output']>;
  onFailedTaskPayload: Scalars['JSONString']['output'];
  program?: Maybe<ProgramType>;
  status: WorkspaceInvitationBatchStatus;
  updatedAt: Scalars['DateTime']['output'];
  workspace: PublicWorkspaceGraphqlType;
  workspaceInvitations?: Maybe<Array<Maybe<WorkspaceInvitationType>>>;
};

export enum WorkspaceInvitationBatchesStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
}

/** An enumeration. */
export enum WorkspaceInvitationStatus {
  /** Canceled */
  Canceled = 'CANCELED',
  /** Completed */
  Completed = 'COMPLETED',
  /** Failed */
  Failed = 'FAILED',
  /** New */
  New = 'NEW',
}

export type WorkspaceInvitationType = {
  __typename?: 'WorkspaceInvitationType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isCreditAllocated: Scalars['Boolean']['output'];
  isMemberExisted: Scalars['Boolean']['output'];
  isUserExisted: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
  status: WorkspaceInvitationStatus;
  updatedAt: Scalars['DateTime']['output'];
  workspaceInvitationBatch: WorkspaceInvitationBatchType;
};

/** An enumeration. */
export enum WorkspaceMemberRole {
  /** Admin */
  Admin = 'ADMIN',
  /** Member */
  Member = 'MEMBER',
  /** Staff */
  Staff = 'STAFF',
}

export type WorkspaceMemberType = {
  __typename?: 'WorkspaceMemberType';
  agentAssignments: Array<WorkspaceAssignmentType>;
  assistantClients: Array<WorkspaceAssistantAssignmentType>;
  assistants: Array<WorkspaceAssistantAssignmentType>;
  availableCredits?: Maybe<Scalars['Decimal']['output']>;
  clientAssignments: Array<WorkspaceAssignmentType>;
  configuration?: Maybe<ConfigurationType>;
  createdAt: Scalars['DateTime']['output'];
  createdGroups: Array<WorkspaceGroupType>;
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  groups: Array<WorkspaceGroupType>;
  id: Scalars['ID']['output'];
  inviteAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  isAgent?: Maybe<Scalars['Boolean']['output']>;
  ledgerAccounts?: Maybe<Array<Maybe<LedgerAccountType>>>;
  memberships: Array<WorkspaceGroupMemberType>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  requiredInvitationEmail: Scalars['Boolean']['output'];
  role: WorkspaceMemberRole;
  roleplaySessions: Array<RoleplaySessionType>;
  totalBalance?: Maybe<Scalars['Decimal']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** Search for existing user by email */
  user: UserType;
  workosId?: Maybe<Scalars['String']['output']>;
  workspace: PublicWorkspaceGraphqlType;
};

export type WorkspaceNoteType = {
  __typename?: 'WorkspaceNoteType';
  author: UserType;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer: UserType;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WorkspaceType = {
  __typename?: 'WorkspaceType';
  active: Scalars['Boolean']['output'];
  /**
   *
   *         When this field is checked, workspace admins will get an "Analytics" tab
   *
   */
  analyticsEnabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /**
   *
   *         Logo to be displayed throughout the workspace app, ideally the
   *         same size as the Exec logo
   *
   */
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** Toggle to enable/disable the program feature in this workspace. */
  programsEnabled: Scalars['Boolean']['output'];
  /** Toggle to enable/disable the roleplay feature in this workspace. */
  roleplaysEnabled: Scalars['Boolean']['output'];
  subscription?: Maybe<SubscriptionType>;
  /** All lower case short name for the workspace url on exec.com */
  urlSlug: Scalars['String']['output'];
};

export type LoggedInNavDataQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LoggedInNavDataQueryQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    name?: string | null;
    phoneNumber?: string | null;
    jobTitle?: string | null;
    linkedinAccount?: string | null;
    timeZone?: string | null;
    billingReady?: boolean | null;
    photo?: string | null;
    isAgent: boolean;
    referralCode?: string | null;
    notificationPreferences: Array<{
      __typename?: 'NotificationPreferenceGraphqlType';
      type: NotificationPreferenceType;
      email: boolean;
    }>;
    agentProfile?: {
      __typename?: 'AgentProfileType';
      id: string;
      coachSlug: string;
    } | null;
  } | null;
};

export type ResendEmailConfirmationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResendEmailConfirmationMutation = {
  __typename?: 'Mutation';
  resendEmailConfirmation?: {
    __typename?: 'ResendEmailConfirmation';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type ArticleSeriesQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type ArticleSeriesQuery = {
  __typename?: 'Query';
  articleSeries?: {
    __typename?: 'ArticleSeries';
    id?: string | null;
    title?: string | null;
    urlSlug?: string | null;
    description?: string | null;
    canonicalDomain: ArticleSeriesCanonicalDomain;
    relatedSeries: Array<{
      __typename?: 'ArticleSeries';
      id?: string | null;
      title?: string | null;
      urlSlug?: string | null;
    }>;
  } | null;
};

export type ChooseCoachIntroProductQueryVariables = Exact<{
  specialtySlug: Scalars['String']['input'];
}>;

export type ChooseCoachIntroProductQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    intakeForm?: {
      __typename?: 'IntakeFormType';
      id: string;
      token?: string | null;
    } | null;
  } | null;
  introProduct?: {
    __typename?: 'ProductType';
    id: string;
    name: string;
    slug: string;
    category?: ProductCategory | null;
  } | null;
};

export type ChooseCoachProductsQueryVariables = Exact<{
  slugs?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ChooseCoachProductsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    intakeForm?: {
      __typename?: 'IntakeFormType';
      id: string;
      token?: string | null;
    } | null;
  } | null;
  products?: Array<{
    __typename?: 'ProductType';
    id: string;
    name: string;
    slug: string;
    category?: ProductCategory | null;
  } | null> | null;
};

export type AgentProfileQueryVariables = Exact<{
  coachSlug?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type AgentProfileQuery = {
  __typename?: 'Query';
  agentProfile?: {
    __typename?: 'AgentProfileType';
    id: string;
    seoIndex?: boolean | null;
    primarySite: AgentProfilePrimarySite;
    coachSlug: string;
    about?: string | null;
    summary?: string | null;
    createdAt: any;
    seoPhoto?: string | null;
    averageRating?: number | null;
    credentials?: Array<string | null> | null;
    introVideo?: string | null;
    introVideoThumbnail?: string | null;
    matchingIndustries?: Array<string | null> | null;
    matchingProficiencies?: Array<string | null> | null;
    matchingJobFunctions?: Array<string | null> | null;
    acceptingNewClients: boolean;
    acceptingNewBookings?: boolean | null;
    canHaveIntroSession?: boolean | null;
    user?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
      referralCode?: string | null;
      workHistories?: Array<{
        __typename?: 'UserWorkHistoryType';
        id: string;
        jobTitles: string;
        emptyCompanyLabel?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        company?: {
          __typename?: 'CompanyType';
          id: string;
          logoUrl?: string | null;
          name: string;
        } | null;
      } | null> | null;
    } | null;
    standardCredentials?: Array<{
      __typename?: 'StandardCredentialType';
      id: string;
      name: string;
      description: string;
      issuer: string;
      requirements?: string | null;
      badgeUrl?: string | null;
    } | null> | null;
    relevantIndustries: Array<{
      __typename?: 'IndustryGraphqlType';
      id: string;
      name: string;
    }>;
    relevantJobFunctions: Array<{
      __typename?: 'JobFunctionGraphqlType';
      id: string;
      name: string;
    }>;
    availability?: Array<{
      __typename?: 'AgentAvailabilityType';
      date?: any | null;
      slots?: Array<any | null> | null;
    } | null> | null;
    faqAnswers?: Array<{
      __typename?: 'FaqAnswerType';
      id: string;
      content?: string | null;
      videoUrl?: string | null;
      videoThumbnail?: string | null;
      faqQuestion?: {
        __typename?: 'FaqQuestionType';
        id: string;
        content: string;
      } | null;
    } | null> | null;
    testimonials?: Array<{
      __typename?: 'TestimonialType';
      id: string;
      name: string;
      message: string;
      jobTitle: string;
      avatarUrl?: string | null;
    } | null> | null;
  } | null;
  specialties?: Array<{
    __typename?: 'SpecialtyType';
    id: string;
    slug: string;
    name: string;
    introProduct?: {
      __typename?: 'ProductType';
      id: string;
      slug: string;
    } | null;
  } | null> | null;
};

export type WorkHistoriesFragmentFragment = {
  __typename?: 'AgentType';
  workHistories?: Array<{
    __typename?: 'UserWorkHistoryType';
    id: string;
    jobTitles: string;
    emptyCompanyLabel?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    company?: {
      __typename?: 'CompanyType';
      id: string;
      logoUrl?: string | null;
      name: string;
    } | null;
  } | null> | null;
};

export type CompanyForDisplayDetailQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type CompanyForDisplayDetailQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyType';
    id: string;
    name: string;
    domain?: string | null;
    description?: string | null;
    founded?: number | null;
    size?: string | null;
    companyLogo?: string | null;
    fullyRemote: boolean;
    twitter?: string | null;
    facebook?: string | null;
    linkedin?: string | null;
    crunchbase?: string | null;
    companySeoSlug?: string | null;
    canonicalIndustry?: {
      __typename?: 'IndustryGraphqlType';
      id: string;
      name: string;
    } | null;
    headquarters?: {
      __typename?: 'PlaceType';
      id: string;
      googlePlaceId: string;
      city?: string | null;
      stateCode?: string | null;
      name: string;
    } | null;
  } | null;
};

export type ServiceDetailQueryVariables = Exact<{
  productSlug?: InputMaybe<Scalars['String']['input']>;
  coachSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type ServiceDetailQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'ProductType';
    id: string;
    slug: string;
    name: string;
    primarySite: ProductPrimarySite;
    category?: ProductCategory | null;
    shortDescription?: string | null;
    description?: string | null;
    duration?: number | null;
    enableBookingRequest: boolean;
    coachEnableBookingRequest?: Array<string | null> | null;
    baseParticipants?: number | null;
    maxParticipants?: number | null;
    numRounds?: number | null;
    includesFinalCheck: boolean;
    turnaroundTime?: number | null;
    enableRfq: boolean;
    metaImageUrl?: string | null;
    numRatings: number;
    averageRating?: number | null;
    specialty?: {
      __typename?: 'SpecialtyType';
      id: string;
      name: string;
    } | null;
    activeFormFields?: Array<{
      __typename?: 'FormFieldType';
      id: string;
      prompt: string;
      answerType: FormFieldAnswerType;
      helpText?: string | null;
      required: boolean;
      formFieldOptions?: Array<{
        __typename?: 'FormFieldOptionType';
        id: string;
        label: string;
        value: string;
      } | null> | null;
    } | null> | null;
    detailGroups?: Array<{
      __typename?: 'ProductDetailGroupType';
      id: string;
      label: string;
      displayType?: DetailGroupTypes | null;
    } | null> | null;
    detailLines?: Array<{
      __typename?: 'ProductDetailLineType';
      id: string;
      header?: string | null;
      text: string;
      detailGroupId?: string | null;
    } | null> | null;
    faqAnswers?: Array<{
      __typename?: 'FaqAnswerType';
      id: string;
      content?: string | null;
      videoUrl?: string | null;
      videoThumbnail?: string | null;
      faqQuestion?: {
        __typename?: 'FaqQuestionType';
        id: string;
        content: string;
      } | null;
    } | null> | null;
    endorsements?: Array<{
      __typename?: 'ProductEndorsementType';
      id: string;
      company?: {
        __typename?: 'CompanyType';
        id: string;
        name: string;
        companyLogo?: string | null;
      } | null;
    } | null> | null;
    priceRange?: {
      __typename?: 'ProductPriceRangeType';
      minCreditPrice?: any | null;
      maxCreditPrice?: any | null;
      minUsdPrice?: any | null;
      maxUsdPrice?: any | null;
    } | null;
    userCoachProducts?: Array<{
      __typename?: 'UserCoachProductType';
      requireBookingRequest?: boolean | null;
      creditPrice?: any | null;
      usdPrice?: any | null;
      locked?: boolean | null;
      lockReason?: ProductLockReason | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        agentProfile?: {
          __typename?: 'AgentProfileType';
          id: string;
          summary?: string | null;
          averageRating?: number | null;
          credentials?: Array<string | null> | null;
          coachSlug: string;
          standardCredentials?: Array<{
            __typename?: 'StandardCredentialType';
            id: string;
            name: string;
            description: string;
            issuer: string;
            requirements?: string | null;
            badgeUrl?: string | null;
          } | null> | null;
          user?: {
            __typename?: 'AgentType';
            id: string;
            workHistories?: Array<{
              __typename?: 'UserWorkHistoryType';
              id: string;
              jobTitles: string;
              emptyCompanyLabel?: string | null;
              startDate?: any | null;
              endDate?: any | null;
              company?: {
                __typename?: 'CompanyType';
                id: string;
                logoUrl?: string | null;
                name: string;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SubmitRfqMutationVariables = Exact<{
  productId: Scalars['ID']['input'];
  coachId: Scalars['ID']['input'];
  formData?: InputMaybe<Scalars['GenericScalar']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubmitRfqMutation = {
  __typename?: 'Mutation';
  submitRfq?: {
    __typename?: 'SubmitRFQ';
    ok?: boolean | null;
    error?: { __typename?: 'ErrorType'; message?: string | null } | null;
  } | null;
};

export type TopArticleSeriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TopArticleSeriesQuery = {
  __typename?: 'Query';
  topArticleSeries?: Array<{
    __typename?: 'ArticleSeries';
    id?: string | null;
    description?: string | null;
    urlSlug?: string | null;
    title?: string | null;
  } | null> | null;
};

export type BasicProductsQueryVariables = Exact<{
  productSlugs?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type BasicProductsQuery = {
  __typename?: 'Query';
  products?: Array<{
    __typename?: 'ProductType';
    id: string;
    slug: string;
    specialty?: {
      __typename?: 'SpecialtyType';
      id: string;
      slug: string;
    } | null;
  } | null> | null;
};

export type BasicSpecialtiesQueryVariables = Exact<{ [key: string]: never }>;

export type BasicSpecialtiesQuery = {
  __typename?: 'Query';
  specialties?: Array<{
    __typename?: 'SpecialtyType';
    id: string;
    name: string;
    slug: string;
    iconUrl?: string | null;
    shortDescription?: string | null;
    hiddenInMatchFlow: boolean;
  } | null> | null;
};

export type AgentProfilesQueryVariables = Exact<{
  productSlug: Scalars['String']['input'];
  intakeFormToken?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  numCoaches?: InputMaybe<Scalars['Int']['input']>;
  includeCurrent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AgentProfilesQuery = {
  __typename?: 'Query';
  agentProfiles?: Array<{
    __typename?: 'AgentProfileType';
    id: string;
    matchingProficiencies?: Array<string | null> | null;
    matchingJobTitles?: Array<string | null> | null;
    matchingIndustries?: Array<string | null> | null;
    matchingJobFunctions?: Array<string | null> | null;
    summary?: string | null;
    about?: string | null;
    introVideo?: string | null;
    introVideoThumbnail?: string | null;
    credentials?: Array<string | null> | null;
    averageRating?: number | null;
    canHaveIntroSession?: boolean | null;
    user?: {
      __typename?: 'AgentType';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      photo?: string | null;
      activeCoachProducts?: Array<{
        __typename?: 'CoachProductType';
        id?: number | null;
        acceptingNewClients?: boolean | null;
        requireBookingRequest?: boolean | null;
        product?: {
          __typename?: 'ProductType';
          name: string;
          id: string;
          shortDescription?: string | null;
          category?: ProductCategory | null;
          creditPrice?: number | null;
          introProduct: boolean;
          enableRfq: boolean;
          enableBookingRequest: boolean;
          slug: string;
          duration?: number | null;
          msrp?: number | null;
          price?: number | null;
          specialty?: {
            __typename?: 'SpecialtyType';
            id: string;
            name: string;
            description?: string | null;
            slug: string;
          } | null;
        } | null;
      } | null> | null;
      workHistories?: Array<{
        __typename?: 'UserWorkHistoryType';
        id: string;
        jobTitles: string;
        emptyCompanyLabel?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        company?: {
          __typename?: 'CompanyType';
          id: string;
          logoUrl?: string | null;
          name: string;
        } | null;
      } | null> | null;
    } | null;
    availability?: Array<{
      __typename?: 'AgentAvailabilityType';
      date?: any | null;
      slots?: Array<any | null> | null;
    } | null> | null;
    standardCredentials?: Array<{
      __typename?: 'StandardCredentialType';
      id: string;
      name: string;
      description: string;
      issuer: string;
      requirements?: string | null;
      badgeUrl?: string | null;
    } | null> | null;
    faqAnswers?: Array<{
      __typename?: 'FaqAnswerType';
      id: string;
    } | null> | null;
  } | null> | null;
};

export type CoachProfileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CoachProfileQuery = {
  __typename?: 'Query';
  coach?: {
    __typename?: 'AgentType';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    photo?: string | null;
    agentProfile?: {
      __typename?: 'AgentProfileType';
      id: string;
      matchingProficiencies?: Array<string | null> | null;
      matchingJobTitles?: Array<string | null> | null;
      matchingIndustries?: Array<string | null> | null;
      summary?: string | null;
      about?: string | null;
      introVideo?: string | null;
      credentials?: Array<string | null> | null;
      averageRating?: number | null;
    } | null;
  } | null;
};

export type FindMyAgentQueryVariables = Exact<{ [key: string]: never }>;

export type FindMyAgentQuery = {
  __typename?: 'Query';
  optionalCurrentUser?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    name?: string | null;
    intakeForm?: {
      __typename?: 'IntakeFormType';
      id: string;
      token?: string | null;
      industriesSkipped: boolean;
      jobTitlesSkipped: boolean;
      jobFunctionsSkipped: boolean;
      matchingOptions: Array<{ __typename?: 'MatchingOptionType'; id: string }>;
      industries: Array<{
        __typename?: 'IndustryGraphqlType';
        id: string;
        name: string;
      }>;
      specialty?: {
        __typename?: 'SpecialtyType';
        id: string;
        name: string;
        slug: string;
      } | null;
      jobTitles: Array<{
        __typename?: 'JobTitleType';
        normalizedTitle: string;
        title: string;
      }>;
      jobFunctions: Array<{
        __typename?: 'JobFunctionGraphqlType';
        name: string;
        id: string;
      }>;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        firstName?: string | null;
        photo?: string | null;
      } | null;
      assignment?: {
        __typename?: 'ChatDirectMessageType';
        id: string;
        coach?: { __typename?: 'TaggableUserType'; id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type FindMyAgentCheckoutMutationVariables = Exact<{
  successUrl?: InputMaybe<Scalars['String']['input']>;
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  ctaType?: InputMaybe<Scalars['String']['input']>;
  ctaSource?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindMyAgentCheckoutMutation = {
  __typename?: 'Mutation';
  findMyAgentCheckout?: {
    __typename?: 'FindMyAgentCheckout';
    id?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type MatchingCategoriesQueryVariables = Exact<{
  specialtySlug: Scalars['String']['input'];
}>;

export type MatchingCategoriesQuery = {
  __typename?: 'Query';
  matchingCategories?: Array<{
    __typename?: 'MatchingCategoryType';
    id: string;
    name: string;
    description?: string | null;
    selectionType: MatchingCategorySelectionType;
    matchingOptions: Array<{
      __typename?: 'MatchingOptionType';
      id: string;
      name: string;
      iconUrl?: string | null;
      description?: string | null;
    }>;
  } | null> | null;
};

export type SaveIntakeFormMutationVariables = Exact<{
  input: IntakeFormInput;
  token?: InputMaybe<Scalars['String']['input']>;
  productSlug?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SaveIntakeFormMutation = {
  __typename?: 'Mutation';
  saveIntakeForm?: {
    __typename?: 'SaveIntakeForm';
    ok?: boolean | null;
    token?: string | null;
    userId?: string | null;
    orderId?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type StandardCredentialPartsFragment = {
  __typename?: 'StandardCredentialType';
  id: string;
  name: string;
  description: string;
  issuer: string;
  requirements?: string | null;
  badgeUrl?: string | null;
};

export type UpdateCoachInfoMutationVariables = Exact<{
  about: Scalars['String']['input'];
  summary: Scalars['String']['input'];
  jobFunctionIds:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  industryIds:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateCoachInfoMutation = {
  __typename?: 'Mutation';
  updateCoachInfo?: {
    __typename?: 'UpdateCoachInfo';
    ok?: boolean | null;
    error?: {
      __typename?: 'ErrorType';
      message?: string | null;
      code?: string | null;
    } | null;
  } | null;
};

export type UpdateCoachProfileMutationVariables = Exact<{
  acceptingNewClients: Scalars['Boolean']['input'];
  acceptingNewBookings: Scalars['Boolean']['input'];
}>;

export type UpdateCoachProfileMutation = {
  __typename?: 'Mutation';
  updateCoachProfile?: {
    __typename?: 'UpdateCoachProfile';
    ok?: boolean | null;
  } | null;
};

export type ReferralsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type ReferralsQueryQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    isAgent: boolean;
    referralCode?: string | null;
    referrals?: Array<{
      __typename?: 'ReferralType';
      id: string;
      createdAt: any;
      referralType?: string | null;
      acquisitionBonusEligibleAt?: any | null;
      acquisitionBonusPaidAmount: number;
      referralBonusPaid: boolean;
    } | null> | null;
  } | null;
};

export type AssignInitialWorkspaceAdminMutationVariables = Exact<{
  workspaceSignedToken: Scalars['String']['input'];
}>;

export type AssignInitialWorkspaceAdminMutation = {
  __typename?: 'Mutation';
  assignInitialWorkspaceAdmin?: {
    __typename?: 'AssignInitialWorkspaceAdmin';
    ok?: boolean | null;
    urlSlug?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type BuildPackageQueryVariables = Exact<{
  slugs?: InputMaybe<Scalars['String']['input']>;
}>;

export type BuildPackageQuery = {
  __typename?: 'Query';
  products?: Array<{
    __typename?: 'ProductType';
    id: string;
    name: string;
    slug: string;
  } | null> | null;
  creditPackages?: Array<{
    __typename?: 'ProductType';
    id: string;
    price?: number | null;
    creditValue?: number | null;
  } | null> | null;
};

export type OrderNextStepsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type OrderNextStepsQuery = {
  __typename?: 'Query';
  order?: {
    __typename?: 'OrderType';
    id: string;
    status: OrderStatus;
    paymentStatus: OrderPaymentStatus;
    orderItems?: Array<{
      __typename?: 'OrderItemType';
      id: string;
      fulfillmentStatus: FulfillmentStatusTypes;
      latestMeetingId?: string | null;
      latestReviewId?: string | null;
      product: {
        __typename?: 'ProductType';
        id: string;
        slug: string;
        introProduct: boolean;
      };
    } | null> | null;
    unmatchedFulfillmentForms?: Array<{
      __typename?: 'FulfillmentFormType';
      id: string;
    } | null> | null;
    incompleteFulfillmentForms?: Array<{
      __typename?: 'FulfillmentFormType';
      id: string;
      missingRequiredAnswers?: boolean | null;
    } | null> | null;
  } | null;
};

export type CreateCoachMeetingMutationVariables = Exact<{
  coachId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
  startTime: Scalars['DateTime']['input'];
  orderItemId?: InputMaybe<Scalars['ID']['input']>;
  fulfillmentFormId?: InputMaybe<Scalars['ID']['input']>;
  formData?: InputMaybe<Scalars['GenericScalar']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
  timeslotsStrings?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  ledgerAccountId?: InputMaybe<Scalars['ID']['input']>;
  usedAllTimeslot?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateCoachMeetingMutation = {
  __typename?: 'Mutation';
  createCoachMeeting?: {
    __typename?: 'CreateCoachMeeting';
    ok?: boolean | null;
    status?: CreateMeetingStatus | null;
    meeting?: {
      __typename?: 'MeetingType';
      id: string;
      eventAt?: any | null;
    } | null;
  } | null;
};

export type FetchFulfillmentFormsQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type FetchFulfillmentFormsQuery = {
  __typename?: 'Query';
  order?: {
    __typename?: 'OrderType';
    id: string;
    errorMessages?: Array<string | null> | null;
    incompleteFulfillmentForms?: Array<{
      __typename?: 'FulfillmentFormType';
      id: string;
      meetingId?: number | null;
      user?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        timeZone?: string | null;
      } | null;
      coach?: {
        __typename?: 'MiniCoachType';
        id: string;
        firstName?: string | null;
      } | null;
      orderItem?: {
        __typename?: 'OrderItemType';
        id: string;
        orderId?: number | null;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        slug: string;
        name: string;
        coachEnableBookingRequest?: Array<string | null> | null;
        enableBookingRequest: boolean;
        activeFormFields?: Array<{
          __typename?: 'FormFieldType';
          id: string;
          prompt: string;
          answerType: FormFieldAnswerType;
          helpText?: string | null;
          required: boolean;
          formFieldOptions?: Array<{
            __typename?: 'FormFieldOptionType';
            id: string;
            label: string;
            value: string;
          } | null> | null;
        } | null> | null;
      } | null;
      formFieldAnswers?: Array<{
        __typename?: 'AnswerType';
        id: string;
        value?: string | null;
        formField?: { __typename?: 'FieldType'; id: string } | null;
        optionValue?: {
          __typename?: 'FormFieldOptionAnalyticsType';
          id: string;
          value: string;
          label: string;
        } | null;
        userFile?: {
          __typename?: 'UserFileType';
          id: string;
          name?: string | null;
          url?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetCoachAvailableDaysQueryVariables = Exact<{
  coachId: Scalars['ID']['input'];
  orderItemId: Scalars['ID']['input'];
}>;

export type GetCoachAvailableDaysQuery = {
  __typename?: 'Query';
  coachAvailableDays?: Array<number | null> | null;
  orderItemMeeting?: {
    __typename?: 'MeetingType';
    id: string;
    chatChannelId?: string | null;
    numMessages?: number | null;
    eventAt?: any | null;
    canceledAt?: any | null;
    cancelReason?: string | null;
    location?: string | null;
    customLocation?: string | null;
    duration?: number | null;
    customerId?: number | null;
    refundableUntil?: any | null;
    canLeaveRating?: boolean | null;
    paymentStatus?: PaymentStatusTypes | null;
    googleEventId?: string | null;
    creditPrice?: any | null;
    allowInvitation: boolean;
    allowGuests?: boolean | null;
    guests?: Array<string | null> | null;
    isBookingRequest?: boolean | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
      coachSlug?: string | null;
      email: string;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
      email: string;
    } | null;
    attendee?: {
      __typename?: 'MeetingAttendeeType';
      id: string;
      userId?: number | null;
      status?: InvitationStatus | null;
      attendingStatus?: AttendingStatus | null;
      meetingId?: number | null;
    } | null;
    workspace?: {
      __typename?: 'WorkspaceType';
      id: string;
      name: string;
      urlSlug: string;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      duration?: number | null;
      category?: ProductCategory | null;
      description?: string | null;
      introProduct: boolean;
      enableGuests?: boolean | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null;
};

export type GetCoachAvailableTimeslotByDayQueryVariables = Exact<{
  coachId: Scalars['ID']['input'];
  productSlug: Scalars['String']['input'];
  lookupDay: Scalars['Date']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCoachAvailableTimeslotByDayQuery = {
  __typename?: 'Query';
  coachAvailableTimeslotByDay?: Array<any | null> | null;
};

export type SaveFulfillmentFormMutationVariables = Exact<{
  formId: Scalars['ID']['input'];
  answerData?: InputMaybe<
    | Array<InputMaybe<FulfillmentFormAnswersInput>>
    | InputMaybe<FulfillmentFormAnswersInput>
  >;
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SaveFulfillmentFormMutation = {
  __typename?: 'Mutation';
  saveFulfillmentForm?: {
    __typename?: 'SaveFulfillmentForm';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    fulfillmentForm?: {
      __typename?: 'FulfillmentFormType';
      id: string;
      meetingId?: number | null;
      user?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      coach?: {
        __typename?: 'MiniCoachType';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      orderItem?: {
        __typename?: 'OrderItemType';
        id: string;
        orderId?: number | null;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        slug: string;
        name: string;
        activeFormFields?: Array<{
          __typename?: 'FormFieldType';
          id: string;
          prompt: string;
          answerType: FormFieldAnswerType;
          helpText?: string | null;
          required: boolean;
          formFieldOptions?: Array<{
            __typename?: 'FormFieldOptionType';
            id: string;
            label: string;
            value: string;
          } | null> | null;
        } | null> | null;
      } | null;
      formFieldAnswers?: Array<{
        __typename?: 'AnswerType';
        id: string;
        value?: string | null;
        formField?: { __typename?: 'FieldType'; id: string } | null;
        optionValue?: {
          __typename?: 'FormFieldOptionAnalyticsType';
          id: string;
          value: string;
          label: string;
        } | null;
        userFile?: {
          __typename?: 'UserFileType';
          id: string;
          name?: string | null;
          url?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PopularIndustriesQueryVariables = Exact<{ [key: string]: never }>;

export type PopularIndustriesQuery = {
  __typename?: 'Query';
  popularIndustries?: Array<{
    __typename?: 'IndustryGraphqlType';
    id: string;
    name: string;
  } | null> | null;
};

export type PopularJobFunctionsQueryVariables = Exact<{ [key: string]: never }>;

export type PopularJobFunctionsQuery = {
  __typename?: 'Query';
  popularJobFunctions?: Array<{
    __typename?: 'JobFunctionGraphqlType';
    id: string;
    name: string;
  } | null> | null;
};

export type PopularPlacesQueryVariables = Exact<{ [key: string]: never }>;

export type PopularPlacesQuery = {
  __typename?: 'Query';
  popularPlaces?: Array<{
    __typename?: 'PlaceType';
    googlePlaceId: string;
    type: string;
    name: string;
    city?: string | null;
    stateCode?: string | null;
    countryCode: PlaceCountryCode;
    location?: Array<string | null> | null;
  } | null> | null;
};

export type PopularSearchesQueryVariables = Exact<{ [key: string]: never }>;

export type PopularSearchesQuery = {
  __typename?: 'Query';
  popularTitles?: Array<{
    __typename?: 'JobTitleType';
    normalizedTitle: string;
    title: string;
  } | null> | null;
};

export type PopularTitlesQueryVariables = Exact<{ [key: string]: never }>;

export type PopularTitlesQuery = {
  __typename?: 'Query';
  popularTitles?: Array<{
    __typename?: 'JobTitleType';
    normalizedTitle: string;
    title: string;
  } | null> | null;
};

export type SaveNameMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type SaveNameMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'UpdateUser';
    errors?: Array<{
      __typename?: 'Error';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CoachPayoutAccountQueryVariables = Exact<{ [key: string]: never }>;

export type CoachPayoutAccountQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    agentProfile?: {
      __typename?: 'AgentProfileType';
      id: string;
      paymentOnboardingReady?: boolean | null;
      payoutChannel?: string | null;
    } | null;
  } | null;
};

export type CoachPayoutEntriesQueryVariables = Exact<{
  coachPayoutId: Scalars['ID']['input'];
}>;

export type CoachPayoutEntriesQuery = {
  __typename?: 'Query';
  coachPayoutConfig?: {
    __typename?: 'CoachPayoutConfigType';
    payoutChannel?: string | null;
    dollarPerCredit?: any | null;
  } | null;
  coachPayoutEntries?: Array<{
    __typename?: 'CoachPayoutEntryType';
    id: string;
    isPayoutEntry: boolean;
    createdAt: any;
    ledgerAccountEntry: {
      __typename?: 'LedgerAccountEntryType';
      id: string;
      amount: number;
      createdAt: any;
      journalEntry: {
        __typename?: 'JournalEntryType';
        id: string;
        memo: string;
        createdAt: any;
      };
    };
    coachPayout: {
      __typename?: 'CoachPayoutType';
      id: string;
      totalCredits: number;
      totalPayout: number;
      paidThrough: any;
      createdAt: any;
    };
  } | null> | null;
};

export type CoachPayoutsQueryVariables = Exact<{ [key: string]: never }>;

export type CoachPayoutsQuery = {
  __typename?: 'Query';
  coachPayouts?: Array<{
    __typename?: 'CoachPayoutType';
    id: string;
    totalPayout: number;
    totalCredits: number;
    createdAt: any;
    paidThrough: any;
  } | null> | null;
};

export type ReceivableCreditsQueryVariables = Exact<{ [key: string]: never }>;

export type ReceivableCreditsQuery = {
  __typename?: 'Query';
  userCredit?: {
    __typename?: 'UserCreditType';
    id?: string | null;
    receivableCredits?: any | null;
  } | null;
};

export type ArchiveProgramMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type ArchiveProgramMutation = {
  __typename?: 'Mutation';
  archiveProgram?: {
    __typename?: 'ArchiveProgram';
    ok?: boolean | null;
    msg?: string | null;
  } | null;
};

export type CloneProgramMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  targetLaunchDate: Scalars['DateTime']['input'];
}>;

export type CloneProgramMutation = {
  __typename?: 'Mutation';
  cloneProgram?: {
    __typename?: 'CloneProgram';
    ok?: boolean | null;
    error?: string | null;
    program?: { __typename?: 'ProgramType'; name: string; id: string } | null;
  } | null;
};

export type CloseProgramComponentMutationVariables = Exact<{
  programComponentId: Scalars['ID']['input'];
  programId: Scalars['ID']['input'];
  isReleaseDependentComponents?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CloseProgramComponentMutation = {
  __typename?: 'Mutation';
  closeProgramComponent?: {
    __typename?: 'CloseProgramComponent';
    ok?: boolean | null;
    msg?: string | null;
  } | null;
};

export type CoachesForComponentQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
  specialtyIds?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  productIds?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
}>;

export type CoachesForComponentQuery = {
  __typename?: 'Query';
  coaches?: Array<{
    __typename?: 'MiniCoachType';
    id: string;
    name?: string | null;
  } | null> | null;
};

export type CompleteAddToCalendarMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type CompleteAddToCalendarMutation = {
  __typename?: 'Mutation';
  completeAddToCalendar?: {
    __typename?: 'CompleteAddToCalendar';
    ok?: boolean | null;
  } | null;
};

export type CompleteProgramMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type CompleteProgramMutation = {
  __typename?: 'Mutation';
  completeProgram?: {
    __typename?: 'CompleteProgram';
    ok?: boolean | null;
    msg?: string | null;
  } | null;
};

export type CompleteSetupProgramComponentsMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type CompleteSetupProgramComponentsMutation = {
  __typename?: 'Mutation';
  completeSetupProgramComponents?: {
    __typename?: 'CompleteSetupProgramComponents';
    ok?: boolean | null;
  } | null;
};

export type CreateProgramMutationVariables = Exact<{
  targetLaunchDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
}>;

export type CreateProgramMutation = {
  __typename?: 'Mutation';
  createProgram?: {
    __typename?: 'CreateProgram';
    ok?: boolean | null;
    program?: {
      __typename?: 'ProgramMiniType';
      id: string;
      name: string;
      status?: ProgramStatus | null;
      targetLaunchDate?: any | null;
      participantCount?: number | null;
    } | null;
  } | null;
};

export type CreateProgramUsersMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
  role?: InputMaybe<ProgramUserRole>;
  emails:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  credit: Scalars['Decimal']['input'];
}>;

export type CreateProgramUsersMutation = {
  __typename?: 'Mutation';
  createProgramUsers?: {
    __typename?: 'CreateProgramUsers';
    ok?: boolean | null;
    msg?: string | null;
    programUsers?: Array<{
      __typename?: 'ProgramUserType';
      id: string;
      roles?: Array<ProgramUserRole | null> | null;
      user?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IsLastActiveComponentQueryQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
  componentId: Scalars['ID']['input'];
}>;

export type IsLastActiveComponentQueryQuery = {
  __typename?: 'Query';
  isLastActiveComponent?: boolean | null;
};

export type LaunchProgramMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type LaunchProgramMutation = {
  __typename?: 'Mutation';
  launchProgram?: { __typename?: 'LaunchProgram'; ok?: boolean | null } | null;
};

export type ProductsForConfigQueryVariables = Exact<{
  specialtyId: Scalars['ID']['input'];
  categories?: InputMaybe<
    Array<InputMaybe<ProductCategories>> | InputMaybe<ProductCategories>
  >;
}>;

export type ProductsForConfigQuery = {
  __typename?: 'Query';
  productsForConfig?: Array<{
    __typename?: 'ProductType';
    id: string;
    name: string;
  } | null> | null;
};

export type ProgramComponentForStatisticQueryQueryVariables = Exact<{
  programComponentId: Scalars['ID']['input'];
}>;

export type ProgramComponentForStatisticQueryQuery = {
  __typename?: 'Query';
  programComponentForStatistic?: {
    __typename?: 'ProgramComponentType';
    id: string;
    completedCount?: number | null;
    totalParticipants?: number | null;
    isSetupComplete: boolean;
    startedCount?: number | null;
    name?: string | null;
    type?: ComponentTypeEnum | null;
    participantGuidance?: string | null;
    targetStartDate?: any | null;
    targetDueDate?: any | null;
    targetEndDate?: any | null;
    closedAt?: any | null;
    programId?: string | null;
    survey?: {
      __typename?: 'ProgramSurveyType';
      id: string;
      formFields?: Array<{
        __typename?: 'FormFieldType';
        id: string;
        link?: {
          __typename?: 'LinkFieldType';
          url?: string | null;
          text?: string | null;
          imageSrc?: string | null;
        } | null;
      } | null> | null;
    } | null;
    prerequisiteComponent?: {
      __typename?: 'ProgramComponentType';
      id: string;
      name?: string | null;
      targetStartDate?: any | null;
      targetDueDate?: any | null;
      targetEndDate?: any | null;
    } | null;
  } | null;
};

export type ProgramComponentParticipantsQueryQueryVariables = Exact<{
  programComponentId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  orderField?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProgramComponentParticipantsQueryQuery = {
  __typename?: 'Query';
  programComponentParticipants?: {
    __typename?: 'ComponentParticipantPaginationType';
    totalPages?: number | null;
    totalParticipants?: number | null;
    size?: number | null;
    currentPage?: number | null;
    participants?: Array<{
      __typename?: 'ComponentParticipantType';
      id: string;
      completedAt?: any | null;
      programComponentResponseId?: string | null;
      deleted?: boolean | null;
      lastReminderAt?: any | null;
      roles?: Array<ProgramUserRole | null> | null;
      user?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ProgramComponentQueryQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
  programComponentId: Scalars['ID']['input'];
}>;

export type ProgramComponentQueryQuery = {
  __typename?: 'Query';
  programComponent?: {
    __typename?: 'ProgramComponentType';
    id: string;
    name?: string | null;
    type?: ComponentTypeEnum | null;
    body?: string | null;
    targetStartDate?: any | null;
    targetDueDate?: any | null;
    targetEndDate?: any | null;
    closedAt?: any | null;
    isServicedByExec: boolean;
    sessionNum: number;
    frequency?: ProgramComponentFrequencyType | null;
    requiredCompleteSurvey: boolean;
    isSetupComplete: boolean;
    visibleUpcomingQuestions: boolean;
    participantGuidance?: string | null;
    coachGuidance?: string | null;
    isCurateCoaches: boolean;
    participantPrelaunchVisibility?: boolean | null;
    requiredSendLaunchEmail: boolean;
    requiredSendDueEmail: boolean;
    requiredSendPastDueEmail: boolean;
    programId?: string | null;
    schedulerByProgramLeads: boolean;
    pendingParticipantEmails?: Array<string> | null;
    forAllParticipants: boolean;
    schedulerUsers?: Array<{
      __typename?: 'ProgramUserType';
      id: string;
      user?: { __typename?: 'MiniUserType'; name?: string | null } | null;
    } | null> | null;
    participants?: Array<{
      __typename?: 'ProgramUserType';
      id: string;
      user?: { __typename?: 'MiniUserType'; name?: string | null } | null;
    } | null> | null;
    coaches?: Array<{
      __typename?: 'MiniCoachType';
      id: string;
      name?: string | null;
    } | null> | null;
    products?: Array<{
      __typename?: 'ProductType';
      id: string;
      name: string;
    } | null> | null;
    specialty?: {
      __typename?: 'SpecialtyType';
      id: string;
      name: string;
    } | null;
    survey?: {
      __typename?: 'ProgramSurveyType';
      id: string;
      formFields?: Array<{
        __typename?: 'FormFieldType';
        id: string;
        answerType: FormFieldAnswerType;
        prompt: string;
        helpText?: string | null;
        required: boolean;
        order: number;
        cloneByTemplateId?: string | null;
        cloneByFormFieldId?: string | null;
        formFieldOptions?: Array<{
          __typename?: 'FormFieldOptionType';
          id: string;
          value: string;
          label: string;
        } | null> | null;
        link?: {
          __typename?: 'LinkFieldType';
          url?: string | null;
          text?: string | null;
          imageSrc?: string | null;
        } | null;
      } | null> | null;
    } | null;
    roleplayScenarios?: Array<{
      __typename?: 'ProgramRoleplayScenarioType';
      id: string;
      visibilityScope: ScenarioVisibilityScope;
      name: string;
      personaName?: string | null;
      personaPhotoUrl?: string | null;
      shortDesc?: string | null;
      difficulty: ScenarioDifficulty;
      slug: string;
    } | null> | null;
    roleplayScenariosRank?: Array<{
      __typename?: 'ProgramRoleplayScenarioRankType';
      scenarioId?: string | null;
      rank?: RoleplayRank | null;
    } | null> | null;
    prerequisiteComponent?: {
      __typename?: 'ProgramComponentType';
      id: string;
      name?: string | null;
      targetStartDate?: any | null;
      targetDueDate?: any | null;
      targetEndDate?: any | null;
    } | null;
    dependentComponents?: Array<{
      __typename?: 'ProgramComponentType';
      id: string;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type ProgramComponentResponseQueryQueryVariables = Exact<{
  responseId: Scalars['ID']['input'];
}>;

export type ProgramComponentResponseQueryQuery = {
  __typename?: 'Query';
  programComponentResponse?: {
    __typename?: 'ProgramComponentResponseType';
    id: string;
    completedAt?: any | null;
    user?: {
      __typename?: 'MiniUserType';
      id: string;
      name?: string | null;
      email: string;
      photo?: string | null;
    } | null;
    programComponent: {
      __typename?: 'ProgramComponentType';
      id: string;
      name?: string | null;
      isSetupComplete: boolean;
      type?: ComponentTypeEnum | null;
      targetEndDate?: any | null;
      targetStartDate?: any | null;
      closedAt?: any | null;
    };
    programSurvey?: {
      __typename?: 'ProgramSurveyType';
      id: string;
      formFields?: Array<{
        __typename?: 'FormFieldType';
        id: string;
        answerType: FormFieldAnswerType;
        prompt: string;
        helpText?: string | null;
        required: boolean;
        order: number;
        formFieldOptions?: Array<{
          __typename?: 'FormFieldOptionType';
          id: string;
          value: string;
          label: string;
        } | null> | null;
      } | null> | null;
    } | null;
    formFieldAnswers: Array<{
      __typename?: 'FormFieldAnswerAnalyticsType';
      id: string;
      value?: string | null;
      formField?: {
        __typename?: 'FormFieldType';
        id: string;
        answerType: FormFieldAnswerType;
        prompt: string;
        helpText?: string | null;
      } | null;
      optionValue?: {
        __typename?: 'FormFieldOptionAnalyticsType';
        id: string;
        value: string;
        label: string;
      } | null;
      userFile?: {
        __typename?: 'UserFileType';
        id: string;
        name?: string | null;
        url?: string | null;
      } | null;
    }>;
    roleplayScenarios?: Array<{
      __typename?: 'ProgramRoleplayScenarioType';
      id: string;
      name: string;
      shortDesc?: string | null;
      personaPhotoUrl?: string | null;
      difficulty: ScenarioDifficulty;
      slug: string;
      archivedAt?: any | null;
      roleplaySessions?: Array<{
        __typename?: 'ProgramRoleplaySessionType';
        id: string;
        score?: number | null;
        rank?: RoleplaySessionRank | null;
        createdAt: any;
        archivedAt?: any | null;
        uuid: string;
        formFieldCount?: number | null;
        correctAnswerCount?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ProgramComponentsQueryQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type ProgramComponentsQueryQuery = {
  __typename?: 'Query';
  programComponents?: {
    __typename?: 'ProgramComponentsQueryResult';
    leadsComponents?: Array<{
      __typename?: 'ProgramComponentType';
      id: string;
      name?: string | null;
      type?: ComponentTypeEnum | null;
      body?: string | null;
      requiredSendLaunchEmail: boolean;
      participantPrelaunchVisibility?: boolean | null;
      isSetupComplete: boolean;
      visibleUpcomingQuestions: boolean;
      participantGuidance?: string | null;
      targetStartDate?: any | null;
      targetDueDate?: any | null;
      targetEndDate?: any | null;
      closedAt?: any | null;
      completedCount?: number | null;
      totalParticipants?: number | null;
      forAllParticipants: boolean;
      startedCount?: number | null;
      programId?: string | null;
      isGroupMeetingSetupDone?: boolean | null;
      survey?: {
        __typename?: 'ProgramSurveyType';
        id: string;
        formFields?: Array<{
          __typename?: 'FormFieldType';
          id: string;
          prompt: string;
          answerType: FormFieldAnswerType;
          order: number;
          link?: {
            __typename?: 'LinkFieldType';
            url?: string | null;
            text?: string | null;
            imageSrc?: string | null;
          } | null;
        } | null> | null;
      } | null;
      roleplayScenarios?: Array<{
        __typename?: 'ProgramRoleplayScenarioType';
        id: string;
        visibilityScope: ScenarioVisibilityScope;
        name: string;
        personaName?: string | null;
        personaPhotoUrl?: string | null;
        shortDesc?: string | null;
        difficulty: ScenarioDifficulty;
        slug: string;
        archivedAt?: any | null;
      } | null> | null;
      roleplayScenariosRank?: Array<{
        __typename?: 'ProgramRoleplayScenarioRankType';
        scenarioId?: string | null;
        rank?: RoleplayRank | null;
      } | null> | null;
      roleplaySessions?: Array<{
        __typename?: 'ProgramRoleplaySessionType';
        id: string;
        scenario: { __typename?: 'ScenarioType'; id: string; name: string };
        user?: {
          __typename?: 'MiniUserType';
          id: string;
          name?: string | null;
        } | null;
      } | null> | null;
      prerequisiteComponent?: {
        __typename?: 'ProgramComponentType';
        id: string;
        name?: string | null;
        targetStartDate?: any | null;
        targetDueDate?: any | null;
        targetEndDate?: any | null;
      } | null;
      dependentComponents?: Array<{
        __typename?: 'ProgramComponentType';
        id: string;
        name?: string | null;
      } | null> | null;
      program?: {
        __typename?: 'ProgramMiniType';
        id: string;
        closedAt?: any | null;
      } | null;
    } | null> | null;
    participantsComponents?: Array<{
      __typename?: 'ProgramComponentType';
      id: string;
      name?: string | null;
      type?: ComponentTypeEnum | null;
      body?: string | null;
      isSetupComplete: boolean;
      visibleUpcomingQuestions: boolean;
      participantGuidance?: string | null;
      targetStartDate?: any | null;
      targetDueDate?: any | null;
      targetEndDate?: any | null;
      closedAt?: any | null;
      programId?: string | null;
      isParticipantRoleplay?: boolean | null;
      participantSubmitAt?: any | null;
      survey?: {
        __typename?: 'ProgramSurveyType';
        id: string;
        isParticipantSurvey?: boolean | null;
        participantSubmitAt?: any | null;
        formFields?: Array<{
          __typename?: 'FormFieldType';
          id: string;
          prompt: string;
          answerType: FormFieldAnswerType;
          order: number;
          link?: {
            __typename?: 'LinkFieldType';
            url?: string | null;
            text?: string | null;
            imageSrc?: string | null;
          } | null;
        } | null> | null;
        formFieldAnswers?: Array<{
          __typename?: 'AnswerType';
          id: string;
          value?: string | null;
          formField?: {
            __typename?: 'FieldType';
            id: string;
            answerType: FormFieldAnswerType;
            prompt: string;
            helpText?: string | null;
          } | null;
          optionValue?: {
            __typename?: 'FormFieldOptionAnalyticsType';
            id: string;
            value: string;
            label: string;
          } | null;
          userFile?: {
            __typename?: 'UserFileType';
            id: string;
            name?: string | null;
            url?: string | null;
          } | null;
        } | null> | null;
      } | null;
      roleplayScenarios?: Array<{
        __typename?: 'ProgramRoleplayScenarioType';
        id: string;
        visibilityScope: ScenarioVisibilityScope;
        name: string;
        personaName?: string | null;
        personaPhotoUrl?: string | null;
        shortDesc?: string | null;
        difficulty: ScenarioDifficulty;
        slug: string;
        archivedAt?: any | null;
      } | null> | null;
      roleplayScenariosRank?: Array<{
        __typename?: 'ProgramRoleplayScenarioRankType';
        scenarioId?: string | null;
        rank?: RoleplayRank | null;
      } | null> | null;
      roleplaySessions?: Array<{
        __typename?: 'ProgramRoleplaySessionType';
        id: string;
        scenario: { __typename?: 'ScenarioType'; id: string; name: string };
        user?: {
          __typename?: 'MiniUserType';
          id: string;
          name?: string | null;
        } | null;
      } | null> | null;
      prerequisiteComponent?: {
        __typename?: 'ProgramComponentType';
        id: string;
        name?: string | null;
        participantSubmitAt?: any | null;
        targetStartDate?: any | null;
        targetDueDate?: any | null;
        targetEndDate?: any | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ProgramQueryQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type ProgramQueryQuery = {
  __typename?: 'Query';
  program?: {
    __typename?: 'ProgramType';
    id: string;
    name: string;
    status?: ProgramStatus | null;
    targetLaunchDate?: any | null;
    targetEndDate?: any | null;
    closedAt?: any | null;
    hasParticipants?: boolean | null;
    scheduleLaunchEmail: boolean;
    sendEnrollmentEmailAt?: any | null;
    hasCompletedSetupComponents?: boolean | null;
    hasComponents?: boolean | null;
    closedBy?: {
      __typename?: 'MiniUserType';
      id: string;
      name?: string | null;
      email: string;
      photo?: string | null;
    } | null;
    programOwners?: Array<{
      __typename?: 'ProgramUserType';
      id: string;
      summary?: string | null;
      roles?: Array<ProgramUserRole | null> | null;
      user?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
        jobTitle?: string | null;
      } | null;
    } | null> | null;
    currentProgramUser?: {
      __typename?: 'ProgramUserType';
      id: string;
      updatedAt: any;
      roles?: Array<ProgramUserRole | null> | null;
      user?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ProgramRoleplayScenariosAnalyticsQueryQueryVariables = Exact<{
  programComponentId: Scalars['ID']['input'];
}>;

export type ProgramRoleplayScenariosAnalyticsQueryQuery = {
  __typename?: 'Query';
  programRoleplayScenariosAnalytics?: Array<{
    __typename?: 'ProgramRoleplayScenarioType';
    id: string;
    name: string;
    personaName?: string | null;
    personaPhoto?: string | null;
    personaPhotoUrl?: string | null;
    slug: string;
    shortDesc?: string | null;
    totalParticipants?: number | null;
    difficulty: ScenarioDifficulty;
    roleplaySessions?: Array<{
      __typename?: 'ProgramRoleplaySessionType';
      id: string;
      userId?: string | null;
      scenarioId?: string | null;
      rank?: RoleplaySessionRank | null;
      score?: number | null;
    } | null> | null;
  } | null> | null;
};

export type ProgramRoleplayScenarioParticipantsQueryQueryVariables = Exact<{
  programComponentId: Scalars['ID']['input'];
  scenarioId: Scalars['ID']['input'];
}>;

export type ProgramRoleplayScenarioParticipantsQueryQuery = {
  __typename?: 'Query';
  programRoleplayScenarioParticipants?: Array<{
    __typename?: 'RoleplaySessionParticipantType';
    id?: string | null;
    name?: string | null;
    email?: string | null;
    photo?: string | null;
    programComponentResponseId?: string | null;
    roleplaySessions?: Array<{
      __typename?: 'ProgramRoleplaySessionType';
      id: string;
      rank?: RoleplaySessionRank | null;
      score?: number | null;
    } | null> | null;
  } | null> | null;
};

export type ProgramStatusFilterQueryVariables = Exact<{ [key: string]: never }>;

export type ProgramStatusFilterQuery = {
  __typename?: 'Query';
  programStatusFilter?: Array<ProgramStatus | null> | null;
};

export type ProgramSurveyQueryQueryVariables = Exact<{
  programSurveyId: Scalars['ID']['input'];
}>;

export type ProgramSurveyQueryQuery = {
  __typename?: 'Query';
  programSurvey?: {
    __typename?: 'ProgramSurveyType';
    id: string;
    formFields?: Array<{
      __typename?: 'FormFieldType';
      id: string;
      answerType: FormFieldAnswerType;
      prompt: string;
      helpText?: string | null;
      required: boolean;
      order: number;
      cloneByTemplateId?: string | null;
      cloneByFormFieldId?: string | null;
      formFieldOptions?: Array<{
        __typename?: 'FormFieldOptionType';
        id: string;
        value: string;
        label: string;
      } | null> | null;
      link?: {
        __typename?: 'LinkFieldType';
        url?: string | null;
        text?: string | null;
      } | null;
    } | null> | null;
    formFieldAnswers?: Array<{
      __typename?: 'AnswerType';
      id: string;
      value?: string | null;
      formField?: { __typename?: 'FieldType'; id: string } | null;
      userFile?: {
        __typename?: 'UserFileType';
        id: string;
        name?: string | null;
        url?: string | null;
      } | null;
    } | null> | null;
    programComponent?: {
      __typename?: 'ProgramComponentType';
      id: string;
      name?: string | null;
      type?: ComponentTypeEnum | null;
      targetStartDate?: any | null;
      targetDueDate?: any | null;
      targetEndDate?: any | null;
      closedAt?: any | null;
      participantGuidance?: string | null;
      program?: {
        __typename?: 'ProgramMiniType';
        id: string;
        status?: ProgramStatus | null;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type ProgramSurveyQuestionParticipantsQueryQueryVariables = Exact<{
  programSurveyId: Scalars['ID']['input'];
  formFieldId: Scalars['ID']['input'];
}>;

export type ProgramSurveyQuestionParticipantsQueryQuery = {
  __typename?: 'Query';
  programSurveyQuestionParticipants?: Array<{
    __typename?: 'AnswerSurveyParticipantType';
    id?: string | null;
    name?: string | null;
    email?: string | null;
    photo?: string | null;
    completedAt?: any | null;
  } | null> | null;
};

export type ProgramSurveyQuestionsAnalyticsQueryQueryVariables = Exact<{
  programSurveyId: Scalars['ID']['input'];
}>;

export type ProgramSurveyQuestionsAnalyticsQueryQuery = {
  __typename?: 'Query';
  programSurveyQuestionsAnalytics?: Array<{
    __typename?: 'FormFieldAnalyticsType';
    id: string;
    answerType: FormFieldAnswerType;
    prompt: string;
    order: number;
    totalResponses?: number | null;
    totalParticipants?: number | null;
    favorable?: number | null;
    average?: number | null;
    link?: {
      __typename?: 'LinkFieldType';
      url?: string | null;
      text?: string | null;
    } | null;
    options?: Array<{
      __typename?: 'FormFieldOptionAnalyticsType';
      id: string;
      value: string;
      label: string;
      totalResponses?: number | null;
      valueProportion?: number | null;
    } | null> | null;
    answers?: Array<{
      __typename?: 'FormFieldAnswerAnalyticsType';
      id: string;
      value?: string | null;
      updatedAt: any;
      user?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
      } | null;
      userFile?: {
        __typename?: 'UserFileType';
        id: string;
        name?: string | null;
        url?: string | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type ProgramUsersQueryQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  orderField?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<
    Array<InputMaybe<ProgramUserRole>> | InputMaybe<ProgramUserRole>
  >;
  query?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProgramUsersQueryQuery = {
  __typename?: 'Query';
  programUsers?: {
    __typename?: 'ProgramUserQueryResult';
    total?: number | null;
    page?: number | null;
    size?: number | null;
    data?: Array<{
      __typename?: 'ProgramUserType';
      id: string;
      roles?: Array<ProgramUserRole | null> | null;
      isWorkspaceMemberActive?: boolean | null;
      user?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ProgramsQueryQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  orderField?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<
    Array<InputMaybe<ProgramStatus>> | InputMaybe<ProgramStatus>
  >;
  query?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProgramsQueryQuery = {
  __typename?: 'Query';
  programs?: {
    __typename?: 'ProgramQueryResult';
    total?: number | null;
    page?: number | null;
    size?: number | null;
    data?: Array<{
      __typename?: 'ProgramMiniType';
      id: string;
      name: string;
      status?: ProgramStatus | null;
      targetLaunchDate?: any | null;
      targetEndDate?: any | null;
      closedAt?: any | null;
      participantCount?: number | null;
      isProgramLead?: boolean | null;
      isProgramParticipant?: boolean | null;
      totalParticipantOngoingSurveys?: number | null;
      closedBy?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PublicProgramQueryQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type PublicProgramQueryQuery = {
  __typename?: 'Query';
  publicProgram?: {
    __typename?: 'PublicProgramType';
    id: string;
    name: string;
  } | null;
};

export type RemoveProgramComponentMutationVariables = Exact<{
  programComponentId: Scalars['ID']['input'];
  programId: Scalars['ID']['input'];
  isReleaseDependentComponents?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type RemoveProgramComponentMutation = {
  __typename?: 'Mutation';
  removeProgramComponent?: {
    __typename?: 'RemoveProgramComponent';
    ok?: boolean | null;
    msg?: string | null;
  } | null;
};

export type RemoveProgramLeadMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
  programUserId: Scalars['ID']['input'];
}>;

export type RemoveProgramLeadMutation = {
  __typename?: 'Mutation';
  removeProgramLead?: {
    __typename?: 'RemoveProgramLead';
    ok?: boolean | null;
    msg?: string | null;
  } | null;
};

export type RemoveProgramParticipantMutationVariables = Exact<{
  programId: Scalars['ID']['input'];
  programUserId: Scalars['ID']['input'];
}>;

export type RemoveProgramParticipantMutation = {
  __typename?: 'Mutation';
  removeProgramParticipant?: {
    __typename?: 'RemoveProgramParticipant';
    ok?: boolean | null;
    msg?: string | null;
  } | null;
};

export type SaveProgramComponentMutationVariables = Exact<{
  input: ProgramComponentInput;
  programId: Scalars['ID']['input'];
}>;

export type SaveProgramComponentMutation = {
  __typename?: 'Mutation';
  saveProgramComponent?: {
    __typename?: 'SaveProgramComponent';
    ok?: boolean | null;
    msg?: string | null;
    programComponent?: {
      __typename?: 'ProgramComponentType';
      id: string;
      isServicedByExec: boolean;
    } | null;
  } | null;
};

export type SaveProgramSurveyAnswerMutationVariables = Exact<{
  surveyId: Scalars['ID']['input'];
  answerData?: InputMaybe<
    | Array<InputMaybe<SurveyFormFieldAnswersInput>>
    | InputMaybe<SurveyFormFieldAnswersInput>
  >;
}>;

export type SaveProgramSurveyAnswerMutation = {
  __typename?: 'Mutation';
  saveProgramSurveyAnswer?: {
    __typename?: 'SaveProgramSurveyAnswer';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type SelectableProgramComponentsQueryQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type SelectableProgramComponentsQueryQuery = {
  __typename?: 'Query';
  selectableProgramComponents?: Array<{
    __typename?: 'ProgramComponentType';
    id: string;
    name?: string | null;
    type?: ComponentTypeEnum | null;
  } | null> | null;
};

export type SendNudgeSurveyMutationVariables = Exact<{
  componentId: Scalars['ID']['input'];
  participantId: Scalars['ID']['input'];
}>;

export type SendNudgeSurveyMutation = {
  __typename?: 'Mutation';
  sendNudgeSurvey?: {
    __typename?: 'SendNudgeSurvey';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type SimpleProgramUsersQueryQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
  roles?: InputMaybe<
    Array<InputMaybe<ProgramUserRole>> | InputMaybe<ProgramUserRole>
  >;
}>;

export type SimpleProgramUsersQueryQuery = {
  __typename?: 'Query';
  pendingParticipantEmails?: Array<string | null> | null;
  simpleProgramUsers?: Array<{
    __typename?: 'SimpleProgramUserType';
    id?: string | null;
    name?: string | null;
    email?: string | null;
    roles?: Array<ProgramUserRole | null> | null;
  } | null> | null;
};

export type SurveyTemplatesQueryQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type SurveyTemplatesQueryQuery = {
  __typename?: 'Query';
  surveyTemplates?: Array<{
    __typename?: 'SurveyTemplateType';
    id: string;
    name: string;
    description?: string | null;
    participantGuidance?: string | null;
    formFields?: Array<{
      __typename?: 'FormFieldType';
      id: string;
      prompt: string;
      helpText?: string | null;
      answerType: FormFieldAnswerType;
      order: number;
      formFieldOptions?: Array<{
        __typename?: 'FormFieldOptionType';
        id: string;
        value: string;
        label: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type UpdateProgramMutationVariables = Exact<{
  targetLaunchDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  programId: Scalars['ID']['input'];
  scheduleLaunchEmail?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateProgramMutation = {
  __typename?: 'Mutation';
  updateProgram?: {
    __typename?: 'UpdateProgram';
    ok?: boolean | null;
    msg?: string | null;
  } | null;
};

export type CreateRatingMutationVariables = Exact<{
  score: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  privateComment?: InputMaybe<Scalars['String']['input']>;
  reviewId?: InputMaybe<Scalars['ID']['input']>;
  meetingId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateRatingMutation = {
  __typename?: 'Mutation';
  createRating?: {
    __typename?: 'CreateRating';
    rating?: { __typename?: 'RatingType'; id: string } | null;
  } | null;
};

export type DeleteRatingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteRatingMutation = {
  __typename?: 'Mutation';
  deleteRating?: { __typename?: 'DeleteRating'; ok?: boolean | null } | null;
};

export type GetRatingInfoQueryVariables = Exact<{
  meeting: Scalars['Boolean']['input'];
  review: Scalars['Boolean']['input'];
  meetingId?: InputMaybe<Scalars['ID']['input']>;
  reviewId?: InputMaybe<Scalars['ID']['input']>;
  ratingId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetRatingInfoQuery = {
  __typename?: 'Query';
  meeting?: {
    __typename?: 'MeetingType';
    id: string;
    eventAt?: any | null;
    canLeaveRating?: boolean | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    product?: { __typename?: 'ProductType'; id: string; name: string } | null;
  } | null;
  review?: {
    __typename?: 'ReviewType';
    id: string;
    completedAt?: any | null;
    canLeaveRating?: boolean | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    product?: { __typename?: 'ProductType'; id: string; name: string } | null;
  } | null;
  rating?: {
    __typename?: 'RatingType';
    id: string;
    score: number;
    comment?: string | null;
    privateComment?: string | null;
    updateAllowed?: boolean | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    meeting?: {
      __typename?: 'MeetingType';
      id: string;
      eventAt?: any | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      product?: { __typename?: 'ProductType'; id: string; name: string } | null;
    } | null;
    review?: {
      __typename?: 'ReviewType';
      id: string;
      completedAt?: any | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      product?: { __typename?: 'ProductType'; id: string; name: string } | null;
    } | null;
  } | null;
};

export type UpdateRatingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  score: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  privateComment?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateRatingMutation = {
  __typename?: 'Mutation';
  updateRating?: {
    __typename?: 'UpdateRating';
    rating?: { __typename?: 'RatingType'; id: string } | null;
  } | null;
};

export type MeetingDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type MeetingDetailQuery = {
  __typename?: 'Query';
  meeting?: {
    __typename?: 'MeetingType';
    id: string;
    duration?: number | null;
    eventAt?: any | null;
    refundableUntil?: any | null;
    canceledAt?: any | null;
    creditPrice?: any | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      creditPrice?: number | null;
    } | null;
    workspace?: {
      __typename?: 'WorkspaceType';
      id: string;
      name: string;
    } | null;
  } | null;
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    timeZone?: string | null;
  } | null;
};

export type RescheduleCoachMeetingMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['DateTime']['input'];
  forceRefund?: InputMaybe<Scalars['Boolean']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  usedAllTimeslot?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type RescheduleCoachMeetingMutation = {
  __typename?: 'Mutation';
  rescheduleCoachMeeting?: {
    __typename?: 'RescheduleCoachMeeting';
    status?: RescheduleStatus | null;
    meeting?: {
      __typename?: 'MeetingType';
      id: string;
      eventAt?: any | null;
    } | null;
  } | null;
};

export type BookReviewPageQueryVariables = Exact<{ [key: string]: never }>;

export type BookReviewPageQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    name?: string | null;
    availableCredits?: any | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      label?: string | null;
      balance: number;
    } | null> | null;
    coaches?: Array<{
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
      activeCoachProducts?: Array<{
        __typename?: 'CoachProductType';
        id?: number | null;
        product?: { __typename?: 'ProductType'; id: string } | null;
      } | null> | null;
      agentProfile?: {
        __typename?: 'AgentProfileType';
        id: string;
        summary?: string | null;
        averageRating?: number | null;
        acceptingNewBookings?: boolean | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ReviewsPageQueryVariables = Exact<{ [key: string]: never }>;

export type ReviewsPageQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    isAgent: boolean;
    availableCredits?: any | null;
    receivableCredits?: any | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      balance: number;
      label?: string | null;
    } | null> | null;
    reviews?: Array<{
      __typename?: 'ReviewType';
      id: string;
      chatChannelId?: string | null;
      status?: string | null;
      state?: string | null;
      nextDate?: string | null;
      numMessages?: number | null;
      acceptanceDeadline?: any | null;
      acceptedAt?: any | null;
      finalCheckRevisionSentAt?: any | null;
      canceledAt?: any | null;
      completedAt?: any | null;
      numRounds: number;
      numRoundsComplete: number;
      customerId?: number | null;
      canLeaveRating?: boolean | null;
      creditPrice?: any | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        turnaroundTime?: number | null;
        includesFinalCheck: boolean;
        description?: string | null;
        numRounds?: number | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type MyCollectionsQueryVariables = Exact<{ [key: string]: never }>;

export type MyCollectionsQuery = {
  __typename?: 'Query';
  myCollections?: Array<{
    __typename?: 'RoleplayType';
    id: string;
    name: string;
    slug: string;
    emojiUnicode: string;
    description?: string | null;
    introduction?: string | null;
    priority: number;
    visibilityScope?: VisibilityScope | null;
    canEdit?: boolean | null;
    scenarios?: Array<{
      __typename?: 'ScenarioType';
      id: string;
      name: string;
      slug: string;
      rank?: RoleplayRank | null;
    } | null> | null;
    workspace?: {
      __typename?: 'PublicWorkspaceGraphqlType';
      id: string;
      name: string;
    } | null;
  } | null> | null;
};

export type PaginatedRoleplaysQueryVariables = Exact<{
  currentPage: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  scope?: InputMaybe<VisibilityScope>;
  query?: InputMaybe<Scalars['String']['input']>;
  workspaceOnly?: InputMaybe<Scalars['Boolean']['input']>;
  includeOwnerData?: InputMaybe<Scalars['Boolean']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  excludedIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
}>;

export type PaginatedRoleplaysQuery = {
  __typename?: 'Query';
  paginatedRoleplays?: {
    __typename?: 'RoleplayPageType';
    currentPage?: number | null;
    pageSize?: number | null;
    totalRecords?: number | null;
    data?: Array<{
      __typename?: 'RoleplayType';
      id: string;
      name: string;
      slug: string;
      emojiUnicode: string;
      description?: string | null;
      introduction?: string | null;
      priority: number;
      visibilityScope?: VisibilityScope | null;
      canEdit?: boolean | null;
      scenarios?: Array<{
        __typename?: 'ScenarioType';
        id: string;
        name: string;
        slug: string;
        rank?: RoleplayRank | null;
      } | null> | null;
      workspace?: {
        __typename?: 'PublicWorkspaceGraphqlType';
        id: string;
        name: string;
      } | null;
    } | null> | null;
  } | null;
};

export type PublicRoleplaysQueryVariables = Exact<{ [key: string]: never }>;

export type PublicRoleplaysQuery = {
  __typename?: 'Query';
  publicRoleplays?: Array<{
    __typename?: 'RoleplayType';
    id: string;
    name: string;
    slug: string;
    emojiUnicode: string;
    description?: string | null;
    priority: number;
    visibilityScope?: VisibilityScope | null;
  } | null> | null;
};

export type RoleplayFieldsFragment = {
  __typename?: 'RoleplayType';
  id: string;
  name: string;
  slug: string;
  description?: string | null;
  introduction?: string | null;
  emojiUnicode: string;
  visibilityScope?: VisibilityScope | null;
  priority: number;
  canEdit?: boolean | null;
  owner?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    email: string;
    photo?: string | null;
  } | null;
  workspace?: {
    __typename?: 'PublicWorkspaceGraphqlType';
    id: string;
    name: string;
  } | null;
};

export type ScenarioFieldsFragment = {
  __typename?: 'ScenarioType';
  id: string;
  name: string;
  shortDesc?: string | null;
  difficulty: ScenarioDifficulty;
  slug: string;
  context?: string | null;
  objective?: string | null;
  personaName?: string | null;
  personaFirstName?: string | null;
  personaLastName?: string | null;
  personaPhotoUrl?: string | null;
  assistantId?: string | null;
  voiceId?: string | null;
  visibilityScope?: VisibilityScope | null;
  visibleCriteriaBanner?: boolean | null;
  personalityCharacteristics?: string | null;
  canEdit?: boolean | null;
  introductionAudioUrl?: string | null;
  owner?: {
    __typename?: 'MiniUserType';
    id: string;
    name?: string | null;
    email: string;
    photo?: string | null;
  } | null;
  personalityTraits: Array<{
    __typename?: 'ScenarioPersonalityTraitType';
    attributeId?: string | null;
    levelId?: string | null;
  }>;
  workspace?: {
    __typename?: 'PublicWorkspaceGraphqlType';
    id: string;
    name: string;
  } | null;
};

export type EvaluationCriterionFieldsFragment = {
  __typename?: 'EvaluationCriterionType';
  id: string;
  name: string;
  order: number;
  items?: Array<{
    __typename?: 'EvaluationCriterionItemType';
    id: string;
    name: string;
    order: number;
    description?: string | null;
  } | null> | null;
};

export type ScenarioListItemFragment = {
  __typename?: 'ScenarioType';
  id: string;
  name: string;
  shortDesc?: string | null;
  slug: string;
  difficulty: ScenarioDifficulty;
  status?: string | null;
  rank?: RoleplayRank | null;
  personaName?: string | null;
  personaPhotoUrl?: string | null;
  visibilityScope?: VisibilityScope | null;
};

export type RoleplaySessionListItemFragment = {
  __typename?: 'RoleplaySessionType';
  id: string;
  uuid: string;
  rank?: RoleplayRank | null;
  updatedAt: any;
  scenario: { __typename?: 'ScenarioType'; slug: string };
};

export type RoleplaySessionFieldsFragment = {
  __typename?: 'RoleplaySessionType';
  id: string;
  uuid: string;
  score?: number | null;
  rank?: RoleplayRank | null;
  callId?: string | null;
  processingStatus?: RoleplaySessionProcessingStatus | null;
  file: string;
  duration?: number | null;
  outcomeFeedback?: string | null;
  positiveFeedback?: string | null;
  constructiveFeedback?: string | null;
  transcript?: string | null;
  cleanMessages?: Array<any | null> | null;
  createdAt: any;
  updatedAt: any;
  isValidAttempt: boolean;
  isValidAttemptExplanation?: string | null;
  recordingUrl?: string | null;
  evaluations?: Array<{
    __typename?: 'RoleplaySessionCriterionType';
    id: string;
    grade?: RoleplayEvalGrade | null;
    feedback?: string | null;
    summary?: any | null;
    feedbackExamples?: Array<{
      __typename?: 'EvaluationCriterionFeedbackExampleType';
      id: string;
      feedback?: string | null;
      salience?: RoleplaySessionEvaluationInstanceSalienceLevel | null;
      grade?: RoleplayEvalGrade | null;
      quote: string;
      suggestionQuote?: string | null;
    } | null> | null;
    evaluationCriterionItem?: {
      __typename?: 'EvaluationCriterionItemType';
      id: string;
      name: string;
      evaluationCriterion: {
        __typename?: 'EvaluationCriterionType';
        id: string;
        name: string;
        order: number;
      };
    } | null;
  } | null> | null;
};

export type ScenariosQueryVariables = Exact<{
  roleplayId: Scalars['ID']['input'];
  workspaceOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ScenariosQuery = {
  __typename?: 'Query';
  roleplayScenarios?: Array<{
    __typename?: 'ScenarioType';
    id: string;
    name: string;
    shortDesc?: string | null;
    difficulty: ScenarioDifficulty;
    slug: string;
    context?: string | null;
    objective?: string | null;
    personaName?: string | null;
    personaFirstName?: string | null;
    personaLastName?: string | null;
    personaPhotoUrl?: string | null;
    assistantId?: string | null;
    voiceId?: string | null;
    visibilityScope?: VisibilityScope | null;
    visibleCriteriaBanner?: boolean | null;
    personalityCharacteristics?: string | null;
    canEdit?: boolean | null;
    introductionAudioUrl?: string | null;
    owner?: {
      __typename?: 'MiniUserType';
      id: string;
      name?: string | null;
      email: string;
      photo?: string | null;
    } | null;
    personalityTraits: Array<{
      __typename?: 'ScenarioPersonalityTraitType';
      attributeId?: string | null;
      levelId?: string | null;
    }>;
    workspace?: {
      __typename?: 'PublicWorkspaceGraphqlType';
      id: string;
      name: string;
    } | null;
  } | null> | null;
};

export type AddCollectionScenarioMutationVariables = Exact<{
  roleplaySlug: Scalars['String']['input'];
  scenarioIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>;
}>;

export type AddCollectionScenarioMutation = {
  __typename?: 'Mutation';
  addCollectionScenario?: {
    __typename?: 'AddCollectionScenario';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type EditScenarioMutationVariables = Exact<{
  slug: Scalars['String']['input'];
  name: Scalars['String']['input'];
  context: Scalars['String']['input'];
  objective: Scalars['String']['input'];
}>;

export type EditScenarioMutation = {
  __typename?: 'Mutation';
  editScenario?: {
    __typename?: 'EditScenario';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type InviteUsersToSessionMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
  isNotified?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
}>;

export type InviteUsersToSessionMutation = {
  __typename?: 'Mutation';
  inviteUsersToSession?: {
    __typename?: 'InviteUsersToSession';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type PersonalityAttributesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PersonalityAttributesQuery = {
  __typename?: 'Query';
  personalityAttributes?: Array<{
    __typename?: 'PersonalityAttributeType';
    id: string;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    levels?: Array<{
      __typename?: 'PersonalityAttributeLevelType';
      id: string;
      name: string;
      description: string;
      level: number;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  } | null> | null;
};

export type RemoveCollectionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveCollectionMutation = {
  __typename?: 'Mutation';
  removeCollection?: {
    __typename?: 'RemoveCollection';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type RemoveCollectionScenarioMutationVariables = Exact<{
  roleplaySlug: Scalars['String']['input'];
  scenarioIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>;
}>;

export type RemoveCollectionScenarioMutation = {
  __typename?: 'Mutation';
  removeCollectionScenario?: {
    __typename?: 'RemoveCollectionScenario';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type RevokeAccessFromSessionMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
  workspaceMemberId: Scalars['ID']['input'];
}>;

export type RevokeAccessFromSessionMutation = {
  __typename?: 'Mutation';
  revokeAccessFromSession?: {
    __typename?: 'RevokeAccessFromSession';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type RoleplayVoicesQueryVariables = Exact<{ [key: string]: never }>;

export type RoleplayVoicesQuery = {
  __typename?: 'Query';
  roleplayVoices?: Array<{
    __typename?: 'RoleplayVoiceType';
    id: string;
    voiceId: string;
    gender?: RoleplayVoiceGender | null;
    provider: string;
    sampleUrl?: string | null;
    name: string;
  } | null> | null;
};

export type SaveCollectionMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  introduction?: InputMaybe<Scalars['String']['input']>;
  emojiUnicode?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type SaveCollectionMutation = {
  __typename?: 'Mutation';
  saveCollection?: {
    __typename?: 'SaveCollection';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    collection?: {
      __typename?: 'RoleplayType';
      id: string;
      slug: string;
    } | null;
  } | null;
};

export type ScenariosQueryQueryVariables = Exact<{
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isAssignee?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<QueryScenariosStatus>;
  scope?: InputMaybe<VisibilityScope>;
  query?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  workspaceOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isOrphaned?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<QueryScenariosOrderBy>;
}>;

export type ScenariosQueryQuery = {
  __typename?: 'Query';
  scenarios?: {
    __typename?: 'ScenariosResult';
    page?: number | null;
    pageSize?: number | null;
    totalRecords?: number | null;
    totalPages?: number | null;
    data?: Array<{
      __typename?: 'ScenarioType';
      id: string;
      rank?: RoleplayRank | null;
      name: string;
      shortDesc?: string | null;
      difficulty: ScenarioDifficulty;
      slug: string;
      context?: string | null;
      objective?: string | null;
      personaName?: string | null;
      personaFirstName?: string | null;
      personaLastName?: string | null;
      personaPhotoUrl?: string | null;
      assistantId?: string | null;
      voiceId?: string | null;
      visibilityScope?: VisibilityScope | null;
      visibleCriteriaBanner?: boolean | null;
      personalityCharacteristics?: string | null;
      canEdit?: boolean | null;
      introductionAudioUrl?: string | null;
      owner?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
      } | null;
      personalityTraits: Array<{
        __typename?: 'ScenarioPersonalityTraitType';
        attributeId?: string | null;
        levelId?: string | null;
      }>;
      workspace?: {
        __typename?: 'PublicWorkspaceGraphqlType';
        id: string;
        name: string;
      } | null;
    } | null> | null;
  } | null;
};

export type SwitchPersonalityModeMutationVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type SwitchPersonalityModeMutation = {
  __typename?: 'Mutation';
  switchPersonalityMode?: {
    __typename?: 'SwitchPersonalityMode';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateCollectionVisibilityScopeMutationVariables = Exact<{
  collectionId: Scalars['ID']['input'];
  visibilityScope: VisibilityScope;
}>;

export type UpdateCollectionVisibilityScopeMutation = {
  __typename?: 'Mutation';
  updateCollectionVisibilityScope?: {
    __typename?: 'UpdateCollectionVisibilityScope';
    ok?: boolean | null;
    msg?: string | null;
    collection?: {
      __typename?: 'RoleplayType';
      id: string;
      visibilityScope?: VisibilityScope | null;
    } | null;
  } | null;
};

export type UpdateScenarioCharacterMutationVariables = Exact<{
  slug: Scalars['String']['input'];
  personaName?: InputMaybe<Scalars['String']['input']>;
  voiceId?: InputMaybe<Scalars['ID']['input']>;
  personalityCharacteristics?: InputMaybe<Scalars['String']['input']>;
  personalityTraits?: InputMaybe<
    Array<InputMaybe<PersonalityTraitInput>> | InputMaybe<PersonalityTraitInput>
  >;
}>;

export type UpdateScenarioCharacterMutation = {
  __typename?: 'Mutation';
  updateScenarioCharacter?: {
    __typename?: 'UpdateScenarioCharacter';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type RoleplayQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type RoleplayQuery = {
  __typename?: 'Query';
  roleplay?: {
    __typename?: 'RoleplayType';
    id: string;
    name: string;
    slug: string;
    description?: string | null;
    introduction?: string | null;
    emojiUnicode: string;
    visibilityScope?: VisibilityScope | null;
    priority: number;
    canEdit?: boolean | null;
    evaluationCriteria?: Array<{
      __typename?: 'EvaluationCriterionType';
      id: string;
      name: string;
      order: number;
      items?: Array<{
        __typename?: 'EvaluationCriterionItemType';
        id: string;
        name: string;
        order: number;
        description?: string | null;
      } | null> | null;
    } | null> | null;
    scenarios?: Array<{
      __typename?: 'ScenarioType';
      id: string;
      name: string;
      shortDesc?: string | null;
      slug: string;
      difficulty: ScenarioDifficulty;
      status?: string | null;
      rank?: RoleplayRank | null;
      personaName?: string | null;
      personaPhotoUrl?: string | null;
      visibilityScope?: VisibilityScope | null;
    } | null> | null;
    owner?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      email: string;
      photo?: string | null;
    } | null;
    workspace?: {
      __typename?: 'PublicWorkspaceGraphqlType';
      id: string;
      name: string;
    } | null;
  } | null;
};

export type CopyEvaluationCriteriaToCollectionMutationVariables = Exact<{
  evaluationCriterionIds:
    | Array<Scalars['ID']['input']>
    | Scalars['ID']['input'];
  collectionId: Scalars['ID']['input'];
}>;

export type CopyEvaluationCriteriaToCollectionMutation = {
  __typename?: 'Mutation';
  copyEvaluationCriteriaToCollection?: {
    __typename?: 'CopyEvaluationCriteriaToCollection';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type CopyEvaluationCriteriaToScenarioMutationVariables = Exact<{
  evaluationCriterionIds:
    | Array<Scalars['ID']['input']>
    | Scalars['ID']['input'];
  scenarioId: Scalars['ID']['input'];
}>;

export type CopyEvaluationCriteriaToScenarioMutation = {
  __typename?: 'Mutation';
  copyEvaluationCriteriaToScenario?: {
    __typename?: 'CopyEvaluationCriteriaToScenario';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type CreateEvaluationCriterionMutationVariables = Exact<{
  name: Scalars['String']['input'];
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  scenarioId?: InputMaybe<Scalars['ID']['input']>;
  evaluationCriterionItems:
    | Array<InputMaybe<EvaluationCriterionItemInput>>
    | InputMaybe<EvaluationCriterionItemInput>;
}>;

export type CreateEvaluationCriterionMutation = {
  __typename?: 'Mutation';
  createEvaluationCriterion?: {
    __typename?: 'CreateEvaluationCriterion';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
    evaluationCriterion?: {
      __typename?: 'EvaluationCriterionType';
      id: string;
      name: string;
    } | null;
  } | null;
};

export type DeleteEvaluationCriterionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteEvaluationCriterionMutation = {
  __typename?: 'Mutation';
  deleteEvaluationCriterion?: {
    __typename?: 'DeleteEvaluationCriterion';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type DismissScenarioCriteriaBannerMutationVariables = Exact<{
  scenarioId: Scalars['ID']['input'];
}>;

export type DismissScenarioCriteriaBannerMutation = {
  __typename?: 'Mutation';
  dismissScenarioCriteriaBanner?: {
    __typename?: 'DismissScenarioCriteriaBanner';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type GetEvaluationCriteriaQueryVariables = Exact<{
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  roleplaySlug?: InputMaybe<Scalars['String']['input']>;
  scenarioId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetEvaluationCriteriaQuery = {
  __typename?: 'Query';
  evaluationCriteria?: Array<{
    __typename?: 'EvaluationCriterionType';
    id: string;
    name: string;
    order: number;
    items?: Array<{
      __typename?: 'EvaluationCriterionItemType';
      id: string;
      name: string;
      order: number;
      description?: string | null;
    } | null> | null;
  } | null> | null;
};

export type RemoveAllEvaluationCriteriaFromTargetMutationVariables = Exact<{
  targetId: Scalars['ID']['input'];
  targetType: TargetType;
}>;

export type RemoveAllEvaluationCriteriaFromTargetMutation = {
  __typename?: 'Mutation';
  removeAllEvaluationCriteriaFromTarget?: {
    __typename?: 'RemoveAllEvaluationCriteriaFromTarget';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type ReorderEvaluationCriteriaMutationVariables = Exact<{
  items: Array<ReorderItemInput> | ReorderItemInput;
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  scenarioId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ReorderEvaluationCriteriaMutation = {
  __typename?: 'Mutation';
  reorderEvaluationCriteria?: {
    __typename?: 'ReorderEvaluationCriteria';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
    evaluationCriteria?: Array<{
      __typename?: 'EvaluationCriterionType';
      id: string;
      name: string;
      order: number;
    } | null> | null;
  } | null;
};

export type ReorderEvaluationCriterionItemsMutationVariables = Exact<{
  items: Array<ReorderItemInput> | ReorderItemInput;
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  scenarioId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ReorderEvaluationCriterionItemsMutation = {
  __typename?: 'Mutation';
  reorderEvaluationCriterionItems?: {
    __typename?: 'ReorderEvaluationCriterionItems';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
    evaluationCriterionItems?: Array<{
      __typename?: 'EvaluationCriterionItemType';
      id: string;
      name: string;
      order: number;
    } | null> | null;
  } | null;
};

export type UpdateEvaluationCriterionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  scenarioId?: InputMaybe<Scalars['ID']['input']>;
  evaluationCriterionItems:
    | Array<InputMaybe<EvaluationCriterionItemInput>>
    | InputMaybe<EvaluationCriterionItemInput>;
}>;

export type UpdateEvaluationCriterionMutation = {
  __typename?: 'Mutation';
  updateEvaluationCriterion?: {
    __typename?: 'UpdateEvaluationCriterion';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
    evaluationCriterion?: {
      __typename?: 'EvaluationCriterionType';
      id: string;
      name: string;
    } | null;
  } | null;
};

export type CreateScenarioIntakeMutationVariables = Exact<{
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
  callId: Scalars['String']['input'];
}>;

export type CreateScenarioIntakeMutation = {
  __typename?: 'Mutation';
  createScenarioIntake?: {
    __typename?: 'CreateScenarioIntake';
    scenarioIntake?: {
      __typename?: 'ScenarioIntakeType';
      id: string;
      uuid: string;
      processingStatus?: ScenarioIntakeProcessingStatus | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type QuickCreateScenarioIntakeMutationVariables = Exact<{
  scenarioPrompt: Scalars['String']['input'];
  roleplayId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type QuickCreateScenarioIntakeMutation = {
  __typename?: 'Mutation';
  quickCreateScenarioIntake?: {
    __typename?: 'QuickCreateScenarioIntake';
    scenarioIntake?: {
      __typename?: 'ScenarioIntakeType';
      id: string;
      uuid: string;
      processingStatus?: ScenarioIntakeProcessingStatus | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type SaveScenarioNotesMutationVariables = Exact<{
  scenarioId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
}>;

export type SaveScenarioNotesMutation = {
  __typename?: 'Mutation';
  saveScenarioNotes?: {
    __typename?: 'SaveScenarioNotes';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type ScenarioNotesQueryVariables = Exact<{
  scenarioId: Scalars['ID']['input'];
}>;

export type ScenarioNotesQuery = {
  __typename?: 'Query';
  scenarioNotes?: {
    __typename?: 'UserScenarioNoteType';
    id: string;
    content: string;
  } | null;
};

export type ScenarioQueryVariables = Exact<{
  scenarioSlug: Scalars['String']['input'];
}>;

export type ScenarioQuery = {
  __typename?: 'Query';
  featureIntroduced?: boolean | null;
  scenario?: {
    __typename?: 'ScenarioType';
    id: string;
    name: string;
    shortDesc?: string | null;
    difficulty: ScenarioDifficulty;
    slug: string;
    context?: string | null;
    objective?: string | null;
    personaName?: string | null;
    personaFirstName?: string | null;
    personaLastName?: string | null;
    personaPhotoUrl?: string | null;
    assistantId?: string | null;
    voiceId?: string | null;
    visibilityScope?: VisibilityScope | null;
    visibleCriteriaBanner?: boolean | null;
    personalityCharacteristics?: string | null;
    canEdit?: boolean | null;
    introductionAudioUrl?: string | null;
    evaluationCriteria?: Array<{
      __typename?: 'EvaluationCriterionType';
      id: string;
      name: string;
      order: number;
      items?: Array<{
        __typename?: 'EvaluationCriterionItemType';
        id: string;
        name: string;
        order: number;
        description?: string | null;
      } | null> | null;
    } | null> | null;
    roleplaySessions?: Array<{
      __typename?: 'RoleplaySessionType';
      id: string;
      uuid: string;
      rank?: RoleplayRank | null;
      updatedAt: any;
      scenario: { __typename?: 'ScenarioType'; slug: string };
    } | null> | null;
    owner?: {
      __typename?: 'MiniUserType';
      id: string;
      name?: string | null;
      email: string;
      photo?: string | null;
    } | null;
    personalityTraits: Array<{
      __typename?: 'ScenarioPersonalityTraitType';
      attributeId?: string | null;
      levelId?: string | null;
    }>;
    workspace?: {
      __typename?: 'PublicWorkspaceGraphqlType';
      id: string;
      name: string;
    } | null;
    collections?: Array<{
      __typename?: 'RoleplayType';
      id: string;
      name: string;
      emojiUnicode: string;
      slug: string;
      canEdit?: boolean | null;
    } | null> | null;
  } | null;
};

export type ScenarioCollectionsFragment = {
  __typename?: 'ScenarioType';
  id: string;
  slug: string;
  name: string;
  collections?: Array<{
    __typename?: 'RoleplayType';
    id: string;
    name: string;
    emojiUnicode: string;
    slug: string;
    canEdit?: boolean | null;
  } | null> | null;
};

export type UpdateScenarioVisibilityScopeMutationVariables = Exact<{
  scenarioId: Scalars['ID']['input'];
  visibilityScope: VisibilityScope;
}>;

export type UpdateScenarioVisibilityScopeMutation = {
  __typename?: 'Mutation';
  updateScenarioVisibilityScope?: {
    __typename?: 'UpdateScenarioVisibilityScope';
    ok?: boolean | null;
    msg?: string | null;
    scenario?: {
      __typename?: 'ScenarioType';
      id: string;
      visibilityScope?: VisibilityScope | null;
    } | null;
  } | null;
};

export type CreateRoleplaySessionMutationVariables = Exact<{
  scenarioId: Scalars['ID']['input'];
  roleplaySlug?: InputMaybe<Scalars['String']['input']>;
  callId: Scalars['String']['input'];
}>;

export type CreateRoleplaySessionMutation = {
  __typename?: 'Mutation';
  createRoleplaySession?: {
    __typename?: 'CreateRoleplaySession';
    roleplaySession?: {
      __typename?: 'RoleplaySessionType';
      id: string;
      uuid: string;
      processingStatus?: RoleplaySessionProcessingStatus | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type RoleplaySessionQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type RoleplaySessionQuery = {
  __typename?: 'Query';
  roleplaySession?: {
    __typename?: 'RoleplaySessionType';
    id: string;
    uuid: string;
    score?: number | null;
    rank?: RoleplayRank | null;
    callId?: string | null;
    processingStatus?: RoleplaySessionProcessingStatus | null;
    file: string;
    duration?: number | null;
    outcomeFeedback?: string | null;
    positiveFeedback?: string | null;
    constructiveFeedback?: string | null;
    transcript?: string | null;
    cleanMessages?: Array<any | null> | null;
    createdAt: any;
    updatedAt: any;
    isValidAttempt: boolean;
    isValidAttemptExplanation?: string | null;
    recordingUrl?: string | null;
    programs?: Array<{
      __typename?: 'ProgramType';
      id: string;
      name: string;
    } | null> | null;
    user: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      email: string;
      photo?: string | null;
    };
    scenario: {
      __typename?: 'ScenarioType';
      id: string;
      name: string;
      shortDesc?: string | null;
      difficulty: ScenarioDifficulty;
      slug: string;
      context?: string | null;
      objective?: string | null;
      personaName?: string | null;
      personaFirstName?: string | null;
      personaLastName?: string | null;
      personaPhotoUrl?: string | null;
      assistantId?: string | null;
      voiceId?: string | null;
      visibilityScope?: VisibilityScope | null;
      visibleCriteriaBanner?: boolean | null;
      personalityCharacteristics?: string | null;
      canEdit?: boolean | null;
      introductionAudioUrl?: string | null;
      roleplaySessions?: Array<{
        __typename?: 'RoleplaySessionType';
        id: string;
        uuid: string;
        rank?: RoleplayRank | null;
        updatedAt: any;
        scenario: { __typename?: 'ScenarioType'; slug: string };
      } | null> | null;
      owner?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
      } | null;
      personalityTraits: Array<{
        __typename?: 'ScenarioPersonalityTraitType';
        attributeId?: string | null;
        levelId?: string | null;
      }>;
      workspace?: {
        __typename?: 'PublicWorkspaceGraphqlType';
        id: string;
        name: string;
      } | null;
    };
    formFields?: Array<{
      __typename?: 'FormFieldType';
      id: string;
      prompt: string;
      answerType: FormFieldAnswerType;
      helpText?: string | null;
      required: boolean;
      formFieldOptions?: Array<{
        __typename?: 'FormFieldOptionType';
        id: string;
        label: string;
        value: string;
      } | null> | null;
      filteredAnswers?: Array<{
        __typename?: 'AnswerType';
        id: string;
        value?: string | null;
        optionValue?: {
          __typename?: 'FormFieldOptionAnalyticsType';
          id: string;
          isCorrect?: boolean | null;
          explanation?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    sharedMembers?: Array<{
      __typename?: 'TaggableWorkspaceMemberType';
      id: string;
      user?: {
        __typename?: 'TaggableUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
      } | null;
    } | null> | null;
    evaluations?: Array<{
      __typename?: 'RoleplaySessionCriterionType';
      id: string;
      grade?: RoleplayEvalGrade | null;
      feedback?: string | null;
      summary?: any | null;
      feedbackExamples?: Array<{
        __typename?: 'EvaluationCriterionFeedbackExampleType';
        id: string;
        feedback?: string | null;
        salience?: RoleplaySessionEvaluationInstanceSalienceLevel | null;
        grade?: RoleplayEvalGrade | null;
        quote: string;
        suggestionQuote?: string | null;
      } | null> | null;
      evaluationCriterionItem?: {
        __typename?: 'EvaluationCriterionItemType';
        id: string;
        name: string;
        evaluationCriterion: {
          __typename?: 'EvaluationCriterionType';
          id: string;
          name: string;
          order: number;
        };
      } | null;
    } | null> | null;
  } | null;
};

export type RoleplaySessionCommentFragmentFragment = {
  __typename?: 'RoleplaySessionCommentType';
  id: string;
  text: string;
  user: string;
};

export type RoleplaySessionStatusQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type RoleplaySessionStatusQuery = {
  __typename?: 'Query';
  roleplaySession?: {
    __typename?: 'RoleplaySessionType';
    uuid: string;
    processingStatus?: RoleplaySessionProcessingStatus | null;
  } | null;
};

export type RoleplaySessionTranscriptQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type RoleplaySessionTranscriptQuery = {
  __typename?: 'Query';
  roleplaySession?: {
    __typename?: 'RoleplaySessionType';
    id: string;
    recordingUrl?: string | null;
    transcripts?: Array<{
      __typename?: 'RoleplaySessionTranscriptLineType';
      id?: string | null;
      speakerId?: string | null;
      time?: any | null;
      endTime?: any | null;
      text?: string | null;
      duration?: number | null;
      secondsFromStart?: number | null;
      evaluatedCriterion?: Array<{
        __typename?: 'RoleplaySessionTranscriptLineEvaluatedCriterionType';
        id?: string | null;
        name?: string | null;
        items?: Array<{
          __typename?: 'RoleplaySessionTranscriptLineEvaluatedCriterionItemType';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          feedbacks?: Array<{
            __typename?: 'RoleplaySessionTranscriptLineEvaluatedCriterionRoleplaySessionType';
            id?: string | null;
            feedback?: string | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
      comments?: Array<{
        __typename?: 'RoleplaySessionCommentType';
        id: string;
        text: string;
        user: string;
      } | null> | null;
      aiComments?: Array<{
        __typename?: 'RoleplaySessionCommentType';
        id: string;
        text: string;
        user: string;
      } | null> | null;
      feedbacks?: Array<{
        __typename?: 'EvaluationCriterionFeedbackExampleType';
        id: string;
        feedback?: string | null;
        salience?: RoleplaySessionEvaluationInstanceSalienceLevel | null;
        grade?: RoleplayEvalGrade | null;
        quote: string;
        suggestionQuote?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type RoleplaySessionTranscriptFragmentFragment = {
  __typename?: 'RoleplaySessionTranscriptLineType';
  id?: string | null;
  speakerId?: string | null;
  time?: any | null;
  endTime?: any | null;
  text?: string | null;
  duration?: number | null;
  secondsFromStart?: number | null;
  evaluatedCriterion?: Array<{
    __typename?: 'RoleplaySessionTranscriptLineEvaluatedCriterionType';
    id?: string | null;
    name?: string | null;
    items?: Array<{
      __typename?: 'RoleplaySessionTranscriptLineEvaluatedCriterionItemType';
      id?: string | null;
      name?: string | null;
      order?: number | null;
      feedbacks?: Array<{
        __typename?: 'RoleplaySessionTranscriptLineEvaluatedCriterionRoleplaySessionType';
        id?: string | null;
        feedback?: string | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
  comments?: Array<{
    __typename?: 'RoleplaySessionCommentType';
    id: string;
    text: string;
    user: string;
  } | null> | null;
  aiComments?: Array<{
    __typename?: 'RoleplaySessionCommentType';
    id: string;
    text: string;
    user: string;
  } | null> | null;
  feedbacks?: Array<{
    __typename?: 'EvaluationCriterionFeedbackExampleType';
    id: string;
    feedback?: string | null;
    salience?: RoleplaySessionEvaluationInstanceSalienceLevel | null;
    grade?: RoleplayEvalGrade | null;
    quote: string;
    suggestionQuote?: string | null;
  } | null> | null;
};

export type SaveQuizAnswerMutationVariables = Exact<{
  roleplaySessionId: Scalars['ID']['input'];
  formFieldId: Scalars['ID']['input'];
  optionValueId: Scalars['ID']['input'];
}>;

export type SaveQuizAnswerMutation = {
  __typename?: 'Mutation';
  saveQuizAnswer?: {
    __typename?: 'SaveQuizAnswer';
    isCorrect?: boolean | null;
    explanation?: string | null;
    ok?: boolean | null;
    msg?: string | null;
  } | null;
};

export type ScenarioIntakeQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type ScenarioIntakeQuery = {
  __typename?: 'Query';
  scenarioIntake?: {
    __typename?: 'ScenarioIntakeType';
    id: string;
    uuid: string;
    processingStatus?: ScenarioIntakeProcessingStatus | null;
    scenario?: {
      __typename?: 'ScenarioType';
      id: string;
      slug: string;
      assistantId?: string | null;
    } | null;
    roleplay?: {
      __typename?: 'RoleplayType';
      id: string;
      name: string;
      slug: string;
      emojiUnicode: string;
    } | null;
  } | null;
};

export type ScheduleQueryVariables = Exact<{ [key: string]: never }>;

export type ScheduleQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    name?: string | null;
    photo?: string | null;
    availableCredits?: any | null;
    hasIndividualAccount?: boolean | null;
    timeZone?: string | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      label?: string | null;
      balance: number;
    } | null> | null;
    coaches?: Array<{
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
      timeZone?: string | null;
      activeCoachProducts?: Array<{
        __typename?: 'CoachProductType';
        id?: number | null;
        product?: { __typename?: 'ProductType'; id: string } | null;
      } | null> | null;
      agentProfile?: {
        __typename?: 'AgentProfileType';
        id: string;
        summary?: string | null;
        averageRating?: number | null;
        acceptingNewBookings?: boolean | null;
        canHaveIntroSession?: boolean | null;
      } | null;
    } | null> | null;
  } | null;
};

export type AcceptBookingRequestMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  timeslot: Scalars['DateTime']['input'];
}>;

export type AcceptBookingRequestMutation = {
  __typename?: 'Mutation';
  acceptBookingRequest?: {
    __typename?: 'AcceptBookingRequest';
    ok?: boolean | null;
    meeting?: { __typename?: 'MeetingType'; id: string } | null;
  } | null;
};

export type BookingRequestTimeslotsQueryVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  isConflictCheck?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BookingRequestTimeslotsQuery = {
  __typename?: 'Query';
  bookingRequestTimeslots?: Array<{
    __typename?: 'TimeslotsType';
    timeslot?: any | null;
    preferred?: boolean | null;
    isConflict?: boolean | null;
  } | null> | null;
};

export type CancelBookingRequestMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
}>;

export type CancelBookingRequestMutation = {
  __typename?: 'Mutation';
  cancelBookingRequest?: {
    __typename?: 'CancelBookingRequest';
    ok?: boolean | null;
    meeting?: { __typename?: 'MeetingType'; id: string } | null;
  } | null;
};

export type SessionsQueryVariables = Exact<{ [key: string]: never }>;

export type SessionsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    isAgent: boolean;
    availableCredits?: any | null;
    receivableCredits?: any | null;
    agentProfile?: {
      __typename?: 'AgentProfileType';
      id: string;
      bufferAfter?: number | null;
      bufferBefore?: number | null;
      minuteIncrement?: number | null;
      minimumMeetingNotice: number;
    } | null;
    unfulfilledOrders?: Array<{
      __typename?: 'OrderType';
      id: string;
      paymentStatus: OrderPaymentStatus;
      cancelIfUnpaidBy?: any | null;
      workspace?: {
        __typename?: 'PublicWorkspaceGraphqlType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      orderItems?: Array<{
        __typename?: 'OrderItemType';
        id: string;
        fulfillmentStatus: FulfillmentStatusTypes;
        product: {
          __typename?: 'ProductType';
          id: string;
          name: string;
          category?: ProductCategory | null;
        };
        meetings: Array<{
          __typename?: 'ChatMeetingType';
          id: string;
          eventAt?: any | null;
          coach?: {
            __typename?: 'AgentType';
            name?: string | null;
            photo?: string | null;
            email: string;
          } | null;
        }>;
        reviews: Array<{
          __typename?: 'ChatReviewType';
          id: string;
          coach?: {
            __typename?: 'AgentType';
            name?: string | null;
            photo?: string | null;
            email: string;
          } | null;
        }>;
      } | null> | null;
    } | null> | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      balance: number;
      label?: string | null;
    } | null> | null;
    meetings?: Array<{
      __typename?: 'MeetingType';
      id: string;
      chatChannelId?: string | null;
      numMessages?: number | null;
      eventAt?: any | null;
      canceledAt?: any | null;
      cancelReason?: string | null;
      location?: string | null;
      customLocation?: string | null;
      duration?: number | null;
      customerId?: number | null;
      refundableUntil?: any | null;
      canLeaveRating?: boolean | null;
      paymentStatus?: PaymentStatusTypes | null;
      googleEventId?: string | null;
      creditPrice?: any | null;
      allowInvitation: boolean;
      allowGuests?: boolean | null;
      guests?: Array<string | null> | null;
      isBookingRequest?: boolean | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        coachSlug?: string | null;
        email: string;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
        email: string;
      } | null;
      attendee?: {
        __typename?: 'MeetingAttendeeType';
        id: string;
        userId?: number | null;
        status?: InvitationStatus | null;
        attendingStatus?: AttendingStatus | null;
        meetingId?: number | null;
      } | null;
      workspace?: {
        __typename?: 'WorkspaceType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        duration?: number | null;
        category?: ProductCategory | null;
        description?: string | null;
        introProduct: boolean;
        enableGuests?: boolean | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
    bookingRequestMeetings?: Array<{
      __typename?: 'MeetingType';
      id: string;
      chatChannelId?: string | null;
      numMessages?: number | null;
      eventAt?: any | null;
      canceledAt?: any | null;
      cancelReason?: string | null;
      location?: string | null;
      customLocation?: string | null;
      duration?: number | null;
      customerId?: number | null;
      refundableUntil?: any | null;
      canLeaveRating?: boolean | null;
      paymentStatus?: PaymentStatusTypes | null;
      googleEventId?: string | null;
      creditPrice?: any | null;
      allowInvitation: boolean;
      allowGuests?: boolean | null;
      guests?: Array<string | null> | null;
      isBookingRequest?: boolean | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        coachSlug?: string | null;
        email: string;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
        email: string;
      } | null;
      attendee?: {
        __typename?: 'MeetingAttendeeType';
        id: string;
        userId?: number | null;
        status?: InvitationStatus | null;
        attendingStatus?: AttendingStatus | null;
        meetingId?: number | null;
      } | null;
      workspace?: {
        __typename?: 'WorkspaceType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        duration?: number | null;
        category?: ProductCategory | null;
        description?: string | null;
        introProduct: boolean;
        enableGuests?: boolean | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TransferCreditsMutationVariables = Exact<{
  amount: Scalars['Decimal']['input'];
  fromKey?: InputMaybe<Scalars['String']['input']>;
  fromId?: InputMaybe<Scalars['ID']['input']>;
  toKey?: InputMaybe<Scalars['String']['input']>;
  toId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type TransferCreditsMutation = {
  __typename?: 'Mutation';
  transferCredits?: {
    __typename?: 'TransferCredits';
    ok?: boolean | null;
    error?: {
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null;
  } | null;
};

export type TransferPageQueryVariables = Exact<{ [key: string]: never }>;

export type TransferPageQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    isAgent: boolean;
    availableCredits?: any | null;
    receivableCredits?: any | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      balance: number;
      label?: string | null;
    } | null> | null;
  } | null;
};

export type AssignAssistantMutationVariables = Exact<{
  clientMemberId: Scalars['ID']['input'];
  assistantMemberId: Scalars['ID']['input'];
}>;

export type AssignAssistantMutation = {
  __typename?: 'Mutation';
  assignAssistant?: {
    __typename?: 'AssignAssistant';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    workspaceAssistantAssignment?: {
      __typename?: 'WorkspaceAssistantAssignmentType';
      client?: {
        __typename?: 'WorkspaceMemberType';
        id: string;
        user: {
          __typename?: 'UserType';
          id: string;
          name?: string | null;
          email: string;
          photo?: string | null;
        };
      } | null;
      assistant?: {
        __typename?: 'WorkspaceMemberType';
        id: string;
        user: {
          __typename?: 'UserType';
          id: string;
          name?: string | null;
          email: string;
          photo?: string | null;
        };
      } | null;
    } | null;
  } | null;
};

export type AssignWorkspaceGroupMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  memberIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>;
}>;

export type AssignWorkspaceGroupMutation = {
  __typename?: 'Mutation';
  assignWorkspaceGroup?: {
    __typename?: 'AssignWorkspaceGroup';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CancelInviteClientScheduleMutationVariables = Exact<{
  scheduleJobId: Scalars['ID']['input'];
}>;

export type CancelInviteClientScheduleMutation = {
  __typename?: 'Mutation';
  cancelInviteClientSchedule?: {
    __typename?: 'CancelInviteClientSchedule';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type ConfigurationFragmentFragment = {
  __typename?: 'ConfigurationType';
  configId?: string | null;
  enableJobSearch?: boolean | null;
  enableReviews?: boolean | null;
  specialtyIncludeIds?: Array<string | null> | null;
  specialtyExcludeIds?: Array<string | null> | null;
  configType?: string | null;
  maximumCreditPrice?: any | null;
  specialtyIncludes?: Array<{
    __typename?: 'SpecialtyType';
    id: string;
    name: string;
  } | null> | null;
  specialtyExcludes?: Array<{
    __typename?: 'SpecialtyType';
    id: string;
    name: string;
  } | null> | null;
  coachSpecIncludes?: Array<{
    __typename?: 'CoachSpecialtyConfigurationType';
    coach?: {
      __typename?: 'MiniCoachType';
      id: string;
      name?: string | null;
    } | null;
    specialty?: {
      __typename?: 'SpecialtyType';
      id: string;
      name: string;
    } | null;
  } | null> | null;
  coachSpecExcludes?: Array<{
    __typename?: 'CoachSpecialtyConfigurationType';
    coach?: {
      __typename?: 'MiniCoachType';
      id: string;
      name?: string | null;
    } | null;
    specialty?: {
      __typename?: 'SpecialtyType';
      id: string;
      name: string;
    } | null;
  } | null> | null;
};

export type CreateWorkspaceGroupMutationVariables = Exact<{
  name: Scalars['String']['input'];
  config?: InputMaybe<ConfigurationInput>;
}>;

export type CreateWorkspaceGroupMutation = {
  __typename?: 'Mutation';
  createWorkspaceGroup?: {
    __typename?: 'CreateWorkspaceGroup';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    group?: {
      __typename?: 'WorkspaceGroupType';
      id: string;
      name: string;
    } | null;
  } | null;
};

export type DeactivateWorkspaceMembersMutationVariables = Exact<{
  workspaceMemberIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>;
}>;

export type DeactivateWorkspaceMembersMutation = {
  __typename?: 'Mutation';
  deactivateWorkspaceMembers?: {
    __typename?: 'DeactivateWorkspaceMembers';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
    deactivatedMembers?: Array<{
      __typename?: 'WorkspaceMemberType';
      id: string;
      user: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        email: string;
      };
    } | null> | null;
  } | null;
};

export type DeleteGroupMemberMutationVariables = Exact<{
  groupMemberId: Scalars['ID']['input'];
}>;

export type DeleteGroupMemberMutation = {
  __typename?: 'Mutation';
  deleteGroupMember?: {
    __typename?: 'DeleteGroupMember';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type DeleteWorkspaceMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteWorkspaceMutation = {
  __typename?: 'Mutation';
  deleteWorkspace?: {
    __typename?: 'WorkspaceDelete';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteWorkspaceGroupMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;

export type DeleteWorkspaceGroupMutation = {
  __typename?: 'Mutation';
  deleteWorkspaceGroup?: {
    __typename?: 'DeleteWorkspaceGroup';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type InviteClientsMutationVariables = Exact<{
  emails: Scalars['String']['input'];
  coachIds?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  credit: Scalars['Decimal']['input'];
  isSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type InviteClientsMutation = {
  __typename?: 'Mutation';
  inviteClients?: {
    __typename?: 'InviteClients';
    ok?: boolean | null;
    toBeInvitedEmails?: Array<string | null> | null;
    invalidEmails?: Array<string | null> | null;
    existedEmails?: Array<string | null> | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type RemoveAssistantMutationVariables = Exact<{
  clientMemberId: Scalars['ID']['input'];
  assistantMemberId: Scalars['ID']['input'];
}>;

export type RemoveAssistantMutation = {
  __typename?: 'Mutation';
  removeAssistant?: {
    __typename?: 'RemoveAssistant';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type SetWorkspaceGroupCreditsMutationVariables = Exact<{
  groupIds:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Decimal']['input'];
}>;

export type SetWorkspaceGroupCreditsMutation = {
  __typename?: 'Mutation';
  setWorkspaceGroupCredits?: {
    __typename?: 'SetWorkspaceGroupCredits';
    ok?: boolean | null;
    success?: number | null;
    total?: number | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SetWorkspaceMemberCreditsMutationVariables = Exact<{
  clientIds:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Decimal']['input'];
}>;

export type SetWorkspaceMemberCreditsMutation = {
  __typename?: 'Mutation';
  setWorkspaceMemberCredits?: {
    __typename?: 'SetWorkspaceMemberCredits';
    ok?: boolean | null;
    success?: number | null;
    total?: number | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SpecialtiesForConfigQueryVariables = Exact<{
  categories?: InputMaybe<
    Array<InputMaybe<ProductCategories>> | InputMaybe<ProductCategories>
  >;
}>;

export type SpecialtiesForConfigQuery = {
  __typename?: 'Query';
  specialtiesForConfig?: Array<{
    __typename?: 'SpecialtyType';
    id: string;
    name: string;
    slug: string;
  } | null> | null;
};

export type TransferCreditsDialogQueryVariables = Exact<{
  keys?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type TransferCreditsDialogQuery = {
  __typename?: 'Query';
  ledgerAccounts?: Array<{
    __typename?: 'LedgerAccountType';
    id: string;
    label?: string | null;
    balance: number;
  } | null> | null;
};

export type UnassignWorkspaceGroupMutationVariables = Exact<{
  groupId?: InputMaybe<Scalars['ID']['input']>;
  memberIds:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type UnassignWorkspaceGroupMutation = {
  __typename?: 'Mutation';
  unassignWorkspaceGroup?: {
    __typename?: 'UnassignWorkspaceGroup';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateGroupRoleMutationVariables = Exact<{
  groupMemberId: Scalars['ID']['input'];
  isAdmin: Scalars['Boolean']['input'];
}>;

export type UpdateGroupRoleMutation = {
  __typename?: 'Mutation';
  updateGroupRole?: {
    __typename?: 'UpdateGroupRole';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateMemberRoleMutationVariables = Exact<{
  memberId: Scalars['ID']['input'];
  role: Scalars['String']['input'];
}>;

export type UpdateMemberRoleMutation = {
  __typename?: 'Mutation';
  updateMemberRole?: {
    __typename?: 'UpdateMemberRole';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    updatedMember?: {
      __typename?: 'WorkspaceMemberType';
      id: string;
      role: WorkspaceMemberRole;
    } | null;
  } | null;
};

export type UpdateWorkspaceGroupMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  config?: InputMaybe<ConfigurationInput>;
}>;

export type UpdateWorkspaceGroupMutation = {
  __typename?: 'Mutation';
  updateWorkspaceGroup?: {
    __typename?: 'UpdateWorkspaceGroup';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateWorkspaceInvitationScheduleMutationVariables = Exact<{
  scheduledJobId: Scalars['ID']['input'];
  emails: Scalars['String']['input'];
  coachIds?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  credit: Scalars['Decimal']['input'];
  isSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type UpdateWorkspaceInvitationScheduleMutation = {
  __typename?: 'Mutation';
  updateWorkspaceInvitationSchedule?: {
    __typename?: 'UpdateWorkspaceInvitationSchedule';
    ok?: boolean | null;
    toBeInvitedEmails?: Array<string | null> | null;
    invalidEmails?: Array<string | null> | null;
    existedEmails?: Array<string | null> | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateWorkspaceUrlSlugMutationVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type UpdateWorkspaceUrlSlugMutation = {
  __typename?: 'Mutation';
  updateWorkspaceUrlSlug?: {
    __typename?: 'UpdateWorkspaceUrlSlug';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
    updatedWorkspace?: {
      __typename?: 'WorkspaceGraphqlType';
      id: string;
      urlSlug: string;
    } | null;
  } | null;
};

export type WorkspaceAnalyticsQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspaceAnalyticsQuery = {
  __typename?: 'Query';
  workspaceAnalytics?: {
    __typename?: 'WorkspaceAnalyticsType';
    id?: string | null;
    url?: string | null;
  } | null;
};

export type WorkspaceClientStatisticsQueryQueryVariables = Exact<{
  currentPage: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortDesc: Scalars['Boolean']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type WorkspaceClientStatisticsQueryQuery = {
  __typename?: 'Query';
  workspaceClientStatistics?: {
    __typename?: 'WorkspaceClientPageType';
    currentPage?: number | null;
    pageSize?: number | null;
    totalRecords?: number | null;
    totalMembers?: number | null;
    data?: Array<{
      __typename?: 'WorkspaceMemberType';
      id: string;
      availableCredits?: any | null;
      totalBalance?: any | null;
      role: WorkspaceMemberRole;
      ledgerAccounts?: Array<{
        __typename?: 'LedgerAccountType';
        id: string;
        name: LedgerAccountName;
        label?: string | null;
        balance: number;
        ownerType?: string | null;
      } | null> | null;
      groups: Array<{
        __typename?: 'WorkspaceGroupType';
        id: string;
        name: string;
      }>;
      workspace: {
        __typename?: 'PublicWorkspaceGraphqlType';
        id: string;
        name: string;
        urlSlug: string;
      };
      user: {
        __typename?: 'UserType';
        id: string;
        photo?: string | null;
        name?: string | null;
        email: string;
        coaches?: Array<{
          __typename?: 'AgentType';
          id: string;
          photo?: string | null;
          name?: string | null;
          agentProfile?: {
            __typename?: 'AgentProfileType';
            id: string;
            coachSlug: string;
          } | null;
        } | null> | null;
      };
      assistantClients: Array<{
        __typename?: 'WorkspaceAssistantAssignmentType';
        id: string;
        client?: {
          __typename?: 'WorkspaceMemberType';
          id: string;
          user: {
            __typename?: 'UserType';
            id: string;
            name?: string | null;
            photo?: string | null;
            email: string;
          };
        } | null;
      }>;
      assistants: Array<{
        __typename?: 'WorkspaceAssistantAssignmentType';
        id: string;
        assistant?: {
          __typename?: 'WorkspaceMemberType';
          id: string;
          user: {
            __typename?: 'UserType';
            id: string;
            name?: string | null;
            photo?: string | null;
            email: string;
          };
        } | null;
      }>;
    } | null> | null;
  } | null;
};

export type WorkspaceGroupQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;

export type WorkspaceGroupQuery = {
  __typename?: 'Query';
  workspaceGroup?: {
    __typename?: 'WorkspaceGroupType';
    id: string;
    name: string;
    memberCount?: number | null;
    availableCredits?: any | null;
    currentUserRole?: string | null;
    configuration?: {
      __typename?: 'ConfigurationType';
      configId?: string | null;
      enableJobSearch?: boolean | null;
      enableReviews?: boolean | null;
      specialtyIncludeIds?: Array<string | null> | null;
      specialtyExcludeIds?: Array<string | null> | null;
      configType?: string | null;
      maximumCreditPrice?: any | null;
      specialtyIncludes?: Array<{
        __typename?: 'SpecialtyType';
        id: string;
        name: string;
      } | null> | null;
      specialtyExcludes?: Array<{
        __typename?: 'SpecialtyType';
        id: string;
        name: string;
      } | null> | null;
      coachSpecIncludes?: Array<{
        __typename?: 'CoachSpecialtyConfigurationType';
        coach?: {
          __typename?: 'MiniCoachType';
          id: string;
          name?: string | null;
        } | null;
        specialty?: {
          __typename?: 'SpecialtyType';
          id: string;
          name: string;
        } | null;
      } | null> | null;
      coachSpecExcludes?: Array<{
        __typename?: 'CoachSpecialtyConfigurationType';
        coach?: {
          __typename?: 'MiniCoachType';
          id: string;
          name?: string | null;
        } | null;
        specialty?: {
          __typename?: 'SpecialtyType';
          id: string;
          name: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type WorkspaceGroupMembersQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;

export type WorkspaceGroupMembersQuery = {
  __typename?: 'Query';
  workspaceGroupMembers?: Array<{
    __typename?: 'WorkspaceGroupMemberType';
    id: string;
    isAdmin?: boolean | null;
    availableCredits?: any | null;
    workspaceMember?: {
      __typename?: 'WorkspaceMemberType';
      id: string;
      user: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        email: string;
      };
    } | null;
  } | null> | null;
};

export type WorkspaceGroupsQueryVariables = Exact<{
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type WorkspaceGroupsQuery = {
  __typename?: 'Query';
  workspaceGroups?: Array<{
    __typename?: 'WorkspaceGroupType';
    id: string;
    name: string;
    memberCount?: number | null;
    availableCredits?: any | null;
    currentUserRole?: string | null;
  } | null> | null;
};

export type WorkspaceInvitationBatchQueryVariables = Exact<{
  workspaceInvitationBatchId: Scalars['ID']['input'];
}>;

export type WorkspaceInvitationBatchQuery = {
  __typename?: 'Query';
  workspaceInvitationBatch?: {
    __typename?: 'WorkspaceInvitationBatchType';
    id: string;
    credit: number;
    status: WorkspaceInvitationBatchStatus;
    coaches?: Array<{
      __typename?: 'MiniCoachType';
      id: string;
      name?: string | null;
    } | null> | null;
    group?: {
      __typename?: 'WorkspaceGroupType';
      id: string;
      name: string;
    } | null;
    workspaceInvitations?: Array<{
      __typename?: 'WorkspaceInvitationType';
      id: string;
      email: string;
    } | null> | null;
  } | null;
};

export type WorkspaceInvitationBatchesQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type WorkspaceInvitationBatchesQuery = {
  __typename?: 'Query';
  workspaceInvitationBatches?: Array<{
    __typename?: 'WorkspaceInvitationBatchType';
    id: string;
    workspaceInvitations?: Array<{
      __typename?: 'WorkspaceInvitationType';
      id: string;
      email: string;
    } | null> | null;
  } | null> | null;
};

export type WorkspaceInvitationBatchesForProgramStatusQueryVariables = Exact<{
  programId: Scalars['ID']['input'];
}>;

export type WorkspaceInvitationBatchesForProgramStatusQuery = {
  __typename?: 'Query';
  workspaceInvitationBatchesForProgramStatus?: WorkspaceInvitationBatchesStatus | null;
};

export type WorkspaceInvitaionBatchesStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WorkspaceInvitaionBatchesStatusQuery = {
  __typename?: 'Query';
  workspaceInvitationBatchesStatus?: WorkspaceInvitationBatchesStatus | null;
};

export type WorkspaceAssistantClientFragmentFragment = {
  __typename?: 'WorkspaceMemberType';
  assistantClients: Array<{
    __typename?: 'WorkspaceAssistantAssignmentType';
    id: string;
    client?: {
      __typename?: 'WorkspaceMemberType';
      id: string;
      user: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
        email: string;
      };
    } | null;
  }>;
};

export type WorkspaceAssistantFragmentFragment = {
  __typename?: 'WorkspaceMemberType';
  assistants: Array<{
    __typename?: 'WorkspaceAssistantAssignmentType';
    id: string;
    assistant?: {
      __typename?: 'WorkspaceMemberType';
      id: string;
      user: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
        email: string;
      };
    } | null;
  }>;
};

export type WorkspaceMemberAssistantsQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type WorkspaceMemberAssistantsQuery = {
  __typename?: 'Query';
  workspaceMember?: {
    __typename?: 'WorkspaceMemberType';
    id: string;
    assistantClients: Array<{
      __typename?: 'WorkspaceAssistantAssignmentType';
      id: string;
      client?: {
        __typename?: 'WorkspaceMemberType';
        id: string;
        user: {
          __typename?: 'UserType';
          id: string;
          name?: string | null;
          photo?: string | null;
          email: string;
        };
      } | null;
    }>;
    assistants: Array<{
      __typename?: 'WorkspaceAssistantAssignmentType';
      id: string;
      assistant?: {
        __typename?: 'WorkspaceMemberType';
        id: string;
        user: {
          __typename?: 'UserType';
          id: string;
          name?: string | null;
          photo?: string | null;
          email: string;
        };
      } | null;
    }>;
  } | null;
};

export type WorkspaceMemberDetailsQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type WorkspaceMemberDetailsQuery = {
  __typename?: 'Query';
  workspaceMember?: {
    __typename?: 'WorkspaceMemberType';
    id: string;
    configuration?: {
      __typename?: 'ConfigurationType';
      configId?: string | null;
      enableJobSearch?: boolean | null;
      enableReviews?: boolean | null;
      specialtyIncludeIds?: Array<string | null> | null;
      specialtyExcludeIds?: Array<string | null> | null;
      configType?: string | null;
      maximumCreditPrice?: any | null;
      specialtyIncludes?: Array<{
        __typename?: 'SpecialtyType';
        id: string;
        name: string;
      } | null> | null;
      specialtyExcludes?: Array<{
        __typename?: 'SpecialtyType';
        id: string;
        name: string;
      } | null> | null;
      coachSpecIncludes?: Array<{
        __typename?: 'CoachSpecialtyConfigurationType';
        coach?: {
          __typename?: 'MiniCoachType';
          id: string;
          name?: string | null;
        } | null;
        specialty?: {
          __typename?: 'SpecialtyType';
          id: string;
          name: string;
        } | null;
      } | null> | null;
      coachSpecExcludes?: Array<{
        __typename?: 'CoachSpecialtyConfigurationType';
        coach?: {
          __typename?: 'MiniCoachType';
          id: string;
          name?: string | null;
        } | null;
        specialty?: {
          __typename?: 'SpecialtyType';
          id: string;
          name: string;
        } | null;
      } | null> | null;
    } | null;
    user: {
      __typename?: 'UserType';
      id: string;
      photo?: string | null;
      name?: string | null;
      email: string;
    };
    assistantClients: Array<{
      __typename?: 'WorkspaceAssistantAssignmentType';
      id: string;
      client?: {
        __typename?: 'WorkspaceMemberType';
        id: string;
        user: {
          __typename?: 'UserType';
          id: string;
          name?: string | null;
          photo?: string | null;
          email: string;
        };
      } | null;
    }>;
    assistants: Array<{
      __typename?: 'WorkspaceAssistantAssignmentType';
      id: string;
      assistant?: {
        __typename?: 'WorkspaceMemberType';
        id: string;
        user: {
          __typename?: 'UserType';
          id: string;
          name?: string | null;
          photo?: string | null;
          email: string;
        };
      } | null;
    }>;
  } | null;
};

export type MemberMeetingsQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars['ID']['input']>;
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  withoutCanceled?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MemberMeetingsQuery = {
  __typename?: 'Query';
  memberMeetings?: {
    __typename?: 'MemberMeetingPaginationType';
    currentPage?: number | null;
    totalRecords?: number | null;
    pageSize?: number | null;
    data?: Array<{
      __typename?: 'MeetingType';
      id: string;
      eventAt?: any | null;
      canceledAt?: any | null;
      cancelReason?: string | null;
      creditPrice?: any | null;
      paymentStatus?: PaymentStatusTypes | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        agentProfile?: {
          __typename?: 'AgentProfileType';
          id: string;
          coachSlug: string;
        } | null;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        creditPrice?: number | null;
        specialty?: {
          __typename?: 'SpecialtyType';
          id: string;
          name: string;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type WorkspaceMembersQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspaceMembersQuery = {
  __typename?: 'Query';
  workspaceMembers?: Array<{
    __typename?: 'WorkspaceMemberType';
    id: string;
    user: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      email: string;
    };
  } | null> | null;
};

export type WorkspaceScheduleJobsQueryVariables = Exact<{
  jobTypes?: InputMaybe<Array<InputMaybe<JobType>> | InputMaybe<JobType>>;
  statuses?: InputMaybe<Array<InputMaybe<JobStatus>> | InputMaybe<JobStatus>>;
  meetingId?: InputMaybe<Scalars['Int']['input']>;
  programId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type WorkspaceScheduleJobsQuery = {
  __typename?: 'Query';
  scheduledJobs?: Array<{
    __typename?: 'ScheduledJobSchemaType';
    id: string;
    jobPayload: any;
    scheduledAt: any;
    status?: JobStatus | null;
    resultStatus: ScheduledJobResultStatus;
  } | null> | null;
};

export type WorkspaceSubscriptionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WorkspaceSubscriptionQuery = {
  __typename?: 'Query';
  currentWorkspace?: {
    __typename?: 'WorkspaceGraphqlType';
    id: string;
    numMembers?: number | null;
    totalPendingInvitations?: number | null;
    subscription?: {
      __typename?: 'SubscriptionType';
      id: string;
      planName?: string | null;
      planDescription?: string | null;
      validUntil?: any | null;
      billingCycleEnd?: any | null;
      stripeSubscriptionId?: string | null;
      quantity: number;
      planEntitlements?: Array<{
        __typename?: 'PlanEntitlementType';
        id: string;
        label?: string | null;
        booleanValue?: boolean | null;
        maxUsageValue?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ValidateInviteEmailsQueryVariables = Exact<{
  emails: Scalars['String']['input'];
  programId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ValidateInviteEmailsQuery = {
  __typename?: 'Query';
  validateInviteEmails?: {
    __typename?: 'ValidateInviteEmailResultType';
    canBeInvitedEmails?: Array<string | null> | null;
    invalidEmails?: Array<string | null> | null;
    existedEmails?: Array<string | null> | null;
    pendingInvEmails?: Array<string | null> | null;
    existedProgramUserEmails?: Array<string | null> | null;
  } | null;
};

export type WorkspaceAssistantQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WorkspaceAssistantQueryQuery = {
  __typename?: 'Query';
  workspaceAssistant?: Array<{
    __typename?: 'WorkspaceAssistantAssignmentType';
    id: string;
    client?: {
      __typename?: 'WorkspaceMemberType';
      id: string;
      user: {
        __typename?: 'UserType';
        id: string;
        photo?: string | null;
        name?: string | null;
        email: string;
      };
    } | null;
  } | null> | null;
};

export type ClientStatisticQueryQueryVariables = Exact<{
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  assignees?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  workspaces?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  nonWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  nonAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;

export type ClientStatisticQueryQuery = {
  __typename?: 'Query';
  clientStatistics?: {
    __typename?: 'ClientPaginationType';
    currentPage?: number | null;
    totalRecords?: number | null;
    pageSize?: number | null;
    data?: Array<{
      __typename?: 'ClientStatisticType';
      id?: string | null;
      userId?: string | null;
      name?: string | null;
      photo?: string | null;
      email?: string | null;
      nextMeetingId?: string | null;
      nextMeetingEventAt?: any | null;
      nextMeetingDuration?: number | null;
      nextMeetingProductName?: string | null;
      lastMeetingId?: string | null;
      lastMeetingEventAt?: any | null;
      lastMeetingDuration?: number | null;
      lastMeetingProductName?: string | null;
      recentReviewId?: string | null;
      recentReviewProductName?: string | null;
      recentReviewCreateAt?: any | null;
      messagesId?: string | null;
      messagesCreatedAt?: any | null;
      messagesHtml?: string | null;
      messagesCid?: string | null;
      workspaceId?: string | null;
      workspaceName?: string | null;
      coachId?: string | null;
      coachName?: string | null;
      coachPhoto?: string | null;
      coachSlug?: string | null;
    } | null> | null;
  } | null;
};

export type ChatCustomersQueryVariables = Exact<{
  assignees?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  workspaces?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  nonWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ChatCustomersQuery = {
  __typename?: 'Query';
  chatCustomers?: Array<{
    __typename?: 'ChatCustomerType';
    id: string;
    chatChannelId?: string | null;
    unreadMessages?: number | null;
    customer?: {
      __typename?: 'TaggableUserType';
      id: string;
      name?: string | null;
      email: string;
      photo?: string | null;
    } | null;
    workspace?: {
      __typename?: 'WorkspaceType';
      id: string;
      name: string;
    } | null;
  } | null> | null;
};

export type ChatCustomerQueryQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type ChatCustomerQueryQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'UserType';
    id: string;
    photo?: string | null;
    name?: string | null;
    email: string;
  } | null;
};

export type ChatMeetingsQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ChatMeetingsQuery = {
  __typename?: 'Query';
  chatMeetings?: Array<{
    __typename?: 'ChatMeetingType';
    id: string;
    chatChannelId?: string | null;
    chatChannelTitle?: string | null;
    unreadMessages?: number | null;
    product?: { __typename?: 'ProductType'; id: string; name: string } | null;
  } | null> | null;
};

export type ChatReviewsQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ChatReviewsQuery = {
  __typename?: 'Query';
  chatReviews?: Array<{
    __typename?: 'ChatReviewType';
    id: string;
    chatChannelId?: string | null;
    status?: string | null;
    chatChannelTitle?: string | null;
    unreadMessages?: number | null;
    canceledAt?: any | null;
  } | null> | null;
};

export type DirectMessagesQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DirectMessagesQuery = {
  __typename?: 'Query';
  directMessages?: Array<{
    __typename?: 'ChatDirectMessageType';
    id: string;
    chatChannelId?: string | null;
    unreadMessages?: number | null;
    coach?: {
      __typename?: 'TaggableUserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'TaggableUserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
  } | null> | null;
};

export type DirectMessageQueryVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
}>;

export type DirectMessageQuery = {
  __typename?: 'Query';
  assignment?: {
    __typename?: 'AssignmentType';
    id: string;
    chatChannelId?: string | null;
    coach?: {
      __typename?: 'MiniCoachType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'MiniUserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
  } | null;
};

export type HasChatChannelsQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type HasChatChannelsQuery = {
  __typename?: 'Query';
  hasChatChannels?: boolean | null;
};

export type WorkspaceGeneralChatQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type WorkspaceGeneralChatQuery = {
  __typename?: 'Query';
  chatChannelId?: string | null;
  customer?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    email: string;
    photo?: string | null;
  } | null;
  currentWorkspace?: {
    __typename?: 'WorkspaceGraphqlType';
    id: string;
    logo?: string | null;
  } | null;
  taggableUsers?: Array<{
    __typename?: 'TaggableUserType';
    id: string;
    name?: string | null;
  } | null> | null;
};

export type WorkspaceMeetingChatQueryVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  customerId: Scalars['ID']['input'];
}>;

export type WorkspaceMeetingChatQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    email: string;
    photo?: string | null;
  } | null;
  meeting?: {
    __typename?: 'MeetingType';
    id: string;
    chatChannelTitle?: string | null;
    chatChannelId?: string | null;
  } | null;
};

export type WorkspaceReviewChatQueryVariables = Exact<{
  reviewId: Scalars['ID']['input'];
  customerId: Scalars['ID']['input'];
}>;

export type WorkspaceReviewChatQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    email: string;
    photo?: string | null;
  } | null;
  review?: {
    __typename?: 'ReviewType';
    id: string;
    chatChannelTitle?: string | null;
    chatChannelId?: string | null;
  } | null;
};

export type NotificationCountQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationCountQuery = {
  __typename?: 'Query';
  notificationCount?: number | null;
};

export type ParticipatedWorkspacesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ParticipatedWorkspacesQuery = {
  __typename?: 'Query';
  participatedWorkspaces?: Array<{
    __typename?: 'PublicWorkspaceGraphqlType';
    id: string;
    name: string;
    urlSlug: string;
    logo?: string | null;
  } | null> | null;
};

export type EnableIndividualAccountMutationVariables = Exact<{
  hasIndividualAccount: Scalars['Boolean']['input'];
}>;

export type EnableIndividualAccountMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'UpdateUser';
    errors?: Array<{
      __typename?: 'Error';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateWorkspaceNameMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type UpdateWorkspaceNameMutation = {
  __typename?: 'Mutation';
  updateWorkspaceName?: {
    __typename?: 'UpdateWorkspaceName';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
      code?: string | null;
    } | null> | null;
    updatedWorkspace?: {
      __typename?: 'WorkspaceGraphqlType';
      id: string;
      name: string;
    } | null;
  } | null;
};

export type WorkspaceNavQueryQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspaceNavQueryQuery = {
  __typename?: 'Query';
  currentWorkspaceRole?: string | null;
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    name?: string | null;
    phoneNumber?: string | null;
    jobTitle?: string | null;
    linkedinAccount?: string | null;
    timeZone?: string | null;
    billingReady?: boolean | null;
    photo?: string | null;
    isAgent: boolean;
    notificationPreferences: Array<{
      __typename?: 'NotificationPreferenceGraphqlType';
      type: NotificationPreferenceType;
      email: boolean;
    }>;
    agentProfile?: {
      __typename?: 'AgentProfileType';
      id: string;
      coachSlug: string;
    } | null;
  } | null;
  currentUserWorkspaceMember?: {
    __typename?: 'WorkspaceMemberType';
    id: string;
    user: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      email: string;
      photo?: string | null;
    };
    assistantClients: Array<{
      __typename?: 'WorkspaceAssistantAssignmentType';
      id: string;
      client?: {
        __typename?: 'WorkspaceMemberType';
        id: string;
        user: {
          __typename?: 'UserType';
          id: string;
          name?: string | null;
          photo?: string | null;
          email: string;
        };
      } | null;
    }>;
    assistants: Array<{
      __typename?: 'WorkspaceAssistantAssignmentType';
      id: string;
      assistant?: {
        __typename?: 'WorkspaceMemberType';
        id: string;
        user: {
          __typename?: 'UserType';
          id: string;
          name?: string | null;
          photo?: string | null;
          email: string;
        };
      } | null;
    }>;
  } | null;
};

export type ClientWorkspacesQueryQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ClientWorkspacesQueryQuery = {
  __typename?: 'Query';
  assignments?: Array<{
    __typename?: 'AssignmentType';
    id: string;
    workspace?: {
      __typename?: 'WorkspaceType';
      id: string;
      name: string;
      urlSlug: string;
      active: boolean;
    } | null;
  } | null> | null;
};

export type ClientWorkspacesQueryForStaffQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ClientWorkspacesQueryForStaffQuery = {
  __typename?: 'Query';
  clientWorkspaces?: Array<{
    __typename?: 'PublicWorkspaceGraphqlType';
    id: string;
    name: string;
    urlSlug: string;
    active: boolean;
  } | null> | null;
};

export type CoachQueryQueryVariables = Exact<{ [key: string]: never }>;

export type CoachQueryQuery = {
  __typename?: 'Query';
  coaches?: Array<{
    __typename?: 'MiniCoachType';
    id: string;
    name?: string | null;
  } | null> | null;
};

export type WorkspaceQueryQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspaceQueryQuery = {
  __typename?: 'Query';
  workspaces?: Array<{
    __typename?: 'PublicWorkspaceGraphqlType';
    id: string;
    name: string;
  } | null> | null;
};

export type CustomerProfileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CustomerProfileQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    email: string;
    phoneNumber?: string | null;
    photo?: string | null;
    jobTitle?: string | null;
    linkedinAccount?: string | null;
    timeZone?: string | null;
    resumes?: Array<{
      __typename?: 'ResumeGraphqlType';
      id: string;
      name?: string | null;
      updatedAt: any;
    } | null> | null;
    intakeForm?: {
      __typename?: 'IntakeFormType';
      id: string;
      completedAt?: any | null;
      industriesSkipped: boolean;
      jobTitlesSkipped: boolean;
      jobFunctionsSkipped: boolean;
      noteForCoach?: string | null;
      matchingOptions: Array<{
        __typename?: 'MatchingOptionType';
        id: string;
        name: string;
        visible: boolean;
        matchingCategories: Array<{
          __typename?: 'MatchingCategoryType';
          id: string;
          name: string;
        }>;
      }>;
      industries: Array<{
        __typename?: 'IndustryGraphqlType';
        id: string;
        name: string;
      }>;
      jobTitles: Array<{
        __typename?: 'JobTitleType';
        normalizedTitle: string;
        title: string;
      }>;
      jobFunctions: Array<{
        __typename?: 'JobFunctionGraphqlType';
        id: string;
        name: string;
      }>;
    } | null;
    coachAssignments: Array<{
      __typename?: 'ChatDirectMessageType';
      id: string;
      coach?: {
        __typename?: 'TaggableUserType';
        id: string;
        name?: string | null;
      } | null;
    }>;
    configuration?: {
      __typename?: 'ConfigurationType';
      configId?: string | null;
      version?: string | null;
      specialtyIncludeIds?: Array<string | null> | null;
      specialtyExcludeIds?: Array<string | null> | null;
      enableJobSearch?: boolean | null;
      enableReviews?: boolean | null;
    } | null;
  } | null;
};

export type AssignCoachesMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  coachIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>;
}>;

export type AssignCoachesMutation = {
  __typename?: 'Mutation';
  assignCoaches?: {
    __typename?: 'AssignCoaches';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type ClientNotesQueryQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type ClientNotesQueryQuery = {
  __typename?: 'Query';
  clientNotes?: Array<{
    __typename?: 'WorkspaceNoteType';
    id: string;
    content?: string | null;
    updatedAt: any;
    createdAt: any;
    author: { __typename?: 'UserType'; id: string; name?: string | null };
  } | null> | null;
};

export type DeleteClientNoteMutationVariables = Exact<{
  noteId: Scalars['ID']['input'];
}>;

export type DeleteClientNoteMutation = {
  __typename?: 'Mutation';
  deleteClientNote?: {
    __typename?: 'DeleteClientNote';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SaveClientNoteMutationVariables = Exact<{
  noteId?: InputMaybe<Scalars['ID']['input']>;
  customerId: Scalars['ID']['input'];
  noteContent: Scalars['String']['input'];
}>;

export type SaveClientNoteMutation = {
  __typename?: 'Mutation';
  saveClientNote?: {
    __typename?: 'SaveClientNote';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type StaffReviewsQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type StaffReviewsQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    email: string;
    availableCredits?: any | null;
    receivableCredits?: any | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      balance: number;
      label?: string | null;
    } | null> | null;
    reviews?: Array<{
      __typename?: 'ReviewType';
      id: string;
      chatChannelId?: string | null;
      status?: string | null;
      state?: string | null;
      nextDate?: string | null;
      numMessages?: number | null;
      acceptanceDeadline?: any | null;
      acceptedAt?: any | null;
      finalCheckRevisionSentAt?: any | null;
      canceledAt?: any | null;
      completedAt?: any | null;
      numRounds: number;
      numRoundsComplete: number;
      customerId?: number | null;
      canLeaveRating?: boolean | null;
      creditPrice?: any | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        turnaroundTime?: number | null;
        includesFinalCheck: boolean;
        description?: string | null;
        numRounds?: number | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
    configuration?: {
      __typename?: 'ConfigurationType';
      configId?: string | null;
      version?: string | null;
      specialtyIncludeIds?: Array<string | null> | null;
      specialtyExcludeIds?: Array<string | null> | null;
      enableJobSearch?: boolean | null;
      enableReviews?: boolean | null;
    } | null;
  } | null;
};

export type StaffSessionsQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type StaffSessionsQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    email: string;
    availableCredits?: any | null;
    receivableCredits?: any | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      balance: number;
      label?: string | null;
    } | null> | null;
    meetings?: Array<{
      __typename?: 'MeetingType';
      id: string;
      chatChannelId?: string | null;
      numMessages?: number | null;
      eventAt?: any | null;
      canceledAt?: any | null;
      cancelReason?: string | null;
      location?: string | null;
      customLocation?: string | null;
      duration?: number | null;
      customerId?: number | null;
      refundableUntil?: any | null;
      canLeaveRating?: boolean | null;
      paymentStatus?: PaymentStatusTypes | null;
      googleEventId?: string | null;
      creditPrice?: any | null;
      allowInvitation: boolean;
      allowGuests?: boolean | null;
      guests?: Array<string | null> | null;
      isBookingRequest?: boolean | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        coachSlug?: string | null;
        email: string;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
        email: string;
      } | null;
      attendee?: {
        __typename?: 'MeetingAttendeeType';
        id: string;
        userId?: number | null;
        status?: InvitationStatus | null;
        attendingStatus?: AttendingStatus | null;
        meetingId?: number | null;
      } | null;
      workspace?: {
        __typename?: 'WorkspaceType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        duration?: number | null;
        category?: ProductCategory | null;
        description?: string | null;
        introProduct: boolean;
        enableGuests?: boolean | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
    configuration?: {
      __typename?: 'ConfigurationType';
      configId?: string | null;
      version?: string | null;
      specialtyIncludeIds?: Array<string | null> | null;
      specialtyExcludeIds?: Array<string | null> | null;
      enableJobSearch?: boolean | null;
      enableReviews?: boolean | null;
    } | null;
    bookingRequestMeetings?: Array<{
      __typename?: 'MeetingType';
      id: string;
      chatChannelId?: string | null;
      numMessages?: number | null;
      eventAt?: any | null;
      canceledAt?: any | null;
      cancelReason?: string | null;
      location?: string | null;
      customLocation?: string | null;
      duration?: number | null;
      customerId?: number | null;
      refundableUntil?: any | null;
      canLeaveRating?: boolean | null;
      paymentStatus?: PaymentStatusTypes | null;
      googleEventId?: string | null;
      creditPrice?: any | null;
      allowInvitation: boolean;
      allowGuests?: boolean | null;
      guests?: Array<string | null> | null;
      isBookingRequest?: boolean | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        coachSlug?: string | null;
        email: string;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
        email: string;
      } | null;
      attendee?: {
        __typename?: 'MeetingAttendeeType';
        id: string;
        userId?: number | null;
        status?: InvitationStatus | null;
        attendingStatus?: AttendingStatus | null;
        meetingId?: number | null;
      } | null;
      workspace?: {
        __typename?: 'WorkspaceType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        duration?: number | null;
        category?: ProductCategory | null;
        description?: string | null;
        introProduct: boolean;
        enableGuests?: boolean | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PublicWorkspaceQueryVariables = Exact<{
  urlSlug: Scalars['String']['input'];
}>;

export type PublicWorkspaceQuery = {
  __typename?: 'Query';
  publicWorkspace?: {
    __typename?: 'PublicWorkspaceGraphqlType';
    id: string;
    name: string;
    urlSlug: string;
    logo?: string | null;
    isUsingSso?: boolean | null;
  } | null;
};

export type ActivateWorkspaceMembershipMutationVariables = Exact<{
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  workspace: Scalars['String']['input'];
}>;

export type ActivateWorkspaceMembershipMutation = {
  __typename?: 'Mutation';
  activateWorkspaceMembership?: {
    __typename?: 'ActivateWorkspaceMembership';
    ok?: boolean | null;
    sesameQueryParam?: string | null;
  } | null;
};

export type CreateWorkspaceMutationVariables = Exact<{
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  refCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateWorkspaceMutation = {
  __typename?: 'Mutation';
  createWorkspace?: {
    __typename?: 'CreateWorkspace';
    ok?: boolean | null;
    workspaceSignedToken?: string | null;
    workspace?: {
      __typename?: 'WorkspaceGraphqlType';
      id: string;
      name: string;
      urlSlug: string;
    } | null;
    error?: {
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null;
  } | null;
};

export type ValidSlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type ValidSlugQuery = {
  __typename?: 'Query';
  validSlug?: {
    __typename?: 'SlugValidationResultType';
    valid?: boolean | null;
    errorMessage?: string | null;
  } | null;
};

export type FaqsQueryVariables = Exact<{
  domain: Scalars['String']['input'];
}>;

export type FaqsQuery = {
  __typename?: 'Query';
  faqs?: Array<{
    __typename?: 'FaqType';
    category?: string | null;
    order?: number | null;
    questions?: Array<{
      __typename?: 'CMSFaqQuestionType';
      question?: string | null;
      order?: number | null;
      answer?: any | null;
    } | null> | null;
  } | null> | null;
};

export type LandingPageQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type LandingPageQuery = {
  __typename?: 'Query';
  landingPageVariant?: {
    __typename?: 'LandingPageVariantGraphqlType';
    basePage: LandingPageVariantBasePage;
    urlSlug: string;
    headline: string;
    headlineFontSize?: number | null;
    subHeader: string;
  } | null;
};

export type SsoGenerateUrlMutationVariables = Exact<{
  workspaceSlug: Scalars['String']['input'];
}>;

export type SsoGenerateUrlMutation = {
  __typename?: 'Mutation';
  ssoGenerateUrl?: {
    __typename?: 'SSOGenerateAuthURL';
    authUrl?: string | null;
  } | null;
};

export type ArticleQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type ArticleQuery = {
  __typename?: 'Query';
  article?: {
    __typename?: 'Article';
    id: string;
    updatedAt?: any | null;
    title?: string | null;
    timeToRead?: number | null;
    summary?: string | null;
    heroImage?: string | null;
    urlSlug?: string | null;
    body?: any | null;
    public?: boolean | null;
    noindex?: boolean | null;
    authorName?: string | null;
    authorPhoto?: string | null;
    authorShortBio?: string | null;
    authorUrl?: string | null;
    authorIsCoach?: boolean | null;
    canonicalDomain: ArticleCanonicalDomain;
    author?: {
      __typename?: 'UserType';
      isAgent: boolean;
      agentProfile?: {
        __typename?: 'AgentProfileType';
        id: string;
        coachSlug: string;
      } | null;
    } | null;
    primarySeries?: {
      __typename?: 'ArticleSeries';
      id?: string | null;
      title?: string | null;
      urlSlug?: string | null;
    } | null;
    sidebarCta?: {
      __typename?: 'ArticleSidebarCTA';
      id: string;
      headline: string;
      body?: string | null;
      ctaLink?: string | null;
      buttonText?: string | null;
    } | null;
  } | null;
  articleReadStatus?: {
    __typename?: 'ArticleReadStatus';
    id: string;
    readStatus: ArticleReadStatusReadStatus;
  } | null;
};

export type BitsAvailableQueryVariables = Exact<{
  loggedIn: Scalars['Boolean']['input'];
}>;

export type BitsAvailableQuery = {
  __typename?: 'Query';
  userCredit?: {
    __typename?: 'UserCreditType';
    id?: string | null;
    bitsAvailable?: any | null;
  } | null;
  optionalCurrentUser?: {
    __typename?: 'UserType';
    id: string;
    isConfirmed: boolean;
    emailProvider: string;
  } | null;
};

export type DeleteGptResponseMutationVariables = Exact<{
  gptResponseUuid: Scalars['String']['input'];
}>;

export type DeleteGptResponseMutation = {
  __typename?: 'Mutation';
  deleteGptResponse?: {
    __typename?: 'DeleteGPTResponse';
    ok?: boolean | null;
  } | null;
};

export type ExecuteGptPromptMutationVariables = Exact<{
  entryId: Scalars['ID']['input'];
  answers: Scalars['GenericScalar']['input'];
}>;

export type ExecuteGptPromptMutation = {
  __typename?: 'Mutation';
  executeGptPrompt?: {
    __typename?: 'ExecuteGPTPrompt';
    gptResponse?: {
      __typename?: 'GPTResponseType';
      id: string;
      uuid?: string | null;
      response?: string | null;
      answers?: any | null;
      errorCode?: GptResponseErrorCode | null;
      rating?: boolean | null;
      createdAt: any;
    } | null;
    error?: { __typename?: 'ErrorType'; code?: string | null } | null;
  } | null;
};

export type GptResponseQueryVariables = Exact<{
  gptResponseUuid: Scalars['String']['input'];
}>;

export type GptResponseQuery = {
  __typename?: 'Query';
  gptResponse?: {
    __typename?: 'GPTResponseType';
    id: string;
    uuid?: string | null;
    response?: string | null;
    answers?: any | null;
    errorCode?: GptResponseErrorCode | null;
    rating?: boolean | null;
    createdAt: any;
  } | null;
};

export type GptResponseFragmentFragment = {
  __typename?: 'GPTResponseType';
  id: string;
  uuid?: string | null;
  response?: string | null;
  answers?: any | null;
  errorCode?: GptResponseErrorCode | null;
  rating?: boolean | null;
  createdAt: any;
};

export type GptResponsesQueryVariables = Exact<{
  entryId: Scalars['String']['input'];
}>;

export type GptResponsesQuery = {
  __typename?: 'Query';
  gptResponses?: Array<{
    __typename?: 'GPTResponseType';
    id: string;
    uuid?: string | null;
    response?: string | null;
    answers?: any | null;
    errorCode?: GptResponseErrorCode | null;
    rating?: boolean | null;
    createdAt: any;
  } | null> | null;
};

export type RateGptResponseMutationVariables = Exact<{
  gptResponseUuid: Scalars['String']['input'];
  rating: Scalars['Boolean']['input'];
}>;

export type RateGptResponseMutation = {
  __typename?: 'Mutation';
  rateGptResponse?: {
    __typename?: 'RateGPTResponse';
    ok?: boolean | null;
  } | null;
};

export type RetryGptResponseMutationVariables = Exact<{
  gptResponseUuid: Scalars['String']['input'];
}>;

export type RetryGptResponseMutation = {
  __typename?: 'Mutation';
  retryGptResponse?: {
    __typename?: 'RetryGPTResponse';
    ok?: boolean | null;
  } | null;
};

export type PromptAnswerQueryVariables = Exact<{
  entryId: Scalars['ID']['input'];
}>;

export type PromptAnswerQuery = {
  __typename?: 'Query';
  promptAnswer?: {
    __typename?: 'PromptAnswerType';
    id: string;
    answer: string;
  } | null;
};

export type ArticleSeriesByArticleQueryQueryVariables = Exact<{
  articleSlug: Scalars['String']['input'];
}>;

export type ArticleSeriesByArticleQueryQuery = {
  __typename?: 'Query';
  articleSeriesByArticle?: Array<{
    __typename?: 'ArticleSeries';
    id?: string | null;
    title?: string | null;
    urlSlug?: string | null;
  } | null> | null;
};

export type SaveArticleReadStatusMutationVariables = Exact<{
  articleId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
}>;

export type SaveArticleReadStatusMutation = {
  __typename?: 'Mutation';
  saveArticleReadStatus?: {
    __typename?: 'SaveArticleReadStatus';
    ok?: boolean | null;
    articleReadStatus?: {
      __typename?: 'ArticleReadStatusType';
      id: string;
      readStatus?: string | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SavePromptAnswerMutationVariables = Exact<{
  entryId: Scalars['ID']['input'];
  answer: Scalars['String']['input'];
}>;

export type SavePromptAnswerMutation = {
  __typename?: 'Mutation';
  savePromptAnswer?: {
    __typename?: 'SavePromptAnswer';
    created?: boolean | null;
    promptAnswer?: {
      __typename?: 'PromptAnswerType';
      id: string;
      answer: string;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type BitPackagesQueryVariables = Exact<{ [key: string]: never }>;

export type BitPackagesQuery = {
  __typename?: 'Query';
  bitPackages?: Array<{
    __typename?: 'ProductType';
    id: string;
    name: string;
    msrp?: number | null;
    price?: number | null;
    creditPrice?: number | null;
    slogan?: string | null;
    description?: string | null;
    category?: ProductCategory | null;
    creditValue?: number | null;
    introProduct: boolean;
    slug: string;
  } | null> | null;
};

export type CheckoutQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CheckoutQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  paymentMethods?: Array<{
    __typename?: 'StripePaymentMethodType';
    stripeId?: string | null;
    brand?: string | null;
    last4?: string | null;
  } | null> | null;
  ledgerAccounts?: Array<{
    __typename?: 'LedgerAccountType';
    id: string;
    label?: string | null;
    balance: number;
  } | null> | null;
};

export type CheckoutOrderCoachFragmentFragment = {
  __typename?: 'AgentType';
  id: string;
  name?: string | null;
  photo?: string | null;
  activeCoachProducts?: Array<{
    __typename?: 'CoachProductType';
    id?: number | null;
    price?: number | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      introProduct: boolean;
      specialtyId?: string | null;
    } | null;
  } | null> | null;
};

export type CheckoutOrderFragmentFragment = {
  __typename?: 'OrderType';
  id: string;
  key?: string | null;
  grossTotal?: number | null;
  total?: number | null;
  grossCreditTotal?: number | null;
  creditTotal?: number | null;
  grossCreditTotalInDollars?: number | null;
  creditTotalInDollars?: number | null;
  stripeClientSecret?: string | null;
  paymentType?: string | null;
  promoDiscount?: number | null;
  promoCreditDiscount?: number | null;
  promoCreditTotalInDollarsDiscount?: number | null;
  ledgerAccountId?: string | null;
  ledgerAccount?: {
    __typename?: 'LedgerAccountType';
    id: string;
    label?: string | null;
    balance: number;
  } | null;
  orderItems?: Array<{
    __typename?: 'OrderItemType';
    id: string;
    grossTotal?: number | null;
    total?: number | null;
    price?: number | null;
    grossCreditTotal?: number | null;
    creditTotal?: number | null;
    grossCreditTotalInDollars?: number | null;
    creditTotalInDollars?: number | null;
    promoDiscount?: number | null;
    promoCreditDiscount?: number | null;
    promoCreditTotalInDollarsDiscount?: number | null;
    product: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      shortDescription?: string | null;
      introProduct: boolean;
      category?: ProductCategory | null;
      specialtyId?: string | null;
    };
  } | null> | null;
  promoCode?: { __typename?: 'PromoCodeType'; id: string; code: string } | null;
  user?: {
    __typename?: 'UserType';
    id: string;
    availableCredits?: any | null;
    receivableCredits?: any | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      balance: number;
      label?: string | null;
    } | null> | null;
  } | null;
};

export type ConfirmOrderMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethod?: InputMaybe<PaymentMethodTypes>;
  affirmCheckoutToken?: InputMaybe<Scalars['String']['input']>;
  ledgerAccountId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ConfirmOrderMutation = {
  __typename?: 'Mutation';
  confirmOrder?: {
    __typename?: 'ConfirmOrder';
    ok?: boolean | null;
    error?: { __typename?: 'ErrorType'; message?: string | null } | null;
  } | null;
};

export type CreateOrderMutationVariables = Exact<{
  productQuantities: Scalars['GenericScalar']['input'];
  withRecommendations: Scalars['Boolean']['input'];
  withCoachInfo: Scalars['Boolean']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateOrderMutation = {
  __typename?: 'Mutation';
  createOrder?: {
    __typename?: 'CreateOrder';
    order?: {
      __typename?: 'OrderType';
      id: string;
      key?: string | null;
      grossTotal?: number | null;
      total?: number | null;
      grossCreditTotal?: number | null;
      creditTotal?: number | null;
      grossCreditTotalInDollars?: number | null;
      creditTotalInDollars?: number | null;
      stripeClientSecret?: string | null;
      paymentType?: string | null;
      promoDiscount?: number | null;
      promoCreditDiscount?: number | null;
      promoCreditTotalInDollarsDiscount?: number | null;
      ledgerAccountId?: string | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        activeCoachProducts?: Array<{
          __typename?: 'CoachProductType';
          id?: number | null;
          price?: number | null;
          product?: {
            __typename?: 'ProductType';
            id: string;
            name: string;
            introProduct: boolean;
            specialtyId?: string | null;
          } | null;
        } | null> | null;
      } | null;
      recommendations?: Array<{
        __typename?: 'ProductType';
        id: string;
        category?: ProductCategory | null;
        name: string;
        shortDescription?: string | null;
        creditPrice?: number | null;
        price?: number | null;
      } | null> | null;
      ledgerAccount?: {
        __typename?: 'LedgerAccountType';
        id: string;
        label?: string | null;
        balance: number;
      } | null;
      orderItems?: Array<{
        __typename?: 'OrderItemType';
        id: string;
        grossTotal?: number | null;
        total?: number | null;
        price?: number | null;
        grossCreditTotal?: number | null;
        creditTotal?: number | null;
        grossCreditTotalInDollars?: number | null;
        creditTotalInDollars?: number | null;
        promoDiscount?: number | null;
        promoCreditDiscount?: number | null;
        promoCreditTotalInDollarsDiscount?: number | null;
        product: {
          __typename?: 'ProductType';
          id: string;
          name: string;
          slug: string;
          shortDescription?: string | null;
          introProduct: boolean;
          category?: ProductCategory | null;
          specialtyId?: string | null;
        };
      } | null> | null;
      promoCode?: {
        __typename?: 'PromoCodeType';
        id: string;
        code: string;
      } | null;
      user?: {
        __typename?: 'UserType';
        id: string;
        availableCredits?: any | null;
        receivableCredits?: any | null;
        ledgerAccounts?: Array<{
          __typename?: 'LedgerAccountType';
          id: string;
          balance: number;
          label?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type CreditPackagesQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreditPackagesQuery = {
  __typename?: 'Query';
  creditPackages?: Array<{
    __typename?: 'ProductType';
    id: string;
    name: string;
    msrp?: number | null;
    price?: number | null;
    slogan?: string | null;
    description?: string | null;
    category?: ProductCategory | null;
    creditValue?: number | null;
    introProduct: boolean;
    slug: string;
  } | null> | null;
};

export type OrderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type OrderQuery = {
  __typename?: 'Query';
  order?: {
    __typename?: 'OrderType';
    id: string;
    key?: string | null;
    grossTotal?: number | null;
    total?: number | null;
    grossCreditTotal?: number | null;
    creditTotal?: number | null;
    grossCreditTotalInDollars?: number | null;
    creditTotalInDollars?: number | null;
    stripeClientSecret?: string | null;
    paymentType?: string | null;
    promoDiscount?: number | null;
    promoCreditDiscount?: number | null;
    promoCreditTotalInDollarsDiscount?: number | null;
    ledgerAccountId?: string | null;
    workspace?: {
      __typename?: 'PublicWorkspaceGraphqlType';
      id: string;
      name: string;
      urlSlug: string;
    } | null;
    ledgerAccount?: {
      __typename?: 'LedgerAccountType';
      id: string;
      label?: string | null;
      balance: number;
    } | null;
    orderItems?: Array<{
      __typename?: 'OrderItemType';
      id: string;
      grossTotal?: number | null;
      total?: number | null;
      price?: number | null;
      grossCreditTotal?: number | null;
      creditTotal?: number | null;
      grossCreditTotalInDollars?: number | null;
      creditTotalInDollars?: number | null;
      promoDiscount?: number | null;
      promoCreditDiscount?: number | null;
      promoCreditTotalInDollarsDiscount?: number | null;
      product: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        shortDescription?: string | null;
        introProduct: boolean;
        category?: ProductCategory | null;
        specialtyId?: string | null;
      };
    } | null> | null;
    promoCode?: {
      __typename?: 'PromoCodeType';
      id: string;
      code: string;
    } | null;
    user?: {
      __typename?: 'UserType';
      id: string;
      availableCredits?: any | null;
      receivableCredits?: any | null;
      ledgerAccounts?: Array<{
        __typename?: 'LedgerAccountType';
        id: string;
        balance: number;
        label?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PendingOrderQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  pendingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  withRecommendations: Scalars['Boolean']['input'];
  withCoachInfo: Scalars['Boolean']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type PendingOrderQuery = {
  __typename?: 'Query';
  pendingOrder?: {
    __typename?: 'OrderType';
    id: string;
    key?: string | null;
    grossTotal?: number | null;
    total?: number | null;
    grossCreditTotal?: number | null;
    creditTotal?: number | null;
    grossCreditTotalInDollars?: number | null;
    creditTotalInDollars?: number | null;
    stripeClientSecret?: string | null;
    paymentType?: string | null;
    promoDiscount?: number | null;
    promoCreditDiscount?: number | null;
    promoCreditTotalInDollarsDiscount?: number | null;
    ledgerAccountId?: string | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
      activeCoachProducts?: Array<{
        __typename?: 'CoachProductType';
        id?: number | null;
        price?: number | null;
        product?: {
          __typename?: 'ProductType';
          id: string;
          name: string;
          introProduct: boolean;
          specialtyId?: string | null;
        } | null;
      } | null> | null;
    } | null;
    recommendations?: Array<{
      __typename?: 'ProductType';
      id: string;
      category?: ProductCategory | null;
      name: string;
      shortDescription?: string | null;
      creditPrice?: number | null;
      price?: number | null;
    } | null> | null;
    ledgerAccount?: {
      __typename?: 'LedgerAccountType';
      id: string;
      label?: string | null;
      balance: number;
    } | null;
    orderItems?: Array<{
      __typename?: 'OrderItemType';
      id: string;
      grossTotal?: number | null;
      total?: number | null;
      price?: number | null;
      grossCreditTotal?: number | null;
      creditTotal?: number | null;
      grossCreditTotalInDollars?: number | null;
      creditTotalInDollars?: number | null;
      promoDiscount?: number | null;
      promoCreditDiscount?: number | null;
      promoCreditTotalInDollarsDiscount?: number | null;
      product: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        shortDescription?: string | null;
        introProduct: boolean;
        category?: ProductCategory | null;
        specialtyId?: string | null;
      };
    } | null> | null;
    promoCode?: {
      __typename?: 'PromoCodeType';
      id: string;
      code: string;
    } | null;
    user?: {
      __typename?: 'UserType';
      id: string;
      availableCredits?: any | null;
      receivableCredits?: any | null;
      ledgerAccounts?: Array<{
        __typename?: 'LedgerAccountType';
        id: string;
        balance: number;
        label?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type RedeemPromoCodeMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  code: Scalars['String']['input'];
}>;

export type RedeemPromoCodeMutation = {
  __typename?: 'Mutation';
  redeemPromoCode?: {
    __typename?: 'RedeemPromoCode';
    ok?: boolean | null;
    error?: { __typename?: 'ErrorType'; message?: string | null } | null;
    order?: {
      __typename?: 'OrderType';
      id: string;
      key?: string | null;
      grossTotal?: number | null;
      total?: number | null;
      grossCreditTotal?: number | null;
      creditTotal?: number | null;
      grossCreditTotalInDollars?: number | null;
      creditTotalInDollars?: number | null;
      stripeClientSecret?: string | null;
      paymentType?: string | null;
      promoDiscount?: number | null;
      promoCreditDiscount?: number | null;
      promoCreditTotalInDollarsDiscount?: number | null;
      ledgerAccountId?: string | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        activeCoachProducts?: Array<{
          __typename?: 'CoachProductType';
          id?: number | null;
          price?: number | null;
          product?: {
            __typename?: 'ProductType';
            id: string;
            name: string;
            introProduct: boolean;
            specialtyId?: string | null;
          } | null;
        } | null> | null;
      } | null;
      ledgerAccount?: {
        __typename?: 'LedgerAccountType';
        id: string;
        label?: string | null;
        balance: number;
      } | null;
      orderItems?: Array<{
        __typename?: 'OrderItemType';
        id: string;
        grossTotal?: number | null;
        total?: number | null;
        price?: number | null;
        grossCreditTotal?: number | null;
        creditTotal?: number | null;
        grossCreditTotalInDollars?: number | null;
        creditTotalInDollars?: number | null;
        promoDiscount?: number | null;
        promoCreditDiscount?: number | null;
        promoCreditTotalInDollarsDiscount?: number | null;
        product: {
          __typename?: 'ProductType';
          id: string;
          name: string;
          slug: string;
          shortDescription?: string | null;
          introProduct: boolean;
          category?: ProductCategory | null;
          specialtyId?: string | null;
        };
      } | null> | null;
      promoCode?: {
        __typename?: 'PromoCodeType';
        id: string;
        code: string;
      } | null;
      user?: {
        __typename?: 'UserType';
        id: string;
        availableCredits?: any | null;
        receivableCredits?: any | null;
        ledgerAccounts?: Array<{
          __typename?: 'LedgerAccountType';
          id: string;
          balance: number;
          label?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type SetExtraCreditsMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  numCredits: Scalars['String']['input'];
}>;

export type SetExtraCreditsMutation = {
  __typename?: 'Mutation';
  setExtraCredits?: {
    __typename?: 'SetExtraCredits';
    ok?: boolean | null;
    error?: { __typename?: 'ErrorType'; message?: string | null } | null;
    order?: {
      __typename?: 'OrderType';
      id: string;
      key?: string | null;
      grossTotal?: number | null;
      total?: number | null;
      grossCreditTotal?: number | null;
      creditTotal?: number | null;
      grossCreditTotalInDollars?: number | null;
      creditTotalInDollars?: number | null;
      stripeClientSecret?: string | null;
      paymentType?: string | null;
      promoDiscount?: number | null;
      promoCreditDiscount?: number | null;
      promoCreditTotalInDollarsDiscount?: number | null;
      ledgerAccountId?: string | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        activeCoachProducts?: Array<{
          __typename?: 'CoachProductType';
          id?: number | null;
          price?: number | null;
          product?: {
            __typename?: 'ProductType';
            id: string;
            name: string;
            introProduct: boolean;
            specialtyId?: string | null;
          } | null;
        } | null> | null;
      } | null;
      ledgerAccount?: {
        __typename?: 'LedgerAccountType';
        id: string;
        label?: string | null;
        balance: number;
      } | null;
      orderItems?: Array<{
        __typename?: 'OrderItemType';
        id: string;
        grossTotal?: number | null;
        total?: number | null;
        price?: number | null;
        grossCreditTotal?: number | null;
        creditTotal?: number | null;
        grossCreditTotalInDollars?: number | null;
        creditTotalInDollars?: number | null;
        promoDiscount?: number | null;
        promoCreditDiscount?: number | null;
        promoCreditTotalInDollarsDiscount?: number | null;
        product: {
          __typename?: 'ProductType';
          id: string;
          name: string;
          slug: string;
          shortDescription?: string | null;
          introProduct: boolean;
          category?: ProductCategory | null;
          specialtyId?: string | null;
        };
      } | null> | null;
      promoCode?: {
        __typename?: 'PromoCodeType';
        id: string;
        code: string;
      } | null;
      user?: {
        __typename?: 'UserType';
        id: string;
        availableCredits?: any | null;
        receivableCredits?: any | null;
        ledgerAccounts?: Array<{
          __typename?: 'LedgerAccountType';
          id: string;
          balance: number;
          label?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateOrderMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  withRecommendations: Scalars['Boolean']['input'];
  withCoachInfo: Scalars['Boolean']['input'];
  productQuantities: Scalars['GenericScalar']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateOrderMutation = {
  __typename?: 'Mutation';
  updateOrder?: {
    __typename?: 'UpdateOrder';
    order?: {
      __typename?: 'OrderType';
      id: string;
      key?: string | null;
      grossTotal?: number | null;
      total?: number | null;
      grossCreditTotal?: number | null;
      creditTotal?: number | null;
      grossCreditTotalInDollars?: number | null;
      creditTotalInDollars?: number | null;
      stripeClientSecret?: string | null;
      paymentType?: string | null;
      promoDiscount?: number | null;
      promoCreditDiscount?: number | null;
      promoCreditTotalInDollarsDiscount?: number | null;
      ledgerAccountId?: string | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        activeCoachProducts?: Array<{
          __typename?: 'CoachProductType';
          id?: number | null;
          price?: number | null;
          product?: {
            __typename?: 'ProductType';
            id: string;
            name: string;
            introProduct: boolean;
            specialtyId?: string | null;
          } | null;
        } | null> | null;
      } | null;
      recommendations?: Array<{
        __typename?: 'ProductType';
        id: string;
        category?: ProductCategory | null;
        name: string;
        shortDescription?: string | null;
        creditPrice?: number | null;
        price?: number | null;
      } | null> | null;
      ledgerAccount?: {
        __typename?: 'LedgerAccountType';
        id: string;
        label?: string | null;
        balance: number;
      } | null;
      orderItems?: Array<{
        __typename?: 'OrderItemType';
        id: string;
        grossTotal?: number | null;
        total?: number | null;
        price?: number | null;
        grossCreditTotal?: number | null;
        creditTotal?: number | null;
        grossCreditTotalInDollars?: number | null;
        creditTotalInDollars?: number | null;
        promoDiscount?: number | null;
        promoCreditDiscount?: number | null;
        promoCreditTotalInDollarsDiscount?: number | null;
        product: {
          __typename?: 'ProductType';
          id: string;
          name: string;
          slug: string;
          shortDescription?: string | null;
          introProduct: boolean;
          category?: ProductCategory | null;
          specialtyId?: string | null;
        };
      } | null> | null;
      promoCode?: {
        __typename?: 'PromoCodeType';
        id: string;
        code: string;
      } | null;
      user?: {
        __typename?: 'UserType';
        id: string;
        availableCredits?: any | null;
        receivableCredits?: any | null;
        ledgerAccounts?: Array<{
          __typename?: 'LedgerAccountType';
          id: string;
          balance: number;
          label?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type AccountLinkMutationVariables = Exact<{
  returnUrl: Scalars['String']['input'];
}>;

export type AccountLinkMutation = {
  __typename?: 'Mutation';
  accountLink?: {
    __typename?: 'AccountLink';
    url?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type ActiveCoachProductsForCustomerQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
  coachId: Scalars['ID']['input'];
}>;

export type ActiveCoachProductsForCustomerQuery = {
  __typename?: 'Query';
  activeCoachProductsForCustomer?: Array<{
    __typename?: 'CoachProductType';
    id?: number | null;
    price?: number | null;
    locked?: boolean | null;
    product?: { __typename?: 'ProductType'; id: string } | null;
  } | null> | null;
};

export type BookReviewMutationVariables = Exact<{
  productId: Scalars['ID']['input'];
  coachId: Scalars['ID']['input'];
  formData?: InputMaybe<Scalars['GenericScalar']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
  ledgerAccountId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type BookReviewMutation = {
  __typename?: 'Mutation';
  bookReview?: {
    __typename?: 'BookReview';
    review?: { __typename?: 'ReviewType'; id: string } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CancelUnpaidOrderMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CancelUnpaidOrderMutation = {
  __typename?: 'Mutation';
  cancelUnpaidOrder?: {
    __typename?: 'CancelUnpaidOrder';
    ok?: boolean | null;
  } | null;
};

export type CoachingDashboardQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CoachingDashboardQuery = {
  __typename?: 'Query';
  assignments?: Array<{
    __typename?: 'AssignmentType';
    id: string;
    chatChannelId?: string | null;
    workspace?: { __typename?: 'WorkspaceType'; id: string } | null;
    coach?: {
      __typename?: 'MiniCoachType';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
      coachSlug?: string | null;
    } | null;
  } | null> | null;
  customer?: {
    __typename?: 'UserType';
    id: string;
    availableCredits?: any | null;
    receivableCredits?: any | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      balance: number;
      label?: string | null;
    } | null> | null;
    upcomingMeetings?: Array<{
      __typename?: 'MeetingType';
      id: string;
      chatChannelId?: string | null;
      numMessages?: number | null;
      eventAt?: any | null;
      canceledAt?: any | null;
      cancelReason?: string | null;
      location?: string | null;
      customLocation?: string | null;
      duration?: number | null;
      customerId?: number | null;
      refundableUntil?: any | null;
      canLeaveRating?: boolean | null;
      paymentStatus?: PaymentStatusTypes | null;
      googleEventId?: string | null;
      creditPrice?: any | null;
      allowInvitation: boolean;
      allowGuests?: boolean | null;
      guests?: Array<string | null> | null;
      isBookingRequest?: boolean | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        coachSlug?: string | null;
        email: string;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
        email: string;
      } | null;
      attendee?: {
        __typename?: 'MeetingAttendeeType';
        id: string;
        userId?: number | null;
        status?: InvitationStatus | null;
        attendingStatus?: AttendingStatus | null;
        meetingId?: number | null;
      } | null;
      workspace?: {
        __typename?: 'WorkspaceType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        duration?: number | null;
        category?: ProductCategory | null;
        description?: string | null;
        introProduct: boolean;
        enableGuests?: boolean | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
    ongoingReviews?: Array<{
      __typename?: 'ReviewType';
      id: string;
      chatChannelId?: string | null;
      status?: string | null;
      state?: string | null;
      nextDate?: string | null;
      numMessages?: number | null;
      acceptanceDeadline?: any | null;
      acceptedAt?: any | null;
      finalCheckRevisionSentAt?: any | null;
      canceledAt?: any | null;
      completedAt?: any | null;
      numRounds: number;
      numRoundsComplete: number;
      customerId?: number | null;
      canLeaveRating?: boolean | null;
      creditPrice?: any | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        turnaroundTime?: number | null;
        includesFinalCheck: boolean;
        description?: string | null;
        numRounds?: number | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
    unfulfilledOrders?: Array<{
      __typename?: 'OrderType';
      id: string;
      paymentStatus: OrderPaymentStatus;
      cancelIfUnpaidBy?: any | null;
      workspace?: {
        __typename?: 'PublicWorkspaceGraphqlType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      orderItems?: Array<{
        __typename?: 'OrderItemType';
        id: string;
        fulfillmentStatus: FulfillmentStatusTypes;
        product: {
          __typename?: 'ProductType';
          id: string;
          name: string;
          category?: ProductCategory | null;
        };
        meetings: Array<{
          __typename?: 'ChatMeetingType';
          id: string;
          eventAt?: any | null;
          coach?: {
            __typename?: 'AgentType';
            name?: string | null;
            photo?: string | null;
            email: string;
          } | null;
        }>;
        reviews: Array<{
          __typename?: 'ChatReviewType';
          id: string;
          coach?: {
            __typename?: 'AgentType';
            name?: string | null;
            photo?: string | null;
            email: string;
          } | null;
        }>;
      } | null> | null;
    } | null> | null;
    bookingRequestMeetings?: Array<{
      __typename?: 'MeetingType';
      id: string;
      chatChannelId?: string | null;
      numMessages?: number | null;
      eventAt?: any | null;
      canceledAt?: any | null;
      cancelReason?: string | null;
      location?: string | null;
      customLocation?: string | null;
      duration?: number | null;
      customerId?: number | null;
      refundableUntil?: any | null;
      canLeaveRating?: boolean | null;
      paymentStatus?: PaymentStatusTypes | null;
      googleEventId?: string | null;
      creditPrice?: any | null;
      allowInvitation: boolean;
      allowGuests?: boolean | null;
      guests?: Array<string | null> | null;
      isBookingRequest?: boolean | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        coachSlug?: string | null;
        email: string;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
        email: string;
      } | null;
      attendee?: {
        __typename?: 'MeetingAttendeeType';
        id: string;
        userId?: number | null;
        status?: InvitationStatus | null;
        attendingStatus?: AttendingStatus | null;
        meetingId?: number | null;
      } | null;
      workspace?: {
        __typename?: 'WorkspaceType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        duration?: number | null;
        category?: ProductCategory | null;
        description?: string | null;
        introProduct: boolean;
        enableGuests?: boolean | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
    configuration?: {
      __typename?: 'ConfigurationType';
      configId?: string | null;
      version?: string | null;
      specialtyIncludeIds?: Array<string | null> | null;
      specialtyExcludeIds?: Array<string | null> | null;
      enableJobSearch?: boolean | null;
      enableReviews?: boolean | null;
    } | null;
    livePrograms?: {
      __typename?: 'ProgramQueryResult';
      data?: Array<{
        __typename?: 'ProgramMiniType';
        id: string;
        name: string;
        status?: ProgramStatus | null;
        targetLaunchDate?: any | null;
        targetEndDate?: any | null;
        isProgramLead?: boolean | null;
        isProgramParticipant?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    photo?: string | null;
    isAgent: boolean;
    timeZone?: string | null;
    availableCredits?: any | null;
    receivableCredits?: any | null;
    agentProfile?: {
      __typename?: 'AgentProfileType';
      id: string;
      coachSlug: string;
      paymentOnboardingReady?: boolean | null;
      payoutChannel?: string | null;
      bufferAfter?: number | null;
      bufferBefore?: number | null;
      minuteIncrement?: number | null;
      minimumMeetingNotice: number;
    } | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      balance: number;
      label?: string | null;
    } | null> | null;
    upcomingMeetings?: Array<{
      __typename?: 'MeetingType';
      id: string;
      chatChannelId?: string | null;
      numMessages?: number | null;
      eventAt?: any | null;
      canceledAt?: any | null;
      cancelReason?: string | null;
      location?: string | null;
      customLocation?: string | null;
      duration?: number | null;
      customerId?: number | null;
      refundableUntil?: any | null;
      canLeaveRating?: boolean | null;
      paymentStatus?: PaymentStatusTypes | null;
      googleEventId?: string | null;
      creditPrice?: any | null;
      allowInvitation: boolean;
      allowGuests?: boolean | null;
      guests?: Array<string | null> | null;
      isBookingRequest?: boolean | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        coachSlug?: string | null;
        email: string;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
        email: string;
      } | null;
      attendee?: {
        __typename?: 'MeetingAttendeeType';
        id: string;
        userId?: number | null;
        status?: InvitationStatus | null;
        attendingStatus?: AttendingStatus | null;
        meetingId?: number | null;
      } | null;
      workspace?: {
        __typename?: 'WorkspaceType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        duration?: number | null;
        category?: ProductCategory | null;
        description?: string | null;
        introProduct: boolean;
        enableGuests?: boolean | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
    ongoingReviews?: Array<{
      __typename?: 'ReviewType';
      id: string;
      chatChannelId?: string | null;
      status?: string | null;
      state?: string | null;
      nextDate?: string | null;
      numMessages?: number | null;
      acceptanceDeadline?: any | null;
      acceptedAt?: any | null;
      finalCheckRevisionSentAt?: any | null;
      canceledAt?: any | null;
      completedAt?: any | null;
      numRounds: number;
      numRoundsComplete: number;
      customerId?: number | null;
      canLeaveRating?: boolean | null;
      creditPrice?: any | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        turnaroundTime?: number | null;
        includesFinalCheck: boolean;
        description?: string | null;
        numRounds?: number | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
    unfulfilledOrders?: Array<{
      __typename?: 'OrderType';
      id: string;
      paymentStatus: OrderPaymentStatus;
      cancelIfUnpaidBy?: any | null;
      workspace?: {
        __typename?: 'PublicWorkspaceGraphqlType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      orderItems?: Array<{
        __typename?: 'OrderItemType';
        id: string;
        fulfillmentStatus: FulfillmentStatusTypes;
        product: {
          __typename?: 'ProductType';
          id: string;
          name: string;
          category?: ProductCategory | null;
        };
        meetings: Array<{
          __typename?: 'ChatMeetingType';
          id: string;
          eventAt?: any | null;
          coach?: {
            __typename?: 'AgentType';
            name?: string | null;
            photo?: string | null;
            email: string;
          } | null;
        }>;
        reviews: Array<{
          __typename?: 'ChatReviewType';
          id: string;
          coach?: {
            __typename?: 'AgentType';
            name?: string | null;
            photo?: string | null;
            email: string;
          } | null;
        }>;
      } | null> | null;
    } | null> | null;
    bookingRequestMeetings?: Array<{
      __typename?: 'MeetingType';
      id: string;
      chatChannelId?: string | null;
      numMessages?: number | null;
      eventAt?: any | null;
      canceledAt?: any | null;
      cancelReason?: string | null;
      location?: string | null;
      customLocation?: string | null;
      duration?: number | null;
      customerId?: number | null;
      refundableUntil?: any | null;
      canLeaveRating?: boolean | null;
      paymentStatus?: PaymentStatusTypes | null;
      googleEventId?: string | null;
      creditPrice?: any | null;
      allowInvitation: boolean;
      allowGuests?: boolean | null;
      guests?: Array<string | null> | null;
      isBookingRequest?: boolean | null;
      coach?: {
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
        photo?: string | null;
        coachSlug?: string | null;
        email: string;
      } | null;
      customer?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        photo?: string | null;
        email: string;
      } | null;
      attendee?: {
        __typename?: 'MeetingAttendeeType';
        id: string;
        userId?: number | null;
        status?: InvitationStatus | null;
        attendingStatus?: AttendingStatus | null;
        meetingId?: number | null;
      } | null;
      workspace?: {
        __typename?: 'WorkspaceType';
        id: string;
        name: string;
        urlSlug: string;
      } | null;
      product?: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        slug: string;
        duration?: number | null;
        category?: ProductCategory | null;
        description?: string | null;
        introProduct: boolean;
        enableGuests?: boolean | null;
      } | null;
      rating?: {
        __typename?: 'RatingType';
        id: string;
        score: number;
        comment?: string | null;
        privateComment?: string | null;
      } | null;
    } | null> | null;
    configuration?: {
      __typename?: 'ConfigurationType';
      configId?: string | null;
      version?: string | null;
      specialtyIncludeIds?: Array<string | null> | null;
      specialtyExcludeIds?: Array<string | null> | null;
      enableJobSearch?: boolean | null;
      enableReviews?: boolean | null;
    } | null;
    livePrograms?: {
      __typename?: 'ProgramQueryResult';
      data?: Array<{
        __typename?: 'ProgramMiniType';
        id: string;
        name: string;
        status?: ProgramStatus | null;
        targetLaunchDate?: any | null;
        targetEndDate?: any | null;
        isProgramLead?: boolean | null;
        isProgramParticipant?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UserAvailableCreditsFragmentFragment = {
  __typename?: 'UserType';
  availableCredits?: any | null;
  receivableCredits?: any | null;
  ledgerAccounts?: Array<{
    __typename?: 'LedgerAccountType';
    id: string;
    balance: number;
    label?: string | null;
  } | null> | null;
};

export type UserUpcomingMeetingsFragmentFragment = {
  __typename?: 'UserType';
  upcomingMeetings?: Array<{
    __typename?: 'MeetingType';
    id: string;
    chatChannelId?: string | null;
    numMessages?: number | null;
    eventAt?: any | null;
    canceledAt?: any | null;
    cancelReason?: string | null;
    location?: string | null;
    customLocation?: string | null;
    duration?: number | null;
    customerId?: number | null;
    refundableUntil?: any | null;
    canLeaveRating?: boolean | null;
    paymentStatus?: PaymentStatusTypes | null;
    googleEventId?: string | null;
    creditPrice?: any | null;
    allowInvitation: boolean;
    allowGuests?: boolean | null;
    guests?: Array<string | null> | null;
    isBookingRequest?: boolean | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
      coachSlug?: string | null;
      email: string;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
      email: string;
    } | null;
    attendee?: {
      __typename?: 'MeetingAttendeeType';
      id: string;
      userId?: number | null;
      status?: InvitationStatus | null;
      attendingStatus?: AttendingStatus | null;
      meetingId?: number | null;
    } | null;
    workspace?: {
      __typename?: 'WorkspaceType';
      id: string;
      name: string;
      urlSlug: string;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      duration?: number | null;
      category?: ProductCategory | null;
      description?: string | null;
      introProduct: boolean;
      enableGuests?: boolean | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null> | null;
};

export type UserOngoingReviewsFragmentFragment = {
  __typename?: 'UserType';
  ongoingReviews?: Array<{
    __typename?: 'ReviewType';
    id: string;
    chatChannelId?: string | null;
    status?: string | null;
    state?: string | null;
    nextDate?: string | null;
    numMessages?: number | null;
    acceptanceDeadline?: any | null;
    acceptedAt?: any | null;
    finalCheckRevisionSentAt?: any | null;
    canceledAt?: any | null;
    completedAt?: any | null;
    numRounds: number;
    numRoundsComplete: number;
    customerId?: number | null;
    canLeaveRating?: boolean | null;
    creditPrice?: any | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      turnaroundTime?: number | null;
      includesFinalCheck: boolean;
      description?: string | null;
      numRounds?: number | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null> | null;
};

export type UserLiveProgramsFragmentFragment = {
  __typename?: 'UserType';
  livePrograms?: {
    __typename?: 'ProgramQueryResult';
    data?: Array<{
      __typename?: 'ProgramMiniType';
      id: string;
      name: string;
      status?: ProgramStatus | null;
      targetLaunchDate?: any | null;
      targetEndDate?: any | null;
      isProgramLead?: boolean | null;
      isProgramParticipant?: boolean | null;
    } | null> | null;
  } | null;
};

export type UserReviewsFragmentFragment = {
  __typename?: 'UserType';
  reviews?: Array<{
    __typename?: 'ReviewType';
    id: string;
    chatChannelId?: string | null;
    status?: string | null;
    state?: string | null;
    nextDate?: string | null;
    numMessages?: number | null;
    acceptanceDeadline?: any | null;
    acceptedAt?: any | null;
    finalCheckRevisionSentAt?: any | null;
    canceledAt?: any | null;
    completedAt?: any | null;
    numRounds: number;
    numRoundsComplete: number;
    customerId?: number | null;
    canLeaveRating?: boolean | null;
    creditPrice?: any | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      turnaroundTime?: number | null;
      includesFinalCheck: boolean;
      description?: string | null;
      numRounds?: number | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null> | null;
};

export type UserMeetingsFragmentFragment = {
  __typename?: 'UserType';
  meetings?: Array<{
    __typename?: 'MeetingType';
    id: string;
    chatChannelId?: string | null;
    numMessages?: number | null;
    eventAt?: any | null;
    canceledAt?: any | null;
    cancelReason?: string | null;
    location?: string | null;
    customLocation?: string | null;
    duration?: number | null;
    customerId?: number | null;
    refundableUntil?: any | null;
    canLeaveRating?: boolean | null;
    paymentStatus?: PaymentStatusTypes | null;
    googleEventId?: string | null;
    creditPrice?: any | null;
    allowInvitation: boolean;
    allowGuests?: boolean | null;
    guests?: Array<string | null> | null;
    isBookingRequest?: boolean | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
      coachSlug?: string | null;
      email: string;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
      email: string;
    } | null;
    attendee?: {
      __typename?: 'MeetingAttendeeType';
      id: string;
      userId?: number | null;
      status?: InvitationStatus | null;
      attendingStatus?: AttendingStatus | null;
      meetingId?: number | null;
    } | null;
    workspace?: {
      __typename?: 'WorkspaceType';
      id: string;
      name: string;
      urlSlug: string;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      duration?: number | null;
      category?: ProductCategory | null;
      description?: string | null;
      introProduct: boolean;
      enableGuests?: boolean | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null> | null;
};

export type UserBookingRequestMeetingsFragmentFragment = {
  __typename?: 'UserType';
  bookingRequestMeetings?: Array<{
    __typename?: 'MeetingType';
    id: string;
    chatChannelId?: string | null;
    numMessages?: number | null;
    eventAt?: any | null;
    canceledAt?: any | null;
    cancelReason?: string | null;
    location?: string | null;
    customLocation?: string | null;
    duration?: number | null;
    customerId?: number | null;
    refundableUntil?: any | null;
    canLeaveRating?: boolean | null;
    paymentStatus?: PaymentStatusTypes | null;
    googleEventId?: string | null;
    creditPrice?: any | null;
    allowInvitation: boolean;
    allowGuests?: boolean | null;
    guests?: Array<string | null> | null;
    isBookingRequest?: boolean | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
      coachSlug?: string | null;
      email: string;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
      email: string;
    } | null;
    attendee?: {
      __typename?: 'MeetingAttendeeType';
      id: string;
      userId?: number | null;
      status?: InvitationStatus | null;
      attendingStatus?: AttendingStatus | null;
      meetingId?: number | null;
    } | null;
    workspace?: {
      __typename?: 'WorkspaceType';
      id: string;
      name: string;
      urlSlug: string;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      duration?: number | null;
      category?: ProductCategory | null;
      description?: string | null;
      introProduct: boolean;
      enableGuests?: boolean | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null> | null;
};

export type UserUnfulfilledOrdersFragmentFragment = {
  __typename?: 'UserType';
  unfulfilledOrders?: Array<{
    __typename?: 'OrderType';
    id: string;
    paymentStatus: OrderPaymentStatus;
    cancelIfUnpaidBy?: any | null;
    workspace?: {
      __typename?: 'PublicWorkspaceGraphqlType';
      id: string;
      name: string;
      urlSlug: string;
    } | null;
    orderItems?: Array<{
      __typename?: 'OrderItemType';
      id: string;
      fulfillmentStatus: FulfillmentStatusTypes;
      product: {
        __typename?: 'ProductType';
        id: string;
        name: string;
        category?: ProductCategory | null;
      };
      meetings: Array<{
        __typename?: 'ChatMeetingType';
        id: string;
        eventAt?: any | null;
        coach?: {
          __typename?: 'AgentType';
          name?: string | null;
          photo?: string | null;
          email: string;
        } | null;
      }>;
      reviews: Array<{
        __typename?: 'ChatReviewType';
        id: string;
        coach?: {
          __typename?: 'AgentType';
          name?: string | null;
          photo?: string | null;
          email: string;
        } | null;
      }>;
    } | null> | null;
  } | null> | null;
};

export type UserConfigurationFragmentFragment = {
  __typename?: 'UserType';
  configuration?: {
    __typename?: 'ConfigurationType';
    configId?: string | null;
    version?: string | null;
    specialtyIncludeIds?: Array<string | null> | null;
    specialtyExcludeIds?: Array<string | null> | null;
    enableJobSearch?: boolean | null;
    enableReviews?: boolean | null;
  } | null;
};

export type MeetingFragmentFragment = {
  __typename?: 'MeetingType';
  id: string;
  chatChannelId?: string | null;
  numMessages?: number | null;
  eventAt?: any | null;
  canceledAt?: any | null;
  cancelReason?: string | null;
  location?: string | null;
  customLocation?: string | null;
  duration?: number | null;
  customerId?: number | null;
  refundableUntil?: any | null;
  canLeaveRating?: boolean | null;
  paymentStatus?: PaymentStatusTypes | null;
  googleEventId?: string | null;
  creditPrice?: any | null;
  allowInvitation: boolean;
  allowGuests?: boolean | null;
  guests?: Array<string | null> | null;
  isBookingRequest?: boolean | null;
  coach?: {
    __typename?: 'AgentType';
    id: string;
    name?: string | null;
    photo?: string | null;
    coachSlug?: string | null;
    email: string;
  } | null;
  customer?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    photo?: string | null;
    email: string;
  } | null;
  attendee?: {
    __typename?: 'MeetingAttendeeType';
    id: string;
    userId?: number | null;
    status?: InvitationStatus | null;
    attendingStatus?: AttendingStatus | null;
    meetingId?: number | null;
  } | null;
  workspace?: {
    __typename?: 'WorkspaceType';
    id: string;
    name: string;
    urlSlug: string;
  } | null;
  product?: {
    __typename?: 'ProductType';
    id: string;
    name: string;
    slug: string;
    duration?: number | null;
    category?: ProductCategory | null;
    description?: string | null;
    introProduct: boolean;
    enableGuests?: boolean | null;
  } | null;
  rating?: {
    __typename?: 'RatingType';
    id: string;
    score: number;
    comment?: string | null;
    privateComment?: string | null;
  } | null;
};

export type MeetingWithFormFragmentFragment = {
  __typename?: 'MeetingType';
  expiredAt?: any | null;
  id: string;
  chatChannelId?: string | null;
  numMessages?: number | null;
  eventAt?: any | null;
  canceledAt?: any | null;
  cancelReason?: string | null;
  location?: string | null;
  customLocation?: string | null;
  duration?: number | null;
  customerId?: number | null;
  refundableUntil?: any | null;
  canLeaveRating?: boolean | null;
  paymentStatus?: PaymentStatusTypes | null;
  googleEventId?: string | null;
  creditPrice?: any | null;
  allowInvitation: boolean;
  allowGuests?: boolean | null;
  guests?: Array<string | null> | null;
  isBookingRequest?: boolean | null;
  formFieldAnswers?: Array<{
    __typename?: 'AnswerType';
    id: string;
    value?: string | null;
    userFile?: {
      __typename?: 'UserFileType';
      id: string;
      name?: string | null;
      url?: string | null;
    } | null;
    optionValue?: {
      __typename?: 'FormFieldOptionAnalyticsType';
      id: string;
      value: string;
      label: string;
    } | null;
    formField?: {
      __typename?: 'FieldType';
      id: string;
      prompt: string;
      answerType: FormFieldAnswerType;
    } | null;
  } | null> | null;
  coach?: {
    __typename?: 'AgentType';
    id: string;
    name?: string | null;
    photo?: string | null;
    coachSlug?: string | null;
    email: string;
  } | null;
  customer?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    photo?: string | null;
    email: string;
  } | null;
  attendee?: {
    __typename?: 'MeetingAttendeeType';
    id: string;
    userId?: number | null;
    status?: InvitationStatus | null;
    attendingStatus?: AttendingStatus | null;
    meetingId?: number | null;
  } | null;
  workspace?: {
    __typename?: 'WorkspaceType';
    id: string;
    name: string;
    urlSlug: string;
  } | null;
  product?: {
    __typename?: 'ProductType';
    id: string;
    name: string;
    slug: string;
    duration?: number | null;
    category?: ProductCategory | null;
    description?: string | null;
    introProduct: boolean;
    enableGuests?: boolean | null;
  } | null;
  rating?: {
    __typename?: 'RatingType';
    id: string;
    score: number;
    comment?: string | null;
    privateComment?: string | null;
  } | null;
};

export type ReviewFragmentFragment = {
  __typename?: 'ReviewType';
  id: string;
  chatChannelId?: string | null;
  status?: string | null;
  state?: string | null;
  nextDate?: string | null;
  numMessages?: number | null;
  acceptanceDeadline?: any | null;
  acceptedAt?: any | null;
  finalCheckRevisionSentAt?: any | null;
  canceledAt?: any | null;
  completedAt?: any | null;
  numRounds: number;
  numRoundsComplete: number;
  customerId?: number | null;
  canLeaveRating?: boolean | null;
  creditPrice?: any | null;
  coach?: {
    __typename?: 'AgentType';
    id: string;
    name?: string | null;
    photo?: string | null;
  } | null;
  customer?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    photo?: string | null;
  } | null;
  product?: {
    __typename?: 'ProductType';
    id: string;
    name: string;
    slug: string;
    turnaroundTime?: number | null;
    includesFinalCheck: boolean;
    description?: string | null;
    numRounds?: number | null;
  } | null;
  rating?: {
    __typename?: 'RatingType';
    id: string;
    score: number;
    comment?: string | null;
    privateComment?: string | null;
  } | null;
};

export type ReviewWithFormFragmentFragment = {
  __typename?: 'ReviewType';
  id: string;
  chatChannelId?: string | null;
  status?: string | null;
  state?: string | null;
  nextDate?: string | null;
  numMessages?: number | null;
  acceptanceDeadline?: any | null;
  acceptedAt?: any | null;
  finalCheckRevisionSentAt?: any | null;
  canceledAt?: any | null;
  completedAt?: any | null;
  numRounds: number;
  numRoundsComplete: number;
  customerId?: number | null;
  canLeaveRating?: boolean | null;
  creditPrice?: any | null;
  formFieldAnswers?: Array<{
    __typename?: 'AnswerType';
    id: string;
    value?: string | null;
    userFile?: {
      __typename?: 'UserFileType';
      id: string;
      name?: string | null;
      url?: string | null;
    } | null;
    optionValue?: {
      __typename?: 'FormFieldOptionAnalyticsType';
      id: string;
      value: string;
      label: string;
    } | null;
    formField?: { __typename?: 'FieldType'; id: string; prompt: string } | null;
  } | null> | null;
  coach?: {
    __typename?: 'AgentType';
    id: string;
    name?: string | null;
    photo?: string | null;
  } | null;
  customer?: {
    __typename?: 'UserType';
    id: string;
    name?: string | null;
    photo?: string | null;
  } | null;
  product?: {
    __typename?: 'ProductType';
    id: string;
    name: string;
    slug: string;
    turnaroundTime?: number | null;
    includesFinalCheck: boolean;
    description?: string | null;
    numRounds?: number | null;
  } | null;
  rating?: {
    __typename?: 'RatingType';
    id: string;
    score: number;
    comment?: string | null;
    privateComment?: string | null;
  } | null;
};

export type ReviewRoundsFragmentFragment = {
  __typename?: 'ReviewType';
  rounds?: Array<{
    __typename?: 'ReviewRoundType';
    id: string;
    completedAt?: any | null;
    revisionSentAt?: any | null;
  } | null> | null;
};

export type AcceptingClientsQueryVariables = Exact<{ [key: string]: never }>;

export type AcceptingClientsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    agentProfile?: {
      __typename?: 'AgentProfileType';
      id: string;
      acceptingNewClients: boolean;
      acceptingNewBookings?: boolean | null;
    } | null;
  } | null;
};

export type AuthCalendarAccountMutationVariables = Exact<{
  authCode: Scalars['String']['input'];
  redirectUri?: InputMaybe<Scalars['String']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  accountType: Scalars['String']['input'];
}>;

export type AuthCalendarAccountMutation = {
  __typename?: 'Mutation';
  authCalendarAccount?: {
    __typename?: 'AuthCalendarAccount';
    calendarAccount?: {
      __typename?: 'CalendarAccountType';
      id: string;
      accountEmail?: string | null;
      scopes?: Array<string | null> | null;
      accountType: CalendarAccountAccountType;
      calendars?: Array<{
        __typename?: 'CalendarType';
        id?: string | null;
        name?: string | null;
        calendarId?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CalendarsQueryVariables = Exact<{
  calendarAccountId: Scalars['ID']['input'];
}>;

export type CalendarsQuery = {
  __typename?: 'Query';
  calendars?: Array<{
    __typename?: 'CalendarQueryType';
    id?: string | null;
    name?: string | null;
    calendarId?: string | null;
    isPrimary?: boolean | null;
  } | null> | null;
};

export type SetExternalCalendarMutationVariables = Exact<{
  calendarAccountId: Scalars['ID']['input'];
  calendars?: InputMaybe<
    Array<InputMaybe<CalendarInputType>> | InputMaybe<CalendarInputType>
  >;
}>;

export type SetExternalCalendarMutation = {
  __typename?: 'Mutation';
  setExternalCalendar?: {
    __typename?: 'SetExternalCalendar';
    ok?: boolean | null;
    error?: { __typename?: 'ErrorType'; message?: string | null } | null;
  } | null;
};

export type CalendarAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type CalendarAccountsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    primaryCalendarAccount?: {
      __typename?: 'CalendarAccountType';
      id: string;
      accountEmail?: string | null;
      scopes?: Array<string | null> | null;
      accountType: CalendarAccountAccountType;
      error?: string | null;
      calendars?: Array<{
        __typename?: 'CalendarType';
        id?: string | null;
        name?: string | null;
        calendarId?: string | null;
        error?: string | null;
      } | null> | null;
    } | null;
    otherCalendarAccounts?: Array<{
      __typename?: 'CalendarAccountType';
      id: string;
      accountEmail?: string | null;
      scopes?: Array<string | null> | null;
      accountType: CalendarAccountAccountType;
      error?: string | null;
      calendars?: Array<{
        __typename?: 'CalendarType';
        id?: string | null;
        name?: string | null;
        calendarId?: string | null;
        error?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CalendarSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type CalendarSettingsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    timeZone?: string | null;
    calendarSetting?: {
      __typename?: 'CalendarSettingGraphqlType';
      mondayTimeslots?: Array<string | null> | null;
      tuesdayTimeslots?: Array<string | null> | null;
      wednesdayTimeslots?: Array<string | null> | null;
      thursdayTimeslots?: Array<string | null> | null;
      fridayTimeslots?: Array<string | null> | null;
      saturdayTimeslots?: Array<string | null> | null;
      sundayTimeslots?: Array<string | null> | null;
    } | null;
  } | null;
};

export type CoachCustomersQueryVariables = Exact<{
  productId?: InputMaybe<Scalars['ID']['input']>;
  includeCustomersOfOtherProducts?: InputMaybe<Scalars['Boolean']['input']>;
  excludeWorkspaceCustomers?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CoachCustomersQuery = {
  __typename?: 'Query';
  coachCustomers?: Array<{
    __typename?: 'CoachCustomerType';
    userId?: number | null;
    userName?: string | null;
    userPhoto?: string | null;
    userEmail?: string | null;
    lastPurchaseAt?: any | null;
    currentPriceOption?: {
      __typename?: 'CustomPriceOptionType';
      id?: number | null;
      price?: number | null;
      coachCreditValue?: any | null;
    } | null;
  } | null> | null;
};

export type CoachProductQueryVariables = Exact<{
  productSlug: Scalars['String']['input'];
}>;

export type CoachProductQuery = {
  __typename?: 'Query';
  coachProduct?: {
    __typename?: 'CoachProductType';
    id?: number | null;
    enabled: boolean;
    maxPrice?: number | null;
    coachCreditValue?: any | null;
    price?: number | null;
    acceptingNewClients?: boolean | null;
    requireBookingRequest?: boolean | null;
    validPrices?: Array<number | null> | null;
    invalidPrices?: Array<number | null> | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      description?: string | null;
      creditPrice?: number | null;
      enableRfq: boolean;
      enableBookingRequest: boolean;
      slug: string;
      specialty?: {
        __typename?: 'SpecialtyType';
        id: string;
        name: string;
      } | null;
    } | null;
  } | null;
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    agentProfile?: {
      __typename?: 'AgentProfileType';
      id: string;
      coachSlug: string;
    } | null;
  } | null;
};

export type CoachWorkspacePricesQueryVariables = Exact<{
  productId: Scalars['ID']['input'];
}>;

export type CoachWorkspacePricesQuery = {
  __typename?: 'Query';
  coachWorkspacePrices?: Array<{
    __typename?: 'CustomPriceOptionType';
    id?: number | null;
    price?: number | null;
    coachCreditValue?: any | null;
    workspace?: {
      __typename?: 'WorkspaceType';
      id: string;
      name: string;
    } | null;
  } | null> | null;
};

export type SetCoachProductPriceMutationVariables = Exact<{
  productId: Scalars['Int']['input'];
  price: Scalars['Decimal']['input'];
  legacyCustomerIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >;
}>;

export type SetCoachProductPriceMutation = {
  __typename?: 'Mutation';
  setCoachProductPrice?: {
    __typename?: 'SetCoachProductPrice';
    ok?: boolean | null;
    coachProduct?: {
      __typename?: 'CoachProductType';
      id?: number | null;
      enabled: boolean;
      maxPrice?: number | null;
      price?: number | null;
    } | null;
  } | null;
};

export type SetCustomPriceOptionMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  price?: InputMaybe<Scalars['Decimal']['input']>;
  productId: Scalars['ID']['input'];
  useDefault?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SetCustomPriceOptionMutation = {
  __typename?: 'Mutation';
  setCustomPriceOption?: {
    __typename?: 'SetCustomPriceOption';
    ok?: boolean | null;
    customPriceOption?: {
      __typename?: 'CustomPriceOptionType';
      id?: number | null;
      price?: number | null;
    } | null;
  } | null;
};

export type ToggleAcceptingNewClientsForCoachProductMutationVariables = Exact<{
  productSlug: Scalars['String']['input'];
}>;

export type ToggleAcceptingNewClientsForCoachProductMutation = {
  __typename?: 'Mutation';
  toggleAcceptingNewClientsForCoachProduct?: {
    __typename?: 'ToggleAcceptingNewClientsForCoachProduct';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
    coachProduct?: {
      __typename?: 'CoachProductType';
      id?: number | null;
      acceptingNewClients?: boolean | null;
    } | null;
  } | null;
};

export type ToggleCoachProductMutationVariables = Exact<{
  productSlug: Scalars['String']['input'];
}>;

export type ToggleCoachProductMutation = {
  __typename?: 'Mutation';
  toggleCoachProduct?: {
    __typename?: 'ToggleCoachProduct';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
    coachProduct?: {
      __typename?: 'CoachProductType';
      id?: number | null;
      enabled: boolean;
    } | null;
  } | null;
};

export type ToggleRequireBookingRequestForCoachProductMutationVariables =
  Exact<{
    productSlug: Scalars['String']['input'];
  }>;

export type ToggleRequireBookingRequestForCoachProductMutation = {
  __typename?: 'Mutation';
  toggleRequireBookingRequestForCoachProduct?: {
    __typename?: 'ToggleRequireBookingRequestForCoachProduct';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
    coachProduct?: {
      __typename?: 'CoachProductType';
      id?: number | null;
      requireBookingRequest?: boolean | null;
    } | null;
  } | null;
};

export type DeleteScheduleOverrideMutationVariables = Exact<{
  date: Scalars['Date']['input'];
}>;

export type DeleteScheduleOverrideMutation = {
  __typename?: 'Mutation';
  deleteScheduleOverride?: {
    __typename?: 'DeleteScheduleOverride';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type MakePrimaryCalendarAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MakePrimaryCalendarAccountMutation = {
  __typename?: 'Mutation';
  makePrimaryCalendarAccount?: {
    __typename?: 'MakePrimaryCalendarAccount';
    ok?: boolean | null;
    error?: { __typename?: 'ErrorType'; message?: string | null } | null;
  } | null;
};

export type MyCoachProductsQueryVariables = Exact<{ [key: string]: never }>;

export type MyCoachProductsQuery = {
  __typename?: 'Query';
  activeCoachProducts?: Array<{
    __typename?: 'CoachProductType';
    price?: number | null;
    enabled: boolean;
    id?: number | null;
    acceptingNewClients?: boolean | null;
    requireBookingRequest?: boolean | null;
    product?: {
      __typename?: 'ProductType';
      name: string;
      id: string;
      shortDescription?: string | null;
      category?: ProductCategory | null;
      creditPrice?: number | null;
      introProduct: boolean;
      enableRfq: boolean;
      enableBookingRequest: boolean;
      slug: string;
      duration?: number | null;
      msrp?: number | null;
      price?: number | null;
      specialty?: {
        __typename?: 'SpecialtyType';
        id: string;
        name: string;
        description?: string | null;
        slug: string;
      } | null;
    } | null;
  } | null> | null;
  inactiveProducts?: Array<{
    __typename?: 'ProductType';
    id: string;
    name: string;
    specialty?: {
      __typename?: 'SpecialtyType';
      id: string;
      name: string;
      description?: string | null;
    } | null;
  } | null> | null;
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    agentProfile?: {
      __typename?: 'AgentProfileType';
      id: string;
      coachSlug: string;
    } | null;
  } | null;
};

export type RemoveCalendarAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveCalendarAccountMutation = {
  __typename?: 'Mutation';
  removeCalendarAccount?: {
    __typename?: 'RemoveCalendarAccount';
    ok?: boolean | null;
    error?: { __typename?: 'ErrorType'; message?: string | null } | null;
  } | null;
};

export type SaveCalendarSettingsMutationVariables = Exact<{
  input: CalendarSettingInput;
}>;

export type SaveCalendarSettingsMutation = {
  __typename?: 'Mutation';
  saveCalendarSettings?: {
    __typename?: 'SaveCalendarSettings';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SaveScheduleOverridesMutationVariables = Exact<{
  dates:
    | Array<InputMaybe<Scalars['Date']['input']>>
    | InputMaybe<Scalars['Date']['input']>;
  timeslots:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type SaveScheduleOverridesMutation = {
  __typename?: 'Mutation';
  saveScheduleOverrides?: {
    __typename?: 'SaveScheduleOverrides';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type ScheduleOverridesQueryVariables = Exact<{ [key: string]: never }>;

export type ScheduleOverridesQuery = {
  __typename?: 'Query';
  scheduleOverrides?: Array<{
    __typename?: 'ScheduleOverrideGraphqlType';
    id: string;
    overrideDate: any;
    timeslots?: Array<string | null> | null;
  } | null> | null;
};

export type BufferSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type BufferSettingsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    agentProfile?: {
      __typename?: 'AgentProfileType';
      id: string;
      bufferAfter?: number | null;
      bufferBefore?: number | null;
      minuteIncrement?: number | null;
      minimumMeetingNotice: number;
    } | null;
  } | null;
};

export type CoachBufferFragmentFragment = {
  __typename?: 'AgentProfileType';
  bufferAfter?: number | null;
  bufferBefore?: number | null;
  minuteIncrement?: number | null;
  minimumMeetingNotice: number;
};

export type UpdateCoachBufferSettingsMutationVariables = Exact<{
  bufferBefore: Scalars['Int']['input'];
  bufferAfter: Scalars['Int']['input'];
  minuteIncrement: Scalars['Int']['input'];
  minimumMeetingNotice: Scalars['Int']['input'];
}>;

export type UpdateCoachBufferSettingsMutation = {
  __typename?: 'Mutation';
  updateBufferSettings?: {
    __typename?: 'UpdateCoachBufferSettings';
    ok?: boolean | null;
  } | null;
};

export type MeetingTypesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type MeetingTypesQuery = {
  __typename?: 'Query';
  specialties?: Array<{
    __typename?: 'SpecialtyType';
    id: string;
    slug: string;
    name: string;
    description?: string | null;
  } | null> | null;
  meetingTypes?: Array<{
    __typename?: 'ProductType';
    id: string;
    slug: string;
    name: string;
    category?: ProductCategory | null;
    duration?: number | null;
    description?: string | null;
    creditPrice?: number | null;
    slogan?: string | null;
    introProduct: boolean;
    enableBookingRequest: boolean;
    coachEnableBookingRequest?: Array<string | null> | null;
    specialty?: {
      __typename?: 'SpecialtyType';
      id: string;
      name: string;
    } | null;
    coach?: { __typename?: 'MiniCoachType'; id: string } | null;
    activeFormFields?: Array<{
      __typename?: 'FormFieldType';
      id: string;
      prompt: string;
      helpText?: string | null;
      answerType: FormFieldAnswerType;
      required: boolean;
      formFieldOptions?: Array<{
        __typename?: 'FormFieldOptionType';
        id: string;
        label: string;
        value: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type RemoveAssignmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveAssignmentMutation = {
  __typename?: 'Mutation';
  removeAssignment?: {
    __typename?: 'RemoveAssignment';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateStripeAccountMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UpdateStripeAccountMutation = {
  __typename?: 'Mutation';
  updateStripeAccount?: {
    __typename?: 'UpdateStripeAccount';
    ready?: boolean | null;
    submitted?: boolean | null;
  } | null;
};

export type AgentProfileBasicQueryVariables = Exact<{
  coachSlug?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type AgentProfileBasicQuery = {
  __typename?: 'Query';
  agentProfile?: {
    __typename?: 'AgentProfileType';
    id: string;
    seoIndex?: boolean | null;
    primarySite: AgentProfilePrimarySite;
    coachSlug: string;
    about?: string | null;
    summary?: string | null;
    createdAt: any;
    seoPhoto?: string | null;
    averageRating?: number | null;
    credentials?: Array<string | null> | null;
    introVideo?: string | null;
    introVideoThumbnail?: string | null;
    user?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
      referralCode?: string | null;
    } | null;
    standardCredentials?: Array<{
      __typename?: 'StandardCredentialType';
      id: string;
      name: string;
      description: string;
      issuer: string;
      requirements?: string | null;
      badgeUrl?: string | null;
    } | null> | null;
    faqAnswers?: Array<{
      __typename?: 'FaqAnswerType';
      id: string;
      content?: string | null;
      videoUrl?: string | null;
      videoThumbnail?: string | null;
      faqQuestion?: {
        __typename?: 'FaqQuestionType';
        id: string;
        content: string;
      } | null;
    } | null> | null;
    testimonials?: Array<{
      __typename?: 'TestimonialType';
      id: string;
      name: string;
      message: string;
      jobTitle: string;
      avatarUrl?: string | null;
    } | null> | null;
  } | null;
};

export type AvailableCreditsQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type AvailableCreditsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    availableCredits?: any | null;
    receivableCredits?: any | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      balance: number;
      label?: string | null;
    } | null> | null;
  } | null;
  customer?: {
    __typename?: 'UserType';
    id: string;
    availableCredits?: any | null;
    receivableCredits?: any | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      balance: number;
      label?: string | null;
    } | null> | null;
  } | null;
};

export type CoachProductPartsFragment = {
  __typename?: 'CoachProductType';
  id?: number | null;
  acceptingNewClients?: boolean | null;
  requireBookingRequest?: boolean | null;
  product?: {
    __typename?: 'ProductType';
    name: string;
    id: string;
    shortDescription?: string | null;
    category?: ProductCategory | null;
    creditPrice?: number | null;
    introProduct: boolean;
    enableRfq: boolean;
    enableBookingRequest: boolean;
    slug: string;
    duration?: number | null;
    msrp?: number | null;
    price?: number | null;
    specialty?: {
      __typename?: 'SpecialtyType';
      id: string;
      name: string;
      description?: string | null;
      slug: string;
    } | null;
  } | null;
};

export type CoachProductPricesQueryVariables = Exact<{
  ids:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>;
}>;

export type CoachProductPricesQuery = {
  __typename?: 'Query';
  coachProducts?: Array<{
    __typename?: 'CoachProductType';
    id?: number | null;
    price?: number | null;
  } | null> | null;
};

export type CoachProductsQueryVariables = Exact<{
  coachUserId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
  specialtyId?: InputMaybe<Scalars['ID']['input']>;
  specialtySlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type CoachProductsQuery = {
  __typename?: 'Query';
  coachProducts?: Array<{
    __typename?: 'CoachProductType';
    price?: number | null;
    usdPrice?: any | null;
    locked?: boolean | null;
    id?: number | null;
    acceptingNewClients?: boolean | null;
    requireBookingRequest?: boolean | null;
    product?: {
      __typename?: 'ProductType';
      name: string;
      id: string;
      shortDescription?: string | null;
      category?: ProductCategory | null;
      creditPrice?: number | null;
      introProduct: boolean;
      enableRfq: boolean;
      enableBookingRequest: boolean;
      slug: string;
      duration?: number | null;
      msrp?: number | null;
      price?: number | null;
      specialty?: {
        __typename?: 'SpecialtyType';
        id: string;
        name: string;
        description?: string | null;
        slug: string;
      } | null;
    } | null;
  } | null> | null;
};

export type CreateIntroOrderMutationVariables = Exact<{
  productQuantities: Scalars['GenericScalar']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
  coachId?: InputMaybe<Scalars['ID']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  timeslotsStrings?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  usedAllTimeslot?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateIntroOrderMutation = {
  __typename?: 'Mutation';
  createOrder?: {
    __typename?: 'CreateOrder';
    ok?: boolean | null;
    order?: { __typename?: 'OrderType'; id: string } | null;
    error?: { __typename?: 'ErrorType'; message?: string | null } | null;
  } | null;
};

export type FaqAnswerPartsFragment = {
  __typename?: 'FaqAnswerType';
  id: string;
  content?: string | null;
  videoUrl?: string | null;
  videoThumbnail?: string | null;
  faqQuestion?: {
    __typename?: 'FaqQuestionType';
    id: string;
    content: string;
  } | null;
};

export type FaqAnswersQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
}>;

export type FaqAnswersQuery = {
  __typename?: 'Query';
  faqAnswers?: Array<{
    __typename?: 'FaqAnswerType';
    id: string;
    content?: string | null;
    videoUrl?: string | null;
    videoThumbnail?: string | null;
    faqQuestion?: {
      __typename?: 'FaqQuestionType';
      id: string;
      content: string;
    } | null;
  } | null> | null;
};

export type CancelCoachMeetingMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CancelCoachMeetingMutation = {
  __typename?: 'Mutation';
  cancelCoachMeeting?: {
    __typename?: 'CancelCoachMeeting';
    ok?: boolean | null;
  } | null;
};

export type FullMeetingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type FullMeetingQuery = {
  __typename?: 'Query';
  meeting?: {
    __typename?: 'MeetingType';
    expiredAt?: any | null;
    id: string;
    chatChannelId?: string | null;
    numMessages?: number | null;
    eventAt?: any | null;
    canceledAt?: any | null;
    cancelReason?: string | null;
    location?: string | null;
    customLocation?: string | null;
    duration?: number | null;
    customerId?: number | null;
    refundableUntil?: any | null;
    canLeaveRating?: boolean | null;
    paymentStatus?: PaymentStatusTypes | null;
    googleEventId?: string | null;
    creditPrice?: any | null;
    allowInvitation: boolean;
    allowGuests?: boolean | null;
    guests?: Array<string | null> | null;
    isBookingRequest?: boolean | null;
    formFieldAnswers?: Array<{
      __typename?: 'AnswerType';
      id: string;
      value?: string | null;
      userFile?: {
        __typename?: 'UserFileType';
        id: string;
        name?: string | null;
        url?: string | null;
      } | null;
      optionValue?: {
        __typename?: 'FormFieldOptionAnalyticsType';
        id: string;
        value: string;
        label: string;
      } | null;
      formField?: {
        __typename?: 'FieldType';
        id: string;
        prompt: string;
        answerType: FormFieldAnswerType;
      } | null;
    } | null> | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
      coachSlug?: string | null;
      email: string;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
      email: string;
    } | null;
    attendee?: {
      __typename?: 'MeetingAttendeeType';
      id: string;
      userId?: number | null;
      status?: InvitationStatus | null;
      attendingStatus?: AttendingStatus | null;
      meetingId?: number | null;
    } | null;
    workspace?: {
      __typename?: 'WorkspaceType';
      id: string;
      name: string;
      urlSlug: string;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      duration?: number | null;
      category?: ProductCategory | null;
      description?: string | null;
      introProduct: boolean;
      enableGuests?: boolean | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null;
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    primaryCalendarAccount?: {
      __typename?: 'CalendarAccountType';
      id: string;
      accountEmail?: string | null;
    } | null;
  } | null;
};

export type AddMeetingAttendeesMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  emails:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type AddMeetingAttendeesMutation = {
  __typename?: 'Mutation';
  addMeetingAttendees?: {
    __typename?: 'AddMeetingAttendees';
    ok?: boolean | null;
  } | null;
};

export type MeetingAttendeesQueryVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  status?: InputMaybe<InvitationStatus>;
  statuses?: InputMaybe<
    Array<InputMaybe<InvitationStatus>> | InputMaybe<InvitationStatus>
  >;
  start?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MeetingAttendeesQuery = {
  __typename?: 'Query';
  meetingAttendees?: {
    __typename?: 'MeetingAttendeesQueryResponse';
    total?: number | null;
    start?: number | null;
    limit?: number | null;
    attendees?: Array<{
      __typename?: 'MeetingAttendeeType';
      id: string;
      status?: InvitationStatus | null;
      attendingStatus?: AttendingStatus | null;
      email: string;
      invitationBatchId?: number | null;
      scheduledJobId?: number | null;
      user?: {
        __typename?: 'MiniUserType';
        id: string;
        name?: string | null;
        email: string;
        photo?: string | null;
      } | null;
    } | null> | null;
    owner?: {
      __typename?: 'MiniUserType';
      id: string;
      name?: string | null;
      email: string;
      photo?: string | null;
    } | null;
  } | null;
};

export type CancelAttendeesScheduleMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  scheduledJobId: Scalars['ID']['input'];
}>;

export type CancelAttendeesScheduleMutation = {
  __typename?: 'Mutation';
  cancelAttendeesSchedule?: {
    __typename?: 'CancelAttendeesSchedule';
    ok?: boolean | null;
  } | null;
};

export type ReInvFailedMeetingAttendeesMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
}>;

export type ReInvFailedMeetingAttendeesMutation = {
  __typename?: 'Mutation';
  reInvFailedMeetingAttendees?: {
    __typename?: 'ReInviteFailedMeetingAttendees';
    ok?: boolean | null;
  } | null;
};

export type RemoveMeetingAttendeesMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  emails:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type RemoveMeetingAttendeesMutation = {
  __typename?: 'Mutation';
  removeMeetingAttendees?: {
    __typename?: 'RemoveMeetingAttendees';
    ok?: boolean | null;
  } | null;
};

export type SaveAttendingStatusMutationVariables = Exact<{
  attendeeId: Scalars['ID']['input'];
  attendingStatus: AttendingStatus;
}>;

export type SaveAttendingStatusMutation = {
  __typename?: 'Mutation';
  saveAttendingStatus?: {
    __typename?: 'SaveAttendingStatus';
    ok?: boolean | null;
    msg?: string | null;
  } | null;
};

export type UpdateAttendeesScheduleMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  emails:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledJobId: Scalars['ID']['input'];
}>;

export type UpdateAttendeesScheduleMutation = {
  __typename?: 'Mutation';
  updateAttendeesSchedule?: {
    __typename?: 'UpdateAttendeeSchedule';
    ok?: boolean | null;
  } | null;
};

export type SaveMeetingAllowGuestsMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  allowGuests: Scalars['Boolean']['input'];
}>;

export type SaveMeetingAllowGuestsMutation = {
  __typename?: 'Mutation';
  saveMeetingAllowGuests?: {
    __typename?: 'SaveMeetingAllowGuests';
    ok?: boolean | null;
  } | null;
};

export type SaveMeetingGuestsMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  guests:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type SaveMeetingGuestsMutation = {
  __typename?: 'Mutation';
  saveMeetingGuests?: {
    __typename?: 'SaveMeetingGuests';
    ok?: boolean | null;
  } | null;
};

export type SeeMoreMeetingsQueryVariables = Exact<{
  index?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SeeMoreMeetingsQuery = {
  __typename?: 'Query';
  meetings?: Array<{
    __typename?: 'MeetingType';
    id: string;
    chatChannelId?: string | null;
    numMessages?: number | null;
    eventAt?: any | null;
    canceledAt?: any | null;
    cancelReason?: string | null;
    location?: string | null;
    customLocation?: string | null;
    duration?: number | null;
    customerId?: number | null;
    refundableUntil?: any | null;
    canLeaveRating?: boolean | null;
    paymentStatus?: PaymentStatusTypes | null;
    googleEventId?: string | null;
    creditPrice?: any | null;
    allowInvitation: boolean;
    allowGuests?: boolean | null;
    guests?: Array<string | null> | null;
    isBookingRequest?: boolean | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
      coachSlug?: string | null;
      email: string;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
      email: string;
    } | null;
    attendee?: {
      __typename?: 'MeetingAttendeeType';
      id: string;
      userId?: number | null;
      status?: InvitationStatus | null;
      attendingStatus?: AttendingStatus | null;
      meetingId?: number | null;
    } | null;
    workspace?: {
      __typename?: 'WorkspaceType';
      id: string;
      name: string;
      urlSlug: string;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      duration?: number | null;
      category?: ProductCategory | null;
      description?: string | null;
      introProduct: boolean;
      enableGuests?: boolean | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null> | null;
};

export type SendGoogleInviteCoachMeetingMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
}>;

export type SendGoogleInviteCoachMeetingMutation = {
  __typename?: 'Mutation';
  sendGoogleInviteCoachMeeting?: {
    __typename?: 'SendGoogleInviteCoachMeeting';
    ok?: boolean | null;
  } | null;
};

export type SetExceptionalRefundMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
}>;

export type SetExceptionalRefundMutation = {
  __typename?: 'Mutation';
  setExceptionalRefund?: {
    __typename?: 'SetExceptionalRefund';
    ok?: boolean | null;
  } | null;
};

export type SaveMeetingLocationMutationVariables = Exact<{
  meetingId: Scalars['ID']['input'];
  customLocation?: InputMaybe<Scalars['String']['input']>;
}>;

export type SaveMeetingLocationMutation = {
  __typename?: 'Mutation';
  saveMeetingLocation?: {
    __typename?: 'SaveMeetingLocation';
    ok?: boolean | null;
  } | null;
};

export type AcceptReviewMutationVariables = Exact<{
  reviewId: Scalars['ID']['input'];
  accepted: Scalars['Boolean']['input'];
}>;

export type AcceptReviewMutation = {
  __typename?: 'Mutation';
  acceptReview?: {
    __typename?: 'AcceptReview';
    review?: {
      __typename?: 'ReviewType';
      id: string;
      acceptedAt?: any | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CancelReviewMutationVariables = Exact<{
  reviewId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CancelReviewMutation = {
  __typename?: 'Mutation';
  cancelReview?: {
    __typename?: 'CancelReview';
    review?: {
      __typename?: 'ReviewType';
      id: string;
      canceledAt?: any | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type FullReviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type FullReviewQuery = {
  __typename?: 'Query';
  review?: {
    __typename?: 'ReviewType';
    id: string;
    chatChannelId?: string | null;
    status?: string | null;
    state?: string | null;
    nextDate?: string | null;
    numMessages?: number | null;
    acceptanceDeadline?: any | null;
    acceptedAt?: any | null;
    finalCheckRevisionSentAt?: any | null;
    canceledAt?: any | null;
    completedAt?: any | null;
    numRounds: number;
    numRoundsComplete: number;
    customerId?: number | null;
    canLeaveRating?: boolean | null;
    creditPrice?: any | null;
    formFieldAnswers?: Array<{
      __typename?: 'AnswerType';
      id: string;
      value?: string | null;
      userFile?: {
        __typename?: 'UserFileType';
        id: string;
        name?: string | null;
        url?: string | null;
      } | null;
      optionValue?: {
        __typename?: 'FormFieldOptionAnalyticsType';
        id: string;
        value: string;
        label: string;
      } | null;
      formField?: {
        __typename?: 'FieldType';
        id: string;
        prompt: string;
      } | null;
    } | null> | null;
    rounds?: Array<{
      __typename?: 'ReviewRoundType';
      id: string;
      completedAt?: any | null;
      revisionSentAt?: any | null;
    } | null> | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      turnaroundTime?: number | null;
      includesFinalCheck: boolean;
      description?: string | null;
      numRounds?: number | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null;
};

export type ReviewTypesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ReviewTypesQuery = {
  __typename?: 'Query';
  specialties?: Array<{
    __typename?: 'SpecialtyType';
    id: string;
    slug: string;
    name: string;
    description?: string | null;
  } | null> | null;
  reviewTypes?: Array<{
    __typename?: 'ProductType';
    id: string;
    slug: string;
    name: string;
    category?: ProductCategory | null;
    description?: string | null;
    creditPrice?: number | null;
    slogan?: string | null;
    turnaroundTime?: number | null;
    numRounds?: number | null;
    includesFinalCheck: boolean;
    activeFormFields?: Array<{
      __typename?: 'FormFieldType';
      id: string;
      prompt: string;
      helpText?: string | null;
      answerType: FormFieldAnswerType;
      required: boolean;
      formFieldOptions?: Array<{
        __typename?: 'FormFieldOptionType';
        id: string;
        label: string;
        value: string;
      } | null> | null;
    } | null> | null;
    specialty?: {
      __typename?: 'SpecialtyType';
      id: string;
      name: string;
    } | null;
    coach?: { __typename?: 'MiniCoachType'; id: string } | null;
  } | null> | null;
};

export type SeeMoreReviewsQueryVariables = Exact<{
  index?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SeeMoreReviewsQuery = {
  __typename?: 'Query';
  reviews?: Array<{
    __typename?: 'ReviewType';
    id: string;
    chatChannelId?: string | null;
    status?: string | null;
    state?: string | null;
    nextDate?: string | null;
    numMessages?: number | null;
    acceptanceDeadline?: any | null;
    acceptedAt?: any | null;
    finalCheckRevisionSentAt?: any | null;
    canceledAt?: any | null;
    completedAt?: any | null;
    numRounds: number;
    numRoundsComplete: number;
    customerId?: number | null;
    canLeaveRating?: boolean | null;
    creditPrice?: any | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      turnaroundTime?: number | null;
      includesFinalCheck: boolean;
      description?: string | null;
      numRounds?: number | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null> | null;
};

export type CompleteReviewRoundMutationVariables = Exact<{
  reviewId: Scalars['ID']['input'];
}>;

export type CompleteReviewRoundMutation = {
  __typename?: 'Mutation';
  completeReviewRound?: {
    __typename?: 'CompleteReviewRound';
    review?: {
      __typename?: 'ReviewType';
      id: string;
      completedAt?: any | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type ReturnReviewRevisionMutationVariables = Exact<{
  reviewId: Scalars['ID']['input'];
}>;

export type ReturnReviewRevisionMutation = {
  __typename?: 'Mutation';
  returnReviewRevision?: {
    __typename?: 'ReturnReviewRevision';
    review?: {
      __typename?: 'ReviewType';
      id: string;
      completedAt?: any | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
  } | null;
};

export type ReviewSidebarQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ReviewSidebarQuery = {
  __typename?: 'Query';
  review?: {
    __typename?: 'ReviewType';
    id: string;
    chatChannelId?: string | null;
    status?: string | null;
    state?: string | null;
    nextDate?: string | null;
    numMessages?: number | null;
    acceptanceDeadline?: any | null;
    acceptedAt?: any | null;
    finalCheckRevisionSentAt?: any | null;
    canceledAt?: any | null;
    completedAt?: any | null;
    numRounds: number;
    numRoundsComplete: number;
    customerId?: number | null;
    canLeaveRating?: boolean | null;
    creditPrice?: any | null;
    formFieldAnswers?: Array<{
      __typename?: 'AnswerType';
      id: string;
      value?: string | null;
      userFile?: {
        __typename?: 'UserFileType';
        id: string;
        name?: string | null;
        url?: string | null;
      } | null;
      optionValue?: {
        __typename?: 'FormFieldOptionAnalyticsType';
        id: string;
        value: string;
        label: string;
      } | null;
      formField?: {
        __typename?: 'FieldType';
        id: string;
        prompt: string;
      } | null;
    } | null> | null;
    rounds?: Array<{
      __typename?: 'ReviewRoundType';
      id: string;
      completedAt?: any | null;
      revisionSentAt?: any | null;
    } | null> | null;
    coach?: {
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      photo?: string | null;
    } | null;
    product?: {
      __typename?: 'ProductType';
      id: string;
      name: string;
      slug: string;
      turnaroundTime?: number | null;
      includesFinalCheck: boolean;
      description?: string | null;
      numRounds?: number | null;
    } | null;
    rating?: {
      __typename?: 'RatingType';
      id: string;
      score: number;
      comment?: string | null;
      privateComment?: string | null;
    } | null;
  } | null;
};

export type FaqQueryVariables = Exact<{
  category: Scalars['String']['input'];
}>;

export type FaqQuery = {
  __typename?: 'Query';
  faq?: {
    __typename?: 'FaqType';
    category?: string | null;
    questions?: Array<{
      __typename?: 'CMSFaqQuestionType';
      question?: string | null;
      order?: number | null;
      answer?: any | null;
    } | null> | null;
  } | null;
};

export type LearnDashboardReadStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LearnDashboardReadStatusQuery = {
  __typename?: 'Query';
  learnBoardReadStatus?: {
    __typename?: 'LearnBoardReadStatus';
    articleSeriesReadStatusItems?: Array<{
      __typename?: 'ArticleSeriesReadStatus';
      articleSeriesId?: number | null;
      totalItemCount?: number | null;
      readInprogressArticleIds?: Array<number | null> | null;
      readCompletedArticleIds?: Array<number | null> | null;
    } | null> | null;
  } | null;
};

export type LearnDashboardQueryVariables = Exact<{
  public?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LearnDashboardQuery = {
  __typename?: 'Query';
  learnBoardArticleSeries?: {
    __typename?: 'LearnBoardSeries';
    recentSeriesItems?: Array<{
      __typename?: 'ArticleSeries';
      id?: string | null;
      title?: string | null;
      urlSlug?: string | null;
    } | null> | null;
    popularSeriesItems?: Array<{
      __typename?: 'ArticleSeries';
      id?: string | null;
      title?: string | null;
      urlSlug?: string | null;
    } | null> | null;
    otherSeriesItems?: Array<{
      __typename?: 'ArticleSeries';
      id?: string | null;
      title?: string | null;
      urlSlug?: string | null;
      description?: string | null;
    } | null> | null;
  } | null;
};

export type RecentArticlesQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  public?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type RecentArticlesQuery = {
  __typename?: 'Query';
  recentArticles?: {
    __typename?: 'ArticlesPaginationType';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    totalPages?: number | null;
    items?: Array<{
      __typename?: 'Article';
      id: string;
      title?: string | null;
      timeToRead?: number | null;
      urlSlug?: string | null;
      public?: boolean | null;
      heroImage?: string | null;
    } | null> | null;
  } | null;
};

export type ArticleSeriesReadStatusQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type ArticleSeriesReadStatusQuery = {
  __typename?: 'Query';
  articleSeriesReadStatus?: {
    __typename?: 'ArticleSeriesReadStatus';
    articleSeriesId?: number | null;
    totalItemCount?: number | null;
    readInprogressArticleIds?: Array<number | null> | null;
    readCompletedArticleIds?: Array<number | null> | null;
  } | null;
};

export type ParseLinkMetadataMutationVariables = Exact<{
  link: Scalars['String']['input'];
}>;

export type ParseLinkMetadataMutation = {
  __typename?: 'Mutation';
  parseLinkMetadata?: {
    __typename?: 'ParseLinkMetadata';
    ok?: boolean | null;
    error?: {
      __typename?: 'ErrorType';
      field?: string | null;
      code?: string | null;
    } | null;
    data?: {
      __typename?: 'LinkMetadataType';
      text?: string | null;
      imageSrc?: string | null;
    } | null;
  } | null;
};

export type BillingSessionMutationVariables = Exact<{
  returnUrl: Scalars['String']['input'];
}>;

export type BillingSessionMutation = {
  __typename?: 'Mutation';
  billingSession?: {
    __typename?: 'BillingSession';
    url?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type NewSubscriptionMutationVariables = Exact<{
  successUrl: Scalars['String']['input'];
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
}>;

export type NewSubscriptionMutation = {
  __typename?: 'Mutation';
  newSubscription?: {
    __typename?: 'NewSubscription';
    url?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type PaymentMethodsQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type PaymentMethodsQuery = {
  __typename?: 'Query';
  paymentMethods?: Array<{
    __typename?: 'StripePaymentMethodType';
    stripeId?: string | null;
    brand?: string | null;
    last4?: string | null;
  } | null> | null;
};

export type RedeemGiftCodeMutationVariables = Exact<{
  code: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type RedeemGiftCodeMutation = {
  __typename?: 'Mutation';
  redeemGiftCode?: {
    __typename?: 'RedeemGiftCode';
    ok?: boolean | null;
    creditsRedeemed?: number | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
    order?: {
      __typename?: 'OrderType';
      id: string;
      key?: string | null;
      grossTotal?: number | null;
      total?: number | null;
      grossCreditTotal?: number | null;
      creditTotal?: number | null;
      grossCreditTotalInDollars?: number | null;
      creditTotalInDollars?: number | null;
      stripeClientSecret?: string | null;
      paymentType?: string | null;
      promoDiscount?: number | null;
      promoCreditDiscount?: number | null;
      promoCreditTotalInDollarsDiscount?: number | null;
      ledgerAccountId?: string | null;
      ledgerAccount?: {
        __typename?: 'LedgerAccountType';
        id: string;
        label?: string | null;
        balance: number;
      } | null;
      orderItems?: Array<{
        __typename?: 'OrderItemType';
        id: string;
        grossTotal?: number | null;
        total?: number | null;
        price?: number | null;
        grossCreditTotal?: number | null;
        creditTotal?: number | null;
        grossCreditTotalInDollars?: number | null;
        creditTotalInDollars?: number | null;
        promoDiscount?: number | null;
        promoCreditDiscount?: number | null;
        promoCreditTotalInDollarsDiscount?: number | null;
        product: {
          __typename?: 'ProductType';
          id: string;
          name: string;
          slug: string;
          shortDescription?: string | null;
          introProduct: boolean;
          category?: ProductCategory | null;
          specialtyId?: string | null;
        };
      } | null> | null;
      promoCode?: {
        __typename?: 'PromoCodeType';
        id: string;
        code: string;
      } | null;
      user?: {
        __typename?: 'UserType';
        id: string;
        availableCredits?: any | null;
        receivableCredits?: any | null;
        ledgerAccounts?: Array<{
          __typename?: 'LedgerAccountType';
          id: string;
          balance: number;
          label?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type RemovePaymentMethodMutationVariables = Exact<{
  stripeId: Scalars['String']['input'];
}>;

export type RemovePaymentMethodMutation = {
  __typename?: 'Mutation';
  removePaymentMethod?: {
    __typename?: 'RemovePaymentMethod';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type SubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    subscription?: {
      __typename?: 'SubscriptionType';
      id: string;
      status?: string | null;
      validUntil?: any | null;
      stripeSubscriptionId?: string | null;
    } | null;
  } | null;
};

export type SaveNotificationPreferencesMutationVariables = Exact<{
  input: NotificationPreferenceInput;
}>;

export type SaveNotificationPreferencesMutation = {
  __typename?: 'Mutation';
  saveNotificationPreferences?: {
    __typename?: 'SaveNotificationPreferences';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type ResumesQueryVariables = Exact<{ [key: string]: never }>;

export type ResumesQuery = {
  __typename?: 'Query';
  resumes?: Array<{
    __typename?: 'ResumeGraphqlType';
    id: string;
    name?: string | null;
    updatedAt: any;
  } | null> | null;
};

export type SaveResumeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SaveResumeMutation = {
  __typename?: 'Mutation';
  saveResume?: {
    __typename?: 'SaveResume';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SaveCustomerFeedbackMutationVariables = Exact<{
  input: CustomerFeedbackInput;
}>;

export type SaveCustomerFeedbackMutation = {
  __typename?: 'Mutation';
  saveCustomerFeedback?: {
    __typename?: 'SaveCustomerFeedback';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type UpdatePersonalInfoMutationVariables = Exact<{
  input: UserInput;
}>;

export type UpdatePersonalInfoMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'UpdateUser';
    user?: {
      __typename?: 'UserType';
      id: string;
      email: string;
      name?: string | null;
      phoneNumber?: string | null;
      jobTitle?: string | null;
      linkedinAccount?: string | null;
      timeZone?: string | null;
    } | null;
    errors?: Array<{
      __typename?: 'Error';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type ArchiveRoleplaySessionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ArchiveRoleplaySessionMutation = {
  __typename?: 'Mutation';
  archiveRoleplaySession?: {
    __typename?: 'ArchiveRoleplaySession';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type ArchiveScenarioMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ArchiveScenarioMutation = {
  __typename?: 'Mutation';
  archiveScenario?: {
    __typename?: 'ArchiveScenario';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CloneCollectionMutationVariables = Exact<{
  collectionId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  visibilityScope: VisibilityScope;
  emojiUnicode?: InputMaybe<Scalars['String']['input']>;
}>;

export type CloneCollectionMutation = {
  __typename?: 'Mutation';
  cloneCollection?: {
    __typename?: 'CloneCollection';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
    collection?: {
      __typename?: 'RoleplayType';
      id: string;
      name: string;
      visibilityScope?: VisibilityScope | null;
      slug: string;
    } | null;
  } | null;
};

export type CloneScenarioMutationVariables = Exact<{
  scenarioId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  visibilityScope: VisibilityScope;
  collectionIds?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
}>;

export type CloneScenarioMutation = {
  __typename?: 'Mutation';
  cloneScenario?: {
    __typename?: 'CloneScenario';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
    scenario?: {
      __typename?: 'ScenarioType';
      id: string;
      name: string;
      visibilityScope?: VisibilityScope | null;
      slug: string;
    } | null;
  } | null;
};

export type GetCoachAllTimeslotsQueryVariables = Exact<{
  productSlug: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
  end: Scalars['DateTime']['input'];
  coachId?: InputMaybe<Scalars['ID']['input']>;
  coachSlug?: InputMaybe<Scalars['String']['input']>;
  ignoreMeetingId?: InputMaybe<Scalars['ID']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  usedAllTimeslot?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetCoachAllTimeslotsQuery = {
  __typename?: 'Query';
  coachAvailableTimeslots?: {
    __typename?: 'CoachAvailableObject';
    timeslots?: Array<any | null> | null;
    timezone?: string | null;
  } | null;
};

export type GetCoachAvailableTimeslotsQueryVariables = Exact<{
  productSlug: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
  end: Scalars['DateTime']['input'];
  coachId?: InputMaybe<Scalars['ID']['input']>;
  coachSlug?: InputMaybe<Scalars['String']['input']>;
  ignoreMeetingId?: InputMaybe<Scalars['ID']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCoachAvailableTimeslotsQuery = {
  __typename?: 'Query';
  coachAvailableTimeslots?: {
    __typename?: 'CoachAvailableObject';
    timeslots?: Array<any | null> | null;
    timezone?: string | null;
  } | null;
};

export type GetCoachPersonalAvailableTimeslotsQueryVariables = Exact<{
  start: Scalars['DateTime']['input'];
  end: Scalars['DateTime']['input'];
  timeslotDuration: Scalars['Int']['input'];
}>;

export type GetCoachPersonalAvailableTimeslotsQuery = {
  __typename?: 'Query';
  coachPersonalAvailableTimeslots?: {
    __typename?: 'CoachAvailableObject';
    timeslots?: Array<any | null> | null;
    timezone?: string | null;
  } | null;
};

export type SaveSearchMutationVariables = Exact<{
  query: Scalars['String']['input'];
  selectedResultType?: InputMaybe<Scalars['String']['input']>;
  selectedResult?: InputMaybe<Scalars['JSONString']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SaveSearchMutation = {
  __typename?: 'Mutation';
  saveSearch?: {
    __typename?: 'SaveSearch';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SearchPreferenceQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SearchPreferenceQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    name?: string | null;
  } | null;
  recentSearches?: Array<{
    __typename?: 'SearchType';
    id: string;
    query: string;
    selectedResultType?: SearchSelectedResultType | null;
    selectedResult?: any | null;
    customer?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      email: string;
    } | null;
  } | null> | null;
  currentUser?: {
    __typename?: 'UserType';
    id: string;
    isStaff: boolean;
  } | null;
};

export type SlideshowQueryVariables = Exact<{
  title: Scalars['String']['input'];
}>;

export type SlideshowQuery = {
  __typename?: 'Query';
  slideshow?: {
    __typename?: 'SlideshowType';
    title?: string | null;
    slides?: Array<{
      __typename?: 'SlideType';
      heading?: string | null;
      subtext?: string | null;
      lengthInSeconds?: number | null;
      buttonText?: string | null;
      buttonLink?: string | null;
      secondaryButtonText?: string | null;
      secondaryButtonLink?: string | null;
    } | null> | null;
  } | null;
};

export type CoachRatingsQueryVariables = Exact<{
  coachId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CoachRatingsQuery = {
  __typename?: 'Query';
  coachRatings?: Array<{
    __typename?: 'PublicRatingType';
    id: string;
    score: number;
    comment?: string | null;
    truncatedName?: string | null;
    featured: boolean;
    updatedAt: any;
  } | null> | null;
};

export type UpdateRatingFeaturedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateRatingFeaturedMutation = {
  __typename?: 'Mutation';
  updateRatingFeatured?: {
    __typename?: 'UpdateRatingFeatured';
    ok?: boolean | null;
    rating?: {
      __typename?: 'PublicRatingType';
      id: string;
      featured: boolean;
    } | null;
  } | null;
};

export type CoachComponentQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CoachComponentQuery = {
  __typename?: 'Query';
  assignments?: Array<{
    __typename?: 'AssignmentType';
    id: string;
    chatChannelId?: string | null;
    coach?: {
      __typename?: 'MiniCoachType';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
    } | null;
  } | null> | null;
  customer?: { __typename?: 'UserType'; id: string } | null;
  currentUser?: { __typename?: 'UserType'; id: string } | null;
};

export type DeleteTodoMutationVariables = Exact<{
  todoId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type DeleteTodoMutation = {
  __typename?: 'Mutation';
  deleteTodo?: {
    __typename?: 'DeleteTodo';
    ok?: boolean | null;
    previousId?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SaveTodoMutationVariables = Exact<{
  todoInput: TodoInput;
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type SaveTodoMutation = {
  __typename?: 'Mutation';
  saveTodo?: {
    __typename?: 'SaveTodo';
    created?: boolean | null;
    todo?: {
      __typename?: 'TodoType';
      id: string;
      title: string;
      dueAt?: any | null;
      url?: string | null;
      hideFromDashboard: boolean;
      completedAt?: any | null;
      createdAt: any;
      link?: {
        __typename?: 'LinkType';
        id: string;
        url: string;
        title?: string | null;
      } | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type TodoPartsFragment = {
  __typename?: 'TodoType';
  id: string;
  title: string;
  dueAt?: any | null;
  url?: string | null;
  hideFromDashboard: boolean;
  completedAt?: any | null;
  createdAt: any;
  link?: {
    __typename?: 'LinkType';
    id: string;
    url: string;
    title?: string | null;
  } | null;
};

export type TodosQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars['ID']['input']>;
  includeComplete?: InputMaybe<Scalars['Boolean']['input']>;
  forDashboard?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type TodosQuery = {
  __typename?: 'Query';
  todos?: Array<{
    __typename?: 'TodoType';
    id: string;
    title: string;
    dueAt?: any | null;
    url?: string | null;
    hideFromDashboard: boolean;
    completedAt?: any | null;
    createdAt: any;
    link?: {
      __typename?: 'LinkType';
      id: string;
      url: string;
      title?: string | null;
    } | null;
  } | null> | null;
};

export type ToggleTodoMutationVariables = Exact<{
  todoId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ToggleTodoMutation = {
  __typename?: 'Mutation';
  toggleTodo?: {
    __typename?: 'ToggleTodo';
    todo?: {
      __typename?: 'TodoType';
      id: string;
      title: string;
      dueAt?: any | null;
      url?: string | null;
      hideFromDashboard: boolean;
      completedAt?: any | null;
      createdAt: any;
      link?: {
        __typename?: 'LinkType';
        id: string;
        url: string;
        title?: string | null;
      } | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type PlusCheckoutMutationVariables = Exact<{
  successUrl?: InputMaybe<Scalars['String']['input']>;
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  ctaType?: InputMaybe<Scalars['String']['input']>;
  ctaSource?: InputMaybe<Scalars['String']['input']>;
}>;

export type PlusCheckoutMutation = {
  __typename?: 'Mutation';
  plusCheckout?: {
    __typename?: 'PlusCheckout';
    id?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CreatePlaceMutationVariables = Exact<{
  placeDetails: PlaceInput;
}>;

export type CreatePlaceMutation = {
  __typename?: 'Mutation';
  createPlace?: {
    __typename?: 'CreatePlace';
    placeId?: number | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type GradeRoleplaySessionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GradeRoleplaySessionMutation = {
  __typename?: 'Mutation';
  gradeRoleplaySession?: {
    __typename?: 'GradeRoleplaySession';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    roleplaySession?: {
      __typename?: 'RoleplaySessionType';
      id: string;
      uuid: string;
      processingStatus?: RoleplaySessionProcessingStatus | null;
    } | null;
  } | null;
};

export type IntroduceFeatureMutationVariables = Exact<{
  featureType: Scalars['String']['input'];
}>;

export type IntroduceFeatureMutation = {
  __typename?: 'Mutation';
  introduceFeature?: {
    __typename?: 'IntroduceFeature';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  workspace?: InputMaybe<Scalars['String']['input']>;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'Login';
    token: string;
    refreshToken: string;
    currentWorkspaceRole?: string | null;
    currentWorkspaceDomain?: string | null;
    sesameQuery?: string | null;
    user?: {
      __typename?: 'UserType';
      id: string;
      email: string;
      onboardingCompletedAt?: any | null;
      isStaff: boolean;
      hasIndividualAccount?: boolean | null;
      coaches?: Array<{
        __typename?: 'AgentType';
        id: string;
        name?: string | null;
      } | null> | null;
    } | null;
    workspaces?: Array<{
      __typename?: 'MiniWorkspace';
      logo?: string | null;
      name?: string | null;
      role?: string | null;
      domain?: string | null;
    } | null> | null;
  } | null;
};

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken?: {
    __typename?: 'Refresh';
    token: string;
    refreshToken: string;
    payload: any;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  nextPath?: InputMaybe<Scalars['String']['input']>;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?: {
    __typename?: 'ResetPassword';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type RevokeSessionMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;

export type RevokeSessionMutation = {
  __typename?: 'Mutation';
  revokeSession?: {
    __typename?: 'RevokeSession';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SaveSessionMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;

export type SaveSessionMutation = {
  __typename?: 'Mutation';
  saveSession?: {
    __typename?: 'SaveSession';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  returnToken: Scalars['Boolean']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  giftCode?: InputMaybe<Scalars['String']['input']>;
  refcode?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  wsToken?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<Scalars['String']['input']>;
  asWorkspaceAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceSignedToken?: InputMaybe<Scalars['String']['input']>;
  forcePlacementPricing?: InputMaybe<Scalars['String']['input']>;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUp?: {
    __typename?: 'SignUp';
    ok?: boolean | null;
    token?: string | null;
    refreshToken?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type TokenAuthMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type TokenAuthMutation = {
  __typename?: 'Mutation';
  tokenAuth?: {
    __typename?: 'ObtainJSONWebToken';
    token: string;
    refreshToken: string;
  } | null;
};

export type CurrentSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentSubscriptionQuery = {
  __typename?: 'Query';
  currentSubscription?: {
    __typename?: 'SubscriptionDetailsType';
    id?: string | null;
    planId?: string | null;
    userId?: string | null;
    workspaceId?: string | null;
    validUntil?: any | null;
    stripeSubscriptionId?: string | null;
    quantity?: number | null;
    billingCycleStart?: any | null;
    billingCycleEnd?: any | null;
    canceledAt?: any | null;
    entitlements?: Array<{
      __typename?: 'CachedEntitlementType';
      id?: string | null;
      entitlement?: Entitlement | null;
      booleanValue?: boolean | null;
      maxUsageValue?: number | null;
      usageCount?: number | null;
    } | null> | null;
  } | null;
};

export type CurrentWorkspaceQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentWorkspaceQuery = {
  __typename?: 'Query';
  currentWorkspace?: {
    __typename?: 'WorkspaceGraphqlType';
    id: string;
    name: string;
    urlSlug: string;
    logo?: string | null;
    analyticsEnabled: boolean;
  } | null;
};

export type WorkspaceCreditsQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspaceCreditsQuery = {
  __typename?: 'Query';
  currentWorkspaceCredits?: any | null;
  currentWorkspaceBudgets?: any | null;
  currentWorkspace?: {
    __typename?: 'WorkspaceGraphqlType';
    id: string;
    creditMode: WorkspaceCreditMode;
  } | null;
};

export type FeatureIntroducedQueryVariables = Exact<{
  featureType: Scalars['String']['input'];
}>;

export type FeatureIntroducedQuery = {
  __typename?: 'Query';
  featureIntroduced?: boolean | null;
};

export type GetJobTitleCrossLinksQueryVariables = Exact<{
  jobTitle: Scalars['String']['input'];
}>;

export type GetJobTitleCrossLinksQuery = {
  __typename?: 'Query';
  keyPlaces?: Array<{
    __typename?: 'PlaceType';
    googlePlaceId: string;
    city?: string | null;
    stateCode?: string | null;
    countryCode: PlaceCountryCode;
    name: string;
    image?: string | null;
  } | null> | null;
  relatedTitles?: Array<{
    __typename?: 'JobTitleType';
    normalizedTitle: string;
    title: string;
  } | null> | null;
};

export type SessionQueryQueryVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;

export type SessionQueryQuery = {
  __typename?: 'Query';
  session?: { __typename?: 'SessionType'; token?: string | null } | null;
};

export type StaffCustomerQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type StaffCustomerQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'UserType';
    id: string;
    email: string;
    name?: string | null;
    photo?: string | null;
    availableCredits?: any | null;
    hasIndividualAccount?: boolean | null;
    timeZone?: string | null;
    ledgerAccounts?: Array<{
      __typename?: 'LedgerAccountType';
      id: string;
      label?: string | null;
      balance: number;
    } | null> | null;
    coaches?: Array<{
      __typename?: 'AgentType';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      photo?: string | null;
      timeZone?: string | null;
      activeCoachProducts?: Array<{
        __typename?: 'CoachProductType';
        id?: number | null;
        product?: { __typename?: 'ProductType'; id: string } | null;
      } | null> | null;
      agentProfile?: {
        __typename?: 'AgentProfileType';
        id: string;
        summary?: string | null;
        averageRating?: number | null;
        acceptingNewBookings?: boolean | null;
        canHaveIntroSession?: boolean | null;
      } | null;
    } | null> | null;
    configuration?: {
      __typename?: 'ConfigurationType';
      configId?: string | null;
      version?: string | null;
      specialtyIncludeIds?: Array<string | null> | null;
      specialtyExcludeIds?: Array<string | null> | null;
      enableJobSearch?: boolean | null;
      enableReviews?: boolean | null;
    } | null;
  } | null;
};

export const WorkHistoriesFragmentFragmentDoc = gql`
  fragment WorkHistoriesFragment on AgentType {
    workHistories {
      id
      company {
        id
        logoUrl
        name
      }
      jobTitles
      emptyCompanyLabel
      startDate
      endDate
    }
  }
`;
export const StandardCredentialPartsFragmentDoc = gql`
  fragment StandardCredentialParts on StandardCredentialType {
    id
    name
    description
    issuer
    requirements
    badgeUrl
  }
`;
export const RoleplayFieldsFragmentDoc = gql`
  fragment RoleplayFields on RoleplayType {
    id
    name
    slug
    description
    introduction
    emojiUnicode
    visibilityScope
    priority
    canEdit
    owner {
      id
      name
      email
      photo
    }
    workspace {
      id
      name
    }
  }
`;
export const ScenarioFieldsFragmentDoc = gql`
  fragment ScenarioFields on ScenarioType {
    id
    name
    shortDesc
    difficulty
    slug
    context
    objective
    personaName
    personaFirstName
    personaLastName
    personaPhotoUrl
    assistantId
    voiceId
    visibilityScope
    visibleCriteriaBanner
    personalityCharacteristics
    canEdit
    introductionAudioUrl
    owner {
      id
      name
      email
      photo
    }
    personalityTraits {
      attributeId
      levelId
    }
    workspace {
      id
      name
    }
  }
`;
export const EvaluationCriterionFieldsFragmentDoc = gql`
  fragment EvaluationCriterionFields on EvaluationCriterionType {
    id
    name
    order
    items {
      id
      name
      order
      description
    }
  }
`;
export const ScenarioListItemFragmentDoc = gql`
  fragment ScenarioListItem on ScenarioType {
    id
    name
    shortDesc
    slug
    difficulty
    status
    rank
    personaName
    personaPhotoUrl
    visibilityScope
  }
`;
export const RoleplaySessionListItemFragmentDoc = gql`
  fragment RoleplaySessionListItem on RoleplaySessionType {
    id
    uuid
    rank
    updatedAt
    scenario {
      slug
    }
  }
`;
export const RoleplaySessionFieldsFragmentDoc = gql`
  fragment RoleplaySessionFields on RoleplaySessionType {
    id
    uuid
    score
    rank
    callId
    processingStatus
    file
    duration
    outcomeFeedback
    positiveFeedback
    constructiveFeedback
    transcript
    cleanMessages
    createdAt
    updatedAt
    isValidAttempt
    isValidAttemptExplanation
    recordingUrl
    evaluations {
      id
      grade
      feedback
      summary
      feedbackExamples(salience: [High, Mid]) {
        id
        feedback
        salience
        grade
        quote
        suggestionQuote
      }
      evaluationCriterionItem {
        id
        name
        evaluationCriterion {
          id
          name
          order
        }
      }
    }
  }
`;
export const ScenarioCollectionsFragmentDoc = gql`
  fragment ScenarioCollections on ScenarioType {
    id
    slug
    name
    collections {
      id
      name
      emojiUnicode
      slug
      canEdit
    }
  }
`;
export const RoleplaySessionCommentFragmentFragmentDoc = gql`
  fragment RoleplaySessionCommentFragment on RoleplaySessionCommentType {
    id
    text
    user
  }
`;
export const RoleplaySessionTranscriptFragmentFragmentDoc = gql`
  fragment RoleplaySessionTranscriptFragment on RoleplaySessionTranscriptLineType {
    id
    speakerId
    time
    endTime
    text
    duration
    secondsFromStart
    evaluatedCriterion(salience: [High, Mid]) {
      id
      name
      items {
        id
        name
        order
        feedbacks {
          id
          feedback
        }
      }
    }
    comments {
      ...RoleplaySessionCommentFragment
    }
    aiComments: comments(user: "AI") {
      ...RoleplaySessionCommentFragment
    }
    feedbacks(salience: [High, Mid]) {
      id
      feedback
      salience
      grade
      quote
      suggestionQuote
    }
  }
`;
export const ConfigurationFragmentFragmentDoc = gql`
  fragment ConfigurationFragment on ConfigurationType {
    configId
    enableJobSearch
    enableReviews
    specialtyIncludeIds
    specialtyExcludeIds
    configType
    maximumCreditPrice
    specialtyIncludes {
      id
      name
    }
    specialtyExcludes {
      id
      name
    }
    coachSpecIncludes {
      coach {
        id
        name
      }
      specialty {
        id
        name
      }
    }
    coachSpecExcludes {
      coach {
        id
        name
      }
      specialty {
        id
        name
      }
    }
  }
`;
export const WorkspaceAssistantClientFragmentFragmentDoc = gql`
  fragment WorkspaceAssistantClientFragment on WorkspaceMemberType {
    assistantClients {
      id
      client {
        id
        user {
          id
          name
          photo
          email
        }
      }
    }
  }
`;
export const WorkspaceAssistantFragmentFragmentDoc = gql`
  fragment WorkspaceAssistantFragment on WorkspaceMemberType {
    assistants {
      id
      assistant {
        id
        user {
          id
          name
          photo
          email
        }
      }
    }
  }
`;
export const GptResponseFragmentFragmentDoc = gql`
  fragment GptResponseFragment on GPTResponseType {
    id
    uuid
    response
    answers
    errorCode
    rating
    createdAt
  }
`;
export const CheckoutOrderCoachFragmentFragmentDoc = gql`
  fragment CheckoutOrderCoachFragment on AgentType {
    id
    name
    photo
    activeCoachProducts {
      id
      price
      product {
        id
        name
        introProduct
        specialtyId
      }
    }
  }
`;
export const UserAvailableCreditsFragmentFragmentDoc = gql`
  fragment UserAvailableCreditsFragment on UserType {
    availableCredits
    receivableCredits
    ledgerAccounts {
      id
      balance
      label
    }
  }
`;
export const CheckoutOrderFragmentFragmentDoc = gql`
  fragment CheckoutOrderFragment on OrderType {
    id
    key
    grossTotal
    total
    grossCreditTotal
    creditTotal
    grossCreditTotalInDollars
    creditTotalInDollars
    stripeClientSecret
    paymentType
    promoDiscount
    promoCreditDiscount
    promoCreditTotalInDollarsDiscount
    ledgerAccountId
    ledgerAccount {
      id
      label
      balance
    }
    orderItems {
      id
      grossTotal
      total
      price
      grossCreditTotal
      creditTotal
      grossCreditTotalInDollars
      creditTotalInDollars
      promoDiscount
      promoCreditDiscount
      promoCreditTotalInDollarsDiscount
      product {
        id
        name
        slug
        shortDescription
        introProduct
        category
        specialtyId
      }
    }
    promoCode {
      id
      code
    }
    user {
      id
      ...UserAvailableCreditsFragment
    }
  }
`;
export const MeetingFragmentFragmentDoc = gql`
  fragment MeetingFragment on MeetingType {
    id
    chatChannelId
    numMessages
    eventAt
    canceledAt
    cancelReason
    location
    customLocation
    duration
    customerId
    refundableUntil
    canLeaveRating
    paymentStatus
    googleEventId
    creditPrice
    allowInvitation
    allowGuests
    guests
    isBookingRequest
    coach {
      id
      name
      photo
      coachSlug
      email
    }
    customer {
      id
      name
      photo
      email
    }
    attendee {
      id
      userId
      status
      attendingStatus
      meetingId
    }
    workspace {
      id
      name
      urlSlug
    }
    product {
      id
      name
      slug
      duration
      category
      description
      introProduct
      enableGuests
    }
    rating {
      id
      score
      comment
      privateComment
    }
  }
`;
export const UserUpcomingMeetingsFragmentFragmentDoc = gql`
  fragment UserUpcomingMeetingsFragment on UserType {
    upcomingMeetings {
      ...MeetingFragment
    }
  }
`;
export const ReviewFragmentFragmentDoc = gql`
  fragment ReviewFragment on ReviewType {
    id
    chatChannelId
    status
    state
    nextDate
    numMessages
    acceptanceDeadline
    acceptedAt
    finalCheckRevisionSentAt
    canceledAt
    completedAt
    numRounds
    numRoundsComplete
    customerId
    canLeaveRating
    creditPrice
    coach {
      id
      name
      photo
    }
    customer {
      id
      name
      photo
    }
    product {
      id
      name
      slug
      turnaroundTime
      includesFinalCheck
      description
      numRounds
    }
    rating {
      id
      score
      comment
      privateComment
    }
  }
`;
export const UserOngoingReviewsFragmentFragmentDoc = gql`
  fragment UserOngoingReviewsFragment on UserType {
    ongoingReviews {
      ...ReviewFragment
    }
  }
`;
export const UserLiveProgramsFragmentFragmentDoc = gql`
  fragment UserLiveProgramsFragment on UserType {
    livePrograms {
      data {
        id
        name
        status
        targetLaunchDate
        targetEndDate
        isProgramLead
        isProgramParticipant
      }
    }
  }
`;
export const UserReviewsFragmentFragmentDoc = gql`
  fragment UserReviewsFragment on UserType {
    reviews {
      ...ReviewFragment
    }
  }
`;
export const UserMeetingsFragmentFragmentDoc = gql`
  fragment UserMeetingsFragment on UserType {
    meetings {
      ...MeetingFragment
    }
  }
`;
export const UserBookingRequestMeetingsFragmentFragmentDoc = gql`
  fragment UserBookingRequestMeetingsFragment on UserType {
    bookingRequestMeetings {
      ...MeetingFragment
    }
  }
`;
export const UserUnfulfilledOrdersFragmentFragmentDoc = gql`
  fragment UserUnfulfilledOrdersFragment on UserType {
    unfulfilledOrders {
      id
      paymentStatus
      cancelIfUnpaidBy
      workspace {
        id
        name
        urlSlug
      }
      orderItems {
        id
        fulfillmentStatus
        product {
          id
          name
          category
        }
        meetings {
          id
          eventAt
          coach {
            name
            photo
            email
          }
        }
        reviews {
          id
          coach {
            name
            photo
            email
          }
        }
      }
    }
  }
`;
export const UserConfigurationFragmentFragmentDoc = gql`
  fragment UserConfigurationFragment on UserType {
    configuration {
      configId
      version
      specialtyIncludeIds
      specialtyExcludeIds
      enableJobSearch
      enableReviews
    }
  }
`;
export const MeetingWithFormFragmentFragmentDoc = gql`
  fragment MeetingWithFormFragment on MeetingType {
    ...MeetingFragment
    expiredAt
    formFieldAnswers {
      id
      value
      userFile {
        id
        name
        url
      }
      optionValue {
        id
        value
        label
      }
      formField {
        id
        prompt
        answerType
      }
    }
  }
`;
export const ReviewWithFormFragmentFragmentDoc = gql`
  fragment ReviewWithFormFragment on ReviewType {
    ...ReviewFragment
    formFieldAnswers {
      id
      value
      userFile {
        id
        name
        url
      }
      optionValue {
        id
        value
        label
      }
      formField {
        id
        prompt
      }
    }
  }
`;
export const ReviewRoundsFragmentFragmentDoc = gql`
  fragment ReviewRoundsFragment on ReviewType {
    rounds {
      id
      completedAt
      revisionSentAt
    }
  }
`;
export const CoachBufferFragmentFragmentDoc = gql`
  fragment CoachBufferFragment on AgentProfileType {
    bufferAfter
    bufferBefore
    minuteIncrement
    minimumMeetingNotice
  }
`;
export const CoachProductPartsFragmentDoc = gql`
  fragment CoachProductParts on CoachProductType {
    id
    acceptingNewClients
    requireBookingRequest
    product {
      name
      id
      shortDescription
      category
      creditPrice
      introProduct
      enableRfq
      enableBookingRequest
      slug
      duration
      msrp
      price
      specialty {
        id
        name
        description
        slug
      }
    }
  }
`;
export const FaqAnswerPartsFragmentDoc = gql`
  fragment FaqAnswerParts on FaqAnswerType {
    id
    content
    videoUrl
    videoThumbnail
    faqQuestion {
      id
      content
    }
  }
`;
export const TodoPartsFragmentDoc = gql`
  fragment TodoParts on TodoType {
    id
    title
    dueAt
    url
    link {
      id
      url
      title
    }
    hideFromDashboard
    completedAt
    createdAt
  }
`;
export const LoggedInNavDataQueryDocument = gql`
  query LoggedInNavDataQuery {
    currentUser {
      id
      email
      name
      phoneNumber
      jobTitle
      linkedinAccount
      timeZone
      billingReady
      photo
      isAgent
      referralCode
      notificationPreferences {
        type
        email
      }
      agentProfile {
        id
        coachSlug
      }
    }
  }
`;

/**
 * __useLoggedInNavDataQueryQuery__
 *
 * To run a query within a React component, call `useLoggedInNavDataQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInNavDataQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInNavDataQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInNavDataQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoggedInNavDataQueryQuery,
    LoggedInNavDataQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoggedInNavDataQueryQuery,
    LoggedInNavDataQueryQueryVariables
  >(LoggedInNavDataQueryDocument, options);
}
export function useLoggedInNavDataQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoggedInNavDataQueryQuery,
    LoggedInNavDataQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoggedInNavDataQueryQuery,
    LoggedInNavDataQueryQueryVariables
  >(LoggedInNavDataQueryDocument, options);
}
export function useLoggedInNavDataQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoggedInNavDataQueryQuery,
        LoggedInNavDataQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoggedInNavDataQueryQuery,
    LoggedInNavDataQueryQueryVariables
  >(LoggedInNavDataQueryDocument, options);
}
export type LoggedInNavDataQueryQueryHookResult = ReturnType<
  typeof useLoggedInNavDataQueryQuery
>;
export type LoggedInNavDataQueryLazyQueryHookResult = ReturnType<
  typeof useLoggedInNavDataQueryLazyQuery
>;
export type LoggedInNavDataQuerySuspenseQueryHookResult = ReturnType<
  typeof useLoggedInNavDataQuerySuspenseQuery
>;
export type LoggedInNavDataQueryQueryResult = Apollo.QueryResult<
  LoggedInNavDataQueryQuery,
  LoggedInNavDataQueryQueryVariables
>;
export const ResendEmailConfirmationDocument = gql`
  mutation ResendEmailConfirmation {
    resendEmailConfirmation {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type ResendEmailConfirmationMutationFn = Apollo.MutationFunction<
  ResendEmailConfirmationMutation,
  ResendEmailConfirmationMutationVariables
>;

/**
 * __useResendEmailConfirmationMutation__
 *
 * To run a mutation, you first call `useResendEmailConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailConfirmationMutation, { data, loading, error }] = useResendEmailConfirmationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendEmailConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendEmailConfirmationMutation,
    ResendEmailConfirmationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendEmailConfirmationMutation,
    ResendEmailConfirmationMutationVariables
  >(ResendEmailConfirmationDocument, options);
}
export type ResendEmailConfirmationMutationHookResult = ReturnType<
  typeof useResendEmailConfirmationMutation
>;
export type ResendEmailConfirmationMutationResult =
  Apollo.MutationResult<ResendEmailConfirmationMutation>;
export type ResendEmailConfirmationMutationOptions = Apollo.BaseMutationOptions<
  ResendEmailConfirmationMutation,
  ResendEmailConfirmationMutationVariables
>;
export const ArticleSeriesDocument = gql`
  query ArticleSeries($slug: String!) {
    articleSeries(slug: $slug) {
      id
      title
      urlSlug
      description
      canonicalDomain
      relatedSeries {
        id
        title
        urlSlug
      }
    }
  }
`;

/**
 * __useArticleSeriesQuery__
 *
 * To run a query within a React component, call `useArticleSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleSeriesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArticleSeriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticleSeriesQuery,
    ArticleSeriesQueryVariables
  > &
    (
      | { variables: ArticleSeriesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticleSeriesQuery, ArticleSeriesQueryVariables>(
    ArticleSeriesDocument,
    options,
  );
}
export function useArticleSeriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleSeriesQuery,
    ArticleSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticleSeriesQuery, ArticleSeriesQueryVariables>(
    ArticleSeriesDocument,
    options,
  );
}
export function useArticleSeriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ArticleSeriesQuery,
        ArticleSeriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ArticleSeriesQuery,
    ArticleSeriesQueryVariables
  >(ArticleSeriesDocument, options);
}
export type ArticleSeriesQueryHookResult = ReturnType<
  typeof useArticleSeriesQuery
>;
export type ArticleSeriesLazyQueryHookResult = ReturnType<
  typeof useArticleSeriesLazyQuery
>;
export type ArticleSeriesSuspenseQueryHookResult = ReturnType<
  typeof useArticleSeriesSuspenseQuery
>;
export type ArticleSeriesQueryResult = Apollo.QueryResult<
  ArticleSeriesQuery,
  ArticleSeriesQueryVariables
>;
export const ChooseCoachIntroProductDocument = gql`
  query ChooseCoachIntroProduct($specialtySlug: String!) {
    currentUser {
      id
      email
      intakeForm {
        id
        token
      }
    }
    introProduct(specialtySlug: $specialtySlug) {
      id
      name
      slug
      category
    }
  }
`;

/**
 * __useChooseCoachIntroProductQuery__
 *
 * To run a query within a React component, call `useChooseCoachIntroProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useChooseCoachIntroProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChooseCoachIntroProductQuery({
 *   variables: {
 *      specialtySlug: // value for 'specialtySlug'
 *   },
 * });
 */
export function useChooseCoachIntroProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChooseCoachIntroProductQuery,
    ChooseCoachIntroProductQueryVariables
  > &
    (
      | { variables: ChooseCoachIntroProductQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChooseCoachIntroProductQuery,
    ChooseCoachIntroProductQueryVariables
  >(ChooseCoachIntroProductDocument, options);
}
export function useChooseCoachIntroProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChooseCoachIntroProductQuery,
    ChooseCoachIntroProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChooseCoachIntroProductQuery,
    ChooseCoachIntroProductQueryVariables
  >(ChooseCoachIntroProductDocument, options);
}
export function useChooseCoachIntroProductSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChooseCoachIntroProductQuery,
        ChooseCoachIntroProductQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ChooseCoachIntroProductQuery,
    ChooseCoachIntroProductQueryVariables
  >(ChooseCoachIntroProductDocument, options);
}
export type ChooseCoachIntroProductQueryHookResult = ReturnType<
  typeof useChooseCoachIntroProductQuery
>;
export type ChooseCoachIntroProductLazyQueryHookResult = ReturnType<
  typeof useChooseCoachIntroProductLazyQuery
>;
export type ChooseCoachIntroProductSuspenseQueryHookResult = ReturnType<
  typeof useChooseCoachIntroProductSuspenseQuery
>;
export type ChooseCoachIntroProductQueryResult = Apollo.QueryResult<
  ChooseCoachIntroProductQuery,
  ChooseCoachIntroProductQueryVariables
>;
export const ChooseCoachProductsDocument = gql`
  query ChooseCoachProducts($slugs: String, $orderId: ID) {
    currentUser {
      id
      email
      intakeForm {
        id
        token
      }
    }
    products(slugs: $slugs, orderId: $orderId) {
      id
      name
      slug
      category
    }
  }
`;

/**
 * __useChooseCoachProductsQuery__
 *
 * To run a query within a React component, call `useChooseCoachProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChooseCoachProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChooseCoachProductsQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useChooseCoachProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChooseCoachProductsQuery,
    ChooseCoachProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChooseCoachProductsQuery,
    ChooseCoachProductsQueryVariables
  >(ChooseCoachProductsDocument, options);
}
export function useChooseCoachProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChooseCoachProductsQuery,
    ChooseCoachProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChooseCoachProductsQuery,
    ChooseCoachProductsQueryVariables
  >(ChooseCoachProductsDocument, options);
}
export function useChooseCoachProductsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChooseCoachProductsQuery,
        ChooseCoachProductsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ChooseCoachProductsQuery,
    ChooseCoachProductsQueryVariables
  >(ChooseCoachProductsDocument, options);
}
export type ChooseCoachProductsQueryHookResult = ReturnType<
  typeof useChooseCoachProductsQuery
>;
export type ChooseCoachProductsLazyQueryHookResult = ReturnType<
  typeof useChooseCoachProductsLazyQuery
>;
export type ChooseCoachProductsSuspenseQueryHookResult = ReturnType<
  typeof useChooseCoachProductsSuspenseQuery
>;
export type ChooseCoachProductsQueryResult = Apollo.QueryResult<
  ChooseCoachProductsQuery,
  ChooseCoachProductsQueryVariables
>;
export const AgentProfileDocument = gql`
  query agentProfile(
    $coachSlug: String
    $id: ID
    $userId: ID
    $customerId: ID
    $specialtySlug: String
  ) {
    agentProfile(
      coachSlug: $coachSlug
      id: $id
      userId: $userId
      customerId: $customerId
      specialtySlug: $specialtySlug
    ) {
      id
      seoIndex
      primarySite
      coachSlug
      user {
        id
        name
        firstName
        lastName
        photo
        referralCode
        ...WorkHistoriesFragment
      }
      about
      summary
      createdAt
      seoPhoto
      averageRating
      credentials
      standardCredentials {
        ...StandardCredentialParts
      }
      introVideo
      introVideoThumbnail
      relevantIndustries {
        id
        name
      }
      relevantJobFunctions {
        id
        name
      }
      availability {
        date
        slots
      }
      matchingIndustries
      matchingProficiencies
      matchingJobFunctions
      acceptingNewClients
      acceptingNewBookings
      canHaveIntroSession
      faqAnswers {
        ...FaqAnswerParts
      }
      testimonials {
        id
        name
        message
        jobTitle
        avatarUrl
      }
    }
    specialties(coachSlug: $coachSlug, specialtySlug: $specialtySlug) {
      id
      slug
      name
      introProduct {
        id
        slug
      }
    }
  }
  ${WorkHistoriesFragmentFragmentDoc}
  ${StandardCredentialPartsFragmentDoc}
  ${FaqAnswerPartsFragmentDoc}
`;

/**
 * __useAgentProfileQuery__
 *
 * To run a query within a React component, call `useAgentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentProfileQuery({
 *   variables: {
 *      coachSlug: // value for 'coachSlug'
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      customerId: // value for 'customerId'
 *      specialtySlug: // value for 'specialtySlug'
 *   },
 * });
 */
export function useAgentProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AgentProfileQuery,
    AgentProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgentProfileQuery, AgentProfileQueryVariables>(
    AgentProfileDocument,
    options,
  );
}
export function useAgentProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgentProfileQuery,
    AgentProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgentProfileQuery, AgentProfileQueryVariables>(
    AgentProfileDocument,
    options,
  );
}
export function useAgentProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AgentProfileQuery,
        AgentProfileQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AgentProfileQuery, AgentProfileQueryVariables>(
    AgentProfileDocument,
    options,
  );
}
export type AgentProfileQueryHookResult = ReturnType<
  typeof useAgentProfileQuery
>;
export type AgentProfileLazyQueryHookResult = ReturnType<
  typeof useAgentProfileLazyQuery
>;
export type AgentProfileSuspenseQueryHookResult = ReturnType<
  typeof useAgentProfileSuspenseQuery
>;
export type AgentProfileQueryResult = Apollo.QueryResult<
  AgentProfileQuery,
  AgentProfileQueryVariables
>;
export const CompanyForDisplayDetailDocument = gql`
  query CompanyForDisplayDetail($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      domain
      description
      founded
      size
      companyLogo
      canonicalIndustry {
        id
        name
      }
      fullyRemote
      twitter
      facebook
      linkedin
      crunchbase
      companySeoSlug
      headquarters {
        id
        googlePlaceId
        city
        stateCode
        name
      }
    }
  }
`;

/**
 * __useCompanyForDisplayDetailQuery__
 *
 * To run a query within a React component, call `useCompanyForDisplayDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyForDisplayDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyForDisplayDetailQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyForDisplayDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyForDisplayDetailQuery,
    CompanyForDisplayDetailQueryVariables
  > &
    (
      | { variables: CompanyForDisplayDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyForDisplayDetailQuery,
    CompanyForDisplayDetailQueryVariables
  >(CompanyForDisplayDetailDocument, options);
}
export function useCompanyForDisplayDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyForDisplayDetailQuery,
    CompanyForDisplayDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyForDisplayDetailQuery,
    CompanyForDisplayDetailQueryVariables
  >(CompanyForDisplayDetailDocument, options);
}
export function useCompanyForDisplayDetailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyForDisplayDetailQuery,
        CompanyForDisplayDetailQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyForDisplayDetailQuery,
    CompanyForDisplayDetailQueryVariables
  >(CompanyForDisplayDetailDocument, options);
}
export type CompanyForDisplayDetailQueryHookResult = ReturnType<
  typeof useCompanyForDisplayDetailQuery
>;
export type CompanyForDisplayDetailLazyQueryHookResult = ReturnType<
  typeof useCompanyForDisplayDetailLazyQuery
>;
export type CompanyForDisplayDetailSuspenseQueryHookResult = ReturnType<
  typeof useCompanyForDisplayDetailSuspenseQuery
>;
export type CompanyForDisplayDetailQueryResult = Apollo.QueryResult<
  CompanyForDisplayDetailQuery,
  CompanyForDisplayDetailQueryVariables
>;
export const ServiceDetailDocument = gql`
  query ServiceDetail($productSlug: String, $coachSlugs: [String]) {
    product(slug: $productSlug, coachSlugs: $coachSlugs) {
      id
      slug
      name
      primarySite
      category
      shortDescription
      description
      duration
      enableBookingRequest
      coachEnableBookingRequest
      baseParticipants
      maxParticipants
      numRounds
      includesFinalCheck
      turnaroundTime
      enableRfq
      metaImageUrl
      numRatings
      averageRating
      specialty {
        id
        name
      }
      activeFormFields {
        id
        prompt
        answerType
        helpText
        required
        formFieldOptions {
          id
          label
          value
        }
      }
      detailGroups {
        id
        label
        displayType
      }
      detailLines {
        id
        header
        text
        detailGroupId
      }
      faqAnswers {
        ...FaqAnswerParts
      }
      endorsements {
        id
        company {
          id
          name
          companyLogo
        }
      }
      priceRange {
        minCreditPrice
        maxCreditPrice
        minUsdPrice
        maxUsdPrice
      }
      userCoachProducts {
        requireBookingRequest
        creditPrice
        usdPrice
        locked
        lockReason
        coach {
          id
          name
          photo
          agentProfile {
            id
            summary
            averageRating
            credentials
            standardCredentials {
              ...StandardCredentialParts
            }
            coachSlug
            user {
              id
              ...WorkHistoriesFragment
            }
          }
        }
      }
    }
  }
  ${FaqAnswerPartsFragmentDoc}
  ${StandardCredentialPartsFragmentDoc}
  ${WorkHistoriesFragmentFragmentDoc}
`;

/**
 * __useServiceDetailQuery__
 *
 * To run a query within a React component, call `useServiceDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceDetailQuery({
 *   variables: {
 *      productSlug: // value for 'productSlug'
 *      coachSlugs: // value for 'coachSlugs'
 *   },
 * });
 */
export function useServiceDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ServiceDetailQuery,
    ServiceDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceDetailQuery, ServiceDetailQueryVariables>(
    ServiceDetailDocument,
    options,
  );
}
export function useServiceDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceDetailQuery,
    ServiceDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceDetailQuery, ServiceDetailQueryVariables>(
    ServiceDetailDocument,
    options,
  );
}
export function useServiceDetailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceDetailQuery,
        ServiceDetailQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ServiceDetailQuery,
    ServiceDetailQueryVariables
  >(ServiceDetailDocument, options);
}
export type ServiceDetailQueryHookResult = ReturnType<
  typeof useServiceDetailQuery
>;
export type ServiceDetailLazyQueryHookResult = ReturnType<
  typeof useServiceDetailLazyQuery
>;
export type ServiceDetailSuspenseQueryHookResult = ReturnType<
  typeof useServiceDetailSuspenseQuery
>;
export type ServiceDetailQueryResult = Apollo.QueryResult<
  ServiceDetailQuery,
  ServiceDetailQueryVariables
>;
export const SubmitRfqDocument = gql`
  mutation SubmitRfq(
    $productId: ID!
    $coachId: ID!
    $formData: GenericScalar
    $name: String
    $email: String
  ) {
    submitRfq(
      productId: $productId
      coachId: $coachId
      formData: $formData
      name: $name
      email: $email
    ) {
      ok
      error {
        message
      }
    }
  }
`;
export type SubmitRfqMutationFn = Apollo.MutationFunction<
  SubmitRfqMutation,
  SubmitRfqMutationVariables
>;

/**
 * __useSubmitRfqMutation__
 *
 * To run a mutation, you first call `useSubmitRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRfqMutation, { data, loading, error }] = useSubmitRfqMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      coachId: // value for 'coachId'
 *      formData: // value for 'formData'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSubmitRfqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitRfqMutation,
    SubmitRfqMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitRfqMutation, SubmitRfqMutationVariables>(
    SubmitRfqDocument,
    options,
  );
}
export type SubmitRfqMutationHookResult = ReturnType<
  typeof useSubmitRfqMutation
>;
export type SubmitRfqMutationResult = Apollo.MutationResult<SubmitRfqMutation>;
export type SubmitRfqMutationOptions = Apollo.BaseMutationOptions<
  SubmitRfqMutation,
  SubmitRfqMutationVariables
>;
export const TopArticleSeriesDocument = gql`
  query TopArticleSeries($limit: Int) {
    topArticleSeries(limit: $limit) {
      id
      description
      urlSlug
      title
    }
  }
`;

/**
 * __useTopArticleSeriesQuery__
 *
 * To run a query within a React component, call `useTopArticleSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopArticleSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopArticleSeriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTopArticleSeriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TopArticleSeriesQuery,
    TopArticleSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopArticleSeriesQuery, TopArticleSeriesQueryVariables>(
    TopArticleSeriesDocument,
    options,
  );
}
export function useTopArticleSeriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopArticleSeriesQuery,
    TopArticleSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TopArticleSeriesQuery,
    TopArticleSeriesQueryVariables
  >(TopArticleSeriesDocument, options);
}
export function useTopArticleSeriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TopArticleSeriesQuery,
        TopArticleSeriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TopArticleSeriesQuery,
    TopArticleSeriesQueryVariables
  >(TopArticleSeriesDocument, options);
}
export type TopArticleSeriesQueryHookResult = ReturnType<
  typeof useTopArticleSeriesQuery
>;
export type TopArticleSeriesLazyQueryHookResult = ReturnType<
  typeof useTopArticleSeriesLazyQuery
>;
export type TopArticleSeriesSuspenseQueryHookResult = ReturnType<
  typeof useTopArticleSeriesSuspenseQuery
>;
export type TopArticleSeriesQueryResult = Apollo.QueryResult<
  TopArticleSeriesQuery,
  TopArticleSeriesQueryVariables
>;
export const BasicProductsDocument = gql`
  query BasicProducts($productSlugs: String, $orderId: ID, $productId: ID) {
    products(slugs: $productSlugs, orderId: $orderId, productId: $productId) {
      id
      slug
      specialty {
        id
        slug
      }
    }
  }
`;

/**
 * __useBasicProductsQuery__
 *
 * To run a query within a React component, call `useBasicProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicProductsQuery({
 *   variables: {
 *      productSlugs: // value for 'productSlugs'
 *      orderId: // value for 'orderId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useBasicProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BasicProductsQuery,
    BasicProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BasicProductsQuery, BasicProductsQueryVariables>(
    BasicProductsDocument,
    options,
  );
}
export function useBasicProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BasicProductsQuery,
    BasicProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BasicProductsQuery, BasicProductsQueryVariables>(
    BasicProductsDocument,
    options,
  );
}
export function useBasicProductsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BasicProductsQuery,
        BasicProductsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BasicProductsQuery,
    BasicProductsQueryVariables
  >(BasicProductsDocument, options);
}
export type BasicProductsQueryHookResult = ReturnType<
  typeof useBasicProductsQuery
>;
export type BasicProductsLazyQueryHookResult = ReturnType<
  typeof useBasicProductsLazyQuery
>;
export type BasicProductsSuspenseQueryHookResult = ReturnType<
  typeof useBasicProductsSuspenseQuery
>;
export type BasicProductsQueryResult = Apollo.QueryResult<
  BasicProductsQuery,
  BasicProductsQueryVariables
>;
export const BasicSpecialtiesDocument = gql`
  query BasicSpecialties {
    specialties {
      id
      name
      slug
      iconUrl
      shortDescription
      hiddenInMatchFlow
    }
  }
`;

/**
 * __useBasicSpecialtiesQuery__
 *
 * To run a query within a React component, call `useBasicSpecialtiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicSpecialtiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicSpecialtiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBasicSpecialtiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BasicSpecialtiesQuery,
    BasicSpecialtiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BasicSpecialtiesQuery, BasicSpecialtiesQueryVariables>(
    BasicSpecialtiesDocument,
    options,
  );
}
export function useBasicSpecialtiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BasicSpecialtiesQuery,
    BasicSpecialtiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BasicSpecialtiesQuery,
    BasicSpecialtiesQueryVariables
  >(BasicSpecialtiesDocument, options);
}
export function useBasicSpecialtiesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BasicSpecialtiesQuery,
        BasicSpecialtiesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BasicSpecialtiesQuery,
    BasicSpecialtiesQueryVariables
  >(BasicSpecialtiesDocument, options);
}
export type BasicSpecialtiesQueryHookResult = ReturnType<
  typeof useBasicSpecialtiesQuery
>;
export type BasicSpecialtiesLazyQueryHookResult = ReturnType<
  typeof useBasicSpecialtiesLazyQuery
>;
export type BasicSpecialtiesSuspenseQueryHookResult = ReturnType<
  typeof useBasicSpecialtiesSuspenseQuery
>;
export type BasicSpecialtiesQueryResult = Apollo.QueryResult<
  BasicSpecialtiesQuery,
  BasicSpecialtiesQueryVariables
>;
export const AgentProfilesDocument = gql`
  query AgentProfiles(
    $productSlug: String!
    $intakeFormToken: String
    $timeZone: String
    $numCoaches: Int
    $includeCurrent: Boolean
  ) {
    agentProfiles(
      productSlug: $productSlug
      intakeFormToken: $intakeFormToken
      timeZone: $timeZone
      numCoaches: $numCoaches
      includeCurrent: $includeCurrent
    ) {
      id
      user {
        id
        firstName
        lastName
        name
        photo
        activeCoachProducts {
          ...CoachProductParts
        }
        ...WorkHistoriesFragment
      }
      matchingProficiencies
      matchingJobTitles
      matchingIndustries
      matchingJobFunctions
      availability {
        date
        slots
      }
      summary
      about
      introVideo
      introVideoThumbnail
      credentials
      standardCredentials {
        ...StandardCredentialParts
      }
      averageRating
      canHaveIntroSession
      faqAnswers {
        id
      }
    }
  }
  ${CoachProductPartsFragmentDoc}
  ${WorkHistoriesFragmentFragmentDoc}
  ${StandardCredentialPartsFragmentDoc}
`;

/**
 * __useAgentProfilesQuery__
 *
 * To run a query within a React component, call `useAgentProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentProfilesQuery({
 *   variables: {
 *      productSlug: // value for 'productSlug'
 *      intakeFormToken: // value for 'intakeFormToken'
 *      timeZone: // value for 'timeZone'
 *      numCoaches: // value for 'numCoaches'
 *      includeCurrent: // value for 'includeCurrent'
 *   },
 * });
 */
export function useAgentProfilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgentProfilesQuery,
    AgentProfilesQueryVariables
  > &
    (
      | { variables: AgentProfilesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgentProfilesQuery, AgentProfilesQueryVariables>(
    AgentProfilesDocument,
    options,
  );
}
export function useAgentProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgentProfilesQuery,
    AgentProfilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgentProfilesQuery, AgentProfilesQueryVariables>(
    AgentProfilesDocument,
    options,
  );
}
export function useAgentProfilesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AgentProfilesQuery,
        AgentProfilesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AgentProfilesQuery,
    AgentProfilesQueryVariables
  >(AgentProfilesDocument, options);
}
export type AgentProfilesQueryHookResult = ReturnType<
  typeof useAgentProfilesQuery
>;
export type AgentProfilesLazyQueryHookResult = ReturnType<
  typeof useAgentProfilesLazyQuery
>;
export type AgentProfilesSuspenseQueryHookResult = ReturnType<
  typeof useAgentProfilesSuspenseQuery
>;
export type AgentProfilesQueryResult = Apollo.QueryResult<
  AgentProfilesQuery,
  AgentProfilesQueryVariables
>;
export const CoachProfileDocument = gql`
  query CoachProfile($id: ID!) {
    coach(id: $id) {
      id
      firstName
      lastName
      name
      photo
      agentProfile {
        id
        matchingProficiencies
        matchingJobTitles
        matchingIndustries
        summary
        about
        introVideo
        credentials
        averageRating
      }
    }
  }
`;

/**
 * __useCoachProfileQuery__
 *
 * To run a query within a React component, call `useCoachProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCoachProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachProfileQuery,
    CoachProfileQueryVariables
  > &
    (
      | { variables: CoachProfileQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachProfileQuery, CoachProfileQueryVariables>(
    CoachProfileDocument,
    options,
  );
}
export function useCoachProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachProfileQuery,
    CoachProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachProfileQuery, CoachProfileQueryVariables>(
    CoachProfileDocument,
    options,
  );
}
export function useCoachProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachProfileQuery,
        CoachProfileQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoachProfileQuery, CoachProfileQueryVariables>(
    CoachProfileDocument,
    options,
  );
}
export type CoachProfileQueryHookResult = ReturnType<
  typeof useCoachProfileQuery
>;
export type CoachProfileLazyQueryHookResult = ReturnType<
  typeof useCoachProfileLazyQuery
>;
export type CoachProfileSuspenseQueryHookResult = ReturnType<
  typeof useCoachProfileSuspenseQuery
>;
export type CoachProfileQueryResult = Apollo.QueryResult<
  CoachProfileQuery,
  CoachProfileQueryVariables
>;
export const FindMyAgentDocument = gql`
  query FindMyAgent {
    optionalCurrentUser {
      id
      email
      name
      intakeForm {
        id
        token
        matchingOptions {
          id
        }
        industries {
          id
          name
        }
        industriesSkipped
        specialty {
          id
          name
          slug
        }
        jobTitles {
          normalizedTitle
          title
        }
        jobTitlesSkipped
        jobFunctions {
          name
          id
        }
        jobFunctionsSkipped
        coach {
          id
          name
          firstName
          photo
        }
        assignment {
          id
          coach {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useFindMyAgentQuery__
 *
 * To run a query within a React component, call `useFindMyAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyAgentQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindMyAgentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindMyAgentQuery,
    FindMyAgentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindMyAgentQuery, FindMyAgentQueryVariables>(
    FindMyAgentDocument,
    options,
  );
}
export function useFindMyAgentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindMyAgentQuery,
    FindMyAgentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindMyAgentQuery, FindMyAgentQueryVariables>(
    FindMyAgentDocument,
    options,
  );
}
export function useFindMyAgentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindMyAgentQuery,
        FindMyAgentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindMyAgentQuery, FindMyAgentQueryVariables>(
    FindMyAgentDocument,
    options,
  );
}
export type FindMyAgentQueryHookResult = ReturnType<typeof useFindMyAgentQuery>;
export type FindMyAgentLazyQueryHookResult = ReturnType<
  typeof useFindMyAgentLazyQuery
>;
export type FindMyAgentSuspenseQueryHookResult = ReturnType<
  typeof useFindMyAgentSuspenseQuery
>;
export type FindMyAgentQueryResult = Apollo.QueryResult<
  FindMyAgentQuery,
  FindMyAgentQueryVariables
>;
export const FindMyAgentCheckoutDocument = gql`
  mutation FindMyAgentCheckout(
    $successUrl: String
    $cancelUrl: String
    $ctaType: String
    $ctaSource: String
  ) {
    findMyAgentCheckout(
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      ctaType: $ctaType
      ctaSource: $ctaSource
    ) {
      id
      errors {
        field
        message
      }
    }
  }
`;
export type FindMyAgentCheckoutMutationFn = Apollo.MutationFunction<
  FindMyAgentCheckoutMutation,
  FindMyAgentCheckoutMutationVariables
>;

/**
 * __useFindMyAgentCheckoutMutation__
 *
 * To run a mutation, you first call `useFindMyAgentCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindMyAgentCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findMyAgentCheckoutMutation, { data, loading, error }] = useFindMyAgentCheckoutMutation({
 *   variables: {
 *      successUrl: // value for 'successUrl'
 *      cancelUrl: // value for 'cancelUrl'
 *      ctaType: // value for 'ctaType'
 *      ctaSource: // value for 'ctaSource'
 *   },
 * });
 */
export function useFindMyAgentCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FindMyAgentCheckoutMutation,
    FindMyAgentCheckoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FindMyAgentCheckoutMutation,
    FindMyAgentCheckoutMutationVariables
  >(FindMyAgentCheckoutDocument, options);
}
export type FindMyAgentCheckoutMutationHookResult = ReturnType<
  typeof useFindMyAgentCheckoutMutation
>;
export type FindMyAgentCheckoutMutationResult =
  Apollo.MutationResult<FindMyAgentCheckoutMutation>;
export type FindMyAgentCheckoutMutationOptions = Apollo.BaseMutationOptions<
  FindMyAgentCheckoutMutation,
  FindMyAgentCheckoutMutationVariables
>;
export const MatchingCategoriesDocument = gql`
  query MatchingCategories($specialtySlug: String!) {
    matchingCategories(specialtySlug: $specialtySlug) {
      id
      name
      description
      selectionType
      matchingOptions {
        id
        name
        iconUrl
        description
      }
    }
  }
`;

/**
 * __useMatchingCategoriesQuery__
 *
 * To run a query within a React component, call `useMatchingCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchingCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchingCategoriesQuery({
 *   variables: {
 *      specialtySlug: // value for 'specialtySlug'
 *   },
 * });
 */
export function useMatchingCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MatchingCategoriesQuery,
    MatchingCategoriesQueryVariables
  > &
    (
      | { variables: MatchingCategoriesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MatchingCategoriesQuery,
    MatchingCategoriesQueryVariables
  >(MatchingCategoriesDocument, options);
}
export function useMatchingCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MatchingCategoriesQuery,
    MatchingCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MatchingCategoriesQuery,
    MatchingCategoriesQueryVariables
  >(MatchingCategoriesDocument, options);
}
export function useMatchingCategoriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MatchingCategoriesQuery,
        MatchingCategoriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MatchingCategoriesQuery,
    MatchingCategoriesQueryVariables
  >(MatchingCategoriesDocument, options);
}
export type MatchingCategoriesQueryHookResult = ReturnType<
  typeof useMatchingCategoriesQuery
>;
export type MatchingCategoriesLazyQueryHookResult = ReturnType<
  typeof useMatchingCategoriesLazyQuery
>;
export type MatchingCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useMatchingCategoriesSuspenseQuery
>;
export type MatchingCategoriesQueryResult = Apollo.QueryResult<
  MatchingCategoriesQuery,
  MatchingCategoriesQueryVariables
>;
export const SaveIntakeFormDocument = gql`
  mutation SaveIntakeForm(
    $input: IntakeFormInput!
    $token: String
    $productSlug: String
    $orderId: ID
  ) {
    saveIntakeForm(
      input: $input
      token: $token
      orderId: $orderId
      productSlug: $productSlug
    ) {
      ok
      token
      userId
      orderId
      errors {
        field
        message
      }
    }
  }
`;
export type SaveIntakeFormMutationFn = Apollo.MutationFunction<
  SaveIntakeFormMutation,
  SaveIntakeFormMutationVariables
>;

/**
 * __useSaveIntakeFormMutation__
 *
 * To run a mutation, you first call `useSaveIntakeFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveIntakeFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveIntakeFormMutation, { data, loading, error }] = useSaveIntakeFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *      token: // value for 'token'
 *      productSlug: // value for 'productSlug'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSaveIntakeFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveIntakeFormMutation,
    SaveIntakeFormMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveIntakeFormMutation,
    SaveIntakeFormMutationVariables
  >(SaveIntakeFormDocument, options);
}
export type SaveIntakeFormMutationHookResult = ReturnType<
  typeof useSaveIntakeFormMutation
>;
export type SaveIntakeFormMutationResult =
  Apollo.MutationResult<SaveIntakeFormMutation>;
export type SaveIntakeFormMutationOptions = Apollo.BaseMutationOptions<
  SaveIntakeFormMutation,
  SaveIntakeFormMutationVariables
>;
export const UpdateCoachInfoDocument = gql`
  mutation UpdateCoachInfo(
    $about: String!
    $summary: String!
    $jobFunctionIds: [String]!
    $industryIds: [String]!
    $specialtySlug: String
  ) {
    updateCoachInfo(
      about: $about
      summary: $summary
      jobFunctionIds: $jobFunctionIds
      industryIds: $industryIds
      specialtySlug: $specialtySlug
    ) {
      ok
      error {
        message
        code
      }
    }
  }
`;
export type UpdateCoachInfoMutationFn = Apollo.MutationFunction<
  UpdateCoachInfoMutation,
  UpdateCoachInfoMutationVariables
>;

/**
 * __useUpdateCoachInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCoachInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachInfoMutation, { data, loading, error }] = useUpdateCoachInfoMutation({
 *   variables: {
 *      about: // value for 'about'
 *      summary: // value for 'summary'
 *      jobFunctionIds: // value for 'jobFunctionIds'
 *      industryIds: // value for 'industryIds'
 *      specialtySlug: // value for 'specialtySlug'
 *   },
 * });
 */
export function useUpdateCoachInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachInfoMutation,
    UpdateCoachInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCoachInfoMutation,
    UpdateCoachInfoMutationVariables
  >(UpdateCoachInfoDocument, options);
}
export type UpdateCoachInfoMutationHookResult = ReturnType<
  typeof useUpdateCoachInfoMutation
>;
export type UpdateCoachInfoMutationResult =
  Apollo.MutationResult<UpdateCoachInfoMutation>;
export type UpdateCoachInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoachInfoMutation,
  UpdateCoachInfoMutationVariables
>;
export const UpdateCoachProfileDocument = gql`
  mutation UpdateCoachProfile(
    $acceptingNewClients: Boolean!
    $acceptingNewBookings: Boolean!
  ) {
    updateCoachProfile(
      acceptingNewClients: $acceptingNewClients
      acceptingNewBookings: $acceptingNewBookings
    ) {
      ok
    }
  }
`;
export type UpdateCoachProfileMutationFn = Apollo.MutationFunction<
  UpdateCoachProfileMutation,
  UpdateCoachProfileMutationVariables
>;

/**
 * __useUpdateCoachProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCoachProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachProfileMutation, { data, loading, error }] = useUpdateCoachProfileMutation({
 *   variables: {
 *      acceptingNewClients: // value for 'acceptingNewClients'
 *      acceptingNewBookings: // value for 'acceptingNewBookings'
 *   },
 * });
 */
export function useUpdateCoachProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachProfileMutation,
    UpdateCoachProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCoachProfileMutation,
    UpdateCoachProfileMutationVariables
  >(UpdateCoachProfileDocument, options);
}
export type UpdateCoachProfileMutationHookResult = ReturnType<
  typeof useUpdateCoachProfileMutation
>;
export type UpdateCoachProfileMutationResult =
  Apollo.MutationResult<UpdateCoachProfileMutation>;
export type UpdateCoachProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoachProfileMutation,
  UpdateCoachProfileMutationVariables
>;
export const ReferralsQueryDocument = gql`
  query ReferralsQuery {
    currentUser {
      id
      isAgent
      referralCode
      referrals {
        id
        createdAt
        referralType
        acquisitionBonusEligibleAt
        acquisitionBonusPaidAmount
        referralBonusPaid
      }
    }
  }
`;

/**
 * __useReferralsQueryQuery__
 *
 * To run a query within a React component, call `useReferralsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReferralsQueryQuery,
    ReferralsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferralsQueryQuery, ReferralsQueryQueryVariables>(
    ReferralsQueryDocument,
    options,
  );
}
export function useReferralsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferralsQueryQuery,
    ReferralsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferralsQueryQuery, ReferralsQueryQueryVariables>(
    ReferralsQueryDocument,
    options,
  );
}
export function useReferralsQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferralsQueryQuery,
        ReferralsQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReferralsQueryQuery,
    ReferralsQueryQueryVariables
  >(ReferralsQueryDocument, options);
}
export type ReferralsQueryQueryHookResult = ReturnType<
  typeof useReferralsQueryQuery
>;
export type ReferralsQueryLazyQueryHookResult = ReturnType<
  typeof useReferralsQueryLazyQuery
>;
export type ReferralsQuerySuspenseQueryHookResult = ReturnType<
  typeof useReferralsQuerySuspenseQuery
>;
export type ReferralsQueryQueryResult = Apollo.QueryResult<
  ReferralsQueryQuery,
  ReferralsQueryQueryVariables
>;
export const AssignInitialWorkspaceAdminDocument = gql`
  mutation assignInitialWorkspaceAdmin($workspaceSignedToken: String!) {
    assignInitialWorkspaceAdmin(workspaceSignedToken: $workspaceSignedToken) {
      ok
      errors {
        field
        message
      }
      urlSlug
    }
  }
`;
export type AssignInitialWorkspaceAdminMutationFn = Apollo.MutationFunction<
  AssignInitialWorkspaceAdminMutation,
  AssignInitialWorkspaceAdminMutationVariables
>;

/**
 * __useAssignInitialWorkspaceAdminMutation__
 *
 * To run a mutation, you first call `useAssignInitialWorkspaceAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignInitialWorkspaceAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignInitialWorkspaceAdminMutation, { data, loading, error }] = useAssignInitialWorkspaceAdminMutation({
 *   variables: {
 *      workspaceSignedToken: // value for 'workspaceSignedToken'
 *   },
 * });
 */
export function useAssignInitialWorkspaceAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignInitialWorkspaceAdminMutation,
    AssignInitialWorkspaceAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignInitialWorkspaceAdminMutation,
    AssignInitialWorkspaceAdminMutationVariables
  >(AssignInitialWorkspaceAdminDocument, options);
}
export type AssignInitialWorkspaceAdminMutationHookResult = ReturnType<
  typeof useAssignInitialWorkspaceAdminMutation
>;
export type AssignInitialWorkspaceAdminMutationResult =
  Apollo.MutationResult<AssignInitialWorkspaceAdminMutation>;
export type AssignInitialWorkspaceAdminMutationOptions =
  Apollo.BaseMutationOptions<
    AssignInitialWorkspaceAdminMutation,
    AssignInitialWorkspaceAdminMutationVariables
  >;
export const BuildPackageDocument = gql`
  query BuildPackage($slugs: String) {
    products(slugs: $slugs) {
      id
      name
      slug
    }
    creditPackages {
      id
      price
      creditValue
    }
  }
`;

/**
 * __useBuildPackageQuery__
 *
 * To run a query within a React component, call `useBuildPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildPackageQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useBuildPackageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BuildPackageQuery,
    BuildPackageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuildPackageQuery, BuildPackageQueryVariables>(
    BuildPackageDocument,
    options,
  );
}
export function useBuildPackageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuildPackageQuery,
    BuildPackageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuildPackageQuery, BuildPackageQueryVariables>(
    BuildPackageDocument,
    options,
  );
}
export function useBuildPackageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BuildPackageQuery,
        BuildPackageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BuildPackageQuery, BuildPackageQueryVariables>(
    BuildPackageDocument,
    options,
  );
}
export type BuildPackageQueryHookResult = ReturnType<
  typeof useBuildPackageQuery
>;
export type BuildPackageLazyQueryHookResult = ReturnType<
  typeof useBuildPackageLazyQuery
>;
export type BuildPackageSuspenseQueryHookResult = ReturnType<
  typeof useBuildPackageSuspenseQuery
>;
export type BuildPackageQueryResult = Apollo.QueryResult<
  BuildPackageQuery,
  BuildPackageQueryVariables
>;
export const OrderNextStepsDocument = gql`
  query OrderNextSteps($id: ID!, $customerId: ID) {
    order(id: $id, customerId: $customerId) {
      id
      status
      paymentStatus
      orderItems {
        id
        product {
          id
          slug
          introProduct
        }
        fulfillmentStatus
        latestMeetingId
        latestReviewId
      }
      unmatchedFulfillmentForms {
        id
      }
      incompleteFulfillmentForms {
        id
        missingRequiredAnswers
      }
    }
  }
`;

/**
 * __useOrderNextStepsQuery__
 *
 * To run a query within a React component, call `useOrderNextStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderNextStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderNextStepsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useOrderNextStepsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderNextStepsQuery,
    OrderNextStepsQueryVariables
  > &
    (
      | { variables: OrderNextStepsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderNextStepsQuery, OrderNextStepsQueryVariables>(
    OrderNextStepsDocument,
    options,
  );
}
export function useOrderNextStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderNextStepsQuery,
    OrderNextStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderNextStepsQuery, OrderNextStepsQueryVariables>(
    OrderNextStepsDocument,
    options,
  );
}
export function useOrderNextStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderNextStepsQuery,
        OrderNextStepsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderNextStepsQuery,
    OrderNextStepsQueryVariables
  >(OrderNextStepsDocument, options);
}
export type OrderNextStepsQueryHookResult = ReturnType<
  typeof useOrderNextStepsQuery
>;
export type OrderNextStepsLazyQueryHookResult = ReturnType<
  typeof useOrderNextStepsLazyQuery
>;
export type OrderNextStepsSuspenseQueryHookResult = ReturnType<
  typeof useOrderNextStepsSuspenseQuery
>;
export type OrderNextStepsQueryResult = Apollo.QueryResult<
  OrderNextStepsQuery,
  OrderNextStepsQueryVariables
>;
export const CreateCoachMeetingDocument = gql`
  mutation CreateCoachMeeting(
    $coachId: ID!
    $productId: ID!
    $startTime: DateTime!
    $orderItemId: ID
    $fulfillmentFormId: ID
    $formData: GenericScalar
    $customerId: ID
    $workspaceId: ID
    $timeslotsStrings: [String]
    $ledgerAccountId: ID
    $usedAllTimeslot: Boolean
  ) {
    createCoachMeeting(
      coachId: $coachId
      orderItemId: $orderItemId
      fulfillmentFormId: $fulfillmentFormId
      productId: $productId
      startTime: $startTime
      formData: $formData
      customerId: $customerId
      workspaceId: $workspaceId
      timeslotsStrings: $timeslotsStrings
      ledgerAccountId: $ledgerAccountId
      usedAllTimeslot: $usedAllTimeslot
    ) {
      ok
      meeting {
        id
        eventAt
      }
      status
    }
  }
`;
export type CreateCoachMeetingMutationFn = Apollo.MutationFunction<
  CreateCoachMeetingMutation,
  CreateCoachMeetingMutationVariables
>;

/**
 * __useCreateCoachMeetingMutation__
 *
 * To run a mutation, you first call `useCreateCoachMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoachMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoachMeetingMutation, { data, loading, error }] = useCreateCoachMeetingMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      productId: // value for 'productId'
 *      startTime: // value for 'startTime'
 *      orderItemId: // value for 'orderItemId'
 *      fulfillmentFormId: // value for 'fulfillmentFormId'
 *      formData: // value for 'formData'
 *      customerId: // value for 'customerId'
 *      workspaceId: // value for 'workspaceId'
 *      timeslotsStrings: // value for 'timeslotsStrings'
 *      ledgerAccountId: // value for 'ledgerAccountId'
 *      usedAllTimeslot: // value for 'usedAllTimeslot'
 *   },
 * });
 */
export function useCreateCoachMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCoachMeetingMutation,
    CreateCoachMeetingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCoachMeetingMutation,
    CreateCoachMeetingMutationVariables
  >(CreateCoachMeetingDocument, options);
}
export type CreateCoachMeetingMutationHookResult = ReturnType<
  typeof useCreateCoachMeetingMutation
>;
export type CreateCoachMeetingMutationResult =
  Apollo.MutationResult<CreateCoachMeetingMutation>;
export type CreateCoachMeetingMutationOptions = Apollo.BaseMutationOptions<
  CreateCoachMeetingMutation,
  CreateCoachMeetingMutationVariables
>;
export const FetchFulfillmentFormsDocument = gql`
  query FetchFulfillmentForms($orderId: ID!, $customerId: ID) {
    order(id: $orderId, customerId: $customerId) {
      id
      errorMessages
      incompleteFulfillmentForms {
        id
        user {
          id
          name
          email
          timeZone
        }
        coach {
          id
          firstName
        }
        meetingId
        orderItem {
          id
          orderId
        }
        product {
          id
          slug
          name
          coachEnableBookingRequest
          enableBookingRequest
          activeFormFields {
            id
            prompt
            answerType
            helpText
            required
            formFieldOptions {
              id
              label
              value
            }
          }
        }
        formFieldAnswers {
          id
          formField {
            id
          }
          value
          optionValue {
            id
            value
            label
          }
          userFile {
            id
            name
            url
          }
        }
      }
    }
  }
`;

/**
 * __useFetchFulfillmentFormsQuery__
 *
 * To run a query within a React component, call `useFetchFulfillmentFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFulfillmentFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFulfillmentFormsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useFetchFulfillmentFormsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchFulfillmentFormsQuery,
    FetchFulfillmentFormsQueryVariables
  > &
    (
      | { variables: FetchFulfillmentFormsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchFulfillmentFormsQuery,
    FetchFulfillmentFormsQueryVariables
  >(FetchFulfillmentFormsDocument, options);
}
export function useFetchFulfillmentFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchFulfillmentFormsQuery,
    FetchFulfillmentFormsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchFulfillmentFormsQuery,
    FetchFulfillmentFormsQueryVariables
  >(FetchFulfillmentFormsDocument, options);
}
export function useFetchFulfillmentFormsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FetchFulfillmentFormsQuery,
        FetchFulfillmentFormsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FetchFulfillmentFormsQuery,
    FetchFulfillmentFormsQueryVariables
  >(FetchFulfillmentFormsDocument, options);
}
export type FetchFulfillmentFormsQueryHookResult = ReturnType<
  typeof useFetchFulfillmentFormsQuery
>;
export type FetchFulfillmentFormsLazyQueryHookResult = ReturnType<
  typeof useFetchFulfillmentFormsLazyQuery
>;
export type FetchFulfillmentFormsSuspenseQueryHookResult = ReturnType<
  typeof useFetchFulfillmentFormsSuspenseQuery
>;
export type FetchFulfillmentFormsQueryResult = Apollo.QueryResult<
  FetchFulfillmentFormsQuery,
  FetchFulfillmentFormsQueryVariables
>;
export const GetCoachAvailableDaysDocument = gql`
  query GetCoachAvailableDays($coachId: ID!, $orderItemId: ID!) {
    coachAvailableDays(coachId: $coachId)
    orderItemMeeting(orderItemId: $orderItemId) {
      ...MeetingFragment
    }
  }
  ${MeetingFragmentFragmentDoc}
`;

/**
 * __useGetCoachAvailableDaysQuery__
 *
 * To run a query within a React component, call `useGetCoachAvailableDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachAvailableDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachAvailableDaysQuery({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      orderItemId: // value for 'orderItemId'
 *   },
 * });
 */
export function useGetCoachAvailableDaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoachAvailableDaysQuery,
    GetCoachAvailableDaysQueryVariables
  > &
    (
      | { variables: GetCoachAvailableDaysQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCoachAvailableDaysQuery,
    GetCoachAvailableDaysQueryVariables
  >(GetCoachAvailableDaysDocument, options);
}
export function useGetCoachAvailableDaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachAvailableDaysQuery,
    GetCoachAvailableDaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoachAvailableDaysQuery,
    GetCoachAvailableDaysQueryVariables
  >(GetCoachAvailableDaysDocument, options);
}
export function useGetCoachAvailableDaysSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCoachAvailableDaysQuery,
        GetCoachAvailableDaysQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCoachAvailableDaysQuery,
    GetCoachAvailableDaysQueryVariables
  >(GetCoachAvailableDaysDocument, options);
}
export type GetCoachAvailableDaysQueryHookResult = ReturnType<
  typeof useGetCoachAvailableDaysQuery
>;
export type GetCoachAvailableDaysLazyQueryHookResult = ReturnType<
  typeof useGetCoachAvailableDaysLazyQuery
>;
export type GetCoachAvailableDaysSuspenseQueryHookResult = ReturnType<
  typeof useGetCoachAvailableDaysSuspenseQuery
>;
export type GetCoachAvailableDaysQueryResult = Apollo.QueryResult<
  GetCoachAvailableDaysQuery,
  GetCoachAvailableDaysQueryVariables
>;
export const GetCoachAvailableTimeslotByDayDocument = gql`
  query GetCoachAvailableTimeslotByDay(
    $coachId: ID!
    $productSlug: String!
    $lookupDay: Date!
    $timeZone: String
  ) {
    coachAvailableTimeslotByDay(
      coachId: $coachId
      productSlug: $productSlug
      lookupDay: $lookupDay
      timeZone: $timeZone
    )
  }
`;

/**
 * __useGetCoachAvailableTimeslotByDayQuery__
 *
 * To run a query within a React component, call `useGetCoachAvailableTimeslotByDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachAvailableTimeslotByDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachAvailableTimeslotByDayQuery({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      productSlug: // value for 'productSlug'
 *      lookupDay: // value for 'lookupDay'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetCoachAvailableTimeslotByDayQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoachAvailableTimeslotByDayQuery,
    GetCoachAvailableTimeslotByDayQueryVariables
  > &
    (
      | {
          variables: GetCoachAvailableTimeslotByDayQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCoachAvailableTimeslotByDayQuery,
    GetCoachAvailableTimeslotByDayQueryVariables
  >(GetCoachAvailableTimeslotByDayDocument, options);
}
export function useGetCoachAvailableTimeslotByDayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachAvailableTimeslotByDayQuery,
    GetCoachAvailableTimeslotByDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoachAvailableTimeslotByDayQuery,
    GetCoachAvailableTimeslotByDayQueryVariables
  >(GetCoachAvailableTimeslotByDayDocument, options);
}
export function useGetCoachAvailableTimeslotByDaySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCoachAvailableTimeslotByDayQuery,
        GetCoachAvailableTimeslotByDayQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCoachAvailableTimeslotByDayQuery,
    GetCoachAvailableTimeslotByDayQueryVariables
  >(GetCoachAvailableTimeslotByDayDocument, options);
}
export type GetCoachAvailableTimeslotByDayQueryHookResult = ReturnType<
  typeof useGetCoachAvailableTimeslotByDayQuery
>;
export type GetCoachAvailableTimeslotByDayLazyQueryHookResult = ReturnType<
  typeof useGetCoachAvailableTimeslotByDayLazyQuery
>;
export type GetCoachAvailableTimeslotByDaySuspenseQueryHookResult = ReturnType<
  typeof useGetCoachAvailableTimeslotByDaySuspenseQuery
>;
export type GetCoachAvailableTimeslotByDayQueryResult = Apollo.QueryResult<
  GetCoachAvailableTimeslotByDayQuery,
  GetCoachAvailableTimeslotByDayQueryVariables
>;
export const SaveFulfillmentFormDocument = gql`
  mutation SaveFulfillmentForm(
    $formId: ID!
    $answerData: [FulfillmentFormAnswersInput]
    $customerId: ID
  ) {
    saveFulfillmentForm(
      formId: $formId
      answerData: $answerData
      customerId: $customerId
    ) {
      ok
      errors {
        field
        message
      }
      fulfillmentForm {
        id
        user {
          id
          name
          email
        }
        coach {
          id
          firstName
          lastName
        }
        meetingId
        orderItem {
          id
          orderId
        }
        product {
          id
          slug
          name
          activeFormFields {
            id
            prompt
            answerType
            helpText
            required
            formFieldOptions {
              id
              label
              value
            }
          }
        }
        formFieldAnswers {
          id
          formField {
            id
          }
          value
          optionValue {
            id
            value
            label
          }
          userFile {
            id
            name
            url
          }
        }
      }
    }
  }
`;
export type SaveFulfillmentFormMutationFn = Apollo.MutationFunction<
  SaveFulfillmentFormMutation,
  SaveFulfillmentFormMutationVariables
>;

/**
 * __useSaveFulfillmentFormMutation__
 *
 * To run a mutation, you first call `useSaveFulfillmentFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFulfillmentFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFulfillmentFormMutation, { data, loading, error }] = useSaveFulfillmentFormMutation({
 *   variables: {
 *      formId: // value for 'formId'
 *      answerData: // value for 'answerData'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useSaveFulfillmentFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveFulfillmentFormMutation,
    SaveFulfillmentFormMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveFulfillmentFormMutation,
    SaveFulfillmentFormMutationVariables
  >(SaveFulfillmentFormDocument, options);
}
export type SaveFulfillmentFormMutationHookResult = ReturnType<
  typeof useSaveFulfillmentFormMutation
>;
export type SaveFulfillmentFormMutationResult =
  Apollo.MutationResult<SaveFulfillmentFormMutation>;
export type SaveFulfillmentFormMutationOptions = Apollo.BaseMutationOptions<
  SaveFulfillmentFormMutation,
  SaveFulfillmentFormMutationVariables
>;
export const PopularIndustriesDocument = gql`
  query PopularIndustries {
    popularIndustries {
      id
      name
    }
  }
`;

/**
 * __usePopularIndustriesQuery__
 *
 * To run a query within a React component, call `usePopularIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularIndustriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PopularIndustriesQuery,
    PopularIndustriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PopularIndustriesQuery,
    PopularIndustriesQueryVariables
  >(PopularIndustriesDocument, options);
}
export function usePopularIndustriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopularIndustriesQuery,
    PopularIndustriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PopularIndustriesQuery,
    PopularIndustriesQueryVariables
  >(PopularIndustriesDocument, options);
}
export function usePopularIndustriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PopularIndustriesQuery,
        PopularIndustriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PopularIndustriesQuery,
    PopularIndustriesQueryVariables
  >(PopularIndustriesDocument, options);
}
export type PopularIndustriesQueryHookResult = ReturnType<
  typeof usePopularIndustriesQuery
>;
export type PopularIndustriesLazyQueryHookResult = ReturnType<
  typeof usePopularIndustriesLazyQuery
>;
export type PopularIndustriesSuspenseQueryHookResult = ReturnType<
  typeof usePopularIndustriesSuspenseQuery
>;
export type PopularIndustriesQueryResult = Apollo.QueryResult<
  PopularIndustriesQuery,
  PopularIndustriesQueryVariables
>;
export const PopularJobFunctionsDocument = gql`
  query PopularJobFunctions {
    popularJobFunctions {
      id
      name
    }
  }
`;

/**
 * __usePopularJobFunctionsQuery__
 *
 * To run a query within a React component, call `usePopularJobFunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularJobFunctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularJobFunctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularJobFunctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PopularJobFunctionsQuery,
    PopularJobFunctionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PopularJobFunctionsQuery,
    PopularJobFunctionsQueryVariables
  >(PopularJobFunctionsDocument, options);
}
export function usePopularJobFunctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopularJobFunctionsQuery,
    PopularJobFunctionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PopularJobFunctionsQuery,
    PopularJobFunctionsQueryVariables
  >(PopularJobFunctionsDocument, options);
}
export function usePopularJobFunctionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PopularJobFunctionsQuery,
        PopularJobFunctionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PopularJobFunctionsQuery,
    PopularJobFunctionsQueryVariables
  >(PopularJobFunctionsDocument, options);
}
export type PopularJobFunctionsQueryHookResult = ReturnType<
  typeof usePopularJobFunctionsQuery
>;
export type PopularJobFunctionsLazyQueryHookResult = ReturnType<
  typeof usePopularJobFunctionsLazyQuery
>;
export type PopularJobFunctionsSuspenseQueryHookResult = ReturnType<
  typeof usePopularJobFunctionsSuspenseQuery
>;
export type PopularJobFunctionsQueryResult = Apollo.QueryResult<
  PopularJobFunctionsQuery,
  PopularJobFunctionsQueryVariables
>;
export const PopularPlacesDocument = gql`
  query PopularPlaces {
    popularPlaces {
      googlePlaceId
      type
      name
      city
      stateCode
      countryCode
      location
    }
  }
`;

/**
 * __usePopularPlacesQuery__
 *
 * To run a query within a React component, call `usePopularPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularPlacesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularPlacesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PopularPlacesQuery,
    PopularPlacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PopularPlacesQuery, PopularPlacesQueryVariables>(
    PopularPlacesDocument,
    options,
  );
}
export function usePopularPlacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopularPlacesQuery,
    PopularPlacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PopularPlacesQuery, PopularPlacesQueryVariables>(
    PopularPlacesDocument,
    options,
  );
}
export function usePopularPlacesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PopularPlacesQuery,
        PopularPlacesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PopularPlacesQuery,
    PopularPlacesQueryVariables
  >(PopularPlacesDocument, options);
}
export type PopularPlacesQueryHookResult = ReturnType<
  typeof usePopularPlacesQuery
>;
export type PopularPlacesLazyQueryHookResult = ReturnType<
  typeof usePopularPlacesLazyQuery
>;
export type PopularPlacesSuspenseQueryHookResult = ReturnType<
  typeof usePopularPlacesSuspenseQuery
>;
export type PopularPlacesQueryResult = Apollo.QueryResult<
  PopularPlacesQuery,
  PopularPlacesQueryVariables
>;
export const PopularSearchesDocument = gql`
  query PopularSearches {
    popularTitles {
      normalizedTitle
      title
    }
  }
`;

/**
 * __usePopularSearchesQuery__
 *
 * To run a query within a React component, call `usePopularSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularSearchesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularSearchesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PopularSearchesQuery,
    PopularSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PopularSearchesQuery, PopularSearchesQueryVariables>(
    PopularSearchesDocument,
    options,
  );
}
export function usePopularSearchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopularSearchesQuery,
    PopularSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PopularSearchesQuery,
    PopularSearchesQueryVariables
  >(PopularSearchesDocument, options);
}
export function usePopularSearchesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PopularSearchesQuery,
        PopularSearchesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PopularSearchesQuery,
    PopularSearchesQueryVariables
  >(PopularSearchesDocument, options);
}
export type PopularSearchesQueryHookResult = ReturnType<
  typeof usePopularSearchesQuery
>;
export type PopularSearchesLazyQueryHookResult = ReturnType<
  typeof usePopularSearchesLazyQuery
>;
export type PopularSearchesSuspenseQueryHookResult = ReturnType<
  typeof usePopularSearchesSuspenseQuery
>;
export type PopularSearchesQueryResult = Apollo.QueryResult<
  PopularSearchesQuery,
  PopularSearchesQueryVariables
>;
export const PopularTitlesDocument = gql`
  query PopularTitles {
    popularTitles {
      normalizedTitle
      title
    }
  }
`;

/**
 * __usePopularTitlesQuery__
 *
 * To run a query within a React component, call `usePopularTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularTitlesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularTitlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PopularTitlesQuery,
    PopularTitlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PopularTitlesQuery, PopularTitlesQueryVariables>(
    PopularTitlesDocument,
    options,
  );
}
export function usePopularTitlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopularTitlesQuery,
    PopularTitlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PopularTitlesQuery, PopularTitlesQueryVariables>(
    PopularTitlesDocument,
    options,
  );
}
export function usePopularTitlesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PopularTitlesQuery,
        PopularTitlesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PopularTitlesQuery,
    PopularTitlesQueryVariables
  >(PopularTitlesDocument, options);
}
export type PopularTitlesQueryHookResult = ReturnType<
  typeof usePopularTitlesQuery
>;
export type PopularTitlesLazyQueryHookResult = ReturnType<
  typeof usePopularTitlesLazyQuery
>;
export type PopularTitlesSuspenseQueryHookResult = ReturnType<
  typeof usePopularTitlesSuspenseQuery
>;
export type PopularTitlesQueryResult = Apollo.QueryResult<
  PopularTitlesQuery,
  PopularTitlesQueryVariables
>;
export const SaveNameDocument = gql`
  mutation SaveName($name: String!) {
    updateUser(input: { name: $name }) {
      errors {
        field
        message
      }
    }
  }
`;
export type SaveNameMutationFn = Apollo.MutationFunction<
  SaveNameMutation,
  SaveNameMutationVariables
>;

/**
 * __useSaveNameMutation__
 *
 * To run a mutation, you first call `useSaveNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNameMutation, { data, loading, error }] = useSaveNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSaveNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveNameMutation,
    SaveNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveNameMutation, SaveNameMutationVariables>(
    SaveNameDocument,
    options,
  );
}
export type SaveNameMutationHookResult = ReturnType<typeof useSaveNameMutation>;
export type SaveNameMutationResult = Apollo.MutationResult<SaveNameMutation>;
export type SaveNameMutationOptions = Apollo.BaseMutationOptions<
  SaveNameMutation,
  SaveNameMutationVariables
>;
export const CoachPayoutAccountDocument = gql`
  query CoachPayoutAccount {
    currentUser {
      id
      email
      agentProfile {
        id
        paymentOnboardingReady
        payoutChannel
      }
    }
  }
`;

/**
 * __useCoachPayoutAccountQuery__
 *
 * To run a query within a React component, call `useCoachPayoutAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachPayoutAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachPayoutAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachPayoutAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachPayoutAccountQuery,
    CoachPayoutAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachPayoutAccountQuery,
    CoachPayoutAccountQueryVariables
  >(CoachPayoutAccountDocument, options);
}
export function useCoachPayoutAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachPayoutAccountQuery,
    CoachPayoutAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachPayoutAccountQuery,
    CoachPayoutAccountQueryVariables
  >(CoachPayoutAccountDocument, options);
}
export function useCoachPayoutAccountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachPayoutAccountQuery,
        CoachPayoutAccountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachPayoutAccountQuery,
    CoachPayoutAccountQueryVariables
  >(CoachPayoutAccountDocument, options);
}
export type CoachPayoutAccountQueryHookResult = ReturnType<
  typeof useCoachPayoutAccountQuery
>;
export type CoachPayoutAccountLazyQueryHookResult = ReturnType<
  typeof useCoachPayoutAccountLazyQuery
>;
export type CoachPayoutAccountSuspenseQueryHookResult = ReturnType<
  typeof useCoachPayoutAccountSuspenseQuery
>;
export type CoachPayoutAccountQueryResult = Apollo.QueryResult<
  CoachPayoutAccountQuery,
  CoachPayoutAccountQueryVariables
>;
export const CoachPayoutEntriesDocument = gql`
  query CoachPayoutEntries($coachPayoutId: ID!) {
    coachPayoutConfig {
      payoutChannel
      dollarPerCredit
    }
    coachPayoutEntries(coachPayoutId: $coachPayoutId) {
      id
      isPayoutEntry
      ledgerAccountEntry {
        id
        amount
        createdAt
        journalEntry {
          id
          memo
          createdAt
        }
      }
      coachPayout {
        id
        totalCredits
        totalPayout
        paidThrough
        createdAt
      }
      createdAt
    }
  }
`;

/**
 * __useCoachPayoutEntriesQuery__
 *
 * To run a query within a React component, call `useCoachPayoutEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachPayoutEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachPayoutEntriesQuery({
 *   variables: {
 *      coachPayoutId: // value for 'coachPayoutId'
 *   },
 * });
 */
export function useCoachPayoutEntriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachPayoutEntriesQuery,
    CoachPayoutEntriesQueryVariables
  > &
    (
      | { variables: CoachPayoutEntriesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachPayoutEntriesQuery,
    CoachPayoutEntriesQueryVariables
  >(CoachPayoutEntriesDocument, options);
}
export function useCoachPayoutEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachPayoutEntriesQuery,
    CoachPayoutEntriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachPayoutEntriesQuery,
    CoachPayoutEntriesQueryVariables
  >(CoachPayoutEntriesDocument, options);
}
export function useCoachPayoutEntriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachPayoutEntriesQuery,
        CoachPayoutEntriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachPayoutEntriesQuery,
    CoachPayoutEntriesQueryVariables
  >(CoachPayoutEntriesDocument, options);
}
export type CoachPayoutEntriesQueryHookResult = ReturnType<
  typeof useCoachPayoutEntriesQuery
>;
export type CoachPayoutEntriesLazyQueryHookResult = ReturnType<
  typeof useCoachPayoutEntriesLazyQuery
>;
export type CoachPayoutEntriesSuspenseQueryHookResult = ReturnType<
  typeof useCoachPayoutEntriesSuspenseQuery
>;
export type CoachPayoutEntriesQueryResult = Apollo.QueryResult<
  CoachPayoutEntriesQuery,
  CoachPayoutEntriesQueryVariables
>;
export const CoachPayoutsDocument = gql`
  query CoachPayouts {
    coachPayouts {
      id
      totalPayout
      totalCredits
      createdAt
      paidThrough
    }
  }
`;

/**
 * __useCoachPayoutsQuery__
 *
 * To run a query within a React component, call `useCoachPayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachPayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachPayoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachPayoutsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachPayoutsQuery,
    CoachPayoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachPayoutsQuery, CoachPayoutsQueryVariables>(
    CoachPayoutsDocument,
    options,
  );
}
export function useCoachPayoutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachPayoutsQuery,
    CoachPayoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachPayoutsQuery, CoachPayoutsQueryVariables>(
    CoachPayoutsDocument,
    options,
  );
}
export function useCoachPayoutsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachPayoutsQuery,
        CoachPayoutsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoachPayoutsQuery, CoachPayoutsQueryVariables>(
    CoachPayoutsDocument,
    options,
  );
}
export type CoachPayoutsQueryHookResult = ReturnType<
  typeof useCoachPayoutsQuery
>;
export type CoachPayoutsLazyQueryHookResult = ReturnType<
  typeof useCoachPayoutsLazyQuery
>;
export type CoachPayoutsSuspenseQueryHookResult = ReturnType<
  typeof useCoachPayoutsSuspenseQuery
>;
export type CoachPayoutsQueryResult = Apollo.QueryResult<
  CoachPayoutsQuery,
  CoachPayoutsQueryVariables
>;
export const ReceivableCreditsDocument = gql`
  query ReceivableCredits {
    userCredit {
      id
      receivableCredits
    }
  }
`;

/**
 * __useReceivableCreditsQuery__
 *
 * To run a query within a React component, call `useReceivableCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivableCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivableCreditsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReceivableCreditsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReceivableCreditsQuery,
    ReceivableCreditsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReceivableCreditsQuery,
    ReceivableCreditsQueryVariables
  >(ReceivableCreditsDocument, options);
}
export function useReceivableCreditsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReceivableCreditsQuery,
    ReceivableCreditsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReceivableCreditsQuery,
    ReceivableCreditsQueryVariables
  >(ReceivableCreditsDocument, options);
}
export function useReceivableCreditsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReceivableCreditsQuery,
        ReceivableCreditsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReceivableCreditsQuery,
    ReceivableCreditsQueryVariables
  >(ReceivableCreditsDocument, options);
}
export type ReceivableCreditsQueryHookResult = ReturnType<
  typeof useReceivableCreditsQuery
>;
export type ReceivableCreditsLazyQueryHookResult = ReturnType<
  typeof useReceivableCreditsLazyQuery
>;
export type ReceivableCreditsSuspenseQueryHookResult = ReturnType<
  typeof useReceivableCreditsSuspenseQuery
>;
export type ReceivableCreditsQueryResult = Apollo.QueryResult<
  ReceivableCreditsQuery,
  ReceivableCreditsQueryVariables
>;
export const ArchiveProgramDocument = gql`
  mutation archiveProgram($programId: ID!) {
    archiveProgram(programId: $programId) {
      ok
      msg
    }
  }
`;
export type ArchiveProgramMutationFn = Apollo.MutationFunction<
  ArchiveProgramMutation,
  ArchiveProgramMutationVariables
>;

/**
 * __useArchiveProgramMutation__
 *
 * To run a mutation, you first call `useArchiveProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProgramMutation, { data, loading, error }] = useArchiveProgramMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useArchiveProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveProgramMutation,
    ArchiveProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveProgramMutation,
    ArchiveProgramMutationVariables
  >(ArchiveProgramDocument, options);
}
export type ArchiveProgramMutationHookResult = ReturnType<
  typeof useArchiveProgramMutation
>;
export type ArchiveProgramMutationResult =
  Apollo.MutationResult<ArchiveProgramMutation>;
export type ArchiveProgramMutationOptions = Apollo.BaseMutationOptions<
  ArchiveProgramMutation,
  ArchiveProgramMutationVariables
>;
export const CloneProgramDocument = gql`
  mutation CloneProgram(
    $programId: ID!
    $name: String!
    $targetLaunchDate: DateTime!
  ) {
    cloneProgram(
      programId: $programId
      name: $name
      targetLaunchDate: $targetLaunchDate
    ) {
      ok
      error
      program {
        name
        id
      }
    }
  }
`;
export type CloneProgramMutationFn = Apollo.MutationFunction<
  CloneProgramMutation,
  CloneProgramMutationVariables
>;

/**
 * __useCloneProgramMutation__
 *
 * To run a mutation, you first call `useCloneProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneProgramMutation, { data, loading, error }] = useCloneProgramMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      name: // value for 'name'
 *      targetLaunchDate: // value for 'targetLaunchDate'
 *   },
 * });
 */
export function useCloneProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneProgramMutation,
    CloneProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloneProgramMutation,
    CloneProgramMutationVariables
  >(CloneProgramDocument, options);
}
export type CloneProgramMutationHookResult = ReturnType<
  typeof useCloneProgramMutation
>;
export type CloneProgramMutationResult =
  Apollo.MutationResult<CloneProgramMutation>;
export type CloneProgramMutationOptions = Apollo.BaseMutationOptions<
  CloneProgramMutation,
  CloneProgramMutationVariables
>;
export const CloseProgramComponentDocument = gql`
  mutation CloseProgramComponent(
    $programComponentId: ID!
    $programId: ID!
    $isReleaseDependentComponents: Boolean
  ) {
    closeProgramComponent(
      programComponentId: $programComponentId
      programId: $programId
      isReleaseDependentComponents: $isReleaseDependentComponents
    ) {
      ok
      msg
    }
  }
`;
export type CloseProgramComponentMutationFn = Apollo.MutationFunction<
  CloseProgramComponentMutation,
  CloseProgramComponentMutationVariables
>;

/**
 * __useCloseProgramComponentMutation__
 *
 * To run a mutation, you first call `useCloseProgramComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseProgramComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeProgramComponentMutation, { data, loading, error }] = useCloseProgramComponentMutation({
 *   variables: {
 *      programComponentId: // value for 'programComponentId'
 *      programId: // value for 'programId'
 *      isReleaseDependentComponents: // value for 'isReleaseDependentComponents'
 *   },
 * });
 */
export function useCloseProgramComponentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseProgramComponentMutation,
    CloseProgramComponentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloseProgramComponentMutation,
    CloseProgramComponentMutationVariables
  >(CloseProgramComponentDocument, options);
}
export type CloseProgramComponentMutationHookResult = ReturnType<
  typeof useCloseProgramComponentMutation
>;
export type CloseProgramComponentMutationResult =
  Apollo.MutationResult<CloseProgramComponentMutation>;
export type CloseProgramComponentMutationOptions = Apollo.BaseMutationOptions<
  CloseProgramComponentMutation,
  CloseProgramComponentMutationVariables
>;
export const CoachesForComponentDocument = gql`
  query coachesForComponent(
    $programId: ID!
    $specialtyIds: [ID]
    $productIds: [ID]
  ) {
    coaches(
      programId: $programId
      specialtyIds: $specialtyIds
      productIds: $productIds
    ) {
      id
      name
    }
  }
`;

/**
 * __useCoachesForComponentQuery__
 *
 * To run a query within a React component, call `useCoachesForComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachesForComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachesForComponentQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      specialtyIds: // value for 'specialtyIds'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useCoachesForComponentQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachesForComponentQuery,
    CoachesForComponentQueryVariables
  > &
    (
      | { variables: CoachesForComponentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachesForComponentQuery,
    CoachesForComponentQueryVariables
  >(CoachesForComponentDocument, options);
}
export function useCoachesForComponentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachesForComponentQuery,
    CoachesForComponentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachesForComponentQuery,
    CoachesForComponentQueryVariables
  >(CoachesForComponentDocument, options);
}
export function useCoachesForComponentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachesForComponentQuery,
        CoachesForComponentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachesForComponentQuery,
    CoachesForComponentQueryVariables
  >(CoachesForComponentDocument, options);
}
export type CoachesForComponentQueryHookResult = ReturnType<
  typeof useCoachesForComponentQuery
>;
export type CoachesForComponentLazyQueryHookResult = ReturnType<
  typeof useCoachesForComponentLazyQuery
>;
export type CoachesForComponentSuspenseQueryHookResult = ReturnType<
  typeof useCoachesForComponentSuspenseQuery
>;
export type CoachesForComponentQueryResult = Apollo.QueryResult<
  CoachesForComponentQuery,
  CoachesForComponentQueryVariables
>;
export const CompleteAddToCalendarDocument = gql`
  mutation CompleteAddToCalendar($programId: ID!) {
    completeAddToCalendar(programId: $programId) {
      ok
    }
  }
`;
export type CompleteAddToCalendarMutationFn = Apollo.MutationFunction<
  CompleteAddToCalendarMutation,
  CompleteAddToCalendarMutationVariables
>;

/**
 * __useCompleteAddToCalendarMutation__
 *
 * To run a mutation, you first call `useCompleteAddToCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAddToCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAddToCalendarMutation, { data, loading, error }] = useCompleteAddToCalendarMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useCompleteAddToCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAddToCalendarMutation,
    CompleteAddToCalendarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteAddToCalendarMutation,
    CompleteAddToCalendarMutationVariables
  >(CompleteAddToCalendarDocument, options);
}
export type CompleteAddToCalendarMutationHookResult = ReturnType<
  typeof useCompleteAddToCalendarMutation
>;
export type CompleteAddToCalendarMutationResult =
  Apollo.MutationResult<CompleteAddToCalendarMutation>;
export type CompleteAddToCalendarMutationOptions = Apollo.BaseMutationOptions<
  CompleteAddToCalendarMutation,
  CompleteAddToCalendarMutationVariables
>;
export const CompleteProgramDocument = gql`
  mutation completeProgram($programId: ID!) {
    completeProgram(programId: $programId) {
      ok
      msg
    }
  }
`;
export type CompleteProgramMutationFn = Apollo.MutationFunction<
  CompleteProgramMutation,
  CompleteProgramMutationVariables
>;

/**
 * __useCompleteProgramMutation__
 *
 * To run a mutation, you first call `useCompleteProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProgramMutation, { data, loading, error }] = useCompleteProgramMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useCompleteProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteProgramMutation,
    CompleteProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteProgramMutation,
    CompleteProgramMutationVariables
  >(CompleteProgramDocument, options);
}
export type CompleteProgramMutationHookResult = ReturnType<
  typeof useCompleteProgramMutation
>;
export type CompleteProgramMutationResult =
  Apollo.MutationResult<CompleteProgramMutation>;
export type CompleteProgramMutationOptions = Apollo.BaseMutationOptions<
  CompleteProgramMutation,
  CompleteProgramMutationVariables
>;
export const CompleteSetupProgramComponentsDocument = gql`
  mutation CompleteSetupProgramComponents($programId: ID!) {
    completeSetupProgramComponents(programId: $programId) {
      ok
    }
  }
`;
export type CompleteSetupProgramComponentsMutationFn = Apollo.MutationFunction<
  CompleteSetupProgramComponentsMutation,
  CompleteSetupProgramComponentsMutationVariables
>;

/**
 * __useCompleteSetupProgramComponentsMutation__
 *
 * To run a mutation, you first call `useCompleteSetupProgramComponentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSetupProgramComponentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSetupProgramComponentsMutation, { data, loading, error }] = useCompleteSetupProgramComponentsMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useCompleteSetupProgramComponentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteSetupProgramComponentsMutation,
    CompleteSetupProgramComponentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteSetupProgramComponentsMutation,
    CompleteSetupProgramComponentsMutationVariables
  >(CompleteSetupProgramComponentsDocument, options);
}
export type CompleteSetupProgramComponentsMutationHookResult = ReturnType<
  typeof useCompleteSetupProgramComponentsMutation
>;
export type CompleteSetupProgramComponentsMutationResult =
  Apollo.MutationResult<CompleteSetupProgramComponentsMutation>;
export type CompleteSetupProgramComponentsMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteSetupProgramComponentsMutation,
    CompleteSetupProgramComponentsMutationVariables
  >;
export const CreateProgramDocument = gql`
  mutation CreateProgram($targetLaunchDate: DateTime!, $name: String!) {
    createProgram(targetLaunchDate: $targetLaunchDate, name: $name) {
      ok
      program {
        id
        name
        status
        targetLaunchDate
        participantCount
      }
    }
  }
`;
export type CreateProgramMutationFn = Apollo.MutationFunction<
  CreateProgramMutation,
  CreateProgramMutationVariables
>;

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      targetLaunchDate: // value for 'targetLaunchDate'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProgramMutation,
    CreateProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProgramMutation,
    CreateProgramMutationVariables
  >(CreateProgramDocument, options);
}
export type CreateProgramMutationHookResult = ReturnType<
  typeof useCreateProgramMutation
>;
export type CreateProgramMutationResult =
  Apollo.MutationResult<CreateProgramMutation>;
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<
  CreateProgramMutation,
  CreateProgramMutationVariables
>;
export const CreateProgramUsersDocument = gql`
  mutation createProgramUsers(
    $programId: ID!
    $role: ProgramUserRole
    $emails: [String]!
    $groupId: ID
    $credit: Decimal!
  ) {
    createProgramUsers(
      programId: $programId
      role: $role
      emails: $emails
      groupId: $groupId
      credit: $credit
    ) {
      ok
      programUsers {
        id
        user {
          id
          name
          email
          photo
        }
        roles
      }
      msg
    }
  }
`;
export type CreateProgramUsersMutationFn = Apollo.MutationFunction<
  CreateProgramUsersMutation,
  CreateProgramUsersMutationVariables
>;

/**
 * __useCreateProgramUsersMutation__
 *
 * To run a mutation, you first call `useCreateProgramUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramUsersMutation, { data, loading, error }] = useCreateProgramUsersMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      role: // value for 'role'
 *      emails: // value for 'emails'
 *      groupId: // value for 'groupId'
 *      credit: // value for 'credit'
 *   },
 * });
 */
export function useCreateProgramUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProgramUsersMutation,
    CreateProgramUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProgramUsersMutation,
    CreateProgramUsersMutationVariables
  >(CreateProgramUsersDocument, options);
}
export type CreateProgramUsersMutationHookResult = ReturnType<
  typeof useCreateProgramUsersMutation
>;
export type CreateProgramUsersMutationResult =
  Apollo.MutationResult<CreateProgramUsersMutation>;
export type CreateProgramUsersMutationOptions = Apollo.BaseMutationOptions<
  CreateProgramUsersMutation,
  CreateProgramUsersMutationVariables
>;
export const IsLastActiveComponentQueryDocument = gql`
  query IsLastActiveComponentQuery($programId: ID!, $componentId: ID!) {
    isLastActiveComponent(programId: $programId, componentId: $componentId)
  }
`;

/**
 * __useIsLastActiveComponentQueryQuery__
 *
 * To run a query within a React component, call `useIsLastActiveComponentQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLastActiveComponentQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLastActiveComponentQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      componentId: // value for 'componentId'
 *   },
 * });
 */
export function useIsLastActiveComponentQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsLastActiveComponentQueryQuery,
    IsLastActiveComponentQueryQueryVariables
  > &
    (
      | { variables: IsLastActiveComponentQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsLastActiveComponentQueryQuery,
    IsLastActiveComponentQueryQueryVariables
  >(IsLastActiveComponentQueryDocument, options);
}
export function useIsLastActiveComponentQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsLastActiveComponentQueryQuery,
    IsLastActiveComponentQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsLastActiveComponentQueryQuery,
    IsLastActiveComponentQueryQueryVariables
  >(IsLastActiveComponentQueryDocument, options);
}
export function useIsLastActiveComponentQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IsLastActiveComponentQueryQuery,
        IsLastActiveComponentQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsLastActiveComponentQueryQuery,
    IsLastActiveComponentQueryQueryVariables
  >(IsLastActiveComponentQueryDocument, options);
}
export type IsLastActiveComponentQueryQueryHookResult = ReturnType<
  typeof useIsLastActiveComponentQueryQuery
>;
export type IsLastActiveComponentQueryLazyQueryHookResult = ReturnType<
  typeof useIsLastActiveComponentQueryLazyQuery
>;
export type IsLastActiveComponentQuerySuspenseQueryHookResult = ReturnType<
  typeof useIsLastActiveComponentQuerySuspenseQuery
>;
export type IsLastActiveComponentQueryQueryResult = Apollo.QueryResult<
  IsLastActiveComponentQueryQuery,
  IsLastActiveComponentQueryQueryVariables
>;
export const LaunchProgramDocument = gql`
  mutation LaunchProgram($programId: ID!) {
    launchProgram(programId: $programId) {
      ok
    }
  }
`;
export type LaunchProgramMutationFn = Apollo.MutationFunction<
  LaunchProgramMutation,
  LaunchProgramMutationVariables
>;

/**
 * __useLaunchProgramMutation__
 *
 * To run a mutation, you first call `useLaunchProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchProgramMutation, { data, loading, error }] = useLaunchProgramMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useLaunchProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LaunchProgramMutation,
    LaunchProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LaunchProgramMutation,
    LaunchProgramMutationVariables
  >(LaunchProgramDocument, options);
}
export type LaunchProgramMutationHookResult = ReturnType<
  typeof useLaunchProgramMutation
>;
export type LaunchProgramMutationResult =
  Apollo.MutationResult<LaunchProgramMutation>;
export type LaunchProgramMutationOptions = Apollo.BaseMutationOptions<
  LaunchProgramMutation,
  LaunchProgramMutationVariables
>;
export const ProductsForConfigDocument = gql`
  query ProductsForConfig($specialtyId: ID!, $categories: [ProductCategories]) {
    productsForConfig(specialtyId: $specialtyId, categories: $categories) {
      id
      name
    }
  }
`;

/**
 * __useProductsForConfigQuery__
 *
 * To run a query within a React component, call `useProductsForConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForConfigQuery({
 *   variables: {
 *      specialtyId: // value for 'specialtyId'
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useProductsForConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsForConfigQuery,
    ProductsForConfigQueryVariables
  > &
    (
      | { variables: ProductsForConfigQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsForConfigQuery,
    ProductsForConfigQueryVariables
  >(ProductsForConfigDocument, options);
}
export function useProductsForConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsForConfigQuery,
    ProductsForConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsForConfigQuery,
    ProductsForConfigQueryVariables
  >(ProductsForConfigDocument, options);
}
export function useProductsForConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProductsForConfigQuery,
        ProductsForConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProductsForConfigQuery,
    ProductsForConfigQueryVariables
  >(ProductsForConfigDocument, options);
}
export type ProductsForConfigQueryHookResult = ReturnType<
  typeof useProductsForConfigQuery
>;
export type ProductsForConfigLazyQueryHookResult = ReturnType<
  typeof useProductsForConfigLazyQuery
>;
export type ProductsForConfigSuspenseQueryHookResult = ReturnType<
  typeof useProductsForConfigSuspenseQuery
>;
export type ProductsForConfigQueryResult = Apollo.QueryResult<
  ProductsForConfigQuery,
  ProductsForConfigQueryVariables
>;
export const ProgramComponentForStatisticQueryDocument = gql`
  query ProgramComponentForStatisticQuery($programComponentId: ID!) {
    programComponentForStatistic(programComponentId: $programComponentId) {
      id
      completedCount
      totalParticipants
      isSetupComplete
      startedCount
      name
      type
      participantGuidance
      targetStartDate
      targetDueDate
      targetEndDate
      closedAt
      survey {
        id
        formFields {
          id
          link {
            url
            text
            imageSrc
          }
        }
      }
      programId
      prerequisiteComponent {
        id
        name
        targetStartDate
        targetDueDate
        targetEndDate
      }
    }
  }
`;

/**
 * __useProgramComponentForStatisticQueryQuery__
 *
 * To run a query within a React component, call `useProgramComponentForStatisticQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramComponentForStatisticQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramComponentForStatisticQueryQuery({
 *   variables: {
 *      programComponentId: // value for 'programComponentId'
 *   },
 * });
 */
export function useProgramComponentForStatisticQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramComponentForStatisticQueryQuery,
    ProgramComponentForStatisticQueryQueryVariables
  > &
    (
      | {
          variables: ProgramComponentForStatisticQueryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramComponentForStatisticQueryQuery,
    ProgramComponentForStatisticQueryQueryVariables
  >(ProgramComponentForStatisticQueryDocument, options);
}
export function useProgramComponentForStatisticQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramComponentForStatisticQueryQuery,
    ProgramComponentForStatisticQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramComponentForStatisticQueryQuery,
    ProgramComponentForStatisticQueryQueryVariables
  >(ProgramComponentForStatisticQueryDocument, options);
}
export function useProgramComponentForStatisticQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramComponentForStatisticQueryQuery,
        ProgramComponentForStatisticQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramComponentForStatisticQueryQuery,
    ProgramComponentForStatisticQueryQueryVariables
  >(ProgramComponentForStatisticQueryDocument, options);
}
export type ProgramComponentForStatisticQueryQueryHookResult = ReturnType<
  typeof useProgramComponentForStatisticQueryQuery
>;
export type ProgramComponentForStatisticQueryLazyQueryHookResult = ReturnType<
  typeof useProgramComponentForStatisticQueryLazyQuery
>;
export type ProgramComponentForStatisticQuerySuspenseQueryHookResult =
  ReturnType<typeof useProgramComponentForStatisticQuerySuspenseQuery>;
export type ProgramComponentForStatisticQueryQueryResult = Apollo.QueryResult<
  ProgramComponentForStatisticQueryQuery,
  ProgramComponentForStatisticQueryQueryVariables
>;
export const ProgramComponentParticipantsQueryDocument = gql`
  query ProgramComponentParticipantsQuery(
    $programComponentId: ID!
    $page: Int
    $size: Int
    $orderField: String
  ) {
    programComponentParticipants(
      programComponentId: $programComponentId
      page: $page
      size: $size
      orderField: $orderField
    ) {
      participants {
        id
        user {
          id
          name
          email
          photo
        }
        completedAt
        programComponentResponseId
        deleted
        lastReminderAt
        roles
      }
      totalPages
      totalParticipants
      size
      currentPage
    }
  }
`;

/**
 * __useProgramComponentParticipantsQueryQuery__
 *
 * To run a query within a React component, call `useProgramComponentParticipantsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramComponentParticipantsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramComponentParticipantsQueryQuery({
 *   variables: {
 *      programComponentId: // value for 'programComponentId'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      orderField: // value for 'orderField'
 *   },
 * });
 */
export function useProgramComponentParticipantsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramComponentParticipantsQueryQuery,
    ProgramComponentParticipantsQueryQueryVariables
  > &
    (
      | {
          variables: ProgramComponentParticipantsQueryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramComponentParticipantsQueryQuery,
    ProgramComponentParticipantsQueryQueryVariables
  >(ProgramComponentParticipantsQueryDocument, options);
}
export function useProgramComponentParticipantsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramComponentParticipantsQueryQuery,
    ProgramComponentParticipantsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramComponentParticipantsQueryQuery,
    ProgramComponentParticipantsQueryQueryVariables
  >(ProgramComponentParticipantsQueryDocument, options);
}
export function useProgramComponentParticipantsQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramComponentParticipantsQueryQuery,
        ProgramComponentParticipantsQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramComponentParticipantsQueryQuery,
    ProgramComponentParticipantsQueryQueryVariables
  >(ProgramComponentParticipantsQueryDocument, options);
}
export type ProgramComponentParticipantsQueryQueryHookResult = ReturnType<
  typeof useProgramComponentParticipantsQueryQuery
>;
export type ProgramComponentParticipantsQueryLazyQueryHookResult = ReturnType<
  typeof useProgramComponentParticipantsQueryLazyQuery
>;
export type ProgramComponentParticipantsQuerySuspenseQueryHookResult =
  ReturnType<typeof useProgramComponentParticipantsQuerySuspenseQuery>;
export type ProgramComponentParticipantsQueryQueryResult = Apollo.QueryResult<
  ProgramComponentParticipantsQueryQuery,
  ProgramComponentParticipantsQueryQueryVariables
>;
export const ProgramComponentQueryDocument = gql`
  query ProgramComponentQuery($programId: ID!, $programComponentId: ID!) {
    programComponent(
      programId: $programId
      programComponentId: $programComponentId
    ) {
      id
      name
      type
      body
      targetStartDate
      targetDueDate
      targetEndDate
      closedAt
      isServicedByExec
      sessionNum
      frequency
      requiredCompleteSurvey
      isSetupComplete
      visibleUpcomingQuestions
      participantGuidance
      coachGuidance
      isCurateCoaches
      participantPrelaunchVisibility
      requiredSendLaunchEmail
      requiredSendDueEmail
      requiredSendPastDueEmail
      programId
      schedulerByProgramLeads
      pendingParticipantEmails
      schedulerUsers {
        id
        user {
          name
        }
      }
      participants {
        id
        user {
          name
        }
      }
      forAllParticipants
      coaches {
        id
        name
      }
      products {
        id
        name
      }
      specialty {
        id
        name
      }
      survey {
        id
        formFields {
          id
          answerType
          prompt
          helpText
          required
          order
          formFieldOptions {
            id
            value
            label
          }
          cloneByTemplateId
          cloneByFormFieldId
          link {
            url
            text
            imageSrc
          }
        }
      }
      roleplayScenarios {
        id
        visibilityScope
        name
        personaName
        personaPhotoUrl
        shortDesc
        difficulty
        slug
      }
      roleplayScenariosRank {
        scenarioId
        rank
      }
      prerequisiteComponent {
        id
        name
        targetStartDate
        targetDueDate
        targetEndDate
      }
      dependentComponents {
        id
        name
      }
    }
  }
`;

/**
 * __useProgramComponentQueryQuery__
 *
 * To run a query within a React component, call `useProgramComponentQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramComponentQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramComponentQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      programComponentId: // value for 'programComponentId'
 *   },
 * });
 */
export function useProgramComponentQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramComponentQueryQuery,
    ProgramComponentQueryQueryVariables
  > &
    (
      | { variables: ProgramComponentQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramComponentQueryQuery,
    ProgramComponentQueryQueryVariables
  >(ProgramComponentQueryDocument, options);
}
export function useProgramComponentQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramComponentQueryQuery,
    ProgramComponentQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramComponentQueryQuery,
    ProgramComponentQueryQueryVariables
  >(ProgramComponentQueryDocument, options);
}
export function useProgramComponentQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramComponentQueryQuery,
        ProgramComponentQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramComponentQueryQuery,
    ProgramComponentQueryQueryVariables
  >(ProgramComponentQueryDocument, options);
}
export type ProgramComponentQueryQueryHookResult = ReturnType<
  typeof useProgramComponentQueryQuery
>;
export type ProgramComponentQueryLazyQueryHookResult = ReturnType<
  typeof useProgramComponentQueryLazyQuery
>;
export type ProgramComponentQuerySuspenseQueryHookResult = ReturnType<
  typeof useProgramComponentQuerySuspenseQuery
>;
export type ProgramComponentQueryQueryResult = Apollo.QueryResult<
  ProgramComponentQueryQuery,
  ProgramComponentQueryQueryVariables
>;
export const ProgramComponentResponseQueryDocument = gql`
  query ProgramComponentResponseQuery($responseId: ID!) {
    programComponentResponse(responseId: $responseId) {
      id
      completedAt
      user {
        id
        name
        email
        photo
      }
      programComponent {
        id
        name
        isSetupComplete
        type
        targetEndDate
        targetStartDate
        closedAt
      }
      programSurvey {
        id
        formFields {
          id
          answerType
          prompt
          helpText
          required
          order
          formFieldOptions {
            id
            value
            label
          }
        }
      }
      formFieldAnswers {
        id
        formField {
          id
          answerType
          prompt
          helpText
        }
        value
        optionValue {
          id
          value
          label
        }
        userFile {
          id
          name
          url
        }
      }
      roleplayScenarios {
        id
        name
        shortDesc
        personaPhotoUrl
        difficulty
        slug
        roleplaySessions {
          id
          score
          rank
          createdAt
          archivedAt
          uuid
          formFieldCount
          correctAnswerCount
        }
        archivedAt
      }
    }
  }
`;

/**
 * __useProgramComponentResponseQueryQuery__
 *
 * To run a query within a React component, call `useProgramComponentResponseQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramComponentResponseQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramComponentResponseQueryQuery({
 *   variables: {
 *      responseId: // value for 'responseId'
 *   },
 * });
 */
export function useProgramComponentResponseQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramComponentResponseQueryQuery,
    ProgramComponentResponseQueryQueryVariables
  > &
    (
      | {
          variables: ProgramComponentResponseQueryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramComponentResponseQueryQuery,
    ProgramComponentResponseQueryQueryVariables
  >(ProgramComponentResponseQueryDocument, options);
}
export function useProgramComponentResponseQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramComponentResponseQueryQuery,
    ProgramComponentResponseQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramComponentResponseQueryQuery,
    ProgramComponentResponseQueryQueryVariables
  >(ProgramComponentResponseQueryDocument, options);
}
export function useProgramComponentResponseQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramComponentResponseQueryQuery,
        ProgramComponentResponseQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramComponentResponseQueryQuery,
    ProgramComponentResponseQueryQueryVariables
  >(ProgramComponentResponseQueryDocument, options);
}
export type ProgramComponentResponseQueryQueryHookResult = ReturnType<
  typeof useProgramComponentResponseQueryQuery
>;
export type ProgramComponentResponseQueryLazyQueryHookResult = ReturnType<
  typeof useProgramComponentResponseQueryLazyQuery
>;
export type ProgramComponentResponseQuerySuspenseQueryHookResult = ReturnType<
  typeof useProgramComponentResponseQuerySuspenseQuery
>;
export type ProgramComponentResponseQueryQueryResult = Apollo.QueryResult<
  ProgramComponentResponseQueryQuery,
  ProgramComponentResponseQueryQueryVariables
>;
export const ProgramComponentsQueryDocument = gql`
  query ProgramComponentsQuery($programId: ID!) {
    programComponents(programId: $programId) {
      leadsComponents {
        id
        name
        type
        body
        requiredSendLaunchEmail
        participantPrelaunchVisibility
        isSetupComplete
        visibleUpcomingQuestions
        participantGuidance
        targetStartDate
        targetDueDate
        targetEndDate
        closedAt
        survey {
          id
          formFields {
            id
            prompt
            answerType
            order
            link {
              url
              text
              imageSrc
            }
          }
        }
        roleplayScenarios {
          id
          visibilityScope
          name
          personaName
          personaPhotoUrl
          shortDesc
          difficulty
          slug
          archivedAt
        }
        roleplayScenariosRank {
          scenarioId
          rank
        }
        roleplaySessions {
          id
          scenario {
            id
            name
          }
          user {
            id
            name
          }
        }
        completedCount
        totalParticipants
        forAllParticipants
        startedCount
        prerequisiteComponent {
          id
          name
          targetStartDate
          targetDueDate
          targetEndDate
        }
        programId
        dependentComponents {
          id
          name
        }
        program {
          id
          closedAt
        }
        isGroupMeetingSetupDone
      }
      participantsComponents {
        id
        name
        type
        body
        isSetupComplete
        visibleUpcomingQuestions
        participantGuidance
        targetStartDate
        targetDueDate
        targetEndDate
        closedAt
        programId
        isParticipantRoleplay
        survey {
          id
          isParticipantSurvey
          participantSubmitAt
          formFields {
            id
            prompt
            answerType
            order
            link {
              url
              text
              imageSrc
            }
          }
          formFieldAnswers {
            id
            formField {
              id
              answerType
              prompt
              helpText
            }
            value
            optionValue {
              id
              value
              label
            }
            userFile {
              id
              name
              url
            }
          }
        }
        roleplayScenarios {
          id
          visibilityScope
          name
          personaName
          personaPhotoUrl
          shortDesc
          difficulty
          slug
          archivedAt
        }
        roleplayScenariosRank {
          scenarioId
          rank
        }
        roleplaySessions {
          id
          scenario {
            id
            name
          }
          user {
            id
            name
          }
        }
        participantSubmitAt
        prerequisiteComponent {
          id
          name
          participantSubmitAt
          targetStartDate
          targetDueDate
          targetEndDate
        }
      }
    }
  }
`;

/**
 * __useProgramComponentsQueryQuery__
 *
 * To run a query within a React component, call `useProgramComponentsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramComponentsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramComponentsQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramComponentsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramComponentsQueryQuery,
    ProgramComponentsQueryQueryVariables
  > &
    (
      | { variables: ProgramComponentsQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramComponentsQueryQuery,
    ProgramComponentsQueryQueryVariables
  >(ProgramComponentsQueryDocument, options);
}
export function useProgramComponentsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramComponentsQueryQuery,
    ProgramComponentsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramComponentsQueryQuery,
    ProgramComponentsQueryQueryVariables
  >(ProgramComponentsQueryDocument, options);
}
export function useProgramComponentsQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramComponentsQueryQuery,
        ProgramComponentsQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramComponentsQueryQuery,
    ProgramComponentsQueryQueryVariables
  >(ProgramComponentsQueryDocument, options);
}
export type ProgramComponentsQueryQueryHookResult = ReturnType<
  typeof useProgramComponentsQueryQuery
>;
export type ProgramComponentsQueryLazyQueryHookResult = ReturnType<
  typeof useProgramComponentsQueryLazyQuery
>;
export type ProgramComponentsQuerySuspenseQueryHookResult = ReturnType<
  typeof useProgramComponentsQuerySuspenseQuery
>;
export type ProgramComponentsQueryQueryResult = Apollo.QueryResult<
  ProgramComponentsQueryQuery,
  ProgramComponentsQueryQueryVariables
>;
export const ProgramQueryDocument = gql`
  query ProgramQuery($programId: ID!) {
    program(programId: $programId) {
      id
      name
      status
      targetLaunchDate
      targetEndDate
      closedAt
      closedBy {
        id
        name
        email
        photo
      }
      hasParticipants
      scheduleLaunchEmail
      sendEnrollmentEmailAt
      hasCompletedSetupComponents
      hasComponents
      programOwners {
        id
        summary
        roles
        user {
          id
          name
          email
          photo
          jobTitle
        }
      }
      currentProgramUser {
        id
        updatedAt
        roles
        user {
          id
          name
          email
          photo
        }
      }
    }
  }
`;

/**
 * __useProgramQueryQuery__
 *
 * To run a query within a React component, call `useProgramQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramQueryQuery,
    ProgramQueryQueryVariables
  > &
    (
      | { variables: ProgramQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProgramQueryQuery, ProgramQueryQueryVariables>(
    ProgramQueryDocument,
    options,
  );
}
export function useProgramQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramQueryQuery,
    ProgramQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProgramQueryQuery, ProgramQueryQueryVariables>(
    ProgramQueryDocument,
    options,
  );
}
export function useProgramQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramQueryQuery,
        ProgramQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProgramQueryQuery, ProgramQueryQueryVariables>(
    ProgramQueryDocument,
    options,
  );
}
export type ProgramQueryQueryHookResult = ReturnType<
  typeof useProgramQueryQuery
>;
export type ProgramQueryLazyQueryHookResult = ReturnType<
  typeof useProgramQueryLazyQuery
>;
export type ProgramQuerySuspenseQueryHookResult = ReturnType<
  typeof useProgramQuerySuspenseQuery
>;
export type ProgramQueryQueryResult = Apollo.QueryResult<
  ProgramQueryQuery,
  ProgramQueryQueryVariables
>;
export const ProgramRoleplayScenariosAnalyticsQueryDocument = gql`
  query ProgramRoleplayScenariosAnalyticsQuery($programComponentId: ID!) {
    programRoleplayScenariosAnalytics(programComponentId: $programComponentId) {
      id
      name
      personaName
      personaPhoto
      personaPhotoUrl
      slug
      shortDesc
      totalParticipants
      difficulty
      roleplaySessions {
        id
        userId
        scenarioId
        rank
        score
      }
    }
  }
`;

/**
 * __useProgramRoleplayScenariosAnalyticsQueryQuery__
 *
 * To run a query within a React component, call `useProgramRoleplayScenariosAnalyticsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramRoleplayScenariosAnalyticsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramRoleplayScenariosAnalyticsQueryQuery({
 *   variables: {
 *      programComponentId: // value for 'programComponentId'
 *   },
 * });
 */
export function useProgramRoleplayScenariosAnalyticsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramRoleplayScenariosAnalyticsQueryQuery,
    ProgramRoleplayScenariosAnalyticsQueryQueryVariables
  > &
    (
      | {
          variables: ProgramRoleplayScenariosAnalyticsQueryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramRoleplayScenariosAnalyticsQueryQuery,
    ProgramRoleplayScenariosAnalyticsQueryQueryVariables
  >(ProgramRoleplayScenariosAnalyticsQueryDocument, options);
}
export function useProgramRoleplayScenariosAnalyticsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramRoleplayScenariosAnalyticsQueryQuery,
    ProgramRoleplayScenariosAnalyticsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramRoleplayScenariosAnalyticsQueryQuery,
    ProgramRoleplayScenariosAnalyticsQueryQueryVariables
  >(ProgramRoleplayScenariosAnalyticsQueryDocument, options);
}
export function useProgramRoleplayScenariosAnalyticsQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramRoleplayScenariosAnalyticsQueryQuery,
        ProgramRoleplayScenariosAnalyticsQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramRoleplayScenariosAnalyticsQueryQuery,
    ProgramRoleplayScenariosAnalyticsQueryQueryVariables
  >(ProgramRoleplayScenariosAnalyticsQueryDocument, options);
}
export type ProgramRoleplayScenariosAnalyticsQueryQueryHookResult = ReturnType<
  typeof useProgramRoleplayScenariosAnalyticsQueryQuery
>;
export type ProgramRoleplayScenariosAnalyticsQueryLazyQueryHookResult =
  ReturnType<typeof useProgramRoleplayScenariosAnalyticsQueryLazyQuery>;
export type ProgramRoleplayScenariosAnalyticsQuerySuspenseQueryHookResult =
  ReturnType<typeof useProgramRoleplayScenariosAnalyticsQuerySuspenseQuery>;
export type ProgramRoleplayScenariosAnalyticsQueryQueryResult =
  Apollo.QueryResult<
    ProgramRoleplayScenariosAnalyticsQueryQuery,
    ProgramRoleplayScenariosAnalyticsQueryQueryVariables
  >;
export const ProgramRoleplayScenarioParticipantsQueryDocument = gql`
  query ProgramRoleplayScenarioParticipantsQuery(
    $programComponentId: ID!
    $scenarioId: ID!
  ) {
    programRoleplayScenarioParticipants(
      programComponentId: $programComponentId
      scenarioId: $scenarioId
    ) {
      id
      name
      email
      photo
      roleplaySessions {
        id
        rank
        score
      }
      programComponentResponseId
    }
  }
`;

/**
 * __useProgramRoleplayScenarioParticipantsQueryQuery__
 *
 * To run a query within a React component, call `useProgramRoleplayScenarioParticipantsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramRoleplayScenarioParticipantsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramRoleplayScenarioParticipantsQueryQuery({
 *   variables: {
 *      programComponentId: // value for 'programComponentId'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useProgramRoleplayScenarioParticipantsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramRoleplayScenarioParticipantsQueryQuery,
    ProgramRoleplayScenarioParticipantsQueryQueryVariables
  > &
    (
      | {
          variables: ProgramRoleplayScenarioParticipantsQueryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramRoleplayScenarioParticipantsQueryQuery,
    ProgramRoleplayScenarioParticipantsQueryQueryVariables
  >(ProgramRoleplayScenarioParticipantsQueryDocument, options);
}
export function useProgramRoleplayScenarioParticipantsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramRoleplayScenarioParticipantsQueryQuery,
    ProgramRoleplayScenarioParticipantsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramRoleplayScenarioParticipantsQueryQuery,
    ProgramRoleplayScenarioParticipantsQueryQueryVariables
  >(ProgramRoleplayScenarioParticipantsQueryDocument, options);
}
export function useProgramRoleplayScenarioParticipantsQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramRoleplayScenarioParticipantsQueryQuery,
        ProgramRoleplayScenarioParticipantsQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramRoleplayScenarioParticipantsQueryQuery,
    ProgramRoleplayScenarioParticipantsQueryQueryVariables
  >(ProgramRoleplayScenarioParticipantsQueryDocument, options);
}
export type ProgramRoleplayScenarioParticipantsQueryQueryHookResult =
  ReturnType<typeof useProgramRoleplayScenarioParticipantsQueryQuery>;
export type ProgramRoleplayScenarioParticipantsQueryLazyQueryHookResult =
  ReturnType<typeof useProgramRoleplayScenarioParticipantsQueryLazyQuery>;
export type ProgramRoleplayScenarioParticipantsQuerySuspenseQueryHookResult =
  ReturnType<typeof useProgramRoleplayScenarioParticipantsQuerySuspenseQuery>;
export type ProgramRoleplayScenarioParticipantsQueryQueryResult =
  Apollo.QueryResult<
    ProgramRoleplayScenarioParticipantsQueryQuery,
    ProgramRoleplayScenarioParticipantsQueryQueryVariables
  >;
export const ProgramStatusFilterDocument = gql`
  query ProgramStatusFilter {
    programStatusFilter
  }
`;

/**
 * __useProgramStatusFilterQuery__
 *
 * To run a query within a React component, call `useProgramStatusFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramStatusFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramStatusFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useProgramStatusFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProgramStatusFilterQuery,
    ProgramStatusFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramStatusFilterQuery,
    ProgramStatusFilterQueryVariables
  >(ProgramStatusFilterDocument, options);
}
export function useProgramStatusFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramStatusFilterQuery,
    ProgramStatusFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramStatusFilterQuery,
    ProgramStatusFilterQueryVariables
  >(ProgramStatusFilterDocument, options);
}
export function useProgramStatusFilterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramStatusFilterQuery,
        ProgramStatusFilterQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramStatusFilterQuery,
    ProgramStatusFilterQueryVariables
  >(ProgramStatusFilterDocument, options);
}
export type ProgramStatusFilterQueryHookResult = ReturnType<
  typeof useProgramStatusFilterQuery
>;
export type ProgramStatusFilterLazyQueryHookResult = ReturnType<
  typeof useProgramStatusFilterLazyQuery
>;
export type ProgramStatusFilterSuspenseQueryHookResult = ReturnType<
  typeof useProgramStatusFilterSuspenseQuery
>;
export type ProgramStatusFilterQueryResult = Apollo.QueryResult<
  ProgramStatusFilterQuery,
  ProgramStatusFilterQueryVariables
>;
export const ProgramSurveyQueryDocument = gql`
  query ProgramSurveyQuery($programSurveyId: ID!) {
    programSurvey(programSurveyId: $programSurveyId) {
      id
      formFields {
        id
        answerType
        prompt
        helpText
        required
        order
        formFieldOptions {
          id
          value
          label
        }
        cloneByTemplateId
        cloneByFormFieldId
        link {
          url
          text
        }
      }
      formFieldAnswers {
        id
        formField {
          id
        }
        value
        userFile {
          id
          name
          url
        }
      }
      programComponent {
        id
        name
        type
        targetStartDate
        targetDueDate
        targetEndDate
        closedAt
        participantGuidance
        program {
          id
          status
          name
        }
      }
    }
  }
`;

/**
 * __useProgramSurveyQueryQuery__
 *
 * To run a query within a React component, call `useProgramSurveyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramSurveyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramSurveyQueryQuery({
 *   variables: {
 *      programSurveyId: // value for 'programSurveyId'
 *   },
 * });
 */
export function useProgramSurveyQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramSurveyQueryQuery,
    ProgramSurveyQueryQueryVariables
  > &
    (
      | { variables: ProgramSurveyQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramSurveyQueryQuery,
    ProgramSurveyQueryQueryVariables
  >(ProgramSurveyQueryDocument, options);
}
export function useProgramSurveyQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramSurveyQueryQuery,
    ProgramSurveyQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramSurveyQueryQuery,
    ProgramSurveyQueryQueryVariables
  >(ProgramSurveyQueryDocument, options);
}
export function useProgramSurveyQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramSurveyQueryQuery,
        ProgramSurveyQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramSurveyQueryQuery,
    ProgramSurveyQueryQueryVariables
  >(ProgramSurveyQueryDocument, options);
}
export type ProgramSurveyQueryQueryHookResult = ReturnType<
  typeof useProgramSurveyQueryQuery
>;
export type ProgramSurveyQueryLazyQueryHookResult = ReturnType<
  typeof useProgramSurveyQueryLazyQuery
>;
export type ProgramSurveyQuerySuspenseQueryHookResult = ReturnType<
  typeof useProgramSurveyQuerySuspenseQuery
>;
export type ProgramSurveyQueryQueryResult = Apollo.QueryResult<
  ProgramSurveyQueryQuery,
  ProgramSurveyQueryQueryVariables
>;
export const ProgramSurveyQuestionParticipantsQueryDocument = gql`
  query ProgramSurveyQuestionParticipantsQuery(
    $programSurveyId: ID!
    $formFieldId: ID!
  ) {
    programSurveyQuestionParticipants(
      programSurveyId: $programSurveyId
      formFieldId: $formFieldId
    ) {
      id
      name
      email
      photo
      completedAt
    }
  }
`;

/**
 * __useProgramSurveyQuestionParticipantsQueryQuery__
 *
 * To run a query within a React component, call `useProgramSurveyQuestionParticipantsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramSurveyQuestionParticipantsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramSurveyQuestionParticipantsQueryQuery({
 *   variables: {
 *      programSurveyId: // value for 'programSurveyId'
 *      formFieldId: // value for 'formFieldId'
 *   },
 * });
 */
export function useProgramSurveyQuestionParticipantsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramSurveyQuestionParticipantsQueryQuery,
    ProgramSurveyQuestionParticipantsQueryQueryVariables
  > &
    (
      | {
          variables: ProgramSurveyQuestionParticipantsQueryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramSurveyQuestionParticipantsQueryQuery,
    ProgramSurveyQuestionParticipantsQueryQueryVariables
  >(ProgramSurveyQuestionParticipantsQueryDocument, options);
}
export function useProgramSurveyQuestionParticipantsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramSurveyQuestionParticipantsQueryQuery,
    ProgramSurveyQuestionParticipantsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramSurveyQuestionParticipantsQueryQuery,
    ProgramSurveyQuestionParticipantsQueryQueryVariables
  >(ProgramSurveyQuestionParticipantsQueryDocument, options);
}
export function useProgramSurveyQuestionParticipantsQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramSurveyQuestionParticipantsQueryQuery,
        ProgramSurveyQuestionParticipantsQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramSurveyQuestionParticipantsQueryQuery,
    ProgramSurveyQuestionParticipantsQueryQueryVariables
  >(ProgramSurveyQuestionParticipantsQueryDocument, options);
}
export type ProgramSurveyQuestionParticipantsQueryQueryHookResult = ReturnType<
  typeof useProgramSurveyQuestionParticipantsQueryQuery
>;
export type ProgramSurveyQuestionParticipantsQueryLazyQueryHookResult =
  ReturnType<typeof useProgramSurveyQuestionParticipantsQueryLazyQuery>;
export type ProgramSurveyQuestionParticipantsQuerySuspenseQueryHookResult =
  ReturnType<typeof useProgramSurveyQuestionParticipantsQuerySuspenseQuery>;
export type ProgramSurveyQuestionParticipantsQueryQueryResult =
  Apollo.QueryResult<
    ProgramSurveyQuestionParticipantsQueryQuery,
    ProgramSurveyQuestionParticipantsQueryQueryVariables
  >;
export const ProgramSurveyQuestionsAnalyticsQueryDocument = gql`
  query ProgramSurveyQuestionsAnalyticsQuery($programSurveyId: ID!) {
    programSurveyQuestionsAnalytics(programSurveyId: $programSurveyId) {
      id
      answerType
      prompt
      link {
        url
        text
      }
      order
      totalResponses
      totalParticipants
      favorable
      average
      options {
        id
        value
        label
        totalResponses
        valueProportion
      }
      answers {
        id
        value
        user {
          id
          name
          email
          photo
        }
        userFile {
          id
          name
          url
        }
        updatedAt
      }
    }
  }
`;

/**
 * __useProgramSurveyQuestionsAnalyticsQueryQuery__
 *
 * To run a query within a React component, call `useProgramSurveyQuestionsAnalyticsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramSurveyQuestionsAnalyticsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramSurveyQuestionsAnalyticsQueryQuery({
 *   variables: {
 *      programSurveyId: // value for 'programSurveyId'
 *   },
 * });
 */
export function useProgramSurveyQuestionsAnalyticsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramSurveyQuestionsAnalyticsQueryQuery,
    ProgramSurveyQuestionsAnalyticsQueryQueryVariables
  > &
    (
      | {
          variables: ProgramSurveyQuestionsAnalyticsQueryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramSurveyQuestionsAnalyticsQueryQuery,
    ProgramSurveyQuestionsAnalyticsQueryQueryVariables
  >(ProgramSurveyQuestionsAnalyticsQueryDocument, options);
}
export function useProgramSurveyQuestionsAnalyticsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramSurveyQuestionsAnalyticsQueryQuery,
    ProgramSurveyQuestionsAnalyticsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramSurveyQuestionsAnalyticsQueryQuery,
    ProgramSurveyQuestionsAnalyticsQueryQueryVariables
  >(ProgramSurveyQuestionsAnalyticsQueryDocument, options);
}
export function useProgramSurveyQuestionsAnalyticsQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramSurveyQuestionsAnalyticsQueryQuery,
        ProgramSurveyQuestionsAnalyticsQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramSurveyQuestionsAnalyticsQueryQuery,
    ProgramSurveyQuestionsAnalyticsQueryQueryVariables
  >(ProgramSurveyQuestionsAnalyticsQueryDocument, options);
}
export type ProgramSurveyQuestionsAnalyticsQueryQueryHookResult = ReturnType<
  typeof useProgramSurveyQuestionsAnalyticsQueryQuery
>;
export type ProgramSurveyQuestionsAnalyticsQueryLazyQueryHookResult =
  ReturnType<typeof useProgramSurveyQuestionsAnalyticsQueryLazyQuery>;
export type ProgramSurveyQuestionsAnalyticsQuerySuspenseQueryHookResult =
  ReturnType<typeof useProgramSurveyQuestionsAnalyticsQuerySuspenseQuery>;
export type ProgramSurveyQuestionsAnalyticsQueryQueryResult =
  Apollo.QueryResult<
    ProgramSurveyQuestionsAnalyticsQueryQuery,
    ProgramSurveyQuestionsAnalyticsQueryQueryVariables
  >;
export const ProgramUsersQueryDocument = gql`
  query ProgramUsersQuery(
    $programId: ID!
    $page: Int
    $size: Int
    $orderField: String
    $roles: [ProgramUserRole]
    $query: String
  ) {
    programUsers(
      programId: $programId
      page: $page
      size: $size
      orderField: $orderField
      roles: $roles
      query: $query
    ) {
      total
      page
      size
      data {
        id
        user {
          id
          name
          email
          photo
        }
        roles
        isWorkspaceMemberActive
      }
    }
  }
`;

/**
 * __useProgramUsersQueryQuery__
 *
 * To run a query within a React component, call `useProgramUsersQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramUsersQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramUsersQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      orderField: // value for 'orderField'
 *      roles: // value for 'roles'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useProgramUsersQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProgramUsersQueryQuery,
    ProgramUsersQueryQueryVariables
  > &
    (
      | { variables: ProgramUsersQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProgramUsersQueryQuery,
    ProgramUsersQueryQueryVariables
  >(ProgramUsersQueryDocument, options);
}
export function useProgramUsersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramUsersQueryQuery,
    ProgramUsersQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProgramUsersQueryQuery,
    ProgramUsersQueryQueryVariables
  >(ProgramUsersQueryDocument, options);
}
export function useProgramUsersQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramUsersQueryQuery,
        ProgramUsersQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramUsersQueryQuery,
    ProgramUsersQueryQueryVariables
  >(ProgramUsersQueryDocument, options);
}
export type ProgramUsersQueryQueryHookResult = ReturnType<
  typeof useProgramUsersQueryQuery
>;
export type ProgramUsersQueryLazyQueryHookResult = ReturnType<
  typeof useProgramUsersQueryLazyQuery
>;
export type ProgramUsersQuerySuspenseQueryHookResult = ReturnType<
  typeof useProgramUsersQuerySuspenseQuery
>;
export type ProgramUsersQueryQueryResult = Apollo.QueryResult<
  ProgramUsersQueryQuery,
  ProgramUsersQueryQueryVariables
>;
export const ProgramsQueryDocument = gql`
  query ProgramsQuery(
    $page: Int
    $size: Int
    $orderField: String
    $status: [ProgramStatus]
    $query: String
  ) {
    programs(
      page: $page
      size: $size
      orderField: $orderField
      status: $status
      query: $query
    ) {
      total
      page
      size
      data {
        id
        name
        status
        targetLaunchDate
        targetEndDate
        closedAt
        closedBy {
          id
          name
          email
          photo
        }
        participantCount
        isProgramLead
        isProgramParticipant
        totalParticipantOngoingSurveys
      }
    }
  }
`;

/**
 * __useProgramsQueryQuery__
 *
 * To run a query within a React component, call `useProgramsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsQueryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      orderField: // value for 'orderField'
 *      status: // value for 'status'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useProgramsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProgramsQueryQuery,
    ProgramsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProgramsQueryQuery, ProgramsQueryQueryVariables>(
    ProgramsQueryDocument,
    options,
  );
}
export function useProgramsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramsQueryQuery,
    ProgramsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProgramsQueryQuery, ProgramsQueryQueryVariables>(
    ProgramsQueryDocument,
    options,
  );
}
export function useProgramsQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProgramsQueryQuery,
        ProgramsQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProgramsQueryQuery,
    ProgramsQueryQueryVariables
  >(ProgramsQueryDocument, options);
}
export type ProgramsQueryQueryHookResult = ReturnType<
  typeof useProgramsQueryQuery
>;
export type ProgramsQueryLazyQueryHookResult = ReturnType<
  typeof useProgramsQueryLazyQuery
>;
export type ProgramsQuerySuspenseQueryHookResult = ReturnType<
  typeof useProgramsQuerySuspenseQuery
>;
export type ProgramsQueryQueryResult = Apollo.QueryResult<
  ProgramsQueryQuery,
  ProgramsQueryQueryVariables
>;
export const PublicProgramQueryDocument = gql`
  query PublicProgramQuery($programId: ID!) {
    publicProgram(programId: $programId) {
      id
      name
    }
  }
`;

/**
 * __usePublicProgramQueryQuery__
 *
 * To run a query within a React component, call `usePublicProgramQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicProgramQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicProgramQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function usePublicProgramQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicProgramQueryQuery,
    PublicProgramQueryQueryVariables
  > &
    (
      | { variables: PublicProgramQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PublicProgramQueryQuery,
    PublicProgramQueryQueryVariables
  >(PublicProgramQueryDocument, options);
}
export function usePublicProgramQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicProgramQueryQuery,
    PublicProgramQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicProgramQueryQuery,
    PublicProgramQueryQueryVariables
  >(PublicProgramQueryDocument, options);
}
export function usePublicProgramQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PublicProgramQueryQuery,
        PublicProgramQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PublicProgramQueryQuery,
    PublicProgramQueryQueryVariables
  >(PublicProgramQueryDocument, options);
}
export type PublicProgramQueryQueryHookResult = ReturnType<
  typeof usePublicProgramQueryQuery
>;
export type PublicProgramQueryLazyQueryHookResult = ReturnType<
  typeof usePublicProgramQueryLazyQuery
>;
export type PublicProgramQuerySuspenseQueryHookResult = ReturnType<
  typeof usePublicProgramQuerySuspenseQuery
>;
export type PublicProgramQueryQueryResult = Apollo.QueryResult<
  PublicProgramQueryQuery,
  PublicProgramQueryQueryVariables
>;
export const RemoveProgramComponentDocument = gql`
  mutation RemoveProgramComponent(
    $programComponentId: ID!
    $programId: ID!
    $isReleaseDependentComponents: Boolean
  ) {
    removeProgramComponent(
      programComponentId: $programComponentId
      programId: $programId
      isReleaseDependentComponents: $isReleaseDependentComponents
    ) {
      ok
      msg
    }
  }
`;
export type RemoveProgramComponentMutationFn = Apollo.MutationFunction<
  RemoveProgramComponentMutation,
  RemoveProgramComponentMutationVariables
>;

/**
 * __useRemoveProgramComponentMutation__
 *
 * To run a mutation, you first call `useRemoveProgramComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProgramComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProgramComponentMutation, { data, loading, error }] = useRemoveProgramComponentMutation({
 *   variables: {
 *      programComponentId: // value for 'programComponentId'
 *      programId: // value for 'programId'
 *      isReleaseDependentComponents: // value for 'isReleaseDependentComponents'
 *   },
 * });
 */
export function useRemoveProgramComponentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProgramComponentMutation,
    RemoveProgramComponentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveProgramComponentMutation,
    RemoveProgramComponentMutationVariables
  >(RemoveProgramComponentDocument, options);
}
export type RemoveProgramComponentMutationHookResult = ReturnType<
  typeof useRemoveProgramComponentMutation
>;
export type RemoveProgramComponentMutationResult =
  Apollo.MutationResult<RemoveProgramComponentMutation>;
export type RemoveProgramComponentMutationOptions = Apollo.BaseMutationOptions<
  RemoveProgramComponentMutation,
  RemoveProgramComponentMutationVariables
>;
export const RemoveProgramLeadDocument = gql`
  mutation removeProgramLead($programId: ID!, $programUserId: ID!) {
    removeProgramLead(programId: $programId, programUserId: $programUserId) {
      ok
      msg
    }
  }
`;
export type RemoveProgramLeadMutationFn = Apollo.MutationFunction<
  RemoveProgramLeadMutation,
  RemoveProgramLeadMutationVariables
>;

/**
 * __useRemoveProgramLeadMutation__
 *
 * To run a mutation, you first call `useRemoveProgramLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProgramLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProgramLeadMutation, { data, loading, error }] = useRemoveProgramLeadMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      programUserId: // value for 'programUserId'
 *   },
 * });
 */
export function useRemoveProgramLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProgramLeadMutation,
    RemoveProgramLeadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveProgramLeadMutation,
    RemoveProgramLeadMutationVariables
  >(RemoveProgramLeadDocument, options);
}
export type RemoveProgramLeadMutationHookResult = ReturnType<
  typeof useRemoveProgramLeadMutation
>;
export type RemoveProgramLeadMutationResult =
  Apollo.MutationResult<RemoveProgramLeadMutation>;
export type RemoveProgramLeadMutationOptions = Apollo.BaseMutationOptions<
  RemoveProgramLeadMutation,
  RemoveProgramLeadMutationVariables
>;
export const RemoveProgramParticipantDocument = gql`
  mutation removeProgramParticipant($programId: ID!, $programUserId: ID!) {
    removeProgramParticipant(
      programId: $programId
      programUserId: $programUserId
    ) {
      ok
      msg
    }
  }
`;
export type RemoveProgramParticipantMutationFn = Apollo.MutationFunction<
  RemoveProgramParticipantMutation,
  RemoveProgramParticipantMutationVariables
>;

/**
 * __useRemoveProgramParticipantMutation__
 *
 * To run a mutation, you first call `useRemoveProgramParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProgramParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProgramParticipantMutation, { data, loading, error }] = useRemoveProgramParticipantMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      programUserId: // value for 'programUserId'
 *   },
 * });
 */
export function useRemoveProgramParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProgramParticipantMutation,
    RemoveProgramParticipantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveProgramParticipantMutation,
    RemoveProgramParticipantMutationVariables
  >(RemoveProgramParticipantDocument, options);
}
export type RemoveProgramParticipantMutationHookResult = ReturnType<
  typeof useRemoveProgramParticipantMutation
>;
export type RemoveProgramParticipantMutationResult =
  Apollo.MutationResult<RemoveProgramParticipantMutation>;
export type RemoveProgramParticipantMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveProgramParticipantMutation,
    RemoveProgramParticipantMutationVariables
  >;
export const SaveProgramComponentDocument = gql`
  mutation SaveProgramComponent(
    $input: ProgramComponentInput!
    $programId: ID!
  ) {
    saveProgramComponent(input: $input, programId: $programId) {
      ok
      msg
      programComponent {
        id
        isServicedByExec
      }
    }
  }
`;
export type SaveProgramComponentMutationFn = Apollo.MutationFunction<
  SaveProgramComponentMutation,
  SaveProgramComponentMutationVariables
>;

/**
 * __useSaveProgramComponentMutation__
 *
 * To run a mutation, you first call `useSaveProgramComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProgramComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProgramComponentMutation, { data, loading, error }] = useSaveProgramComponentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useSaveProgramComponentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveProgramComponentMutation,
    SaveProgramComponentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveProgramComponentMutation,
    SaveProgramComponentMutationVariables
  >(SaveProgramComponentDocument, options);
}
export type SaveProgramComponentMutationHookResult = ReturnType<
  typeof useSaveProgramComponentMutation
>;
export type SaveProgramComponentMutationResult =
  Apollo.MutationResult<SaveProgramComponentMutation>;
export type SaveProgramComponentMutationOptions = Apollo.BaseMutationOptions<
  SaveProgramComponentMutation,
  SaveProgramComponentMutationVariables
>;
export const SaveProgramSurveyAnswerDocument = gql`
  mutation SaveProgramSurveyAnswer(
    $surveyId: ID!
    $answerData: [SurveyFormFieldAnswersInput]
  ) {
    saveProgramSurveyAnswer(surveyId: $surveyId, answerData: $answerData) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type SaveProgramSurveyAnswerMutationFn = Apollo.MutationFunction<
  SaveProgramSurveyAnswerMutation,
  SaveProgramSurveyAnswerMutationVariables
>;

/**
 * __useSaveProgramSurveyAnswerMutation__
 *
 * To run a mutation, you first call `useSaveProgramSurveyAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProgramSurveyAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProgramSurveyAnswerMutation, { data, loading, error }] = useSaveProgramSurveyAnswerMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      answerData: // value for 'answerData'
 *   },
 * });
 */
export function useSaveProgramSurveyAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveProgramSurveyAnswerMutation,
    SaveProgramSurveyAnswerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveProgramSurveyAnswerMutation,
    SaveProgramSurveyAnswerMutationVariables
  >(SaveProgramSurveyAnswerDocument, options);
}
export type SaveProgramSurveyAnswerMutationHookResult = ReturnType<
  typeof useSaveProgramSurveyAnswerMutation
>;
export type SaveProgramSurveyAnswerMutationResult =
  Apollo.MutationResult<SaveProgramSurveyAnswerMutation>;
export type SaveProgramSurveyAnswerMutationOptions = Apollo.BaseMutationOptions<
  SaveProgramSurveyAnswerMutation,
  SaveProgramSurveyAnswerMutationVariables
>;
export const SelectableProgramComponentsQueryDocument = gql`
  query SelectableProgramComponentsQuery($programId: ID!) {
    selectableProgramComponents(programId: $programId) {
      id
      name
      type
    }
  }
`;

/**
 * __useSelectableProgramComponentsQueryQuery__
 *
 * To run a query within a React component, call `useSelectableProgramComponentsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectableProgramComponentsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectableProgramComponentsQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useSelectableProgramComponentsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SelectableProgramComponentsQueryQuery,
    SelectableProgramComponentsQueryQueryVariables
  > &
    (
      | {
          variables: SelectableProgramComponentsQueryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SelectableProgramComponentsQueryQuery,
    SelectableProgramComponentsQueryQueryVariables
  >(SelectableProgramComponentsQueryDocument, options);
}
export function useSelectableProgramComponentsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelectableProgramComponentsQueryQuery,
    SelectableProgramComponentsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SelectableProgramComponentsQueryQuery,
    SelectableProgramComponentsQueryQueryVariables
  >(SelectableProgramComponentsQueryDocument, options);
}
export function useSelectableProgramComponentsQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SelectableProgramComponentsQueryQuery,
        SelectableProgramComponentsQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SelectableProgramComponentsQueryQuery,
    SelectableProgramComponentsQueryQueryVariables
  >(SelectableProgramComponentsQueryDocument, options);
}
export type SelectableProgramComponentsQueryQueryHookResult = ReturnType<
  typeof useSelectableProgramComponentsQueryQuery
>;
export type SelectableProgramComponentsQueryLazyQueryHookResult = ReturnType<
  typeof useSelectableProgramComponentsQueryLazyQuery
>;
export type SelectableProgramComponentsQuerySuspenseQueryHookResult =
  ReturnType<typeof useSelectableProgramComponentsQuerySuspenseQuery>;
export type SelectableProgramComponentsQueryQueryResult = Apollo.QueryResult<
  SelectableProgramComponentsQueryQuery,
  SelectableProgramComponentsQueryQueryVariables
>;
export const SendNudgeSurveyDocument = gql`
  mutation SendNudgeSurvey($componentId: ID!, $participantId: ID!) {
    sendNudgeSurvey(componentId: $componentId, participantId: $participantId) {
      ok
      errors {
        message
      }
    }
  }
`;
export type SendNudgeSurveyMutationFn = Apollo.MutationFunction<
  SendNudgeSurveyMutation,
  SendNudgeSurveyMutationVariables
>;

/**
 * __useSendNudgeSurveyMutation__
 *
 * To run a mutation, you first call `useSendNudgeSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNudgeSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNudgeSurveyMutation, { data, loading, error }] = useSendNudgeSurveyMutation({
 *   variables: {
 *      componentId: // value for 'componentId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useSendNudgeSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendNudgeSurveyMutation,
    SendNudgeSurveyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendNudgeSurveyMutation,
    SendNudgeSurveyMutationVariables
  >(SendNudgeSurveyDocument, options);
}
export type SendNudgeSurveyMutationHookResult = ReturnType<
  typeof useSendNudgeSurveyMutation
>;
export type SendNudgeSurveyMutationResult =
  Apollo.MutationResult<SendNudgeSurveyMutation>;
export type SendNudgeSurveyMutationOptions = Apollo.BaseMutationOptions<
  SendNudgeSurveyMutation,
  SendNudgeSurveyMutationVariables
>;
export const SimpleProgramUsersQueryDocument = gql`
  query SimpleProgramUsersQuery($programId: ID!, $roles: [ProgramUserRole]) {
    simpleProgramUsers(programId: $programId, roles: $roles) {
      id
      name
      email
      roles
    }
    pendingParticipantEmails(programId: $programId)
  }
`;

/**
 * __useSimpleProgramUsersQueryQuery__
 *
 * To run a query within a React component, call `useSimpleProgramUsersQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProgramUsersQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProgramUsersQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useSimpleProgramUsersQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleProgramUsersQueryQuery,
    SimpleProgramUsersQueryQueryVariables
  > &
    (
      | { variables: SimpleProgramUsersQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimpleProgramUsersQueryQuery,
    SimpleProgramUsersQueryQueryVariables
  >(SimpleProgramUsersQueryDocument, options);
}
export function useSimpleProgramUsersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleProgramUsersQueryQuery,
    SimpleProgramUsersQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleProgramUsersQueryQuery,
    SimpleProgramUsersQueryQueryVariables
  >(SimpleProgramUsersQueryDocument, options);
}
export function useSimpleProgramUsersQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SimpleProgramUsersQueryQuery,
        SimpleProgramUsersQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SimpleProgramUsersQueryQuery,
    SimpleProgramUsersQueryQueryVariables
  >(SimpleProgramUsersQueryDocument, options);
}
export type SimpleProgramUsersQueryQueryHookResult = ReturnType<
  typeof useSimpleProgramUsersQueryQuery
>;
export type SimpleProgramUsersQueryLazyQueryHookResult = ReturnType<
  typeof useSimpleProgramUsersQueryLazyQuery
>;
export type SimpleProgramUsersQuerySuspenseQueryHookResult = ReturnType<
  typeof useSimpleProgramUsersQuerySuspenseQuery
>;
export type SimpleProgramUsersQueryQueryResult = Apollo.QueryResult<
  SimpleProgramUsersQueryQuery,
  SimpleProgramUsersQueryQueryVariables
>;
export const SurveyTemplatesQueryDocument = gql`
  query SurveyTemplatesQuery($programId: ID!) {
    surveyTemplates(programId: $programId) {
      id
      name
      description
      participantGuidance
      formFields {
        id
        prompt
        helpText
        answerType
        order
        formFieldOptions {
          id
          value
          label
        }
      }
    }
  }
`;

/**
 * __useSurveyTemplatesQueryQuery__
 *
 * To run a query within a React component, call `useSurveyTemplatesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyTemplatesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyTemplatesQueryQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useSurveyTemplatesQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SurveyTemplatesQueryQuery,
    SurveyTemplatesQueryQueryVariables
  > &
    (
      | { variables: SurveyTemplatesQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SurveyTemplatesQueryQuery,
    SurveyTemplatesQueryQueryVariables
  >(SurveyTemplatesQueryDocument, options);
}
export function useSurveyTemplatesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyTemplatesQueryQuery,
    SurveyTemplatesQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SurveyTemplatesQueryQuery,
    SurveyTemplatesQueryQueryVariables
  >(SurveyTemplatesQueryDocument, options);
}
export function useSurveyTemplatesQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SurveyTemplatesQueryQuery,
        SurveyTemplatesQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SurveyTemplatesQueryQuery,
    SurveyTemplatesQueryQueryVariables
  >(SurveyTemplatesQueryDocument, options);
}
export type SurveyTemplatesQueryQueryHookResult = ReturnType<
  typeof useSurveyTemplatesQueryQuery
>;
export type SurveyTemplatesQueryLazyQueryHookResult = ReturnType<
  typeof useSurveyTemplatesQueryLazyQuery
>;
export type SurveyTemplatesQuerySuspenseQueryHookResult = ReturnType<
  typeof useSurveyTemplatesQuerySuspenseQuery
>;
export type SurveyTemplatesQueryQueryResult = Apollo.QueryResult<
  SurveyTemplatesQueryQuery,
  SurveyTemplatesQueryQueryVariables
>;
export const UpdateProgramDocument = gql`
  mutation UpdateProgram(
    $targetLaunchDate: DateTime
    $name: String
    $programId: ID!
    $scheduleLaunchEmail: Boolean
  ) {
    updateProgram(
      targetLaunchDate: $targetLaunchDate
      name: $name
      programId: $programId
      scheduleLaunchEmail: $scheduleLaunchEmail
    ) {
      ok
      msg
    }
  }
`;
export type UpdateProgramMutationFn = Apollo.MutationFunction<
  UpdateProgramMutation,
  UpdateProgramMutationVariables
>;

/**
 * __useUpdateProgramMutation__
 *
 * To run a mutation, you first call `useUpdateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramMutation, { data, loading, error }] = useUpdateProgramMutation({
 *   variables: {
 *      targetLaunchDate: // value for 'targetLaunchDate'
 *      name: // value for 'name'
 *      programId: // value for 'programId'
 *      scheduleLaunchEmail: // value for 'scheduleLaunchEmail'
 *   },
 * });
 */
export function useUpdateProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProgramMutation,
    UpdateProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProgramMutation,
    UpdateProgramMutationVariables
  >(UpdateProgramDocument, options);
}
export type UpdateProgramMutationHookResult = ReturnType<
  typeof useUpdateProgramMutation
>;
export type UpdateProgramMutationResult =
  Apollo.MutationResult<UpdateProgramMutation>;
export type UpdateProgramMutationOptions = Apollo.BaseMutationOptions<
  UpdateProgramMutation,
  UpdateProgramMutationVariables
>;
export const CreateRatingDocument = gql`
  mutation CreateRating(
    $score: Int!
    $comment: String
    $privateComment: String
    $reviewId: ID
    $meetingId: ID
  ) {
    createRating(
      score: $score
      comment: $comment
      privateComment: $privateComment
      reviewId: $reviewId
      meetingId: $meetingId
    ) {
      rating {
        id
      }
    }
  }
`;
export type CreateRatingMutationFn = Apollo.MutationFunction<
  CreateRatingMutation,
  CreateRatingMutationVariables
>;

/**
 * __useCreateRatingMutation__
 *
 * To run a mutation, you first call `useCreateRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRatingMutation, { data, loading, error }] = useCreateRatingMutation({
 *   variables: {
 *      score: // value for 'score'
 *      comment: // value for 'comment'
 *      privateComment: // value for 'privateComment'
 *      reviewId: // value for 'reviewId'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useCreateRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRatingMutation,
    CreateRatingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRatingMutation,
    CreateRatingMutationVariables
  >(CreateRatingDocument, options);
}
export type CreateRatingMutationHookResult = ReturnType<
  typeof useCreateRatingMutation
>;
export type CreateRatingMutationResult =
  Apollo.MutationResult<CreateRatingMutation>;
export type CreateRatingMutationOptions = Apollo.BaseMutationOptions<
  CreateRatingMutation,
  CreateRatingMutationVariables
>;
export const DeleteRatingDocument = gql`
  mutation DeleteRating($id: ID!) {
    deleteRating(id: $id) {
      ok
    }
  }
`;
export type DeleteRatingMutationFn = Apollo.MutationFunction<
  DeleteRatingMutation,
  DeleteRatingMutationVariables
>;

/**
 * __useDeleteRatingMutation__
 *
 * To run a mutation, you first call `useDeleteRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRatingMutation, { data, loading, error }] = useDeleteRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRatingMutation,
    DeleteRatingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRatingMutation,
    DeleteRatingMutationVariables
  >(DeleteRatingDocument, options);
}
export type DeleteRatingMutationHookResult = ReturnType<
  typeof useDeleteRatingMutation
>;
export type DeleteRatingMutationResult =
  Apollo.MutationResult<DeleteRatingMutation>;
export type DeleteRatingMutationOptions = Apollo.BaseMutationOptions<
  DeleteRatingMutation,
  DeleteRatingMutationVariables
>;
export const GetRatingInfoDocument = gql`
  query GetRatingInfo(
    $meeting: Boolean!
    $review: Boolean!
    $meetingId: ID
    $reviewId: ID
    $ratingId: ID
  ) {
    meeting(id: $meetingId) @include(if: $meeting) {
      id
      eventAt
      canLeaveRating
      coach {
        id
        name
        photo
      }
      customer {
        id
        name
        photo
      }
      product {
        id
        name
      }
    }
    review(id: $reviewId) @include(if: $review) {
      id
      completedAt
      canLeaveRating
      coach {
        id
        name
        photo
      }
      customer {
        id
        name
        photo
      }
      product {
        id
        name
      }
    }
    rating(id: $ratingId, meetingId: $meetingId, reviewId: $reviewId) {
      id
      score
      comment
      privateComment
      updateAllowed
      coach {
        id
        name
        photo
      }
      customer {
        id
        name
        photo
      }
      meeting {
        id
        eventAt
        coach {
          id
          name
          photo
        }
        product {
          id
          name
        }
      }
      review {
        id
        completedAt
        coach {
          id
          name
          photo
        }
        product {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetRatingInfoQuery__
 *
 * To run a query within a React component, call `useGetRatingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRatingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRatingInfoQuery({
 *   variables: {
 *      meeting: // value for 'meeting'
 *      review: // value for 'review'
 *      meetingId: // value for 'meetingId'
 *      reviewId: // value for 'reviewId'
 *      ratingId: // value for 'ratingId'
 *   },
 * });
 */
export function useGetRatingInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRatingInfoQuery,
    GetRatingInfoQueryVariables
  > &
    (
      | { variables: GetRatingInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRatingInfoQuery, GetRatingInfoQueryVariables>(
    GetRatingInfoDocument,
    options,
  );
}
export function useGetRatingInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRatingInfoQuery,
    GetRatingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRatingInfoQuery, GetRatingInfoQueryVariables>(
    GetRatingInfoDocument,
    options,
  );
}
export function useGetRatingInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetRatingInfoQuery,
        GetRatingInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRatingInfoQuery,
    GetRatingInfoQueryVariables
  >(GetRatingInfoDocument, options);
}
export type GetRatingInfoQueryHookResult = ReturnType<
  typeof useGetRatingInfoQuery
>;
export type GetRatingInfoLazyQueryHookResult = ReturnType<
  typeof useGetRatingInfoLazyQuery
>;
export type GetRatingInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetRatingInfoSuspenseQuery
>;
export type GetRatingInfoQueryResult = Apollo.QueryResult<
  GetRatingInfoQuery,
  GetRatingInfoQueryVariables
>;
export const UpdateRatingDocument = gql`
  mutation UpdateRating(
    $id: ID!
    $score: Int!
    $comment: String
    $privateComment: String
  ) {
    updateRating(
      id: $id
      score: $score
      comment: $comment
      privateComment: $privateComment
    ) {
      rating {
        id
      }
    }
  }
`;
export type UpdateRatingMutationFn = Apollo.MutationFunction<
  UpdateRatingMutation,
  UpdateRatingMutationVariables
>;

/**
 * __useUpdateRatingMutation__
 *
 * To run a mutation, you first call `useUpdateRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRatingMutation, { data, loading, error }] = useUpdateRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      score: // value for 'score'
 *      comment: // value for 'comment'
 *      privateComment: // value for 'privateComment'
 *   },
 * });
 */
export function useUpdateRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRatingMutation,
    UpdateRatingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRatingMutation,
    UpdateRatingMutationVariables
  >(UpdateRatingDocument, options);
}
export type UpdateRatingMutationHookResult = ReturnType<
  typeof useUpdateRatingMutation
>;
export type UpdateRatingMutationResult =
  Apollo.MutationResult<UpdateRatingMutation>;
export type UpdateRatingMutationOptions = Apollo.BaseMutationOptions<
  UpdateRatingMutation,
  UpdateRatingMutationVariables
>;
export const MeetingDetailDocument = gql`
  query MeetingDetail($id: ID!, $customerId: ID) {
    meeting(id: $id, customerId: $customerId) {
      id
      duration
      eventAt
      refundableUntil
      canceledAt
      creditPrice
      coach {
        id
        name
        photo
      }
      customer {
        id
        name
        photo
      }
      product {
        id
        name
        slug
        creditPrice
      }
      workspace {
        id
        name
      }
    }
    currentUser {
      id
      name
      timeZone
    }
  }
`;

/**
 * __useMeetingDetailQuery__
 *
 * To run a query within a React component, call `useMeetingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useMeetingDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    MeetingDetailQuery,
    MeetingDetailQueryVariables
  > &
    (
      | { variables: MeetingDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeetingDetailQuery, MeetingDetailQueryVariables>(
    MeetingDetailDocument,
    options,
  );
}
export function useMeetingDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeetingDetailQuery,
    MeetingDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeetingDetailQuery, MeetingDetailQueryVariables>(
    MeetingDetailDocument,
    options,
  );
}
export function useMeetingDetailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MeetingDetailQuery,
        MeetingDetailQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MeetingDetailQuery,
    MeetingDetailQueryVariables
  >(MeetingDetailDocument, options);
}
export type MeetingDetailQueryHookResult = ReturnType<
  typeof useMeetingDetailQuery
>;
export type MeetingDetailLazyQueryHookResult = ReturnType<
  typeof useMeetingDetailLazyQuery
>;
export type MeetingDetailSuspenseQueryHookResult = ReturnType<
  typeof useMeetingDetailSuspenseQuery
>;
export type MeetingDetailQueryResult = Apollo.QueryResult<
  MeetingDetailQuery,
  MeetingDetailQueryVariables
>;
export const RescheduleCoachMeetingDocument = gql`
  mutation RescheduleCoachMeeting(
    $meetingId: ID!
    $message: String
    $startTime: DateTime!
    $forceRefund: Boolean
    $customerId: ID
    $usedAllTimeslot: Boolean
  ) {
    rescheduleCoachMeeting(
      meetingId: $meetingId
      startTime: $startTime
      message: $message
      forceRefund: $forceRefund
      customerId: $customerId
      usedAllTimeslot: $usedAllTimeslot
    ) {
      status
      meeting {
        id
        eventAt
      }
    }
  }
`;
export type RescheduleCoachMeetingMutationFn = Apollo.MutationFunction<
  RescheduleCoachMeetingMutation,
  RescheduleCoachMeetingMutationVariables
>;

/**
 * __useRescheduleCoachMeetingMutation__
 *
 * To run a mutation, you first call `useRescheduleCoachMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleCoachMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleCoachMeetingMutation, { data, loading, error }] = useRescheduleCoachMeetingMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      message: // value for 'message'
 *      startTime: // value for 'startTime'
 *      forceRefund: // value for 'forceRefund'
 *      customerId: // value for 'customerId'
 *      usedAllTimeslot: // value for 'usedAllTimeslot'
 *   },
 * });
 */
export function useRescheduleCoachMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleCoachMeetingMutation,
    RescheduleCoachMeetingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleCoachMeetingMutation,
    RescheduleCoachMeetingMutationVariables
  >(RescheduleCoachMeetingDocument, options);
}
export type RescheduleCoachMeetingMutationHookResult = ReturnType<
  typeof useRescheduleCoachMeetingMutation
>;
export type RescheduleCoachMeetingMutationResult =
  Apollo.MutationResult<RescheduleCoachMeetingMutation>;
export type RescheduleCoachMeetingMutationOptions = Apollo.BaseMutationOptions<
  RescheduleCoachMeetingMutation,
  RescheduleCoachMeetingMutationVariables
>;
export const BookReviewPageDocument = gql`
  query BookReviewPage {
    currentUser {
      id
      email
      name
      availableCredits
      ledgerAccounts {
        id
        label
        balance
      }
      coaches {
        id
        name
        firstName
        lastName
        photo
        activeCoachProducts {
          id
          product {
            id
          }
        }
        agentProfile {
          id
          summary
          averageRating
          acceptingNewBookings
        }
      }
    }
  }
`;

/**
 * __useBookReviewPageQuery__
 *
 * To run a query within a React component, call `useBookReviewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookReviewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookReviewPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookReviewPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BookReviewPageQuery,
    BookReviewPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookReviewPageQuery, BookReviewPageQueryVariables>(
    BookReviewPageDocument,
    options,
  );
}
export function useBookReviewPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookReviewPageQuery,
    BookReviewPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookReviewPageQuery, BookReviewPageQueryVariables>(
    BookReviewPageDocument,
    options,
  );
}
export function useBookReviewPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BookReviewPageQuery,
        BookReviewPageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BookReviewPageQuery,
    BookReviewPageQueryVariables
  >(BookReviewPageDocument, options);
}
export type BookReviewPageQueryHookResult = ReturnType<
  typeof useBookReviewPageQuery
>;
export type BookReviewPageLazyQueryHookResult = ReturnType<
  typeof useBookReviewPageLazyQuery
>;
export type BookReviewPageSuspenseQueryHookResult = ReturnType<
  typeof useBookReviewPageSuspenseQuery
>;
export type BookReviewPageQueryResult = Apollo.QueryResult<
  BookReviewPageQuery,
  BookReviewPageQueryVariables
>;
export const ReviewsPageDocument = gql`
  query ReviewsPage {
    currentUser {
      id
      isAgent
      ...UserAvailableCreditsFragment
      ...UserReviewsFragment
    }
  }
  ${UserAvailableCreditsFragmentFragmentDoc}
  ${UserReviewsFragmentFragmentDoc}
  ${ReviewFragmentFragmentDoc}
`;

/**
 * __useReviewsPageQuery__
 *
 * To run a query within a React component, call `useReviewsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReviewsPageQuery,
    ReviewsPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewsPageQuery, ReviewsPageQueryVariables>(
    ReviewsPageDocument,
    options,
  );
}
export function useReviewsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReviewsPageQuery,
    ReviewsPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewsPageQuery, ReviewsPageQueryVariables>(
    ReviewsPageDocument,
    options,
  );
}
export function useReviewsPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReviewsPageQuery,
        ReviewsPageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReviewsPageQuery, ReviewsPageQueryVariables>(
    ReviewsPageDocument,
    options,
  );
}
export type ReviewsPageQueryHookResult = ReturnType<typeof useReviewsPageQuery>;
export type ReviewsPageLazyQueryHookResult = ReturnType<
  typeof useReviewsPageLazyQuery
>;
export type ReviewsPageSuspenseQueryHookResult = ReturnType<
  typeof useReviewsPageSuspenseQuery
>;
export type ReviewsPageQueryResult = Apollo.QueryResult<
  ReviewsPageQuery,
  ReviewsPageQueryVariables
>;
export const MyCollectionsDocument = gql`
  query MyCollections {
    myCollections {
      id
      name
      slug
      emojiUnicode
      description
      introduction
      priority
      visibilityScope
      scenarios {
        id
        name
        slug
        rank
      }
      workspace {
        id
        name
      }
      canEdit
    }
  }
`;

/**
 * __useMyCollectionsQuery__
 *
 * To run a query within a React component, call `useMyCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyCollectionsQuery,
    MyCollectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyCollectionsQuery, MyCollectionsQueryVariables>(
    MyCollectionsDocument,
    options,
  );
}
export function useMyCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyCollectionsQuery,
    MyCollectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyCollectionsQuery, MyCollectionsQueryVariables>(
    MyCollectionsDocument,
    options,
  );
}
export function useMyCollectionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyCollectionsQuery,
        MyCollectionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyCollectionsQuery,
    MyCollectionsQueryVariables
  >(MyCollectionsDocument, options);
}
export type MyCollectionsQueryHookResult = ReturnType<
  typeof useMyCollectionsQuery
>;
export type MyCollectionsLazyQueryHookResult = ReturnType<
  typeof useMyCollectionsLazyQuery
>;
export type MyCollectionsSuspenseQueryHookResult = ReturnType<
  typeof useMyCollectionsSuspenseQuery
>;
export type MyCollectionsQueryResult = Apollo.QueryResult<
  MyCollectionsQuery,
  MyCollectionsQueryVariables
>;
export const PaginatedRoleplaysDocument = gql`
  query PaginatedRoleplays(
    $currentPage: Int!
    $pageSize: Int!
    $scope: VisibilityScope
    $query: String
    $workspaceOnly: Boolean
    $includeOwnerData: Boolean
    $isOwner: Boolean
    $excludedIds: [ID!]
  ) {
    paginatedRoleplays(
      currentPage: $currentPage
      pageSize: $pageSize
      scope: $scope
      query: $query
      workspaceOnly: $workspaceOnly
      includeOwnerData: $includeOwnerData
      isOwner: $isOwner
      excludedIds: $excludedIds
    ) {
      currentPage
      pageSize
      totalRecords
      data {
        id
        name
        slug
        emojiUnicode
        description
        introduction
        priority
        visibilityScope
        scenarios {
          id
          name
          slug
          rank
        }
        workspace {
          id
          name
        }
        canEdit
      }
    }
  }
`;

/**
 * __usePaginatedRoleplaysQuery__
 *
 * To run a query within a React component, call `usePaginatedRoleplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedRoleplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedRoleplaysQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *      pageSize: // value for 'pageSize'
 *      scope: // value for 'scope'
 *      query: // value for 'query'
 *      workspaceOnly: // value for 'workspaceOnly'
 *      includeOwnerData: // value for 'includeOwnerData'
 *      isOwner: // value for 'isOwner'
 *      excludedIds: // value for 'excludedIds'
 *   },
 * });
 */
export function usePaginatedRoleplaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedRoleplaysQuery,
    PaginatedRoleplaysQueryVariables
  > &
    (
      | { variables: PaginatedRoleplaysQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedRoleplaysQuery,
    PaginatedRoleplaysQueryVariables
  >(PaginatedRoleplaysDocument, options);
}
export function usePaginatedRoleplaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedRoleplaysQuery,
    PaginatedRoleplaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedRoleplaysQuery,
    PaginatedRoleplaysQueryVariables
  >(PaginatedRoleplaysDocument, options);
}
export function usePaginatedRoleplaysSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PaginatedRoleplaysQuery,
        PaginatedRoleplaysQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PaginatedRoleplaysQuery,
    PaginatedRoleplaysQueryVariables
  >(PaginatedRoleplaysDocument, options);
}
export type PaginatedRoleplaysQueryHookResult = ReturnType<
  typeof usePaginatedRoleplaysQuery
>;
export type PaginatedRoleplaysLazyQueryHookResult = ReturnType<
  typeof usePaginatedRoleplaysLazyQuery
>;
export type PaginatedRoleplaysSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedRoleplaysSuspenseQuery
>;
export type PaginatedRoleplaysQueryResult = Apollo.QueryResult<
  PaginatedRoleplaysQuery,
  PaginatedRoleplaysQueryVariables
>;
export const PublicRoleplaysDocument = gql`
  query PublicRoleplays {
    publicRoleplays {
      id
      name
      slug
      emojiUnicode
      description
      priority
      visibilityScope
    }
  }
`;

/**
 * __usePublicRoleplaysQuery__
 *
 * To run a query within a React component, call `usePublicRoleplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicRoleplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicRoleplaysQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicRoleplaysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PublicRoleplaysQuery,
    PublicRoleplaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PublicRoleplaysQuery, PublicRoleplaysQueryVariables>(
    PublicRoleplaysDocument,
    options,
  );
}
export function usePublicRoleplaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicRoleplaysQuery,
    PublicRoleplaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicRoleplaysQuery,
    PublicRoleplaysQueryVariables
  >(PublicRoleplaysDocument, options);
}
export function usePublicRoleplaysSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PublicRoleplaysQuery,
        PublicRoleplaysQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PublicRoleplaysQuery,
    PublicRoleplaysQueryVariables
  >(PublicRoleplaysDocument, options);
}
export type PublicRoleplaysQueryHookResult = ReturnType<
  typeof usePublicRoleplaysQuery
>;
export type PublicRoleplaysLazyQueryHookResult = ReturnType<
  typeof usePublicRoleplaysLazyQuery
>;
export type PublicRoleplaysSuspenseQueryHookResult = ReturnType<
  typeof usePublicRoleplaysSuspenseQuery
>;
export type PublicRoleplaysQueryResult = Apollo.QueryResult<
  PublicRoleplaysQuery,
  PublicRoleplaysQueryVariables
>;
export const ScenariosDocument = gql`
  query Scenarios($roleplayId: ID!, $workspaceOnly: Boolean) {
    roleplayScenarios(roleplayId: $roleplayId, workspaceOnly: $workspaceOnly) {
      ...ScenarioFields
    }
  }
  ${ScenarioFieldsFragmentDoc}
`;

/**
 * __useScenariosQuery__
 *
 * To run a query within a React component, call `useScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenariosQuery({
 *   variables: {
 *      roleplayId: // value for 'roleplayId'
 *      workspaceOnly: // value for 'workspaceOnly'
 *   },
 * });
 */
export function useScenariosQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScenariosQuery,
    ScenariosQueryVariables
  > &
    (
      | { variables: ScenariosQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScenariosQuery, ScenariosQueryVariables>(
    ScenariosDocument,
    options,
  );
}
export function useScenariosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScenariosQuery,
    ScenariosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScenariosQuery, ScenariosQueryVariables>(
    ScenariosDocument,
    options,
  );
}
export function useScenariosSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ScenariosQuery, ScenariosQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ScenariosQuery, ScenariosQueryVariables>(
    ScenariosDocument,
    options,
  );
}
export type ScenariosQueryHookResult = ReturnType<typeof useScenariosQuery>;
export type ScenariosLazyQueryHookResult = ReturnType<
  typeof useScenariosLazyQuery
>;
export type ScenariosSuspenseQueryHookResult = ReturnType<
  typeof useScenariosSuspenseQuery
>;
export type ScenariosQueryResult = Apollo.QueryResult<
  ScenariosQuery,
  ScenariosQueryVariables
>;
export const AddCollectionScenarioDocument = gql`
  mutation AddCollectionScenario($roleplaySlug: String!, $scenarioIds: [ID]!) {
    addCollectionScenario(
      roleplaySlug: $roleplaySlug
      scenarioIds: $scenarioIds
    ) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type AddCollectionScenarioMutationFn = Apollo.MutationFunction<
  AddCollectionScenarioMutation,
  AddCollectionScenarioMutationVariables
>;

/**
 * __useAddCollectionScenarioMutation__
 *
 * To run a mutation, you first call `useAddCollectionScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollectionScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollectionScenarioMutation, { data, loading, error }] = useAddCollectionScenarioMutation({
 *   variables: {
 *      roleplaySlug: // value for 'roleplaySlug'
 *      scenarioIds: // value for 'scenarioIds'
 *   },
 * });
 */
export function useAddCollectionScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCollectionScenarioMutation,
    AddCollectionScenarioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCollectionScenarioMutation,
    AddCollectionScenarioMutationVariables
  >(AddCollectionScenarioDocument, options);
}
export type AddCollectionScenarioMutationHookResult = ReturnType<
  typeof useAddCollectionScenarioMutation
>;
export type AddCollectionScenarioMutationResult =
  Apollo.MutationResult<AddCollectionScenarioMutation>;
export type AddCollectionScenarioMutationOptions = Apollo.BaseMutationOptions<
  AddCollectionScenarioMutation,
  AddCollectionScenarioMutationVariables
>;
export const EditScenarioDocument = gql`
  mutation EditScenario(
    $slug: String!
    $name: String!
    $context: String!
    $objective: String!
  ) {
    editScenario(
      slug: $slug
      name: $name
      context: $context
      objective: $objective
    ) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type EditScenarioMutationFn = Apollo.MutationFunction<
  EditScenarioMutation,
  EditScenarioMutationVariables
>;

/**
 * __useEditScenarioMutation__
 *
 * To run a mutation, you first call `useEditScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editScenarioMutation, { data, loading, error }] = useEditScenarioMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      name: // value for 'name'
 *      context: // value for 'context'
 *      objective: // value for 'objective'
 *   },
 * });
 */
export function useEditScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditScenarioMutation,
    EditScenarioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditScenarioMutation,
    EditScenarioMutationVariables
  >(EditScenarioDocument, options);
}
export type EditScenarioMutationHookResult = ReturnType<
  typeof useEditScenarioMutation
>;
export type EditScenarioMutationResult =
  Apollo.MutationResult<EditScenarioMutation>;
export type EditScenarioMutationOptions = Apollo.BaseMutationOptions<
  EditScenarioMutation,
  EditScenarioMutationVariables
>;
export const InviteUsersToSessionDocument = gql`
  mutation InviteUsersToSession(
    $sessionId: ID!
    $emails: [String!]!
    $isNotified: Boolean
    $message: String
  ) {
    inviteUsersToSession(
      sessionId: $sessionId
      emails: $emails
      isNotified: $isNotified
      message: $message
    ) {
      ok
      errors {
        message
      }
    }
  }
`;
export type InviteUsersToSessionMutationFn = Apollo.MutationFunction<
  InviteUsersToSessionMutation,
  InviteUsersToSessionMutationVariables
>;

/**
 * __useInviteUsersToSessionMutation__
 *
 * To run a mutation, you first call `useInviteUsersToSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersToSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersToSessionMutation, { data, loading, error }] = useInviteUsersToSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      emails: // value for 'emails'
 *      isNotified: // value for 'isNotified'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useInviteUsersToSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUsersToSessionMutation,
    InviteUsersToSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteUsersToSessionMutation,
    InviteUsersToSessionMutationVariables
  >(InviteUsersToSessionDocument, options);
}
export type InviteUsersToSessionMutationHookResult = ReturnType<
  typeof useInviteUsersToSessionMutation
>;
export type InviteUsersToSessionMutationResult =
  Apollo.MutationResult<InviteUsersToSessionMutation>;
export type InviteUsersToSessionMutationOptions = Apollo.BaseMutationOptions<
  InviteUsersToSessionMutation,
  InviteUsersToSessionMutationVariables
>;
export const PersonalityAttributesDocument = gql`
  query PersonalityAttributes {
    personalityAttributes {
      id
      name
      description
      levels {
        id
        name
        description
        level
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __usePersonalityAttributesQuery__
 *
 * To run a query within a React component, call `usePersonalityAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalityAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalityAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalityAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PersonalityAttributesQuery,
    PersonalityAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PersonalityAttributesQuery,
    PersonalityAttributesQueryVariables
  >(PersonalityAttributesDocument, options);
}
export function usePersonalityAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonalityAttributesQuery,
    PersonalityAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PersonalityAttributesQuery,
    PersonalityAttributesQueryVariables
  >(PersonalityAttributesDocument, options);
}
export function usePersonalityAttributesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PersonalityAttributesQuery,
        PersonalityAttributesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PersonalityAttributesQuery,
    PersonalityAttributesQueryVariables
  >(PersonalityAttributesDocument, options);
}
export type PersonalityAttributesQueryHookResult = ReturnType<
  typeof usePersonalityAttributesQuery
>;
export type PersonalityAttributesLazyQueryHookResult = ReturnType<
  typeof usePersonalityAttributesLazyQuery
>;
export type PersonalityAttributesSuspenseQueryHookResult = ReturnType<
  typeof usePersonalityAttributesSuspenseQuery
>;
export type PersonalityAttributesQueryResult = Apollo.QueryResult<
  PersonalityAttributesQuery,
  PersonalityAttributesQueryVariables
>;
export const RemoveCollectionDocument = gql`
  mutation RemoveCollection($id: ID!) {
    removeCollection(id: $id) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type RemoveCollectionMutationFn = Apollo.MutationFunction<
  RemoveCollectionMutation,
  RemoveCollectionMutationVariables
>;

/**
 * __useRemoveCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollectionMutation, { data, loading, error }] = useRemoveCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCollectionMutation,
    RemoveCollectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCollectionMutation,
    RemoveCollectionMutationVariables
  >(RemoveCollectionDocument, options);
}
export type RemoveCollectionMutationHookResult = ReturnType<
  typeof useRemoveCollectionMutation
>;
export type RemoveCollectionMutationResult =
  Apollo.MutationResult<RemoveCollectionMutation>;
export type RemoveCollectionMutationOptions = Apollo.BaseMutationOptions<
  RemoveCollectionMutation,
  RemoveCollectionMutationVariables
>;
export const RemoveCollectionScenarioDocument = gql`
  mutation RemoveCollectionScenario(
    $roleplaySlug: String!
    $scenarioIds: [ID]!
  ) {
    removeCollectionScenario(
      roleplaySlug: $roleplaySlug
      scenarioIds: $scenarioIds
    ) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type RemoveCollectionScenarioMutationFn = Apollo.MutationFunction<
  RemoveCollectionScenarioMutation,
  RemoveCollectionScenarioMutationVariables
>;

/**
 * __useRemoveCollectionScenarioMutation__
 *
 * To run a mutation, you first call `useRemoveCollectionScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollectionScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollectionScenarioMutation, { data, loading, error }] = useRemoveCollectionScenarioMutation({
 *   variables: {
 *      roleplaySlug: // value for 'roleplaySlug'
 *      scenarioIds: // value for 'scenarioIds'
 *   },
 * });
 */
export function useRemoveCollectionScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCollectionScenarioMutation,
    RemoveCollectionScenarioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCollectionScenarioMutation,
    RemoveCollectionScenarioMutationVariables
  >(RemoveCollectionScenarioDocument, options);
}
export type RemoveCollectionScenarioMutationHookResult = ReturnType<
  typeof useRemoveCollectionScenarioMutation
>;
export type RemoveCollectionScenarioMutationResult =
  Apollo.MutationResult<RemoveCollectionScenarioMutation>;
export type RemoveCollectionScenarioMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveCollectionScenarioMutation,
    RemoveCollectionScenarioMutationVariables
  >;
export const RevokeAccessFromSessionDocument = gql`
  mutation RevokeAccessFromSession($sessionId: ID!, $workspaceMemberId: ID!) {
    revokeAccessFromSession(
      sessionId: $sessionId
      workspaceMemberId: $workspaceMemberId
    ) {
      ok
      errors {
        message
      }
    }
  }
`;
export type RevokeAccessFromSessionMutationFn = Apollo.MutationFunction<
  RevokeAccessFromSessionMutation,
  RevokeAccessFromSessionMutationVariables
>;

/**
 * __useRevokeAccessFromSessionMutation__
 *
 * To run a mutation, you first call `useRevokeAccessFromSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAccessFromSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAccessFromSessionMutation, { data, loading, error }] = useRevokeAccessFromSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      workspaceMemberId: // value for 'workspaceMemberId'
 *   },
 * });
 */
export function useRevokeAccessFromSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeAccessFromSessionMutation,
    RevokeAccessFromSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeAccessFromSessionMutation,
    RevokeAccessFromSessionMutationVariables
  >(RevokeAccessFromSessionDocument, options);
}
export type RevokeAccessFromSessionMutationHookResult = ReturnType<
  typeof useRevokeAccessFromSessionMutation
>;
export type RevokeAccessFromSessionMutationResult =
  Apollo.MutationResult<RevokeAccessFromSessionMutation>;
export type RevokeAccessFromSessionMutationOptions = Apollo.BaseMutationOptions<
  RevokeAccessFromSessionMutation,
  RevokeAccessFromSessionMutationVariables
>;
export const RoleplayVoicesDocument = gql`
  query RoleplayVoices {
    roleplayVoices {
      id
      voiceId
      gender
      provider
      sampleUrl
      name
    }
  }
`;

/**
 * __useRoleplayVoicesQuery__
 *
 * To run a query within a React component, call `useRoleplayVoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleplayVoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleplayVoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleplayVoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RoleplayVoicesQuery,
    RoleplayVoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleplayVoicesQuery, RoleplayVoicesQueryVariables>(
    RoleplayVoicesDocument,
    options,
  );
}
export function useRoleplayVoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleplayVoicesQuery,
    RoleplayVoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleplayVoicesQuery, RoleplayVoicesQueryVariables>(
    RoleplayVoicesDocument,
    options,
  );
}
export function useRoleplayVoicesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RoleplayVoicesQuery,
        RoleplayVoicesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RoleplayVoicesQuery,
    RoleplayVoicesQueryVariables
  >(RoleplayVoicesDocument, options);
}
export type RoleplayVoicesQueryHookResult = ReturnType<
  typeof useRoleplayVoicesQuery
>;
export type RoleplayVoicesLazyQueryHookResult = ReturnType<
  typeof useRoleplayVoicesLazyQuery
>;
export type RoleplayVoicesSuspenseQueryHookResult = ReturnType<
  typeof useRoleplayVoicesSuspenseQuery
>;
export type RoleplayVoicesQueryResult = Apollo.QueryResult<
  RoleplayVoicesQuery,
  RoleplayVoicesQueryVariables
>;
export const SaveCollectionDocument = gql`
  mutation SaveCollection(
    $id: ID
    $name: String!
    $introduction: String
    $emojiUnicode: String
    $description: String
  ) {
    saveCollection(
      id: $id
      name: $name
      introduction: $introduction
      emojiUnicode: $emojiUnicode
      description: $description
    ) {
      ok
      errors {
        field
        message
      }
      collection {
        id
        slug
      }
    }
  }
`;
export type SaveCollectionMutationFn = Apollo.MutationFunction<
  SaveCollectionMutation,
  SaveCollectionMutationVariables
>;

/**
 * __useSaveCollectionMutation__
 *
 * To run a mutation, you first call `useSaveCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCollectionMutation, { data, loading, error }] = useSaveCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      introduction: // value for 'introduction'
 *      emojiUnicode: // value for 'emojiUnicode'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useSaveCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCollectionMutation,
    SaveCollectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCollectionMutation,
    SaveCollectionMutationVariables
  >(SaveCollectionDocument, options);
}
export type SaveCollectionMutationHookResult = ReturnType<
  typeof useSaveCollectionMutation
>;
export type SaveCollectionMutationResult =
  Apollo.MutationResult<SaveCollectionMutation>;
export type SaveCollectionMutationOptions = Apollo.BaseMutationOptions<
  SaveCollectionMutation,
  SaveCollectionMutationVariables
>;
export const ScenariosQueryDocument = gql`
  query ScenariosQuery(
    $isOwner: Boolean
    $isAssignee: Boolean
    $page: Int
    $pageSize: Int
    $status: QueryScenariosStatus
    $scope: VisibilityScope
    $query: String
    $collectionId: ID
    $workspaceOnly: Boolean
    $isOrphaned: Boolean
    $orderBy: QueryScenariosOrderBy
  ) {
    scenarios(
      isOwner: $isOwner
      isAssignee: $isAssignee
      page: $page
      pageSize: $pageSize
      status: $status
      scope: $scope
      query: $query
      collectionId: $collectionId
      workspaceOnly: $workspaceOnly
      isOrphaned: $isOrphaned
      orderBy: $orderBy
    ) {
      page
      pageSize
      totalRecords
      totalPages
      data {
        id
        rank
        ...ScenarioFields
      }
    }
  }
  ${ScenarioFieldsFragmentDoc}
`;

/**
 * __useScenariosQueryQuery__
 *
 * To run a query within a React component, call `useScenariosQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenariosQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenariosQueryQuery({
 *   variables: {
 *      isOwner: // value for 'isOwner'
 *      isAssignee: // value for 'isAssignee'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      status: // value for 'status'
 *      scope: // value for 'scope'
 *      query: // value for 'query'
 *      collectionId: // value for 'collectionId'
 *      workspaceOnly: // value for 'workspaceOnly'
 *      isOrphaned: // value for 'isOrphaned'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useScenariosQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ScenariosQueryQuery,
    ScenariosQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScenariosQueryQuery, ScenariosQueryQueryVariables>(
    ScenariosQueryDocument,
    options,
  );
}
export function useScenariosQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScenariosQueryQuery,
    ScenariosQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScenariosQueryQuery, ScenariosQueryQueryVariables>(
    ScenariosQueryDocument,
    options,
  );
}
export function useScenariosQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ScenariosQueryQuery,
        ScenariosQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ScenariosQueryQuery,
    ScenariosQueryQueryVariables
  >(ScenariosQueryDocument, options);
}
export type ScenariosQueryQueryHookResult = ReturnType<
  typeof useScenariosQueryQuery
>;
export type ScenariosQueryLazyQueryHookResult = ReturnType<
  typeof useScenariosQueryLazyQuery
>;
export type ScenariosQuerySuspenseQueryHookResult = ReturnType<
  typeof useScenariosQuerySuspenseQuery
>;
export type ScenariosQueryQueryResult = Apollo.QueryResult<
  ScenariosQueryQuery,
  ScenariosQueryQueryVariables
>;
export const SwitchPersonalityModeDocument = gql`
  mutation SwitchPersonalityMode($slug: String!) {
    switchPersonalityMode(slug: $slug) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type SwitchPersonalityModeMutationFn = Apollo.MutationFunction<
  SwitchPersonalityModeMutation,
  SwitchPersonalityModeMutationVariables
>;

/**
 * __useSwitchPersonalityModeMutation__
 *
 * To run a mutation, you first call `useSwitchPersonalityModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchPersonalityModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchPersonalityModeMutation, { data, loading, error }] = useSwitchPersonalityModeMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSwitchPersonalityModeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchPersonalityModeMutation,
    SwitchPersonalityModeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SwitchPersonalityModeMutation,
    SwitchPersonalityModeMutationVariables
  >(SwitchPersonalityModeDocument, options);
}
export type SwitchPersonalityModeMutationHookResult = ReturnType<
  typeof useSwitchPersonalityModeMutation
>;
export type SwitchPersonalityModeMutationResult =
  Apollo.MutationResult<SwitchPersonalityModeMutation>;
export type SwitchPersonalityModeMutationOptions = Apollo.BaseMutationOptions<
  SwitchPersonalityModeMutation,
  SwitchPersonalityModeMutationVariables
>;
export const UpdateCollectionVisibilityScopeDocument = gql`
  mutation UpdateCollectionVisibilityScope(
    $collectionId: ID!
    $visibilityScope: VisibilityScope!
  ) {
    updateCollectionVisibilityScope(
      collectionId: $collectionId
      visibilityScope: $visibilityScope
    ) {
      collection {
        id
        visibilityScope
      }
      ok
      msg
    }
  }
`;
export type UpdateCollectionVisibilityScopeMutationFn = Apollo.MutationFunction<
  UpdateCollectionVisibilityScopeMutation,
  UpdateCollectionVisibilityScopeMutationVariables
>;

/**
 * __useUpdateCollectionVisibilityScopeMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionVisibilityScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionVisibilityScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionVisibilityScopeMutation, { data, loading, error }] = useUpdateCollectionVisibilityScopeMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      visibilityScope: // value for 'visibilityScope'
 *   },
 * });
 */
export function useUpdateCollectionVisibilityScopeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionVisibilityScopeMutation,
    UpdateCollectionVisibilityScopeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCollectionVisibilityScopeMutation,
    UpdateCollectionVisibilityScopeMutationVariables
  >(UpdateCollectionVisibilityScopeDocument, options);
}
export type UpdateCollectionVisibilityScopeMutationHookResult = ReturnType<
  typeof useUpdateCollectionVisibilityScopeMutation
>;
export type UpdateCollectionVisibilityScopeMutationResult =
  Apollo.MutationResult<UpdateCollectionVisibilityScopeMutation>;
export type UpdateCollectionVisibilityScopeMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCollectionVisibilityScopeMutation,
    UpdateCollectionVisibilityScopeMutationVariables
  >;
export const UpdateScenarioCharacterDocument = gql`
  mutation UpdateScenarioCharacter(
    $slug: String!
    $personaName: String
    $voiceId: ID
    $personalityCharacteristics: String
    $personalityTraits: [PersonalityTraitInput]
  ) {
    updateScenarioCharacter(
      slug: $slug
      personaName: $personaName
      voiceId: $voiceId
      personalityCharacteristics: $personalityCharacteristics
      personalityTraits: $personalityTraits
    ) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type UpdateScenarioCharacterMutationFn = Apollo.MutationFunction<
  UpdateScenarioCharacterMutation,
  UpdateScenarioCharacterMutationVariables
>;

/**
 * __useUpdateScenarioCharacterMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioCharacterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioCharacterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioCharacterMutation, { data, loading, error }] = useUpdateScenarioCharacterMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      personaName: // value for 'personaName'
 *      voiceId: // value for 'voiceId'
 *      personalityCharacteristics: // value for 'personalityCharacteristics'
 *      personalityTraits: // value for 'personalityTraits'
 *   },
 * });
 */
export function useUpdateScenarioCharacterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScenarioCharacterMutation,
    UpdateScenarioCharacterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScenarioCharacterMutation,
    UpdateScenarioCharacterMutationVariables
  >(UpdateScenarioCharacterDocument, options);
}
export type UpdateScenarioCharacterMutationHookResult = ReturnType<
  typeof useUpdateScenarioCharacterMutation
>;
export type UpdateScenarioCharacterMutationResult =
  Apollo.MutationResult<UpdateScenarioCharacterMutation>;
export type UpdateScenarioCharacterMutationOptions = Apollo.BaseMutationOptions<
  UpdateScenarioCharacterMutation,
  UpdateScenarioCharacterMutationVariables
>;
export const RoleplayDocument = gql`
  query Roleplay($slug: String, $roleplayId: ID) {
    roleplay(slug: $slug, roleplayId: $roleplayId) {
      ...RoleplayFields
      evaluationCriteria {
        ...EvaluationCriterionFields
      }
      scenarios {
        ...ScenarioListItem
      }
    }
  }
  ${RoleplayFieldsFragmentDoc}
  ${EvaluationCriterionFieldsFragmentDoc}
  ${ScenarioListItemFragmentDoc}
`;

/**
 * __useRoleplayQuery__
 *
 * To run a query within a React component, call `useRoleplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleplayQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      roleplayId: // value for 'roleplayId'
 *   },
 * });
 */
export function useRoleplayQuery(
  baseOptions?: Apollo.QueryHookOptions<RoleplayQuery, RoleplayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleplayQuery, RoleplayQueryVariables>(
    RoleplayDocument,
    options,
  );
}
export function useRoleplayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleplayQuery,
    RoleplayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleplayQuery, RoleplayQueryVariables>(
    RoleplayDocument,
    options,
  );
}
export function useRoleplaySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RoleplayQuery, RoleplayQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RoleplayQuery, RoleplayQueryVariables>(
    RoleplayDocument,
    options,
  );
}
export type RoleplayQueryHookResult = ReturnType<typeof useRoleplayQuery>;
export type RoleplayLazyQueryHookResult = ReturnType<
  typeof useRoleplayLazyQuery
>;
export type RoleplaySuspenseQueryHookResult = ReturnType<
  typeof useRoleplaySuspenseQuery
>;
export type RoleplayQueryResult = Apollo.QueryResult<
  RoleplayQuery,
  RoleplayQueryVariables
>;
export const CopyEvaluationCriteriaToCollectionDocument = gql`
  mutation CopyEvaluationCriteriaToCollection(
    $evaluationCriterionIds: [ID!]!
    $collectionId: ID!
  ) {
    copyEvaluationCriteriaToCollection(
      evaluationCriterionIds: $evaluationCriterionIds
      collectionId: $collectionId
    ) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type CopyEvaluationCriteriaToCollectionMutationFn =
  Apollo.MutationFunction<
    CopyEvaluationCriteriaToCollectionMutation,
    CopyEvaluationCriteriaToCollectionMutationVariables
  >;

/**
 * __useCopyEvaluationCriteriaToCollectionMutation__
 *
 * To run a mutation, you first call `useCopyEvaluationCriteriaToCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyEvaluationCriteriaToCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyEvaluationCriteriaToCollectionMutation, { data, loading, error }] = useCopyEvaluationCriteriaToCollectionMutation({
 *   variables: {
 *      evaluationCriterionIds: // value for 'evaluationCriterionIds'
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useCopyEvaluationCriteriaToCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyEvaluationCriteriaToCollectionMutation,
    CopyEvaluationCriteriaToCollectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CopyEvaluationCriteriaToCollectionMutation,
    CopyEvaluationCriteriaToCollectionMutationVariables
  >(CopyEvaluationCriteriaToCollectionDocument, options);
}
export type CopyEvaluationCriteriaToCollectionMutationHookResult = ReturnType<
  typeof useCopyEvaluationCriteriaToCollectionMutation
>;
export type CopyEvaluationCriteriaToCollectionMutationResult =
  Apollo.MutationResult<CopyEvaluationCriteriaToCollectionMutation>;
export type CopyEvaluationCriteriaToCollectionMutationOptions =
  Apollo.BaseMutationOptions<
    CopyEvaluationCriteriaToCollectionMutation,
    CopyEvaluationCriteriaToCollectionMutationVariables
  >;
export const CopyEvaluationCriteriaToScenarioDocument = gql`
  mutation CopyEvaluationCriteriaToScenario(
    $evaluationCriterionIds: [ID!]!
    $scenarioId: ID!
  ) {
    copyEvaluationCriteriaToScenario(
      evaluationCriterionIds: $evaluationCriterionIds
      scenarioId: $scenarioId
    ) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type CopyEvaluationCriteriaToScenarioMutationFn =
  Apollo.MutationFunction<
    CopyEvaluationCriteriaToScenarioMutation,
    CopyEvaluationCriteriaToScenarioMutationVariables
  >;

/**
 * __useCopyEvaluationCriteriaToScenarioMutation__
 *
 * To run a mutation, you first call `useCopyEvaluationCriteriaToScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyEvaluationCriteriaToScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyEvaluationCriteriaToScenarioMutation, { data, loading, error }] = useCopyEvaluationCriteriaToScenarioMutation({
 *   variables: {
 *      evaluationCriterionIds: // value for 'evaluationCriterionIds'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useCopyEvaluationCriteriaToScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyEvaluationCriteriaToScenarioMutation,
    CopyEvaluationCriteriaToScenarioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CopyEvaluationCriteriaToScenarioMutation,
    CopyEvaluationCriteriaToScenarioMutationVariables
  >(CopyEvaluationCriteriaToScenarioDocument, options);
}
export type CopyEvaluationCriteriaToScenarioMutationHookResult = ReturnType<
  typeof useCopyEvaluationCriteriaToScenarioMutation
>;
export type CopyEvaluationCriteriaToScenarioMutationResult =
  Apollo.MutationResult<CopyEvaluationCriteriaToScenarioMutation>;
export type CopyEvaluationCriteriaToScenarioMutationOptions =
  Apollo.BaseMutationOptions<
    CopyEvaluationCriteriaToScenarioMutation,
    CopyEvaluationCriteriaToScenarioMutationVariables
  >;
export const CreateEvaluationCriterionDocument = gql`
  mutation CreateEvaluationCriterion(
    $name: String!
    $roleplayId: ID
    $scenarioId: ID
    $evaluationCriterionItems: [EvaluationCriterionItemInput]!
  ) {
    createEvaluationCriterion(
      name: $name
      roleplayId: $roleplayId
      scenarioId: $scenarioId
      evaluationCriterionItems: $evaluationCriterionItems
    ) {
      ok
      errors {
        field
        messages
      }
      evaluationCriterion {
        id
        name
      }
    }
  }
`;
export type CreateEvaluationCriterionMutationFn = Apollo.MutationFunction<
  CreateEvaluationCriterionMutation,
  CreateEvaluationCriterionMutationVariables
>;

/**
 * __useCreateEvaluationCriterionMutation__
 *
 * To run a mutation, you first call `useCreateEvaluationCriterionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvaluationCriterionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvaluationCriterionMutation, { data, loading, error }] = useCreateEvaluationCriterionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      roleplayId: // value for 'roleplayId'
 *      scenarioId: // value for 'scenarioId'
 *      evaluationCriterionItems: // value for 'evaluationCriterionItems'
 *   },
 * });
 */
export function useCreateEvaluationCriterionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEvaluationCriterionMutation,
    CreateEvaluationCriterionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEvaluationCriterionMutation,
    CreateEvaluationCriterionMutationVariables
  >(CreateEvaluationCriterionDocument, options);
}
export type CreateEvaluationCriterionMutationHookResult = ReturnType<
  typeof useCreateEvaluationCriterionMutation
>;
export type CreateEvaluationCriterionMutationResult =
  Apollo.MutationResult<CreateEvaluationCriterionMutation>;
export type CreateEvaluationCriterionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateEvaluationCriterionMutation,
    CreateEvaluationCriterionMutationVariables
  >;
export const DeleteEvaluationCriterionDocument = gql`
  mutation DeleteEvaluationCriterion($id: ID!) {
    deleteEvaluationCriterion(id: $id) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type DeleteEvaluationCriterionMutationFn = Apollo.MutationFunction<
  DeleteEvaluationCriterionMutation,
  DeleteEvaluationCriterionMutationVariables
>;

/**
 * __useDeleteEvaluationCriterionMutation__
 *
 * To run a mutation, you first call `useDeleteEvaluationCriterionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEvaluationCriterionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEvaluationCriterionMutation, { data, loading, error }] = useDeleteEvaluationCriterionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEvaluationCriterionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEvaluationCriterionMutation,
    DeleteEvaluationCriterionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEvaluationCriterionMutation,
    DeleteEvaluationCriterionMutationVariables
  >(DeleteEvaluationCriterionDocument, options);
}
export type DeleteEvaluationCriterionMutationHookResult = ReturnType<
  typeof useDeleteEvaluationCriterionMutation
>;
export type DeleteEvaluationCriterionMutationResult =
  Apollo.MutationResult<DeleteEvaluationCriterionMutation>;
export type DeleteEvaluationCriterionMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteEvaluationCriterionMutation,
    DeleteEvaluationCriterionMutationVariables
  >;
export const DismissScenarioCriteriaBannerDocument = gql`
  mutation DismissScenarioCriteriaBanner($scenarioId: ID!) {
    dismissScenarioCriteriaBanner(scenarioId: $scenarioId) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type DismissScenarioCriteriaBannerMutationFn = Apollo.MutationFunction<
  DismissScenarioCriteriaBannerMutation,
  DismissScenarioCriteriaBannerMutationVariables
>;

/**
 * __useDismissScenarioCriteriaBannerMutation__
 *
 * To run a mutation, you first call `useDismissScenarioCriteriaBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissScenarioCriteriaBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissScenarioCriteriaBannerMutation, { data, loading, error }] = useDismissScenarioCriteriaBannerMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useDismissScenarioCriteriaBannerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissScenarioCriteriaBannerMutation,
    DismissScenarioCriteriaBannerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DismissScenarioCriteriaBannerMutation,
    DismissScenarioCriteriaBannerMutationVariables
  >(DismissScenarioCriteriaBannerDocument, options);
}
export type DismissScenarioCriteriaBannerMutationHookResult = ReturnType<
  typeof useDismissScenarioCriteriaBannerMutation
>;
export type DismissScenarioCriteriaBannerMutationResult =
  Apollo.MutationResult<DismissScenarioCriteriaBannerMutation>;
export type DismissScenarioCriteriaBannerMutationOptions =
  Apollo.BaseMutationOptions<
    DismissScenarioCriteriaBannerMutation,
    DismissScenarioCriteriaBannerMutationVariables
  >;
export const GetEvaluationCriteriaDocument = gql`
  query GetEvaluationCriteria(
    $roleplayId: ID
    $roleplaySlug: String
    $scenarioId: ID
  ) {
    evaluationCriteria(
      roleplayId: $roleplayId
      roleplaySlug: $roleplaySlug
      scenarioId: $scenarioId
    ) {
      id
      name
      order
      items {
        id
        name
        order
        description
      }
    }
  }
`;

/**
 * __useGetEvaluationCriteriaQuery__
 *
 * To run a query within a React component, call `useGetEvaluationCriteriaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvaluationCriteriaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvaluationCriteriaQuery({
 *   variables: {
 *      roleplayId: // value for 'roleplayId'
 *      roleplaySlug: // value for 'roleplaySlug'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useGetEvaluationCriteriaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEvaluationCriteriaQuery,
    GetEvaluationCriteriaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEvaluationCriteriaQuery,
    GetEvaluationCriteriaQueryVariables
  >(GetEvaluationCriteriaDocument, options);
}
export function useGetEvaluationCriteriaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEvaluationCriteriaQuery,
    GetEvaluationCriteriaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEvaluationCriteriaQuery,
    GetEvaluationCriteriaQueryVariables
  >(GetEvaluationCriteriaDocument, options);
}
export function useGetEvaluationCriteriaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetEvaluationCriteriaQuery,
        GetEvaluationCriteriaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEvaluationCriteriaQuery,
    GetEvaluationCriteriaQueryVariables
  >(GetEvaluationCriteriaDocument, options);
}
export type GetEvaluationCriteriaQueryHookResult = ReturnType<
  typeof useGetEvaluationCriteriaQuery
>;
export type GetEvaluationCriteriaLazyQueryHookResult = ReturnType<
  typeof useGetEvaluationCriteriaLazyQuery
>;
export type GetEvaluationCriteriaSuspenseQueryHookResult = ReturnType<
  typeof useGetEvaluationCriteriaSuspenseQuery
>;
export type GetEvaluationCriteriaQueryResult = Apollo.QueryResult<
  GetEvaluationCriteriaQuery,
  GetEvaluationCriteriaQueryVariables
>;
export const RemoveAllEvaluationCriteriaFromTargetDocument = gql`
  mutation RemoveAllEvaluationCriteriaFromTarget(
    $targetId: ID!
    $targetType: TargetType!
  ) {
    removeAllEvaluationCriteriaFromTarget(
      targetId: $targetId
      targetType: $targetType
    ) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type RemoveAllEvaluationCriteriaFromTargetMutationFn =
  Apollo.MutationFunction<
    RemoveAllEvaluationCriteriaFromTargetMutation,
    RemoveAllEvaluationCriteriaFromTargetMutationVariables
  >;

/**
 * __useRemoveAllEvaluationCriteriaFromTargetMutation__
 *
 * To run a mutation, you first call `useRemoveAllEvaluationCriteriaFromTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllEvaluationCriteriaFromTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllEvaluationCriteriaFromTargetMutation, { data, loading, error }] = useRemoveAllEvaluationCriteriaFromTargetMutation({
 *   variables: {
 *      targetId: // value for 'targetId'
 *      targetType: // value for 'targetType'
 *   },
 * });
 */
export function useRemoveAllEvaluationCriteriaFromTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAllEvaluationCriteriaFromTargetMutation,
    RemoveAllEvaluationCriteriaFromTargetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAllEvaluationCriteriaFromTargetMutation,
    RemoveAllEvaluationCriteriaFromTargetMutationVariables
  >(RemoveAllEvaluationCriteriaFromTargetDocument, options);
}
export type RemoveAllEvaluationCriteriaFromTargetMutationHookResult =
  ReturnType<typeof useRemoveAllEvaluationCriteriaFromTargetMutation>;
export type RemoveAllEvaluationCriteriaFromTargetMutationResult =
  Apollo.MutationResult<RemoveAllEvaluationCriteriaFromTargetMutation>;
export type RemoveAllEvaluationCriteriaFromTargetMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveAllEvaluationCriteriaFromTargetMutation,
    RemoveAllEvaluationCriteriaFromTargetMutationVariables
  >;
export const ReorderEvaluationCriteriaDocument = gql`
  mutation ReorderEvaluationCriteria(
    $items: [ReorderItemInput!]!
    $roleplayId: ID
    $scenarioId: ID
  ) {
    reorderEvaluationCriteria(
      items: $items
      roleplayId: $roleplayId
      scenarioId: $scenarioId
    ) {
      ok
      errors {
        field
        messages
      }
      evaluationCriteria {
        id
        name
        order
      }
    }
  }
`;
export type ReorderEvaluationCriteriaMutationFn = Apollo.MutationFunction<
  ReorderEvaluationCriteriaMutation,
  ReorderEvaluationCriteriaMutationVariables
>;

/**
 * __useReorderEvaluationCriteriaMutation__
 *
 * To run a mutation, you first call `useReorderEvaluationCriteriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderEvaluationCriteriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderEvaluationCriteriaMutation, { data, loading, error }] = useReorderEvaluationCriteriaMutation({
 *   variables: {
 *      items: // value for 'items'
 *      roleplayId: // value for 'roleplayId'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useReorderEvaluationCriteriaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderEvaluationCriteriaMutation,
    ReorderEvaluationCriteriaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderEvaluationCriteriaMutation,
    ReorderEvaluationCriteriaMutationVariables
  >(ReorderEvaluationCriteriaDocument, options);
}
export type ReorderEvaluationCriteriaMutationHookResult = ReturnType<
  typeof useReorderEvaluationCriteriaMutation
>;
export type ReorderEvaluationCriteriaMutationResult =
  Apollo.MutationResult<ReorderEvaluationCriteriaMutation>;
export type ReorderEvaluationCriteriaMutationOptions =
  Apollo.BaseMutationOptions<
    ReorderEvaluationCriteriaMutation,
    ReorderEvaluationCriteriaMutationVariables
  >;
export const ReorderEvaluationCriterionItemsDocument = gql`
  mutation ReorderEvaluationCriterionItems(
    $items: [ReorderItemInput!]!
    $roleplayId: ID
    $scenarioId: ID
  ) {
    reorderEvaluationCriterionItems(
      items: $items
      roleplayId: $roleplayId
      scenarioId: $scenarioId
    ) {
      ok
      errors {
        field
        messages
      }
      evaluationCriterionItems {
        id
        name
        order
      }
    }
  }
`;
export type ReorderEvaluationCriterionItemsMutationFn = Apollo.MutationFunction<
  ReorderEvaluationCriterionItemsMutation,
  ReorderEvaluationCriterionItemsMutationVariables
>;

/**
 * __useReorderEvaluationCriterionItemsMutation__
 *
 * To run a mutation, you first call `useReorderEvaluationCriterionItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderEvaluationCriterionItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderEvaluationCriterionItemsMutation, { data, loading, error }] = useReorderEvaluationCriterionItemsMutation({
 *   variables: {
 *      items: // value for 'items'
 *      roleplayId: // value for 'roleplayId'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useReorderEvaluationCriterionItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderEvaluationCriterionItemsMutation,
    ReorderEvaluationCriterionItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderEvaluationCriterionItemsMutation,
    ReorderEvaluationCriterionItemsMutationVariables
  >(ReorderEvaluationCriterionItemsDocument, options);
}
export type ReorderEvaluationCriterionItemsMutationHookResult = ReturnType<
  typeof useReorderEvaluationCriterionItemsMutation
>;
export type ReorderEvaluationCriterionItemsMutationResult =
  Apollo.MutationResult<ReorderEvaluationCriterionItemsMutation>;
export type ReorderEvaluationCriterionItemsMutationOptions =
  Apollo.BaseMutationOptions<
    ReorderEvaluationCriterionItemsMutation,
    ReorderEvaluationCriterionItemsMutationVariables
  >;
export const UpdateEvaluationCriterionDocument = gql`
  mutation UpdateEvaluationCriterion(
    $id: ID!
    $name: String
    $order: Int
    $roleplayId: ID
    $scenarioId: ID
    $evaluationCriterionItems: [EvaluationCriterionItemInput]!
  ) {
    updateEvaluationCriterion(
      id: $id
      name: $name
      order: $order
      roleplayId: $roleplayId
      scenarioId: $scenarioId
      evaluationCriterionItems: $evaluationCriterionItems
    ) {
      ok
      errors {
        field
        messages
      }
      evaluationCriterion {
        id
        name
      }
    }
  }
`;
export type UpdateEvaluationCriterionMutationFn = Apollo.MutationFunction<
  UpdateEvaluationCriterionMutation,
  UpdateEvaluationCriterionMutationVariables
>;

/**
 * __useUpdateEvaluationCriterionMutation__
 *
 * To run a mutation, you first call `useUpdateEvaluationCriterionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvaluationCriterionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvaluationCriterionMutation, { data, loading, error }] = useUpdateEvaluationCriterionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      order: // value for 'order'
 *      roleplayId: // value for 'roleplayId'
 *      scenarioId: // value for 'scenarioId'
 *      evaluationCriterionItems: // value for 'evaluationCriterionItems'
 *   },
 * });
 */
export function useUpdateEvaluationCriterionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEvaluationCriterionMutation,
    UpdateEvaluationCriterionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEvaluationCriterionMutation,
    UpdateEvaluationCriterionMutationVariables
  >(UpdateEvaluationCriterionDocument, options);
}
export type UpdateEvaluationCriterionMutationHookResult = ReturnType<
  typeof useUpdateEvaluationCriterionMutation
>;
export type UpdateEvaluationCriterionMutationResult =
  Apollo.MutationResult<UpdateEvaluationCriterionMutation>;
export type UpdateEvaluationCriterionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateEvaluationCriterionMutation,
    UpdateEvaluationCriterionMutationVariables
  >;
export const CreateScenarioIntakeDocument = gql`
  mutation CreateScenarioIntake($roleplayId: ID, $callId: String!) {
    createScenarioIntake(roleplayId: $roleplayId, callId: $callId) {
      scenarioIntake {
        id
        uuid
        processingStatus
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type CreateScenarioIntakeMutationFn = Apollo.MutationFunction<
  CreateScenarioIntakeMutation,
  CreateScenarioIntakeMutationVariables
>;

/**
 * __useCreateScenarioIntakeMutation__
 *
 * To run a mutation, you first call `useCreateScenarioIntakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioIntakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioIntakeMutation, { data, loading, error }] = useCreateScenarioIntakeMutation({
 *   variables: {
 *      roleplayId: // value for 'roleplayId'
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCreateScenarioIntakeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScenarioIntakeMutation,
    CreateScenarioIntakeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScenarioIntakeMutation,
    CreateScenarioIntakeMutationVariables
  >(CreateScenarioIntakeDocument, options);
}
export type CreateScenarioIntakeMutationHookResult = ReturnType<
  typeof useCreateScenarioIntakeMutation
>;
export type CreateScenarioIntakeMutationResult =
  Apollo.MutationResult<CreateScenarioIntakeMutation>;
export type CreateScenarioIntakeMutationOptions = Apollo.BaseMutationOptions<
  CreateScenarioIntakeMutation,
  CreateScenarioIntakeMutationVariables
>;
export const QuickCreateScenarioIntakeDocument = gql`
  mutation QuickCreateScenarioIntake(
    $scenarioPrompt: String!
    $roleplayId: ID
  ) {
    quickCreateScenarioIntake(
      scenarioPrompt: $scenarioPrompt
      roleplayId: $roleplayId
    ) {
      scenarioIntake {
        id
        uuid
        processingStatus
      }
      errors {
        field
        message
      }
    }
  }
`;
export type QuickCreateScenarioIntakeMutationFn = Apollo.MutationFunction<
  QuickCreateScenarioIntakeMutation,
  QuickCreateScenarioIntakeMutationVariables
>;

/**
 * __useQuickCreateScenarioIntakeMutation__
 *
 * To run a mutation, you first call `useQuickCreateScenarioIntakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuickCreateScenarioIntakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quickCreateScenarioIntakeMutation, { data, loading, error }] = useQuickCreateScenarioIntakeMutation({
 *   variables: {
 *      scenarioPrompt: // value for 'scenarioPrompt'
 *      roleplayId: // value for 'roleplayId'
 *   },
 * });
 */
export function useQuickCreateScenarioIntakeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuickCreateScenarioIntakeMutation,
    QuickCreateScenarioIntakeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuickCreateScenarioIntakeMutation,
    QuickCreateScenarioIntakeMutationVariables
  >(QuickCreateScenarioIntakeDocument, options);
}
export type QuickCreateScenarioIntakeMutationHookResult = ReturnType<
  typeof useQuickCreateScenarioIntakeMutation
>;
export type QuickCreateScenarioIntakeMutationResult =
  Apollo.MutationResult<QuickCreateScenarioIntakeMutation>;
export type QuickCreateScenarioIntakeMutationOptions =
  Apollo.BaseMutationOptions<
    QuickCreateScenarioIntakeMutation,
    QuickCreateScenarioIntakeMutationVariables
  >;
export const SaveScenarioNotesDocument = gql`
  mutation SaveScenarioNotes($scenarioId: ID!, $content: String!) {
    saveScenarioNotes(scenarioId: $scenarioId, content: $content) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type SaveScenarioNotesMutationFn = Apollo.MutationFunction<
  SaveScenarioNotesMutation,
  SaveScenarioNotesMutationVariables
>;

/**
 * __useSaveScenarioNotesMutation__
 *
 * To run a mutation, you first call `useSaveScenarioNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveScenarioNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveScenarioNotesMutation, { data, loading, error }] = useSaveScenarioNotesMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSaveScenarioNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveScenarioNotesMutation,
    SaveScenarioNotesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveScenarioNotesMutation,
    SaveScenarioNotesMutationVariables
  >(SaveScenarioNotesDocument, options);
}
export type SaveScenarioNotesMutationHookResult = ReturnType<
  typeof useSaveScenarioNotesMutation
>;
export type SaveScenarioNotesMutationResult =
  Apollo.MutationResult<SaveScenarioNotesMutation>;
export type SaveScenarioNotesMutationOptions = Apollo.BaseMutationOptions<
  SaveScenarioNotesMutation,
  SaveScenarioNotesMutationVariables
>;
export const ScenarioNotesDocument = gql`
  query ScenarioNotes($scenarioId: ID!) {
    scenarioNotes(scenarioId: $scenarioId) {
      id
      content
    }
  }
`;

/**
 * __useScenarioNotesQuery__
 *
 * To run a query within a React component, call `useScenarioNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenarioNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenarioNotesQuery({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useScenarioNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScenarioNotesQuery,
    ScenarioNotesQueryVariables
  > &
    (
      | { variables: ScenarioNotesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScenarioNotesQuery, ScenarioNotesQueryVariables>(
    ScenarioNotesDocument,
    options,
  );
}
export function useScenarioNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScenarioNotesQuery,
    ScenarioNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScenarioNotesQuery, ScenarioNotesQueryVariables>(
    ScenarioNotesDocument,
    options,
  );
}
export function useScenarioNotesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ScenarioNotesQuery,
        ScenarioNotesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ScenarioNotesQuery,
    ScenarioNotesQueryVariables
  >(ScenarioNotesDocument, options);
}
export type ScenarioNotesQueryHookResult = ReturnType<
  typeof useScenarioNotesQuery
>;
export type ScenarioNotesLazyQueryHookResult = ReturnType<
  typeof useScenarioNotesLazyQuery
>;
export type ScenarioNotesSuspenseQueryHookResult = ReturnType<
  typeof useScenarioNotesSuspenseQuery
>;
export type ScenarioNotesQueryResult = Apollo.QueryResult<
  ScenarioNotesQuery,
  ScenarioNotesQueryVariables
>;
export const ScenarioDocument = gql`
  query Scenario($scenarioSlug: String!) {
    scenario(scenarioSlug: $scenarioSlug) {
      ...ScenarioFields
      evaluationCriteria {
        ...EvaluationCriterionFields
      }
      roleplaySessions {
        ...RoleplaySessionListItem
      }
      ...ScenarioCollections
    }
    featureIntroduced(featureType: "HOW_TO_ROLEPLAY")
  }
  ${ScenarioFieldsFragmentDoc}
  ${EvaluationCriterionFieldsFragmentDoc}
  ${RoleplaySessionListItemFragmentDoc}
  ${ScenarioCollectionsFragmentDoc}
`;

/**
 * __useScenarioQuery__
 *
 * To run a query within a React component, call `useScenarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenarioQuery({
 *   variables: {
 *      scenarioSlug: // value for 'scenarioSlug'
 *   },
 * });
 */
export function useScenarioQuery(
  baseOptions: Apollo.QueryHookOptions<ScenarioQuery, ScenarioQueryVariables> &
    ({ variables: ScenarioQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScenarioQuery, ScenarioQueryVariables>(
    ScenarioDocument,
    options,
  );
}
export function useScenarioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScenarioQuery,
    ScenarioQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScenarioQuery, ScenarioQueryVariables>(
    ScenarioDocument,
    options,
  );
}
export function useScenarioSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ScenarioQuery, ScenarioQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ScenarioQuery, ScenarioQueryVariables>(
    ScenarioDocument,
    options,
  );
}
export type ScenarioQueryHookResult = ReturnType<typeof useScenarioQuery>;
export type ScenarioLazyQueryHookResult = ReturnType<
  typeof useScenarioLazyQuery
>;
export type ScenarioSuspenseQueryHookResult = ReturnType<
  typeof useScenarioSuspenseQuery
>;
export type ScenarioQueryResult = Apollo.QueryResult<
  ScenarioQuery,
  ScenarioQueryVariables
>;
export const UpdateScenarioVisibilityScopeDocument = gql`
  mutation UpdateScenarioVisibilityScope(
    $scenarioId: ID!
    $visibilityScope: VisibilityScope!
  ) {
    updateScenarioVisibilityScope(
      scenarioId: $scenarioId
      visibilityScope: $visibilityScope
    ) {
      scenario {
        id
        visibilityScope
      }
      ok
      msg
    }
  }
`;
export type UpdateScenarioVisibilityScopeMutationFn = Apollo.MutationFunction<
  UpdateScenarioVisibilityScopeMutation,
  UpdateScenarioVisibilityScopeMutationVariables
>;

/**
 * __useUpdateScenarioVisibilityScopeMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioVisibilityScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioVisibilityScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioVisibilityScopeMutation, { data, loading, error }] = useUpdateScenarioVisibilityScopeMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      visibilityScope: // value for 'visibilityScope'
 *   },
 * });
 */
export function useUpdateScenarioVisibilityScopeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScenarioVisibilityScopeMutation,
    UpdateScenarioVisibilityScopeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScenarioVisibilityScopeMutation,
    UpdateScenarioVisibilityScopeMutationVariables
  >(UpdateScenarioVisibilityScopeDocument, options);
}
export type UpdateScenarioVisibilityScopeMutationHookResult = ReturnType<
  typeof useUpdateScenarioVisibilityScopeMutation
>;
export type UpdateScenarioVisibilityScopeMutationResult =
  Apollo.MutationResult<UpdateScenarioVisibilityScopeMutation>;
export type UpdateScenarioVisibilityScopeMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateScenarioVisibilityScopeMutation,
    UpdateScenarioVisibilityScopeMutationVariables
  >;
export const CreateRoleplaySessionDocument = gql`
  mutation CreateRoleplaySession(
    $scenarioId: ID!
    $roleplaySlug: String
    $callId: String!
  ) {
    createRoleplaySession(
      scenarioId: $scenarioId
      roleplaySlug: $roleplaySlug
      callId: $callId
    ) {
      roleplaySession {
        id
        uuid
        processingStatus
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type CreateRoleplaySessionMutationFn = Apollo.MutationFunction<
  CreateRoleplaySessionMutation,
  CreateRoleplaySessionMutationVariables
>;

/**
 * __useCreateRoleplaySessionMutation__
 *
 * To run a mutation, you first call `useCreateRoleplaySessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleplaySessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleplaySessionMutation, { data, loading, error }] = useCreateRoleplaySessionMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      roleplaySlug: // value for 'roleplaySlug'
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useCreateRoleplaySessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleplaySessionMutation,
    CreateRoleplaySessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRoleplaySessionMutation,
    CreateRoleplaySessionMutationVariables
  >(CreateRoleplaySessionDocument, options);
}
export type CreateRoleplaySessionMutationHookResult = ReturnType<
  typeof useCreateRoleplaySessionMutation
>;
export type CreateRoleplaySessionMutationResult =
  Apollo.MutationResult<CreateRoleplaySessionMutation>;
export type CreateRoleplaySessionMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleplaySessionMutation,
  CreateRoleplaySessionMutationVariables
>;
export const RoleplaySessionDocument = gql`
  query RoleplaySession($uuid: String!) {
    roleplaySession(uuid: $uuid) {
      ...RoleplaySessionFields
      programs {
        id
        name
      }
      user {
        id
        name
        email
        photo
      }
      scenario {
        ...ScenarioFields
        roleplaySessions {
          ...RoleplaySessionListItem
        }
      }
      formFields {
        id
        prompt
        answerType
        helpText
        required
        formFieldOptions {
          id
          label
          value
        }
        filteredAnswers {
          id
          value
          optionValue {
            id
            isCorrect
            explanation
          }
        }
      }
      sharedMembers {
        id
        user {
          id
          name
          email
          photo
        }
      }
    }
  }
  ${RoleplaySessionFieldsFragmentDoc}
  ${ScenarioFieldsFragmentDoc}
  ${RoleplaySessionListItemFragmentDoc}
`;

/**
 * __useRoleplaySessionQuery__
 *
 * To run a query within a React component, call `useRoleplaySessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleplaySessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleplaySessionQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRoleplaySessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleplaySessionQuery,
    RoleplaySessionQueryVariables
  > &
    (
      | { variables: RoleplaySessionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleplaySessionQuery, RoleplaySessionQueryVariables>(
    RoleplaySessionDocument,
    options,
  );
}
export function useRoleplaySessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleplaySessionQuery,
    RoleplaySessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleplaySessionQuery,
    RoleplaySessionQueryVariables
  >(RoleplaySessionDocument, options);
}
export function useRoleplaySessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RoleplaySessionQuery,
        RoleplaySessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RoleplaySessionQuery,
    RoleplaySessionQueryVariables
  >(RoleplaySessionDocument, options);
}
export type RoleplaySessionQueryHookResult = ReturnType<
  typeof useRoleplaySessionQuery
>;
export type RoleplaySessionLazyQueryHookResult = ReturnType<
  typeof useRoleplaySessionLazyQuery
>;
export type RoleplaySessionSuspenseQueryHookResult = ReturnType<
  typeof useRoleplaySessionSuspenseQuery
>;
export type RoleplaySessionQueryResult = Apollo.QueryResult<
  RoleplaySessionQuery,
  RoleplaySessionQueryVariables
>;
export const RoleplaySessionStatusDocument = gql`
  query RoleplaySessionStatus($uuid: String!) {
    roleplaySession(uuid: $uuid) {
      uuid
      processingStatus
    }
  }
`;

/**
 * __useRoleplaySessionStatusQuery__
 *
 * To run a query within a React component, call `useRoleplaySessionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleplaySessionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleplaySessionStatusQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRoleplaySessionStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleplaySessionStatusQuery,
    RoleplaySessionStatusQueryVariables
  > &
    (
      | { variables: RoleplaySessionStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleplaySessionStatusQuery,
    RoleplaySessionStatusQueryVariables
  >(RoleplaySessionStatusDocument, options);
}
export function useRoleplaySessionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleplaySessionStatusQuery,
    RoleplaySessionStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleplaySessionStatusQuery,
    RoleplaySessionStatusQueryVariables
  >(RoleplaySessionStatusDocument, options);
}
export function useRoleplaySessionStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RoleplaySessionStatusQuery,
        RoleplaySessionStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RoleplaySessionStatusQuery,
    RoleplaySessionStatusQueryVariables
  >(RoleplaySessionStatusDocument, options);
}
export type RoleplaySessionStatusQueryHookResult = ReturnType<
  typeof useRoleplaySessionStatusQuery
>;
export type RoleplaySessionStatusLazyQueryHookResult = ReturnType<
  typeof useRoleplaySessionStatusLazyQuery
>;
export type RoleplaySessionStatusSuspenseQueryHookResult = ReturnType<
  typeof useRoleplaySessionStatusSuspenseQuery
>;
export type RoleplaySessionStatusQueryResult = Apollo.QueryResult<
  RoleplaySessionStatusQuery,
  RoleplaySessionStatusQueryVariables
>;
export const RoleplaySessionTranscriptDocument = gql`
  query RoleplaySessionTranscript($uuid: String!) {
    roleplaySession(uuid: $uuid) {
      id
      transcripts {
        id
        ...RoleplaySessionTranscriptFragment
      }
      recordingUrl
    }
  }
  ${RoleplaySessionTranscriptFragmentFragmentDoc}
  ${RoleplaySessionCommentFragmentFragmentDoc}
`;

/**
 * __useRoleplaySessionTranscriptQuery__
 *
 * To run a query within a React component, call `useRoleplaySessionTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleplaySessionTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleplaySessionTranscriptQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRoleplaySessionTranscriptQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleplaySessionTranscriptQuery,
    RoleplaySessionTranscriptQueryVariables
  > &
    (
      | { variables: RoleplaySessionTranscriptQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleplaySessionTranscriptQuery,
    RoleplaySessionTranscriptQueryVariables
  >(RoleplaySessionTranscriptDocument, options);
}
export function useRoleplaySessionTranscriptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleplaySessionTranscriptQuery,
    RoleplaySessionTranscriptQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleplaySessionTranscriptQuery,
    RoleplaySessionTranscriptQueryVariables
  >(RoleplaySessionTranscriptDocument, options);
}
export function useRoleplaySessionTranscriptSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RoleplaySessionTranscriptQuery,
        RoleplaySessionTranscriptQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RoleplaySessionTranscriptQuery,
    RoleplaySessionTranscriptQueryVariables
  >(RoleplaySessionTranscriptDocument, options);
}
export type RoleplaySessionTranscriptQueryHookResult = ReturnType<
  typeof useRoleplaySessionTranscriptQuery
>;
export type RoleplaySessionTranscriptLazyQueryHookResult = ReturnType<
  typeof useRoleplaySessionTranscriptLazyQuery
>;
export type RoleplaySessionTranscriptSuspenseQueryHookResult = ReturnType<
  typeof useRoleplaySessionTranscriptSuspenseQuery
>;
export type RoleplaySessionTranscriptQueryResult = Apollo.QueryResult<
  RoleplaySessionTranscriptQuery,
  RoleplaySessionTranscriptQueryVariables
>;
export const SaveQuizAnswerDocument = gql`
  mutation SaveQuizAnswer(
    $roleplaySessionId: ID!
    $formFieldId: ID!
    $optionValueId: ID!
  ) {
    saveQuizAnswer(
      roleplaySessionId: $roleplaySessionId
      formFieldId: $formFieldId
      optionValueId: $optionValueId
    ) {
      isCorrect
      explanation
      ok
      msg
    }
  }
`;
export type SaveQuizAnswerMutationFn = Apollo.MutationFunction<
  SaveQuizAnswerMutation,
  SaveQuizAnswerMutationVariables
>;

/**
 * __useSaveQuizAnswerMutation__
 *
 * To run a mutation, you first call `useSaveQuizAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveQuizAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveQuizAnswerMutation, { data, loading, error }] = useSaveQuizAnswerMutation({
 *   variables: {
 *      roleplaySessionId: // value for 'roleplaySessionId'
 *      formFieldId: // value for 'formFieldId'
 *      optionValueId: // value for 'optionValueId'
 *   },
 * });
 */
export function useSaveQuizAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveQuizAnswerMutation,
    SaveQuizAnswerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveQuizAnswerMutation,
    SaveQuizAnswerMutationVariables
  >(SaveQuizAnswerDocument, options);
}
export type SaveQuizAnswerMutationHookResult = ReturnType<
  typeof useSaveQuizAnswerMutation
>;
export type SaveQuizAnswerMutationResult =
  Apollo.MutationResult<SaveQuizAnswerMutation>;
export type SaveQuizAnswerMutationOptions = Apollo.BaseMutationOptions<
  SaveQuizAnswerMutation,
  SaveQuizAnswerMutationVariables
>;
export const ScenarioIntakeDocument = gql`
  query ScenarioIntake($uuid: String!) {
    scenarioIntake(uuid: $uuid) {
      id
      uuid
      processingStatus
      scenario {
        id
        slug
        assistantId
      }
      roleplay {
        id
        name
        slug
        emojiUnicode
      }
    }
  }
`;

/**
 * __useScenarioIntakeQuery__
 *
 * To run a query within a React component, call `useScenarioIntakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenarioIntakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenarioIntakeQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useScenarioIntakeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScenarioIntakeQuery,
    ScenarioIntakeQueryVariables
  > &
    (
      | { variables: ScenarioIntakeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScenarioIntakeQuery, ScenarioIntakeQueryVariables>(
    ScenarioIntakeDocument,
    options,
  );
}
export function useScenarioIntakeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScenarioIntakeQuery,
    ScenarioIntakeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScenarioIntakeQuery, ScenarioIntakeQueryVariables>(
    ScenarioIntakeDocument,
    options,
  );
}
export function useScenarioIntakeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ScenarioIntakeQuery,
        ScenarioIntakeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ScenarioIntakeQuery,
    ScenarioIntakeQueryVariables
  >(ScenarioIntakeDocument, options);
}
export type ScenarioIntakeQueryHookResult = ReturnType<
  typeof useScenarioIntakeQuery
>;
export type ScenarioIntakeLazyQueryHookResult = ReturnType<
  typeof useScenarioIntakeLazyQuery
>;
export type ScenarioIntakeSuspenseQueryHookResult = ReturnType<
  typeof useScenarioIntakeSuspenseQuery
>;
export type ScenarioIntakeQueryResult = Apollo.QueryResult<
  ScenarioIntakeQuery,
  ScenarioIntakeQueryVariables
>;
export const ScheduleDocument = gql`
  query Schedule {
    currentUser {
      id
      email
      name
      photo
      availableCredits
      hasIndividualAccount
      timeZone
      ledgerAccounts {
        id
        label
        balance
      }
      coaches {
        id
        name
        firstName
        lastName
        photo
        activeCoachProducts {
          id
          product {
            id
          }
        }
        timeZone
        agentProfile {
          id
          summary
          averageRating
          acceptingNewBookings
          canHaveIntroSession
        }
      }
    }
  }
`;

/**
 * __useScheduleQuery__
 *
 * To run a query within a React component, call `useScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleQuery({
 *   variables: {
 *   },
 * });
 */
export function useScheduleQuery(
  baseOptions?: Apollo.QueryHookOptions<ScheduleQuery, ScheduleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduleQuery, ScheduleQueryVariables>(
    ScheduleDocument,
    options,
  );
}
export function useScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleQuery,
    ScheduleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduleQuery, ScheduleQueryVariables>(
    ScheduleDocument,
    options,
  );
}
export function useScheduleSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ScheduleQuery, ScheduleQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ScheduleQuery, ScheduleQueryVariables>(
    ScheduleDocument,
    options,
  );
}
export type ScheduleQueryHookResult = ReturnType<typeof useScheduleQuery>;
export type ScheduleLazyQueryHookResult = ReturnType<
  typeof useScheduleLazyQuery
>;
export type ScheduleSuspenseQueryHookResult = ReturnType<
  typeof useScheduleSuspenseQuery
>;
export type ScheduleQueryResult = Apollo.QueryResult<
  ScheduleQuery,
  ScheduleQueryVariables
>;
export const AcceptBookingRequestDocument = gql`
  mutation AcceptBookingRequest($meetingId: ID!, $timeslot: DateTime!) {
    acceptBookingRequest(meetingId: $meetingId, timeslot: $timeslot) {
      ok
      meeting {
        id
      }
    }
  }
`;
export type AcceptBookingRequestMutationFn = Apollo.MutationFunction<
  AcceptBookingRequestMutation,
  AcceptBookingRequestMutationVariables
>;

/**
 * __useAcceptBookingRequestMutation__
 *
 * To run a mutation, you first call `useAcceptBookingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBookingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBookingRequestMutation, { data, loading, error }] = useAcceptBookingRequestMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      timeslot: // value for 'timeslot'
 *   },
 * });
 */
export function useAcceptBookingRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptBookingRequestMutation,
    AcceptBookingRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptBookingRequestMutation,
    AcceptBookingRequestMutationVariables
  >(AcceptBookingRequestDocument, options);
}
export type AcceptBookingRequestMutationHookResult = ReturnType<
  typeof useAcceptBookingRequestMutation
>;
export type AcceptBookingRequestMutationResult =
  Apollo.MutationResult<AcceptBookingRequestMutation>;
export type AcceptBookingRequestMutationOptions = Apollo.BaseMutationOptions<
  AcceptBookingRequestMutation,
  AcceptBookingRequestMutationVariables
>;
export const BookingRequestTimeslotsDocument = gql`
  query BookingRequestTimeslots($meetingId: ID!, $isConflictCheck: Boolean) {
    bookingRequestTimeslots(
      meetingId: $meetingId
      isConflictCheck: $isConflictCheck
    ) {
      timeslot
      preferred
      isConflict
    }
  }
`;

/**
 * __useBookingRequestTimeslotsQuery__
 *
 * To run a query within a React component, call `useBookingRequestTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestTimeslotsQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      isConflictCheck: // value for 'isConflictCheck'
 *   },
 * });
 */
export function useBookingRequestTimeslotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BookingRequestTimeslotsQuery,
    BookingRequestTimeslotsQueryVariables
  > &
    (
      | { variables: BookingRequestTimeslotsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BookingRequestTimeslotsQuery,
    BookingRequestTimeslotsQueryVariables
  >(BookingRequestTimeslotsDocument, options);
}
export function useBookingRequestTimeslotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookingRequestTimeslotsQuery,
    BookingRequestTimeslotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BookingRequestTimeslotsQuery,
    BookingRequestTimeslotsQueryVariables
  >(BookingRequestTimeslotsDocument, options);
}
export function useBookingRequestTimeslotsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BookingRequestTimeslotsQuery,
        BookingRequestTimeslotsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BookingRequestTimeslotsQuery,
    BookingRequestTimeslotsQueryVariables
  >(BookingRequestTimeslotsDocument, options);
}
export type BookingRequestTimeslotsQueryHookResult = ReturnType<
  typeof useBookingRequestTimeslotsQuery
>;
export type BookingRequestTimeslotsLazyQueryHookResult = ReturnType<
  typeof useBookingRequestTimeslotsLazyQuery
>;
export type BookingRequestTimeslotsSuspenseQueryHookResult = ReturnType<
  typeof useBookingRequestTimeslotsSuspenseQuery
>;
export type BookingRequestTimeslotsQueryResult = Apollo.QueryResult<
  BookingRequestTimeslotsQuery,
  BookingRequestTimeslotsQueryVariables
>;
export const CancelBookingRequestDocument = gql`
  mutation CancelBookingRequest($meetingId: ID!) {
    cancelBookingRequest(meetingId: $meetingId) {
      ok
      meeting {
        id
      }
    }
  }
`;
export type CancelBookingRequestMutationFn = Apollo.MutationFunction<
  CancelBookingRequestMutation,
  CancelBookingRequestMutationVariables
>;

/**
 * __useCancelBookingRequestMutation__
 *
 * To run a mutation, you first call `useCancelBookingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingRequestMutation, { data, loading, error }] = useCancelBookingRequestMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useCancelBookingRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelBookingRequestMutation,
    CancelBookingRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelBookingRequestMutation,
    CancelBookingRequestMutationVariables
  >(CancelBookingRequestDocument, options);
}
export type CancelBookingRequestMutationHookResult = ReturnType<
  typeof useCancelBookingRequestMutation
>;
export type CancelBookingRequestMutationResult =
  Apollo.MutationResult<CancelBookingRequestMutation>;
export type CancelBookingRequestMutationOptions = Apollo.BaseMutationOptions<
  CancelBookingRequestMutation,
  CancelBookingRequestMutationVariables
>;
export const SessionsDocument = gql`
  query Sessions {
    currentUser {
      id
      isAgent
      ...UserUnfulfilledOrdersFragment
      agentProfile {
        id
        ...CoachBufferFragment
      }
      ...UserAvailableCreditsFragment
      ...UserMeetingsFragment
      ...UserBookingRequestMeetingsFragment
    }
  }
  ${UserUnfulfilledOrdersFragmentFragmentDoc}
  ${CoachBufferFragmentFragmentDoc}
  ${UserAvailableCreditsFragmentFragmentDoc}
  ${UserMeetingsFragmentFragmentDoc}
  ${MeetingFragmentFragmentDoc}
  ${UserBookingRequestMeetingsFragmentFragmentDoc}
`;

/**
 * __useSessionsQuery__
 *
 * To run a query within a React component, call `useSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<SessionsQuery, SessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionsQuery, SessionsQueryVariables>(
    SessionsDocument,
    options,
  );
}
export function useSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionsQuery,
    SessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionsQuery, SessionsQueryVariables>(
    SessionsDocument,
    options,
  );
}
export function useSessionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SessionsQuery, SessionsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SessionsQuery, SessionsQueryVariables>(
    SessionsDocument,
    options,
  );
}
export type SessionsQueryHookResult = ReturnType<typeof useSessionsQuery>;
export type SessionsLazyQueryHookResult = ReturnType<
  typeof useSessionsLazyQuery
>;
export type SessionsSuspenseQueryHookResult = ReturnType<
  typeof useSessionsSuspenseQuery
>;
export type SessionsQueryResult = Apollo.QueryResult<
  SessionsQuery,
  SessionsQueryVariables
>;
export const TransferCreditsDocument = gql`
  mutation TransferCredits(
    $amount: Decimal!
    $fromKey: String
    $fromId: ID
    $toKey: String
    $toId: ID
  ) {
    transferCredits(
      amount: $amount
      fromKey: $fromKey
      fromId: $fromId
      toKey: $toKey
      toId: $toId
    ) {
      ok
      error {
        field
        message
      }
    }
  }
`;
export type TransferCreditsMutationFn = Apollo.MutationFunction<
  TransferCreditsMutation,
  TransferCreditsMutationVariables
>;

/**
 * __useTransferCreditsMutation__
 *
 * To run a mutation, you first call `useTransferCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferCreditsMutation, { data, loading, error }] = useTransferCreditsMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      fromKey: // value for 'fromKey'
 *      fromId: // value for 'fromId'
 *      toKey: // value for 'toKey'
 *      toId: // value for 'toId'
 *   },
 * });
 */
export function useTransferCreditsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferCreditsMutation,
    TransferCreditsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TransferCreditsMutation,
    TransferCreditsMutationVariables
  >(TransferCreditsDocument, options);
}
export type TransferCreditsMutationHookResult = ReturnType<
  typeof useTransferCreditsMutation
>;
export type TransferCreditsMutationResult =
  Apollo.MutationResult<TransferCreditsMutation>;
export type TransferCreditsMutationOptions = Apollo.BaseMutationOptions<
  TransferCreditsMutation,
  TransferCreditsMutationVariables
>;
export const TransferPageDocument = gql`
  query TransferPage {
    currentUser {
      id
      isAgent
      ...UserAvailableCreditsFragment
    }
  }
  ${UserAvailableCreditsFragmentFragmentDoc}
`;

/**
 * __useTransferPageQuery__
 *
 * To run a query within a React component, call `useTransferPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransferPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TransferPageQuery,
    TransferPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransferPageQuery, TransferPageQueryVariables>(
    TransferPageDocument,
    options,
  );
}
export function useTransferPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransferPageQuery,
    TransferPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransferPageQuery, TransferPageQueryVariables>(
    TransferPageDocument,
    options,
  );
}
export function useTransferPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TransferPageQuery,
        TransferPageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TransferPageQuery, TransferPageQueryVariables>(
    TransferPageDocument,
    options,
  );
}
export type TransferPageQueryHookResult = ReturnType<
  typeof useTransferPageQuery
>;
export type TransferPageLazyQueryHookResult = ReturnType<
  typeof useTransferPageLazyQuery
>;
export type TransferPageSuspenseQueryHookResult = ReturnType<
  typeof useTransferPageSuspenseQuery
>;
export type TransferPageQueryResult = Apollo.QueryResult<
  TransferPageQuery,
  TransferPageQueryVariables
>;
export const AssignAssistantDocument = gql`
  mutation AssignAssistant($clientMemberId: ID!, $assistantMemberId: ID!) {
    assignAssistant(
      clientMemberId: $clientMemberId
      assistantMemberId: $assistantMemberId
    ) {
      ok
      errors {
        field
        message
      }
      workspaceAssistantAssignment {
        client {
          id
          user {
            id
            name
            email
            photo
          }
        }
        assistant {
          id
          user {
            id
            name
            email
            photo
          }
        }
      }
    }
  }
`;
export type AssignAssistantMutationFn = Apollo.MutationFunction<
  AssignAssistantMutation,
  AssignAssistantMutationVariables
>;

/**
 * __useAssignAssistantMutation__
 *
 * To run a mutation, you first call `useAssignAssistantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAssistantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAssistantMutation, { data, loading, error }] = useAssignAssistantMutation({
 *   variables: {
 *      clientMemberId: // value for 'clientMemberId'
 *      assistantMemberId: // value for 'assistantMemberId'
 *   },
 * });
 */
export function useAssignAssistantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignAssistantMutation,
    AssignAssistantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignAssistantMutation,
    AssignAssistantMutationVariables
  >(AssignAssistantDocument, options);
}
export type AssignAssistantMutationHookResult = ReturnType<
  typeof useAssignAssistantMutation
>;
export type AssignAssistantMutationResult =
  Apollo.MutationResult<AssignAssistantMutation>;
export type AssignAssistantMutationOptions = Apollo.BaseMutationOptions<
  AssignAssistantMutation,
  AssignAssistantMutationVariables
>;
export const AssignWorkspaceGroupDocument = gql`
  mutation AssignWorkspaceGroup($groupId: ID!, $memberIds: [ID]!) {
    assignWorkspaceGroup(groupId: $groupId, memberIds: $memberIds) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type AssignWorkspaceGroupMutationFn = Apollo.MutationFunction<
  AssignWorkspaceGroupMutation,
  AssignWorkspaceGroupMutationVariables
>;

/**
 * __useAssignWorkspaceGroupMutation__
 *
 * To run a mutation, you first call `useAssignWorkspaceGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignWorkspaceGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignWorkspaceGroupMutation, { data, loading, error }] = useAssignWorkspaceGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useAssignWorkspaceGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignWorkspaceGroupMutation,
    AssignWorkspaceGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignWorkspaceGroupMutation,
    AssignWorkspaceGroupMutationVariables
  >(AssignWorkspaceGroupDocument, options);
}
export type AssignWorkspaceGroupMutationHookResult = ReturnType<
  typeof useAssignWorkspaceGroupMutation
>;
export type AssignWorkspaceGroupMutationResult =
  Apollo.MutationResult<AssignWorkspaceGroupMutation>;
export type AssignWorkspaceGroupMutationOptions = Apollo.BaseMutationOptions<
  AssignWorkspaceGroupMutation,
  AssignWorkspaceGroupMutationVariables
>;
export const CancelInviteClientScheduleDocument = gql`
  mutation CancelInviteClientSchedule($scheduleJobId: ID!) {
    cancelInviteClientSchedule(scheduleJobId: $scheduleJobId) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type CancelInviteClientScheduleMutationFn = Apollo.MutationFunction<
  CancelInviteClientScheduleMutation,
  CancelInviteClientScheduleMutationVariables
>;

/**
 * __useCancelInviteClientScheduleMutation__
 *
 * To run a mutation, you first call `useCancelInviteClientScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInviteClientScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInviteClientScheduleMutation, { data, loading, error }] = useCancelInviteClientScheduleMutation({
 *   variables: {
 *      scheduleJobId: // value for 'scheduleJobId'
 *   },
 * });
 */
export function useCancelInviteClientScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelInviteClientScheduleMutation,
    CancelInviteClientScheduleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelInviteClientScheduleMutation,
    CancelInviteClientScheduleMutationVariables
  >(CancelInviteClientScheduleDocument, options);
}
export type CancelInviteClientScheduleMutationHookResult = ReturnType<
  typeof useCancelInviteClientScheduleMutation
>;
export type CancelInviteClientScheduleMutationResult =
  Apollo.MutationResult<CancelInviteClientScheduleMutation>;
export type CancelInviteClientScheduleMutationOptions =
  Apollo.BaseMutationOptions<
    CancelInviteClientScheduleMutation,
    CancelInviteClientScheduleMutationVariables
  >;
export const CreateWorkspaceGroupDocument = gql`
  mutation CreateWorkspaceGroup($name: String!, $config: ConfigurationInput) {
    createWorkspaceGroup(name: $name, config: $config) {
      ok
      errors {
        field
        message
      }
      group {
        id
        name
      }
    }
  }
`;
export type CreateWorkspaceGroupMutationFn = Apollo.MutationFunction<
  CreateWorkspaceGroupMutation,
  CreateWorkspaceGroupMutationVariables
>;

/**
 * __useCreateWorkspaceGroupMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceGroupMutation, { data, loading, error }] = useCreateWorkspaceGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useCreateWorkspaceGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkspaceGroupMutation,
    CreateWorkspaceGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWorkspaceGroupMutation,
    CreateWorkspaceGroupMutationVariables
  >(CreateWorkspaceGroupDocument, options);
}
export type CreateWorkspaceGroupMutationHookResult = ReturnType<
  typeof useCreateWorkspaceGroupMutation
>;
export type CreateWorkspaceGroupMutationResult =
  Apollo.MutationResult<CreateWorkspaceGroupMutation>;
export type CreateWorkspaceGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkspaceGroupMutation,
  CreateWorkspaceGroupMutationVariables
>;
export const DeactivateWorkspaceMembersDocument = gql`
  mutation DeactivateWorkspaceMembers($workspaceMemberIds: [ID]!) {
    deactivateWorkspaceMembers(workspaceMemberIds: $workspaceMemberIds) {
      ok
      errors {
        field
        messages
      }
      deactivatedMembers {
        id
        user {
          id
          name
          email
        }
      }
    }
  }
`;
export type DeactivateWorkspaceMembersMutationFn = Apollo.MutationFunction<
  DeactivateWorkspaceMembersMutation,
  DeactivateWorkspaceMembersMutationVariables
>;

/**
 * __useDeactivateWorkspaceMembersMutation__
 *
 * To run a mutation, you first call `useDeactivateWorkspaceMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateWorkspaceMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateWorkspaceMembersMutation, { data, loading, error }] = useDeactivateWorkspaceMembersMutation({
 *   variables: {
 *      workspaceMemberIds: // value for 'workspaceMemberIds'
 *   },
 * });
 */
export function useDeactivateWorkspaceMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateWorkspaceMembersMutation,
    DeactivateWorkspaceMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateWorkspaceMembersMutation,
    DeactivateWorkspaceMembersMutationVariables
  >(DeactivateWorkspaceMembersDocument, options);
}
export type DeactivateWorkspaceMembersMutationHookResult = ReturnType<
  typeof useDeactivateWorkspaceMembersMutation
>;
export type DeactivateWorkspaceMembersMutationResult =
  Apollo.MutationResult<DeactivateWorkspaceMembersMutation>;
export type DeactivateWorkspaceMembersMutationOptions =
  Apollo.BaseMutationOptions<
    DeactivateWorkspaceMembersMutation,
    DeactivateWorkspaceMembersMutationVariables
  >;
export const DeleteGroupMemberDocument = gql`
  mutation DeleteGroupMember($groupMemberId: ID!) {
    deleteGroupMember(groupMemberId: $groupMemberId) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type DeleteGroupMemberMutationFn = Apollo.MutationFunction<
  DeleteGroupMemberMutation,
  DeleteGroupMemberMutationVariables
>;

/**
 * __useDeleteGroupMemberMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMemberMutation, { data, loading, error }] = useDeleteGroupMemberMutation({
 *   variables: {
 *      groupMemberId: // value for 'groupMemberId'
 *   },
 * });
 */
export function useDeleteGroupMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupMemberMutation,
    DeleteGroupMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGroupMemberMutation,
    DeleteGroupMemberMutationVariables
  >(DeleteGroupMemberDocument, options);
}
export type DeleteGroupMemberMutationHookResult = ReturnType<
  typeof useDeleteGroupMemberMutation
>;
export type DeleteGroupMemberMutationResult =
  Apollo.MutationResult<DeleteGroupMemberMutation>;
export type DeleteGroupMemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupMemberMutation,
  DeleteGroupMemberMutationVariables
>;
export const DeleteWorkspaceDocument = gql`
  mutation DeleteWorkspace {
    deleteWorkspace {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type DeleteWorkspaceMutationFn = Apollo.MutationFunction<
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables
>;

/**
 * __useDeleteWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceMutation, { data, loading, error }] = useDeleteWorkspaceMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorkspaceMutation,
    DeleteWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWorkspaceMutation,
    DeleteWorkspaceMutationVariables
  >(DeleteWorkspaceDocument, options);
}
export type DeleteWorkspaceMutationHookResult = ReturnType<
  typeof useDeleteWorkspaceMutation
>;
export type DeleteWorkspaceMutationResult =
  Apollo.MutationResult<DeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables
>;
export const DeleteWorkspaceGroupDocument = gql`
  mutation DeleteWorkspaceGroup($groupId: ID!) {
    deleteWorkspaceGroup(groupId: $groupId) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type DeleteWorkspaceGroupMutationFn = Apollo.MutationFunction<
  DeleteWorkspaceGroupMutation,
  DeleteWorkspaceGroupMutationVariables
>;

/**
 * __useDeleteWorkspaceGroupMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceGroupMutation, { data, loading, error }] = useDeleteWorkspaceGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteWorkspaceGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorkspaceGroupMutation,
    DeleteWorkspaceGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWorkspaceGroupMutation,
    DeleteWorkspaceGroupMutationVariables
  >(DeleteWorkspaceGroupDocument, options);
}
export type DeleteWorkspaceGroupMutationHookResult = ReturnType<
  typeof useDeleteWorkspaceGroupMutation
>;
export type DeleteWorkspaceGroupMutationResult =
  Apollo.MutationResult<DeleteWorkspaceGroupMutation>;
export type DeleteWorkspaceGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorkspaceGroupMutation,
  DeleteWorkspaceGroupMutationVariables
>;
export const InviteClientsDocument = gql`
  mutation InviteClients(
    $emails: String!
    $coachIds: [ID]
    $groupId: ID
    $credit: Decimal!
    $isSchedule: Boolean
    $scheduleTime: DateTime
  ) {
    inviteClients(
      emails: $emails
      coachIds: $coachIds
      groupId: $groupId
      credit: $credit
      isSchedule: $isSchedule
      scheduleTime: $scheduleTime
    ) {
      ok
      errors {
        field
        message
      }
      toBeInvitedEmails
      invalidEmails
      existedEmails
    }
  }
`;
export type InviteClientsMutationFn = Apollo.MutationFunction<
  InviteClientsMutation,
  InviteClientsMutationVariables
>;

/**
 * __useInviteClientsMutation__
 *
 * To run a mutation, you first call `useInviteClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteClientsMutation, { data, loading, error }] = useInviteClientsMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      coachIds: // value for 'coachIds'
 *      groupId: // value for 'groupId'
 *      credit: // value for 'credit'
 *      isSchedule: // value for 'isSchedule'
 *      scheduleTime: // value for 'scheduleTime'
 *   },
 * });
 */
export function useInviteClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteClientsMutation,
    InviteClientsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteClientsMutation,
    InviteClientsMutationVariables
  >(InviteClientsDocument, options);
}
export type InviteClientsMutationHookResult = ReturnType<
  typeof useInviteClientsMutation
>;
export type InviteClientsMutationResult =
  Apollo.MutationResult<InviteClientsMutation>;
export type InviteClientsMutationOptions = Apollo.BaseMutationOptions<
  InviteClientsMutation,
  InviteClientsMutationVariables
>;
export const RemoveAssistantDocument = gql`
  mutation RemoveAssistant($clientMemberId: ID!, $assistantMemberId: ID!) {
    removeAssistant(
      clientMemberId: $clientMemberId
      assistantMemberId: $assistantMemberId
    ) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type RemoveAssistantMutationFn = Apollo.MutationFunction<
  RemoveAssistantMutation,
  RemoveAssistantMutationVariables
>;

/**
 * __useRemoveAssistantMutation__
 *
 * To run a mutation, you first call `useRemoveAssistantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssistantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssistantMutation, { data, loading, error }] = useRemoveAssistantMutation({
 *   variables: {
 *      clientMemberId: // value for 'clientMemberId'
 *      assistantMemberId: // value for 'assistantMemberId'
 *   },
 * });
 */
export function useRemoveAssistantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAssistantMutation,
    RemoveAssistantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAssistantMutation,
    RemoveAssistantMutationVariables
  >(RemoveAssistantDocument, options);
}
export type RemoveAssistantMutationHookResult = ReturnType<
  typeof useRemoveAssistantMutation
>;
export type RemoveAssistantMutationResult =
  Apollo.MutationResult<RemoveAssistantMutation>;
export type RemoveAssistantMutationOptions = Apollo.BaseMutationOptions<
  RemoveAssistantMutation,
  RemoveAssistantMutationVariables
>;
export const SetWorkspaceGroupCreditsDocument = gql`
  mutation SetWorkspaceGroupCredits($groupIds: [String]!, $amount: Decimal!) {
    setWorkspaceGroupCredits(groupIds: $groupIds, amount: $amount) {
      ok
      errors {
        field
        messages
      }
      success
      total
    }
  }
`;
export type SetWorkspaceGroupCreditsMutationFn = Apollo.MutationFunction<
  SetWorkspaceGroupCreditsMutation,
  SetWorkspaceGroupCreditsMutationVariables
>;

/**
 * __useSetWorkspaceGroupCreditsMutation__
 *
 * To run a mutation, you first call `useSetWorkspaceGroupCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkspaceGroupCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkspaceGroupCreditsMutation, { data, loading, error }] = useSetWorkspaceGroupCreditsMutation({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useSetWorkspaceGroupCreditsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetWorkspaceGroupCreditsMutation,
    SetWorkspaceGroupCreditsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetWorkspaceGroupCreditsMutation,
    SetWorkspaceGroupCreditsMutationVariables
  >(SetWorkspaceGroupCreditsDocument, options);
}
export type SetWorkspaceGroupCreditsMutationHookResult = ReturnType<
  typeof useSetWorkspaceGroupCreditsMutation
>;
export type SetWorkspaceGroupCreditsMutationResult =
  Apollo.MutationResult<SetWorkspaceGroupCreditsMutation>;
export type SetWorkspaceGroupCreditsMutationOptions =
  Apollo.BaseMutationOptions<
    SetWorkspaceGroupCreditsMutation,
    SetWorkspaceGroupCreditsMutationVariables
  >;
export const SetWorkspaceMemberCreditsDocument = gql`
  mutation SetWorkspaceMemberCredits($clientIds: [String]!, $amount: Decimal!) {
    setWorkspaceMemberCredits(clientIds: $clientIds, amount: $amount) {
      ok
      errors {
        field
        messages
      }
      success
      total
    }
  }
`;
export type SetWorkspaceMemberCreditsMutationFn = Apollo.MutationFunction<
  SetWorkspaceMemberCreditsMutation,
  SetWorkspaceMemberCreditsMutationVariables
>;

/**
 * __useSetWorkspaceMemberCreditsMutation__
 *
 * To run a mutation, you first call `useSetWorkspaceMemberCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkspaceMemberCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkspaceMemberCreditsMutation, { data, loading, error }] = useSetWorkspaceMemberCreditsMutation({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useSetWorkspaceMemberCreditsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetWorkspaceMemberCreditsMutation,
    SetWorkspaceMemberCreditsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetWorkspaceMemberCreditsMutation,
    SetWorkspaceMemberCreditsMutationVariables
  >(SetWorkspaceMemberCreditsDocument, options);
}
export type SetWorkspaceMemberCreditsMutationHookResult = ReturnType<
  typeof useSetWorkspaceMemberCreditsMutation
>;
export type SetWorkspaceMemberCreditsMutationResult =
  Apollo.MutationResult<SetWorkspaceMemberCreditsMutation>;
export type SetWorkspaceMemberCreditsMutationOptions =
  Apollo.BaseMutationOptions<
    SetWorkspaceMemberCreditsMutation,
    SetWorkspaceMemberCreditsMutationVariables
  >;
export const SpecialtiesForConfigDocument = gql`
  query SpecialtiesForConfig($categories: [ProductCategories]) {
    specialtiesForConfig(categories: $categories) {
      id
      name
      slug
    }
  }
`;

/**
 * __useSpecialtiesForConfigQuery__
 *
 * To run a query within a React component, call `useSpecialtiesForConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialtiesForConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialtiesForConfigQuery({
 *   variables: {
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useSpecialtiesForConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpecialtiesForConfigQuery,
    SpecialtiesForConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpecialtiesForConfigQuery,
    SpecialtiesForConfigQueryVariables
  >(SpecialtiesForConfigDocument, options);
}
export function useSpecialtiesForConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpecialtiesForConfigQuery,
    SpecialtiesForConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpecialtiesForConfigQuery,
    SpecialtiesForConfigQueryVariables
  >(SpecialtiesForConfigDocument, options);
}
export function useSpecialtiesForConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SpecialtiesForConfigQuery,
        SpecialtiesForConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SpecialtiesForConfigQuery,
    SpecialtiesForConfigQueryVariables
  >(SpecialtiesForConfigDocument, options);
}
export type SpecialtiesForConfigQueryHookResult = ReturnType<
  typeof useSpecialtiesForConfigQuery
>;
export type SpecialtiesForConfigLazyQueryHookResult = ReturnType<
  typeof useSpecialtiesForConfigLazyQuery
>;
export type SpecialtiesForConfigSuspenseQueryHookResult = ReturnType<
  typeof useSpecialtiesForConfigSuspenseQuery
>;
export type SpecialtiesForConfigQueryResult = Apollo.QueryResult<
  SpecialtiesForConfigQuery,
  SpecialtiesForConfigQueryVariables
>;
export const TransferCreditsDialogDocument = gql`
  query TransferCreditsDialog($keys: [String]) {
    ledgerAccounts(keys: $keys) {
      id
      label
      balance
    }
  }
`;

/**
 * __useTransferCreditsDialogQuery__
 *
 * To run a query within a React component, call `useTransferCreditsDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferCreditsDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferCreditsDialogQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useTransferCreditsDialogQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TransferCreditsDialogQuery,
    TransferCreditsDialogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransferCreditsDialogQuery,
    TransferCreditsDialogQueryVariables
  >(TransferCreditsDialogDocument, options);
}
export function useTransferCreditsDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransferCreditsDialogQuery,
    TransferCreditsDialogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransferCreditsDialogQuery,
    TransferCreditsDialogQueryVariables
  >(TransferCreditsDialogDocument, options);
}
export function useTransferCreditsDialogSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TransferCreditsDialogQuery,
        TransferCreditsDialogQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TransferCreditsDialogQuery,
    TransferCreditsDialogQueryVariables
  >(TransferCreditsDialogDocument, options);
}
export type TransferCreditsDialogQueryHookResult = ReturnType<
  typeof useTransferCreditsDialogQuery
>;
export type TransferCreditsDialogLazyQueryHookResult = ReturnType<
  typeof useTransferCreditsDialogLazyQuery
>;
export type TransferCreditsDialogSuspenseQueryHookResult = ReturnType<
  typeof useTransferCreditsDialogSuspenseQuery
>;
export type TransferCreditsDialogQueryResult = Apollo.QueryResult<
  TransferCreditsDialogQuery,
  TransferCreditsDialogQueryVariables
>;
export const UnassignWorkspaceGroupDocument = gql`
  mutation UnassignWorkspaceGroup($groupId: ID, $memberIds: [String]!) {
    unassignWorkspaceGroup(groupId: $groupId, memberIds: $memberIds) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type UnassignWorkspaceGroupMutationFn = Apollo.MutationFunction<
  UnassignWorkspaceGroupMutation,
  UnassignWorkspaceGroupMutationVariables
>;

/**
 * __useUnassignWorkspaceGroupMutation__
 *
 * To run a mutation, you first call `useUnassignWorkspaceGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignWorkspaceGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignWorkspaceGroupMutation, { data, loading, error }] = useUnassignWorkspaceGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useUnassignWorkspaceGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignWorkspaceGroupMutation,
    UnassignWorkspaceGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnassignWorkspaceGroupMutation,
    UnassignWorkspaceGroupMutationVariables
  >(UnassignWorkspaceGroupDocument, options);
}
export type UnassignWorkspaceGroupMutationHookResult = ReturnType<
  typeof useUnassignWorkspaceGroupMutation
>;
export type UnassignWorkspaceGroupMutationResult =
  Apollo.MutationResult<UnassignWorkspaceGroupMutation>;
export type UnassignWorkspaceGroupMutationOptions = Apollo.BaseMutationOptions<
  UnassignWorkspaceGroupMutation,
  UnassignWorkspaceGroupMutationVariables
>;
export const UpdateGroupRoleDocument = gql`
  mutation UpdateGroupRole($groupMemberId: ID!, $isAdmin: Boolean!) {
    updateGroupRole(groupMemberId: $groupMemberId, isAdmin: $isAdmin) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type UpdateGroupRoleMutationFn = Apollo.MutationFunction<
  UpdateGroupRoleMutation,
  UpdateGroupRoleMutationVariables
>;

/**
 * __useUpdateGroupRoleMutation__
 *
 * To run a mutation, you first call `useUpdateGroupRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupRoleMutation, { data, loading, error }] = useUpdateGroupRoleMutation({
 *   variables: {
 *      groupMemberId: // value for 'groupMemberId'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useUpdateGroupRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupRoleMutation,
    UpdateGroupRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGroupRoleMutation,
    UpdateGroupRoleMutationVariables
  >(UpdateGroupRoleDocument, options);
}
export type UpdateGroupRoleMutationHookResult = ReturnType<
  typeof useUpdateGroupRoleMutation
>;
export type UpdateGroupRoleMutationResult =
  Apollo.MutationResult<UpdateGroupRoleMutation>;
export type UpdateGroupRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupRoleMutation,
  UpdateGroupRoleMutationVariables
>;
export const UpdateMemberRoleDocument = gql`
  mutation UpdateMemberRole($memberId: ID!, $role: String!) {
    updateMemberRole(memberId: $memberId, role: $role) {
      ok
      errors {
        field
        message
      }
      updatedMember {
        id
        role
      }
    }
  }
`;
export type UpdateMemberRoleMutationFn = Apollo.MutationFunction<
  UpdateMemberRoleMutation,
  UpdateMemberRoleMutationVariables
>;

/**
 * __useUpdateMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberRoleMutation, { data, loading, error }] = useUpdateMemberRoleMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateMemberRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberRoleMutation,
    UpdateMemberRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberRoleMutation,
    UpdateMemberRoleMutationVariables
  >(UpdateMemberRoleDocument, options);
}
export type UpdateMemberRoleMutationHookResult = ReturnType<
  typeof useUpdateMemberRoleMutation
>;
export type UpdateMemberRoleMutationResult =
  Apollo.MutationResult<UpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberRoleMutation,
  UpdateMemberRoleMutationVariables
>;
export const UpdateWorkspaceGroupDocument = gql`
  mutation UpdateWorkspaceGroup(
    $groupId: ID!
    $name: String!
    $config: ConfigurationInput
  ) {
    updateWorkspaceGroup(groupId: $groupId, name: $name, config: $config) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type UpdateWorkspaceGroupMutationFn = Apollo.MutationFunction<
  UpdateWorkspaceGroupMutation,
  UpdateWorkspaceGroupMutationVariables
>;

/**
 * __useUpdateWorkspaceGroupMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceGroupMutation, { data, loading, error }] = useUpdateWorkspaceGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      name: // value for 'name'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useUpdateWorkspaceGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceGroupMutation,
    UpdateWorkspaceGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkspaceGroupMutation,
    UpdateWorkspaceGroupMutationVariables
  >(UpdateWorkspaceGroupDocument, options);
}
export type UpdateWorkspaceGroupMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceGroupMutation
>;
export type UpdateWorkspaceGroupMutationResult =
  Apollo.MutationResult<UpdateWorkspaceGroupMutation>;
export type UpdateWorkspaceGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkspaceGroupMutation,
  UpdateWorkspaceGroupMutationVariables
>;
export const UpdateWorkspaceInvitationScheduleDocument = gql`
  mutation UpdateWorkspaceInvitationSchedule(
    $scheduledJobId: ID!
    $emails: String!
    $coachIds: [ID]
    $groupId: ID
    $credit: Decimal!
    $isSchedule: Boolean
    $scheduleTime: DateTime
  ) {
    updateWorkspaceInvitationSchedule(
      scheduledJobId: $scheduledJobId
      emails: $emails
      coachIds: $coachIds
      groupId: $groupId
      credit: $credit
      isSchedule: $isSchedule
      scheduleTime: $scheduleTime
    ) {
      ok
      errors {
        field
        message
      }
      toBeInvitedEmails
      invalidEmails
      existedEmails
    }
  }
`;
export type UpdateWorkspaceInvitationScheduleMutationFn =
  Apollo.MutationFunction<
    UpdateWorkspaceInvitationScheduleMutation,
    UpdateWorkspaceInvitationScheduleMutationVariables
  >;

/**
 * __useUpdateWorkspaceInvitationScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceInvitationScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceInvitationScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceInvitationScheduleMutation, { data, loading, error }] = useUpdateWorkspaceInvitationScheduleMutation({
 *   variables: {
 *      scheduledJobId: // value for 'scheduledJobId'
 *      emails: // value for 'emails'
 *      coachIds: // value for 'coachIds'
 *      groupId: // value for 'groupId'
 *      credit: // value for 'credit'
 *      isSchedule: // value for 'isSchedule'
 *      scheduleTime: // value for 'scheduleTime'
 *   },
 * });
 */
export function useUpdateWorkspaceInvitationScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceInvitationScheduleMutation,
    UpdateWorkspaceInvitationScheduleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkspaceInvitationScheduleMutation,
    UpdateWorkspaceInvitationScheduleMutationVariables
  >(UpdateWorkspaceInvitationScheduleDocument, options);
}
export type UpdateWorkspaceInvitationScheduleMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceInvitationScheduleMutation
>;
export type UpdateWorkspaceInvitationScheduleMutationResult =
  Apollo.MutationResult<UpdateWorkspaceInvitationScheduleMutation>;
export type UpdateWorkspaceInvitationScheduleMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateWorkspaceInvitationScheduleMutation,
    UpdateWorkspaceInvitationScheduleMutationVariables
  >;
export const UpdateWorkspaceUrlSlugDocument = gql`
  mutation UpdateWorkspaceUrlSlug($slug: String!) {
    updateWorkspaceUrlSlug(slug: $slug) {
      ok
      errors {
        message
      }
      updatedWorkspace {
        id
        urlSlug
      }
    }
  }
`;
export type UpdateWorkspaceUrlSlugMutationFn = Apollo.MutationFunction<
  UpdateWorkspaceUrlSlugMutation,
  UpdateWorkspaceUrlSlugMutationVariables
>;

/**
 * __useUpdateWorkspaceUrlSlugMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceUrlSlugMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceUrlSlugMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceUrlSlugMutation, { data, loading, error }] = useUpdateWorkspaceUrlSlugMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpdateWorkspaceUrlSlugMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceUrlSlugMutation,
    UpdateWorkspaceUrlSlugMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkspaceUrlSlugMutation,
    UpdateWorkspaceUrlSlugMutationVariables
  >(UpdateWorkspaceUrlSlugDocument, options);
}
export type UpdateWorkspaceUrlSlugMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceUrlSlugMutation
>;
export type UpdateWorkspaceUrlSlugMutationResult =
  Apollo.MutationResult<UpdateWorkspaceUrlSlugMutation>;
export type UpdateWorkspaceUrlSlugMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkspaceUrlSlugMutation,
  UpdateWorkspaceUrlSlugMutationVariables
>;
export const WorkspaceAnalyticsDocument = gql`
  query WorkspaceAnalytics {
    workspaceAnalytics {
      id
      url
    }
  }
`;

/**
 * __useWorkspaceAnalyticsQuery__
 *
 * To run a query within a React component, call `useWorkspaceAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceAnalyticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceAnalyticsQuery,
    WorkspaceAnalyticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceAnalyticsQuery,
    WorkspaceAnalyticsQueryVariables
  >(WorkspaceAnalyticsDocument, options);
}
export function useWorkspaceAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceAnalyticsQuery,
    WorkspaceAnalyticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceAnalyticsQuery,
    WorkspaceAnalyticsQueryVariables
  >(WorkspaceAnalyticsDocument, options);
}
export function useWorkspaceAnalyticsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceAnalyticsQuery,
        WorkspaceAnalyticsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceAnalyticsQuery,
    WorkspaceAnalyticsQueryVariables
  >(WorkspaceAnalyticsDocument, options);
}
export type WorkspaceAnalyticsQueryHookResult = ReturnType<
  typeof useWorkspaceAnalyticsQuery
>;
export type WorkspaceAnalyticsLazyQueryHookResult = ReturnType<
  typeof useWorkspaceAnalyticsLazyQuery
>;
export type WorkspaceAnalyticsSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceAnalyticsSuspenseQuery
>;
export type WorkspaceAnalyticsQueryResult = Apollo.QueryResult<
  WorkspaceAnalyticsQuery,
  WorkspaceAnalyticsQueryVariables
>;
export const WorkspaceClientStatisticsQueryDocument = gql`
  query WorkspaceClientStatisticsQuery(
    $currentPage: Int!
    $pageSize: Int!
    $sortField: String
    $sortDesc: Boolean!
    $query: String
    $groupIds: [String]
  ) {
    workspaceClientStatistics(
      currentPage: $currentPage
      pageSize: $pageSize
      sortField: $sortField
      sortDesc: $sortDesc
      query: $query
      groupIds: $groupIds
    ) {
      currentPage
      pageSize
      totalRecords
      totalMembers
      data {
        id
        availableCredits
        totalBalance
        ledgerAccounts {
          id
          name
          label
          balance
          ownerType
        }
        role
        groups {
          id
          name
        }
        workspace {
          id
          name
          urlSlug
        }
        ...WorkspaceAssistantClientFragment
        ...WorkspaceAssistantFragment
        user {
          id
          photo
          name
          email
          coaches {
            id
            photo
            name
            agentProfile {
              id
              coachSlug
            }
          }
        }
      }
    }
  }
  ${WorkspaceAssistantClientFragmentFragmentDoc}
  ${WorkspaceAssistantFragmentFragmentDoc}
`;

/**
 * __useWorkspaceClientStatisticsQueryQuery__
 *
 * To run a query within a React component, call `useWorkspaceClientStatisticsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceClientStatisticsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceClientStatisticsQueryQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *      pageSize: // value for 'pageSize'
 *      sortField: // value for 'sortField'
 *      sortDesc: // value for 'sortDesc'
 *      query: // value for 'query'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useWorkspaceClientStatisticsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceClientStatisticsQueryQuery,
    WorkspaceClientStatisticsQueryQueryVariables
  > &
    (
      | {
          variables: WorkspaceClientStatisticsQueryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceClientStatisticsQueryQuery,
    WorkspaceClientStatisticsQueryQueryVariables
  >(WorkspaceClientStatisticsQueryDocument, options);
}
export function useWorkspaceClientStatisticsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceClientStatisticsQueryQuery,
    WorkspaceClientStatisticsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceClientStatisticsQueryQuery,
    WorkspaceClientStatisticsQueryQueryVariables
  >(WorkspaceClientStatisticsQueryDocument, options);
}
export function useWorkspaceClientStatisticsQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceClientStatisticsQueryQuery,
        WorkspaceClientStatisticsQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceClientStatisticsQueryQuery,
    WorkspaceClientStatisticsQueryQueryVariables
  >(WorkspaceClientStatisticsQueryDocument, options);
}
export type WorkspaceClientStatisticsQueryQueryHookResult = ReturnType<
  typeof useWorkspaceClientStatisticsQueryQuery
>;
export type WorkspaceClientStatisticsQueryLazyQueryHookResult = ReturnType<
  typeof useWorkspaceClientStatisticsQueryLazyQuery
>;
export type WorkspaceClientStatisticsQuerySuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceClientStatisticsQuerySuspenseQuery
>;
export type WorkspaceClientStatisticsQueryQueryResult = Apollo.QueryResult<
  WorkspaceClientStatisticsQueryQuery,
  WorkspaceClientStatisticsQueryQueryVariables
>;
export const WorkspaceGroupDocument = gql`
  query WorkspaceGroup($groupId: ID!) {
    workspaceGroup(groupId: $groupId) {
      id
      name
      memberCount
      availableCredits
      currentUserRole
      configuration {
        ...ConfigurationFragment
      }
    }
  }
  ${ConfigurationFragmentFragmentDoc}
`;

/**
 * __useWorkspaceGroupQuery__
 *
 * To run a query within a React component, call `useWorkspaceGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useWorkspaceGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceGroupQuery,
    WorkspaceGroupQueryVariables
  > &
    (
      | { variables: WorkspaceGroupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkspaceGroupQuery, WorkspaceGroupQueryVariables>(
    WorkspaceGroupDocument,
    options,
  );
}
export function useWorkspaceGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceGroupQuery,
    WorkspaceGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkspaceGroupQuery, WorkspaceGroupQueryVariables>(
    WorkspaceGroupDocument,
    options,
  );
}
export function useWorkspaceGroupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceGroupQuery,
        WorkspaceGroupQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceGroupQuery,
    WorkspaceGroupQueryVariables
  >(WorkspaceGroupDocument, options);
}
export type WorkspaceGroupQueryHookResult = ReturnType<
  typeof useWorkspaceGroupQuery
>;
export type WorkspaceGroupLazyQueryHookResult = ReturnType<
  typeof useWorkspaceGroupLazyQuery
>;
export type WorkspaceGroupSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceGroupSuspenseQuery
>;
export type WorkspaceGroupQueryResult = Apollo.QueryResult<
  WorkspaceGroupQuery,
  WorkspaceGroupQueryVariables
>;
export const WorkspaceGroupMembersDocument = gql`
  query WorkspaceGroupMembers($groupId: ID!) {
    workspaceGroupMembers(groupId: $groupId) {
      id
      isAdmin
      availableCredits
      workspaceMember {
        id
        user {
          id
          name
          email
        }
      }
    }
  }
`;

/**
 * __useWorkspaceGroupMembersQuery__
 *
 * To run a query within a React component, call `useWorkspaceGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceGroupMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceGroupMembersQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useWorkspaceGroupMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceGroupMembersQuery,
    WorkspaceGroupMembersQueryVariables
  > &
    (
      | { variables: WorkspaceGroupMembersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceGroupMembersQuery,
    WorkspaceGroupMembersQueryVariables
  >(WorkspaceGroupMembersDocument, options);
}
export function useWorkspaceGroupMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceGroupMembersQuery,
    WorkspaceGroupMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceGroupMembersQuery,
    WorkspaceGroupMembersQueryVariables
  >(WorkspaceGroupMembersDocument, options);
}
export function useWorkspaceGroupMembersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceGroupMembersQuery,
        WorkspaceGroupMembersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceGroupMembersQuery,
    WorkspaceGroupMembersQueryVariables
  >(WorkspaceGroupMembersDocument, options);
}
export type WorkspaceGroupMembersQueryHookResult = ReturnType<
  typeof useWorkspaceGroupMembersQuery
>;
export type WorkspaceGroupMembersLazyQueryHookResult = ReturnType<
  typeof useWorkspaceGroupMembersLazyQuery
>;
export type WorkspaceGroupMembersSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceGroupMembersSuspenseQuery
>;
export type WorkspaceGroupMembersQueryResult = Apollo.QueryResult<
  WorkspaceGroupMembersQuery,
  WorkspaceGroupMembersQueryVariables
>;
export const WorkspaceGroupsDocument = gql`
  query WorkspaceGroups($sortField: String, $sortDesc: Boolean) {
    workspaceGroups(sortField: $sortField, sortDesc: $sortDesc) {
      id
      name
      memberCount
      availableCredits
      currentUserRole
    }
  }
`;

/**
 * __useWorkspaceGroupsQuery__
 *
 * To run a query within a React component, call `useWorkspaceGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceGroupsQuery({
 *   variables: {
 *      sortField: // value for 'sortField'
 *      sortDesc: // value for 'sortDesc'
 *   },
 * });
 */
export function useWorkspaceGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceGroupsQuery,
    WorkspaceGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkspaceGroupsQuery, WorkspaceGroupsQueryVariables>(
    WorkspaceGroupsDocument,
    options,
  );
}
export function useWorkspaceGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceGroupsQuery,
    WorkspaceGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceGroupsQuery,
    WorkspaceGroupsQueryVariables
  >(WorkspaceGroupsDocument, options);
}
export function useWorkspaceGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceGroupsQuery,
        WorkspaceGroupsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceGroupsQuery,
    WorkspaceGroupsQueryVariables
  >(WorkspaceGroupsDocument, options);
}
export type WorkspaceGroupsQueryHookResult = ReturnType<
  typeof useWorkspaceGroupsQuery
>;
export type WorkspaceGroupsLazyQueryHookResult = ReturnType<
  typeof useWorkspaceGroupsLazyQuery
>;
export type WorkspaceGroupsSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceGroupsSuspenseQuery
>;
export type WorkspaceGroupsQueryResult = Apollo.QueryResult<
  WorkspaceGroupsQuery,
  WorkspaceGroupsQueryVariables
>;
export const WorkspaceInvitationBatchDocument = gql`
  query WorkspaceInvitationBatch($workspaceInvitationBatchId: ID!) {
    workspaceInvitationBatch(
      workspaceInvitationBatchId: $workspaceInvitationBatchId
    ) {
      id
      coaches {
        id
        name
      }
      group {
        id
        name
      }
      credit
      status
      workspaceInvitations {
        id
        email
      }
    }
  }
`;

/**
 * __useWorkspaceInvitationBatchQuery__
 *
 * To run a query within a React component, call `useWorkspaceInvitationBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceInvitationBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceInvitationBatchQuery({
 *   variables: {
 *      workspaceInvitationBatchId: // value for 'workspaceInvitationBatchId'
 *   },
 * });
 */
export function useWorkspaceInvitationBatchQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceInvitationBatchQuery,
    WorkspaceInvitationBatchQueryVariables
  > &
    (
      | { variables: WorkspaceInvitationBatchQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceInvitationBatchQuery,
    WorkspaceInvitationBatchQueryVariables
  >(WorkspaceInvitationBatchDocument, options);
}
export function useWorkspaceInvitationBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceInvitationBatchQuery,
    WorkspaceInvitationBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceInvitationBatchQuery,
    WorkspaceInvitationBatchQueryVariables
  >(WorkspaceInvitationBatchDocument, options);
}
export function useWorkspaceInvitationBatchSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceInvitationBatchQuery,
        WorkspaceInvitationBatchQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceInvitationBatchQuery,
    WorkspaceInvitationBatchQueryVariables
  >(WorkspaceInvitationBatchDocument, options);
}
export type WorkspaceInvitationBatchQueryHookResult = ReturnType<
  typeof useWorkspaceInvitationBatchQuery
>;
export type WorkspaceInvitationBatchLazyQueryHookResult = ReturnType<
  typeof useWorkspaceInvitationBatchLazyQuery
>;
export type WorkspaceInvitationBatchSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceInvitationBatchSuspenseQuery
>;
export type WorkspaceInvitationBatchQueryResult = Apollo.QueryResult<
  WorkspaceInvitationBatchQuery,
  WorkspaceInvitationBatchQueryVariables
>;
export const WorkspaceInvitationBatchesDocument = gql`
  query WorkspaceInvitationBatches($programId: ID!) {
    workspaceInvitationBatches(programId: $programId) {
      id
      workspaceInvitations {
        id
        email
      }
    }
  }
`;

/**
 * __useWorkspaceInvitationBatchesQuery__
 *
 * To run a query within a React component, call `useWorkspaceInvitationBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceInvitationBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceInvitationBatchesQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useWorkspaceInvitationBatchesQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceInvitationBatchesQuery,
    WorkspaceInvitationBatchesQueryVariables
  > &
    (
      | { variables: WorkspaceInvitationBatchesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceInvitationBatchesQuery,
    WorkspaceInvitationBatchesQueryVariables
  >(WorkspaceInvitationBatchesDocument, options);
}
export function useWorkspaceInvitationBatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceInvitationBatchesQuery,
    WorkspaceInvitationBatchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceInvitationBatchesQuery,
    WorkspaceInvitationBatchesQueryVariables
  >(WorkspaceInvitationBatchesDocument, options);
}
export function useWorkspaceInvitationBatchesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceInvitationBatchesQuery,
        WorkspaceInvitationBatchesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceInvitationBatchesQuery,
    WorkspaceInvitationBatchesQueryVariables
  >(WorkspaceInvitationBatchesDocument, options);
}
export type WorkspaceInvitationBatchesQueryHookResult = ReturnType<
  typeof useWorkspaceInvitationBatchesQuery
>;
export type WorkspaceInvitationBatchesLazyQueryHookResult = ReturnType<
  typeof useWorkspaceInvitationBatchesLazyQuery
>;
export type WorkspaceInvitationBatchesSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceInvitationBatchesSuspenseQuery
>;
export type WorkspaceInvitationBatchesQueryResult = Apollo.QueryResult<
  WorkspaceInvitationBatchesQuery,
  WorkspaceInvitationBatchesQueryVariables
>;
export const WorkspaceInvitationBatchesForProgramStatusDocument = gql`
  query WorkspaceInvitationBatchesForProgramStatus($programId: ID!) {
    workspaceInvitationBatchesForProgramStatus(programId: $programId)
  }
`;

/**
 * __useWorkspaceInvitationBatchesForProgramStatusQuery__
 *
 * To run a query within a React component, call `useWorkspaceInvitationBatchesForProgramStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceInvitationBatchesForProgramStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceInvitationBatchesForProgramStatusQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useWorkspaceInvitationBatchesForProgramStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceInvitationBatchesForProgramStatusQuery,
    WorkspaceInvitationBatchesForProgramStatusQueryVariables
  > &
    (
      | {
          variables: WorkspaceInvitationBatchesForProgramStatusQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceInvitationBatchesForProgramStatusQuery,
    WorkspaceInvitationBatchesForProgramStatusQueryVariables
  >(WorkspaceInvitationBatchesForProgramStatusDocument, options);
}
export function useWorkspaceInvitationBatchesForProgramStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceInvitationBatchesForProgramStatusQuery,
    WorkspaceInvitationBatchesForProgramStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceInvitationBatchesForProgramStatusQuery,
    WorkspaceInvitationBatchesForProgramStatusQueryVariables
  >(WorkspaceInvitationBatchesForProgramStatusDocument, options);
}
export function useWorkspaceInvitationBatchesForProgramStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceInvitationBatchesForProgramStatusQuery,
        WorkspaceInvitationBatchesForProgramStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceInvitationBatchesForProgramStatusQuery,
    WorkspaceInvitationBatchesForProgramStatusQueryVariables
  >(WorkspaceInvitationBatchesForProgramStatusDocument, options);
}
export type WorkspaceInvitationBatchesForProgramStatusQueryHookResult =
  ReturnType<typeof useWorkspaceInvitationBatchesForProgramStatusQuery>;
export type WorkspaceInvitationBatchesForProgramStatusLazyQueryHookResult =
  ReturnType<typeof useWorkspaceInvitationBatchesForProgramStatusLazyQuery>;
export type WorkspaceInvitationBatchesForProgramStatusSuspenseQueryHookResult =
  ReturnType<typeof useWorkspaceInvitationBatchesForProgramStatusSuspenseQuery>;
export type WorkspaceInvitationBatchesForProgramStatusQueryResult =
  Apollo.QueryResult<
    WorkspaceInvitationBatchesForProgramStatusQuery,
    WorkspaceInvitationBatchesForProgramStatusQueryVariables
  >;
export const WorkspaceInvitaionBatchesStatusDocument = gql`
  query WorkspaceInvitaionBatchesStatus {
    workspaceInvitationBatchesStatus
  }
`;

/**
 * __useWorkspaceInvitaionBatchesStatusQuery__
 *
 * To run a query within a React component, call `useWorkspaceInvitaionBatchesStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceInvitaionBatchesStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceInvitaionBatchesStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceInvitaionBatchesStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceInvitaionBatchesStatusQuery,
    WorkspaceInvitaionBatchesStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceInvitaionBatchesStatusQuery,
    WorkspaceInvitaionBatchesStatusQueryVariables
  >(WorkspaceInvitaionBatchesStatusDocument, options);
}
export function useWorkspaceInvitaionBatchesStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceInvitaionBatchesStatusQuery,
    WorkspaceInvitaionBatchesStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceInvitaionBatchesStatusQuery,
    WorkspaceInvitaionBatchesStatusQueryVariables
  >(WorkspaceInvitaionBatchesStatusDocument, options);
}
export function useWorkspaceInvitaionBatchesStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceInvitaionBatchesStatusQuery,
        WorkspaceInvitaionBatchesStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceInvitaionBatchesStatusQuery,
    WorkspaceInvitaionBatchesStatusQueryVariables
  >(WorkspaceInvitaionBatchesStatusDocument, options);
}
export type WorkspaceInvitaionBatchesStatusQueryHookResult = ReturnType<
  typeof useWorkspaceInvitaionBatchesStatusQuery
>;
export type WorkspaceInvitaionBatchesStatusLazyQueryHookResult = ReturnType<
  typeof useWorkspaceInvitaionBatchesStatusLazyQuery
>;
export type WorkspaceInvitaionBatchesStatusSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceInvitaionBatchesStatusSuspenseQuery
>;
export type WorkspaceInvitaionBatchesStatusQueryResult = Apollo.QueryResult<
  WorkspaceInvitaionBatchesStatusQuery,
  WorkspaceInvitaionBatchesStatusQueryVariables
>;
export const WorkspaceMemberAssistantsDocument = gql`
  query WorkspaceMemberAssistants($customerId: ID!) {
    workspaceMember(customerId: $customerId) {
      id
      ...WorkspaceAssistantClientFragment
      ...WorkspaceAssistantFragment
    }
  }
  ${WorkspaceAssistantClientFragmentFragmentDoc}
  ${WorkspaceAssistantFragmentFragmentDoc}
`;

/**
 * __useWorkspaceMemberAssistantsQuery__
 *
 * To run a query within a React component, call `useWorkspaceMemberAssistantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceMemberAssistantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceMemberAssistantsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useWorkspaceMemberAssistantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceMemberAssistantsQuery,
    WorkspaceMemberAssistantsQueryVariables
  > &
    (
      | { variables: WorkspaceMemberAssistantsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceMemberAssistantsQuery,
    WorkspaceMemberAssistantsQueryVariables
  >(WorkspaceMemberAssistantsDocument, options);
}
export function useWorkspaceMemberAssistantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceMemberAssistantsQuery,
    WorkspaceMemberAssistantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceMemberAssistantsQuery,
    WorkspaceMemberAssistantsQueryVariables
  >(WorkspaceMemberAssistantsDocument, options);
}
export function useWorkspaceMemberAssistantsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceMemberAssistantsQuery,
        WorkspaceMemberAssistantsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceMemberAssistantsQuery,
    WorkspaceMemberAssistantsQueryVariables
  >(WorkspaceMemberAssistantsDocument, options);
}
export type WorkspaceMemberAssistantsQueryHookResult = ReturnType<
  typeof useWorkspaceMemberAssistantsQuery
>;
export type WorkspaceMemberAssistantsLazyQueryHookResult = ReturnType<
  typeof useWorkspaceMemberAssistantsLazyQuery
>;
export type WorkspaceMemberAssistantsSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceMemberAssistantsSuspenseQuery
>;
export type WorkspaceMemberAssistantsQueryResult = Apollo.QueryResult<
  WorkspaceMemberAssistantsQuery,
  WorkspaceMemberAssistantsQueryVariables
>;
export const WorkspaceMemberDetailsDocument = gql`
  query WorkspaceMemberDetails($customerId: ID!) {
    workspaceMember(customerId: $customerId) {
      id
      configuration {
        ...ConfigurationFragment
      }
      user {
        id
        photo
        name
        email
      }
      ...WorkspaceAssistantClientFragment
      ...WorkspaceAssistantFragment
    }
  }
  ${ConfigurationFragmentFragmentDoc}
  ${WorkspaceAssistantClientFragmentFragmentDoc}
  ${WorkspaceAssistantFragmentFragmentDoc}
`;

/**
 * __useWorkspaceMemberDetailsQuery__
 *
 * To run a query within a React component, call `useWorkspaceMemberDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceMemberDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceMemberDetailsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useWorkspaceMemberDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceMemberDetailsQuery,
    WorkspaceMemberDetailsQueryVariables
  > &
    (
      | { variables: WorkspaceMemberDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceMemberDetailsQuery,
    WorkspaceMemberDetailsQueryVariables
  >(WorkspaceMemberDetailsDocument, options);
}
export function useWorkspaceMemberDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceMemberDetailsQuery,
    WorkspaceMemberDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceMemberDetailsQuery,
    WorkspaceMemberDetailsQueryVariables
  >(WorkspaceMemberDetailsDocument, options);
}
export function useWorkspaceMemberDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceMemberDetailsQuery,
        WorkspaceMemberDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceMemberDetailsQuery,
    WorkspaceMemberDetailsQueryVariables
  >(WorkspaceMemberDetailsDocument, options);
}
export type WorkspaceMemberDetailsQueryHookResult = ReturnType<
  typeof useWorkspaceMemberDetailsQuery
>;
export type WorkspaceMemberDetailsLazyQueryHookResult = ReturnType<
  typeof useWorkspaceMemberDetailsLazyQuery
>;
export type WorkspaceMemberDetailsSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceMemberDetailsSuspenseQuery
>;
export type WorkspaceMemberDetailsQueryResult = Apollo.QueryResult<
  WorkspaceMemberDetailsQuery,
  WorkspaceMemberDetailsQueryVariables
>;
export const MemberMeetingsDocument = gql`
  query MemberMeetings(
    $customerId: ID
    $currentPage: Int
    $pageSize: Int
    $withoutCanceled: Boolean
  ) {
    memberMeetings(
      customerId: $customerId
      currentPage: $currentPage
      pageSize: $pageSize
      withoutCanceled: $withoutCanceled
    ) {
      currentPage
      totalRecords
      pageSize
      data {
        id
        eventAt
        canceledAt
        cancelReason
        creditPrice
        paymentStatus
        coach {
          id
          name
          photo
          agentProfile {
            id
            coachSlug
          }
        }
        customer {
          id
          name
          photo
        }
        product {
          id
          name
          slug
          creditPrice
          specialty {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useMemberMeetingsQuery__
 *
 * To run a query within a React component, call `useMemberMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberMeetingsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      currentPage: // value for 'currentPage'
 *      pageSize: // value for 'pageSize'
 *      withoutCanceled: // value for 'withoutCanceled'
 *   },
 * });
 */
export function useMemberMeetingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MemberMeetingsQuery,
    MemberMeetingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberMeetingsQuery, MemberMeetingsQueryVariables>(
    MemberMeetingsDocument,
    options,
  );
}
export function useMemberMeetingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberMeetingsQuery,
    MemberMeetingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberMeetingsQuery, MemberMeetingsQueryVariables>(
    MemberMeetingsDocument,
    options,
  );
}
export function useMemberMeetingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MemberMeetingsQuery,
        MemberMeetingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberMeetingsQuery,
    MemberMeetingsQueryVariables
  >(MemberMeetingsDocument, options);
}
export type MemberMeetingsQueryHookResult = ReturnType<
  typeof useMemberMeetingsQuery
>;
export type MemberMeetingsLazyQueryHookResult = ReturnType<
  typeof useMemberMeetingsLazyQuery
>;
export type MemberMeetingsSuspenseQueryHookResult = ReturnType<
  typeof useMemberMeetingsSuspenseQuery
>;
export type MemberMeetingsQueryResult = Apollo.QueryResult<
  MemberMeetingsQuery,
  MemberMeetingsQueryVariables
>;
export const WorkspaceMembersDocument = gql`
  query WorkspaceMembers {
    workspaceMembers {
      id
      user {
        id
        name
        email
      }
    }
  }
`;

/**
 * __useWorkspaceMembersQuery__
 *
 * To run a query within a React component, call `useWorkspaceMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceMembersQuery,
    WorkspaceMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkspaceMembersQuery, WorkspaceMembersQueryVariables>(
    WorkspaceMembersDocument,
    options,
  );
}
export function useWorkspaceMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceMembersQuery,
    WorkspaceMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceMembersQuery,
    WorkspaceMembersQueryVariables
  >(WorkspaceMembersDocument, options);
}
export function useWorkspaceMembersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceMembersQuery,
        WorkspaceMembersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceMembersQuery,
    WorkspaceMembersQueryVariables
  >(WorkspaceMembersDocument, options);
}
export type WorkspaceMembersQueryHookResult = ReturnType<
  typeof useWorkspaceMembersQuery
>;
export type WorkspaceMembersLazyQueryHookResult = ReturnType<
  typeof useWorkspaceMembersLazyQuery
>;
export type WorkspaceMembersSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceMembersSuspenseQuery
>;
export type WorkspaceMembersQueryResult = Apollo.QueryResult<
  WorkspaceMembersQuery,
  WorkspaceMembersQueryVariables
>;
export const WorkspaceScheduleJobsDocument = gql`
  query WorkspaceScheduleJobs(
    $jobTypes: [JobType]
    $statuses: [JobStatus]
    $meetingId: Int
    $programId: Int
  ) {
    scheduledJobs(
      jobTypes: $jobTypes
      statuses: $statuses
      meetingId: $meetingId
      programId: $programId
    ) {
      id
      jobPayload
      scheduledAt
      status
      resultStatus
    }
  }
`;

/**
 * __useWorkspaceScheduleJobsQuery__
 *
 * To run a query within a React component, call `useWorkspaceScheduleJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceScheduleJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceScheduleJobsQuery({
 *   variables: {
 *      jobTypes: // value for 'jobTypes'
 *      statuses: // value for 'statuses'
 *      meetingId: // value for 'meetingId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useWorkspaceScheduleJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceScheduleJobsQuery,
    WorkspaceScheduleJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceScheduleJobsQuery,
    WorkspaceScheduleJobsQueryVariables
  >(WorkspaceScheduleJobsDocument, options);
}
export function useWorkspaceScheduleJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceScheduleJobsQuery,
    WorkspaceScheduleJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceScheduleJobsQuery,
    WorkspaceScheduleJobsQueryVariables
  >(WorkspaceScheduleJobsDocument, options);
}
export function useWorkspaceScheduleJobsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceScheduleJobsQuery,
        WorkspaceScheduleJobsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceScheduleJobsQuery,
    WorkspaceScheduleJobsQueryVariables
  >(WorkspaceScheduleJobsDocument, options);
}
export type WorkspaceScheduleJobsQueryHookResult = ReturnType<
  typeof useWorkspaceScheduleJobsQuery
>;
export type WorkspaceScheduleJobsLazyQueryHookResult = ReturnType<
  typeof useWorkspaceScheduleJobsLazyQuery
>;
export type WorkspaceScheduleJobsSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceScheduleJobsSuspenseQuery
>;
export type WorkspaceScheduleJobsQueryResult = Apollo.QueryResult<
  WorkspaceScheduleJobsQuery,
  WorkspaceScheduleJobsQueryVariables
>;
export const WorkspaceSubscriptionDocument = gql`
  query WorkspaceSubscription {
    currentWorkspace {
      id
      numMembers
      totalPendingInvitations
      subscription {
        id
        planName
        planDescription
        validUntil
        billingCycleEnd
        stripeSubscriptionId
        quantity
        planEntitlements {
          id
          label
          booleanValue
          maxUsageValue
        }
      }
    }
  }
`;

/**
 * __useWorkspaceSubscriptionQuery__
 *
 * To run a query within a React component, call `useWorkspaceSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceSubscriptionQuery,
    WorkspaceSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceSubscriptionQuery,
    WorkspaceSubscriptionQueryVariables
  >(WorkspaceSubscriptionDocument, options);
}
export function useWorkspaceSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceSubscriptionQuery,
    WorkspaceSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceSubscriptionQuery,
    WorkspaceSubscriptionQueryVariables
  >(WorkspaceSubscriptionDocument, options);
}
export function useWorkspaceSubscriptionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceSubscriptionQuery,
        WorkspaceSubscriptionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceSubscriptionQuery,
    WorkspaceSubscriptionQueryVariables
  >(WorkspaceSubscriptionDocument, options);
}
export type WorkspaceSubscriptionQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionQuery
>;
export type WorkspaceSubscriptionLazyQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionLazyQuery
>;
export type WorkspaceSubscriptionSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionSuspenseQuery
>;
export type WorkspaceSubscriptionQueryResult = Apollo.QueryResult<
  WorkspaceSubscriptionQuery,
  WorkspaceSubscriptionQueryVariables
>;
export const ValidateInviteEmailsDocument = gql`
  query ValidateInviteEmails($emails: String!, $programId: ID) {
    validateInviteEmails(emails: $emails, programId: $programId) {
      canBeInvitedEmails
      invalidEmails
      existedEmails
      pendingInvEmails
      existedProgramUserEmails
    }
  }
`;

/**
 * __useValidateInviteEmailsQuery__
 *
 * To run a query within a React component, call `useValidateInviteEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateInviteEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateInviteEmailsQuery({
 *   variables: {
 *      emails: // value for 'emails'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useValidateInviteEmailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateInviteEmailsQuery,
    ValidateInviteEmailsQueryVariables
  > &
    (
      | { variables: ValidateInviteEmailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateInviteEmailsQuery,
    ValidateInviteEmailsQueryVariables
  >(ValidateInviteEmailsDocument, options);
}
export function useValidateInviteEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateInviteEmailsQuery,
    ValidateInviteEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateInviteEmailsQuery,
    ValidateInviteEmailsQueryVariables
  >(ValidateInviteEmailsDocument, options);
}
export function useValidateInviteEmailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ValidateInviteEmailsQuery,
        ValidateInviteEmailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ValidateInviteEmailsQuery,
    ValidateInviteEmailsQueryVariables
  >(ValidateInviteEmailsDocument, options);
}
export type ValidateInviteEmailsQueryHookResult = ReturnType<
  typeof useValidateInviteEmailsQuery
>;
export type ValidateInviteEmailsLazyQueryHookResult = ReturnType<
  typeof useValidateInviteEmailsLazyQuery
>;
export type ValidateInviteEmailsSuspenseQueryHookResult = ReturnType<
  typeof useValidateInviteEmailsSuspenseQuery
>;
export type ValidateInviteEmailsQueryResult = Apollo.QueryResult<
  ValidateInviteEmailsQuery,
  ValidateInviteEmailsQueryVariables
>;
export const WorkspaceAssistantQueryDocument = gql`
  query WorkspaceAssistantQuery {
    workspaceAssistant {
      id
      client {
        id
        user {
          id
          photo
          name
          email
        }
      }
    }
  }
`;

/**
 * __useWorkspaceAssistantQueryQuery__
 *
 * To run a query within a React component, call `useWorkspaceAssistantQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceAssistantQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceAssistantQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceAssistantQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceAssistantQueryQuery,
    WorkspaceAssistantQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceAssistantQueryQuery,
    WorkspaceAssistantQueryQueryVariables
  >(WorkspaceAssistantQueryDocument, options);
}
export function useWorkspaceAssistantQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceAssistantQueryQuery,
    WorkspaceAssistantQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceAssistantQueryQuery,
    WorkspaceAssistantQueryQueryVariables
  >(WorkspaceAssistantQueryDocument, options);
}
export function useWorkspaceAssistantQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceAssistantQueryQuery,
        WorkspaceAssistantQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceAssistantQueryQuery,
    WorkspaceAssistantQueryQueryVariables
  >(WorkspaceAssistantQueryDocument, options);
}
export type WorkspaceAssistantQueryQueryHookResult = ReturnType<
  typeof useWorkspaceAssistantQueryQuery
>;
export type WorkspaceAssistantQueryLazyQueryHookResult = ReturnType<
  typeof useWorkspaceAssistantQueryLazyQuery
>;
export type WorkspaceAssistantQuerySuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceAssistantQuerySuspenseQuery
>;
export type WorkspaceAssistantQueryQueryResult = Apollo.QueryResult<
  WorkspaceAssistantQueryQuery,
  WorkspaceAssistantQueryQueryVariables
>;
export const ClientStatisticQueryDocument = gql`
  query ClientStatisticQuery(
    $currentPage: Int
    $pageSize: Int
    $assignees: [String]
    $workspaces: [String]
    $nonWorkspace: Boolean
    $sortField: String
    $sortDesc: Boolean
    $nonAssigned: Boolean
    $query: String
  ) {
    clientStatistics(
      currentPage: $currentPage
      pageSize: $pageSize
      assignees: $assignees
      workspaces: $workspaces
      nonWorkspace: $nonWorkspace
      sortField: $sortField
      sortDesc: $sortDesc
      nonAssigned: $nonAssigned
      query: $query
    ) {
      currentPage
      totalRecords
      pageSize
      data {
        id
        userId
        name
        photo
        email
        nextMeetingId
        nextMeetingEventAt
        nextMeetingDuration
        nextMeetingProductName
        lastMeetingId
        lastMeetingEventAt
        lastMeetingDuration
        lastMeetingProductName
        recentReviewId
        recentReviewProductName
        recentReviewCreateAt
        messagesId
        messagesCreatedAt
        messagesHtml
        messagesCid
        workspaceId
        workspaceName
        coachId
        coachName
        coachPhoto
        coachSlug
      }
    }
  }
`;

/**
 * __useClientStatisticQueryQuery__
 *
 * To run a query within a React component, call `useClientStatisticQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientStatisticQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientStatisticQueryQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *      pageSize: // value for 'pageSize'
 *      assignees: // value for 'assignees'
 *      workspaces: // value for 'workspaces'
 *      nonWorkspace: // value for 'nonWorkspace'
 *      sortField: // value for 'sortField'
 *      sortDesc: // value for 'sortDesc'
 *      nonAssigned: // value for 'nonAssigned'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useClientStatisticQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClientStatisticQueryQuery,
    ClientStatisticQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientStatisticQueryQuery,
    ClientStatisticQueryQueryVariables
  >(ClientStatisticQueryDocument, options);
}
export function useClientStatisticQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientStatisticQueryQuery,
    ClientStatisticQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientStatisticQueryQuery,
    ClientStatisticQueryQueryVariables
  >(ClientStatisticQueryDocument, options);
}
export function useClientStatisticQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientStatisticQueryQuery,
        ClientStatisticQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientStatisticQueryQuery,
    ClientStatisticQueryQueryVariables
  >(ClientStatisticQueryDocument, options);
}
export type ClientStatisticQueryQueryHookResult = ReturnType<
  typeof useClientStatisticQueryQuery
>;
export type ClientStatisticQueryLazyQueryHookResult = ReturnType<
  typeof useClientStatisticQueryLazyQuery
>;
export type ClientStatisticQuerySuspenseQueryHookResult = ReturnType<
  typeof useClientStatisticQuerySuspenseQuery
>;
export type ClientStatisticQueryQueryResult = Apollo.QueryResult<
  ClientStatisticQueryQuery,
  ClientStatisticQueryQueryVariables
>;
export const ChatCustomersDocument = gql`
  query ChatCustomers(
    $assignees: [String]
    $workspaces: [String]
    $nonWorkspace: Boolean
    $offset: Int
    $limit: Int
  ) {
    chatCustomers(
      assignees: $assignees
      workspaces: $workspaces
      nonWorkspace: $nonWorkspace
      offset: $offset
      limit: $limit
    ) {
      id
      chatChannelId
      unreadMessages
      customer {
        id
        name
        email
        photo
      }
      workspace {
        id
        name
      }
    }
  }
`;

/**
 * __useChatCustomersQuery__
 *
 * To run a query within a React component, call `useChatCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatCustomersQuery({
 *   variables: {
 *      assignees: // value for 'assignees'
 *      workspaces: // value for 'workspaces'
 *      nonWorkspace: // value for 'nonWorkspace'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useChatCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChatCustomersQuery,
    ChatCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatCustomersQuery, ChatCustomersQueryVariables>(
    ChatCustomersDocument,
    options,
  );
}
export function useChatCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatCustomersQuery,
    ChatCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatCustomersQuery, ChatCustomersQueryVariables>(
    ChatCustomersDocument,
    options,
  );
}
export function useChatCustomersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChatCustomersQuery,
        ChatCustomersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ChatCustomersQuery,
    ChatCustomersQueryVariables
  >(ChatCustomersDocument, options);
}
export type ChatCustomersQueryHookResult = ReturnType<
  typeof useChatCustomersQuery
>;
export type ChatCustomersLazyQueryHookResult = ReturnType<
  typeof useChatCustomersLazyQuery
>;
export type ChatCustomersSuspenseQueryHookResult = ReturnType<
  typeof useChatCustomersSuspenseQuery
>;
export type ChatCustomersQueryResult = Apollo.QueryResult<
  ChatCustomersQuery,
  ChatCustomersQueryVariables
>;
export const ChatCustomerQueryDocument = gql`
  query ChatCustomerQuery($customerId: ID!) {
    customer(id: $customerId) {
      id
      photo
      name
      email
    }
  }
`;

/**
 * __useChatCustomerQueryQuery__
 *
 * To run a query within a React component, call `useChatCustomerQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatCustomerQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatCustomerQueryQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useChatCustomerQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatCustomerQueryQuery,
    ChatCustomerQueryQueryVariables
  > &
    (
      | { variables: ChatCustomerQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChatCustomerQueryQuery,
    ChatCustomerQueryQueryVariables
  >(ChatCustomerQueryDocument, options);
}
export function useChatCustomerQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatCustomerQueryQuery,
    ChatCustomerQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChatCustomerQueryQuery,
    ChatCustomerQueryQueryVariables
  >(ChatCustomerQueryDocument, options);
}
export function useChatCustomerQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChatCustomerQueryQuery,
        ChatCustomerQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ChatCustomerQueryQuery,
    ChatCustomerQueryQueryVariables
  >(ChatCustomerQueryDocument, options);
}
export type ChatCustomerQueryQueryHookResult = ReturnType<
  typeof useChatCustomerQueryQuery
>;
export type ChatCustomerQueryLazyQueryHookResult = ReturnType<
  typeof useChatCustomerQueryLazyQuery
>;
export type ChatCustomerQuerySuspenseQueryHookResult = ReturnType<
  typeof useChatCustomerQuerySuspenseQuery
>;
export type ChatCustomerQueryQueryResult = Apollo.QueryResult<
  ChatCustomerQueryQuery,
  ChatCustomerQueryQueryVariables
>;
export const ChatMeetingsDocument = gql`
  query ChatMeetings($customerId: ID!, $offset: Int, $limit: Int) {
    chatMeetings(customerId: $customerId, offset: $offset, limit: $limit) {
      id
      chatChannelId
      chatChannelTitle
      unreadMessages
      product {
        id
        name
      }
    }
  }
`;

/**
 * __useChatMeetingsQuery__
 *
 * To run a query within a React component, call `useChatMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMeetingsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useChatMeetingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatMeetingsQuery,
    ChatMeetingsQueryVariables
  > &
    (
      | { variables: ChatMeetingsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatMeetingsQuery, ChatMeetingsQueryVariables>(
    ChatMeetingsDocument,
    options,
  );
}
export function useChatMeetingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatMeetingsQuery,
    ChatMeetingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatMeetingsQuery, ChatMeetingsQueryVariables>(
    ChatMeetingsDocument,
    options,
  );
}
export function useChatMeetingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChatMeetingsQuery,
        ChatMeetingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ChatMeetingsQuery, ChatMeetingsQueryVariables>(
    ChatMeetingsDocument,
    options,
  );
}
export type ChatMeetingsQueryHookResult = ReturnType<
  typeof useChatMeetingsQuery
>;
export type ChatMeetingsLazyQueryHookResult = ReturnType<
  typeof useChatMeetingsLazyQuery
>;
export type ChatMeetingsSuspenseQueryHookResult = ReturnType<
  typeof useChatMeetingsSuspenseQuery
>;
export type ChatMeetingsQueryResult = Apollo.QueryResult<
  ChatMeetingsQuery,
  ChatMeetingsQueryVariables
>;
export const ChatReviewsDocument = gql`
  query ChatReviews($customerId: ID!, $offset: Int, $limit: Int) {
    chatReviews(customerId: $customerId, offset: $offset, limit: $limit) {
      id
      chatChannelId
      status
      chatChannelTitle
      unreadMessages
      canceledAt
    }
  }
`;

/**
 * __useChatReviewsQuery__
 *
 * To run a query within a React component, call `useChatReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatReviewsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useChatReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatReviewsQuery,
    ChatReviewsQueryVariables
  > &
    (
      | { variables: ChatReviewsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatReviewsQuery, ChatReviewsQueryVariables>(
    ChatReviewsDocument,
    options,
  );
}
export function useChatReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatReviewsQuery,
    ChatReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatReviewsQuery, ChatReviewsQueryVariables>(
    ChatReviewsDocument,
    options,
  );
}
export function useChatReviewsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChatReviewsQuery,
        ChatReviewsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ChatReviewsQuery, ChatReviewsQueryVariables>(
    ChatReviewsDocument,
    options,
  );
}
export type ChatReviewsQueryHookResult = ReturnType<typeof useChatReviewsQuery>;
export type ChatReviewsLazyQueryHookResult = ReturnType<
  typeof useChatReviewsLazyQuery
>;
export type ChatReviewsSuspenseQueryHookResult = ReturnType<
  typeof useChatReviewsSuspenseQuery
>;
export type ChatReviewsQueryResult = Apollo.QueryResult<
  ChatReviewsQuery,
  ChatReviewsQueryVariables
>;
export const DirectMessagesDocument = gql`
  query DirectMessages($customerId: ID!, $offset: Int, $limit: Int) {
    directMessages(customerId: $customerId, offset: $offset, limit: $limit) {
      id
      chatChannelId
      coach {
        id
        name
        photo
      }
      customer {
        id
        name
        photo
      }
      unreadMessages
    }
  }
`;

/**
 * __useDirectMessagesQuery__
 *
 * To run a query within a React component, call `useDirectMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectMessagesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDirectMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DirectMessagesQuery,
    DirectMessagesQueryVariables
  > &
    (
      | { variables: DirectMessagesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DirectMessagesQuery, DirectMessagesQueryVariables>(
    DirectMessagesDocument,
    options,
  );
}
export function useDirectMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DirectMessagesQuery,
    DirectMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DirectMessagesQuery, DirectMessagesQueryVariables>(
    DirectMessagesDocument,
    options,
  );
}
export function useDirectMessagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DirectMessagesQuery,
        DirectMessagesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DirectMessagesQuery,
    DirectMessagesQueryVariables
  >(DirectMessagesDocument, options);
}
export type DirectMessagesQueryHookResult = ReturnType<
  typeof useDirectMessagesQuery
>;
export type DirectMessagesLazyQueryHookResult = ReturnType<
  typeof useDirectMessagesLazyQuery
>;
export type DirectMessagesSuspenseQueryHookResult = ReturnType<
  typeof useDirectMessagesSuspenseQuery
>;
export type DirectMessagesQueryResult = Apollo.QueryResult<
  DirectMessagesQuery,
  DirectMessagesQueryVariables
>;
export const DirectMessageDocument = gql`
  query DirectMessage($assignmentId: ID!) {
    assignment(id: $assignmentId) {
      id
      chatChannelId
      coach {
        id
        name
        photo
      }
      customer {
        id
        name
        photo
      }
    }
  }
`;

/**
 * __useDirectMessageQuery__
 *
 * To run a query within a React component, call `useDirectMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectMessageQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useDirectMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    DirectMessageQuery,
    DirectMessageQueryVariables
  > &
    (
      | { variables: DirectMessageQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DirectMessageQuery, DirectMessageQueryVariables>(
    DirectMessageDocument,
    options,
  );
}
export function useDirectMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DirectMessageQuery,
    DirectMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DirectMessageQuery, DirectMessageQueryVariables>(
    DirectMessageDocument,
    options,
  );
}
export function useDirectMessageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DirectMessageQuery,
        DirectMessageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DirectMessageQuery,
    DirectMessageQueryVariables
  >(DirectMessageDocument, options);
}
export type DirectMessageQueryHookResult = ReturnType<
  typeof useDirectMessageQuery
>;
export type DirectMessageLazyQueryHookResult = ReturnType<
  typeof useDirectMessageLazyQuery
>;
export type DirectMessageSuspenseQueryHookResult = ReturnType<
  typeof useDirectMessageSuspenseQuery
>;
export type DirectMessageQueryResult = Apollo.QueryResult<
  DirectMessageQuery,
  DirectMessageQueryVariables
>;
export const HasChatChannelsDocument = gql`
  query HasChatChannels($customerId: ID) {
    hasChatChannels(customerId: $customerId)
  }
`;

/**
 * __useHasChatChannelsQuery__
 *
 * To run a query within a React component, call `useHasChatChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasChatChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasChatChannelsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useHasChatChannelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HasChatChannelsQuery,
    HasChatChannelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HasChatChannelsQuery, HasChatChannelsQueryVariables>(
    HasChatChannelsDocument,
    options,
  );
}
export function useHasChatChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasChatChannelsQuery,
    HasChatChannelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HasChatChannelsQuery,
    HasChatChannelsQueryVariables
  >(HasChatChannelsDocument, options);
}
export function useHasChatChannelsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HasChatChannelsQuery,
        HasChatChannelsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HasChatChannelsQuery,
    HasChatChannelsQueryVariables
  >(HasChatChannelsDocument, options);
}
export type HasChatChannelsQueryHookResult = ReturnType<
  typeof useHasChatChannelsQuery
>;
export type HasChatChannelsLazyQueryHookResult = ReturnType<
  typeof useHasChatChannelsLazyQuery
>;
export type HasChatChannelsSuspenseQueryHookResult = ReturnType<
  typeof useHasChatChannelsSuspenseQuery
>;
export type HasChatChannelsQueryResult = Apollo.QueryResult<
  HasChatChannelsQuery,
  HasChatChannelsQueryVariables
>;
export const WorkspaceGeneralChatDocument = gql`
  query WorkspaceGeneralChat($customerId: ID!) {
    customer(id: $customerId) {
      id
      name
      email
      photo
    }
    currentWorkspace {
      id
      logo
    }
    taggableUsers {
      id
      name
    }
    chatChannelId(customerId: $customerId)
  }
`;

/**
 * __useWorkspaceGeneralChatQuery__
 *
 * To run a query within a React component, call `useWorkspaceGeneralChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceGeneralChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceGeneralChatQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useWorkspaceGeneralChatQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceGeneralChatQuery,
    WorkspaceGeneralChatQueryVariables
  > &
    (
      | { variables: WorkspaceGeneralChatQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceGeneralChatQuery,
    WorkspaceGeneralChatQueryVariables
  >(WorkspaceGeneralChatDocument, options);
}
export function useWorkspaceGeneralChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceGeneralChatQuery,
    WorkspaceGeneralChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceGeneralChatQuery,
    WorkspaceGeneralChatQueryVariables
  >(WorkspaceGeneralChatDocument, options);
}
export function useWorkspaceGeneralChatSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceGeneralChatQuery,
        WorkspaceGeneralChatQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceGeneralChatQuery,
    WorkspaceGeneralChatQueryVariables
  >(WorkspaceGeneralChatDocument, options);
}
export type WorkspaceGeneralChatQueryHookResult = ReturnType<
  typeof useWorkspaceGeneralChatQuery
>;
export type WorkspaceGeneralChatLazyQueryHookResult = ReturnType<
  typeof useWorkspaceGeneralChatLazyQuery
>;
export type WorkspaceGeneralChatSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceGeneralChatSuspenseQuery
>;
export type WorkspaceGeneralChatQueryResult = Apollo.QueryResult<
  WorkspaceGeneralChatQuery,
  WorkspaceGeneralChatQueryVariables
>;
export const WorkspaceMeetingChatDocument = gql`
  query WorkspaceMeetingChat($meetingId: ID!, $customerId: ID!) {
    customer(id: $customerId) {
      id
      name
      email
      photo
    }
    meeting(id: $meetingId, customerId: $customerId) {
      id
      chatChannelTitle
      chatChannelId
    }
  }
`;

/**
 * __useWorkspaceMeetingChatQuery__
 *
 * To run a query within a React component, call `useWorkspaceMeetingChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceMeetingChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceMeetingChatQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useWorkspaceMeetingChatQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceMeetingChatQuery,
    WorkspaceMeetingChatQueryVariables
  > &
    (
      | { variables: WorkspaceMeetingChatQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceMeetingChatQuery,
    WorkspaceMeetingChatQueryVariables
  >(WorkspaceMeetingChatDocument, options);
}
export function useWorkspaceMeetingChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceMeetingChatQuery,
    WorkspaceMeetingChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceMeetingChatQuery,
    WorkspaceMeetingChatQueryVariables
  >(WorkspaceMeetingChatDocument, options);
}
export function useWorkspaceMeetingChatSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceMeetingChatQuery,
        WorkspaceMeetingChatQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceMeetingChatQuery,
    WorkspaceMeetingChatQueryVariables
  >(WorkspaceMeetingChatDocument, options);
}
export type WorkspaceMeetingChatQueryHookResult = ReturnType<
  typeof useWorkspaceMeetingChatQuery
>;
export type WorkspaceMeetingChatLazyQueryHookResult = ReturnType<
  typeof useWorkspaceMeetingChatLazyQuery
>;
export type WorkspaceMeetingChatSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceMeetingChatSuspenseQuery
>;
export type WorkspaceMeetingChatQueryResult = Apollo.QueryResult<
  WorkspaceMeetingChatQuery,
  WorkspaceMeetingChatQueryVariables
>;
export const WorkspaceReviewChatDocument = gql`
  query WorkspaceReviewChat($reviewId: ID!, $customerId: ID!) {
    customer(id: $customerId) {
      id
      name
      email
      photo
    }
    review(id: $reviewId, customerId: $customerId) {
      id
      chatChannelTitle
      chatChannelId
    }
  }
`;

/**
 * __useWorkspaceReviewChatQuery__
 *
 * To run a query within a React component, call `useWorkspaceReviewChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceReviewChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceReviewChatQuery({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useWorkspaceReviewChatQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceReviewChatQuery,
    WorkspaceReviewChatQueryVariables
  > &
    (
      | { variables: WorkspaceReviewChatQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceReviewChatQuery,
    WorkspaceReviewChatQueryVariables
  >(WorkspaceReviewChatDocument, options);
}
export function useWorkspaceReviewChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceReviewChatQuery,
    WorkspaceReviewChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceReviewChatQuery,
    WorkspaceReviewChatQueryVariables
  >(WorkspaceReviewChatDocument, options);
}
export function useWorkspaceReviewChatSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceReviewChatQuery,
        WorkspaceReviewChatQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceReviewChatQuery,
    WorkspaceReviewChatQueryVariables
  >(WorkspaceReviewChatDocument, options);
}
export type WorkspaceReviewChatQueryHookResult = ReturnType<
  typeof useWorkspaceReviewChatQuery
>;
export type WorkspaceReviewChatLazyQueryHookResult = ReturnType<
  typeof useWorkspaceReviewChatLazyQuery
>;
export type WorkspaceReviewChatSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceReviewChatSuspenseQuery
>;
export type WorkspaceReviewChatQueryResult = Apollo.QueryResult<
  WorkspaceReviewChatQuery,
  WorkspaceReviewChatQueryVariables
>;
export const NotificationCountDocument = gql`
  query NotificationCount {
    notificationCount
  }
`;

/**
 * __useNotificationCountQuery__
 *
 * To run a query within a React component, call `useNotificationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationCountQuery,
    NotificationCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotificationCountQuery,
    NotificationCountQueryVariables
  >(NotificationCountDocument, options);
}
export function useNotificationCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationCountQuery,
    NotificationCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotificationCountQuery,
    NotificationCountQueryVariables
  >(NotificationCountDocument, options);
}
export function useNotificationCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        NotificationCountQuery,
        NotificationCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    NotificationCountQuery,
    NotificationCountQueryVariables
  >(NotificationCountDocument, options);
}
export type NotificationCountQueryHookResult = ReturnType<
  typeof useNotificationCountQuery
>;
export type NotificationCountLazyQueryHookResult = ReturnType<
  typeof useNotificationCountLazyQuery
>;
export type NotificationCountSuspenseQueryHookResult = ReturnType<
  typeof useNotificationCountSuspenseQuery
>;
export type NotificationCountQueryResult = Apollo.QueryResult<
  NotificationCountQuery,
  NotificationCountQueryVariables
>;
export const ParticipatedWorkspacesDocument = gql`
  query ParticipatedWorkspaces {
    participatedWorkspaces {
      id
      name
      urlSlug
      logo
    }
  }
`;

/**
 * __useParticipatedWorkspacesQuery__
 *
 * To run a query within a React component, call `useParticipatedWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipatedWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipatedWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useParticipatedWorkspacesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ParticipatedWorkspacesQuery,
    ParticipatedWorkspacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ParticipatedWorkspacesQuery,
    ParticipatedWorkspacesQueryVariables
  >(ParticipatedWorkspacesDocument, options);
}
export function useParticipatedWorkspacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ParticipatedWorkspacesQuery,
    ParticipatedWorkspacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ParticipatedWorkspacesQuery,
    ParticipatedWorkspacesQueryVariables
  >(ParticipatedWorkspacesDocument, options);
}
export function useParticipatedWorkspacesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ParticipatedWorkspacesQuery,
        ParticipatedWorkspacesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ParticipatedWorkspacesQuery,
    ParticipatedWorkspacesQueryVariables
  >(ParticipatedWorkspacesDocument, options);
}
export type ParticipatedWorkspacesQueryHookResult = ReturnType<
  typeof useParticipatedWorkspacesQuery
>;
export type ParticipatedWorkspacesLazyQueryHookResult = ReturnType<
  typeof useParticipatedWorkspacesLazyQuery
>;
export type ParticipatedWorkspacesSuspenseQueryHookResult = ReturnType<
  typeof useParticipatedWorkspacesSuspenseQuery
>;
export type ParticipatedWorkspacesQueryResult = Apollo.QueryResult<
  ParticipatedWorkspacesQuery,
  ParticipatedWorkspacesQueryVariables
>;
export const EnableIndividualAccountDocument = gql`
  mutation EnableIndividualAccount($hasIndividualAccount: Boolean!) {
    updateUser(input: { hasIndividualAccount: $hasIndividualAccount }) {
      errors {
        field
        message
      }
    }
  }
`;
export type EnableIndividualAccountMutationFn = Apollo.MutationFunction<
  EnableIndividualAccountMutation,
  EnableIndividualAccountMutationVariables
>;

/**
 * __useEnableIndividualAccountMutation__
 *
 * To run a mutation, you first call `useEnableIndividualAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableIndividualAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableIndividualAccountMutation, { data, loading, error }] = useEnableIndividualAccountMutation({
 *   variables: {
 *      hasIndividualAccount: // value for 'hasIndividualAccount'
 *   },
 * });
 */
export function useEnableIndividualAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableIndividualAccountMutation,
    EnableIndividualAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnableIndividualAccountMutation,
    EnableIndividualAccountMutationVariables
  >(EnableIndividualAccountDocument, options);
}
export type EnableIndividualAccountMutationHookResult = ReturnType<
  typeof useEnableIndividualAccountMutation
>;
export type EnableIndividualAccountMutationResult =
  Apollo.MutationResult<EnableIndividualAccountMutation>;
export type EnableIndividualAccountMutationOptions = Apollo.BaseMutationOptions<
  EnableIndividualAccountMutation,
  EnableIndividualAccountMutationVariables
>;
export const UpdateWorkspaceNameDocument = gql`
  mutation UpdateWorkspaceName($name: String!) {
    updateWorkspaceName(name: $name) {
      ok
      errors {
        message
        code
      }
      updatedWorkspace {
        id
        name
      }
    }
  }
`;
export type UpdateWorkspaceNameMutationFn = Apollo.MutationFunction<
  UpdateWorkspaceNameMutation,
  UpdateWorkspaceNameMutationVariables
>;

/**
 * __useUpdateWorkspaceNameMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceNameMutation, { data, loading, error }] = useUpdateWorkspaceNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateWorkspaceNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceNameMutation,
    UpdateWorkspaceNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkspaceNameMutation,
    UpdateWorkspaceNameMutationVariables
  >(UpdateWorkspaceNameDocument, options);
}
export type UpdateWorkspaceNameMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceNameMutation
>;
export type UpdateWorkspaceNameMutationResult =
  Apollo.MutationResult<UpdateWorkspaceNameMutation>;
export type UpdateWorkspaceNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkspaceNameMutation,
  UpdateWorkspaceNameMutationVariables
>;
export const WorkspaceNavQueryDocument = gql`
  query WorkspaceNavQuery {
    currentWorkspaceRole
    currentUser {
      id
      email
      name
      phoneNumber
      jobTitle
      linkedinAccount
      timeZone
      billingReady
      photo
      notificationPreferences {
        type
        email
      }
      isAgent
      agentProfile {
        id
        coachSlug
      }
    }
    currentUserWorkspaceMember {
      id
      user {
        id
        name
        email
        photo
      }
      ...WorkspaceAssistantClientFragment
      ...WorkspaceAssistantFragment
    }
  }
  ${WorkspaceAssistantClientFragmentFragmentDoc}
  ${WorkspaceAssistantFragmentFragmentDoc}
`;

/**
 * __useWorkspaceNavQueryQuery__
 *
 * To run a query within a React component, call `useWorkspaceNavQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceNavQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceNavQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceNavQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceNavQueryQuery,
    WorkspaceNavQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceNavQueryQuery,
    WorkspaceNavQueryQueryVariables
  >(WorkspaceNavQueryDocument, options);
}
export function useWorkspaceNavQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceNavQueryQuery,
    WorkspaceNavQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceNavQueryQuery,
    WorkspaceNavQueryQueryVariables
  >(WorkspaceNavQueryDocument, options);
}
export function useWorkspaceNavQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceNavQueryQuery,
        WorkspaceNavQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceNavQueryQuery,
    WorkspaceNavQueryQueryVariables
  >(WorkspaceNavQueryDocument, options);
}
export type WorkspaceNavQueryQueryHookResult = ReturnType<
  typeof useWorkspaceNavQueryQuery
>;
export type WorkspaceNavQueryLazyQueryHookResult = ReturnType<
  typeof useWorkspaceNavQueryLazyQuery
>;
export type WorkspaceNavQuerySuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceNavQuerySuspenseQuery
>;
export type WorkspaceNavQueryQueryResult = Apollo.QueryResult<
  WorkspaceNavQueryQuery,
  WorkspaceNavQueryQueryVariables
>;
export const ClientWorkspacesQueryDocument = gql`
  query ClientWorkspacesQuery($clientId: ID) {
    assignments(customerId: $clientId) {
      id
      workspace {
        id
        name
        urlSlug
        active
      }
    }
  }
`;

/**
 * __useClientWorkspacesQueryQuery__
 *
 * To run a query within a React component, call `useClientWorkspacesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientWorkspacesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientWorkspacesQueryQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientWorkspacesQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClientWorkspacesQueryQuery,
    ClientWorkspacesQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientWorkspacesQueryQuery,
    ClientWorkspacesQueryQueryVariables
  >(ClientWorkspacesQueryDocument, options);
}
export function useClientWorkspacesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientWorkspacesQueryQuery,
    ClientWorkspacesQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientWorkspacesQueryQuery,
    ClientWorkspacesQueryQueryVariables
  >(ClientWorkspacesQueryDocument, options);
}
export function useClientWorkspacesQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientWorkspacesQueryQuery,
        ClientWorkspacesQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientWorkspacesQueryQuery,
    ClientWorkspacesQueryQueryVariables
  >(ClientWorkspacesQueryDocument, options);
}
export type ClientWorkspacesQueryQueryHookResult = ReturnType<
  typeof useClientWorkspacesQueryQuery
>;
export type ClientWorkspacesQueryLazyQueryHookResult = ReturnType<
  typeof useClientWorkspacesQueryLazyQuery
>;
export type ClientWorkspacesQuerySuspenseQueryHookResult = ReturnType<
  typeof useClientWorkspacesQuerySuspenseQuery
>;
export type ClientWorkspacesQueryQueryResult = Apollo.QueryResult<
  ClientWorkspacesQueryQuery,
  ClientWorkspacesQueryQueryVariables
>;
export const ClientWorkspacesQueryForStaffDocument = gql`
  query ClientWorkspacesQueryForStaff($clientId: ID) {
    clientWorkspaces(clientId: $clientId) {
      id
      name
      urlSlug
      active
    }
  }
`;

/**
 * __useClientWorkspacesQueryForStaffQuery__
 *
 * To run a query within a React component, call `useClientWorkspacesQueryForStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientWorkspacesQueryForStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientWorkspacesQueryForStaffQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientWorkspacesQueryForStaffQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClientWorkspacesQueryForStaffQuery,
    ClientWorkspacesQueryForStaffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientWorkspacesQueryForStaffQuery,
    ClientWorkspacesQueryForStaffQueryVariables
  >(ClientWorkspacesQueryForStaffDocument, options);
}
export function useClientWorkspacesQueryForStaffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientWorkspacesQueryForStaffQuery,
    ClientWorkspacesQueryForStaffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientWorkspacesQueryForStaffQuery,
    ClientWorkspacesQueryForStaffQueryVariables
  >(ClientWorkspacesQueryForStaffDocument, options);
}
export function useClientWorkspacesQueryForStaffSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientWorkspacesQueryForStaffQuery,
        ClientWorkspacesQueryForStaffQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientWorkspacesQueryForStaffQuery,
    ClientWorkspacesQueryForStaffQueryVariables
  >(ClientWorkspacesQueryForStaffDocument, options);
}
export type ClientWorkspacesQueryForStaffQueryHookResult = ReturnType<
  typeof useClientWorkspacesQueryForStaffQuery
>;
export type ClientWorkspacesQueryForStaffLazyQueryHookResult = ReturnType<
  typeof useClientWorkspacesQueryForStaffLazyQuery
>;
export type ClientWorkspacesQueryForStaffSuspenseQueryHookResult = ReturnType<
  typeof useClientWorkspacesQueryForStaffSuspenseQuery
>;
export type ClientWorkspacesQueryForStaffQueryResult = Apollo.QueryResult<
  ClientWorkspacesQueryForStaffQuery,
  ClientWorkspacesQueryForStaffQueryVariables
>;
export const CoachQueryDocument = gql`
  query CoachQuery {
    coaches {
      id
      name
    }
  }
`;

/**
 * __useCoachQueryQuery__
 *
 * To run a query within a React component, call `useCoachQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachQueryQuery,
    CoachQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachQueryQuery, CoachQueryQueryVariables>(
    CoachQueryDocument,
    options,
  );
}
export function useCoachQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachQueryQuery,
    CoachQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachQueryQuery, CoachQueryQueryVariables>(
    CoachQueryDocument,
    options,
  );
}
export function useCoachQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachQueryQuery,
        CoachQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoachQueryQuery, CoachQueryQueryVariables>(
    CoachQueryDocument,
    options,
  );
}
export type CoachQueryQueryHookResult = ReturnType<typeof useCoachQueryQuery>;
export type CoachQueryLazyQueryHookResult = ReturnType<
  typeof useCoachQueryLazyQuery
>;
export type CoachQuerySuspenseQueryHookResult = ReturnType<
  typeof useCoachQuerySuspenseQuery
>;
export type CoachQueryQueryResult = Apollo.QueryResult<
  CoachQueryQuery,
  CoachQueryQueryVariables
>;
export const WorkspaceQueryDocument = gql`
  query WorkspaceQuery {
    workspaces {
      id
      name
    }
  }
`;

/**
 * __useWorkspaceQueryQuery__
 *
 * To run a query within a React component, call `useWorkspaceQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceQueryQuery,
    WorkspaceQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkspaceQueryQuery, WorkspaceQueryQueryVariables>(
    WorkspaceQueryDocument,
    options,
  );
}
export function useWorkspaceQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceQueryQuery,
    WorkspaceQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkspaceQueryQuery, WorkspaceQueryQueryVariables>(
    WorkspaceQueryDocument,
    options,
  );
}
export function useWorkspaceQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceQueryQuery,
        WorkspaceQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceQueryQuery,
    WorkspaceQueryQueryVariables
  >(WorkspaceQueryDocument, options);
}
export type WorkspaceQueryQueryHookResult = ReturnType<
  typeof useWorkspaceQueryQuery
>;
export type WorkspaceQueryLazyQueryHookResult = ReturnType<
  typeof useWorkspaceQueryLazyQuery
>;
export type WorkspaceQuerySuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceQuerySuspenseQuery
>;
export type WorkspaceQueryQueryResult = Apollo.QueryResult<
  WorkspaceQueryQuery,
  WorkspaceQueryQueryVariables
>;
export const CustomerProfileDocument = gql`
  query CustomerProfile($id: ID!, $workspaceId: ID) {
    customer(id: $id, workspaceId: $workspaceId) {
      id
      name
      email
      phoneNumber
      photo
      jobTitle
      linkedinAccount
      timeZone
      resumes {
        id
        name
        updatedAt
      }
      intakeForm {
        id
        completedAt
        matchingOptions {
          id
          name
          visible
          matchingCategories {
            id
            name
          }
        }
        industriesSkipped
        jobTitlesSkipped
        jobFunctionsSkipped
        noteForCoach
        industries {
          id
          name
        }
        jobTitles {
          normalizedTitle
          title
        }
        jobFunctions {
          id
          name
        }
      }
      coachAssignments {
        id
        coach {
          id
          name
        }
      }
      ...UserConfigurationFragment
    }
  }
  ${UserConfigurationFragmentFragmentDoc}
`;

/**
 * __useCustomerProfileQuery__
 *
 * To run a query within a React component, call `useCustomerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useCustomerProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerProfileQuery,
    CustomerProfileQueryVariables
  > &
    (
      | { variables: CustomerProfileQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerProfileQuery, CustomerProfileQueryVariables>(
    CustomerProfileDocument,
    options,
  );
}
export function useCustomerProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerProfileQuery,
    CustomerProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomerProfileQuery,
    CustomerProfileQueryVariables
  >(CustomerProfileDocument, options);
}
export function useCustomerProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CustomerProfileQuery,
        CustomerProfileQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CustomerProfileQuery,
    CustomerProfileQueryVariables
  >(CustomerProfileDocument, options);
}
export type CustomerProfileQueryHookResult = ReturnType<
  typeof useCustomerProfileQuery
>;
export type CustomerProfileLazyQueryHookResult = ReturnType<
  typeof useCustomerProfileLazyQuery
>;
export type CustomerProfileSuspenseQueryHookResult = ReturnType<
  typeof useCustomerProfileSuspenseQuery
>;
export type CustomerProfileQueryResult = Apollo.QueryResult<
  CustomerProfileQuery,
  CustomerProfileQueryVariables
>;
export const AssignCoachesDocument = gql`
  mutation AssignCoaches($customerId: ID!, $coachIds: [ID]!) {
    assignCoaches(customerId: $customerId, coachIds: $coachIds) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type AssignCoachesMutationFn = Apollo.MutationFunction<
  AssignCoachesMutation,
  AssignCoachesMutationVariables
>;

/**
 * __useAssignCoachesMutation__
 *
 * To run a mutation, you first call `useAssignCoachesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCoachesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCoachesMutation, { data, loading, error }] = useAssignCoachesMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      coachIds: // value for 'coachIds'
 *   },
 * });
 */
export function useAssignCoachesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignCoachesMutation,
    AssignCoachesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignCoachesMutation,
    AssignCoachesMutationVariables
  >(AssignCoachesDocument, options);
}
export type AssignCoachesMutationHookResult = ReturnType<
  typeof useAssignCoachesMutation
>;
export type AssignCoachesMutationResult =
  Apollo.MutationResult<AssignCoachesMutation>;
export type AssignCoachesMutationOptions = Apollo.BaseMutationOptions<
  AssignCoachesMutation,
  AssignCoachesMutationVariables
>;
export const ClientNotesQueryDocument = gql`
  query ClientNotesQuery($customerId: ID!) {
    clientNotes(customerId: $customerId) {
      id
      content
      updatedAt
      createdAt
      author {
        id
        name
      }
    }
  }
`;

/**
 * __useClientNotesQueryQuery__
 *
 * To run a query within a React component, call `useClientNotesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientNotesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientNotesQueryQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useClientNotesQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientNotesQueryQuery,
    ClientNotesQueryQueryVariables
  > &
    (
      | { variables: ClientNotesQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientNotesQueryQuery, ClientNotesQueryQueryVariables>(
    ClientNotesQueryDocument,
    options,
  );
}
export function useClientNotesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientNotesQueryQuery,
    ClientNotesQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientNotesQueryQuery,
    ClientNotesQueryQueryVariables
  >(ClientNotesQueryDocument, options);
}
export function useClientNotesQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientNotesQueryQuery,
        ClientNotesQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientNotesQueryQuery,
    ClientNotesQueryQueryVariables
  >(ClientNotesQueryDocument, options);
}
export type ClientNotesQueryQueryHookResult = ReturnType<
  typeof useClientNotesQueryQuery
>;
export type ClientNotesQueryLazyQueryHookResult = ReturnType<
  typeof useClientNotesQueryLazyQuery
>;
export type ClientNotesQuerySuspenseQueryHookResult = ReturnType<
  typeof useClientNotesQuerySuspenseQuery
>;
export type ClientNotesQueryQueryResult = Apollo.QueryResult<
  ClientNotesQueryQuery,
  ClientNotesQueryQueryVariables
>;
export const DeleteClientNoteDocument = gql`
  mutation DeleteClientNote($noteId: ID!) {
    deleteClientNote(noteId: $noteId) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type DeleteClientNoteMutationFn = Apollo.MutationFunction<
  DeleteClientNoteMutation,
  DeleteClientNoteMutationVariables
>;

/**
 * __useDeleteClientNoteMutation__
 *
 * To run a mutation, you first call `useDeleteClientNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientNoteMutation, { data, loading, error }] = useDeleteClientNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteClientNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientNoteMutation,
    DeleteClientNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientNoteMutation,
    DeleteClientNoteMutationVariables
  >(DeleteClientNoteDocument, options);
}
export type DeleteClientNoteMutationHookResult = ReturnType<
  typeof useDeleteClientNoteMutation
>;
export type DeleteClientNoteMutationResult =
  Apollo.MutationResult<DeleteClientNoteMutation>;
export type DeleteClientNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientNoteMutation,
  DeleteClientNoteMutationVariables
>;
export const SaveClientNoteDocument = gql`
  mutation SaveClientNote(
    $noteId: ID
    $customerId: ID!
    $noteContent: String!
  ) {
    saveClientNote(
      noteId: $noteId
      customerId: $customerId
      noteContent: $noteContent
    ) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type SaveClientNoteMutationFn = Apollo.MutationFunction<
  SaveClientNoteMutation,
  SaveClientNoteMutationVariables
>;

/**
 * __useSaveClientNoteMutation__
 *
 * To run a mutation, you first call `useSaveClientNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClientNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClientNoteMutation, { data, loading, error }] = useSaveClientNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      customerId: // value for 'customerId'
 *      noteContent: // value for 'noteContent'
 *   },
 * });
 */
export function useSaveClientNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveClientNoteMutation,
    SaveClientNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveClientNoteMutation,
    SaveClientNoteMutationVariables
  >(SaveClientNoteDocument, options);
}
export type SaveClientNoteMutationHookResult = ReturnType<
  typeof useSaveClientNoteMutation
>;
export type SaveClientNoteMutationResult =
  Apollo.MutationResult<SaveClientNoteMutation>;
export type SaveClientNoteMutationOptions = Apollo.BaseMutationOptions<
  SaveClientNoteMutation,
  SaveClientNoteMutationVariables
>;
export const StaffReviewsDocument = gql`
  query StaffReviews($customerId: ID!, $workspaceId: ID) {
    customer(id: $customerId, workspaceId: $workspaceId) {
      id
      name
      email
      ...UserAvailableCreditsFragment
      ...UserReviewsFragment
      ...UserConfigurationFragment
    }
  }
  ${UserAvailableCreditsFragmentFragmentDoc}
  ${UserReviewsFragmentFragmentDoc}
  ${ReviewFragmentFragmentDoc}
  ${UserConfigurationFragmentFragmentDoc}
`;

/**
 * __useStaffReviewsQuery__
 *
 * To run a query within a React component, call `useStaffReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffReviewsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useStaffReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    StaffReviewsQuery,
    StaffReviewsQueryVariables
  > &
    (
      | { variables: StaffReviewsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaffReviewsQuery, StaffReviewsQueryVariables>(
    StaffReviewsDocument,
    options,
  );
}
export function useStaffReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StaffReviewsQuery,
    StaffReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaffReviewsQuery, StaffReviewsQueryVariables>(
    StaffReviewsDocument,
    options,
  );
}
export function useStaffReviewsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        StaffReviewsQuery,
        StaffReviewsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StaffReviewsQuery, StaffReviewsQueryVariables>(
    StaffReviewsDocument,
    options,
  );
}
export type StaffReviewsQueryHookResult = ReturnType<
  typeof useStaffReviewsQuery
>;
export type StaffReviewsLazyQueryHookResult = ReturnType<
  typeof useStaffReviewsLazyQuery
>;
export type StaffReviewsSuspenseQueryHookResult = ReturnType<
  typeof useStaffReviewsSuspenseQuery
>;
export type StaffReviewsQueryResult = Apollo.QueryResult<
  StaffReviewsQuery,
  StaffReviewsQueryVariables
>;
export const StaffSessionsDocument = gql`
  query StaffSessions($customerId: ID!, $workspaceId: ID) {
    customer(id: $customerId, workspaceId: $workspaceId) {
      id
      name
      email
      ...UserAvailableCreditsFragment
      ...UserMeetingsFragment
      ...UserConfigurationFragment
      ...UserBookingRequestMeetingsFragment
    }
  }
  ${UserAvailableCreditsFragmentFragmentDoc}
  ${UserMeetingsFragmentFragmentDoc}
  ${MeetingFragmentFragmentDoc}
  ${UserConfigurationFragmentFragmentDoc}
  ${UserBookingRequestMeetingsFragmentFragmentDoc}
`;

/**
 * __useStaffSessionsQuery__
 *
 * To run a query within a React component, call `useStaffSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffSessionsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useStaffSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    StaffSessionsQuery,
    StaffSessionsQueryVariables
  > &
    (
      | { variables: StaffSessionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaffSessionsQuery, StaffSessionsQueryVariables>(
    StaffSessionsDocument,
    options,
  );
}
export function useStaffSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StaffSessionsQuery,
    StaffSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaffSessionsQuery, StaffSessionsQueryVariables>(
    StaffSessionsDocument,
    options,
  );
}
export function useStaffSessionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        StaffSessionsQuery,
        StaffSessionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StaffSessionsQuery,
    StaffSessionsQueryVariables
  >(StaffSessionsDocument, options);
}
export type StaffSessionsQueryHookResult = ReturnType<
  typeof useStaffSessionsQuery
>;
export type StaffSessionsLazyQueryHookResult = ReturnType<
  typeof useStaffSessionsLazyQuery
>;
export type StaffSessionsSuspenseQueryHookResult = ReturnType<
  typeof useStaffSessionsSuspenseQuery
>;
export type StaffSessionsQueryResult = Apollo.QueryResult<
  StaffSessionsQuery,
  StaffSessionsQueryVariables
>;
export const PublicWorkspaceDocument = gql`
  query PublicWorkspace($urlSlug: String!) {
    publicWorkspace(urlSlug: $urlSlug) {
      id
      name
      urlSlug
      logo
      isUsingSso
    }
  }
`;

/**
 * __usePublicWorkspaceQuery__
 *
 * To run a query within a React component, call `usePublicWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicWorkspaceQuery({
 *   variables: {
 *      urlSlug: // value for 'urlSlug'
 *   },
 * });
 */
export function usePublicWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicWorkspaceQuery,
    PublicWorkspaceQueryVariables
  > &
    (
      | { variables: PublicWorkspaceQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PublicWorkspaceQuery, PublicWorkspaceQueryVariables>(
    PublicWorkspaceDocument,
    options,
  );
}
export function usePublicWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicWorkspaceQuery,
    PublicWorkspaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicWorkspaceQuery,
    PublicWorkspaceQueryVariables
  >(PublicWorkspaceDocument, options);
}
export function usePublicWorkspaceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PublicWorkspaceQuery,
        PublicWorkspaceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PublicWorkspaceQuery,
    PublicWorkspaceQueryVariables
  >(PublicWorkspaceDocument, options);
}
export type PublicWorkspaceQueryHookResult = ReturnType<
  typeof usePublicWorkspaceQuery
>;
export type PublicWorkspaceLazyQueryHookResult = ReturnType<
  typeof usePublicWorkspaceLazyQuery
>;
export type PublicWorkspaceSuspenseQueryHookResult = ReturnType<
  typeof usePublicWorkspaceSuspenseQuery
>;
export type PublicWorkspaceQueryResult = Apollo.QueryResult<
  PublicWorkspaceQuery,
  PublicWorkspaceQueryVariables
>;
export const ActivateWorkspaceMembershipDocument = gql`
  mutation ActivateWorkspaceMembership(
    $name: String!
    $password: String!
    $workspace: String!
  ) {
    activateWorkspaceMembership(
      name: $name
      password: $password
      workspace: $workspace
    ) {
      ok
      sesameQueryParam
    }
  }
`;
export type ActivateWorkspaceMembershipMutationFn = Apollo.MutationFunction<
  ActivateWorkspaceMembershipMutation,
  ActivateWorkspaceMembershipMutationVariables
>;

/**
 * __useActivateWorkspaceMembershipMutation__
 *
 * To run a mutation, you first call `useActivateWorkspaceMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateWorkspaceMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateWorkspaceMembershipMutation, { data, loading, error }] = useActivateWorkspaceMembershipMutation({
 *   variables: {
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      workspace: // value for 'workspace'
 *   },
 * });
 */
export function useActivateWorkspaceMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateWorkspaceMembershipMutation,
    ActivateWorkspaceMembershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateWorkspaceMembershipMutation,
    ActivateWorkspaceMembershipMutationVariables
  >(ActivateWorkspaceMembershipDocument, options);
}
export type ActivateWorkspaceMembershipMutationHookResult = ReturnType<
  typeof useActivateWorkspaceMembershipMutation
>;
export type ActivateWorkspaceMembershipMutationResult =
  Apollo.MutationResult<ActivateWorkspaceMembershipMutation>;
export type ActivateWorkspaceMembershipMutationOptions =
  Apollo.BaseMutationOptions<
    ActivateWorkspaceMembershipMutation,
    ActivateWorkspaceMembershipMutationVariables
  >;
export const CreateWorkspaceDocument = gql`
  mutation CreateWorkspace($name: String!, $slug: String!, $refCode: String) {
    createWorkspace(name: $name, slug: $slug, refCode: $refCode) {
      ok
      workspace {
        id
        name
        urlSlug
      }
      workspaceSignedToken
      error {
        field
        message
      }
    }
  }
`;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *      refCode: // value for 'refCode'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkspaceMutation,
    CreateWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWorkspaceMutation,
    CreateWorkspaceMutationVariables
  >(CreateWorkspaceDocument, options);
}
export type CreateWorkspaceMutationHookResult = ReturnType<
  typeof useCreateWorkspaceMutation
>;
export type CreateWorkspaceMutationResult =
  Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;
export const ValidSlugDocument = gql`
  query ValidSlug($slug: String!) {
    validSlug(slug: $slug) {
      valid
      errorMessage
    }
  }
`;

/**
 * __useValidSlugQuery__
 *
 * To run a query within a React component, call `useValidSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useValidSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidSlugQuery,
    ValidSlugQueryVariables
  > &
    (
      | { variables: ValidSlugQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidSlugQuery, ValidSlugQueryVariables>(
    ValidSlugDocument,
    options,
  );
}
export function useValidSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidSlugQuery,
    ValidSlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidSlugQuery, ValidSlugQueryVariables>(
    ValidSlugDocument,
    options,
  );
}
export function useValidSlugSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ValidSlugQuery, ValidSlugQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ValidSlugQuery, ValidSlugQueryVariables>(
    ValidSlugDocument,
    options,
  );
}
export type ValidSlugQueryHookResult = ReturnType<typeof useValidSlugQuery>;
export type ValidSlugLazyQueryHookResult = ReturnType<
  typeof useValidSlugLazyQuery
>;
export type ValidSlugSuspenseQueryHookResult = ReturnType<
  typeof useValidSlugSuspenseQuery
>;
export type ValidSlugQueryResult = Apollo.QueryResult<
  ValidSlugQuery,
  ValidSlugQueryVariables
>;
export const FaqsDocument = gql`
  query Faqs($domain: String!) {
    faqs(domain: $domain) {
      category
      order
      questions {
        question
        order
        answer
      }
    }
  }
`;

/**
 * __useFaqsQuery__
 *
 * To run a query within a React component, call `useFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqsQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useFaqsQuery(
  baseOptions: Apollo.QueryHookOptions<FaqsQuery, FaqsQueryVariables> &
    ({ variables: FaqsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FaqsQuery, FaqsQueryVariables>(FaqsDocument, options);
}
export function useFaqsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FaqsQuery, FaqsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FaqsQuery, FaqsQueryVariables>(
    FaqsDocument,
    options,
  );
}
export function useFaqsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FaqsQuery, FaqsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FaqsQuery, FaqsQueryVariables>(
    FaqsDocument,
    options,
  );
}
export type FaqsQueryHookResult = ReturnType<typeof useFaqsQuery>;
export type FaqsLazyQueryHookResult = ReturnType<typeof useFaqsLazyQuery>;
export type FaqsSuspenseQueryHookResult = ReturnType<
  typeof useFaqsSuspenseQuery
>;
export type FaqsQueryResult = Apollo.QueryResult<FaqsQuery, FaqsQueryVariables>;
export const LandingPageDocument = gql`
  query LandingPage($slug: String!) {
    landingPageVariant(urlSlug: $slug) {
      basePage
      urlSlug
      headline
      headlineFontSize
      subHeader
    }
  }
`;

/**
 * __useLandingPageQuery__
 *
 * To run a query within a React component, call `useLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useLandingPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    LandingPageQuery,
    LandingPageQueryVariables
  > &
    (
      | { variables: LandingPageQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingPageQuery, LandingPageQueryVariables>(
    LandingPageDocument,
    options,
  );
}
export function useLandingPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LandingPageQuery,
    LandingPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingPageQuery, LandingPageQueryVariables>(
    LandingPageDocument,
    options,
  );
}
export function useLandingPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LandingPageQuery,
        LandingPageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LandingPageQuery, LandingPageQueryVariables>(
    LandingPageDocument,
    options,
  );
}
export type LandingPageQueryHookResult = ReturnType<typeof useLandingPageQuery>;
export type LandingPageLazyQueryHookResult = ReturnType<
  typeof useLandingPageLazyQuery
>;
export type LandingPageSuspenseQueryHookResult = ReturnType<
  typeof useLandingPageSuspenseQuery
>;
export type LandingPageQueryResult = Apollo.QueryResult<
  LandingPageQuery,
  LandingPageQueryVariables
>;
export const SsoGenerateUrlDocument = gql`
  mutation SsoGenerateUrl($workspaceSlug: String!) {
    ssoGenerateUrl(workspaceSlug: $workspaceSlug) {
      authUrl
    }
  }
`;
export type SsoGenerateUrlMutationFn = Apollo.MutationFunction<
  SsoGenerateUrlMutation,
  SsoGenerateUrlMutationVariables
>;

/**
 * __useSsoGenerateUrlMutation__
 *
 * To run a mutation, you first call `useSsoGenerateUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSsoGenerateUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ssoGenerateUrlMutation, { data, loading, error }] = useSsoGenerateUrlMutation({
 *   variables: {
 *      workspaceSlug: // value for 'workspaceSlug'
 *   },
 * });
 */
export function useSsoGenerateUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SsoGenerateUrlMutation,
    SsoGenerateUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SsoGenerateUrlMutation,
    SsoGenerateUrlMutationVariables
  >(SsoGenerateUrlDocument, options);
}
export type SsoGenerateUrlMutationHookResult = ReturnType<
  typeof useSsoGenerateUrlMutation
>;
export type SsoGenerateUrlMutationResult =
  Apollo.MutationResult<SsoGenerateUrlMutation>;
export type SsoGenerateUrlMutationOptions = Apollo.BaseMutationOptions<
  SsoGenerateUrlMutation,
  SsoGenerateUrlMutationVariables
>;
export const ArticleDocument = gql`
  query Article($slug: String!) {
    article(slug: $slug) {
      id
      updatedAt
      title
      timeToRead
      summary
      heroImage
      urlSlug
      body
      public
      noindex
      authorName
      authorPhoto
      authorShortBio
      authorUrl
      authorIsCoach
      canonicalDomain
      author {
        isAgent
        agentProfile {
          id
          coachSlug
        }
      }
      primarySeries {
        id
        title
        urlSlug
      }
      sidebarCta {
        id
        headline
        body
        ctaLink
        buttonText
      }
    }
    articleReadStatus(slug: $slug) {
      id
      readStatus
    }
  }
`;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArticleQuery(
  baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables> &
    ({ variables: ArticleQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(
    ArticleDocument,
    options,
  );
}
export function useArticleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleQuery,
    ArticleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(
    ArticleDocument,
    options,
  );
}
export function useArticleSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ArticleQuery, ArticleQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ArticleQuery, ArticleQueryVariables>(
    ArticleDocument,
    options,
  );
}
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleSuspenseQueryHookResult = ReturnType<
  typeof useArticleSuspenseQuery
>;
export type ArticleQueryResult = Apollo.QueryResult<
  ArticleQuery,
  ArticleQueryVariables
>;
export const BitsAvailableDocument = gql`
  query BitsAvailable($loggedIn: Boolean!) {
    userCredit @include(if: $loggedIn) {
      id
      bitsAvailable
    }
    optionalCurrentUser {
      id
      isConfirmed
      emailProvider
    }
  }
`;

/**
 * __useBitsAvailableQuery__
 *
 * To run a query within a React component, call `useBitsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useBitsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitsAvailableQuery({
 *   variables: {
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useBitsAvailableQuery(
  baseOptions: Apollo.QueryHookOptions<
    BitsAvailableQuery,
    BitsAvailableQueryVariables
  > &
    (
      | { variables: BitsAvailableQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BitsAvailableQuery, BitsAvailableQueryVariables>(
    BitsAvailableDocument,
    options,
  );
}
export function useBitsAvailableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BitsAvailableQuery,
    BitsAvailableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BitsAvailableQuery, BitsAvailableQueryVariables>(
    BitsAvailableDocument,
    options,
  );
}
export function useBitsAvailableSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BitsAvailableQuery,
        BitsAvailableQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BitsAvailableQuery,
    BitsAvailableQueryVariables
  >(BitsAvailableDocument, options);
}
export type BitsAvailableQueryHookResult = ReturnType<
  typeof useBitsAvailableQuery
>;
export type BitsAvailableLazyQueryHookResult = ReturnType<
  typeof useBitsAvailableLazyQuery
>;
export type BitsAvailableSuspenseQueryHookResult = ReturnType<
  typeof useBitsAvailableSuspenseQuery
>;
export type BitsAvailableQueryResult = Apollo.QueryResult<
  BitsAvailableQuery,
  BitsAvailableQueryVariables
>;
export const DeleteGptResponseDocument = gql`
  mutation DeleteGptResponse($gptResponseUuid: String!) {
    deleteGptResponse(gptResponseUuid: $gptResponseUuid) {
      ok
    }
  }
`;
export type DeleteGptResponseMutationFn = Apollo.MutationFunction<
  DeleteGptResponseMutation,
  DeleteGptResponseMutationVariables
>;

/**
 * __useDeleteGptResponseMutation__
 *
 * To run a mutation, you first call `useDeleteGptResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGptResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGptResponseMutation, { data, loading, error }] = useDeleteGptResponseMutation({
 *   variables: {
 *      gptResponseUuid: // value for 'gptResponseUuid'
 *   },
 * });
 */
export function useDeleteGptResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGptResponseMutation,
    DeleteGptResponseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGptResponseMutation,
    DeleteGptResponseMutationVariables
  >(DeleteGptResponseDocument, options);
}
export type DeleteGptResponseMutationHookResult = ReturnType<
  typeof useDeleteGptResponseMutation
>;
export type DeleteGptResponseMutationResult =
  Apollo.MutationResult<DeleteGptResponseMutation>;
export type DeleteGptResponseMutationOptions = Apollo.BaseMutationOptions<
  DeleteGptResponseMutation,
  DeleteGptResponseMutationVariables
>;
export const ExecuteGptPromptDocument = gql`
  mutation ExecuteGptPrompt($entryId: ID!, $answers: GenericScalar!) {
    executeGptPrompt(entryId: $entryId, answers: $answers) {
      gptResponse {
        ...GptResponseFragment
      }
      error {
        code
      }
    }
  }
  ${GptResponseFragmentFragmentDoc}
`;
export type ExecuteGptPromptMutationFn = Apollo.MutationFunction<
  ExecuteGptPromptMutation,
  ExecuteGptPromptMutationVariables
>;

/**
 * __useExecuteGptPromptMutation__
 *
 * To run a mutation, you first call `useExecuteGptPromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteGptPromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeGptPromptMutation, { data, loading, error }] = useExecuteGptPromptMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useExecuteGptPromptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExecuteGptPromptMutation,
    ExecuteGptPromptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExecuteGptPromptMutation,
    ExecuteGptPromptMutationVariables
  >(ExecuteGptPromptDocument, options);
}
export type ExecuteGptPromptMutationHookResult = ReturnType<
  typeof useExecuteGptPromptMutation
>;
export type ExecuteGptPromptMutationResult =
  Apollo.MutationResult<ExecuteGptPromptMutation>;
export type ExecuteGptPromptMutationOptions = Apollo.BaseMutationOptions<
  ExecuteGptPromptMutation,
  ExecuteGptPromptMutationVariables
>;
export const GptResponseDocument = gql`
  query GptResponse($gptResponseUuid: String!) {
    gptResponse(gptResponseUuid: $gptResponseUuid) {
      ...GptResponseFragment
    }
  }
  ${GptResponseFragmentFragmentDoc}
`;

/**
 * __useGptResponseQuery__
 *
 * To run a query within a React component, call `useGptResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGptResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGptResponseQuery({
 *   variables: {
 *      gptResponseUuid: // value for 'gptResponseUuid'
 *   },
 * });
 */
export function useGptResponseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GptResponseQuery,
    GptResponseQueryVariables
  > &
    (
      | { variables: GptResponseQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GptResponseQuery, GptResponseQueryVariables>(
    GptResponseDocument,
    options,
  );
}
export function useGptResponseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GptResponseQuery,
    GptResponseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GptResponseQuery, GptResponseQueryVariables>(
    GptResponseDocument,
    options,
  );
}
export function useGptResponseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GptResponseQuery,
        GptResponseQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GptResponseQuery, GptResponseQueryVariables>(
    GptResponseDocument,
    options,
  );
}
export type GptResponseQueryHookResult = ReturnType<typeof useGptResponseQuery>;
export type GptResponseLazyQueryHookResult = ReturnType<
  typeof useGptResponseLazyQuery
>;
export type GptResponseSuspenseQueryHookResult = ReturnType<
  typeof useGptResponseSuspenseQuery
>;
export type GptResponseQueryResult = Apollo.QueryResult<
  GptResponseQuery,
  GptResponseQueryVariables
>;
export const GptResponsesDocument = gql`
  query GptResponses($entryId: String!) {
    gptResponses(entryId: $entryId) {
      ...GptResponseFragment
    }
  }
  ${GptResponseFragmentFragmentDoc}
`;

/**
 * __useGptResponsesQuery__
 *
 * To run a query within a React component, call `useGptResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGptResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGptResponsesQuery({
 *   variables: {
 *      entryId: // value for 'entryId'
 *   },
 * });
 */
export function useGptResponsesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GptResponsesQuery,
    GptResponsesQueryVariables
  > &
    (
      | { variables: GptResponsesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GptResponsesQuery, GptResponsesQueryVariables>(
    GptResponsesDocument,
    options,
  );
}
export function useGptResponsesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GptResponsesQuery,
    GptResponsesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GptResponsesQuery, GptResponsesQueryVariables>(
    GptResponsesDocument,
    options,
  );
}
export function useGptResponsesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GptResponsesQuery,
        GptResponsesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GptResponsesQuery, GptResponsesQueryVariables>(
    GptResponsesDocument,
    options,
  );
}
export type GptResponsesQueryHookResult = ReturnType<
  typeof useGptResponsesQuery
>;
export type GptResponsesLazyQueryHookResult = ReturnType<
  typeof useGptResponsesLazyQuery
>;
export type GptResponsesSuspenseQueryHookResult = ReturnType<
  typeof useGptResponsesSuspenseQuery
>;
export type GptResponsesQueryResult = Apollo.QueryResult<
  GptResponsesQuery,
  GptResponsesQueryVariables
>;
export const RateGptResponseDocument = gql`
  mutation RateGptResponse($gptResponseUuid: String!, $rating: Boolean!) {
    rateGptResponse(gptResponseUuid: $gptResponseUuid, rating: $rating) {
      ok
    }
  }
`;
export type RateGptResponseMutationFn = Apollo.MutationFunction<
  RateGptResponseMutation,
  RateGptResponseMutationVariables
>;

/**
 * __useRateGptResponseMutation__
 *
 * To run a mutation, you first call `useRateGptResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateGptResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateGptResponseMutation, { data, loading, error }] = useRateGptResponseMutation({
 *   variables: {
 *      gptResponseUuid: // value for 'gptResponseUuid'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useRateGptResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RateGptResponseMutation,
    RateGptResponseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RateGptResponseMutation,
    RateGptResponseMutationVariables
  >(RateGptResponseDocument, options);
}
export type RateGptResponseMutationHookResult = ReturnType<
  typeof useRateGptResponseMutation
>;
export type RateGptResponseMutationResult =
  Apollo.MutationResult<RateGptResponseMutation>;
export type RateGptResponseMutationOptions = Apollo.BaseMutationOptions<
  RateGptResponseMutation,
  RateGptResponseMutationVariables
>;
export const RetryGptResponseDocument = gql`
  mutation RetryGptResponse($gptResponseUuid: String!) {
    retryGptResponse(gptResponseUuid: $gptResponseUuid) {
      ok
    }
  }
`;
export type RetryGptResponseMutationFn = Apollo.MutationFunction<
  RetryGptResponseMutation,
  RetryGptResponseMutationVariables
>;

/**
 * __useRetryGptResponseMutation__
 *
 * To run a mutation, you first call `useRetryGptResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryGptResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryGptResponseMutation, { data, loading, error }] = useRetryGptResponseMutation({
 *   variables: {
 *      gptResponseUuid: // value for 'gptResponseUuid'
 *   },
 * });
 */
export function useRetryGptResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetryGptResponseMutation,
    RetryGptResponseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RetryGptResponseMutation,
    RetryGptResponseMutationVariables
  >(RetryGptResponseDocument, options);
}
export type RetryGptResponseMutationHookResult = ReturnType<
  typeof useRetryGptResponseMutation
>;
export type RetryGptResponseMutationResult =
  Apollo.MutationResult<RetryGptResponseMutation>;
export type RetryGptResponseMutationOptions = Apollo.BaseMutationOptions<
  RetryGptResponseMutation,
  RetryGptResponseMutationVariables
>;
export const PromptAnswerDocument = gql`
  query PromptAnswer($entryId: ID!) {
    promptAnswer(entryId: $entryId) {
      id
      answer
    }
  }
`;

/**
 * __usePromptAnswerQuery__
 *
 * To run a query within a React component, call `usePromptAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromptAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromptAnswerQuery({
 *   variables: {
 *      entryId: // value for 'entryId'
 *   },
 * });
 */
export function usePromptAnswerQuery(
  baseOptions: Apollo.QueryHookOptions<
    PromptAnswerQuery,
    PromptAnswerQueryVariables
  > &
    (
      | { variables: PromptAnswerQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PromptAnswerQuery, PromptAnswerQueryVariables>(
    PromptAnswerDocument,
    options,
  );
}
export function usePromptAnswerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PromptAnswerQuery,
    PromptAnswerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PromptAnswerQuery, PromptAnswerQueryVariables>(
    PromptAnswerDocument,
    options,
  );
}
export function usePromptAnswerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PromptAnswerQuery,
        PromptAnswerQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PromptAnswerQuery, PromptAnswerQueryVariables>(
    PromptAnswerDocument,
    options,
  );
}
export type PromptAnswerQueryHookResult = ReturnType<
  typeof usePromptAnswerQuery
>;
export type PromptAnswerLazyQueryHookResult = ReturnType<
  typeof usePromptAnswerLazyQuery
>;
export type PromptAnswerSuspenseQueryHookResult = ReturnType<
  typeof usePromptAnswerSuspenseQuery
>;
export type PromptAnswerQueryResult = Apollo.QueryResult<
  PromptAnswerQuery,
  PromptAnswerQueryVariables
>;
export const ArticleSeriesByArticleQueryDocument = gql`
  query ArticleSeriesByArticleQuery($articleSlug: String!) {
    articleSeriesByArticle(articleSlug: $articleSlug) {
      id
      title
      urlSlug
    }
  }
`;

/**
 * __useArticleSeriesByArticleQueryQuery__
 *
 * To run a query within a React component, call `useArticleSeriesByArticleQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleSeriesByArticleQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleSeriesByArticleQueryQuery({
 *   variables: {
 *      articleSlug: // value for 'articleSlug'
 *   },
 * });
 */
export function useArticleSeriesByArticleQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticleSeriesByArticleQueryQuery,
    ArticleSeriesByArticleQueryQueryVariables
  > &
    (
      | { variables: ArticleSeriesByArticleQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ArticleSeriesByArticleQueryQuery,
    ArticleSeriesByArticleQueryQueryVariables
  >(ArticleSeriesByArticleQueryDocument, options);
}
export function useArticleSeriesByArticleQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleSeriesByArticleQueryQuery,
    ArticleSeriesByArticleQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ArticleSeriesByArticleQueryQuery,
    ArticleSeriesByArticleQueryQueryVariables
  >(ArticleSeriesByArticleQueryDocument, options);
}
export function useArticleSeriesByArticleQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ArticleSeriesByArticleQueryQuery,
        ArticleSeriesByArticleQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ArticleSeriesByArticleQueryQuery,
    ArticleSeriesByArticleQueryQueryVariables
  >(ArticleSeriesByArticleQueryDocument, options);
}
export type ArticleSeriesByArticleQueryQueryHookResult = ReturnType<
  typeof useArticleSeriesByArticleQueryQuery
>;
export type ArticleSeriesByArticleQueryLazyQueryHookResult = ReturnType<
  typeof useArticleSeriesByArticleQueryLazyQuery
>;
export type ArticleSeriesByArticleQuerySuspenseQueryHookResult = ReturnType<
  typeof useArticleSeriesByArticleQuerySuspenseQuery
>;
export type ArticleSeriesByArticleQueryQueryResult = Apollo.QueryResult<
  ArticleSeriesByArticleQueryQuery,
  ArticleSeriesByArticleQueryQueryVariables
>;
export const SaveArticleReadStatusDocument = gql`
  mutation SaveArticleReadStatus($articleId: ID!, $status: String!) {
    saveArticleReadStatus(articleId: $articleId, status: $status) {
      articleReadStatus {
        id
        readStatus
      }
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type SaveArticleReadStatusMutationFn = Apollo.MutationFunction<
  SaveArticleReadStatusMutation,
  SaveArticleReadStatusMutationVariables
>;

/**
 * __useSaveArticleReadStatusMutation__
 *
 * To run a mutation, you first call `useSaveArticleReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveArticleReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveArticleReadStatusMutation, { data, loading, error }] = useSaveArticleReadStatusMutation({
 *   variables: {
 *      articleId: // value for 'articleId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSaveArticleReadStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveArticleReadStatusMutation,
    SaveArticleReadStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveArticleReadStatusMutation,
    SaveArticleReadStatusMutationVariables
  >(SaveArticleReadStatusDocument, options);
}
export type SaveArticleReadStatusMutationHookResult = ReturnType<
  typeof useSaveArticleReadStatusMutation
>;
export type SaveArticleReadStatusMutationResult =
  Apollo.MutationResult<SaveArticleReadStatusMutation>;
export type SaveArticleReadStatusMutationOptions = Apollo.BaseMutationOptions<
  SaveArticleReadStatusMutation,
  SaveArticleReadStatusMutationVariables
>;
export const SavePromptAnswerDocument = gql`
  mutation SavePromptAnswer($entryId: ID!, $answer: String!) {
    savePromptAnswer(entryId: $entryId, answer: $answer) {
      created
      promptAnswer {
        id
        answer
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type SavePromptAnswerMutationFn = Apollo.MutationFunction<
  SavePromptAnswerMutation,
  SavePromptAnswerMutationVariables
>;

/**
 * __useSavePromptAnswerMutation__
 *
 * To run a mutation, you first call `useSavePromptAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePromptAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePromptAnswerMutation, { data, loading, error }] = useSavePromptAnswerMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useSavePromptAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePromptAnswerMutation,
    SavePromptAnswerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SavePromptAnswerMutation,
    SavePromptAnswerMutationVariables
  >(SavePromptAnswerDocument, options);
}
export type SavePromptAnswerMutationHookResult = ReturnType<
  typeof useSavePromptAnswerMutation
>;
export type SavePromptAnswerMutationResult =
  Apollo.MutationResult<SavePromptAnswerMutation>;
export type SavePromptAnswerMutationOptions = Apollo.BaseMutationOptions<
  SavePromptAnswerMutation,
  SavePromptAnswerMutationVariables
>;
export const BitPackagesDocument = gql`
  query BitPackages {
    bitPackages {
      id
      name
      msrp
      price
      creditPrice
      slogan
      description
      category
      creditValue
      introProduct
      slug
    }
  }
`;

/**
 * __useBitPackagesQuery__
 *
 * To run a query within a React component, call `useBitPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBitPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitPackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBitPackagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BitPackagesQuery,
    BitPackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BitPackagesQuery, BitPackagesQueryVariables>(
    BitPackagesDocument,
    options,
  );
}
export function useBitPackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BitPackagesQuery,
    BitPackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BitPackagesQuery, BitPackagesQueryVariables>(
    BitPackagesDocument,
    options,
  );
}
export function useBitPackagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BitPackagesQuery,
        BitPackagesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BitPackagesQuery, BitPackagesQueryVariables>(
    BitPackagesDocument,
    options,
  );
}
export type BitPackagesQueryHookResult = ReturnType<typeof useBitPackagesQuery>;
export type BitPackagesLazyQueryHookResult = ReturnType<
  typeof useBitPackagesLazyQuery
>;
export type BitPackagesSuspenseQueryHookResult = ReturnType<
  typeof useBitPackagesSuspenseQuery
>;
export type BitPackagesQueryResult = Apollo.QueryResult<
  BitPackagesQuery,
  BitPackagesQueryVariables
>;
export const CheckoutDocument = gql`
  query Checkout($customerId: ID) {
    currentUser {
      id
      firstName
      lastName
      email
    }
    paymentMethods(customerId: $customerId) {
      stripeId
      brand
      last4
    }
    ledgerAccounts(customerId: $customerId) {
      id
      label
      balance
    }
  }
`;

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCheckoutQuery(
  baseOptions?: Apollo.QueryHookOptions<CheckoutQuery, CheckoutQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckoutQuery, CheckoutQueryVariables>(
    CheckoutDocument,
    options,
  );
}
export function useCheckoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckoutQuery,
    CheckoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckoutQuery, CheckoutQueryVariables>(
    CheckoutDocument,
    options,
  );
}
export function useCheckoutSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CheckoutQuery, CheckoutQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CheckoutQuery, CheckoutQueryVariables>(
    CheckoutDocument,
    options,
  );
}
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<
  typeof useCheckoutLazyQuery
>;
export type CheckoutSuspenseQueryHookResult = ReturnType<
  typeof useCheckoutSuspenseQuery
>;
export type CheckoutQueryResult = Apollo.QueryResult<
  CheckoutQuery,
  CheckoutQueryVariables
>;
export const ConfirmOrderDocument = gql`
  mutation ConfirmOrder(
    $id: ID!
    $customerId: ID
    $paymentMethod: PaymentMethodTypes
    $affirmCheckoutToken: String
    $ledgerAccountId: ID
  ) {
    confirmOrder(
      id: $id
      customerId: $customerId
      paymentMethod: $paymentMethod
      affirmCheckoutToken: $affirmCheckoutToken
      ledgerAccountId: $ledgerAccountId
    ) {
      ok
      error {
        message
      }
    }
  }
`;
export type ConfirmOrderMutationFn = Apollo.MutationFunction<
  ConfirmOrderMutation,
  ConfirmOrderMutationVariables
>;

/**
 * __useConfirmOrderMutation__
 *
 * To run a mutation, you first call `useConfirmOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderMutation, { data, loading, error }] = useConfirmOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *      paymentMethod: // value for 'paymentMethod'
 *      affirmCheckoutToken: // value for 'affirmCheckoutToken'
 *      ledgerAccountId: // value for 'ledgerAccountId'
 *   },
 * });
 */
export function useConfirmOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmOrderMutation,
    ConfirmOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmOrderMutation,
    ConfirmOrderMutationVariables
  >(ConfirmOrderDocument, options);
}
export type ConfirmOrderMutationHookResult = ReturnType<
  typeof useConfirmOrderMutation
>;
export type ConfirmOrderMutationResult =
  Apollo.MutationResult<ConfirmOrderMutation>;
export type ConfirmOrderMutationOptions = Apollo.BaseMutationOptions<
  ConfirmOrderMutation,
  ConfirmOrderMutationVariables
>;
export const CreateOrderDocument = gql`
  mutation CreateOrder(
    $productQuantities: GenericScalar!
    $withRecommendations: Boolean!
    $withCoachInfo: Boolean!
    $key: String
    $customerId: ID
  ) {
    createOrder(
      productQuantities: $productQuantities
      key: $key
      customerId: $customerId
    ) {
      order {
        ...CheckoutOrderFragment
        coach @include(if: $withCoachInfo) {
          ...CheckoutOrderCoachFragment
        }
        recommendations @include(if: $withRecommendations) {
          id
          category
          name
          shortDescription
          creditPrice
          price
        }
      }
    }
  }
  ${CheckoutOrderFragmentFragmentDoc}
  ${UserAvailableCreditsFragmentFragmentDoc}
  ${CheckoutOrderCoachFragmentFragmentDoc}
`;
export type CreateOrderMutationFn = Apollo.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      productQuantities: // value for 'productQuantities'
 *      withRecommendations: // value for 'withRecommendations'
 *      withCoachInfo: // value for 'withCoachInfo'
 *      key: // value for 'key'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(
    CreateOrderDocument,
    options,
  );
}
export type CreateOrderMutationHookResult = ReturnType<
  typeof useCreateOrderMutation
>;
export type CreateOrderMutationResult =
  Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export const CreditPackagesDocument = gql`
  query CreditPackages($slug: String, $site: String) {
    creditPackages(slug: $slug, site: $site) {
      id
      name
      msrp
      price
      slogan
      description
      category
      creditValue
      introProduct
      slug
    }
  }
`;

/**
 * __useCreditPackagesQuery__
 *
 * To run a query within a React component, call `useCreditPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditPackagesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      site: // value for 'site'
 *   },
 * });
 */
export function useCreditPackagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CreditPackagesQuery,
    CreditPackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreditPackagesQuery, CreditPackagesQueryVariables>(
    CreditPackagesDocument,
    options,
  );
}
export function useCreditPackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreditPackagesQuery,
    CreditPackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreditPackagesQuery, CreditPackagesQueryVariables>(
    CreditPackagesDocument,
    options,
  );
}
export function useCreditPackagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CreditPackagesQuery,
        CreditPackagesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CreditPackagesQuery,
    CreditPackagesQueryVariables
  >(CreditPackagesDocument, options);
}
export type CreditPackagesQueryHookResult = ReturnType<
  typeof useCreditPackagesQuery
>;
export type CreditPackagesLazyQueryHookResult = ReturnType<
  typeof useCreditPackagesLazyQuery
>;
export type CreditPackagesSuspenseQueryHookResult = ReturnType<
  typeof useCreditPackagesSuspenseQuery
>;
export type CreditPackagesQueryResult = Apollo.QueryResult<
  CreditPackagesQuery,
  CreditPackagesQueryVariables
>;
export const OrderDocument = gql`
  query Order($id: ID!, $customerId: ID) {
    order(id: $id, customerId: $customerId) {
      ...CheckoutOrderFragment
      workspace {
        id
        name
        urlSlug
      }
    }
  }
  ${CheckoutOrderFragmentFragmentDoc}
  ${UserAvailableCreditsFragmentFragmentDoc}
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables> &
    ({ variables: OrderQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options,
  );
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options,
  );
}
export function useOrderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options,
  );
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderSuspenseQueryHookResult = ReturnType<
  typeof useOrderSuspenseQuery
>;
export type OrderQueryResult = Apollo.QueryResult<
  OrderQuery,
  OrderQueryVariables
>;
export const PendingOrderDocument = gql`
  query PendingOrder(
    $id: ID
    $key: String
    $pendingOnly: Boolean
    $withRecommendations: Boolean!
    $withCoachInfo: Boolean!
    $customerId: ID
  ) {
    pendingOrder(
      id: $id
      key: $key
      customerId: $customerId
      pendingOnly: $pendingOnly
    ) {
      ...CheckoutOrderFragment
      coach @include(if: $withCoachInfo) {
        id
        name
        photo
        activeCoachProducts {
          id
          price
          product {
            id
            name
            introProduct
            specialtyId
          }
        }
      }
      recommendations @include(if: $withRecommendations) {
        id
        category
        name
        shortDescription
        creditPrice
        price
      }
    }
  }
  ${CheckoutOrderFragmentFragmentDoc}
  ${UserAvailableCreditsFragmentFragmentDoc}
`;

/**
 * __usePendingOrderQuery__
 *
 * To run a query within a React component, call `usePendingOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      key: // value for 'key'
 *      pendingOnly: // value for 'pendingOnly'
 *      withRecommendations: // value for 'withRecommendations'
 *      withCoachInfo: // value for 'withCoachInfo'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function usePendingOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    PendingOrderQuery,
    PendingOrderQueryVariables
  > &
    (
      | { variables: PendingOrderQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PendingOrderQuery, PendingOrderQueryVariables>(
    PendingOrderDocument,
    options,
  );
}
export function usePendingOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingOrderQuery,
    PendingOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PendingOrderQuery, PendingOrderQueryVariables>(
    PendingOrderDocument,
    options,
  );
}
export function usePendingOrderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PendingOrderQuery,
        PendingOrderQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PendingOrderQuery, PendingOrderQueryVariables>(
    PendingOrderDocument,
    options,
  );
}
export type PendingOrderQueryHookResult = ReturnType<
  typeof usePendingOrderQuery
>;
export type PendingOrderLazyQueryHookResult = ReturnType<
  typeof usePendingOrderLazyQuery
>;
export type PendingOrderSuspenseQueryHookResult = ReturnType<
  typeof usePendingOrderSuspenseQuery
>;
export type PendingOrderQueryResult = Apollo.QueryResult<
  PendingOrderQuery,
  PendingOrderQueryVariables
>;
export const RedeemPromoCodeDocument = gql`
  mutation RedeemPromoCode($orderId: ID!, $code: String!) {
    redeemPromoCode(orderId: $orderId, code: $code) {
      ok
      error {
        message
      }
      order {
        ...CheckoutOrderFragment
        coach {
          ...CheckoutOrderCoachFragment
        }
      }
    }
  }
  ${CheckoutOrderFragmentFragmentDoc}
  ${UserAvailableCreditsFragmentFragmentDoc}
  ${CheckoutOrderCoachFragmentFragmentDoc}
`;
export type RedeemPromoCodeMutationFn = Apollo.MutationFunction<
  RedeemPromoCodeMutation,
  RedeemPromoCodeMutationVariables
>;

/**
 * __useRedeemPromoCodeMutation__
 *
 * To run a mutation, you first call `useRedeemPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPromoCodeMutation, { data, loading, error }] = useRedeemPromoCodeMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useRedeemPromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedeemPromoCodeMutation,
    RedeemPromoCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RedeemPromoCodeMutation,
    RedeemPromoCodeMutationVariables
  >(RedeemPromoCodeDocument, options);
}
export type RedeemPromoCodeMutationHookResult = ReturnType<
  typeof useRedeemPromoCodeMutation
>;
export type RedeemPromoCodeMutationResult =
  Apollo.MutationResult<RedeemPromoCodeMutation>;
export type RedeemPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  RedeemPromoCodeMutation,
  RedeemPromoCodeMutationVariables
>;
export const SetExtraCreditsDocument = gql`
  mutation SetExtraCredits($orderId: ID!, $numCredits: String!) {
    setExtraCredits(orderId: $orderId, numCredits: $numCredits) {
      ok
      error {
        message
      }
      order {
        ...CheckoutOrderFragment
        coach {
          ...CheckoutOrderCoachFragment
        }
      }
    }
  }
  ${CheckoutOrderFragmentFragmentDoc}
  ${UserAvailableCreditsFragmentFragmentDoc}
  ${CheckoutOrderCoachFragmentFragmentDoc}
`;
export type SetExtraCreditsMutationFn = Apollo.MutationFunction<
  SetExtraCreditsMutation,
  SetExtraCreditsMutationVariables
>;

/**
 * __useSetExtraCreditsMutation__
 *
 * To run a mutation, you first call `useSetExtraCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExtraCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExtraCreditsMutation, { data, loading, error }] = useSetExtraCreditsMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      numCredits: // value for 'numCredits'
 *   },
 * });
 */
export function useSetExtraCreditsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetExtraCreditsMutation,
    SetExtraCreditsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetExtraCreditsMutation,
    SetExtraCreditsMutationVariables
  >(SetExtraCreditsDocument, options);
}
export type SetExtraCreditsMutationHookResult = ReturnType<
  typeof useSetExtraCreditsMutation
>;
export type SetExtraCreditsMutationResult =
  Apollo.MutationResult<SetExtraCreditsMutation>;
export type SetExtraCreditsMutationOptions = Apollo.BaseMutationOptions<
  SetExtraCreditsMutation,
  SetExtraCreditsMutationVariables
>;
export const UpdateOrderDocument = gql`
  mutation UpdateOrder(
    $id: ID!
    $withRecommendations: Boolean!
    $withCoachInfo: Boolean!
    $productQuantities: GenericScalar!
    $customerId: ID
  ) {
    updateOrder(
      id: $id
      productQuantities: $productQuantities
      customerId: $customerId
    ) {
      order {
        ...CheckoutOrderFragment
        coach @include(if: $withCoachInfo) {
          ...CheckoutOrderCoachFragment
        }
        recommendations @include(if: $withRecommendations) {
          id
          category
          name
          shortDescription
          creditPrice
          price
        }
      }
    }
  }
  ${CheckoutOrderFragmentFragmentDoc}
  ${UserAvailableCreditsFragmentFragmentDoc}
  ${CheckoutOrderCoachFragmentFragmentDoc}
`;
export type UpdateOrderMutationFn = Apollo.MutationFunction<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      withRecommendations: // value for 'withRecommendations'
 *      withCoachInfo: // value for 'withCoachInfo'
 *      productQuantities: // value for 'productQuantities'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUpdateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderMutation,
    UpdateOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(
    UpdateOrderDocument,
    options,
  );
}
export type UpdateOrderMutationHookResult = ReturnType<
  typeof useUpdateOrderMutation
>;
export type UpdateOrderMutationResult =
  Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;
export const AccountLinkDocument = gql`
  mutation AccountLink($returnUrl: String!) {
    accountLink(returnUrl: $returnUrl) {
      url
      errors {
        field
        message
      }
    }
  }
`;
export type AccountLinkMutationFn = Apollo.MutationFunction<
  AccountLinkMutation,
  AccountLinkMutationVariables
>;

/**
 * __useAccountLinkMutation__
 *
 * To run a mutation, you first call `useAccountLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountLinkMutation, { data, loading, error }] = useAccountLinkMutation({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useAccountLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccountLinkMutation,
    AccountLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AccountLinkMutation, AccountLinkMutationVariables>(
    AccountLinkDocument,
    options,
  );
}
export type AccountLinkMutationHookResult = ReturnType<
  typeof useAccountLinkMutation
>;
export type AccountLinkMutationResult =
  Apollo.MutationResult<AccountLinkMutation>;
export type AccountLinkMutationOptions = Apollo.BaseMutationOptions<
  AccountLinkMutation,
  AccountLinkMutationVariables
>;
export const ActiveCoachProductsForCustomerDocument = gql`
  query ActiveCoachProductsForCustomer(
    $userId: ID!
    $workspaceId: ID
    $coachId: ID!
  ) {
    activeCoachProductsForCustomer(
      userId: $userId
      workspaceId: $workspaceId
      coachId: $coachId
    ) {
      id
      product {
        id
      }
      price
      locked
    }
  }
`;

/**
 * __useActiveCoachProductsForCustomerQuery__
 *
 * To run a query within a React component, call `useActiveCoachProductsForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveCoachProductsForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveCoachProductsForCustomerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      workspaceId: // value for 'workspaceId'
 *      coachId: // value for 'coachId'
 *   },
 * });
 */
export function useActiveCoachProductsForCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveCoachProductsForCustomerQuery,
    ActiveCoachProductsForCustomerQueryVariables
  > &
    (
      | {
          variables: ActiveCoachProductsForCustomerQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActiveCoachProductsForCustomerQuery,
    ActiveCoachProductsForCustomerQueryVariables
  >(ActiveCoachProductsForCustomerDocument, options);
}
export function useActiveCoachProductsForCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveCoachProductsForCustomerQuery,
    ActiveCoachProductsForCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActiveCoachProductsForCustomerQuery,
    ActiveCoachProductsForCustomerQueryVariables
  >(ActiveCoachProductsForCustomerDocument, options);
}
export function useActiveCoachProductsForCustomerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ActiveCoachProductsForCustomerQuery,
        ActiveCoachProductsForCustomerQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ActiveCoachProductsForCustomerQuery,
    ActiveCoachProductsForCustomerQueryVariables
  >(ActiveCoachProductsForCustomerDocument, options);
}
export type ActiveCoachProductsForCustomerQueryHookResult = ReturnType<
  typeof useActiveCoachProductsForCustomerQuery
>;
export type ActiveCoachProductsForCustomerLazyQueryHookResult = ReturnType<
  typeof useActiveCoachProductsForCustomerLazyQuery
>;
export type ActiveCoachProductsForCustomerSuspenseQueryHookResult = ReturnType<
  typeof useActiveCoachProductsForCustomerSuspenseQuery
>;
export type ActiveCoachProductsForCustomerQueryResult = Apollo.QueryResult<
  ActiveCoachProductsForCustomerQuery,
  ActiveCoachProductsForCustomerQueryVariables
>;
export const BookReviewDocument = gql`
  mutation BookReview(
    $productId: ID!
    $coachId: ID!
    $formData: GenericScalar
    $customerId: ID
    $workspaceId: ID
    $ledgerAccountId: ID
  ) {
    bookReview(
      productId: $productId
      coachId: $coachId
      formData: $formData
      customerId: $customerId
      workspaceId: $workspaceId
      ledgerAccountId: $ledgerAccountId
    ) {
      review {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type BookReviewMutationFn = Apollo.MutationFunction<
  BookReviewMutation,
  BookReviewMutationVariables
>;

/**
 * __useBookReviewMutation__
 *
 * To run a mutation, you first call `useBookReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookReviewMutation, { data, loading, error }] = useBookReviewMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      coachId: // value for 'coachId'
 *      formData: // value for 'formData'
 *      customerId: // value for 'customerId'
 *      workspaceId: // value for 'workspaceId'
 *      ledgerAccountId: // value for 'ledgerAccountId'
 *   },
 * });
 */
export function useBookReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookReviewMutation,
    BookReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookReviewMutation, BookReviewMutationVariables>(
    BookReviewDocument,
    options,
  );
}
export type BookReviewMutationHookResult = ReturnType<
  typeof useBookReviewMutation
>;
export type BookReviewMutationResult =
  Apollo.MutationResult<BookReviewMutation>;
export type BookReviewMutationOptions = Apollo.BaseMutationOptions<
  BookReviewMutation,
  BookReviewMutationVariables
>;
export const CancelUnpaidOrderDocument = gql`
  mutation CancelUnpaidOrder($orderId: ID!, $customerId: ID) {
    cancelUnpaidOrder(orderId: $orderId, customerId: $customerId) {
      ok
    }
  }
`;
export type CancelUnpaidOrderMutationFn = Apollo.MutationFunction<
  CancelUnpaidOrderMutation,
  CancelUnpaidOrderMutationVariables
>;

/**
 * __useCancelUnpaidOrderMutation__
 *
 * To run a mutation, you first call `useCancelUnpaidOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUnpaidOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUnpaidOrderMutation, { data, loading, error }] = useCancelUnpaidOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCancelUnpaidOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelUnpaidOrderMutation,
    CancelUnpaidOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelUnpaidOrderMutation,
    CancelUnpaidOrderMutationVariables
  >(CancelUnpaidOrderDocument, options);
}
export type CancelUnpaidOrderMutationHookResult = ReturnType<
  typeof useCancelUnpaidOrderMutation
>;
export type CancelUnpaidOrderMutationResult =
  Apollo.MutationResult<CancelUnpaidOrderMutation>;
export type CancelUnpaidOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelUnpaidOrderMutation,
  CancelUnpaidOrderMutationVariables
>;
export const CoachingDashboardDocument = gql`
  query CoachingDashboard($customerId: ID, $workspaceId: ID) {
    assignments(customerId: $customerId) {
      id
      chatChannelId
      workspace {
        id
      }
      coach {
        id
        name
        firstName
        lastName
        photo
        coachSlug
      }
    }
    customer(id: $customerId, workspaceId: $workspaceId) {
      id
      ...UserAvailableCreditsFragment
      ...UserUpcomingMeetingsFragment
      ...UserOngoingReviewsFragment
      ...UserUnfulfilledOrdersFragment
      ...UserBookingRequestMeetingsFragment
      ...UserConfigurationFragment
      ...UserLiveProgramsFragment
    }
    currentUser {
      id
      name
      photo
      isAgent
      timeZone
      agentProfile {
        id
        coachSlug
        paymentOnboardingReady
        payoutChannel
        ...CoachBufferFragment
      }
      ...UserAvailableCreditsFragment
      ...UserUpcomingMeetingsFragment
      ...UserOngoingReviewsFragment
      ...UserUnfulfilledOrdersFragment
      ...UserBookingRequestMeetingsFragment
      ...UserConfigurationFragment
      ...UserLiveProgramsFragment
    }
  }
  ${UserAvailableCreditsFragmentFragmentDoc}
  ${UserUpcomingMeetingsFragmentFragmentDoc}
  ${MeetingFragmentFragmentDoc}
  ${UserOngoingReviewsFragmentFragmentDoc}
  ${ReviewFragmentFragmentDoc}
  ${UserUnfulfilledOrdersFragmentFragmentDoc}
  ${UserBookingRequestMeetingsFragmentFragmentDoc}
  ${UserConfigurationFragmentFragmentDoc}
  ${UserLiveProgramsFragmentFragmentDoc}
  ${CoachBufferFragmentFragmentDoc}
`;

/**
 * __useCoachingDashboardQuery__
 *
 * To run a query within a React component, call `useCoachingDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachingDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachingDashboardQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useCoachingDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachingDashboardQuery,
    CoachingDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachingDashboardQuery,
    CoachingDashboardQueryVariables
  >(CoachingDashboardDocument, options);
}
export function useCoachingDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachingDashboardQuery,
    CoachingDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachingDashboardQuery,
    CoachingDashboardQueryVariables
  >(CoachingDashboardDocument, options);
}
export function useCoachingDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachingDashboardQuery,
        CoachingDashboardQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachingDashboardQuery,
    CoachingDashboardQueryVariables
  >(CoachingDashboardDocument, options);
}
export type CoachingDashboardQueryHookResult = ReturnType<
  typeof useCoachingDashboardQuery
>;
export type CoachingDashboardLazyQueryHookResult = ReturnType<
  typeof useCoachingDashboardLazyQuery
>;
export type CoachingDashboardSuspenseQueryHookResult = ReturnType<
  typeof useCoachingDashboardSuspenseQuery
>;
export type CoachingDashboardQueryResult = Apollo.QueryResult<
  CoachingDashboardQuery,
  CoachingDashboardQueryVariables
>;
export const AcceptingClientsDocument = gql`
  query AcceptingClients {
    currentUser {
      id
      agentProfile {
        id
        acceptingNewClients
        acceptingNewBookings
      }
    }
  }
`;

/**
 * __useAcceptingClientsQuery__
 *
 * To run a query within a React component, call `useAcceptingClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptingClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceptingClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcceptingClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AcceptingClientsQuery,
    AcceptingClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AcceptingClientsQuery, AcceptingClientsQueryVariables>(
    AcceptingClientsDocument,
    options,
  );
}
export function useAcceptingClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AcceptingClientsQuery,
    AcceptingClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AcceptingClientsQuery,
    AcceptingClientsQueryVariables
  >(AcceptingClientsDocument, options);
}
export function useAcceptingClientsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AcceptingClientsQuery,
        AcceptingClientsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AcceptingClientsQuery,
    AcceptingClientsQueryVariables
  >(AcceptingClientsDocument, options);
}
export type AcceptingClientsQueryHookResult = ReturnType<
  typeof useAcceptingClientsQuery
>;
export type AcceptingClientsLazyQueryHookResult = ReturnType<
  typeof useAcceptingClientsLazyQuery
>;
export type AcceptingClientsSuspenseQueryHookResult = ReturnType<
  typeof useAcceptingClientsSuspenseQuery
>;
export type AcceptingClientsQueryResult = Apollo.QueryResult<
  AcceptingClientsQuery,
  AcceptingClientsQueryVariables
>;
export const AuthCalendarAccountDocument = gql`
  mutation authCalendarAccount(
    $authCode: String!
    $redirectUri: String
    $isPrimary: Boolean!
    $accountType: String!
  ) {
    authCalendarAccount(
      authCode: $authCode
      redirectUri: $redirectUri
      isPrimary: $isPrimary
      accountType: $accountType
    ) {
      calendarAccount {
        id
        accountEmail
        scopes
        accountType
        calendars {
          id
          name
          calendarId
        }
      }
    }
  }
`;
export type AuthCalendarAccountMutationFn = Apollo.MutationFunction<
  AuthCalendarAccountMutation,
  AuthCalendarAccountMutationVariables
>;

/**
 * __useAuthCalendarAccountMutation__
 *
 * To run a mutation, you first call `useAuthCalendarAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthCalendarAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authCalendarAccountMutation, { data, loading, error }] = useAuthCalendarAccountMutation({
 *   variables: {
 *      authCode: // value for 'authCode'
 *      redirectUri: // value for 'redirectUri'
 *      isPrimary: // value for 'isPrimary'
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useAuthCalendarAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthCalendarAccountMutation,
    AuthCalendarAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthCalendarAccountMutation,
    AuthCalendarAccountMutationVariables
  >(AuthCalendarAccountDocument, options);
}
export type AuthCalendarAccountMutationHookResult = ReturnType<
  typeof useAuthCalendarAccountMutation
>;
export type AuthCalendarAccountMutationResult =
  Apollo.MutationResult<AuthCalendarAccountMutation>;
export type AuthCalendarAccountMutationOptions = Apollo.BaseMutationOptions<
  AuthCalendarAccountMutation,
  AuthCalendarAccountMutationVariables
>;
export const CalendarsDocument = gql`
  query Calendars($calendarAccountId: ID!) {
    calendars(calendarAccountId: $calendarAccountId) {
      id
      name
      calendarId
      isPrimary
    }
  }
`;

/**
 * __useCalendarsQuery__
 *
 * To run a query within a React component, call `useCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarsQuery({
 *   variables: {
 *      calendarAccountId: // value for 'calendarAccountId'
 *   },
 * });
 */
export function useCalendarsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarsQuery,
    CalendarsQueryVariables
  > &
    (
      | { variables: CalendarsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarsQuery, CalendarsQueryVariables>(
    CalendarsDocument,
    options,
  );
}
export function useCalendarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarsQuery,
    CalendarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalendarsQuery, CalendarsQueryVariables>(
    CalendarsDocument,
    options,
  );
}
export function useCalendarsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CalendarsQuery, CalendarsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CalendarsQuery, CalendarsQueryVariables>(
    CalendarsDocument,
    options,
  );
}
export type CalendarsQueryHookResult = ReturnType<typeof useCalendarsQuery>;
export type CalendarsLazyQueryHookResult = ReturnType<
  typeof useCalendarsLazyQuery
>;
export type CalendarsSuspenseQueryHookResult = ReturnType<
  typeof useCalendarsSuspenseQuery
>;
export type CalendarsQueryResult = Apollo.QueryResult<
  CalendarsQuery,
  CalendarsQueryVariables
>;
export const SetExternalCalendarDocument = gql`
  mutation SetExternalCalendar(
    $calendarAccountId: ID!
    $calendars: [CalendarInputType]
  ) {
    setExternalCalendar(
      calendarAccountId: $calendarAccountId
      calendars: $calendars
    ) {
      ok
      error {
        message
      }
    }
  }
`;
export type SetExternalCalendarMutationFn = Apollo.MutationFunction<
  SetExternalCalendarMutation,
  SetExternalCalendarMutationVariables
>;

/**
 * __useSetExternalCalendarMutation__
 *
 * To run a mutation, you first call `useSetExternalCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExternalCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExternalCalendarMutation, { data, loading, error }] = useSetExternalCalendarMutation({
 *   variables: {
 *      calendarAccountId: // value for 'calendarAccountId'
 *      calendars: // value for 'calendars'
 *   },
 * });
 */
export function useSetExternalCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetExternalCalendarMutation,
    SetExternalCalendarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetExternalCalendarMutation,
    SetExternalCalendarMutationVariables
  >(SetExternalCalendarDocument, options);
}
export type SetExternalCalendarMutationHookResult = ReturnType<
  typeof useSetExternalCalendarMutation
>;
export type SetExternalCalendarMutationResult =
  Apollo.MutationResult<SetExternalCalendarMutation>;
export type SetExternalCalendarMutationOptions = Apollo.BaseMutationOptions<
  SetExternalCalendarMutation,
  SetExternalCalendarMutationVariables
>;
export const CalendarAccountsDocument = gql`
  query CalendarAccounts {
    currentUser {
      id
      primaryCalendarAccount {
        id
        accountEmail
        scopes
        accountType
        error
        calendars {
          id
          name
          calendarId
          error
        }
      }
      otherCalendarAccounts {
        id
        accountEmail
        scopes
        accountType
        error
        calendars {
          id
          name
          calendarId
          error
        }
      }
    }
  }
`;

/**
 * __useCalendarAccountsQuery__
 *
 * To run a query within a React component, call `useCalendarAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalendarAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CalendarAccountsQuery,
    CalendarAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarAccountsQuery, CalendarAccountsQueryVariables>(
    CalendarAccountsDocument,
    options,
  );
}
export function useCalendarAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarAccountsQuery,
    CalendarAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalendarAccountsQuery,
    CalendarAccountsQueryVariables
  >(CalendarAccountsDocument, options);
}
export function useCalendarAccountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CalendarAccountsQuery,
        CalendarAccountsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CalendarAccountsQuery,
    CalendarAccountsQueryVariables
  >(CalendarAccountsDocument, options);
}
export type CalendarAccountsQueryHookResult = ReturnType<
  typeof useCalendarAccountsQuery
>;
export type CalendarAccountsLazyQueryHookResult = ReturnType<
  typeof useCalendarAccountsLazyQuery
>;
export type CalendarAccountsSuspenseQueryHookResult = ReturnType<
  typeof useCalendarAccountsSuspenseQuery
>;
export type CalendarAccountsQueryResult = Apollo.QueryResult<
  CalendarAccountsQuery,
  CalendarAccountsQueryVariables
>;
export const CalendarSettingsDocument = gql`
  query CalendarSettings {
    currentUser {
      id
      timeZone
      calendarSetting {
        mondayTimeslots
        tuesdayTimeslots
        wednesdayTimeslots
        thursdayTimeslots
        fridayTimeslots
        saturdayTimeslots
        sundayTimeslots
      }
    }
  }
`;

/**
 * __useCalendarSettingsQuery__
 *
 * To run a query within a React component, call `useCalendarSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalendarSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CalendarSettingsQuery,
    CalendarSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarSettingsQuery, CalendarSettingsQueryVariables>(
    CalendarSettingsDocument,
    options,
  );
}
export function useCalendarSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarSettingsQuery,
    CalendarSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalendarSettingsQuery,
    CalendarSettingsQueryVariables
  >(CalendarSettingsDocument, options);
}
export function useCalendarSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CalendarSettingsQuery,
        CalendarSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CalendarSettingsQuery,
    CalendarSettingsQueryVariables
  >(CalendarSettingsDocument, options);
}
export type CalendarSettingsQueryHookResult = ReturnType<
  typeof useCalendarSettingsQuery
>;
export type CalendarSettingsLazyQueryHookResult = ReturnType<
  typeof useCalendarSettingsLazyQuery
>;
export type CalendarSettingsSuspenseQueryHookResult = ReturnType<
  typeof useCalendarSettingsSuspenseQuery
>;
export type CalendarSettingsQueryResult = Apollo.QueryResult<
  CalendarSettingsQuery,
  CalendarSettingsQueryVariables
>;
export const CoachCustomersDocument = gql`
  query CoachCustomers(
    $productId: ID
    $includeCustomersOfOtherProducts: Boolean
    $excludeWorkspaceCustomers: Boolean
  ) {
    coachCustomers(
      productId: $productId
      includeCustomersOfOtherProducts: $includeCustomersOfOtherProducts
      excludeWorkspaceCustomers: $excludeWorkspaceCustomers
    ) {
      userId
      userName
      userPhoto
      userEmail
      lastPurchaseAt
      currentPriceOption {
        id
        price
        coachCreditValue
      }
    }
  }
`;

/**
 * __useCoachCustomersQuery__
 *
 * To run a query within a React component, call `useCoachCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachCustomersQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      includeCustomersOfOtherProducts: // value for 'includeCustomersOfOtherProducts'
 *      excludeWorkspaceCustomers: // value for 'excludeWorkspaceCustomers'
 *   },
 * });
 */
export function useCoachCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachCustomersQuery,
    CoachCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachCustomersQuery, CoachCustomersQueryVariables>(
    CoachCustomersDocument,
    options,
  );
}
export function useCoachCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachCustomersQuery,
    CoachCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachCustomersQuery, CoachCustomersQueryVariables>(
    CoachCustomersDocument,
    options,
  );
}
export function useCoachCustomersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachCustomersQuery,
        CoachCustomersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachCustomersQuery,
    CoachCustomersQueryVariables
  >(CoachCustomersDocument, options);
}
export type CoachCustomersQueryHookResult = ReturnType<
  typeof useCoachCustomersQuery
>;
export type CoachCustomersLazyQueryHookResult = ReturnType<
  typeof useCoachCustomersLazyQuery
>;
export type CoachCustomersSuspenseQueryHookResult = ReturnType<
  typeof useCoachCustomersSuspenseQuery
>;
export type CoachCustomersQueryResult = Apollo.QueryResult<
  CoachCustomersQuery,
  CoachCustomersQueryVariables
>;
export const CoachProductDocument = gql`
  query CoachProduct($productSlug: String!) {
    coachProduct(productSlug: $productSlug) {
      id
      enabled
      maxPrice
      coachCreditValue
      price
      acceptingNewClients
      requireBookingRequest
      validPrices
      invalidPrices
      product {
        id
        name
        description
        creditPrice
        enableRfq
        enableBookingRequest
        slug
        specialty {
          id
          name
        }
      }
    }
    currentUser {
      id
      agentProfile {
        id
        coachSlug
      }
    }
  }
`;

/**
 * __useCoachProductQuery__
 *
 * To run a query within a React component, call `useCoachProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachProductQuery({
 *   variables: {
 *      productSlug: // value for 'productSlug'
 *   },
 * });
 */
export function useCoachProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachProductQuery,
    CoachProductQueryVariables
  > &
    (
      | { variables: CoachProductQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachProductQuery, CoachProductQueryVariables>(
    CoachProductDocument,
    options,
  );
}
export function useCoachProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachProductQuery,
    CoachProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachProductQuery, CoachProductQueryVariables>(
    CoachProductDocument,
    options,
  );
}
export function useCoachProductSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachProductQuery,
        CoachProductQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoachProductQuery, CoachProductQueryVariables>(
    CoachProductDocument,
    options,
  );
}
export type CoachProductQueryHookResult = ReturnType<
  typeof useCoachProductQuery
>;
export type CoachProductLazyQueryHookResult = ReturnType<
  typeof useCoachProductLazyQuery
>;
export type CoachProductSuspenseQueryHookResult = ReturnType<
  typeof useCoachProductSuspenseQuery
>;
export type CoachProductQueryResult = Apollo.QueryResult<
  CoachProductQuery,
  CoachProductQueryVariables
>;
export const CoachWorkspacePricesDocument = gql`
  query CoachWorkspacePrices($productId: ID!) {
    coachWorkspacePrices(productId: $productId) {
      id
      workspace {
        id
        name
      }
      price
      coachCreditValue
    }
  }
`;

/**
 * __useCoachWorkspacePricesQuery__
 *
 * To run a query within a React component, call `useCoachWorkspacePricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachWorkspacePricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachWorkspacePricesQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCoachWorkspacePricesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachWorkspacePricesQuery,
    CoachWorkspacePricesQueryVariables
  > &
    (
      | { variables: CoachWorkspacePricesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachWorkspacePricesQuery,
    CoachWorkspacePricesQueryVariables
  >(CoachWorkspacePricesDocument, options);
}
export function useCoachWorkspacePricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachWorkspacePricesQuery,
    CoachWorkspacePricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachWorkspacePricesQuery,
    CoachWorkspacePricesQueryVariables
  >(CoachWorkspacePricesDocument, options);
}
export function useCoachWorkspacePricesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachWorkspacePricesQuery,
        CoachWorkspacePricesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachWorkspacePricesQuery,
    CoachWorkspacePricesQueryVariables
  >(CoachWorkspacePricesDocument, options);
}
export type CoachWorkspacePricesQueryHookResult = ReturnType<
  typeof useCoachWorkspacePricesQuery
>;
export type CoachWorkspacePricesLazyQueryHookResult = ReturnType<
  typeof useCoachWorkspacePricesLazyQuery
>;
export type CoachWorkspacePricesSuspenseQueryHookResult = ReturnType<
  typeof useCoachWorkspacePricesSuspenseQuery
>;
export type CoachWorkspacePricesQueryResult = Apollo.QueryResult<
  CoachWorkspacePricesQuery,
  CoachWorkspacePricesQueryVariables
>;
export const SetCoachProductPriceDocument = gql`
  mutation SetCoachProductPrice(
    $productId: Int!
    $price: Decimal!
    $legacyCustomerIds: [Int]
  ) {
    setCoachProductPrice(
      productId: $productId
      price: $price
      legacyCustomerIds: $legacyCustomerIds
    ) {
      ok
      coachProduct {
        id
        enabled
        maxPrice
        price
      }
    }
  }
`;
export type SetCoachProductPriceMutationFn = Apollo.MutationFunction<
  SetCoachProductPriceMutation,
  SetCoachProductPriceMutationVariables
>;

/**
 * __useSetCoachProductPriceMutation__
 *
 * To run a mutation, you first call `useSetCoachProductPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCoachProductPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCoachProductPriceMutation, { data, loading, error }] = useSetCoachProductPriceMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      price: // value for 'price'
 *      legacyCustomerIds: // value for 'legacyCustomerIds'
 *   },
 * });
 */
export function useSetCoachProductPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCoachProductPriceMutation,
    SetCoachProductPriceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetCoachProductPriceMutation,
    SetCoachProductPriceMutationVariables
  >(SetCoachProductPriceDocument, options);
}
export type SetCoachProductPriceMutationHookResult = ReturnType<
  typeof useSetCoachProductPriceMutation
>;
export type SetCoachProductPriceMutationResult =
  Apollo.MutationResult<SetCoachProductPriceMutation>;
export type SetCoachProductPriceMutationOptions = Apollo.BaseMutationOptions<
  SetCoachProductPriceMutation,
  SetCoachProductPriceMutationVariables
>;
export const SetCustomPriceOptionDocument = gql`
  mutation SetCustomPriceOption(
    $customerId: ID!
    $price: Decimal
    $productId: ID!
    $useDefault: Boolean
  ) {
    setCustomPriceOption(
      customerId: $customerId
      price: $price
      productId: $productId
      useDefault: $useDefault
    ) {
      ok
      customPriceOption {
        id
        price
      }
    }
  }
`;
export type SetCustomPriceOptionMutationFn = Apollo.MutationFunction<
  SetCustomPriceOptionMutation,
  SetCustomPriceOptionMutationVariables
>;

/**
 * __useSetCustomPriceOptionMutation__
 *
 * To run a mutation, you first call `useSetCustomPriceOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomPriceOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomPriceOptionMutation, { data, loading, error }] = useSetCustomPriceOptionMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      price: // value for 'price'
 *      productId: // value for 'productId'
 *      useDefault: // value for 'useDefault'
 *   },
 * });
 */
export function useSetCustomPriceOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCustomPriceOptionMutation,
    SetCustomPriceOptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetCustomPriceOptionMutation,
    SetCustomPriceOptionMutationVariables
  >(SetCustomPriceOptionDocument, options);
}
export type SetCustomPriceOptionMutationHookResult = ReturnType<
  typeof useSetCustomPriceOptionMutation
>;
export type SetCustomPriceOptionMutationResult =
  Apollo.MutationResult<SetCustomPriceOptionMutation>;
export type SetCustomPriceOptionMutationOptions = Apollo.BaseMutationOptions<
  SetCustomPriceOptionMutation,
  SetCustomPriceOptionMutationVariables
>;
export const ToggleAcceptingNewClientsForCoachProductDocument = gql`
  mutation ToggleAcceptingNewClientsForCoachProduct($productSlug: String!) {
    toggleAcceptingNewClientsForCoachProduct(productSlug: $productSlug) {
      ok
      errors {
        message
      }
      coachProduct {
        id
        acceptingNewClients
      }
    }
  }
`;
export type ToggleAcceptingNewClientsForCoachProductMutationFn =
  Apollo.MutationFunction<
    ToggleAcceptingNewClientsForCoachProductMutation,
    ToggleAcceptingNewClientsForCoachProductMutationVariables
  >;

/**
 * __useToggleAcceptingNewClientsForCoachProductMutation__
 *
 * To run a mutation, you first call `useToggleAcceptingNewClientsForCoachProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAcceptingNewClientsForCoachProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAcceptingNewClientsForCoachProductMutation, { data, loading, error }] = useToggleAcceptingNewClientsForCoachProductMutation({
 *   variables: {
 *      productSlug: // value for 'productSlug'
 *   },
 * });
 */
export function useToggleAcceptingNewClientsForCoachProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleAcceptingNewClientsForCoachProductMutation,
    ToggleAcceptingNewClientsForCoachProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleAcceptingNewClientsForCoachProductMutation,
    ToggleAcceptingNewClientsForCoachProductMutationVariables
  >(ToggleAcceptingNewClientsForCoachProductDocument, options);
}
export type ToggleAcceptingNewClientsForCoachProductMutationHookResult =
  ReturnType<typeof useToggleAcceptingNewClientsForCoachProductMutation>;
export type ToggleAcceptingNewClientsForCoachProductMutationResult =
  Apollo.MutationResult<ToggleAcceptingNewClientsForCoachProductMutation>;
export type ToggleAcceptingNewClientsForCoachProductMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleAcceptingNewClientsForCoachProductMutation,
    ToggleAcceptingNewClientsForCoachProductMutationVariables
  >;
export const ToggleCoachProductDocument = gql`
  mutation ToggleCoachProduct($productSlug: String!) {
    toggleCoachProduct(productSlug: $productSlug) {
      ok
      errors {
        message
      }
      coachProduct {
        id
        enabled
      }
    }
  }
`;
export type ToggleCoachProductMutationFn = Apollo.MutationFunction<
  ToggleCoachProductMutation,
  ToggleCoachProductMutationVariables
>;

/**
 * __useToggleCoachProductMutation__
 *
 * To run a mutation, you first call `useToggleCoachProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCoachProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCoachProductMutation, { data, loading, error }] = useToggleCoachProductMutation({
 *   variables: {
 *      productSlug: // value for 'productSlug'
 *   },
 * });
 */
export function useToggleCoachProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleCoachProductMutation,
    ToggleCoachProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleCoachProductMutation,
    ToggleCoachProductMutationVariables
  >(ToggleCoachProductDocument, options);
}
export type ToggleCoachProductMutationHookResult = ReturnType<
  typeof useToggleCoachProductMutation
>;
export type ToggleCoachProductMutationResult =
  Apollo.MutationResult<ToggleCoachProductMutation>;
export type ToggleCoachProductMutationOptions = Apollo.BaseMutationOptions<
  ToggleCoachProductMutation,
  ToggleCoachProductMutationVariables
>;
export const ToggleRequireBookingRequestForCoachProductDocument = gql`
  mutation ToggleRequireBookingRequestForCoachProduct($productSlug: String!) {
    toggleRequireBookingRequestForCoachProduct(productSlug: $productSlug) {
      ok
      errors {
        message
      }
      coachProduct {
        id
        requireBookingRequest
      }
    }
  }
`;
export type ToggleRequireBookingRequestForCoachProductMutationFn =
  Apollo.MutationFunction<
    ToggleRequireBookingRequestForCoachProductMutation,
    ToggleRequireBookingRequestForCoachProductMutationVariables
  >;

/**
 * __useToggleRequireBookingRequestForCoachProductMutation__
 *
 * To run a mutation, you first call `useToggleRequireBookingRequestForCoachProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleRequireBookingRequestForCoachProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleRequireBookingRequestForCoachProductMutation, { data, loading, error }] = useToggleRequireBookingRequestForCoachProductMutation({
 *   variables: {
 *      productSlug: // value for 'productSlug'
 *   },
 * });
 */
export function useToggleRequireBookingRequestForCoachProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleRequireBookingRequestForCoachProductMutation,
    ToggleRequireBookingRequestForCoachProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleRequireBookingRequestForCoachProductMutation,
    ToggleRequireBookingRequestForCoachProductMutationVariables
  >(ToggleRequireBookingRequestForCoachProductDocument, options);
}
export type ToggleRequireBookingRequestForCoachProductMutationHookResult =
  ReturnType<typeof useToggleRequireBookingRequestForCoachProductMutation>;
export type ToggleRequireBookingRequestForCoachProductMutationResult =
  Apollo.MutationResult<ToggleRequireBookingRequestForCoachProductMutation>;
export type ToggleRequireBookingRequestForCoachProductMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleRequireBookingRequestForCoachProductMutation,
    ToggleRequireBookingRequestForCoachProductMutationVariables
  >;
export const DeleteScheduleOverrideDocument = gql`
  mutation DeleteScheduleOverride($date: Date!) {
    deleteScheduleOverride(date: $date) {
      ok
      errors {
        messages
      }
    }
  }
`;
export type DeleteScheduleOverrideMutationFn = Apollo.MutationFunction<
  DeleteScheduleOverrideMutation,
  DeleteScheduleOverrideMutationVariables
>;

/**
 * __useDeleteScheduleOverrideMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduleOverrideMutation, { data, loading, error }] = useDeleteScheduleOverrideMutation({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDeleteScheduleOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScheduleOverrideMutation,
    DeleteScheduleOverrideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScheduleOverrideMutation,
    DeleteScheduleOverrideMutationVariables
  >(DeleteScheduleOverrideDocument, options);
}
export type DeleteScheduleOverrideMutationHookResult = ReturnType<
  typeof useDeleteScheduleOverrideMutation
>;
export type DeleteScheduleOverrideMutationResult =
  Apollo.MutationResult<DeleteScheduleOverrideMutation>;
export type DeleteScheduleOverrideMutationOptions = Apollo.BaseMutationOptions<
  DeleteScheduleOverrideMutation,
  DeleteScheduleOverrideMutationVariables
>;
export const MakePrimaryCalendarAccountDocument = gql`
  mutation MakePrimaryCalendarAccount($id: ID!) {
    makePrimaryCalendarAccount(id: $id) {
      ok
      error {
        message
      }
    }
  }
`;
export type MakePrimaryCalendarAccountMutationFn = Apollo.MutationFunction<
  MakePrimaryCalendarAccountMutation,
  MakePrimaryCalendarAccountMutationVariables
>;

/**
 * __useMakePrimaryCalendarAccountMutation__
 *
 * To run a mutation, you first call `useMakePrimaryCalendarAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakePrimaryCalendarAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makePrimaryCalendarAccountMutation, { data, loading, error }] = useMakePrimaryCalendarAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMakePrimaryCalendarAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakePrimaryCalendarAccountMutation,
    MakePrimaryCalendarAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakePrimaryCalendarAccountMutation,
    MakePrimaryCalendarAccountMutationVariables
  >(MakePrimaryCalendarAccountDocument, options);
}
export type MakePrimaryCalendarAccountMutationHookResult = ReturnType<
  typeof useMakePrimaryCalendarAccountMutation
>;
export type MakePrimaryCalendarAccountMutationResult =
  Apollo.MutationResult<MakePrimaryCalendarAccountMutation>;
export type MakePrimaryCalendarAccountMutationOptions =
  Apollo.BaseMutationOptions<
    MakePrimaryCalendarAccountMutation,
    MakePrimaryCalendarAccountMutationVariables
  >;
export const MyCoachProductsDocument = gql`
  query MyCoachProducts {
    activeCoachProducts {
      price
      enabled
      ...CoachProductParts
    }
    inactiveProducts {
      id
      name
      specialty {
        id
        name
        description
      }
    }
    currentUser {
      id
      agentProfile {
        id
        coachSlug
      }
    }
  }
  ${CoachProductPartsFragmentDoc}
`;

/**
 * __useMyCoachProductsQuery__
 *
 * To run a query within a React component, call `useMyCoachProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCoachProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCoachProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCoachProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyCoachProductsQuery,
    MyCoachProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyCoachProductsQuery, MyCoachProductsQueryVariables>(
    MyCoachProductsDocument,
    options,
  );
}
export function useMyCoachProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyCoachProductsQuery,
    MyCoachProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyCoachProductsQuery,
    MyCoachProductsQueryVariables
  >(MyCoachProductsDocument, options);
}
export function useMyCoachProductsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyCoachProductsQuery,
        MyCoachProductsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyCoachProductsQuery,
    MyCoachProductsQueryVariables
  >(MyCoachProductsDocument, options);
}
export type MyCoachProductsQueryHookResult = ReturnType<
  typeof useMyCoachProductsQuery
>;
export type MyCoachProductsLazyQueryHookResult = ReturnType<
  typeof useMyCoachProductsLazyQuery
>;
export type MyCoachProductsSuspenseQueryHookResult = ReturnType<
  typeof useMyCoachProductsSuspenseQuery
>;
export type MyCoachProductsQueryResult = Apollo.QueryResult<
  MyCoachProductsQuery,
  MyCoachProductsQueryVariables
>;
export const RemoveCalendarAccountDocument = gql`
  mutation RemoveCalendarAccount($id: ID!) {
    removeCalendarAccount(id: $id) {
      ok
      error {
        message
      }
    }
  }
`;
export type RemoveCalendarAccountMutationFn = Apollo.MutationFunction<
  RemoveCalendarAccountMutation,
  RemoveCalendarAccountMutationVariables
>;

/**
 * __useRemoveCalendarAccountMutation__
 *
 * To run a mutation, you first call `useRemoveCalendarAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCalendarAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCalendarAccountMutation, { data, loading, error }] = useRemoveCalendarAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCalendarAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCalendarAccountMutation,
    RemoveCalendarAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCalendarAccountMutation,
    RemoveCalendarAccountMutationVariables
  >(RemoveCalendarAccountDocument, options);
}
export type RemoveCalendarAccountMutationHookResult = ReturnType<
  typeof useRemoveCalendarAccountMutation
>;
export type RemoveCalendarAccountMutationResult =
  Apollo.MutationResult<RemoveCalendarAccountMutation>;
export type RemoveCalendarAccountMutationOptions = Apollo.BaseMutationOptions<
  RemoveCalendarAccountMutation,
  RemoveCalendarAccountMutationVariables
>;
export const SaveCalendarSettingsDocument = gql`
  mutation SaveCalendarSettings($input: CalendarSettingInput!) {
    saveCalendarSettings(input: $input) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type SaveCalendarSettingsMutationFn = Apollo.MutationFunction<
  SaveCalendarSettingsMutation,
  SaveCalendarSettingsMutationVariables
>;

/**
 * __useSaveCalendarSettingsMutation__
 *
 * To run a mutation, you first call `useSaveCalendarSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCalendarSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCalendarSettingsMutation, { data, loading, error }] = useSaveCalendarSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCalendarSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCalendarSettingsMutation,
    SaveCalendarSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCalendarSettingsMutation,
    SaveCalendarSettingsMutationVariables
  >(SaveCalendarSettingsDocument, options);
}
export type SaveCalendarSettingsMutationHookResult = ReturnType<
  typeof useSaveCalendarSettingsMutation
>;
export type SaveCalendarSettingsMutationResult =
  Apollo.MutationResult<SaveCalendarSettingsMutation>;
export type SaveCalendarSettingsMutationOptions = Apollo.BaseMutationOptions<
  SaveCalendarSettingsMutation,
  SaveCalendarSettingsMutationVariables
>;
export const SaveScheduleOverridesDocument = gql`
  mutation SaveScheduleOverrides($dates: [Date]!, $timeslots: [String]!) {
    saveScheduleOverrides(dates: $dates, timeslots: $timeslots) {
      ok
      errors {
        messages
      }
    }
  }
`;
export type SaveScheduleOverridesMutationFn = Apollo.MutationFunction<
  SaveScheduleOverridesMutation,
  SaveScheduleOverridesMutationVariables
>;

/**
 * __useSaveScheduleOverridesMutation__
 *
 * To run a mutation, you first call `useSaveScheduleOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveScheduleOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveScheduleOverridesMutation, { data, loading, error }] = useSaveScheduleOverridesMutation({
 *   variables: {
 *      dates: // value for 'dates'
 *      timeslots: // value for 'timeslots'
 *   },
 * });
 */
export function useSaveScheduleOverridesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveScheduleOverridesMutation,
    SaveScheduleOverridesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveScheduleOverridesMutation,
    SaveScheduleOverridesMutationVariables
  >(SaveScheduleOverridesDocument, options);
}
export type SaveScheduleOverridesMutationHookResult = ReturnType<
  typeof useSaveScheduleOverridesMutation
>;
export type SaveScheduleOverridesMutationResult =
  Apollo.MutationResult<SaveScheduleOverridesMutation>;
export type SaveScheduleOverridesMutationOptions = Apollo.BaseMutationOptions<
  SaveScheduleOverridesMutation,
  SaveScheduleOverridesMutationVariables
>;
export const ScheduleOverridesDocument = gql`
  query ScheduleOverrides {
    scheduleOverrides(onlyFuture: true) {
      id
      overrideDate
      timeslots
    }
  }
`;

/**
 * __useScheduleOverridesQuery__
 *
 * To run a query within a React component, call `useScheduleOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleOverridesQuery({
 *   variables: {
 *   },
 * });
 */
export function useScheduleOverridesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ScheduleOverridesQuery,
    ScheduleOverridesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduleOverridesQuery,
    ScheduleOverridesQueryVariables
  >(ScheduleOverridesDocument, options);
}
export function useScheduleOverridesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleOverridesQuery,
    ScheduleOverridesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduleOverridesQuery,
    ScheduleOverridesQueryVariables
  >(ScheduleOverridesDocument, options);
}
export function useScheduleOverridesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ScheduleOverridesQuery,
        ScheduleOverridesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ScheduleOverridesQuery,
    ScheduleOverridesQueryVariables
  >(ScheduleOverridesDocument, options);
}
export type ScheduleOverridesQueryHookResult = ReturnType<
  typeof useScheduleOverridesQuery
>;
export type ScheduleOverridesLazyQueryHookResult = ReturnType<
  typeof useScheduleOverridesLazyQuery
>;
export type ScheduleOverridesSuspenseQueryHookResult = ReturnType<
  typeof useScheduleOverridesSuspenseQuery
>;
export type ScheduleOverridesQueryResult = Apollo.QueryResult<
  ScheduleOverridesQuery,
  ScheduleOverridesQueryVariables
>;
export const BufferSettingsDocument = gql`
  query BufferSettings {
    currentUser {
      id
      agentProfile {
        id
        ...CoachBufferFragment
      }
    }
  }
  ${CoachBufferFragmentFragmentDoc}
`;

/**
 * __useBufferSettingsQuery__
 *
 * To run a query within a React component, call `useBufferSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBufferSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBufferSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBufferSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BufferSettingsQuery,
    BufferSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BufferSettingsQuery, BufferSettingsQueryVariables>(
    BufferSettingsDocument,
    options,
  );
}
export function useBufferSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BufferSettingsQuery,
    BufferSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BufferSettingsQuery, BufferSettingsQueryVariables>(
    BufferSettingsDocument,
    options,
  );
}
export function useBufferSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BufferSettingsQuery,
        BufferSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BufferSettingsQuery,
    BufferSettingsQueryVariables
  >(BufferSettingsDocument, options);
}
export type BufferSettingsQueryHookResult = ReturnType<
  typeof useBufferSettingsQuery
>;
export type BufferSettingsLazyQueryHookResult = ReturnType<
  typeof useBufferSettingsLazyQuery
>;
export type BufferSettingsSuspenseQueryHookResult = ReturnType<
  typeof useBufferSettingsSuspenseQuery
>;
export type BufferSettingsQueryResult = Apollo.QueryResult<
  BufferSettingsQuery,
  BufferSettingsQueryVariables
>;
export const UpdateCoachBufferSettingsDocument = gql`
  mutation UpdateCoachBufferSettings(
    $bufferBefore: Int!
    $bufferAfter: Int!
    $minuteIncrement: Int!
    $minimumMeetingNotice: Int!
  ) {
    updateBufferSettings(
      bufferBefore: $bufferBefore
      bufferAfter: $bufferAfter
      minuteIncrement: $minuteIncrement
      minimumMeetingNotice: $minimumMeetingNotice
    ) {
      ok
    }
  }
`;
export type UpdateCoachBufferSettingsMutationFn = Apollo.MutationFunction<
  UpdateCoachBufferSettingsMutation,
  UpdateCoachBufferSettingsMutationVariables
>;

/**
 * __useUpdateCoachBufferSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCoachBufferSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachBufferSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachBufferSettingsMutation, { data, loading, error }] = useUpdateCoachBufferSettingsMutation({
 *   variables: {
 *      bufferBefore: // value for 'bufferBefore'
 *      bufferAfter: // value for 'bufferAfter'
 *      minuteIncrement: // value for 'minuteIncrement'
 *      minimumMeetingNotice: // value for 'minimumMeetingNotice'
 *   },
 * });
 */
export function useUpdateCoachBufferSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachBufferSettingsMutation,
    UpdateCoachBufferSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCoachBufferSettingsMutation,
    UpdateCoachBufferSettingsMutationVariables
  >(UpdateCoachBufferSettingsDocument, options);
}
export type UpdateCoachBufferSettingsMutationHookResult = ReturnType<
  typeof useUpdateCoachBufferSettingsMutation
>;
export type UpdateCoachBufferSettingsMutationResult =
  Apollo.MutationResult<UpdateCoachBufferSettingsMutation>;
export type UpdateCoachBufferSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCoachBufferSettingsMutation,
    UpdateCoachBufferSettingsMutationVariables
  >;
export const MeetingTypesDocument = gql`
  query MeetingTypes($userId: ID, $workspaceId: ID) {
    specialties(userId: $userId, workspaceId: $workspaceId) {
      id
      slug
      name
      description
    }
    meetingTypes(userId: $userId, workspaceId: $workspaceId) {
      id
      slug
      name
      category
      duration
      description
      creditPrice
      slogan
      introProduct
      enableBookingRequest
      coachEnableBookingRequest
      specialty {
        id
        name
      }
      coach {
        id
      }
      activeFormFields {
        id
        prompt
        helpText
        answerType
        required
        formFieldOptions {
          id
          label
          value
        }
      }
    }
  }
`;

/**
 * __useMeetingTypesQuery__
 *
 * To run a query within a React component, call `useMeetingTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingTypesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useMeetingTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MeetingTypesQuery,
    MeetingTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeetingTypesQuery, MeetingTypesQueryVariables>(
    MeetingTypesDocument,
    options,
  );
}
export function useMeetingTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeetingTypesQuery,
    MeetingTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeetingTypesQuery, MeetingTypesQueryVariables>(
    MeetingTypesDocument,
    options,
  );
}
export function useMeetingTypesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MeetingTypesQuery,
        MeetingTypesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeetingTypesQuery, MeetingTypesQueryVariables>(
    MeetingTypesDocument,
    options,
  );
}
export type MeetingTypesQueryHookResult = ReturnType<
  typeof useMeetingTypesQuery
>;
export type MeetingTypesLazyQueryHookResult = ReturnType<
  typeof useMeetingTypesLazyQuery
>;
export type MeetingTypesSuspenseQueryHookResult = ReturnType<
  typeof useMeetingTypesSuspenseQuery
>;
export type MeetingTypesQueryResult = Apollo.QueryResult<
  MeetingTypesQuery,
  MeetingTypesQueryVariables
>;
export const RemoveAssignmentDocument = gql`
  mutation RemoveAssignment($id: ID!) {
    removeAssignment(id: $id) {
      ok
      errors {
        messages
      }
    }
  }
`;
export type RemoveAssignmentMutationFn = Apollo.MutationFunction<
  RemoveAssignmentMutation,
  RemoveAssignmentMutationVariables
>;

/**
 * __useRemoveAssignmentMutation__
 *
 * To run a mutation, you first call `useRemoveAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssignmentMutation, { data, loading, error }] = useRemoveAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAssignmentMutation,
    RemoveAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAssignmentMutation,
    RemoveAssignmentMutationVariables
  >(RemoveAssignmentDocument, options);
}
export type RemoveAssignmentMutationHookResult = ReturnType<
  typeof useRemoveAssignmentMutation
>;
export type RemoveAssignmentMutationResult =
  Apollo.MutationResult<RemoveAssignmentMutation>;
export type RemoveAssignmentMutationOptions = Apollo.BaseMutationOptions<
  RemoveAssignmentMutation,
  RemoveAssignmentMutationVariables
>;
export const UpdateStripeAccountDocument = gql`
  mutation UpdateStripeAccount {
    updateStripeAccount {
      ready
      submitted
    }
  }
`;
export type UpdateStripeAccountMutationFn = Apollo.MutationFunction<
  UpdateStripeAccountMutation,
  UpdateStripeAccountMutationVariables
>;

/**
 * __useUpdateStripeAccountMutation__
 *
 * To run a mutation, you first call `useUpdateStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeAccountMutation, { data, loading, error }] = useUpdateStripeAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateStripeAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStripeAccountMutation,
    UpdateStripeAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStripeAccountMutation,
    UpdateStripeAccountMutationVariables
  >(UpdateStripeAccountDocument, options);
}
export type UpdateStripeAccountMutationHookResult = ReturnType<
  typeof useUpdateStripeAccountMutation
>;
export type UpdateStripeAccountMutationResult =
  Apollo.MutationResult<UpdateStripeAccountMutation>;
export type UpdateStripeAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateStripeAccountMutation,
  UpdateStripeAccountMutationVariables
>;
export const AgentProfileBasicDocument = gql`
  query AgentProfileBasic(
    $coachSlug: String
    $id: ID
    $userId: ID
    $customerId: ID
    $specialtySlug: String
  ) {
    agentProfile(
      coachSlug: $coachSlug
      id: $id
      userId: $userId
      customerId: $customerId
      specialtySlug: $specialtySlug
    ) {
      id
      seoIndex
      primarySite
      coachSlug
      user {
        id
        name
        firstName
        lastName
        photo
        referralCode
      }
      about
      summary
      createdAt
      seoPhoto
      averageRating
      credentials
      standardCredentials {
        ...StandardCredentialParts
      }
      introVideo
      introVideoThumbnail
      faqAnswers {
        ...FaqAnswerParts
      }
      testimonials {
        id
        name
        message
        jobTitle
        avatarUrl
      }
    }
  }
  ${StandardCredentialPartsFragmentDoc}
  ${FaqAnswerPartsFragmentDoc}
`;

/**
 * __useAgentProfileBasicQuery__
 *
 * To run a query within a React component, call `useAgentProfileBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentProfileBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentProfileBasicQuery({
 *   variables: {
 *      coachSlug: // value for 'coachSlug'
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      customerId: // value for 'customerId'
 *      specialtySlug: // value for 'specialtySlug'
 *   },
 * });
 */
export function useAgentProfileBasicQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AgentProfileBasicQuery,
    AgentProfileBasicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgentProfileBasicQuery,
    AgentProfileBasicQueryVariables
  >(AgentProfileBasicDocument, options);
}
export function useAgentProfileBasicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgentProfileBasicQuery,
    AgentProfileBasicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgentProfileBasicQuery,
    AgentProfileBasicQueryVariables
  >(AgentProfileBasicDocument, options);
}
export function useAgentProfileBasicSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AgentProfileBasicQuery,
        AgentProfileBasicQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AgentProfileBasicQuery,
    AgentProfileBasicQueryVariables
  >(AgentProfileBasicDocument, options);
}
export type AgentProfileBasicQueryHookResult = ReturnType<
  typeof useAgentProfileBasicQuery
>;
export type AgentProfileBasicLazyQueryHookResult = ReturnType<
  typeof useAgentProfileBasicLazyQuery
>;
export type AgentProfileBasicSuspenseQueryHookResult = ReturnType<
  typeof useAgentProfileBasicSuspenseQuery
>;
export type AgentProfileBasicQueryResult = Apollo.QueryResult<
  AgentProfileBasicQuery,
  AgentProfileBasicQueryVariables
>;
export const AvailableCreditsDocument = gql`
  query AvailableCredits($customerId: ID) {
    currentUser {
      id
      ...UserAvailableCreditsFragment
    }
    customer(id: $customerId) {
      id
      ...UserAvailableCreditsFragment
    }
  }
  ${UserAvailableCreditsFragmentFragmentDoc}
`;

/**
 * __useAvailableCreditsQuery__
 *
 * To run a query within a React component, call `useAvailableCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCreditsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useAvailableCreditsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableCreditsQuery,
    AvailableCreditsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailableCreditsQuery, AvailableCreditsQueryVariables>(
    AvailableCreditsDocument,
    options,
  );
}
export function useAvailableCreditsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCreditsQuery,
    AvailableCreditsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableCreditsQuery,
    AvailableCreditsQueryVariables
  >(AvailableCreditsDocument, options);
}
export function useAvailableCreditsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AvailableCreditsQuery,
        AvailableCreditsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AvailableCreditsQuery,
    AvailableCreditsQueryVariables
  >(AvailableCreditsDocument, options);
}
export type AvailableCreditsQueryHookResult = ReturnType<
  typeof useAvailableCreditsQuery
>;
export type AvailableCreditsLazyQueryHookResult = ReturnType<
  typeof useAvailableCreditsLazyQuery
>;
export type AvailableCreditsSuspenseQueryHookResult = ReturnType<
  typeof useAvailableCreditsSuspenseQuery
>;
export type AvailableCreditsQueryResult = Apollo.QueryResult<
  AvailableCreditsQuery,
  AvailableCreditsQueryVariables
>;
export const CoachProductPricesDocument = gql`
  query CoachProductPrices($ids: [Int]!) {
    coachProducts(ids: $ids) {
      id
      price
    }
  }
`;

/**
 * __useCoachProductPricesQuery__
 *
 * To run a query within a React component, call `useCoachProductPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachProductPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachProductPricesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCoachProductPricesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachProductPricesQuery,
    CoachProductPricesQueryVariables
  > &
    (
      | { variables: CoachProductPricesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoachProductPricesQuery,
    CoachProductPricesQueryVariables
  >(CoachProductPricesDocument, options);
}
export function useCoachProductPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachProductPricesQuery,
    CoachProductPricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoachProductPricesQuery,
    CoachProductPricesQueryVariables
  >(CoachProductPricesDocument, options);
}
export function useCoachProductPricesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachProductPricesQuery,
        CoachProductPricesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachProductPricesQuery,
    CoachProductPricesQueryVariables
  >(CoachProductPricesDocument, options);
}
export type CoachProductPricesQueryHookResult = ReturnType<
  typeof useCoachProductPricesQuery
>;
export type CoachProductPricesLazyQueryHookResult = ReturnType<
  typeof useCoachProductPricesLazyQuery
>;
export type CoachProductPricesSuspenseQueryHookResult = ReturnType<
  typeof useCoachProductPricesSuspenseQuery
>;
export type CoachProductPricesQueryResult = Apollo.QueryResult<
  CoachProductPricesQuery,
  CoachProductPricesQueryVariables
>;
export const CoachProductsDocument = gql`
  query CoachProducts(
    $coachUserId: ID!
    $customerId: ID
    $specialtyId: ID
    $specialtySlug: String
  ) {
    coachProducts(
      coachUserId: $coachUserId
      customerId: $customerId
      specialtyId: $specialtyId
      specialtySlug: $specialtySlug
    ) {
      price
      usdPrice
      locked
      ...CoachProductParts
    }
  }
  ${CoachProductPartsFragmentDoc}
`;

/**
 * __useCoachProductsQuery__
 *
 * To run a query within a React component, call `useCoachProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachProductsQuery({
 *   variables: {
 *      coachUserId: // value for 'coachUserId'
 *      customerId: // value for 'customerId'
 *      specialtyId: // value for 'specialtyId'
 *      specialtySlug: // value for 'specialtySlug'
 *   },
 * });
 */
export function useCoachProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachProductsQuery,
    CoachProductsQueryVariables
  > &
    (
      | { variables: CoachProductsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachProductsQuery, CoachProductsQueryVariables>(
    CoachProductsDocument,
    options,
  );
}
export function useCoachProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachProductsQuery,
    CoachProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachProductsQuery, CoachProductsQueryVariables>(
    CoachProductsDocument,
    options,
  );
}
export function useCoachProductsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachProductsQuery,
        CoachProductsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachProductsQuery,
    CoachProductsQueryVariables
  >(CoachProductsDocument, options);
}
export type CoachProductsQueryHookResult = ReturnType<
  typeof useCoachProductsQuery
>;
export type CoachProductsLazyQueryHookResult = ReturnType<
  typeof useCoachProductsLazyQuery
>;
export type CoachProductsSuspenseQueryHookResult = ReturnType<
  typeof useCoachProductsSuspenseQuery
>;
export type CoachProductsQueryResult = Apollo.QueryResult<
  CoachProductsQuery,
  CoachProductsQueryVariables
>;
export const CreateIntroOrderDocument = gql`
  mutation CreateIntroOrder(
    $productQuantities: GenericScalar!
    $customerId: ID
    $coachId: ID
    $startTime: DateTime
    $key: String
    $timeslotsStrings: [String]
    $usedAllTimeslot: Boolean
  ) {
    createOrder(
      productQuantities: $productQuantities
      coachId: $coachId
      customerId: $customerId
      startTime: $startTime
      key: $key
      timeslotsStrings: $timeslotsStrings
      usedAllTimeslot: $usedAllTimeslot
    ) {
      ok
      order {
        id
      }
      error {
        message
      }
    }
  }
`;
export type CreateIntroOrderMutationFn = Apollo.MutationFunction<
  CreateIntroOrderMutation,
  CreateIntroOrderMutationVariables
>;

/**
 * __useCreateIntroOrderMutation__
 *
 * To run a mutation, you first call `useCreateIntroOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntroOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntroOrderMutation, { data, loading, error }] = useCreateIntroOrderMutation({
 *   variables: {
 *      productQuantities: // value for 'productQuantities'
 *      customerId: // value for 'customerId'
 *      coachId: // value for 'coachId'
 *      startTime: // value for 'startTime'
 *      key: // value for 'key'
 *      timeslotsStrings: // value for 'timeslotsStrings'
 *      usedAllTimeslot: // value for 'usedAllTimeslot'
 *   },
 * });
 */
export function useCreateIntroOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIntroOrderMutation,
    CreateIntroOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIntroOrderMutation,
    CreateIntroOrderMutationVariables
  >(CreateIntroOrderDocument, options);
}
export type CreateIntroOrderMutationHookResult = ReturnType<
  typeof useCreateIntroOrderMutation
>;
export type CreateIntroOrderMutationResult =
  Apollo.MutationResult<CreateIntroOrderMutation>;
export type CreateIntroOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateIntroOrderMutation,
  CreateIntroOrderMutationVariables
>;
export const FaqAnswersDocument = gql`
  query FaqAnswers($ids: [ID]) {
    faqAnswers(ids: $ids) {
      id
      ...FaqAnswerParts
    }
  }
  ${FaqAnswerPartsFragmentDoc}
`;

/**
 * __useFaqAnswersQuery__
 *
 * To run a query within a React component, call `useFaqAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqAnswersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFaqAnswersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FaqAnswersQuery,
    FaqAnswersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FaqAnswersQuery, FaqAnswersQueryVariables>(
    FaqAnswersDocument,
    options,
  );
}
export function useFaqAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FaqAnswersQuery,
    FaqAnswersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FaqAnswersQuery, FaqAnswersQueryVariables>(
    FaqAnswersDocument,
    options,
  );
}
export function useFaqAnswersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FaqAnswersQuery,
        FaqAnswersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FaqAnswersQuery, FaqAnswersQueryVariables>(
    FaqAnswersDocument,
    options,
  );
}
export type FaqAnswersQueryHookResult = ReturnType<typeof useFaqAnswersQuery>;
export type FaqAnswersLazyQueryHookResult = ReturnType<
  typeof useFaqAnswersLazyQuery
>;
export type FaqAnswersSuspenseQueryHookResult = ReturnType<
  typeof useFaqAnswersSuspenseQuery
>;
export type FaqAnswersQueryResult = Apollo.QueryResult<
  FaqAnswersQuery,
  FaqAnswersQueryVariables
>;
export const CancelCoachMeetingDocument = gql`
  mutation CancelCoachMeeting(
    $meetingId: ID!
    $cancelReason: String
    $customerId: ID
  ) {
    cancelCoachMeeting(
      meetingId: $meetingId
      cancelReason: $cancelReason
      customerId: $customerId
    ) {
      ok
    }
  }
`;
export type CancelCoachMeetingMutationFn = Apollo.MutationFunction<
  CancelCoachMeetingMutation,
  CancelCoachMeetingMutationVariables
>;

/**
 * __useCancelCoachMeetingMutation__
 *
 * To run a mutation, you first call `useCancelCoachMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCoachMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCoachMeetingMutation, { data, loading, error }] = useCancelCoachMeetingMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      cancelReason: // value for 'cancelReason'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCancelCoachMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelCoachMeetingMutation,
    CancelCoachMeetingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelCoachMeetingMutation,
    CancelCoachMeetingMutationVariables
  >(CancelCoachMeetingDocument, options);
}
export type CancelCoachMeetingMutationHookResult = ReturnType<
  typeof useCancelCoachMeetingMutation
>;
export type CancelCoachMeetingMutationResult =
  Apollo.MutationResult<CancelCoachMeetingMutation>;
export type CancelCoachMeetingMutationOptions = Apollo.BaseMutationOptions<
  CancelCoachMeetingMutation,
  CancelCoachMeetingMutationVariables
>;
export const FullMeetingDocument = gql`
  query FullMeeting($id: ID!, $customerId: ID) {
    meeting(id: $id, customerId: $customerId) {
      ...MeetingWithFormFragment
    }
    currentUser {
      id
      primaryCalendarAccount {
        id
        accountEmail
      }
    }
  }
  ${MeetingWithFormFragmentFragmentDoc}
  ${MeetingFragmentFragmentDoc}
`;

/**
 * __useFullMeetingQuery__
 *
 * To run a query within a React component, call `useFullMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullMeetingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useFullMeetingQuery(
  baseOptions: Apollo.QueryHookOptions<
    FullMeetingQuery,
    FullMeetingQueryVariables
  > &
    (
      | { variables: FullMeetingQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FullMeetingQuery, FullMeetingQueryVariables>(
    FullMeetingDocument,
    options,
  );
}
export function useFullMeetingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FullMeetingQuery,
    FullMeetingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FullMeetingQuery, FullMeetingQueryVariables>(
    FullMeetingDocument,
    options,
  );
}
export function useFullMeetingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FullMeetingQuery,
        FullMeetingQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FullMeetingQuery, FullMeetingQueryVariables>(
    FullMeetingDocument,
    options,
  );
}
export type FullMeetingQueryHookResult = ReturnType<typeof useFullMeetingQuery>;
export type FullMeetingLazyQueryHookResult = ReturnType<
  typeof useFullMeetingLazyQuery
>;
export type FullMeetingSuspenseQueryHookResult = ReturnType<
  typeof useFullMeetingSuspenseQuery
>;
export type FullMeetingQueryResult = Apollo.QueryResult<
  FullMeetingQuery,
  FullMeetingQueryVariables
>;
export const AddMeetingAttendeesDocument = gql`
  mutation AddMeetingAttendees(
    $meetingId: ID!
    $emails: [String]!
    $scheduleTime: DateTime
  ) {
    addMeetingAttendees(
      meetingId: $meetingId
      emails: $emails
      scheduleTime: $scheduleTime
    ) {
      ok
    }
  }
`;
export type AddMeetingAttendeesMutationFn = Apollo.MutationFunction<
  AddMeetingAttendeesMutation,
  AddMeetingAttendeesMutationVariables
>;

/**
 * __useAddMeetingAttendeesMutation__
 *
 * To run a mutation, you first call `useAddMeetingAttendeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMeetingAttendeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMeetingAttendeesMutation, { data, loading, error }] = useAddMeetingAttendeesMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      emails: // value for 'emails'
 *      scheduleTime: // value for 'scheduleTime'
 *   },
 * });
 */
export function useAddMeetingAttendeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMeetingAttendeesMutation,
    AddMeetingAttendeesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddMeetingAttendeesMutation,
    AddMeetingAttendeesMutationVariables
  >(AddMeetingAttendeesDocument, options);
}
export type AddMeetingAttendeesMutationHookResult = ReturnType<
  typeof useAddMeetingAttendeesMutation
>;
export type AddMeetingAttendeesMutationResult =
  Apollo.MutationResult<AddMeetingAttendeesMutation>;
export type AddMeetingAttendeesMutationOptions = Apollo.BaseMutationOptions<
  AddMeetingAttendeesMutation,
  AddMeetingAttendeesMutationVariables
>;
export const MeetingAttendeesDocument = gql`
  query MeetingAttendees(
    $meetingId: ID!
    $status: InvitationStatus
    $statuses: [InvitationStatus]
    $start: Int
    $limit: Int
  ) {
    meetingAttendees(
      meetingId: $meetingId
      status: $status
      statuses: $statuses
      start: $start
      limit: $limit
    ) {
      total
      start
      limit
      attendees {
        id
        status
        attendingStatus
        email
        invitationBatchId
        scheduledJobId
        user {
          id
          name
          email
          photo
        }
      }
      owner {
        id
        name
        email
        photo
      }
    }
  }
`;

/**
 * __useMeetingAttendeesQuery__
 *
 * To run a query within a React component, call `useMeetingAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingAttendeesQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      status: // value for 'status'
 *      statuses: // value for 'statuses'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMeetingAttendeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MeetingAttendeesQuery,
    MeetingAttendeesQueryVariables
  > &
    (
      | { variables: MeetingAttendeesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeetingAttendeesQuery, MeetingAttendeesQueryVariables>(
    MeetingAttendeesDocument,
    options,
  );
}
export function useMeetingAttendeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeetingAttendeesQuery,
    MeetingAttendeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MeetingAttendeesQuery,
    MeetingAttendeesQueryVariables
  >(MeetingAttendeesDocument, options);
}
export function useMeetingAttendeesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MeetingAttendeesQuery,
        MeetingAttendeesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MeetingAttendeesQuery,
    MeetingAttendeesQueryVariables
  >(MeetingAttendeesDocument, options);
}
export type MeetingAttendeesQueryHookResult = ReturnType<
  typeof useMeetingAttendeesQuery
>;
export type MeetingAttendeesLazyQueryHookResult = ReturnType<
  typeof useMeetingAttendeesLazyQuery
>;
export type MeetingAttendeesSuspenseQueryHookResult = ReturnType<
  typeof useMeetingAttendeesSuspenseQuery
>;
export type MeetingAttendeesQueryResult = Apollo.QueryResult<
  MeetingAttendeesQuery,
  MeetingAttendeesQueryVariables
>;
export const CancelAttendeesScheduleDocument = gql`
  mutation CancelAttendeesSchedule($meetingId: ID!, $scheduledJobId: ID!) {
    cancelAttendeesSchedule(
      meetingId: $meetingId
      scheduledJobId: $scheduledJobId
    ) {
      ok
    }
  }
`;
export type CancelAttendeesScheduleMutationFn = Apollo.MutationFunction<
  CancelAttendeesScheduleMutation,
  CancelAttendeesScheduleMutationVariables
>;

/**
 * __useCancelAttendeesScheduleMutation__
 *
 * To run a mutation, you first call `useCancelAttendeesScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAttendeesScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAttendeesScheduleMutation, { data, loading, error }] = useCancelAttendeesScheduleMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      scheduledJobId: // value for 'scheduledJobId'
 *   },
 * });
 */
export function useCancelAttendeesScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAttendeesScheduleMutation,
    CancelAttendeesScheduleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAttendeesScheduleMutation,
    CancelAttendeesScheduleMutationVariables
  >(CancelAttendeesScheduleDocument, options);
}
export type CancelAttendeesScheduleMutationHookResult = ReturnType<
  typeof useCancelAttendeesScheduleMutation
>;
export type CancelAttendeesScheduleMutationResult =
  Apollo.MutationResult<CancelAttendeesScheduleMutation>;
export type CancelAttendeesScheduleMutationOptions = Apollo.BaseMutationOptions<
  CancelAttendeesScheduleMutation,
  CancelAttendeesScheduleMutationVariables
>;
export const ReInvFailedMeetingAttendeesDocument = gql`
  mutation ReInvFailedMeetingAttendees($meetingId: ID!) {
    reInvFailedMeetingAttendees(meetingId: $meetingId) {
      ok
    }
  }
`;
export type ReInvFailedMeetingAttendeesMutationFn = Apollo.MutationFunction<
  ReInvFailedMeetingAttendeesMutation,
  ReInvFailedMeetingAttendeesMutationVariables
>;

/**
 * __useReInvFailedMeetingAttendeesMutation__
 *
 * To run a mutation, you first call `useReInvFailedMeetingAttendeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReInvFailedMeetingAttendeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reInvFailedMeetingAttendeesMutation, { data, loading, error }] = useReInvFailedMeetingAttendeesMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useReInvFailedMeetingAttendeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReInvFailedMeetingAttendeesMutation,
    ReInvFailedMeetingAttendeesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReInvFailedMeetingAttendeesMutation,
    ReInvFailedMeetingAttendeesMutationVariables
  >(ReInvFailedMeetingAttendeesDocument, options);
}
export type ReInvFailedMeetingAttendeesMutationHookResult = ReturnType<
  typeof useReInvFailedMeetingAttendeesMutation
>;
export type ReInvFailedMeetingAttendeesMutationResult =
  Apollo.MutationResult<ReInvFailedMeetingAttendeesMutation>;
export type ReInvFailedMeetingAttendeesMutationOptions =
  Apollo.BaseMutationOptions<
    ReInvFailedMeetingAttendeesMutation,
    ReInvFailedMeetingAttendeesMutationVariables
  >;
export const RemoveMeetingAttendeesDocument = gql`
  mutation RemoveMeetingAttendees($meetingId: ID!, $emails: [String]!) {
    removeMeetingAttendees(meetingId: $meetingId, emails: $emails) {
      ok
    }
  }
`;
export type RemoveMeetingAttendeesMutationFn = Apollo.MutationFunction<
  RemoveMeetingAttendeesMutation,
  RemoveMeetingAttendeesMutationVariables
>;

/**
 * __useRemoveMeetingAttendeesMutation__
 *
 * To run a mutation, you first call `useRemoveMeetingAttendeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMeetingAttendeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMeetingAttendeesMutation, { data, loading, error }] = useRemoveMeetingAttendeesMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useRemoveMeetingAttendeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMeetingAttendeesMutation,
    RemoveMeetingAttendeesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMeetingAttendeesMutation,
    RemoveMeetingAttendeesMutationVariables
  >(RemoveMeetingAttendeesDocument, options);
}
export type RemoveMeetingAttendeesMutationHookResult = ReturnType<
  typeof useRemoveMeetingAttendeesMutation
>;
export type RemoveMeetingAttendeesMutationResult =
  Apollo.MutationResult<RemoveMeetingAttendeesMutation>;
export type RemoveMeetingAttendeesMutationOptions = Apollo.BaseMutationOptions<
  RemoveMeetingAttendeesMutation,
  RemoveMeetingAttendeesMutationVariables
>;
export const SaveAttendingStatusDocument = gql`
  mutation SaveAttendingStatus(
    $attendeeId: ID!
    $attendingStatus: AttendingStatus!
  ) {
    saveAttendingStatus(
      attendeeId: $attendeeId
      attendingStatus: $attendingStatus
    ) {
      ok
      msg
    }
  }
`;
export type SaveAttendingStatusMutationFn = Apollo.MutationFunction<
  SaveAttendingStatusMutation,
  SaveAttendingStatusMutationVariables
>;

/**
 * __useSaveAttendingStatusMutation__
 *
 * To run a mutation, you first call `useSaveAttendingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAttendingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAttendingStatusMutation, { data, loading, error }] = useSaveAttendingStatusMutation({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *      attendingStatus: // value for 'attendingStatus'
 *   },
 * });
 */
export function useSaveAttendingStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAttendingStatusMutation,
    SaveAttendingStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveAttendingStatusMutation,
    SaveAttendingStatusMutationVariables
  >(SaveAttendingStatusDocument, options);
}
export type SaveAttendingStatusMutationHookResult = ReturnType<
  typeof useSaveAttendingStatusMutation
>;
export type SaveAttendingStatusMutationResult =
  Apollo.MutationResult<SaveAttendingStatusMutation>;
export type SaveAttendingStatusMutationOptions = Apollo.BaseMutationOptions<
  SaveAttendingStatusMutation,
  SaveAttendingStatusMutationVariables
>;
export const UpdateAttendeesScheduleDocument = gql`
  mutation UpdateAttendeesSchedule(
    $meetingId: ID!
    $emails: [String]!
    $scheduleTime: DateTime
    $scheduledJobId: ID!
  ) {
    updateAttendeesSchedule(
      meetingId: $meetingId
      emails: $emails
      scheduleTime: $scheduleTime
      scheduledJobId: $scheduledJobId
    ) {
      ok
    }
  }
`;
export type UpdateAttendeesScheduleMutationFn = Apollo.MutationFunction<
  UpdateAttendeesScheduleMutation,
  UpdateAttendeesScheduleMutationVariables
>;

/**
 * __useUpdateAttendeesScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateAttendeesScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendeesScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendeesScheduleMutation, { data, loading, error }] = useUpdateAttendeesScheduleMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      emails: // value for 'emails'
 *      scheduleTime: // value for 'scheduleTime'
 *      scheduledJobId: // value for 'scheduledJobId'
 *   },
 * });
 */
export function useUpdateAttendeesScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAttendeesScheduleMutation,
    UpdateAttendeesScheduleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAttendeesScheduleMutation,
    UpdateAttendeesScheduleMutationVariables
  >(UpdateAttendeesScheduleDocument, options);
}
export type UpdateAttendeesScheduleMutationHookResult = ReturnType<
  typeof useUpdateAttendeesScheduleMutation
>;
export type UpdateAttendeesScheduleMutationResult =
  Apollo.MutationResult<UpdateAttendeesScheduleMutation>;
export type UpdateAttendeesScheduleMutationOptions = Apollo.BaseMutationOptions<
  UpdateAttendeesScheduleMutation,
  UpdateAttendeesScheduleMutationVariables
>;
export const SaveMeetingAllowGuestsDocument = gql`
  mutation SaveMeetingAllowGuests($meetingId: ID!, $allowGuests: Boolean!) {
    saveMeetingAllowGuests(meetingId: $meetingId, allowGuests: $allowGuests) {
      ok
    }
  }
`;
export type SaveMeetingAllowGuestsMutationFn = Apollo.MutationFunction<
  SaveMeetingAllowGuestsMutation,
  SaveMeetingAllowGuestsMutationVariables
>;

/**
 * __useSaveMeetingAllowGuestsMutation__
 *
 * To run a mutation, you first call `useSaveMeetingAllowGuestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMeetingAllowGuestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMeetingAllowGuestsMutation, { data, loading, error }] = useSaveMeetingAllowGuestsMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      allowGuests: // value for 'allowGuests'
 *   },
 * });
 */
export function useSaveMeetingAllowGuestsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveMeetingAllowGuestsMutation,
    SaveMeetingAllowGuestsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveMeetingAllowGuestsMutation,
    SaveMeetingAllowGuestsMutationVariables
  >(SaveMeetingAllowGuestsDocument, options);
}
export type SaveMeetingAllowGuestsMutationHookResult = ReturnType<
  typeof useSaveMeetingAllowGuestsMutation
>;
export type SaveMeetingAllowGuestsMutationResult =
  Apollo.MutationResult<SaveMeetingAllowGuestsMutation>;
export type SaveMeetingAllowGuestsMutationOptions = Apollo.BaseMutationOptions<
  SaveMeetingAllowGuestsMutation,
  SaveMeetingAllowGuestsMutationVariables
>;
export const SaveMeetingGuestsDocument = gql`
  mutation SaveMeetingGuests($meetingId: ID!, $guests: [String]!) {
    saveMeetingGuests(meetingId: $meetingId, guests: $guests) {
      ok
    }
  }
`;
export type SaveMeetingGuestsMutationFn = Apollo.MutationFunction<
  SaveMeetingGuestsMutation,
  SaveMeetingGuestsMutationVariables
>;

/**
 * __useSaveMeetingGuestsMutation__
 *
 * To run a mutation, you first call `useSaveMeetingGuestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMeetingGuestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMeetingGuestsMutation, { data, loading, error }] = useSaveMeetingGuestsMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      guests: // value for 'guests'
 *   },
 * });
 */
export function useSaveMeetingGuestsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveMeetingGuestsMutation,
    SaveMeetingGuestsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveMeetingGuestsMutation,
    SaveMeetingGuestsMutationVariables
  >(SaveMeetingGuestsDocument, options);
}
export type SaveMeetingGuestsMutationHookResult = ReturnType<
  typeof useSaveMeetingGuestsMutation
>;
export type SaveMeetingGuestsMutationResult =
  Apollo.MutationResult<SaveMeetingGuestsMutation>;
export type SaveMeetingGuestsMutationOptions = Apollo.BaseMutationOptions<
  SaveMeetingGuestsMutation,
  SaveMeetingGuestsMutationVariables
>;
export const SeeMoreMeetingsDocument = gql`
  query SeeMoreMeetings($index: Int, $customerId: ID) {
    meetings(customerId: $customerId, index: $index) {
      ...MeetingFragment
    }
  }
  ${MeetingFragmentFragmentDoc}
`;

/**
 * __useSeeMoreMeetingsQuery__
 *
 * To run a query within a React component, call `useSeeMoreMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeeMoreMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeeMoreMeetingsQuery({
 *   variables: {
 *      index: // value for 'index'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useSeeMoreMeetingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SeeMoreMeetingsQuery,
    SeeMoreMeetingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeeMoreMeetingsQuery, SeeMoreMeetingsQueryVariables>(
    SeeMoreMeetingsDocument,
    options,
  );
}
export function useSeeMoreMeetingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SeeMoreMeetingsQuery,
    SeeMoreMeetingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SeeMoreMeetingsQuery,
    SeeMoreMeetingsQueryVariables
  >(SeeMoreMeetingsDocument, options);
}
export function useSeeMoreMeetingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SeeMoreMeetingsQuery,
        SeeMoreMeetingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SeeMoreMeetingsQuery,
    SeeMoreMeetingsQueryVariables
  >(SeeMoreMeetingsDocument, options);
}
export type SeeMoreMeetingsQueryHookResult = ReturnType<
  typeof useSeeMoreMeetingsQuery
>;
export type SeeMoreMeetingsLazyQueryHookResult = ReturnType<
  typeof useSeeMoreMeetingsLazyQuery
>;
export type SeeMoreMeetingsSuspenseQueryHookResult = ReturnType<
  typeof useSeeMoreMeetingsSuspenseQuery
>;
export type SeeMoreMeetingsQueryResult = Apollo.QueryResult<
  SeeMoreMeetingsQuery,
  SeeMoreMeetingsQueryVariables
>;
export const SendGoogleInviteCoachMeetingDocument = gql`
  mutation SendGoogleInviteCoachMeeting($meetingId: ID!) {
    sendGoogleInviteCoachMeeting(meetingId: $meetingId) {
      ok
    }
  }
`;
export type SendGoogleInviteCoachMeetingMutationFn = Apollo.MutationFunction<
  SendGoogleInviteCoachMeetingMutation,
  SendGoogleInviteCoachMeetingMutationVariables
>;

/**
 * __useSendGoogleInviteCoachMeetingMutation__
 *
 * To run a mutation, you first call `useSendGoogleInviteCoachMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGoogleInviteCoachMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGoogleInviteCoachMeetingMutation, { data, loading, error }] = useSendGoogleInviteCoachMeetingMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useSendGoogleInviteCoachMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendGoogleInviteCoachMeetingMutation,
    SendGoogleInviteCoachMeetingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendGoogleInviteCoachMeetingMutation,
    SendGoogleInviteCoachMeetingMutationVariables
  >(SendGoogleInviteCoachMeetingDocument, options);
}
export type SendGoogleInviteCoachMeetingMutationHookResult = ReturnType<
  typeof useSendGoogleInviteCoachMeetingMutation
>;
export type SendGoogleInviteCoachMeetingMutationResult =
  Apollo.MutationResult<SendGoogleInviteCoachMeetingMutation>;
export type SendGoogleInviteCoachMeetingMutationOptions =
  Apollo.BaseMutationOptions<
    SendGoogleInviteCoachMeetingMutation,
    SendGoogleInviteCoachMeetingMutationVariables
  >;
export const SetExceptionalRefundDocument = gql`
  mutation SetExceptionalRefund($meetingId: ID!) {
    setExceptionalRefund(meetingId: $meetingId) {
      ok
    }
  }
`;
export type SetExceptionalRefundMutationFn = Apollo.MutationFunction<
  SetExceptionalRefundMutation,
  SetExceptionalRefundMutationVariables
>;

/**
 * __useSetExceptionalRefundMutation__
 *
 * To run a mutation, you first call `useSetExceptionalRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExceptionalRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExceptionalRefundMutation, { data, loading, error }] = useSetExceptionalRefundMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useSetExceptionalRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetExceptionalRefundMutation,
    SetExceptionalRefundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetExceptionalRefundMutation,
    SetExceptionalRefundMutationVariables
  >(SetExceptionalRefundDocument, options);
}
export type SetExceptionalRefundMutationHookResult = ReturnType<
  typeof useSetExceptionalRefundMutation
>;
export type SetExceptionalRefundMutationResult =
  Apollo.MutationResult<SetExceptionalRefundMutation>;
export type SetExceptionalRefundMutationOptions = Apollo.BaseMutationOptions<
  SetExceptionalRefundMutation,
  SetExceptionalRefundMutationVariables
>;
export const SaveMeetingLocationDocument = gql`
  mutation SaveMeetingLocation($meetingId: ID!, $customLocation: String) {
    saveMeetingLocation(
      meetingId: $meetingId
      customLocation: $customLocation
    ) {
      ok
    }
  }
`;
export type SaveMeetingLocationMutationFn = Apollo.MutationFunction<
  SaveMeetingLocationMutation,
  SaveMeetingLocationMutationVariables
>;

/**
 * __useSaveMeetingLocationMutation__
 *
 * To run a mutation, you first call `useSaveMeetingLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMeetingLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMeetingLocationMutation, { data, loading, error }] = useSaveMeetingLocationMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      customLocation: // value for 'customLocation'
 *   },
 * });
 */
export function useSaveMeetingLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveMeetingLocationMutation,
    SaveMeetingLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveMeetingLocationMutation,
    SaveMeetingLocationMutationVariables
  >(SaveMeetingLocationDocument, options);
}
export type SaveMeetingLocationMutationHookResult = ReturnType<
  typeof useSaveMeetingLocationMutation
>;
export type SaveMeetingLocationMutationResult =
  Apollo.MutationResult<SaveMeetingLocationMutation>;
export type SaveMeetingLocationMutationOptions = Apollo.BaseMutationOptions<
  SaveMeetingLocationMutation,
  SaveMeetingLocationMutationVariables
>;
export const AcceptReviewDocument = gql`
  mutation AcceptReview($reviewId: ID!, $accepted: Boolean!) {
    acceptReview(reviewId: $reviewId, accepted: $accepted) {
      review {
        id
        acceptedAt
      }
      errors {
        message
      }
    }
  }
`;
export type AcceptReviewMutationFn = Apollo.MutationFunction<
  AcceptReviewMutation,
  AcceptReviewMutationVariables
>;

/**
 * __useAcceptReviewMutation__
 *
 * To run a mutation, you first call `useAcceptReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptReviewMutation, { data, loading, error }] = useAcceptReviewMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *      accepted: // value for 'accepted'
 *   },
 * });
 */
export function useAcceptReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptReviewMutation,
    AcceptReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptReviewMutation,
    AcceptReviewMutationVariables
  >(AcceptReviewDocument, options);
}
export type AcceptReviewMutationHookResult = ReturnType<
  typeof useAcceptReviewMutation
>;
export type AcceptReviewMutationResult =
  Apollo.MutationResult<AcceptReviewMutation>;
export type AcceptReviewMutationOptions = Apollo.BaseMutationOptions<
  AcceptReviewMutation,
  AcceptReviewMutationVariables
>;
export const CancelReviewDocument = gql`
  mutation CancelReview($reviewId: ID!, $customerId: ID) {
    cancelReview(reviewId: $reviewId, customerId: $customerId) {
      review {
        id
        canceledAt
      }
      errors {
        message
      }
    }
  }
`;
export type CancelReviewMutationFn = Apollo.MutationFunction<
  CancelReviewMutation,
  CancelReviewMutationVariables
>;

/**
 * __useCancelReviewMutation__
 *
 * To run a mutation, you first call `useCancelReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReviewMutation, { data, loading, error }] = useCancelReviewMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCancelReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelReviewMutation,
    CancelReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelReviewMutation,
    CancelReviewMutationVariables
  >(CancelReviewDocument, options);
}
export type CancelReviewMutationHookResult = ReturnType<
  typeof useCancelReviewMutation
>;
export type CancelReviewMutationResult =
  Apollo.MutationResult<CancelReviewMutation>;
export type CancelReviewMutationOptions = Apollo.BaseMutationOptions<
  CancelReviewMutation,
  CancelReviewMutationVariables
>;
export const FullReviewDocument = gql`
  query FullReview($id: ID!, $customerId: ID) {
    review(id: $id, customerId: $customerId) {
      ...ReviewWithFormFragment
      ...ReviewRoundsFragment
    }
  }
  ${ReviewWithFormFragmentFragmentDoc}
  ${ReviewFragmentFragmentDoc}
  ${ReviewRoundsFragmentFragmentDoc}
`;

/**
 * __useFullReviewQuery__
 *
 * To run a query within a React component, call `useFullReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useFullReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    FullReviewQuery,
    FullReviewQueryVariables
  > &
    (
      | { variables: FullReviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FullReviewQuery, FullReviewQueryVariables>(
    FullReviewDocument,
    options,
  );
}
export function useFullReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FullReviewQuery,
    FullReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FullReviewQuery, FullReviewQueryVariables>(
    FullReviewDocument,
    options,
  );
}
export function useFullReviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FullReviewQuery,
        FullReviewQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FullReviewQuery, FullReviewQueryVariables>(
    FullReviewDocument,
    options,
  );
}
export type FullReviewQueryHookResult = ReturnType<typeof useFullReviewQuery>;
export type FullReviewLazyQueryHookResult = ReturnType<
  typeof useFullReviewLazyQuery
>;
export type FullReviewSuspenseQueryHookResult = ReturnType<
  typeof useFullReviewSuspenseQuery
>;
export type FullReviewQueryResult = Apollo.QueryResult<
  FullReviewQuery,
  FullReviewQueryVariables
>;
export const ReviewTypesDocument = gql`
  query ReviewTypes($userId: ID, $workspaceId: ID) {
    specialties {
      id
      slug
      name
      description
    }
    reviewTypes(userId: $userId, workspaceId: $workspaceId) {
      id
      slug
      name
      category
      description
      creditPrice
      slogan
      turnaroundTime
      numRounds
      includesFinalCheck
      activeFormFields {
        id
        prompt
        helpText
        answerType
        required
        formFieldOptions {
          id
          label
          value
        }
      }
      specialty {
        id
        name
      }
      coach {
        id
      }
    }
  }
`;

/**
 * __useReviewTypesQuery__
 *
 * To run a query within a React component, call `useReviewTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewTypesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useReviewTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReviewTypesQuery,
    ReviewTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewTypesQuery, ReviewTypesQueryVariables>(
    ReviewTypesDocument,
    options,
  );
}
export function useReviewTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReviewTypesQuery,
    ReviewTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewTypesQuery, ReviewTypesQueryVariables>(
    ReviewTypesDocument,
    options,
  );
}
export function useReviewTypesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReviewTypesQuery,
        ReviewTypesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReviewTypesQuery, ReviewTypesQueryVariables>(
    ReviewTypesDocument,
    options,
  );
}
export type ReviewTypesQueryHookResult = ReturnType<typeof useReviewTypesQuery>;
export type ReviewTypesLazyQueryHookResult = ReturnType<
  typeof useReviewTypesLazyQuery
>;
export type ReviewTypesSuspenseQueryHookResult = ReturnType<
  typeof useReviewTypesSuspenseQuery
>;
export type ReviewTypesQueryResult = Apollo.QueryResult<
  ReviewTypesQuery,
  ReviewTypesQueryVariables
>;
export const SeeMoreReviewsDocument = gql`
  query SeeMoreReviews($index: Int, $customerId: ID) {
    reviews(customerId: $customerId, index: $index) {
      ...ReviewFragment
    }
  }
  ${ReviewFragmentFragmentDoc}
`;

/**
 * __useSeeMoreReviewsQuery__
 *
 * To run a query within a React component, call `useSeeMoreReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeeMoreReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeeMoreReviewsQuery({
 *   variables: {
 *      index: // value for 'index'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useSeeMoreReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SeeMoreReviewsQuery,
    SeeMoreReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeeMoreReviewsQuery, SeeMoreReviewsQueryVariables>(
    SeeMoreReviewsDocument,
    options,
  );
}
export function useSeeMoreReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SeeMoreReviewsQuery,
    SeeMoreReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SeeMoreReviewsQuery, SeeMoreReviewsQueryVariables>(
    SeeMoreReviewsDocument,
    options,
  );
}
export function useSeeMoreReviewsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SeeMoreReviewsQuery,
        SeeMoreReviewsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SeeMoreReviewsQuery,
    SeeMoreReviewsQueryVariables
  >(SeeMoreReviewsDocument, options);
}
export type SeeMoreReviewsQueryHookResult = ReturnType<
  typeof useSeeMoreReviewsQuery
>;
export type SeeMoreReviewsLazyQueryHookResult = ReturnType<
  typeof useSeeMoreReviewsLazyQuery
>;
export type SeeMoreReviewsSuspenseQueryHookResult = ReturnType<
  typeof useSeeMoreReviewsSuspenseQuery
>;
export type SeeMoreReviewsQueryResult = Apollo.QueryResult<
  SeeMoreReviewsQuery,
  SeeMoreReviewsQueryVariables
>;
export const CompleteReviewRoundDocument = gql`
  mutation CompleteReviewRound($reviewId: ID!) {
    completeReviewRound(reviewId: $reviewId) {
      review {
        id
        completedAt
      }
      errors {
        message
      }
    }
  }
`;
export type CompleteReviewRoundMutationFn = Apollo.MutationFunction<
  CompleteReviewRoundMutation,
  CompleteReviewRoundMutationVariables
>;

/**
 * __useCompleteReviewRoundMutation__
 *
 * To run a mutation, you first call `useCompleteReviewRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteReviewRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeReviewRoundMutation, { data, loading, error }] = useCompleteReviewRoundMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useCompleteReviewRoundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteReviewRoundMutation,
    CompleteReviewRoundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteReviewRoundMutation,
    CompleteReviewRoundMutationVariables
  >(CompleteReviewRoundDocument, options);
}
export type CompleteReviewRoundMutationHookResult = ReturnType<
  typeof useCompleteReviewRoundMutation
>;
export type CompleteReviewRoundMutationResult =
  Apollo.MutationResult<CompleteReviewRoundMutation>;
export type CompleteReviewRoundMutationOptions = Apollo.BaseMutationOptions<
  CompleteReviewRoundMutation,
  CompleteReviewRoundMutationVariables
>;
export const ReturnReviewRevisionDocument = gql`
  mutation ReturnReviewRevision($reviewId: ID!) {
    returnReviewRevision(reviewId: $reviewId) {
      review {
        id
        completedAt
      }
      errors {
        message
      }
    }
  }
`;
export type ReturnReviewRevisionMutationFn = Apollo.MutationFunction<
  ReturnReviewRevisionMutation,
  ReturnReviewRevisionMutationVariables
>;

/**
 * __useReturnReviewRevisionMutation__
 *
 * To run a mutation, you first call `useReturnReviewRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnReviewRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnReviewRevisionMutation, { data, loading, error }] = useReturnReviewRevisionMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useReturnReviewRevisionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReturnReviewRevisionMutation,
    ReturnReviewRevisionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReturnReviewRevisionMutation,
    ReturnReviewRevisionMutationVariables
  >(ReturnReviewRevisionDocument, options);
}
export type ReturnReviewRevisionMutationHookResult = ReturnType<
  typeof useReturnReviewRevisionMutation
>;
export type ReturnReviewRevisionMutationResult =
  Apollo.MutationResult<ReturnReviewRevisionMutation>;
export type ReturnReviewRevisionMutationOptions = Apollo.BaseMutationOptions<
  ReturnReviewRevisionMutation,
  ReturnReviewRevisionMutationVariables
>;
export const ReviewSidebarDocument = gql`
  query ReviewSidebar($id: ID!, $customerId: ID) {
    review(id: $id, customerId: $customerId) {
      ...ReviewWithFormFragment
      ...ReviewRoundsFragment
    }
  }
  ${ReviewWithFormFragmentFragmentDoc}
  ${ReviewFragmentFragmentDoc}
  ${ReviewRoundsFragmentFragmentDoc}
`;

/**
 * __useReviewSidebarQuery__
 *
 * To run a query within a React component, call `useReviewSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewSidebarQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useReviewSidebarQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReviewSidebarQuery,
    ReviewSidebarQueryVariables
  > &
    (
      | { variables: ReviewSidebarQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewSidebarQuery, ReviewSidebarQueryVariables>(
    ReviewSidebarDocument,
    options,
  );
}
export function useReviewSidebarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReviewSidebarQuery,
    ReviewSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewSidebarQuery, ReviewSidebarQueryVariables>(
    ReviewSidebarDocument,
    options,
  );
}
export function useReviewSidebarSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReviewSidebarQuery,
        ReviewSidebarQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReviewSidebarQuery,
    ReviewSidebarQueryVariables
  >(ReviewSidebarDocument, options);
}
export type ReviewSidebarQueryHookResult = ReturnType<
  typeof useReviewSidebarQuery
>;
export type ReviewSidebarLazyQueryHookResult = ReturnType<
  typeof useReviewSidebarLazyQuery
>;
export type ReviewSidebarSuspenseQueryHookResult = ReturnType<
  typeof useReviewSidebarSuspenseQuery
>;
export type ReviewSidebarQueryResult = Apollo.QueryResult<
  ReviewSidebarQuery,
  ReviewSidebarQueryVariables
>;
export const FaqDocument = gql`
  query Faq($category: String!) {
    faq(category: $category) {
      category
      questions {
        question
        order
        answer
      }
    }
  }
`;

/**
 * __useFaqQuery__
 *
 * To run a query within a React component, call `useFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useFaqQuery(
  baseOptions: Apollo.QueryHookOptions<FaqQuery, FaqQueryVariables> &
    ({ variables: FaqQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FaqQuery, FaqQueryVariables>(FaqDocument, options);
}
export function useFaqLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FaqQuery, FaqQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FaqQuery, FaqQueryVariables>(FaqDocument, options);
}
export function useFaqSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FaqQuery, FaqQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FaqQuery, FaqQueryVariables>(
    FaqDocument,
    options,
  );
}
export type FaqQueryHookResult = ReturnType<typeof useFaqQuery>;
export type FaqLazyQueryHookResult = ReturnType<typeof useFaqLazyQuery>;
export type FaqSuspenseQueryHookResult = ReturnType<typeof useFaqSuspenseQuery>;
export type FaqQueryResult = Apollo.QueryResult<FaqQuery, FaqQueryVariables>;
export const LearnDashboardReadStatusDocument = gql`
  query LearnDashboardReadStatus {
    learnBoardReadStatus {
      articleSeriesReadStatusItems {
        articleSeriesId
        totalItemCount
        readInprogressArticleIds
        readCompletedArticleIds
      }
    }
  }
`;

/**
 * __useLearnDashboardReadStatusQuery__
 *
 * To run a query within a React component, call `useLearnDashboardReadStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnDashboardReadStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnDashboardReadStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useLearnDashboardReadStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LearnDashboardReadStatusQuery,
    LearnDashboardReadStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LearnDashboardReadStatusQuery,
    LearnDashboardReadStatusQueryVariables
  >(LearnDashboardReadStatusDocument, options);
}
export function useLearnDashboardReadStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LearnDashboardReadStatusQuery,
    LearnDashboardReadStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LearnDashboardReadStatusQuery,
    LearnDashboardReadStatusQueryVariables
  >(LearnDashboardReadStatusDocument, options);
}
export function useLearnDashboardReadStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LearnDashboardReadStatusQuery,
        LearnDashboardReadStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LearnDashboardReadStatusQuery,
    LearnDashboardReadStatusQueryVariables
  >(LearnDashboardReadStatusDocument, options);
}
export type LearnDashboardReadStatusQueryHookResult = ReturnType<
  typeof useLearnDashboardReadStatusQuery
>;
export type LearnDashboardReadStatusLazyQueryHookResult = ReturnType<
  typeof useLearnDashboardReadStatusLazyQuery
>;
export type LearnDashboardReadStatusSuspenseQueryHookResult = ReturnType<
  typeof useLearnDashboardReadStatusSuspenseQuery
>;
export type LearnDashboardReadStatusQueryResult = Apollo.QueryResult<
  LearnDashboardReadStatusQuery,
  LearnDashboardReadStatusQueryVariables
>;
export const LearnDashboardDocument = gql`
  query LearnDashboard($public: Boolean) {
    learnBoardArticleSeries(public: $public) {
      recentSeriesItems {
        id
        title
        urlSlug
      }
      popularSeriesItems {
        id
        title
        urlSlug
      }
      otherSeriesItems {
        id
        title
        urlSlug
        description
      }
    }
  }
`;

/**
 * __useLearnDashboardQuery__
 *
 * To run a query within a React component, call `useLearnDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnDashboardQuery({
 *   variables: {
 *      public: // value for 'public'
 *   },
 * });
 */
export function useLearnDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LearnDashboardQuery,
    LearnDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LearnDashboardQuery, LearnDashboardQueryVariables>(
    LearnDashboardDocument,
    options,
  );
}
export function useLearnDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LearnDashboardQuery,
    LearnDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LearnDashboardQuery, LearnDashboardQueryVariables>(
    LearnDashboardDocument,
    options,
  );
}
export function useLearnDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LearnDashboardQuery,
        LearnDashboardQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LearnDashboardQuery,
    LearnDashboardQueryVariables
  >(LearnDashboardDocument, options);
}
export type LearnDashboardQueryHookResult = ReturnType<
  typeof useLearnDashboardQuery
>;
export type LearnDashboardLazyQueryHookResult = ReturnType<
  typeof useLearnDashboardLazyQuery
>;
export type LearnDashboardSuspenseQueryHookResult = ReturnType<
  typeof useLearnDashboardSuspenseQuery
>;
export type LearnDashboardQueryResult = Apollo.QueryResult<
  LearnDashboardQuery,
  LearnDashboardQueryVariables
>;
export const RecentArticlesDocument = gql`
  query RecentArticles($page: Int!, $pageSize: Int!, $public: Boolean) {
    recentArticles(public: $public, page: $page, pageSize: $pageSize) {
      page
      pageSize
      total
      totalPages
      items {
        id
        title
        timeToRead
        urlSlug
        public
        heroImage
      }
    }
  }
`;

/**
 * __useRecentArticlesQuery__
 *
 * To run a query within a React component, call `useRecentArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentArticlesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      public: // value for 'public'
 *   },
 * });
 */
export function useRecentArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecentArticlesQuery,
    RecentArticlesQueryVariables
  > &
    (
      | { variables: RecentArticlesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentArticlesQuery, RecentArticlesQueryVariables>(
    RecentArticlesDocument,
    options,
  );
}
export function useRecentArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentArticlesQuery,
    RecentArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentArticlesQuery, RecentArticlesQueryVariables>(
    RecentArticlesDocument,
    options,
  );
}
export function useRecentArticlesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RecentArticlesQuery,
        RecentArticlesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RecentArticlesQuery,
    RecentArticlesQueryVariables
  >(RecentArticlesDocument, options);
}
export type RecentArticlesQueryHookResult = ReturnType<
  typeof useRecentArticlesQuery
>;
export type RecentArticlesLazyQueryHookResult = ReturnType<
  typeof useRecentArticlesLazyQuery
>;
export type RecentArticlesSuspenseQueryHookResult = ReturnType<
  typeof useRecentArticlesSuspenseQuery
>;
export type RecentArticlesQueryResult = Apollo.QueryResult<
  RecentArticlesQuery,
  RecentArticlesQueryVariables
>;
export const ArticleSeriesReadStatusDocument = gql`
  query ArticleSeriesReadStatus($slug: String!) {
    articleSeriesReadStatus(slug: $slug) {
      articleSeriesId
      totalItemCount
      readInprogressArticleIds
      readCompletedArticleIds
    }
  }
`;

/**
 * __useArticleSeriesReadStatusQuery__
 *
 * To run a query within a React component, call `useArticleSeriesReadStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleSeriesReadStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleSeriesReadStatusQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArticleSeriesReadStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticleSeriesReadStatusQuery,
    ArticleSeriesReadStatusQueryVariables
  > &
    (
      | { variables: ArticleSeriesReadStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ArticleSeriesReadStatusQuery,
    ArticleSeriesReadStatusQueryVariables
  >(ArticleSeriesReadStatusDocument, options);
}
export function useArticleSeriesReadStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleSeriesReadStatusQuery,
    ArticleSeriesReadStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ArticleSeriesReadStatusQuery,
    ArticleSeriesReadStatusQueryVariables
  >(ArticleSeriesReadStatusDocument, options);
}
export function useArticleSeriesReadStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ArticleSeriesReadStatusQuery,
        ArticleSeriesReadStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ArticleSeriesReadStatusQuery,
    ArticleSeriesReadStatusQueryVariables
  >(ArticleSeriesReadStatusDocument, options);
}
export type ArticleSeriesReadStatusQueryHookResult = ReturnType<
  typeof useArticleSeriesReadStatusQuery
>;
export type ArticleSeriesReadStatusLazyQueryHookResult = ReturnType<
  typeof useArticleSeriesReadStatusLazyQuery
>;
export type ArticleSeriesReadStatusSuspenseQueryHookResult = ReturnType<
  typeof useArticleSeriesReadStatusSuspenseQuery
>;
export type ArticleSeriesReadStatusQueryResult = Apollo.QueryResult<
  ArticleSeriesReadStatusQuery,
  ArticleSeriesReadStatusQueryVariables
>;
export const ParseLinkMetadataDocument = gql`
  mutation ParseLinkMetadata($link: String!) {
    parseLinkMetadata(link: $link) {
      ok
      error {
        field
        code
      }
      data {
        text
        imageSrc
      }
    }
  }
`;
export type ParseLinkMetadataMutationFn = Apollo.MutationFunction<
  ParseLinkMetadataMutation,
  ParseLinkMetadataMutationVariables
>;

/**
 * __useParseLinkMetadataMutation__
 *
 * To run a mutation, you first call `useParseLinkMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseLinkMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseLinkMetadataMutation, { data, loading, error }] = useParseLinkMetadataMutation({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useParseLinkMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ParseLinkMetadataMutation,
    ParseLinkMetadataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ParseLinkMetadataMutation,
    ParseLinkMetadataMutationVariables
  >(ParseLinkMetadataDocument, options);
}
export type ParseLinkMetadataMutationHookResult = ReturnType<
  typeof useParseLinkMetadataMutation
>;
export type ParseLinkMetadataMutationResult =
  Apollo.MutationResult<ParseLinkMetadataMutation>;
export type ParseLinkMetadataMutationOptions = Apollo.BaseMutationOptions<
  ParseLinkMetadataMutation,
  ParseLinkMetadataMutationVariables
>;
export const BillingSessionDocument = gql`
  mutation BillingSession($returnUrl: String!) {
    billingSession(returnUrl: $returnUrl) {
      url
      errors {
        field
        message
      }
    }
  }
`;
export type BillingSessionMutationFn = Apollo.MutationFunction<
  BillingSessionMutation,
  BillingSessionMutationVariables
>;

/**
 * __useBillingSessionMutation__
 *
 * To run a mutation, you first call `useBillingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingSessionMutation, { data, loading, error }] = useBillingSessionMutation({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useBillingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BillingSessionMutation,
    BillingSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BillingSessionMutation,
    BillingSessionMutationVariables
  >(BillingSessionDocument, options);
}
export type BillingSessionMutationHookResult = ReturnType<
  typeof useBillingSessionMutation
>;
export type BillingSessionMutationResult =
  Apollo.MutationResult<BillingSessionMutation>;
export type BillingSessionMutationOptions = Apollo.BaseMutationOptions<
  BillingSessionMutation,
  BillingSessionMutationVariables
>;
export const NewSubscriptionDocument = gql`
  mutation NewSubscription($successUrl: String!, $cancelUrl: String) {
    newSubscription(successUrl: $successUrl, cancelUrl: $cancelUrl) {
      url
      errors {
        field
        message
      }
    }
  }
`;
export type NewSubscriptionMutationFn = Apollo.MutationFunction<
  NewSubscriptionMutation,
  NewSubscriptionMutationVariables
>;

/**
 * __useNewSubscriptionMutation__
 *
 * To run a mutation, you first call `useNewSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newSubscriptionMutation, { data, loading, error }] = useNewSubscriptionMutation({
 *   variables: {
 *      successUrl: // value for 'successUrl'
 *      cancelUrl: // value for 'cancelUrl'
 *   },
 * });
 */
export function useNewSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NewSubscriptionMutation,
    NewSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NewSubscriptionMutation,
    NewSubscriptionMutationVariables
  >(NewSubscriptionDocument, options);
}
export type NewSubscriptionMutationHookResult = ReturnType<
  typeof useNewSubscriptionMutation
>;
export type NewSubscriptionMutationResult =
  Apollo.MutationResult<NewSubscriptionMutation>;
export type NewSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  NewSubscriptionMutation,
  NewSubscriptionMutationVariables
>;
export const PaymentMethodsDocument = gql`
  query PaymentMethods($customerId: ID) {
    paymentMethods(customerId: $customerId) {
      stripeId
      brand
      last4
    }
  }
`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function usePaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PaymentMethodsDocument,
    options,
  );
}
export function usePaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PaymentMethodsDocument,
    options,
  );
}
export function usePaymentMethodsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PaymentMethodsQuery,
        PaymentMethodsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >(PaymentMethodsDocument, options);
}
export type PaymentMethodsQueryHookResult = ReturnType<
  typeof usePaymentMethodsQuery
>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<
  typeof usePaymentMethodsLazyQuery
>;
export type PaymentMethodsSuspenseQueryHookResult = ReturnType<
  typeof usePaymentMethodsSuspenseQuery
>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables
>;
export const RedeemGiftCodeDocument = gql`
  mutation RedeemGiftCode($code: String!, $orderId: ID) {
    redeemGiftCode(code: $code, orderId: $orderId) {
      ok
      creditsRedeemed
      errors {
        message
      }
      order {
        ...CheckoutOrderFragment
      }
    }
  }
  ${CheckoutOrderFragmentFragmentDoc}
  ${UserAvailableCreditsFragmentFragmentDoc}
`;
export type RedeemGiftCodeMutationFn = Apollo.MutationFunction<
  RedeemGiftCodeMutation,
  RedeemGiftCodeMutationVariables
>;

/**
 * __useRedeemGiftCodeMutation__
 *
 * To run a mutation, you first call `useRedeemGiftCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemGiftCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemGiftCodeMutation, { data, loading, error }] = useRedeemGiftCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useRedeemGiftCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedeemGiftCodeMutation,
    RedeemGiftCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RedeemGiftCodeMutation,
    RedeemGiftCodeMutationVariables
  >(RedeemGiftCodeDocument, options);
}
export type RedeemGiftCodeMutationHookResult = ReturnType<
  typeof useRedeemGiftCodeMutation
>;
export type RedeemGiftCodeMutationResult =
  Apollo.MutationResult<RedeemGiftCodeMutation>;
export type RedeemGiftCodeMutationOptions = Apollo.BaseMutationOptions<
  RedeemGiftCodeMutation,
  RedeemGiftCodeMutationVariables
>;
export const RemovePaymentMethodDocument = gql`
  mutation RemovePaymentMethod($stripeId: String!) {
    removePaymentMethod(stripeId: $stripeId) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type RemovePaymentMethodMutationFn = Apollo.MutationFunction<
  RemovePaymentMethodMutation,
  RemovePaymentMethodMutationVariables
>;

/**
 * __useRemovePaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemovePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePaymentMethodMutation, { data, loading, error }] = useRemovePaymentMethodMutation({
 *   variables: {
 *      stripeId: // value for 'stripeId'
 *   },
 * });
 */
export function useRemovePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePaymentMethodMutation,
    RemovePaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePaymentMethodMutation,
    RemovePaymentMethodMutationVariables
  >(RemovePaymentMethodDocument, options);
}
export type RemovePaymentMethodMutationHookResult = ReturnType<
  typeof useRemovePaymentMethodMutation
>;
export type RemovePaymentMethodMutationResult =
  Apollo.MutationResult<RemovePaymentMethodMutation>;
export type RemovePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  RemovePaymentMethodMutation,
  RemovePaymentMethodMutationVariables
>;
export const SubscriptionDocument = gql`
  query Subscription {
    currentUser {
      id
      subscription {
        id
        status
        validUntil
        stripeSubscriptionId
      }
    }
  }
`;

/**
 * __useSubscriptionQuery__
 *
 * To run a query within a React component, call `useSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubscriptionQuery,
    SubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionQuery, SubscriptionQueryVariables>(
    SubscriptionDocument,
    options,
  );
}
export function useSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionQuery,
    SubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionQuery, SubscriptionQueryVariables>(
    SubscriptionDocument,
    options,
  );
}
export function useSubscriptionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SubscriptionQuery,
        SubscriptionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubscriptionQuery, SubscriptionQueryVariables>(
    SubscriptionDocument,
    options,
  );
}
export type SubscriptionQueryHookResult = ReturnType<
  typeof useSubscriptionQuery
>;
export type SubscriptionLazyQueryHookResult = ReturnType<
  typeof useSubscriptionLazyQuery
>;
export type SubscriptionSuspenseQueryHookResult = ReturnType<
  typeof useSubscriptionSuspenseQuery
>;
export type SubscriptionQueryResult = Apollo.QueryResult<
  SubscriptionQuery,
  SubscriptionQueryVariables
>;
export const SaveNotificationPreferencesDocument = gql`
  mutation SaveNotificationPreferences($input: NotificationPreferenceInput!) {
    saveNotificationPreferences(input: $input) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type SaveNotificationPreferencesMutationFn = Apollo.MutationFunction<
  SaveNotificationPreferencesMutation,
  SaveNotificationPreferencesMutationVariables
>;

/**
 * __useSaveNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useSaveNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNotificationPreferencesMutation, { data, loading, error }] = useSaveNotificationPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveNotificationPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveNotificationPreferencesMutation,
    SaveNotificationPreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveNotificationPreferencesMutation,
    SaveNotificationPreferencesMutationVariables
  >(SaveNotificationPreferencesDocument, options);
}
export type SaveNotificationPreferencesMutationHookResult = ReturnType<
  typeof useSaveNotificationPreferencesMutation
>;
export type SaveNotificationPreferencesMutationResult =
  Apollo.MutationResult<SaveNotificationPreferencesMutation>;
export type SaveNotificationPreferencesMutationOptions =
  Apollo.BaseMutationOptions<
    SaveNotificationPreferencesMutation,
    SaveNotificationPreferencesMutationVariables
  >;
export const ResumesDocument = gql`
  query Resumes {
    resumes {
      id
      name
      updatedAt
    }
  }
`;

/**
 * __useResumesQuery__
 *
 * To run a query within a React component, call `useResumesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumesQuery({
 *   variables: {
 *   },
 * });
 */
export function useResumesQuery(
  baseOptions?: Apollo.QueryHookOptions<ResumesQuery, ResumesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResumesQuery, ResumesQueryVariables>(
    ResumesDocument,
    options,
  );
}
export function useResumesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResumesQuery,
    ResumesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResumesQuery, ResumesQueryVariables>(
    ResumesDocument,
    options,
  );
}
export function useResumesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ResumesQuery, ResumesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ResumesQuery, ResumesQueryVariables>(
    ResumesDocument,
    options,
  );
}
export type ResumesQueryHookResult = ReturnType<typeof useResumesQuery>;
export type ResumesLazyQueryHookResult = ReturnType<typeof useResumesLazyQuery>;
export type ResumesSuspenseQueryHookResult = ReturnType<
  typeof useResumesSuspenseQuery
>;
export type ResumesQueryResult = Apollo.QueryResult<
  ResumesQuery,
  ResumesQueryVariables
>;
export const SaveResumeDocument = gql`
  mutation SaveResume($id: ID!, $name: String, $archived: Boolean) {
    saveResume(id: $id, name: $name, archived: $archived) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type SaveResumeMutationFn = Apollo.MutationFunction<
  SaveResumeMutation,
  SaveResumeMutationVariables
>;

/**
 * __useSaveResumeMutation__
 *
 * To run a mutation, you first call `useSaveResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveResumeMutation, { data, loading, error }] = useSaveResumeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useSaveResumeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveResumeMutation,
    SaveResumeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveResumeMutation, SaveResumeMutationVariables>(
    SaveResumeDocument,
    options,
  );
}
export type SaveResumeMutationHookResult = ReturnType<
  typeof useSaveResumeMutation
>;
export type SaveResumeMutationResult =
  Apollo.MutationResult<SaveResumeMutation>;
export type SaveResumeMutationOptions = Apollo.BaseMutationOptions<
  SaveResumeMutation,
  SaveResumeMutationVariables
>;
export const SaveCustomerFeedbackDocument = gql`
  mutation SaveCustomerFeedback($input: CustomerFeedbackInput!) {
    saveCustomerFeedback(input: $input) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type SaveCustomerFeedbackMutationFn = Apollo.MutationFunction<
  SaveCustomerFeedbackMutation,
  SaveCustomerFeedbackMutationVariables
>;

/**
 * __useSaveCustomerFeedbackMutation__
 *
 * To run a mutation, you first call `useSaveCustomerFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerFeedbackMutation, { data, loading, error }] = useSaveCustomerFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCustomerFeedbackMutation,
    SaveCustomerFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCustomerFeedbackMutation,
    SaveCustomerFeedbackMutationVariables
  >(SaveCustomerFeedbackDocument, options);
}
export type SaveCustomerFeedbackMutationHookResult = ReturnType<
  typeof useSaveCustomerFeedbackMutation
>;
export type SaveCustomerFeedbackMutationResult =
  Apollo.MutationResult<SaveCustomerFeedbackMutation>;
export type SaveCustomerFeedbackMutationOptions = Apollo.BaseMutationOptions<
  SaveCustomerFeedbackMutation,
  SaveCustomerFeedbackMutationVariables
>;
export const UpdatePersonalInfoDocument = gql`
  mutation UpdatePersonalInfo($input: UserInput!) {
    updateUser(input: $input) {
      user {
        id
        email
        name
        phoneNumber
        jobTitle
        linkedinAccount
        timeZone
      }
      errors {
        field
        message
      }
    }
  }
`;
export type UpdatePersonalInfoMutationFn = Apollo.MutationFunction<
  UpdatePersonalInfoMutation,
  UpdatePersonalInfoMutationVariables
>;

/**
 * __useUpdatePersonalInfoMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalInfoMutation, { data, loading, error }] = useUpdatePersonalInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePersonalInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonalInfoMutation,
    UpdatePersonalInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonalInfoMutation,
    UpdatePersonalInfoMutationVariables
  >(UpdatePersonalInfoDocument, options);
}
export type UpdatePersonalInfoMutationHookResult = ReturnType<
  typeof useUpdatePersonalInfoMutation
>;
export type UpdatePersonalInfoMutationResult =
  Apollo.MutationResult<UpdatePersonalInfoMutation>;
export type UpdatePersonalInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonalInfoMutation,
  UpdatePersonalInfoMutationVariables
>;
export const ArchiveRoleplaySessionDocument = gql`
  mutation ArchiveRoleplaySession($id: ID!) {
    archiveRoleplaySession(id: $id) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type ArchiveRoleplaySessionMutationFn = Apollo.MutationFunction<
  ArchiveRoleplaySessionMutation,
  ArchiveRoleplaySessionMutationVariables
>;

/**
 * __useArchiveRoleplaySessionMutation__
 *
 * To run a mutation, you first call `useArchiveRoleplaySessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveRoleplaySessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveRoleplaySessionMutation, { data, loading, error }] = useArchiveRoleplaySessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveRoleplaySessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveRoleplaySessionMutation,
    ArchiveRoleplaySessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveRoleplaySessionMutation,
    ArchiveRoleplaySessionMutationVariables
  >(ArchiveRoleplaySessionDocument, options);
}
export type ArchiveRoleplaySessionMutationHookResult = ReturnType<
  typeof useArchiveRoleplaySessionMutation
>;
export type ArchiveRoleplaySessionMutationResult =
  Apollo.MutationResult<ArchiveRoleplaySessionMutation>;
export type ArchiveRoleplaySessionMutationOptions = Apollo.BaseMutationOptions<
  ArchiveRoleplaySessionMutation,
  ArchiveRoleplaySessionMutationVariables
>;
export const ArchiveScenarioDocument = gql`
  mutation ArchiveScenario($id: ID!) {
    archiveScenario(id: $id) {
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type ArchiveScenarioMutationFn = Apollo.MutationFunction<
  ArchiveScenarioMutation,
  ArchiveScenarioMutationVariables
>;

/**
 * __useArchiveScenarioMutation__
 *
 * To run a mutation, you first call `useArchiveScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveScenarioMutation, { data, loading, error }] = useArchiveScenarioMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveScenarioMutation,
    ArchiveScenarioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveScenarioMutation,
    ArchiveScenarioMutationVariables
  >(ArchiveScenarioDocument, options);
}
export type ArchiveScenarioMutationHookResult = ReturnType<
  typeof useArchiveScenarioMutation
>;
export type ArchiveScenarioMutationResult =
  Apollo.MutationResult<ArchiveScenarioMutation>;
export type ArchiveScenarioMutationOptions = Apollo.BaseMutationOptions<
  ArchiveScenarioMutation,
  ArchiveScenarioMutationVariables
>;
export const CloneCollectionDocument = gql`
  mutation CloneCollection(
    $collectionId: ID!
    $name: String!
    $visibilityScope: VisibilityScope!
    $emojiUnicode: String
  ) {
    cloneCollection(
      collectionId: $collectionId
      name: $name
      visibilityScope: $visibilityScope
      emojiUnicode: $emojiUnicode
    ) {
      ok
      errors {
        message
      }
      collection {
        id
        name
        visibilityScope
        slug
      }
    }
  }
`;
export type CloneCollectionMutationFn = Apollo.MutationFunction<
  CloneCollectionMutation,
  CloneCollectionMutationVariables
>;

/**
 * __useCloneCollectionMutation__
 *
 * To run a mutation, you first call `useCloneCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneCollectionMutation, { data, loading, error }] = useCloneCollectionMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      name: // value for 'name'
 *      visibilityScope: // value for 'visibilityScope'
 *      emojiUnicode: // value for 'emojiUnicode'
 *   },
 * });
 */
export function useCloneCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneCollectionMutation,
    CloneCollectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloneCollectionMutation,
    CloneCollectionMutationVariables
  >(CloneCollectionDocument, options);
}
export type CloneCollectionMutationHookResult = ReturnType<
  typeof useCloneCollectionMutation
>;
export type CloneCollectionMutationResult =
  Apollo.MutationResult<CloneCollectionMutation>;
export type CloneCollectionMutationOptions = Apollo.BaseMutationOptions<
  CloneCollectionMutation,
  CloneCollectionMutationVariables
>;
export const CloneScenarioDocument = gql`
  mutation CloneScenario(
    $scenarioId: ID!
    $name: String!
    $visibilityScope: VisibilityScope!
    $collectionIds: [ID]
  ) {
    cloneScenario(
      scenarioId: $scenarioId
      name: $name
      visibilityScope: $visibilityScope
      collectionIds: $collectionIds
    ) {
      ok
      errors {
        message
      }
      scenario {
        id
        name
        visibilityScope
        slug
      }
    }
  }
`;
export type CloneScenarioMutationFn = Apollo.MutationFunction<
  CloneScenarioMutation,
  CloneScenarioMutationVariables
>;

/**
 * __useCloneScenarioMutation__
 *
 * To run a mutation, you first call `useCloneScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneScenarioMutation, { data, loading, error }] = useCloneScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      name: // value for 'name'
 *      visibilityScope: // value for 'visibilityScope'
 *      collectionIds: // value for 'collectionIds'
 *   },
 * });
 */
export function useCloneScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneScenarioMutation,
    CloneScenarioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloneScenarioMutation,
    CloneScenarioMutationVariables
  >(CloneScenarioDocument, options);
}
export type CloneScenarioMutationHookResult = ReturnType<
  typeof useCloneScenarioMutation
>;
export type CloneScenarioMutationResult =
  Apollo.MutationResult<CloneScenarioMutation>;
export type CloneScenarioMutationOptions = Apollo.BaseMutationOptions<
  CloneScenarioMutation,
  CloneScenarioMutationVariables
>;
export const GetCoachAllTimeslotsDocument = gql`
  query GetCoachAllTimeslots(
    $productSlug: String!
    $start: DateTime!
    $end: DateTime!
    $coachId: ID
    $coachSlug: String
    $ignoreMeetingId: ID
    $timeZone: String
    $usedAllTimeslot: Boolean = true
  ) {
    coachAvailableTimeslots(
      productSlug: $productSlug
      start: $start
      end: $end
      coachId: $coachId
      coachSlug: $coachSlug
      ignoreMeetingId: $ignoreMeetingId
      timeZone: $timeZone
      usedAllTimeslot: $usedAllTimeslot
    ) {
      timeslots
      timezone
    }
  }
`;

/**
 * __useGetCoachAllTimeslotsQuery__
 *
 * To run a query within a React component, call `useGetCoachAllTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachAllTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachAllTimeslotsQuery({
 *   variables: {
 *      productSlug: // value for 'productSlug'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      coachId: // value for 'coachId'
 *      coachSlug: // value for 'coachSlug'
 *      ignoreMeetingId: // value for 'ignoreMeetingId'
 *      timeZone: // value for 'timeZone'
 *      usedAllTimeslot: // value for 'usedAllTimeslot'
 *   },
 * });
 */
export function useGetCoachAllTimeslotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoachAllTimeslotsQuery,
    GetCoachAllTimeslotsQueryVariables
  > &
    (
      | { variables: GetCoachAllTimeslotsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCoachAllTimeslotsQuery,
    GetCoachAllTimeslotsQueryVariables
  >(GetCoachAllTimeslotsDocument, options);
}
export function useGetCoachAllTimeslotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachAllTimeslotsQuery,
    GetCoachAllTimeslotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoachAllTimeslotsQuery,
    GetCoachAllTimeslotsQueryVariables
  >(GetCoachAllTimeslotsDocument, options);
}
export function useGetCoachAllTimeslotsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCoachAllTimeslotsQuery,
        GetCoachAllTimeslotsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCoachAllTimeslotsQuery,
    GetCoachAllTimeslotsQueryVariables
  >(GetCoachAllTimeslotsDocument, options);
}
export type GetCoachAllTimeslotsQueryHookResult = ReturnType<
  typeof useGetCoachAllTimeslotsQuery
>;
export type GetCoachAllTimeslotsLazyQueryHookResult = ReturnType<
  typeof useGetCoachAllTimeslotsLazyQuery
>;
export type GetCoachAllTimeslotsSuspenseQueryHookResult = ReturnType<
  typeof useGetCoachAllTimeslotsSuspenseQuery
>;
export type GetCoachAllTimeslotsQueryResult = Apollo.QueryResult<
  GetCoachAllTimeslotsQuery,
  GetCoachAllTimeslotsQueryVariables
>;
export const GetCoachAvailableTimeslotsDocument = gql`
  query GetCoachAvailableTimeslots(
    $productSlug: String!
    $start: DateTime!
    $end: DateTime!
    $coachId: ID
    $coachSlug: String
    $ignoreMeetingId: ID
    $timeZone: String
  ) {
    coachAvailableTimeslots(
      productSlug: $productSlug
      start: $start
      end: $end
      coachId: $coachId
      coachSlug: $coachSlug
      ignoreMeetingId: $ignoreMeetingId
      timeZone: $timeZone
    ) {
      timeslots
      timezone
    }
  }
`;

/**
 * __useGetCoachAvailableTimeslotsQuery__
 *
 * To run a query within a React component, call `useGetCoachAvailableTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachAvailableTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachAvailableTimeslotsQuery({
 *   variables: {
 *      productSlug: // value for 'productSlug'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      coachId: // value for 'coachId'
 *      coachSlug: // value for 'coachSlug'
 *      ignoreMeetingId: // value for 'ignoreMeetingId'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetCoachAvailableTimeslotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoachAvailableTimeslotsQuery,
    GetCoachAvailableTimeslotsQueryVariables
  > &
    (
      | { variables: GetCoachAvailableTimeslotsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCoachAvailableTimeslotsQuery,
    GetCoachAvailableTimeslotsQueryVariables
  >(GetCoachAvailableTimeslotsDocument, options);
}
export function useGetCoachAvailableTimeslotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachAvailableTimeslotsQuery,
    GetCoachAvailableTimeslotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoachAvailableTimeslotsQuery,
    GetCoachAvailableTimeslotsQueryVariables
  >(GetCoachAvailableTimeslotsDocument, options);
}
export function useGetCoachAvailableTimeslotsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCoachAvailableTimeslotsQuery,
        GetCoachAvailableTimeslotsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCoachAvailableTimeslotsQuery,
    GetCoachAvailableTimeslotsQueryVariables
  >(GetCoachAvailableTimeslotsDocument, options);
}
export type GetCoachAvailableTimeslotsQueryHookResult = ReturnType<
  typeof useGetCoachAvailableTimeslotsQuery
>;
export type GetCoachAvailableTimeslotsLazyQueryHookResult = ReturnType<
  typeof useGetCoachAvailableTimeslotsLazyQuery
>;
export type GetCoachAvailableTimeslotsSuspenseQueryHookResult = ReturnType<
  typeof useGetCoachAvailableTimeslotsSuspenseQuery
>;
export type GetCoachAvailableTimeslotsQueryResult = Apollo.QueryResult<
  GetCoachAvailableTimeslotsQuery,
  GetCoachAvailableTimeslotsQueryVariables
>;
export const GetCoachPersonalAvailableTimeslotsDocument = gql`
  query GetCoachPersonalAvailableTimeslots(
    $start: DateTime!
    $end: DateTime!
    $timeslotDuration: Int!
  ) {
    coachPersonalAvailableTimeslots(
      start: $start
      end: $end
      timeslotDuration: $timeslotDuration
    ) {
      timeslots
      timezone
    }
  }
`;

/**
 * __useGetCoachPersonalAvailableTimeslotsQuery__
 *
 * To run a query within a React component, call `useGetCoachPersonalAvailableTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachPersonalAvailableTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachPersonalAvailableTimeslotsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      timeslotDuration: // value for 'timeslotDuration'
 *   },
 * });
 */
export function useGetCoachPersonalAvailableTimeslotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoachPersonalAvailableTimeslotsQuery,
    GetCoachPersonalAvailableTimeslotsQueryVariables
  > &
    (
      | {
          variables: GetCoachPersonalAvailableTimeslotsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCoachPersonalAvailableTimeslotsQuery,
    GetCoachPersonalAvailableTimeslotsQueryVariables
  >(GetCoachPersonalAvailableTimeslotsDocument, options);
}
export function useGetCoachPersonalAvailableTimeslotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachPersonalAvailableTimeslotsQuery,
    GetCoachPersonalAvailableTimeslotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoachPersonalAvailableTimeslotsQuery,
    GetCoachPersonalAvailableTimeslotsQueryVariables
  >(GetCoachPersonalAvailableTimeslotsDocument, options);
}
export function useGetCoachPersonalAvailableTimeslotsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCoachPersonalAvailableTimeslotsQuery,
        GetCoachPersonalAvailableTimeslotsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCoachPersonalAvailableTimeslotsQuery,
    GetCoachPersonalAvailableTimeslotsQueryVariables
  >(GetCoachPersonalAvailableTimeslotsDocument, options);
}
export type GetCoachPersonalAvailableTimeslotsQueryHookResult = ReturnType<
  typeof useGetCoachPersonalAvailableTimeslotsQuery
>;
export type GetCoachPersonalAvailableTimeslotsLazyQueryHookResult = ReturnType<
  typeof useGetCoachPersonalAvailableTimeslotsLazyQuery
>;
export type GetCoachPersonalAvailableTimeslotsSuspenseQueryHookResult =
  ReturnType<typeof useGetCoachPersonalAvailableTimeslotsSuspenseQuery>;
export type GetCoachPersonalAvailableTimeslotsQueryResult = Apollo.QueryResult<
  GetCoachPersonalAvailableTimeslotsQuery,
  GetCoachPersonalAvailableTimeslotsQueryVariables
>;
export const SaveSearchDocument = gql`
  mutation SaveSearch(
    $query: String!
    $selectedResultType: String
    $selectedResult: JSONString
    $customerId: ID
  ) {
    saveSearch(
      query: $query
      selectedResultType: $selectedResultType
      selectedResult: $selectedResult
      customerId: $customerId
    ) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type SaveSearchMutationFn = Apollo.MutationFunction<
  SaveSearchMutation,
  SaveSearchMutationVariables
>;

/**
 * __useSaveSearchMutation__
 *
 * To run a mutation, you first call `useSaveSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSearchMutation, { data, loading, error }] = useSaveSearchMutation({
 *   variables: {
 *      query: // value for 'query'
 *      selectedResultType: // value for 'selectedResultType'
 *      selectedResult: // value for 'selectedResult'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useSaveSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSearchMutation,
    SaveSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveSearchMutation, SaveSearchMutationVariables>(
    SaveSearchDocument,
    options,
  );
}
export type SaveSearchMutationHookResult = ReturnType<
  typeof useSaveSearchMutation
>;
export type SaveSearchMutationResult =
  Apollo.MutationResult<SaveSearchMutation>;
export type SaveSearchMutationOptions = Apollo.BaseMutationOptions<
  SaveSearchMutation,
  SaveSearchMutationVariables
>;
export const SearchPreferenceDocument = gql`
  query SearchPreference($customerId: ID) {
    customer(id: $customerId) {
      id
      email
      name
    }
    recentSearches {
      id
      query
      customer {
        id
        name
        email
      }
      selectedResultType
      selectedResult
    }
    currentUser {
      id
      isStaff
    }
  }
`;

/**
 * __useSearchPreferenceQuery__
 *
 * To run a query within a React component, call `useSearchPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPreferenceQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useSearchPreferenceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchPreferenceQuery,
    SearchPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPreferenceQuery, SearchPreferenceQueryVariables>(
    SearchPreferenceDocument,
    options,
  );
}
export function useSearchPreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPreferenceQuery,
    SearchPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchPreferenceQuery,
    SearchPreferenceQueryVariables
  >(SearchPreferenceDocument, options);
}
export function useSearchPreferenceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SearchPreferenceQuery,
        SearchPreferenceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SearchPreferenceQuery,
    SearchPreferenceQueryVariables
  >(SearchPreferenceDocument, options);
}
export type SearchPreferenceQueryHookResult = ReturnType<
  typeof useSearchPreferenceQuery
>;
export type SearchPreferenceLazyQueryHookResult = ReturnType<
  typeof useSearchPreferenceLazyQuery
>;
export type SearchPreferenceSuspenseQueryHookResult = ReturnType<
  typeof useSearchPreferenceSuspenseQuery
>;
export type SearchPreferenceQueryResult = Apollo.QueryResult<
  SearchPreferenceQuery,
  SearchPreferenceQueryVariables
>;
export const SlideshowDocument = gql`
  query Slideshow($title: String!) {
    slideshow(title: $title) {
      title
      slides {
        heading
        subtext
        lengthInSeconds
        buttonText
        buttonLink
        secondaryButtonText
        secondaryButtonLink
      }
    }
  }
`;

/**
 * __useSlideshowQuery__
 *
 * To run a query within a React component, call `useSlideshowQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlideshowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlideshowQuery({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useSlideshowQuery(
  baseOptions: Apollo.QueryHookOptions<
    SlideshowQuery,
    SlideshowQueryVariables
  > &
    (
      | { variables: SlideshowQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SlideshowQuery, SlideshowQueryVariables>(
    SlideshowDocument,
    options,
  );
}
export function useSlideshowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SlideshowQuery,
    SlideshowQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SlideshowQuery, SlideshowQueryVariables>(
    SlideshowDocument,
    options,
  );
}
export function useSlideshowSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SlideshowQuery, SlideshowQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SlideshowQuery, SlideshowQueryVariables>(
    SlideshowDocument,
    options,
  );
}
export type SlideshowQueryHookResult = ReturnType<typeof useSlideshowQuery>;
export type SlideshowLazyQueryHookResult = ReturnType<
  typeof useSlideshowLazyQuery
>;
export type SlideshowSuspenseQueryHookResult = ReturnType<
  typeof useSlideshowSuspenseQuery
>;
export type SlideshowQueryResult = Apollo.QueryResult<
  SlideshowQuery,
  SlideshowQueryVariables
>;
export const CoachRatingsDocument = gql`
  query CoachRatings($coachId: ID!, $offset: Int, $limit: Int) {
    coachRatings(coachId: $coachId, offset: $offset, limit: $limit) {
      id
      score
      comment
      truncatedName
      featured
      updatedAt
    }
  }
`;

/**
 * __useCoachRatingsQuery__
 *
 * To run a query within a React component, call `useCoachRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachRatingsQuery({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCoachRatingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachRatingsQuery,
    CoachRatingsQueryVariables
  > &
    (
      | { variables: CoachRatingsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachRatingsQuery, CoachRatingsQueryVariables>(
    CoachRatingsDocument,
    options,
  );
}
export function useCoachRatingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachRatingsQuery,
    CoachRatingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachRatingsQuery, CoachRatingsQueryVariables>(
    CoachRatingsDocument,
    options,
  );
}
export function useCoachRatingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachRatingsQuery,
        CoachRatingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoachRatingsQuery, CoachRatingsQueryVariables>(
    CoachRatingsDocument,
    options,
  );
}
export type CoachRatingsQueryHookResult = ReturnType<
  typeof useCoachRatingsQuery
>;
export type CoachRatingsLazyQueryHookResult = ReturnType<
  typeof useCoachRatingsLazyQuery
>;
export type CoachRatingsSuspenseQueryHookResult = ReturnType<
  typeof useCoachRatingsSuspenseQuery
>;
export type CoachRatingsQueryResult = Apollo.QueryResult<
  CoachRatingsQuery,
  CoachRatingsQueryVariables
>;
export const UpdateRatingFeaturedDocument = gql`
  mutation UpdateRatingFeatured($id: ID!, $isFeatured: Boolean) {
    updateRatingFeatured(id: $id, isFeatured: $isFeatured) {
      ok
      rating {
        id
        featured
      }
    }
  }
`;
export type UpdateRatingFeaturedMutationFn = Apollo.MutationFunction<
  UpdateRatingFeaturedMutation,
  UpdateRatingFeaturedMutationVariables
>;

/**
 * __useUpdateRatingFeaturedMutation__
 *
 * To run a mutation, you first call `useUpdateRatingFeaturedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRatingFeaturedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRatingFeaturedMutation, { data, loading, error }] = useUpdateRatingFeaturedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isFeatured: // value for 'isFeatured'
 *   },
 * });
 */
export function useUpdateRatingFeaturedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRatingFeaturedMutation,
    UpdateRatingFeaturedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRatingFeaturedMutation,
    UpdateRatingFeaturedMutationVariables
  >(UpdateRatingFeaturedDocument, options);
}
export type UpdateRatingFeaturedMutationHookResult = ReturnType<
  typeof useUpdateRatingFeaturedMutation
>;
export type UpdateRatingFeaturedMutationResult =
  Apollo.MutationResult<UpdateRatingFeaturedMutation>;
export type UpdateRatingFeaturedMutationOptions = Apollo.BaseMutationOptions<
  UpdateRatingFeaturedMutation,
  UpdateRatingFeaturedMutationVariables
>;
export const CoachComponentDocument = gql`
  query CoachComponent($customerId: ID) {
    assignments(customerId: $customerId) {
      id
      chatChannelId
      coach {
        id
        name
        firstName
        lastName
        photo
      }
    }
    customer(id: $customerId) {
      id
    }
    currentUser {
      id
    }
  }
`;

/**
 * __useCoachComponentQuery__
 *
 * To run a query within a React component, call `useCoachComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachComponentQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCoachComponentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoachComponentQuery,
    CoachComponentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachComponentQuery, CoachComponentQueryVariables>(
    CoachComponentDocument,
    options,
  );
}
export function useCoachComponentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachComponentQuery,
    CoachComponentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachComponentQuery, CoachComponentQueryVariables>(
    CoachComponentDocument,
    options,
  );
}
export function useCoachComponentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachComponentQuery,
        CoachComponentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CoachComponentQuery,
    CoachComponentQueryVariables
  >(CoachComponentDocument, options);
}
export type CoachComponentQueryHookResult = ReturnType<
  typeof useCoachComponentQuery
>;
export type CoachComponentLazyQueryHookResult = ReturnType<
  typeof useCoachComponentLazyQuery
>;
export type CoachComponentSuspenseQueryHookResult = ReturnType<
  typeof useCoachComponentSuspenseQuery
>;
export type CoachComponentQueryResult = Apollo.QueryResult<
  CoachComponentQuery,
  CoachComponentQueryVariables
>;
export const DeleteTodoDocument = gql`
  mutation DeleteTodo($todoId: ID!, $customerId: ID) {
    deleteTodo(todoId: $todoId, customerId: $customerId) {
      ok
      previousId
      errors {
        field
        messages
      }
    }
  }
`;
export type DeleteTodoMutationFn = Apollo.MutationFunction<
  DeleteTodoMutation,
  DeleteTodoMutationVariables
>;

/**
 * __useDeleteTodoMutation__
 *
 * To run a mutation, you first call `useDeleteTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTodoMutation, { data, loading, error }] = useDeleteTodoMutation({
 *   variables: {
 *      todoId: // value for 'todoId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useDeleteTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTodoMutation,
    DeleteTodoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTodoMutation, DeleteTodoMutationVariables>(
    DeleteTodoDocument,
    options,
  );
}
export type DeleteTodoMutationHookResult = ReturnType<
  typeof useDeleteTodoMutation
>;
export type DeleteTodoMutationResult =
  Apollo.MutationResult<DeleteTodoMutation>;
export type DeleteTodoMutationOptions = Apollo.BaseMutationOptions<
  DeleteTodoMutation,
  DeleteTodoMutationVariables
>;
export const SaveTodoDocument = gql`
  mutation SaveTodo($todoInput: TodoInput!, $customerId: ID) {
    saveTodo(input: $todoInput, customerId: $customerId) {
      created
      todo {
        ...TodoParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${TodoPartsFragmentDoc}
`;
export type SaveTodoMutationFn = Apollo.MutationFunction<
  SaveTodoMutation,
  SaveTodoMutationVariables
>;

/**
 * __useSaveTodoMutation__
 *
 * To run a mutation, you first call `useSaveTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTodoMutation, { data, loading, error }] = useSaveTodoMutation({
 *   variables: {
 *      todoInput: // value for 'todoInput'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useSaveTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveTodoMutation,
    SaveTodoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveTodoMutation, SaveTodoMutationVariables>(
    SaveTodoDocument,
    options,
  );
}
export type SaveTodoMutationHookResult = ReturnType<typeof useSaveTodoMutation>;
export type SaveTodoMutationResult = Apollo.MutationResult<SaveTodoMutation>;
export type SaveTodoMutationOptions = Apollo.BaseMutationOptions<
  SaveTodoMutation,
  SaveTodoMutationVariables
>;
export const TodosDocument = gql`
  query Todos(
    $customerId: ID
    $includeComplete: Boolean
    $forDashboard: Boolean
  ) {
    todos(
      customerId: $customerId
      forDashboard: $forDashboard
      includeComplete: $includeComplete
    ) {
      ...TodoParts
    }
  }
  ${TodoPartsFragmentDoc}
`;

/**
 * __useTodosQuery__
 *
 * To run a query within a React component, call `useTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodosQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      includeComplete: // value for 'includeComplete'
 *      forDashboard: // value for 'forDashboard'
 *   },
 * });
 */
export function useTodosQuery(
  baseOptions?: Apollo.QueryHookOptions<TodosQuery, TodosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodosQuery, TodosQueryVariables>(
    TodosDocument,
    options,
  );
}
export function useTodosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TodosQuery, TodosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodosQuery, TodosQueryVariables>(
    TodosDocument,
    options,
  );
}
export function useTodosSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TodosQuery, TodosQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TodosQuery, TodosQueryVariables>(
    TodosDocument,
    options,
  );
}
export type TodosQueryHookResult = ReturnType<typeof useTodosQuery>;
export type TodosLazyQueryHookResult = ReturnType<typeof useTodosLazyQuery>;
export type TodosSuspenseQueryHookResult = ReturnType<
  typeof useTodosSuspenseQuery
>;
export type TodosQueryResult = Apollo.QueryResult<
  TodosQuery,
  TodosQueryVariables
>;
export const ToggleTodoDocument = gql`
  mutation ToggleTodo($todoId: ID!, $customerId: ID) {
    toggleTodo(todoId: $todoId, customerId: $customerId) {
      todo {
        ...TodoParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${TodoPartsFragmentDoc}
`;
export type ToggleTodoMutationFn = Apollo.MutationFunction<
  ToggleTodoMutation,
  ToggleTodoMutationVariables
>;

/**
 * __useToggleTodoMutation__
 *
 * To run a mutation, you first call `useToggleTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTodoMutation, { data, loading, error }] = useToggleTodoMutation({
 *   variables: {
 *      todoId: // value for 'todoId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useToggleTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleTodoMutation,
    ToggleTodoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleTodoMutation, ToggleTodoMutationVariables>(
    ToggleTodoDocument,
    options,
  );
}
export type ToggleTodoMutationHookResult = ReturnType<
  typeof useToggleTodoMutation
>;
export type ToggleTodoMutationResult =
  Apollo.MutationResult<ToggleTodoMutation>;
export type ToggleTodoMutationOptions = Apollo.BaseMutationOptions<
  ToggleTodoMutation,
  ToggleTodoMutationVariables
>;
export const PlusCheckoutDocument = gql`
  mutation PlusCheckout(
    $successUrl: String
    $cancelUrl: String
    $ctaType: String
    $ctaSource: String
  ) {
    plusCheckout(
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      ctaType: $ctaType
      ctaSource: $ctaSource
    ) {
      id
      errors {
        field
        message
      }
    }
  }
`;
export type PlusCheckoutMutationFn = Apollo.MutationFunction<
  PlusCheckoutMutation,
  PlusCheckoutMutationVariables
>;

/**
 * __usePlusCheckoutMutation__
 *
 * To run a mutation, you first call `usePlusCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlusCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [plusCheckoutMutation, { data, loading, error }] = usePlusCheckoutMutation({
 *   variables: {
 *      successUrl: // value for 'successUrl'
 *      cancelUrl: // value for 'cancelUrl'
 *      ctaType: // value for 'ctaType'
 *      ctaSource: // value for 'ctaSource'
 *   },
 * });
 */
export function usePlusCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlusCheckoutMutation,
    PlusCheckoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlusCheckoutMutation,
    PlusCheckoutMutationVariables
  >(PlusCheckoutDocument, options);
}
export type PlusCheckoutMutationHookResult = ReturnType<
  typeof usePlusCheckoutMutation
>;
export type PlusCheckoutMutationResult =
  Apollo.MutationResult<PlusCheckoutMutation>;
export type PlusCheckoutMutationOptions = Apollo.BaseMutationOptions<
  PlusCheckoutMutation,
  PlusCheckoutMutationVariables
>;
export const CreatePlaceDocument = gql`
  mutation CreatePlace($placeDetails: PlaceInput!) {
    createPlace(input: $placeDetails) {
      placeId
      errors {
        field
        messages
      }
    }
  }
`;
export type CreatePlaceMutationFn = Apollo.MutationFunction<
  CreatePlaceMutation,
  CreatePlaceMutationVariables
>;

/**
 * __useCreatePlaceMutation__
 *
 * To run a mutation, you first call `useCreatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceMutation, { data, loading, error }] = useCreatePlaceMutation({
 *   variables: {
 *      placeDetails: // value for 'placeDetails'
 *   },
 * });
 */
export function useCreatePlaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlaceMutation,
    CreatePlaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePlaceMutation, CreatePlaceMutationVariables>(
    CreatePlaceDocument,
    options,
  );
}
export type CreatePlaceMutationHookResult = ReturnType<
  typeof useCreatePlaceMutation
>;
export type CreatePlaceMutationResult =
  Apollo.MutationResult<CreatePlaceMutation>;
export type CreatePlaceMutationOptions = Apollo.BaseMutationOptions<
  CreatePlaceMutation,
  CreatePlaceMutationVariables
>;
export const GradeRoleplaySessionDocument = gql`
  mutation GradeRoleplaySession($id: ID!) {
    gradeRoleplaySession(id: $id) {
      ok
      errors {
        field
        message
      }
      roleplaySession {
        id
        uuid
        processingStatus
      }
    }
  }
`;
export type GradeRoleplaySessionMutationFn = Apollo.MutationFunction<
  GradeRoleplaySessionMutation,
  GradeRoleplaySessionMutationVariables
>;

/**
 * __useGradeRoleplaySessionMutation__
 *
 * To run a mutation, you first call `useGradeRoleplaySessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGradeRoleplaySessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gradeRoleplaySessionMutation, { data, loading, error }] = useGradeRoleplaySessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGradeRoleplaySessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GradeRoleplaySessionMutation,
    GradeRoleplaySessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GradeRoleplaySessionMutation,
    GradeRoleplaySessionMutationVariables
  >(GradeRoleplaySessionDocument, options);
}
export type GradeRoleplaySessionMutationHookResult = ReturnType<
  typeof useGradeRoleplaySessionMutation
>;
export type GradeRoleplaySessionMutationResult =
  Apollo.MutationResult<GradeRoleplaySessionMutation>;
export type GradeRoleplaySessionMutationOptions = Apollo.BaseMutationOptions<
  GradeRoleplaySessionMutation,
  GradeRoleplaySessionMutationVariables
>;
export const IntroduceFeatureDocument = gql`
  mutation IntroduceFeature($featureType: String!) {
    introduceFeature(featureType: $featureType) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type IntroduceFeatureMutationFn = Apollo.MutationFunction<
  IntroduceFeatureMutation,
  IntroduceFeatureMutationVariables
>;

/**
 * __useIntroduceFeatureMutation__
 *
 * To run a mutation, you first call `useIntroduceFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntroduceFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [introduceFeatureMutation, { data, loading, error }] = useIntroduceFeatureMutation({
 *   variables: {
 *      featureType: // value for 'featureType'
 *   },
 * });
 */
export function useIntroduceFeatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntroduceFeatureMutation,
    IntroduceFeatureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IntroduceFeatureMutation,
    IntroduceFeatureMutationVariables
  >(IntroduceFeatureDocument, options);
}
export type IntroduceFeatureMutationHookResult = ReturnType<
  typeof useIntroduceFeatureMutation
>;
export type IntroduceFeatureMutationResult =
  Apollo.MutationResult<IntroduceFeatureMutation>;
export type IntroduceFeatureMutationOptions = Apollo.BaseMutationOptions<
  IntroduceFeatureMutation,
  IntroduceFeatureMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!, $workspace: String) {
    login(email: $email, password: $password, workspace: $workspace) {
      token
      refreshToken
      currentWorkspaceRole
      currentWorkspaceDomain
      sesameQuery
      user {
        id
        email
        onboardingCompletedAt
        isStaff
        hasIndividualAccount
        coaches {
          id
          name
        }
      }
      workspaces {
        logo
        name
        role
        domain
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      workspace: // value for 'workspace'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
      payload
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, options);
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>;
export type RefreshTokenMutationResult =
  Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($email: String!, $nextPath: String) {
    resetPassword(email: $email, nextPath: $nextPath) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      nextPath: // value for 'nextPath'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const RevokeSessionDocument = gql`
  mutation RevokeSession($sessionId: String!) {
    revokeSession(sessionId: $sessionId) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type RevokeSessionMutationFn = Apollo.MutationFunction<
  RevokeSessionMutation,
  RevokeSessionMutationVariables
>;

/**
 * __useRevokeSessionMutation__
 *
 * To run a mutation, you first call `useRevokeSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeSessionMutation, { data, loading, error }] = useRevokeSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useRevokeSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeSessionMutation,
    RevokeSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeSessionMutation,
    RevokeSessionMutationVariables
  >(RevokeSessionDocument, options);
}
export type RevokeSessionMutationHookResult = ReturnType<
  typeof useRevokeSessionMutation
>;
export type RevokeSessionMutationResult =
  Apollo.MutationResult<RevokeSessionMutation>;
export type RevokeSessionMutationOptions = Apollo.BaseMutationOptions<
  RevokeSessionMutation,
  RevokeSessionMutationVariables
>;
export const SaveSessionDocument = gql`
  mutation SaveSession($sessionId: String!, $token: String!) {
    saveSession(sessionId: $sessionId, token: $token) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;
export type SaveSessionMutationFn = Apollo.MutationFunction<
  SaveSessionMutation,
  SaveSessionMutationVariables
>;

/**
 * __useSaveSessionMutation__
 *
 * To run a mutation, you first call `useSaveSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSessionMutation, { data, loading, error }] = useSaveSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSaveSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSessionMutation,
    SaveSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveSessionMutation, SaveSessionMutationVariables>(
    SaveSessionDocument,
    options,
  );
}
export type SaveSessionMutationHookResult = ReturnType<
  typeof useSaveSessionMutation
>;
export type SaveSessionMutationResult =
  Apollo.MutationResult<SaveSessionMutation>;
export type SaveSessionMutationOptions = Apollo.BaseMutationOptions<
  SaveSessionMutation,
  SaveSessionMutationVariables
>;
export const SignUpDocument = gql`
  mutation SignUp(
    $email: String!
    $returnToken: Boolean!
    $timeZone: String
    $name: String
    $password: String
    $giftCode: String
    $refcode: String
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $utmTerm: String
    $utmContent: String
    $gclid: String
    $wsToken: String
    $coach: String
    $asWorkspaceAdmin: Boolean
    $workspaceSignedToken: String
    $forcePlacementPricing: String
  ) {
    signUp(
      email: $email
      returnToken: $returnToken
      timeZone: $timeZone
      name: $name
      password: $password
      giftCode: $giftCode
      refcode: $refcode
      utmSource: $utmSource
      utmMedium: $utmMedium
      utmCampaign: $utmCampaign
      utmTerm: $utmTerm
      utmContent: $utmContent
      gclid: $gclid
      wsToken: $wsToken
      coach: $coach
      asWorkspaceAdmin: $asWorkspaceAdmin
      workspaceSignedToken: $workspaceSignedToken
      forcePlacementPricing: $forcePlacementPricing
    ) {
      ok
      token
      refreshToken
      errors {
        field
        messages
      }
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      returnToken: // value for 'returnToken'
 *      timeZone: // value for 'timeZone'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      giftCode: // value for 'giftCode'
 *      refcode: // value for 'refcode'
 *      utmSource: // value for 'utmSource'
 *      utmMedium: // value for 'utmMedium'
 *      utmCampaign: // value for 'utmCampaign'
 *      utmTerm: // value for 'utmTerm'
 *      utmContent: // value for 'utmContent'
 *      gclid: // value for 'gclid'
 *      wsToken: // value for 'wsToken'
 *      coach: // value for 'coach'
 *      asWorkspaceAdmin: // value for 'asWorkspaceAdmin'
 *      workspaceSignedToken: // value for 'workspaceSignedToken'
 *      forcePlacementPricing: // value for 'forcePlacementPricing'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options,
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const TokenAuthDocument = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      refreshToken
    }
  }
`;
export type TokenAuthMutationFn = Apollo.MutationFunction<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TokenAuthMutation,
    TokenAuthMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(
    TokenAuthDocument,
    options,
  );
}
export type TokenAuthMutationHookResult = ReturnType<
  typeof useTokenAuthMutation
>;
export type TokenAuthMutationResult = Apollo.MutationResult<TokenAuthMutation>;
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;
export const CurrentSubscriptionDocument = gql`
  query CurrentSubscription {
    currentSubscription {
      id
      planId
      userId
      workspaceId
      validUntil
      stripeSubscriptionId
      quantity
      billingCycleStart
      billingCycleEnd
      canceledAt
      entitlements {
        id
        entitlement
        booleanValue
        maxUsageValue
        usageCount
      }
    }
  }
`;

/**
 * __useCurrentSubscriptionQuery__
 *
 * To run a query within a React component, call `useCurrentSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentSubscriptionQuery,
    CurrentSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentSubscriptionQuery,
    CurrentSubscriptionQueryVariables
  >(CurrentSubscriptionDocument, options);
}
export function useCurrentSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentSubscriptionQuery,
    CurrentSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentSubscriptionQuery,
    CurrentSubscriptionQueryVariables
  >(CurrentSubscriptionDocument, options);
}
export function useCurrentSubscriptionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CurrentSubscriptionQuery,
        CurrentSubscriptionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentSubscriptionQuery,
    CurrentSubscriptionQueryVariables
  >(CurrentSubscriptionDocument, options);
}
export type CurrentSubscriptionQueryHookResult = ReturnType<
  typeof useCurrentSubscriptionQuery
>;
export type CurrentSubscriptionLazyQueryHookResult = ReturnType<
  typeof useCurrentSubscriptionLazyQuery
>;
export type CurrentSubscriptionSuspenseQueryHookResult = ReturnType<
  typeof useCurrentSubscriptionSuspenseQuery
>;
export type CurrentSubscriptionQueryResult = Apollo.QueryResult<
  CurrentSubscriptionQuery,
  CurrentSubscriptionQueryVariables
>;
export const CurrentWorkspaceDocument = gql`
  query CurrentWorkspace {
    currentWorkspace {
      id
      name
      urlSlug
      logo
      analyticsEnabled
    }
  }
`;

/**
 * __useCurrentWorkspaceQuery__
 *
 * To run a query within a React component, call `useCurrentWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentWorkspaceQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentWorkspaceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentWorkspaceQuery,
    CurrentWorkspaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentWorkspaceQuery, CurrentWorkspaceQueryVariables>(
    CurrentWorkspaceDocument,
    options,
  );
}
export function useCurrentWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentWorkspaceQuery,
    CurrentWorkspaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentWorkspaceQuery,
    CurrentWorkspaceQueryVariables
  >(CurrentWorkspaceDocument, options);
}
export function useCurrentWorkspaceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CurrentWorkspaceQuery,
        CurrentWorkspaceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentWorkspaceQuery,
    CurrentWorkspaceQueryVariables
  >(CurrentWorkspaceDocument, options);
}
export type CurrentWorkspaceQueryHookResult = ReturnType<
  typeof useCurrentWorkspaceQuery
>;
export type CurrentWorkspaceLazyQueryHookResult = ReturnType<
  typeof useCurrentWorkspaceLazyQuery
>;
export type CurrentWorkspaceSuspenseQueryHookResult = ReturnType<
  typeof useCurrentWorkspaceSuspenseQuery
>;
export type CurrentWorkspaceQueryResult = Apollo.QueryResult<
  CurrentWorkspaceQuery,
  CurrentWorkspaceQueryVariables
>;
export const WorkspaceCreditsDocument = gql`
  query WorkspaceCredits {
    currentWorkspaceCredits
    currentWorkspaceBudgets
    currentWorkspace {
      id
      creditMode
    }
  }
`;

/**
 * __useWorkspaceCreditsQuery__
 *
 * To run a query within a React component, call `useWorkspaceCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceCreditsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceCreditsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceCreditsQuery,
    WorkspaceCreditsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkspaceCreditsQuery, WorkspaceCreditsQueryVariables>(
    WorkspaceCreditsDocument,
    options,
  );
}
export function useWorkspaceCreditsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceCreditsQuery,
    WorkspaceCreditsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceCreditsQuery,
    WorkspaceCreditsQueryVariables
  >(WorkspaceCreditsDocument, options);
}
export function useWorkspaceCreditsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkspaceCreditsQuery,
        WorkspaceCreditsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceCreditsQuery,
    WorkspaceCreditsQueryVariables
  >(WorkspaceCreditsDocument, options);
}
export type WorkspaceCreditsQueryHookResult = ReturnType<
  typeof useWorkspaceCreditsQuery
>;
export type WorkspaceCreditsLazyQueryHookResult = ReturnType<
  typeof useWorkspaceCreditsLazyQuery
>;
export type WorkspaceCreditsSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceCreditsSuspenseQuery
>;
export type WorkspaceCreditsQueryResult = Apollo.QueryResult<
  WorkspaceCreditsQuery,
  WorkspaceCreditsQueryVariables
>;
export const FeatureIntroducedDocument = gql`
  query FeatureIntroduced($featureType: String!) {
    featureIntroduced(featureType: $featureType)
  }
`;

/**
 * __useFeatureIntroducedQuery__
 *
 * To run a query within a React component, call `useFeatureIntroducedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureIntroducedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureIntroducedQuery({
 *   variables: {
 *      featureType: // value for 'featureType'
 *   },
 * });
 */
export function useFeatureIntroducedQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeatureIntroducedQuery,
    FeatureIntroducedQueryVariables
  > &
    (
      | { variables: FeatureIntroducedQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureIntroducedQuery,
    FeatureIntroducedQueryVariables
  >(FeatureIntroducedDocument, options);
}
export function useFeatureIntroducedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureIntroducedQuery,
    FeatureIntroducedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureIntroducedQuery,
    FeatureIntroducedQueryVariables
  >(FeatureIntroducedDocument, options);
}
export function useFeatureIntroducedSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FeatureIntroducedQuery,
        FeatureIntroducedQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FeatureIntroducedQuery,
    FeatureIntroducedQueryVariables
  >(FeatureIntroducedDocument, options);
}
export type FeatureIntroducedQueryHookResult = ReturnType<
  typeof useFeatureIntroducedQuery
>;
export type FeatureIntroducedLazyQueryHookResult = ReturnType<
  typeof useFeatureIntroducedLazyQuery
>;
export type FeatureIntroducedSuspenseQueryHookResult = ReturnType<
  typeof useFeatureIntroducedSuspenseQuery
>;
export type FeatureIntroducedQueryResult = Apollo.QueryResult<
  FeatureIntroducedQuery,
  FeatureIntroducedQueryVariables
>;
export const GetJobTitleCrossLinksDocument = gql`
  query GetJobTitleCrossLinks($jobTitle: String!) {
    keyPlaces {
      googlePlaceId
      city
      stateCode
      countryCode
      name
      image
    }
    relatedTitles(jobTitle: $jobTitle) {
      normalizedTitle
      title
    }
  }
`;

/**
 * __useGetJobTitleCrossLinksQuery__
 *
 * To run a query within a React component, call `useGetJobTitleCrossLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobTitleCrossLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobTitleCrossLinksQuery({
 *   variables: {
 *      jobTitle: // value for 'jobTitle'
 *   },
 * });
 */
export function useGetJobTitleCrossLinksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobTitleCrossLinksQuery,
    GetJobTitleCrossLinksQueryVariables
  > &
    (
      | { variables: GetJobTitleCrossLinksQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetJobTitleCrossLinksQuery,
    GetJobTitleCrossLinksQueryVariables
  >(GetJobTitleCrossLinksDocument, options);
}
export function useGetJobTitleCrossLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobTitleCrossLinksQuery,
    GetJobTitleCrossLinksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetJobTitleCrossLinksQuery,
    GetJobTitleCrossLinksQueryVariables
  >(GetJobTitleCrossLinksDocument, options);
}
export function useGetJobTitleCrossLinksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetJobTitleCrossLinksQuery,
        GetJobTitleCrossLinksQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetJobTitleCrossLinksQuery,
    GetJobTitleCrossLinksQueryVariables
  >(GetJobTitleCrossLinksDocument, options);
}
export type GetJobTitleCrossLinksQueryHookResult = ReturnType<
  typeof useGetJobTitleCrossLinksQuery
>;
export type GetJobTitleCrossLinksLazyQueryHookResult = ReturnType<
  typeof useGetJobTitleCrossLinksLazyQuery
>;
export type GetJobTitleCrossLinksSuspenseQueryHookResult = ReturnType<
  typeof useGetJobTitleCrossLinksSuspenseQuery
>;
export type GetJobTitleCrossLinksQueryResult = Apollo.QueryResult<
  GetJobTitleCrossLinksQuery,
  GetJobTitleCrossLinksQueryVariables
>;
export const SessionQueryDocument = gql`
  query SessionQuery($sessionId: String!) {
    session(sessionId: $sessionId) {
      token
    }
  }
`;

/**
 * __useSessionQueryQuery__
 *
 * To run a query within a React component, call `useSessionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQueryQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSessionQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionQueryQuery,
    SessionQueryQueryVariables
  > &
    (
      | { variables: SessionQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionQueryQuery, SessionQueryQueryVariables>(
    SessionQueryDocument,
    options,
  );
}
export function useSessionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionQueryQuery,
    SessionQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionQueryQuery, SessionQueryQueryVariables>(
    SessionQueryDocument,
    options,
  );
}
export function useSessionQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SessionQueryQuery,
        SessionQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SessionQueryQuery, SessionQueryQueryVariables>(
    SessionQueryDocument,
    options,
  );
}
export type SessionQueryQueryHookResult = ReturnType<
  typeof useSessionQueryQuery
>;
export type SessionQueryLazyQueryHookResult = ReturnType<
  typeof useSessionQueryLazyQuery
>;
export type SessionQuerySuspenseQueryHookResult = ReturnType<
  typeof useSessionQuerySuspenseQuery
>;
export type SessionQueryQueryResult = Apollo.QueryResult<
  SessionQueryQuery,
  SessionQueryQueryVariables
>;
export const StaffCustomerDocument = gql`
  query StaffCustomer($customerId: ID!, $workspaceId: ID) {
    customer(id: $customerId, workspaceId: $workspaceId) {
      id
      email
      name
      photo
      availableCredits
      hasIndividualAccount
      timeZone
      ledgerAccounts {
        id
        label
        balance
      }
      coaches {
        id
        name
        firstName
        lastName
        photo
        activeCoachProducts {
          id
          product {
            id
          }
        }
        timeZone
        agentProfile {
          id
          summary
          averageRating
          acceptingNewBookings
          canHaveIntroSession
        }
      }
      ...UserConfigurationFragment
    }
  }
  ${UserConfigurationFragmentFragmentDoc}
`;

/**
 * __useStaffCustomerQuery__
 *
 * To run a query within a React component, call `useStaffCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useStaffCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    StaffCustomerQuery,
    StaffCustomerQueryVariables
  > &
    (
      | { variables: StaffCustomerQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaffCustomerQuery, StaffCustomerQueryVariables>(
    StaffCustomerDocument,
    options,
  );
}
export function useStaffCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StaffCustomerQuery,
    StaffCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaffCustomerQuery, StaffCustomerQueryVariables>(
    StaffCustomerDocument,
    options,
  );
}
export function useStaffCustomerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        StaffCustomerQuery,
        StaffCustomerQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StaffCustomerQuery,
    StaffCustomerQueryVariables
  >(StaffCustomerDocument, options);
}
export type StaffCustomerQueryHookResult = ReturnType<
  typeof useStaffCustomerQuery
>;
export type StaffCustomerLazyQueryHookResult = ReturnType<
  typeof useStaffCustomerLazyQuery
>;
export type StaffCustomerSuspenseQueryHookResult = ReturnType<
  typeof useStaffCustomerSuspenseQuery
>;
export type StaffCustomerQueryResult = Apollo.QueryResult<
  StaffCustomerQuery,
  StaffCustomerQueryVariables
>;
