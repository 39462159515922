import { Classes, Colors, Dialog, DialogProps } from '@blueprintjs/core';
import styled from '@emotion/styled';

import { BP_NS, INNER_BORDER_COLOR } from '@/css/constants';

const StyledDialog = styled(Dialog)<
  DialogProps & { width: string; centered?: boolean }
>`
  &.${Classes.DIALOG} {
    background-color: ${Colors.WHITE};
    position: ${({ centered }) => (centered ? undefined : 'fixed')};
    top: ${({ centered }) => (centered ? undefined : '7vh')};
    margin-top: ${({ centered }) => (centered ? undefined : '0')};

    width: ${(props) => props.width || '450px'};
    max-width: 90%;
    &.full-width-dialog {
      max-width: 100%;
      padding: 0px;
      margin: 0px;
      height: 100vh;
    }
    .${Classes.DIALOG_HEADER} {
      box-shadow: none;
      padding: 12px 12px 12px 24px;
      border-bottom: 1px solid ${INNER_BORDER_COLOR};

      .${BP_NS}-heading {
        font-family: 'Inter';
        font-size: 18px;
      }
    }
  }
`;

export default StyledDialog;
