import { useEffect, useState } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import { Box } from '@/components/layout/flexbox';
import { Media } from '@/components/layout/Media';
import MainMenu from '@/components/pieces/menu/MainMenu';
import MenuAvatar from '@/components/pieces/menu/MenuAvatar';
import MenuBilling from '@/components/pieces/menu/MenuBilling';
import MenuFeedback from '@/components/pieces/menu/MenuFeedback';
import { MENU_BACKGROUND } from '@/components/pieces/menu/MenuHeader';
import MenuNotifications from '@/components/pieces/menu/MenuNotifications';
import MenuPage from '@/components/pieces/menu/MenuPage';
import MenuPersonal from '@/components/pieces/menu/MenuPersonal';
import MenuResumes from '@/components/pieces/menu/MenuResumes';

import MobileMenu from './MobileMenu';

let completionTimeout = null;

function LoggedInMenu({
  user,
  onClose,
  onRefetch,
  prerenderNavSection,
  isOpen,
  pageLoading,
  sizeNavBreakpoint,
}) {
  const initialMenuPage =
    prerenderNavSection === 'billing' && !user.billingReady
      ? null
      : prerenderNavSection;
  const [menuPage, setMenuPage] = useState(initialMenuPage);
  const [completion, setCompletion] = useState(null);

  useEffect(() => {
    clearTimeout(completionTimeout);
    if (completion) {
      completionTimeout = setTimeout(() => {
        setCompletion(null);
      }, 2500);
    }
  }, [completion]);

  useEffect(() => {
    return () => clearTimeout(completionTimeout);
  }, []);

  useEffect(() => {
    if (menuPage) {
      clearTimeout(completionTimeout);
    }
  }, [menuPage]);

  function handleBackLink() {
    setMenuPage(menuPage === 'settings' ? null : 'settings');
    setCompletion(null);
  }

  function handleClose() {
    onClose();
    setTimeout(() => setMenuPage(null), 1000);
  }

  useHotkeys(
    'escape',
    (e) => {
      if (isOpen && !e.defaultPrevented) {
        handleClose();
        e.stopPropagation();
      }
    },
    {},
    [isOpen],
  );

  return (
    <Box
      width={1}
      style={{
        backgroundColor: MENU_BACKGROUND,
        position: 'relative',
        overflow: 'hidden',
        minHeight: '100vh',
      }}
    >
      <MenuPage visible={!menuPage} withHeader={false} slideTo='left'>
        <Media greaterThanOrEqual={sizeNavBreakpoint}>
          <MainMenu
            user={user}
            completion={completion}
            onClose={handleClose}
            onMenuChange={setMenuPage}
          />
        </Media>
        <Media lessThan={sizeNavBreakpoint}>
          <MobileMenu
            user={user}
            onClose={handleClose}
            onMenuChange={setMenuPage}
            pageLoading={pageLoading}
          />
        </Media>
      </MenuPage>

      <MenuPage
        visible={menuPage === 'settings'}
        withHeader={false}
        slideTo={menuPage ? 'left' : 'right'}
      >
        <MainMenu
          user={user}
          completion={completion}
          onClose={handleClose}
          onMenuChange={setMenuPage}
          onBackLink={handleBackLink}
          sizeNavBreakpoint={sizeNavBreakpoint}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'personal'}>
        <MenuPersonal
          user={user}
          onClose={handleClose}
          onBackLink={handleBackLink}
          onComplete={() => {
            setCompletion('personal');
            setMenuPage(null);
          }}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'billing'}>
        <MenuBilling
          onClose={handleClose}
          onBackLink={handleBackLink}
          isOpen={isOpen && menuPage === 'billing'}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'resumes'}>
        <MenuResumes
          onClose={handleClose}
          onBackLink={handleBackLink}
          isOpen={isOpen && menuPage === 'resumes'}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'notifications'}>
        <MenuNotifications
          user={user}
          onClose={handleClose}
          onBackLink={handleBackLink}
          isOpen={isOpen && menuPage === 'notifications'}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'feedback'}>
        <MenuFeedback
          onClose={handleClose}
          onBackLink={handleBackLink}
          onComplete={() => {
            setCompletion('feedback');
            setMenuPage(null);
          }}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'avatar'}>
        {completion === 'avatar' ? null : (
          <MenuAvatar
            onClose={handleClose}
            onBackLink={handleBackLink}
            onComplete={() => {
              setCompletion('avatar');
              setMenuPage(null);
              onRefetch();
            }}
          />
        )}
      </MenuPage>
    </Box>
  );
}

export default LoggedInMenu;
