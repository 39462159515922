import { Classes } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import MeetingHeader from '@/components/pieces/coaching/meetings/MeetingHeader';
import { CARD_CLASSES } from '@/css/constants';

export function MeetingSkeleton() {
  return (
    <>
      <MeetingHeader meeting={null} loading={true} />
      <Box pb={6}>
        <Box maxWidth={1100} m='0 auto' px={[3, 24]}>
          <Box mt={4} width={1}>
            <Flex flexWrap='wrap'>
              <Box
                width={['100%', '100%', '100%', 450]}
                backgroundColor='#FFF'
                className={`${CARD_CLASSES} ${Classes.SKELETON}`}
                height={500}
                p={[3, 3, 24]}
                mb={[24, 24, 24, 0]}
              />
              <Box
                width={['100%', 'auto']}
                style={{ flex: 1 }}
                ml={[0, 0, 0, 24]}
                backgroundColor='#FFF'
                className={`${CARD_CLASSES} ${Classes.SKELETON}`}
                height={500}
              />
            </Flex>
          </Box>
        </Box>
      </Box>
    </>
  );
}
