import {
  Checkbox as BlueprintCheckbox,
  CheckboxProps,
  Classes,
} from '@blueprintjs/core';
import styled from '@emotion/styled';

const StyledDiv = styled('div')`
  width: fit-content;
  label.${Classes.CONTROL}.${Classes.CHECKBOX} {
    display: flex;
    margin: 0;
    padding: 0;
    .${Classes.CONTROL_INDICATOR} {
      margin: 0;
      font-size: 20px;
      border-radius: 3px;
    }
  }

  label {
    input:checked ~ .${Classes.CONTROL_INDICATOR} {
      :before {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
      }
    }
  }
`;

function StandaloneCheckbox(props: CheckboxProps) {
  return (
    <StyledDiv>
      <BlueprintCheckbox {...props} />
    </StyledDiv>
  );
}

export default StandaloneCheckbox;
