import { useEffect, useState } from 'react';

import { Colors, Portal, Spinner } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { useHotkeys } from 'react-hotkeys-hook';
import { InstantSearch, useInstantSearch } from 'react-instantsearch';
import { useMediaQuery } from 'react-responsive';

import { getSearchClient } from '@/components/helpers/api/algolia-utils';
import { useWorkspace } from '@/components/helpers/custom-hooks/use-workspace';
import { Box, Flex } from '@/components/layout/flexbox';
import { isXsQuery } from '@/components/layout/Media';
import BackDrop from '@/components/pieces/BackDrop';
import { getSearchIndexes } from '@/components/pieces/search/GlobalSearch/search-index-config';
import { SearchIndex } from '@/components/pieces/search/GlobalSearch/search-types';
import {
  CTA_COLOR,
  LIGHTEST_BACKGROUND,
  PORTAL_Z_INDEX,
} from '@/css/constants';
import { SearchPreferenceQuery } from '@/graphql';

import SearchBox from './SearchBox';
import SearchFilters from './SearchFilters';
import SearchResults from './SearchResults';

export default function GlobalSearch({
  visible,
  onClose,
  searchPreference,
  isCoach,
}) {
  const isXs = useMediaQuery(isXsQuery);
  const hiddenTransform = isXs ? 'translateY(120%)' : 'translateY(-100%)';
  const transitionSpeed = isXs ? '300ms' : '150ms';

  return (
    <Portal>
      <BackDrop visible={visible} />
      <SearchModalContainer
        visibility={visible ? 'visible' : 'hidden'}
        transform={visible ? 'translateY(0%)' : hiddenTransform}
        transition={
          visible
            ? `transform ${transitionSpeed}`
            : `transform ${transitionSpeed}, visibility 0s ${transitionSpeed}`
        }
        onClick={onClose}
      >
        <InstantSearch
          searchClient={getSearchClient()}
          future={{
            preserveSharedStateOnUnmount: true,
          }}
        >
          <SearchModal
            visible={visible}
            searchPreference={searchPreference}
            onClose={onClose}
            isCoach={isCoach}
            isXs={isXs}
          />
        </InstantSearch>
      </SearchModalContainer>
    </Portal>
  );
}

interface SearchModalProps {
  visible: boolean;
  searchPreference: SearchPreferenceQuery;
  isCoach: boolean;
  isXs: boolean;
  onClose: () => void;
}

function SearchModal({
  visible,
  searchPreference,
  isCoach,
  isXs,
  onClose,
}: SearchModalProps) {
  const workspace = useWorkspace();
  const { status } = useInstantSearch();
  const [localQuery, setLocalQuery] = useState('');

  const [filteredIndexes, setFilteredIndexes] = useState<SearchIndex[]>([]);
  const [indexes, setIndexes] = useState<SearchIndex[]>([]);

  useEffect(() => {
    const searchIndexes = getSearchIndexes(workspace, isCoach);
    setIndexes(searchIndexes);
    setFilteredIndexes(searchIndexes);
  }, [workspace, isCoach]);

  useHotkeys(
    'escape',
    (e) => {
      if (visible) {
        onClose();
        e.stopPropagation();
      }
    },
    {},
    [visible],
  );

  useHotkeys(
    'escape',
    (e) => {
      if (!visible) return;
      onClose();
      e.preventDefault();
    },
    {
      enableOnFormTags: true,
      enableOnContentEditable: true,
      keydown: true,
      keyup: false,
      preventDefault: true,
    },
    [visible],
  );

  return (
    <Box
      style={{
        margin: `8px auto`,
        backgroundColor: Colors.WHITE,
        maxWidth: localQuery ? 850 : 600,
        borderRadius: 6,
        overflow: 'hidden',
        fontSize: isXs ? 14 : 16,
      }}
      width={[0.97, 0.8]}
      onClick={(e) => e.stopPropagation()}
    >
      {visible ? (
        <SearchBox
          autoFocus={true}
          throttledDuration={300}
          query={localQuery}
          onQueryChange={setLocalQuery}
        />
      ) : null}
      <Flex
        flexDirection={isXs ? 'column-reverse' : 'row'}
        style={{
          backgroundColor: LIGHTEST_BACKGROUND,
          borderTop: `1px solid ${Colors.LIGHT_GRAY3}`,
        }}
        p={[3, 24]}
        pb={4}
      >
        <Box
          width={[1, localQuery ? 0.7 : 1]}
          style={{ position: 'relative', minHeight: 200 }}
        >
          <SearchResults
            visible={visible}
            searchPreference={searchPreference}
            onClose={onClose}
            availableIndexes={indexes}
            filteredIndexes={filteredIndexes}
            query={localQuery}
          />
          {status === 'stalled' ? (
            <Flex
              justifyContent='center'
              style={{ position: 'absolute', bottom: 16, left: 0, right: 0 }}
            >
              <Spinner />
            </Flex>
          ) : null}
        </Box>
        {localQuery ? (
          <Flex
            width={[1, 0.3]}
            px={isXs ? 0 : 24}
            flexDirection={isXs ? 'row' : 'column'}
            justifyContent={isXs ? 'space-between' : 'flex-start'}
            alignItems={isXs ? 'center' : 'flex-start'}
          >
            <Box mb={3}>
              <b>{isXs ? 'Results' : 'Filters'}</b>
            </Box>
            <SearchFilters
              availableIndexes={indexes}
              filteredIndexes={filteredIndexes}
              onChange={setFilteredIndexes}
            />
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
}

interface SearchModalContainerProps {
  visibility?: string;
  transform?: string;
  transition?: string;
}

const SearchModalContainer = styled(Box)<SearchModalContainerProps>`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: ${(props) => props.visibility};
  transform: ${(props) => props.transform};
  transition: ${(props) => props.transition};
  z-index: ${PORTAL_Z_INDEX};

  a.resultItem {
    display: block;
  }

  .ais-Hits-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .ais-Hits-item {
    border-bottom: 1px solid ${Colors.LIGHT_GRAY3};
  }

  .resultItem {
    .arrow {
      color: ${Colors.GRAY5};
    }

    :hover {
      background-color: ${Colors.LIGHT_GRAY5};
      .arrow {
        color: ${CTA_COLOR};
      }
    }
  }

  .selected {
    background-color: ${Colors.LIGHT_GRAY4} !important;

    .arrow {
      color: ${CTA_COLOR};
    }
  }

  .link {
    color: ${CTA_COLOR};
  }

  .suggestions {
    .ais-Highlight {
      color: ${Colors.BLACK};
    }
    .secondary {
      .ais-Highlight {
        color: ${Colors.GRAY3};
      }
    }
  }
  .results {
    .ais-Highlight {
      color: ${Colors.GRAY3};
    }
  }
  .ais-Highlight-highlighted {
    color: ${Colors.BLACK};
  }
`;
