import { Colors, Icon } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import { CARD_CLASSES } from '@/css/constants';

export default function SortCriterionCard({
  criterionName,
}: {
  criterionName: string;
}) {
  return (
    <Box
      flex={1}
      mb={3}
      className={CARD_CLASSES}
      backgroundColor={Colors.WHITE}
    >
      <Flex
        p={3}
        alignItems={'center'}
        width={'100%'}
        css={{ cursor: 'pointer' }}
      >
        <Icon icon='drag-handle-vertical' />
        <Box fontSize={16} mx={12} fontWeight={600}>
          {criterionName}
        </Box>
      </Flex>
    </Box>
  );
}
