import React from 'react';

import { Button, Classes } from '@blueprintjs/core';
import styled from '@emotion/styled';

import { Box, Flex } from '@/components/layout/flexbox';
import VideoWithPopout from '@/components/pieces/talent-agents/VideoWithPopout';

const PAGE_SIZE = 2;

const VIDEO_SKELETON = (
  <Flex>
    <Box className={Classes.SKELETON} width='100%' height='3em' />
    <Box className={Classes.SKELETON} width='100%' height='1em' mt={3} />
  </Flex>
);

export default function CoachVideos({ faqItems = [] }) {
  const [page, setPage] = React.useState(1);
  return (
    <>
      <VideoGrid>
        {faqItems.slice(0, page * PAGE_SIZE).map((item) => (
          <Flex
            key={item.id}
            justifyContent='space-between'
            flexDirection='column'
          >
            <Box mb={2} fontWeight={600}>
              {item?.faqQuestion?.content || VIDEO_SKELETON}
            </Box>
            <Box>
              <VideoWithPopout
                title={item?.faqQuestion?.content}
                url={item?.videoUrl}
                thumbnail={item?.videoThumbnail}
              />
            </Box>
          </Flex>
        ))}
      </VideoGrid>
      {page * PAGE_SIZE < faqItems.length && (
        <Flex mt={3} alignItems='center'>
          <Box className='bts' style={{ flex: 1 }} />
          <Button rightIcon='chevron-down' onClick={() => setPage(page + 1)}>
            More Videos
          </Button>
          <Box className='bts' style={{ flex: 1 }} />
        </Flex>
      )}
    </>
  );
}

const VideoGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
