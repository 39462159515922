import { Configure, Index } from 'react-instantsearch';

import { getCurrentSite } from '@/components/helpers/configuration/utils';
import { buildArticleContentFilter } from '@/components/helpers/search/articleSearchFilter';
import { Box } from '@/components/layout/flexbox';
import { ARTICLE_SERIES_ARTICLES_INDEX } from '@/components/pieces/algolia/indexes';
import { AlgoliaArticleCardGrid } from '@/components/pieces/learn/search/ArticleCardGrid';
import ArticleSearchResultsHeader from '@/components/pieces/learn/search/ArticleSearchResultsHeader';
import { useResponsiveGridSize } from '@/components/pieces/learn/useResponsiveGridSize';
import { getUser } from '@/components/session/JwtTokenManager';

interface ReadStatus {
  completedItemCount?: number;
  totalItemCount?: number;
  articleReadStatusById?: Record<string, any>;
}

interface ArticleSeriesContentProps {
  title: string;
  urlSlug: string;
  cardsPerRow: number;
  readStatus?: ReadStatus;
  buttonText?: string;
}

function ArticleSeriesContent({
  title,
  urlSlug,
  cardsPerRow,
  readStatus = {},
}: ArticleSeriesContentProps) {
  const { articleReadStatusById = {} } = readStatus;

  return (
    <Box width={1}>
      <Box width={1} mb={3}>
        <ArticleSearchResultsHeader
          hitsPerPage={cardsPerRow}
          title={title}
          url={`/learn/series/${urlSlug}`}
        />
      </Box>
      <AlgoliaArticleCardGrid
        readStatusById={articleReadStatusById}
        cardsPerRow={cardsPerRow}
      />
    </Box>
  );
}

interface ArticleSeriesBlockProps {
  urlSlug: string;
  excludeArticleSlug?: string;
  articleSeriesId: string;
  title: string;
  readStatus?: ReadStatus;
  cardWidth?: number;
  buttonText?: string;
}

export default function ArticleSeriesBlock({
  urlSlug,
  excludeArticleSlug,
  articleSeriesId,
  title,
  readStatus = {},
}: ArticleSeriesBlockProps) {
  const cardsPerRow = useResponsiveGridSize();
  const user = getUser();
  const filters = buildArticleContentFilter({
    visibleOnSite: getCurrentSite(),
    excludeUrlSlug: excludeArticleSlug,
    articleSeriesUrlSlug: urlSlug,
    showPublicOnly: !user,
  });

  return (
    <Index
      key={articleSeriesId}
      indexName={ARTICLE_SERIES_ARTICLES_INDEX}
      indexId={urlSlug}
    >
      <Configure hitsPerPage={cardsPerRow} filters={filters} />
      <ArticleSeriesContent
        title={title}
        urlSlug={urlSlug}
        cardsPerRow={cardsPerRow}
        readStatus={readStatus}
      />
    </Index>
  );
}
