import CreditSVG from '@/../../hypetrain/static/img/credit.svg';
import { Classes } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import { formatMoney, pluralize } from '@/components/helpers/string-utils';
import EmbedSvg from '@/components/helpers/ui/EmbedSvg';
import { Box, Flex } from '@/components/layout/flexbox';
import { BRAND_PURPLE, GREEN, MIDTEXT_COLOR } from '@/css/constants';

export default function ProductItem({
  product,
  selected,
  disabled,
  selectedSpecialty = null,
  extraIcon = null,
  hideDisabled = false,
}) {
  if (disabled && hideDisabled) {
    return null;
  }
  const { name, slogan, msrp, price, displayedCreditPrice } = product || {};
  return (
    <Flex
      ml={3}
      className={`${Classes.CARD} ${
        disabled ? Classes.ELEVATION_2 : `${Classes.INTERACTIVE}`
      }`}
      flexDirection='column'
      justifyContent='flex-end'
      width={1}
      height={'100%'}
      css={{
        border: '3px solid #e2e2e2',
        borderColor: selected ? BRAND_PURPLE : '#e2e2e2',
        opacity: disabled ? 0.5 : undefined,
        cursor: disabled ? 'not-allowed' : undefined,
        position: 'relative',
      }}
    >
      {slogan ? (
        <Box
          color='#B38B25'
          backgroundColor='#fff9e5'
          fontWeight={500}
          mb={2}
          px={2}
          mx={-2}
          css={{ display: 'inline-block ' }}
          className='product-slogan'
        >
          {slogan}
        </Box>
      ) : null}
      <Flex
        width={1}
        justifyContent='space-between'
        alignItems='center'
        fontSize={18}
        fontWeight={600}
      >
        {name}
        {extraIcon}
      </Flex>
      {disabled && product.credit_price ? (
        <Box mt={2} color={MIDTEXT_COLOR} fontSize={12}>
          Unavailable.{' '}
          <Link
            to={`/match/${selectedSpecialty && selectedSpecialty.slug}?p=${
              product && product.slug
            }`}
          >
            Find coach
          </Link>
        </Box>
      ) : price || displayedCreditPrice ? (
        <Flex mt={2} alignItems='center'>
          {msrp ? (
            <Box
              fontSize={18}
              fontWeight={500}
              color={'rgba(119, 131, 151, 0.6)'}
              style={{ textDecoration: 'line-through' }}
            >
              {formatMoney(msrp, 0)}
            </Box>
          ) : null}
          {price ? (
            <Box
              ml={msrp ? 2 : null}
              fontWeight={500}
              color={msrp ? GREEN : MIDTEXT_COLOR}
            >
              {formatMoney(price, 0)}
            </Box>
          ) : null}
          {displayedCreditPrice ? (
            <Box ml={price ? 2 : null} fontWeight={500} color={MIDTEXT_COLOR}>
              <span style={{ marginRight: 4 }}>
                <EmbedSvg src={CreditSVG} height={18} width={18} />
              </span>
              {pluralize(displayedCreditPrice, 'Credit', 'Credits', true)}
            </Box>
          ) : null}
        </Flex>
      ) : null}
    </Flex>
  );
}
