import React, { ReactNode } from 'react';

import {
  Button,
  Classes,
  Icon,
  IconName,
  MaybeElement,
} from '@blueprintjs/core';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import {
  BP_NS,
  CTA_COLOR,
  GREEN,
  LIGHT_BACKGROUND,
  MIDTEXT_COLOR,
} from '@/css/constants';

export type ButtonVariant =
  | 'default'
  | 'primary'
  | 'danger'
  | 'success'
  | 'white';
export type ButtonSize = 'default' | 'small' | 'large';

interface BaseButtonProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  fontSize?: string;
  padding?: string;
  fill?: boolean;
}

type FlexibleButtonProps<T extends React.ElementType> = BaseButtonProps & {
  as?: T;
  icon?: T extends typeof Button ? IconName | MaybeElement : ReactNode;
} & React.ComponentPropsWithoutRef<T>;

const baseButtonStyles = `
  border-radius: 8px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;

  display: inline-flex;
  align-items: center;

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    min-width: 16px;
  }
`;

const getVariantStyles = (variant: ButtonVariant) => {
  switch (variant) {
    case 'primary':
      return `
        background: ${CTA_COLOR};
        color: white;
        &:hover:not(:disabled) {
          background: ${CTA_COLOR};
          filter: brightness(110%);
          color: #fff;
          box-shadow: 0 0 0 0 rgba(226, 226, 226, 0),
            0 0 0 0 rgba(226, 226, 226, 0),
            0 2px 6px rgba(41, 155, 240, 0.18), 0 1px 0 #f0f0;
        }

        &:disabled {
          background: ${CTA_COLOR};
          color: white;
          opacity: 0.6;
        }

        .${BP_NS}-icon {
          color: white;
        }
      `;
    case 'danger':
      return `
          background: #C61D1D;
          color: white;
          &:hover:not(:disabled) {
            background: #C61D1D;
            filter: brightness(110%);
            color: #fff;
            box-shadow: 0 0 0 0 rgba(226, 226, 226, 0),
              0 0 0 0 rgba(226, 226, 226, 0),
              0 2px 6px rgba(198, 29, 29, 0.18), 0 1px 0 #f0f0;
          }

          &:disabled {
            background: #C61D1D;
            color: white;
            opacity: 0.6;
          }

          .${BP_NS}-icon {
            color: white;
          }
        `;
    case 'success':
      return `
              background: ${GREEN};
              color: white;
              &:hover:not(:disabled) {
                background: ${GREEN};
                filter: brightness(110%);
                color: #fff;
                box-shadow: 0 0 0 0 rgba(226, 226, 226, 0),
                  0 0 0 0 rgba(226, 226, 226, 0),
                  0 2px 6px rgba(198, 29, 29, 0.18), 0 1px 0 #f0f0;
              }

              &:disabled {
                background: ${GREEN};
                color: white;
                opacity: 0.6;
              }

              .${BP_NS}-icon {
                color: white;
              }
            `;
    default:
      return `
        background: ${variant === 'white' ? '#FFF' : LIGHT_BACKGROUND};
        color: ${MIDTEXT_COLOR};
        &:hover {
          background: ${LIGHT_BACKGROUND};
          box-shadow: 0 0 0 0 rgba(226, 226, 226, 0),
            0 0 0 0 rgba(226, 226, 226, 0),
            inset 0 0 0 1px #e2e2e2,
            inset 0 0px 1px #e2e2e2,
            0 2px 6px rgba(41, 155, 240, 0.18),
            0 1px 0 #f0f0;
          background-clip: padding-box;
        }
      `;
  }
};

const getSizeStyles = (
  size: ButtonSize,
  fontSize?: string,
  padding?: string,
) => {
  let defaultStyles;
  switch (size) {
    case 'small':
      defaultStyles = `
        padding: 5px 12px;
        font-size: 14px;
      `;
      break;
    case 'large':
      defaultStyles = `
        padding: 16px 32px;
        font-size: 18px;
        min-height: 58px;
      `;
      break;
    default:
      defaultStyles = `
        padding: 8px 24px;
        font-size: 16px;
      `;
  }

  return `
    ${defaultStyles}
    ${fontSize ? `font-size: ${fontSize};` : ''}
    ${padding ? `padding: ${padding};` : ''}
  `;
};

const StyledComponent = styled.button<BaseButtonProps>`
  ${baseButtonStyles}
  ${(props) => getVariantStyles(props.variant || 'default')}
  ${(props) =>
    getSizeStyles(props.size || 'default', props.fontSize, props.padding)}

  &.${Classes.BUTTON}:not([class*="${BP_NS}-intent-"]) {
    ${baseButtonStyles}
    ${(props) => getVariantStyles(props.variant || 'default')}
    ${(props) =>
      getSizeStyles(props.size || 'default', props.fontSize, props.padding)}
  }
`;

export default function FlexibleButton<
  T extends React.ElementType = typeof Button,
>({
  as,
  variant = 'default',
  size = 'default',
  className,
  fontSize,
  padding,
  icon,
  fill,
  children,
  ...props
}: FlexibleButtonProps<T>) {
  const Component = as || Button;
  const isDefaultButton = Component === Button;

  const buttonProps = {
    variant,
    size,
    fontSize,
    padding,
    className: `${className || ''} ${Classes.BUTTON} ${
      fill ? Classes.FILL : ''
    }`.trim(),
    ...(isDefaultButton ? { icon: icon as IconName | MaybeElement } : {}),
    ...props,
  };

  const maybeIcon =
    !isDefaultButton && icon ? (
      typeof icon === 'string' ? (
        <Icon icon={icon as IconName} />
      ) : (
        <span className='icon-wrapper'>{icon}</span>
      )
    ) : null;

  return (
    <StyledComponent as={Component} {...buttonProps}>
      {maybeIcon}
      {children && <span>{children}</span>}
    </StyledComponent>
  );
}

// Type definitions for common use cases
export type ButtonAsButtonProps = FlexibleButtonProps<'button'>;
export type ButtonAsAnchorProps = FlexibleButtonProps<'a'>;
export type ButtonAsLinkProps = FlexibleButtonProps<typeof Link>;
