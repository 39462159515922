import { Colors } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import { Container } from '@/components/pieces/bootstrap';
import PlaybookSvg from '@/components/pieces/icons/svg/playbook';
import { BRAND_PURPLE, SUBTEXT_COLOR } from '@/css/constants';

export default function ProgramHeader() {
  return (
    <Box className='bbs' pt={[2, 4]} pb={[2, 4]} bg={Colors.WHITE}>
      <Container>
        <Flex alignItems='center'>
          <PlaybookSvg size={26} color={BRAND_PURPLE} />
          <Box ml={3}>
            <h1 style={{ fontSize: 28, margin: 0 }}>Programs</h1>
          </Box>
        </Flex>
        <Box color={SUBTEXT_COLOR} mt={2}>
          Create, manage, and track your programs
        </Box>
      </Container>
    </Box>
  );
}
