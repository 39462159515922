export function EvaluationCriteriaIcon({ size = 16, color = '#2B2165' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 0.916667C4 0.410406 4.41041 0 4.91667 0H11.0833C11.5896 0 12 0.410406 12 0.916667V2H15C15.5523 2 16 2.44772 16 3V4C16 6.60026 14.0151 8.73692 11.4778 8.97746C10.949 9.9055 10.0594 10.6013 9 10.874V14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H5C4.44772 16 4 15.5523 4 15C4 14.4477 4.44772 14 5 14H7V10.874C5.94057 10.6013 5.05101 9.9055 4.52219 8.97746C1.9849 8.73692 0 6.60026 0 4V3C0 2.44772 0.447715 2 1 2H4V0.916667ZM4 4H2C2 5.30622 2.83481 6.41746 4 6.82929V4ZM12 6.82929V4H14C14 5.30622 13.1652 6.41746 12 6.82929Z'
        fill={color}
      />
    </svg>
  );
}
