import { Flex } from '@/components/layout/flexbox';
import Clickable from '@/components/pieces/interaction/Clickable';
import ProductDropdown from '@/components/pieces/products/ProductDropdown';
import ProductItem from '@/components/pieces/products/ProductItem';

const NUMBER_OF_PACKAGES = 8;

function Product({
  product,
  selectedSpecialty,
  selected,
  disabled,
  onClick,
  width,
  maxPerRow = 4,
  hideDisabled = false,
}) {
  let flex = null;
  let maxWidth = null;
  if (maxPerRow === 1) {
    flex = ['1 1 100%'];
    maxWidth = '100%';
  } else if (maxPerRow === 2) {
    flex = ['1 1 100%', '1 1 50%'];
    maxWidth = ['100%', '50%'];
  } else if (maxPerRow === 3) {
    flex = ['1 1 100%', '1 1 33%'];
    maxWidth = ['100%', '33%'];
  } else if (maxPerRow >= 4) {
    flex = ['1 1 100%', '1 1 33%', '1 1 33%', '1 1 25%'];
    maxWidth = ['100%', '33%', '33%', '25%'];
  }

  return (
    <Clickable onClick={disabled ? null : onClick}>
      <Flex width={[1, 1, width]} flex={flex} maxWidth={maxWidth} mb={3}>
        <ProductItem
          disabled={disabled}
          product={product}
          selected={selected}
          selectedSpecialty={selectedSpecialty}
          hideDisabled={hideDisabled}
        />
      </Flex>
    </Clickable>
  );
}

export default function ProductList({
  eligibleProducts,
  disabledProductIds = [],
  selectedProduct,
  setSelectedProduct,
  disabled,
  products,
  selectedSpecialty,
  minCredits = null,
  maxPerRow = 4,
  hideDisabled = false,
}) {
  const displayNumber =
    eligibleProducts.length > NUMBER_OF_PACKAGES
      ? NUMBER_OF_PACKAGES - 1
      : NUMBER_OF_PACKAGES;
  const options = eligibleProducts
    .map((p) => ({
      ...p,
      disabled: disabledProductIds.includes(parseInt(p.id)),
    }))
    // Sort disabled products to the end
    .sort((x, y) => (x.disabled === y.disabled ? 0 : x.disabled ? 1 : -1));
  const defaultOption = options.slice(0, displayNumber);
  const moreOptions = options.slice(displayNumber, options.length);
  return (
    <>
      {defaultOption.map((product) => {
        const disabledProduct =
          disabled ||
          product.creditValue < minCredits ||
          product.locked ||
          disabledProductIds.includes(parseInt(product.id));
        return (
          <Product
            key={product.id}
            product={product}
            selected={selectedProduct && product.id === selectedProduct.id}
            onClick={() => setSelectedProduct(product)}
            disabled={disabledProduct}
            width={products.length > 0 ? 1 / products.length : 1}
            selectedSpecialty={selectedSpecialty}
            maxPerRow={maxPerRow}
            hideDisabled={hideDisabled}
          />
        );
      })}
      {moreOptions.length > 0 && (
        <ProductDropdown
          disabled={disabled}
          options={moreOptions}
          selectedId={selectedProduct && selectedProduct.id}
          onChange={setSelectedProduct}
          hideDisabled={hideDisabled}
        />
      )}
    </>
  );
}
