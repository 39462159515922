import React from 'react';

import { Classes, Icon, Menu, MenuItem, Position } from '@blueprintjs/core';
import styled from '@emotion/styled';

import { formatMoney } from '@/components/helpers/string-utils';
import { Popover } from '@/components/helpers/ui/blueprint-overrides/Popover';
import { Box, Flex } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import ProductItem from '@/components/pieces/products/ProductItem';
import {
  BRAND_PURPLE,
  GREEN,
  HOVER_BACKGROUND,
  MIDTEXT_COLOR,
} from '@/css/constants';

export default function ProductDropdown({
  disabled,
  selectedId,
  onChange,
  options = [],
  hideDisabled = false,
}): React.ReactElement {
  const selectedProduct = options.find((item) => item.id == selectedId);
  const icon = <Icon icon={'caret-down'} size={18} />;
  return (
    <PopoverStyled
      canEscapeKeyClose={true}
      position={Position.BOTTOM_RIGHT}
      modifiers={{ arrow: { enabled: false } }}
      className='product-popover'
      content={
        <MenuStyled>
          {options.map((product) => {
            const { name, msrp, price } = product;
            return (
              <MenuItem
                key={product.name}
                className={selectedId === product.id && 'active'}
                onClick={() => {
                  onChange(product);
                }}
                text={
                  <Flex justifyContent='space-between' minWidth={200}>
                    <Box fontSize={16} fontWeight={500}>
                      {name}
                    </Box>
                    <Flex>
                      {msrp ? (
                        <Box
                          fontSize={16}
                          color={'rgba(119, 131, 151, 0.6)'}
                          style={{ textDecoration: 'line-through' }}
                        >
                          {formatMoney(msrp, 0)}
                        </Box>
                      ) : null}
                      {price ? (
                        <Box
                          fontSize={16}
                          ml={msrp ? 2 : null}
                          fontWeight={500}
                          color={msrp ? GREEN : MIDTEXT_COLOR}
                        >
                          {formatMoney(price, 0)}
                        </Box>
                      ) : null}
                    </Flex>
                  </Flex>
                }
              />
            );
          })}
        </MenuStyled>
      }
    >
      {selectedProduct ? (
        <ProductItem
          disabled={disabled}
          selected={!!selectedProduct}
          product={selectedProduct}
          extraIcon={icon}
          hideDisabled={hideDisabled}
        />
      ) : (
        <Flex
          className={`${Classes.CARD} ${Classes.INTERACTIVE}`}
          fontSize={18}
          fontWeight={600}
          ml={3}
          padding={20}
          width={1}
          height={'100%'}
          alignItems='center'
          justifyContent='space-between'
          css={{
            border: '3px solid #e2e2e2',
            borderColor: '#e2e2e2',
          }}
        >
          More Options{icon}
        </Flex>
      )}
    </PopoverStyled>
  );
}

const PopoverStyled = styled(Popover)`
  display: flex;
  flex: 1 1 25%;
  max-width: 25%;
  margin-bottom: 16px;
  & .${Classes.POPOVER_TARGET} {
    width: 100%;
    display: inherit;
  }
  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    flex: 1 1 33%;
    max-width: 33%;
  }
  @media screen and (max-width: ${breakpoints.sm - 1}px) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const MenuStyled = styled(Menu)`
  padding: 0px !important;
  height: 300px;
  overflow-y: scroll;
  a {
    padding: 16px 24px 16px 16px;
    display: flex;
    flex-direction: row-reverse;
    border-radius: 0;
    :hover {
      background: ${HOVER_BACKGROUND} !important;
    }
    span {
      margin-left: 10px;
      margin-right: 0;
      display: none;
    }
    &.active {
      background: ${HOVER_BACKGROUND};
      font-weight: 600;
      color: ${BRAND_PURPLE} !important;
      span {
        display: block;
        color: ${BRAND_PURPLE} !important;
      }
    }
  }
`;
