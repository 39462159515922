import { CSSProperties, useMemo, useState } from 'react';

import {
  Classes,
  Colors,
  Icon,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Position,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import styled from '@emotion/styled';
import classnames from 'classnames';

import { Popover } from '@/components/helpers/ui/blueprint-overrides/Popover';
import { Tooltip } from '@/components/helpers/ui/blueprint-overrides/Tooltip';
import { isWorkspaceAdmin } from '@/components/helpers/workspace/permissions';
import { Box, Flex } from '@/components/layout/flexbox';
import RoundedIconButton from '@/components/pieces/form/RoundedIconButton';
import StandaloneCheckbox from '@/components/pieces/form/StandaloneCheckbox';
import Clickable from '@/components/pieces/interaction/Clickable';
import Avatar from '@/components/pieces/users/Avatar';
import {
  BP_NS,
  INNER_BORDER_COLOR,
  LIGHTEST_BACKGROUND,
  LIGHT_BACKGROUND,
  STANDARD_NAV_HEIGHT,
  SUBTEXT_COLOR,
} from '@/css/constants';
import {
  WorkspaceClientStatisticsQueryQuery,
  WorkspaceMemberRole,
} from '@/graphql';

import CreditsWithAccountsPopover from './CreditsWithAccounts';
import GroupsWithPopover from './GroupsWithPopover';

const TABLE_MIN_WIDTH = 900;

interface Sort {
  field: string;
  asc: boolean | null;
}
interface HeaderConfig {
  field: string;
  title: string;
  tooltip?: string;
  type: string;
}
interface HeaderProps {
  config: HeaderConfig;
  currentSort: Sort;
  onSort: (string) => any;
  checkedAll: boolean;
  onCheckRow?: (rowData: {
    data: any;
    checked: boolean;
    isCheckAll: boolean;
  }) => void;
}

export const isAgentMember = ({ role }) => {
  return role && ['ADMIN', 'STAFF'].includes(role);
};

export const isAdminMember = ({ role }) => role === 'ADMIN';

function Header({
  config,
  currentSort,
  onSort,
  checkedAll,
  onCheckRow,
}: HeaderProps) {
  const field = config.field;
  const isActive = field && currentSort.field === field;
  let target = (
    <Clickable onClick={() => (field ? onSort(field) : {})}>
      <Flex alignItems='center' justifyContent={'flex-start'}>
        <Box mr={1}>{config.title}</Box>
        {field && (
          <Icon
            icon={isActive && currentSort.asc ? 'chevron-up' : 'chevron-down'}
          />
        )}
      </Flex>
    </Clickable>
  );
  if (config.type === 'checkbox') {
    target = (
      <StandaloneCheckbox
        checked={checkedAll}
        onChange={(e) => {
          onCheckRow({
            data: null,
            checked: (e.target as HTMLInputElement).checked,
            isCheckAll: true,
          });
        }}
      />
    );
  }
  return (
    <th
      className={classnames('header', {
        'active-sort': isActive,
      })}
    >
      {config.tooltip ? (
        <Tooltip
          content={<div style={{ maxWidth: 200 }}>{config.tooltip}</div>}
          placement='top-end'
          minimal={true}
        >
          {target}
        </Tooltip>
      ) : (
        <> {target} </>
      )}
    </th>
  );
}

const defaultHeaders = [
  {
    key: 'checkbox',
    field: null,
    title: '',
    type: 'checkbox',
  },
  {
    key: 'name',
    field: 'name',
    title: 'Name',
    type: 'text',
  },
  {
    key: 'email',
    field: 'email',
    title: 'Email',
    type: 'text',
  },
  {
    key: 'groups',
    field: null,
    title: 'Groups',
    type: 'text',
  },
  {
    key: 'totalBalance',
    field: 'balance',
    title: 'Available Credits',
    type: 'text',
  },
];

const adminHeaders = defaultHeaders.concat([
  {
    key: 'actions',
    field: null,
    title: 'Actions',
    type: 'text',
  },
]);

export function MemberName({
  row,
  style,
}: {
  row?: WorkspaceClientStatisticsQueryQuery['workspaceClientStatistics']['data'][0];
  style?: CSSProperties;
}) {
  const {
    user = {} as WorkspaceClientStatisticsQueryQuery['workspaceClientStatistics']['data'][0]['user'],
  } = row;
  const label = user.name || user.email;
  const postfix = isAdminMember(row) ? (
    <Tag intent='primary'>Admin</Tag>
  ) : isAgentMember(row) ? (
    <Tag intent='primary'>Staff</Tag>
  ) : null;

  return (
    <span>
      <span style={{ marginRight: 5, ...style }}> {label}</span>
      {postfix}
    </span>
  );
}

interface ClientTableProps {
  loading?: boolean;
  data: WorkspaceClientStatisticsQueryQuery['workspaceClientStatistics']['data'];
  currentSort: Sort;
  onSort: (fieldName) => any;
  onTransferCredits: (user) => any;
  onUpdateRole: (user, newRole: WorkspaceMemberRole) => void;
  onRemove: (user) => any;
  checkedData?: string[] | number[];
  onSelectedMember: (member) => any;
  onSetGroup: (member) => any;
  onCheckRow?: (rowData: {
    data: any;
    checked: boolean;
    isCheckAll: boolean;
  }) => void;
}

export default function WorkspaceMemberTable({
  loading = false,
  data,
  currentSort,
  onSort,
  onTransferCredits,
  onUpdateRole,
  onRemove,
  checkedData,
  onCheckRow,
  onSelectedMember,
  onSetGroup,
}: ClientTableProps) {
  const headers = isWorkspaceAdmin() ? adminHeaders : defaultHeaders;
  const selectedsSet = useMemo(
    () => new Set<string | number>(checkedData),
    [checkedData],
  );

  const checkedAll =
    data.length > 0 &&
    !(data.filter((row) => !selectedsSet.has(row.id)).length > 0);

  const [openMenuId, setOpenMenuId] = useState<string | number | null>(null);

  return (
    <TOCBox width='100%' height='100%'>
      <TOC className={Classes.HTML_TABLE}>
        <thead>
          <tr>
            {headers.map((config) => (
              <Header
                key={config.key}
                config={config}
                currentSort={currentSort}
                onSort={onSort}
                checkedAll={checkedAll}
                onCheckRow={onCheckRow}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, n) => {
            const user = row ? row.user : null;

            return (
              <tr key={`workspace-item-${row.id}-${n}`}>
                <td style={{ paddingRight: 0 }}>
                  <StandaloneCheckbox
                    checked={selectedsSet.has(row.id)}
                    onChange={(e) => {
                      onCheckRow({
                        data: row,
                        checked: (e.target as HTMLInputElement).checked,
                        isCheckAll: false,
                      });
                    }}
                  />
                </td>
                <td>
                  <Flex alignItems='center'>
                    <Tooltip content={user.email}>
                      <Avatar size={24} user={user} />
                    </Tooltip>
                    <Box ml='5px'>
                      <a
                        onClick={() => {
                          onSelectedMember(row);
                          return;
                        }}
                      >
                        <MemberName row={row} />
                      </a>
                    </Box>
                  </Flex>
                </td>
                <td>
                  <span style={{ marginRight: 5 }}> {user?.email}</span>
                </td>
                <td>
                  <GroupsWithPopover groups={row.groups} />
                </td>
                <td>
                  <CreditsWithAccountsPopover
                    totalBalance={row.totalBalance}
                    ledgerAccounts={row.ledgerAccounts}
                  />
                </td>
                {isWorkspaceAdmin() && (
                  <td>
                    <Popover
                      content={
                        <Menu>
                          <MenuItem
                            onClick={() => {
                              onTransferCredits(row);
                              setOpenMenuId(null);
                            }}
                            text='Transfer Credits'
                            icon='exchange'
                          />
                          <MenuItem
                            onClick={() => {
                              onSetGroup(row);
                              setOpenMenuId(null);
                            }}
                            text='Set Group'
                            icon='people'
                          />
                          {isAdminMember(row) ? (
                            <MenuItem
                              onClick={() => {
                                onUpdateRole(row, WorkspaceMemberRole.Member);
                                setOpenMenuId(null);
                              }}
                              text='Convert to Member'
                              icon='follower'
                            />
                          ) : (
                            <MenuItem
                              onClick={() => {
                                onUpdateRole(row, WorkspaceMemberRole.Admin);
                                setOpenMenuId(null);
                              }}
                              text='Convert to Admin'
                              icon='follower'
                            />
                          )}
                          {!isAdminMember(row) && (
                            <>
                              <MenuDivider />
                              <MenuItem
                                onClick={() => {
                                  onRemove(row);
                                  setOpenMenuId(null);
                                }}
                                text='Remove User'
                                icon='trash'
                                intent={Intent.DANGER}
                              />
                            </>
                          )}
                        </Menu>
                      }
                      canEscapeKeyClose={true}
                      position={Position.BOTTOM_RIGHT}
                      isOpen={openMenuId === row.id}
                      onClose={() => setOpenMenuId(null)}
                    >
                      <RoundedIconButton
                        backgroundColor={LIGHT_BACKGROUND}
                        icon={
                          <Icon icon='more' size={20} color={SUBTEXT_COLOR} />
                        }
                        onClick={() =>
                          setOpenMenuId(openMenuId === row.id ? null : row.id)
                        }
                      />
                    </Popover>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </TOC>
      {!data.length ? (
        <Flex
          css={{
            background: Colors.WHITE,
            borderBottom: `1px solid ${Colors.LIGHT_GRAY2}`,
            minHeight: 200,
          }}
          width='100%'
          py='24px'
          justifyContent='center'
          alignItems='center'
        >
          <div>{!loading ? 'No Clients!' : ''}</div>
        </Flex>
      ) : null}
      {loading ? (
        <Flex
          justifyContent='center'
          p={2}
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            opacity: 0.5,
            width: '100%',
            height: '100%',
            background: '#fff',
          }}
        >
          <Spinner intent='primary' />
        </Flex>
      ) : null}
    </TOCBox>
  );
}

export const PagingSticky = styled(Flex)`
  @media (min-width: ${TABLE_MIN_WIDTH + 200}px) {
    position: sticky;
    bottom: 0px;
    z-index: 9;
  }
`;

export const TOCBox = styled(Box)`
  position: relative;

  @media (max-width: ${TABLE_MIN_WIDTH + 200}px) {
    overflow-x: scroll;
  }
`;

export const TOC = styled.table`
  &.${BP_NS}-html-table {
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    min-width: ${TABLE_MIN_WIDTH}px;
    position: relative;
    .right-align {
      text-align: right;
    }

    a {
      font-weight: 500;
    }

    &.noboder-table {
      & tbody {
        td {
          border: none;
        }
        & tr:nth-of-type(even) {
          background-color: ${Colors.WHITE};
        }

        & tr:hover {
          background-color: ${LIGHTEST_BACKGROUND};
          cursor: pointer;
        }
      }
      & tr th {
        border: none;
      }
    }

    & tbody {
      & tr:nth-of-type(odd) {
        background-color: ${Colors.WHITE};
      }

      & tr:nth-of-type(even) {
        background-color: ${LIGHTEST_BACKGROUND};
      }
      & tr {
        height: 60px;
        padding: 16px;
        border-style: solid;
        border-color: ${Colors.LIGHT_GRAY5};
        border-width: 0 0 1px 0;
        &:first-of-type {
          border-top: none;
        }
      }
      & td {
        border-style: solid;
        border-color: ${INNER_BORDER_COLOR};
        border-width: 0 1px 0 0;
        &:first-of-type {
          padding-left: 24px;
        }
        &:last-of-type {
          padding-right: 24px;
        }
      }
    }
    & thead {
      & tr {
        & th:first-of-type:before {
          display: none;
        }
        & th {
          border-style: solid;
          border-color: ${INNER_BORDER_COLOR};
          border-width: 0 1px 0 0;
          @media (min-width: ${TABLE_MIN_WIDTH + 200}px) {
            position: sticky;
            /* set top for sticky work */
            top: ${STANDARD_NAV_HEIGHT}px;
            z-index: 9;
          }

          /* Sticky just work with bow shadow not border */
          box-shadow: inset 0 -1px 0 ${Colors.LIGHT_GRAY2};

          /* and one small fix for weird FF behavior, described in https://stackoverflow.com/questions/7517127/ */
          background-clip: padding-box;

          &.header {
            background-color: ${Colors.WHITE};
            font-size: 15px;
            font-weight: 400;
            color: ${Colors.GRAY3};
            &.active-sort {
              font-weight: 600;
              color: ${Colors.DARK_GRAY1};
            }
          }
          &:first-of-type {
            padding-left: 24px;
          }
          &:last-of-type {
            padding-right: 24px;
          }
        }
      }
    }
    &.${Classes.HTML_TABLE} td,
    &.${Classes.HTML_TABLE} th {
      padding: 10px 12px;
      vertical-align: middle;
    }
    &.${Classes.HTML_TABLE} tr:first-of-type td {
      box-shadow: none;
    }
  }
`;
