import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, ButtonGroup, Icon } from '@blueprintjs/core';

import { Tooltip } from '@/components/helpers/ui/blueprint-overrides/Tooltip';
import { Box, Flex } from '@/components/layout/flexbox';
import { SUBTEXT_COLOR } from '@/css/constants';
import { RateGptResponseDocument } from '@/graphql';

export default function RateResponse({ gptResponseUuid, defaultRating }) {
  const [rating, setRating] = useState(defaultRating);
  const [rate] = useMutation(RateGptResponseDocument, {
    onCompleted: () => {},
  });

  function handleRating(rating) {
    rate({
      variables: {
        gptResponseUuid,
        rating,
      },
    });
    setRating(rating);
  }

  if (rating === true || rating === false) {
    return (
      <Flex alignItems='center'>
        <Icon
          icon={rating ? 'thumbs-up' : 'thumbs-down'}
          intent={rating ? 'success' : 'danger'}
        />
        <Box ml={2} fontSize={14} color={SUBTEXT_COLOR}>
          Thanks for your feedback
        </Box>
      </Flex>
    );
  } else {
    return (
      <ButtonGroup minimal={true}>
        <Tooltip content='Helpful!'>
          <Button
            icon='thumbs-up'
            intent='success'
            onClick={() => handleRating(true)}
          />
        </Tooltip>
        <Tooltip content='Not Helpful'>
          <Button
            icon='thumbs-down'
            intent='danger'
            onClick={() => handleRating(false)}
          />
        </Tooltip>
      </ButtonGroup>
    );
  }
}
