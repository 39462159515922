// Apollo GraphQL client

// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient, ApolloLink } from '@apollo/client/core';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import * as httpLinkCommon from '@apollo/client/link/http';

import {
  getJwtToken,
  getRefreshToken,
} from '@/components/session/JwtTokenManager';

// ----------------------------------------------------------------------------

const GRAPHQL = `${API_HOST}/api`;

// see frontend/node_modules/@apollo/client/link/batch-http/lib/batchHttpLink.js
function defaultBuildBatchKeyFnc(operation) {
  const context = operation.getContext();
  const contextConfig = {
    http: context.http,
    options: context.fetchOptions,
    credentials: context.credentials,
    headers: context.headers,
  };
  return (
    httpLinkCommon.selectURI(operation, GRAPHQL) + JSON.stringify(contextConfig)
  );
}

const UNIQUE_OPERATION_REQUEST_NAMES = [
  'InviteClients',
  'MeetingTypes',
  'StaffCustomer',
  'ActiveCoachProductsForCustomer',
];

// Create a HTTP client (both server/client). It takes the GraphQL
// server from the `GRAPHQL` environment variable
export function createClient(cacheOption): ApolloClient<NormalizedCacheObject> {
  // Create the cache first, which we'll share across Apollo tooling.
  // This is an in-memory cache. Since we'll be calling `createClient` on
  // universally, the cache will survive until the HTTP request is
  // responded to (on the server) or for the whole of the user's visit (in
  // the browser)
  const cache = new InMemoryCache(cacheOption || {});

  // If we're in the browser, we'd have received initial state from the
  // server. Restore it, so the client app can continue with the same data.
  if (!import.meta.env.SSR) {
    cache.restore((window as any).__APOLLO__);
  }

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: getJwtToken()
        ? {
            Authorization: `JWT ${getJwtToken()}`,
            Session: getRefreshToken(),
            IsExec: IS_EXEC,
          }
        : { IsExec: IS_EXEC },
    });

    return forward(operation);
  });

  const httpLink = new BatchHttpLink({
    credentials: 'same-origin',
    uri: GRAPHQL,
    batchKey: (operation) => {
      if (
        operation &&
        UNIQUE_OPERATION_REQUEST_NAMES.includes(operation.operationName)
      ) {
        return `${operation.operationName}_${Math.random()}`; // would like to send unique query
      }
      return defaultBuildBatchKeyFnc(operation);
    },
  });

  // Return a new Apollo Client back, with the cache we've just created,
  // and an array of 'links' (Apollo parlance for GraphQL middleware)
  // to tell Apollo how to handle GraphQL requests
  return new ApolloClient({
    cache,
    link: ApolloLink.from([
      authMiddleware,
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      httpLink,
    ]),
    ssrMode: import.meta.env.SSR,
    resolvers: {},
  });
}
