import { Classes, Colors, Icon } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import { Box, Flex } from '@/components/layout/flexbox';
import WorkspaceNav from '@/components/pages_logged_in/workspace/nav';
import { Container } from '@/components/pieces/bootstrap';
import { SIDE_NAV_WIDTH } from '@/css/constants';

function ProgramDetailsHeaderLoader() {
  return (
    <Box className='bbs' pt={[2, 4]} bg={Colors.WHITE}>
      <Container>
        <Box mb={3}>
          <Link
            to='/programs'
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon icon='chevron-left' size={14} />{' '}
            <span style={{ fontSize: 15 }}>All Programs</span>
          </Link>
        </Box>

        <Flex mt={2} alignItems={'center'} flexDirection={'row'}>
          <Flex flexDirection='column' flex={1} width={'auto'}>
            <Box height={30} width={175} className={Classes.SKELETON} />
            <Flex mt={2}>
              <Box height={24} width={95} className={Classes.SKELETON} />
              <Box height={24} width={300} className={Classes.SKELETON} />
            </Flex>
          </Flex>
        </Flex>
        <Flex mt={3} height={60} alignItems='center'>
          <Box height={22} width={100} className={Classes.SKELETON} />
          <Box height={22} width={100} className={Classes.SKELETON} ml={24} />
          <Box height={22} width={100} className={Classes.SKELETON} ml={24} />
        </Flex>
      </Container>
    </Box>
  );
}

export default function ProgramDetailsLoader({
  includeSidebar = false,
  hasHeader = true,
  sizeNavWidth = SIDE_NAV_WIDTH,
}) {
  return (
    <>
      {includeSidebar && <WorkspaceNav sizeNavBreakpoint='md' />}
      <Box ml={includeSidebar ? [0, 0, sizeNavWidth] : 0}>
        {hasHeader && <ProgramDetailsHeaderLoader />}
        <Box>
          <Container mb={2}>
            <Box mt={36} mb={5} className={Classes.SKELETON} height={400} />
          </Container>
        </Box>
      </Box>
    </>
  );
}
