import { Placement } from '@floating-ui/react';

import { Popover } from './Popover';

import type { PopoverProps } from './props';

interface TooltipProps
  extends Omit<PopoverProps, 'intent' | 'position' | 'placement'> {
  /**
   * Whether to use a compact appearance
   */
  compact?: boolean;

  /**
   * Maximum width of the tooltip content
   * @default "400px"
   */
  maxWidth?: string;

  /**
   * The amount of time in milliseconds the tooltip should wait before opening
   * after the user hovers over the trigger.
   * @default 100
   */
  hoverOpenDelay?: number;

  /**
   * The amount of time in milliseconds the tooltip should remain open after
   * the user hovers off the trigger.
   * @default 0
   */
  hoverCloseDelay?: number;

  /**
   * Duration in milliseconds for the tooltip enter/exit animation
   * @default 100
   */
  transitionDuration?: number;

  /**
   * Props to be applied to the target element
   */
  targetProps?: React.HTMLAttributes<HTMLElement>;

  /**
   * Whether the tooltip is disabled.
   * When true, the tooltip will not be shown.
   * @default false
   */
  disabled?: boolean;

  /**
   * The placement of the tooltip.
   * @default 'right'
   */
  placement?: Placement;
}

export function Tooltip({
  content,
  children,
  compact = false,
  maxWidth = '400px',
  placement = 'right',
  hoverOpenDelay = 100,
  hoverCloseDelay = 0,
  minimal = false,
  className,
  popoverClassName,
  transitionDuration = 100,
  targetProps,
  disabled = false,
  ...restProps
}: TooltipProps) {
  // If disabled, just render the children without the tooltip
  if (disabled) {
    return <>{children}</>;
  }

  const backgroundColor = 'var(--blueprint-dark-gray5)';

  return (
    <Popover
      content={
        // TODO: Remove inline styles after removing Blueprintjs styles from Popover
        <div className='rounded-md shadow-md' style={{ backgroundColor }}>
          <div
            className={`max-w-[${maxWidth}] leading-[1.4] text-white ${compact ? 'p-[5px_8px]' : 'p-[10px_16px]'} `}
            style={{ fontSize: '14px' }}
          >
            {content}
          </div>
        </div>
      }
      interactionKind='hover'
      placement={placement}
      minimal={minimal}
      className={className}
      popoverClassName={popoverClassName}
      hoverOpenDelay={hoverOpenDelay}
      hoverCloseDelay={hoverCloseDelay}
      transitionDuration={transitionDuration}
      targetProps={targetProps}
      modifiers={{
        arrow: {
          enabled: !minimal,
        },
      }}
      arrowProps={{
        fill: backgroundColor,
      }}
      {...restProps}
    >
      {children}
    </Popover>
  );
}
