import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Classes, Icon, Menu, MenuItem, Position } from '@blueprintjs/core';
import styled from '@emotion/styled';

import { useWorkspaceSlug } from '@/components/helpers/custom-hooks/use-workspace-slug';
import { Popover } from '@/components/helpers/ui/blueprint-overrides/Popover';
import { Box, Flex } from '@/components/layout/flexbox';
import {
  CreateWorkspaceDialog,
  CreateWorkspaceOption,
} from '@/components/pages_logged_in/workspace/nav/SideNav/CreateWorkspace';
import EnableIndividualDialog from '@/components/pages_logged_in/workspace/nav/SideNav/EnableIndividualDialog';
import WorkspaceMenuLogo from '@/components/pages_logged_in/workspace/nav/SideNav/WorkspaceMenuLogo';
import { DEFAULT_PERSONAL_WOKSPACE } from '@/components/pages_logged_in/workspace/pieces/useClientWorkspaces';
import { isSingleAccount } from '@/components/pages_public/login/LoginForm';
import { getUser, hasValidToken } from '@/components/session/JwtTokenManager';
import {
  BP_NS,
  BRAND_PURPLE,
  HOVER_BACKGROUND,
  SUBTEXT_COLOR,
} from '@/css/constants';
import { ParticipatedWorkspacesDocument } from '@/graphql';

function WorkspaceHeader({ selectedWorkspace, showLoader }) {
  return (
    <Flex
      fontSize={16}
      fontWeight={600}
      padding={12}
      width={['calc(100% - 50px)', '100%']}
      alignItems='center'
      justifyContent='space-between'
      css={{ whiteSpace: 'nowrap' }}
    >
      <Flex alignItems='center'>
        <WorkspaceMenuLogo
          isLink={false}
          workspace={selectedWorkspace}
          iconSize={24}
          fitSize
          margin={0}
          loading={showLoader}
        />
        <Box
          maxWidth={[150, 150, 100]}
          css={{
            marginLeft: 10,
            marginRight: 10,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className={showLoader ? Classes.SKELETON : null}
        >
          {selectedWorkspace
            ? selectedWorkspace.name
            : DEFAULT_PERSONAL_WOKSPACE.name}
        </Box>
      </Flex>
      <Icon icon='chevron-down' color={SUBTEXT_COLOR} size={18} />
    </Flex>
  );
}

function WorkspaceMenuItem({ workspace, isActive, hasIndividual, onSelect }) {
  const { name, urlSlug } = workspace;
  const isPersonalAccount = !urlSlug && !hasIndividual;

  return (
    <MenuItem
      className={isActive ? 'active' : ''}
      onClick={() => onSelect(urlSlug, isPersonalAccount)}
      text={
        <Flex
          justifyContent='space-between'
          alignItems='center'
          fontSize={16}
          minWidth={200}
        >
          {isPersonalAccount ? (
            <Icon icon='plus' size={18} color={SUBTEXT_COLOR} />
          ) : (
            <WorkspaceMenuLogo
              workspace={urlSlug ? workspace : null}
              isLink={false}
              iconSize={24}
              fitSize
              margin={0}
            />
          )}
          <Box
            width={1}
            css={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            {isPersonalAccount ? 'Add Personal Account' : name}
          </Box>
        </Flex>
      }
    />
  );
}

export default function WorkspaceSwitching({ pageLoading }) {
  const [enableIndividualDialogOpen, setEnableIndividualDialogOpen] =
    useState(false);
  const [createWorkspaceVisible, setCreateWorkspaceVisible] = useState(false);
  const [isPopoverOpen] = useState(false);

  const workspaceSlug = useWorkspaceSlug();
  const user = getUser();
  const { data: workspacesData, loading } = useQuery(
    ParticipatedWorkspacesDocument,
    {
      skip: !hasValidToken(),
    },
  );

  const showLoader = pageLoading || loading;
  const workspaces = workspacesData?.participatedWorkspaces || [];
  const hasIndividual = workspaces.length ? user.hasIndividualAccount : true;
  const selectedWorkspace = workspaces.find((w) => w.urlSlug === workspaceSlug);

  const handleWorkspaceSelect = (urlSlug, isPersonalAccount) => {
    if (isPersonalAccount) {
      setEnableIndividualDialogOpen(true);
      return;
    }

    window.location.href = `/to-workspace/${urlSlug || 'personal-mode'}`;
  };

  // Early return for single account
  if (isSingleAccount(workspaces, hasIndividual) && !IS_EXEC) {
    return (
      <WorkspaceMenuLogo
        isLink={true}
        workspace={selectedWorkspace}
        loading={loading}
      />
    );
  }

  const options = [...workspaces, DEFAULT_PERSONAL_WOKSPACE];

  return (
    <Box p={2} height={61} className='bbs'>
      <StyledPopover
        canEscapeKeyClose={true}
        isOpen={isPopoverOpen}
        interactionKind='click'
        position={Position.BOTTOM}
        modifiers={{ arrow: { enabled: false }, flip: { enabled: false } }}
        usePortal={true}
        content={
          <StyledMenu>
            {options.map((workspace) => (
              <WorkspaceMenuItem
                key={workspace.name}
                workspace={workspace}
                isActive={workspaceSlug === workspace.urlSlug}
                hasIndividual={hasIndividual}
                onSelect={handleWorkspaceSelect}
              />
            ))}
            <CreateWorkspaceOption
              setDialogVisible={setCreateWorkspaceVisible}
            />
          </StyledMenu>
        }
      >
        <WorkspaceHeader
          selectedWorkspace={selectedWorkspace}
          showLoader={showLoader}
        />
      </StyledPopover>

      <EnableIndividualDialog
        visible={enableIndividualDialogOpen}
        setVisible={setEnableIndividualDialogOpen}
        afterEnable={() => {
          window.location.reload();
        }}
      />
      <CreateWorkspaceDialog
        visible={createWorkspaceVisible}
        setVisible={setCreateWorkspaceVisible}
      />
    </Box>
  );
}

const StyledPopover = styled(Popover)`
  cursor: pointer;
  width: 100%;
  display: inline-block;
  border-radius: 4px;

  &:hover {
    background-color: ${HOVER_BACKGROUND};
  }

  .${BP_NS}-popover-target {
    width: 100%;
  }
`;

const StyledMenu = styled(Menu)`
  max-height: 370px;
  overflow: hidden;
  overflow-y: auto;

  &.${Classes.MENU} {
    padding: 0px;
    min-width: auto;
    .${Classes.MENU_ITEM} {
      padding: 16px;
      border-radius: unset;

      img,
      svg {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }

      &:hover,
      &:focus {
        color: ${BRAND_PURPLE};
        text-decoration: none;
        outline: none;
        background-color: ${HOVER_BACKGROUND};
      }

      &.active {
        img,
        svg {
          -webkit-filter: none;
          filter: none;
        }
        color: ${BRAND_PURPLE};
        font-weight: 600;
        background-color: ${HOVER_BACKGROUND};
      }
    }
  }
`;
