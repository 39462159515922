import { Link, useLocation } from 'react-router-dom';

import { Media } from '@/components/layout/Media';
import Logo from '@/components/nav/Logo';
import PlacementSvg from '@/components/pieces/icons/svg/placement';

export default function NavLogo({ path }) {
  const location = useLocation();
  const image = (
    <>
      <Media lessThan='lg'>
        <PlacementSvg size={35} />
      </Media>
      <Media greaterThan='md'>
        <Logo height={35} />
      </Media>
    </>
  );
  return (
    <>{location.pathname === path ? image : <Link to={path}>{image}</Link>}</>
  );
}
