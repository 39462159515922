import styled from '@emotion/styled';

import { Box } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import { BRAND_PURPLE, PURPLE_TEXT_COLOR } from '@/css/constants';

interface StyledTitleProps {
  color?: string;
}

export const StyledTitle = styled.h2<StyledTitleProps>`
  margin-top: 0;
  text-align: center;
  color: ${(props) => (props.color ? props.color : BRAND_PURPLE)};
  font-size: 48px;
  font-weight: bold;
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 36px;
  }
`;

export function StyledSubtitle(props: any) {
  return (
    <Box
      maxWidth={900}
      textAlign='center'
      m={'0 auto'}
      fontSize={[18, 24]}
      color={PURPLE_TEXT_COLOR}
      pb={2}
      {...props}
    />
  );
}

export function StyledSmallText({ color, ...props }: any) {
  return (
    <Box
      fontSize={14}
      color={color || BRAND_PURPLE}
      letterSpacing={1}
      fontWeight={500}
      textAlign='center'
      {...props}
    />
  );
}
