import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Callout, Intent } from '@blueprintjs/core';
import { useNavigate, useParams } from 'react-router-dom';

import { Box } from '@/components/layout/flexbox';
import { buildRoleplayUrl } from '@/components/pages_logged_in/roleplays/utils';
import StyledDialog from '@/components/pieces/StyledDialog';
import {
  ArchiveScenarioDocument,
  RoleplayDocument,
  ScenarioType,
  VisibilityScope,
} from '@/graphql';

function ScenarioDeleteConfirm({
  open,
  onClose,
  scenario,
  onSuccess = () => {},
  shouldRedirectAfterDelete = true,
}: {
  open: boolean;
  onClose: () => void;
  scenario: ScenarioType;
  onSuccess?: () => void;
  shouldRedirectAfterDelete?: boolean;
}) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const roleplaySlug = useParams<{
    roleplaySlug?: string;
  }>()?.roleplaySlug;

  const [archive, { loading }] = useMutation(ArchiveScenarioDocument, {
    refetchQueries: [
      {
        query: RoleplayDocument,
        variables: {
          slug: roleplaySlug,
        },
      },
    ],
    onCompleted: (data) => {
      if (data.archiveScenario.ok) {
        onSuccess();
        if (shouldRedirectAfterDelete) {
          const redirectUrl = roleplaySlug
            ? buildRoleplayUrl(roleplaySlug)
            : '/roleplays';
          navigate(redirectUrl);
        }
      }
      if (data.archiveScenario.errors) {
        setErrorMessage(data.archiveScenario.errors[0].message);
      }
    },
  });

  return (
    <StyledDialog
      isOpen={open}
      lazy={true}
      title='Are you sure you want to delete this scenario?'
      width='480px'
      onClose={() => {
        onClose();
      }}
      onOpening={() => {
        setErrorMessage(null);
      }}
    >
      <Box p={24}>
        {errorMessage && (
          <Box mb={3}>
            <Callout icon={null} intent={Intent.DANGER}>
              {errorMessage}
            </Callout>
          </Box>
        )}

        <Box>
          <Box>This will:</Box>
          <ul>
            {scenario?.visibilityScope === VisibilityScope.Workspace && (
              <li>Deleting this scenario will remove it from the workspace.</li>
            )}
            <li>Update related setting on program components</li>
          </ul>
        </Box>
        <Box mt={4}>
          <Button
            intent={Intent.DANGER}
            large={true}
            disabled={loading}
            onClick={() => {
              archive({ variables: { id: scenario?.id } });
            }}
            loading={loading}
          >
            Delete
          </Button>
          <Button
            large={true}
            disabled={loading}
            onClick={() => {
              onClose();
            }}
            style={{ marginLeft: 12 }}
          >
            Nevermind
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
}

export default ScenarioDeleteConfirm;
