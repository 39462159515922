import { ReactNode } from 'react';

import styled from '@emotion/styled';

import EmbedSvg from '@/components/helpers/ui/EmbedSvg';
import { Box, Flex } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import { Container } from '@/components/pieces/bootstrap';
import {
  StyledSmallText,
  StyledSubtitle,
  StyledTitle,
} from '@/components/pieces/marketing/Titles';
import WhiteBar from '@/components/pieces/marketing/WhiteBar';
import LPLink from '@/components/pieces/upsell/LPLink';
import {
  BRAND_PURPLE,
  MIDTEXT_COLOR,
  PURPLE_TEXT_COLOR,
} from '@/css/constants';

interface UserExperienceProps {
  items?: any[];
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  ctaLink?: string;
  ctaText?: string;
  onCtaClick?: () => void;
  smallText?: string;
  itemsPerRow?: number;
  background?: string;
  borderWidth?: number;
  footer?: ReactNode;
}

export default function UserExperienceV2({
  items = [],
  title = 'A seamless, modern experience',
  subtitle = 'World-class coaches. Incredible technology. Lasting change.',
  ctaLink = null,
  ctaText = null,
  onCtaClick = null,
  smallText = null,
  itemsPerRow = 4,
  background = '#FFF',
  borderWidth = 1,
  footer = null,
}: UserExperienceProps) {
  return (
    <WhiteBar style={{ backgroundColor: background }}>
      <Container>
        <Box mb={4}>
          {smallText ? (
            <StyledSmallText color={BRAND_PURPLE} mb={3}>
              {smallText}
            </StyledSmallText>
          ) : null}
          <StyledTitle color={PURPLE_TEXT_COLOR}>{title}</StyledTitle>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
        </Box>
        <StyledBox style={{ background: background, borderWidth: borderWidth }}>
          <StyledGrid
            itemsPerRow={itemsPerRow}
            style={{ gridGap: borderWidth }}
          >
            {items.map((item, i) => {
              const { title, photo, feature } = item;
              return (
                <GridItem
                  className='grid-item'
                  key={'item-grid-' + i}
                  style={{ backgroundColor: background }}
                >
                  <StyledIcon src={photo} />
                  <Flex flexDirection={['column', 'column']}>
                    {feature && (
                      <Box
                        pt={[0, 0, 3]}
                        pb={[1, 2, 0]}
                        px={[0, 0, 4]}
                        ml={[3, 3, 9]}
                        fontSize={20}
                        fontWeight={600}
                        color={MIDTEXT_COLOR}
                      >
                        {feature}
                      </Box>
                    )}
                    <Box
                      pt={[0, 0, 3]}
                      px={[0, 0, 4]}
                      ml={[3, 3, 9]}
                      fontSize={18}
                    >
                      {title}
                    </Box>
                  </Flex>
                </GridItem>
              );
            })}
          </StyledGrid>
        </StyledBox>
        {ctaText ? (
          <Flex alignItems='center' flexDirection='column' mt={5}>
            <Box mb={3}>
              <LPLink
                onCtaClick={onCtaClick}
                to={ctaLink}
                color={BRAND_PURPLE}
                text={ctaText}
                style={{
                  width: 300,
                }}
              />
            </Box>
          </Flex>
        ) : null}
        {footer}
      </Container>
    </WhiteBar>
  );
}

const StyledBox = styled(Box)`
  background: white;
  border: 1px solid rgb(244, 241, 255);
  border-radius: 10px;
  overflow: hidden;
`;

const GridItem = styled('div')`
  min-height: 195px;
  overflow: hidden;
  text-align: center;
`;

const StyledIcon = styled(EmbedSvg)`
  margin-bottom: 20px;
  max-height: 100px;
`;

interface StyledGridProps {
  itemsPerRow?: number;
}

const StyledGrid = styled(Flex)<StyledGridProps>`
  display: grid;
  background-color: rgb(244, 241, 255);
  grid-gap: 1px;
  grid-template-columns: repeat(
    ${(props) => (props.itemsPerRow ? props.itemsPerRow : 4)},
    1fr
  );

  .grid-item {
    padding: 20px 0;
    background-color: #fff;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    grid-template-columns: repeat(
      ${(props) => (props.itemsPerRow === 3 ? 1 : 2)},
      1fr
    );

    .grid-item {
      display: flex;
      align-items: center;
      padding: 20px;
      text-align: left;
      min-height: auto;

      img {
        width: 60px;
      }

      &:last-item {
        border-bottom: 0px;
      }
    }
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
