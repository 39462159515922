import { ReactNode, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Classes, Icon, IconName, Intent } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import { ResendEmailConfirmationDocument } from '@/graphql';

export function ResendConfirmationButton({
  onSuccess,
  cta = 'Resend',
  ...btnProps
}) {
  const [resendConfirmation, { loading }] = useMutation(
    ResendEmailConfirmationDocument,
    {
      onCompleted: () => onSuccess('sent'),
    },
  );
  const text = loading ? 'Sending' : cta;

  return (
    <Button
      text={text}
      loading={loading}
      onClick={(e) => {
        e.preventDefault();
        resendConfirmation();
      }}
      {...btnProps}
    />
  );
}

export default function ResendEmailConfirmation({ email }) {
  const [sendStatus, setStatus] = useState('unsent');
  interface MessageOptions {
    icon: IconName;
    iconColor: string | null;
    iconIntent: Intent | null;
    message: string | ReactNode;
    py: number;
  }

  let options: MessageOptions = {
    icon: 'error',
    iconColor: '#CFDDE9',
    iconIntent: null,
    message:
      'Please confirm your email by clicking the link in the email we sent you',
    py: 1,
  };

  if (sendStatus === 'sent') {
    options = {
      icon: 'tick-circle',
      iconColor: null,
      iconIntent: 'success',
      message: (
        <div>
          Confirmation resent to <b>{email}</b>. It may take a minute to arrive.
        </div>
      ),
      py: 2,
    };
  }

  return (
    <Flex
      className='bg-dark'
      justifyContent='space-around'
      alignItems='center'
      px={2}
      py={[options.py + 1, options.py]}
      style={{ minHeight: 50 }}
    >
      <Flex alignItems='center' flexWrap='wrap'>
        <Flex alignItems='top' className={Classes.DARK} mb={[1, 0]}>
          <Icon
            icon={options.icon}
            color={options.iconColor}
            intent={options.iconIntent}
            style={{ marginTop: 2 }}
          />
          <Box ml={2}>{options.message}</Box>
        </Flex>
        {sendStatus === 'unsent' ? (
          <Box ml={2} pl={[3, 1]} my={[1, 0]}>
            <ResendConfirmationButton onSuccess={setStatus} />
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );
}
