import { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Callout,
  Classes,
  Colors,
  Dialog,
  Icon,
  TextArea,
} from '@blueprintjs/core';
import styled from '@emotion/styled';

import sanitizeGraphqlObject from '@/components/helpers/graphql/sanitize-graphql-object';
import {
  convertToHtml,
  convertToMarkdown,
} from '@/components/helpers/string-utils';
import LazyImg from '@/components/helpers/ui/LazyImg';
import { Box, Flex } from '@/components/layout/flexbox';
import PrimaryButton from '@/components/pieces/button/PrimaryButton';
import Tiptap from '@/components/pieces/editor/tiptap';
import Subtext from '@/components/pieces/form/Subtext';
import IndustryMultiselect from '@/components/pieces/industries/IndustryMultiselect';
import JobFunctionMultiselect from '@/components/pieces/jobs/JobFunctionMultiselect';
import { SUBTEXT_COLOR } from '@/css/constants';
import { AgentProfileType, UpdateCoachInfoDocument } from '@/graphql';

interface EditCoachProfileDialogProps {
  visible: boolean;
  profile: AgentProfileType;
  onClose: (value: boolean) => void;
  onSave: () => Promise<void>;
  specialtySlug?: string;
}

function EditCoachProfileDialog({
  visible,
  onClose,
  profile,
  onSave,
  specialtySlug = null,
}: EditCoachProfileDialogProps) {
  const { user } = profile;
  const { photo = '' } = user || {};
  const photoSrc =
    photo && photo.startsWith('/media') ? `${API_HOST}${photo}` : photo;
  const [jobFunctions, setJobFunctions] = useState(
    profile.relevantJobFunctions.map(sanitizeGraphqlObject),
  );
  const [industries, setIndustries] = useState(
    profile.relevantIndustries.map(sanitizeGraphqlObject),
  );
  const [shortSummary, setShortSummary] = useState(profile.summary);
  const [fullBio, setFullBio] = useState(convertToHtml(profile.about));
  const [error, setError] = useState<string | null>(null);

  const [updateCoachInfo, { loading }] = useMutation(UpdateCoachInfoDocument, {
    onCompleted: ({ updateCoachInfo }) => {
      if (updateCoachInfo.ok) {
        onSave().then(() => {
          onClose(false);
        });
      } else {
        setError(updateCoachInfo.error.message);
      }
    },
    onError: () => {
      setError('Unable to save coach profile. Please try again later.');
    },
  });

  return (
    <StyledDialog
      isOpen={visible}
      onClose={() => onClose(false)}
      lazy={true}
      title='Edit Profile'
      style={{ width: 700 }}
    >
      <StyledContainerList
        pt={2}
        pb={16}
        px={20}
        backgroundColor='#FBFBFF'
        fontSize={16}
      >
        <Flex alignItems={'center'} fontSize={20} my={2} fontWeight={600}>
          <Icon icon='asterisk' color={'#ED5DA0'} style={{ marginRight: 5 }} />
          Essentials
        </Flex>
        <Flex
          width={'100%'}
          flexDirection='column'
          justifyContent='space-between'
          backgroundColor='#F5F5FB'
          p={16}
          css={{ borderRadius: 8 }}
        >
          <Box>
            <Box fontWeight={600} mb={2}>
              Profile Photo
            </Box>
            <Flex justifyContent='flex-start'>
              <LazyImg
                src={photoSrc}
                height={75}
                width={75}
                style={{ borderRadius: 6 }}
              />
              <Box>
                <Subtext pl={3}>
                  To edit your photo please email{' '}
                  <a href='mailto:hello@exec.com'>hello@exec.com</a>.
                </Subtext>
              </Box>
            </Flex>
          </Box>
          <Box mt={3}>
            <Flex
              width={1}
              mb={2}
              justifyContent='space-between'
              flexWrap={['wrap', 'wrap', 'nowrap']}
            >
              <Flex>
                <Box fontWeight={600}>Short Summary</Box>
                <Box ml={1} color={SUBTEXT_COLOR}>
                  (Max of 280 characters.)
                </Box>
              </Flex>
              {shortSummary && (
                <Box color={SUBTEXT_COLOR}>{shortSummary.length}/280</Box>
              )}
            </Flex>
            <Box>
              <TextArea
                rows={3}
                fill={true}
                maxLength={280}
                placeholder='Add your short summary'
                value={shortSummary}
                onChange={(e) => setShortSummary(e.target.value)}
                disabled={loading}
                style={{
                  boxShadow: 'none',
                  resize: 'vertical',
                  border: '1px solid #E1E8ED',
                }}
              />
            </Box>
          </Box>
          <Box mt={3}>
            <Flex mb={2}>
              <Box fontWeight={600}>Full Bio</Box>
              <Box ml={1} color={SUBTEXT_COLOR}>
                (Rich text editor)
              </Box>
            </Flex>
            <Box>
              <Tiptap
                content={fullBio}
                onChange={setFullBio}
                placeholder='Write your full bio here...'
              />
            </Box>
          </Box>
        </Flex>
        <Flex
          alignItems={'center'}
          fontSize={20}
          mb={2}
          mt={4}
          fontWeight={600}
        >
          <Icon icon='edit' color={'#cb9921'} style={{ marginRight: 5 }} />
          Expertise
        </Flex>
        <Flex
          width={'100%'}
          flexDirection='column'
          justifyContent='space-between'
          backgroundColor='#F5F5FB'
          p={16}
          css={{ borderRadius: 8 }}
        >
          <Box mt={2}>
            <Flex mb={2}>
              <Box fontWeight={600}>Job Functions</Box>
              <Box ml={1} color={SUBTEXT_COLOR}>
                (Limit 10)
              </Box>
            </Flex>
            <Box>
              <JobFunctionMultiselect
                onChange={(newJobFunctions) => {
                  if (newJobFunctions.length <= 10) {
                    setJobFunctions(newJobFunctions);
                  }
                }}
                large={false}
                disabled={loading}
                selections={jobFunctions}
                onKeyPress={() => {}}
              />
            </Box>
          </Box>
          <Box mt={3}>
            <Flex mb={2}>
              <Box fontWeight={600}>Industries</Box>
              <Box ml={1} color={SUBTEXT_COLOR}>
                (Limit 10)
              </Box>
            </Flex>
            <Box>
              <IndustryMultiselect
                onChange={(newIndustries) => {
                  if (newIndustries.length <= 10) {
                    setIndustries(newIndustries);
                  }
                }}
                large={false}
                disabled={loading}
                selections={industries}
                onKeyPress={() => {}}
              />
            </Box>
          </Box>
        </Flex>
        {error && (
          <Box mt={2}>
            <Callout intent='warning'>{error}</Callout>
          </Box>
        )}
        <PrimaryButton
          large={false}
          style={{
            fontSize: 22,
            padding: '16px 48px',
            marginTop: 20,
          }}
          disabled={loading}
          loading={loading}
          onClick={() => {
            updateCoachInfo({
              variables: {
                about: convertToMarkdown(fullBio),
                summary: shortSummary,
                jobFunctionIds: jobFunctions.map((item) => item.id),
                industryIds: industries.map((item) => item.id),
                specialtySlug,
              },
            });
          }}
        >
          {loading ? 'Saving' : 'Save Settings'}
        </PrimaryButton>
      </StyledContainerList>
    </StyledDialog>
  );
}

export default EditCoachProfileDialog;

const StyledDialog = styled(Dialog)`
  &.${Classes.DIALOG} {
    max-width: 90%;
    padding-bottom: 0;
    .${Classes.DIALOG_HEADER} {
      padding: 16px 24px;
      background-color: ${Colors.WHITE};
      .${Classes.HEADING} {
        font-size: 24px;
      }
    }
  }
`;

const StyledContainerList = styled(Box)`
  .${Classes.BUTTON} {
    padding: 12px 19px;
    margin-right: 10px;
  }
  .${Classes.BUTTON_TEXT} {
    font-size: 16px;
  }

  .${Classes.FORM_GROUP} {
    margin: 0;
  }

  border-radius: 12px;
`;
