import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Callout,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
} from '@blueprintjs/core';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import { useNavigate } from 'react-router-dom';

import { useWorkspace } from '@/components/helpers/custom-hooks/use-workspace';
import { pluralize } from '@/components/helpers/string-utils';
import { Popover } from '@/components/helpers/ui/blueprint-overrides/Popover';
import { Tooltip } from '@/components/helpers/ui/blueprint-overrides/Tooltip';
import { Box, Flex } from '@/components/layout/flexbox';
import Filter from '@/components/pages_logged_in/workspace/pieces/Filter';
import FilterButton from '@/components/pages_logged_in/workspace/pieces/FilterButton';
import SettingSvg from '@/components/pieces/icons/svg/setting';
import StyledDialog from '@/components/pieces/StyledDialog';
import Avatar from '@/components/pieces/users/Avatar';
import { getUser } from '@/components/session/JwtTokenManager';
import { MIDTEXT_COLOR } from '@/css/constants';
import {
  CloneScenarioDocument,
  MyCollectionsDocument,
  RoleplayType,
  ScenarioType,
  VisibilityScope,
} from '@/graphql';

export const validateScenarioInput = ({ name }: { name: string }) => ({
  valid: !!name.trim(),
});

const workspaceIcon = 'office';
const workspaceText = 'Workspace';
const onlyMeIcon = 'eye-open';
const onlyMeText = 'Only Me';

const visibleBgStyle = {
  backgroundColor: '#0719560A',
  minWidth: '150px',
};

function CloneScenarioDialog({
  open,
  onClose,
  scenario,
}: {
  open: boolean;
  onClose: () => void;
  scenario?: ScenarioType;
}) {
  const user = getUser();
  const workspace = useWorkspace();

  const isPersonalMode = !workspace;

  const [visibilityScope, setVisibilityScope] = useState<VisibilityScope>(
    VisibilityScope.User,
  );
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<string[]>(
    [],
  );
  const [buttonIcon, buttonText] =
    visibilityScope === VisibilityScope.Workspace
      ? [<Icon icon={workspaceIcon} />, workspaceText]
      : [<Icon icon={onlyMeIcon} />, onlyMeText];

  const [name, setName] = useState(`${scenario?.name} (Copy)`);
  const [errorMessage, setErrorMessage] = useState('123123');
  const navigate = useNavigate();

  useEffect(() => {
    setName(`${scenario?.name} (Copy)`);
  }, [scenario]);

  const [clone, { loading }] = useMutation(CloneScenarioDocument, {
    onCompleted: (data) => {
      if (data.cloneScenario.ok) {
        const redirectUrl = `/scenarios/${data.cloneScenario.scenario.slug}`;
        navigate(redirectUrl);
        onClose();
      }
      if (data.cloneScenario.errors) {
        setErrorMessage("Couldn't clone scenario");
      }
    },
    onError: () => {
      setErrorMessage("Couldn't clone scenario");
    },
  });
  const onCloneScenario = () => {
    setErrorMessage(null);
    clone({
      variables: {
        scenarioId: scenario.id,
        name,
        visibilityScope,
        collectionIds: selectedCollectionIds,
      },
    });
  };

  const validation = validateScenarioInput({ name });
  const [roleplays, setRoleplays] = useState<RoleplayType[]>([]);

  useQuery(MyCollectionsDocument, {
    fetchPolicy: 'cache-and-network',

    onCompleted: (roleplayData) => {
      setRoleplays(roleplayData?.myCollections || []);
    },
    onError: () => {
      setErrorMessage("Couldn't fetch collections");
    },
  });

  const collectionOptions = roleplays.map((roleplay) => ({
    id: roleplay.id,
    name: (
      <Flex
        style={{
          gap: 8,
          maxWidth: 300,
        }}
        alignItems='center'
      >
        {roleplay?.emojiUnicode && (
          <Emoji
            unified={roleplay?.emojiUnicode.toLocaleLowerCase()}
            emojiStyle={EmojiStyle.APPLE}
            size={16}
          />
        )}
        <Box
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {roleplay?.name}
        </Box>
      </Flex>
    ),
  }));

  return (
    <StyledDialog
      isOpen={open}
      lazy={true}
      title={<Box fontSize={18}>Clone Scenario</Box>}
      width='480px'
      onClose={() => {
        onClose();
      }}
      onOpening={() => {
        setErrorMessage(null);
      }}
    >
      <Box py={3} px={'24px'} css={{ borderRadius: '0 0 12px 12px' }}>
        {errorMessage && (
          <Box mb={2}>
            <Callout icon={null} intent={Intent.DANGER}>
              {errorMessage}
            </Callout>
          </Box>
        )}
        <Box width={1}>
          <Box fontWeight={600} fontSize={16}>
            Owner
          </Box>
          <Box mt={1}>
            <Box css={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <Tooltip content={user?.name || user?.email}>
                <Avatar user={user} size={36} />
              </Tooltip>
              <Flex style={{ gap: 4 }} flexDirection={'column'}>
                {user?.name && (
                  <Box css={titleCss} lineHeight={'14px'}>
                    {user?.name}
                  </Box>
                )}
                <Box fontSize={12} color={MIDTEXT_COLOR} lineHeight={'12px'}>
                  {user?.email}
                </Box>
              </Flex>
            </Box>
          </Box>
          {!isPersonalMode && (
            <Box mt={3}>
              <Box fontWeight={600} fontSize={16}>
                Workspace
              </Box>
              <Box css={titleCss} lineHeight={'14px'} mt={1}>
                {workspace?.name}
              </Box>
            </Box>
          )}
          <Box mt={3}>
            <Box fontWeight={600} fontSize={16}>
              Scenario Name
            </Box>
            <InputGroup
              style={{ marginTop: 4, fontSize: 16 }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              large={true}
              fill={true}
              placeholder='Scenario Name'
              autoFocus={true}
              disabled={loading}
            />
          </Box>
          <Box mt={3}>
            <Box fontWeight={600} fontSize={16}>
              Add Scenario To My Collections
            </Box>
            <Box mt={1}>
              <Filter
                button={
                  <FilterButton
                    style={visibleBgStyle}
                    rightIcon={<SettingSvg />}
                    text={
                      selectedCollectionIds.length
                        ? `${pluralize(
                            selectedCollectionIds.length,
                            'Collection',
                            'Collections',
                          )}`
                        : 'No Collection'
                    }
                  />
                }
                items={collectionOptions.map(({ id, name }) => ({
                  id,
                  text: name,
                }))}
                isSelectedFn={(x) => {
                  return selectedCollectionIds.includes(x);
                }}
                multiple={true}
                onSelect={(id: string) => {
                  if (selectedCollectionIds.includes(id)) {
                    setSelectedCollectionIds(
                      selectedCollectionIds.filter((x) => x !== id),
                    );
                  } else {
                    setSelectedCollectionIds((prev) => [...prev, id]);
                  }
                }}
                menuPosition='bottom-left'
                viewMode='checkbox'
                fullHeight={false}
              />
            </Box>
          </Box>
          {!isPersonalMode && (
            <Box mt={3}>
              <Box fontWeight={600} fontSize={16}>
                Visibility Scope
              </Box>
              <Box mt={1}>
                <Popover
                  content={
                    <Menu>
                      {visibilityScope === VisibilityScope.User ? (
                        <MenuItem
                          onClick={() =>
                            setVisibilityScope(VisibilityScope.Workspace)
                          }
                          text={workspaceText}
                          icon={workspaceIcon}
                        />
                      ) : (
                        <MenuItem
                          onClick={() =>
                            setVisibilityScope(VisibilityScope.User)
                          }
                          text={onlyMeText}
                          icon={onlyMeIcon}
                        />
                      )}
                    </Menu>
                  }
                  placement='bottom-end'
                >
                  <Button
                    icon={buttonIcon}
                    style={visibleBgStyle}
                    rightIcon='caret-down'
                  >
                    {buttonText}
                  </Button>
                </Popover>
              </Box>
            </Box>
          )}

          <FormGroup style={{ margin: 0, marginTop: 12 }}>
            <Flex mt={3} style={{ gap: 8 }} justifyContent={'flex-end'}>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                loading={loading}
                style={{ minWidth: 160 }}
                intent='primary'
                disabled={!validation.valid}
                onClick={onCloneScenario}
              >
                Clone
              </Button>
            </Flex>
          </FormGroup>
        </Box>
      </Box>
    </StyledDialog>
  );
}

const titleCss: any = {
  fontSize: '14px',
  fontWeight: 500,
  color: 'black',
};

export default CloneScenarioDialog;
