import DOMPurify from 'dompurify';
import { marked } from 'marked';
import TurndownService from 'turndown';

import { toVulgar } from './number-utils';

const DJANGO_ESCAPE_PAIRS = [
  ['&amp;', '&'],
  ['&lt;', '<'],
  ['&gt;', '>'],
  ['&quot;', '"'],
  ['&#39;', "'"],
];

// This function converts a limited set of HTML character codes
// into their ascii representation. Warning: only display this
// content in sanitized places.
export function unescape(str: string): string {
  return DJANGO_ESCAPE_PAIRS.reduce((str, pair) => {
    return str.replaceAll(pair[0], pair[1]);
  }, str);
}

export function pluralize(
  n,
  singular,
  plural,
  showCount = true,
  showVulgar = false,
) {
  return `${showCount ? `${showVulgar ? toVulgar(n) : n} ` : ''}${
    parseFloat(n) === 1 || n === 1.0 ? singular : plural
  }`;
}

export function formatMoney(
  cashflow: number,
  decimals: number = 2,
  unit: string | null = '$',
  nanDisplayedAs: string = '',
): string {
  const precision = 10 ** decimals;
  const roundedCashflow = Math.round(cashflow * precision) / precision;
  const strMoney = Math.abs(roundedCashflow).toFixed(decimals);

  if (Number.isNaN(Number(strMoney))) {
    return nanDisplayedAs;
  }

  const prefix = cashflow < 0 ? '-' + (unit ?? '') : (unit ?? '');
  return prefix + commafy(strMoney, decimals, decimals);
}

function normalizeNumberToFloat(n: string | number): number {
  let num = n;
  if (typeof num === 'string') {
    if (!num.includes('.') && num.includes(',')) {
      num = num.replace(',', '');
    } else if (num.indexOf('.') > num.indexOf(',')) {
      num = num.replace(',', '');
    } else if (num.indexOf('.') < num.indexOf(',')) {
      num = num.replace('.', '').replace(',', '.');
    }
  }
  return parseFloat(num.toString());
}

function truncateDecimals(
  n: number,
  decimals: number,
  maxDecimals: number,
): string {
  const truncatedNumber = n.toFixed(maxDecimals);
  for (let i = maxDecimals - 1; i > decimals; i--) {
    if (Number(n.toFixed(i)) !== Number(truncatedNumber)) {
      return truncatedNumber;
    }
  }
  return n.toFixed(decimals);
}

/**
 * Formats a number with comma separators for thousands
 * and optional decimal place control
 * @param num - The number to format
 * @param decimals - The minimum number of decimal places
 * @param maxDecimals - The maximum number of decimal places
 * @returns Formatted string with commas
 */
export function commafy(
  num: string | number | null | undefined,
  decimals?: number,
  maxDecimals?: number,
): string {
  if (typeof num === 'undefined' || num === null || num === '') {
    return '';
  }

  let n = normalizeNumberToFloat(num);

  if (typeof decimals === 'number' && typeof maxDecimals === 'number') {
    n = Number(truncateDecimals(n, decimals, maxDecimals));
  } else if (typeof decimals === 'number') {
    n = Number(n.toFixed(decimals));
  }

  return `${n}`
    .split('.')
    .map((s, i) => (i ? s : s.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')))
    .join('.');
}

export function hasHTMLTags(str?: string): boolean {
  if (!str) return false;

  // This regex matches any HTML tag, including self-closing tags
  // It's more permissive than a full HTML validator, which is what we want for editor output
  const htmlTagPattern = /<\/?([a-z][a-z0-9]*)\b[^>]*>/i;
  return htmlTagPattern.test(str);
}

// Match HTML documents that start with an opening tag and end with a closing tag
const COMPLETE_HTML_OPENING_TAG = /^<[a-z][a-z0-9]*(?:\s+[^>]*)?>/i;
const COMPLETE_HTML_CLOSING_TAG = /<\/[a-z][a-z0-9]*>$/i;

/**
 * Checks if a string represents a complete HTML element with matching opening and closing tags
 * @param str - The string to check
 * @returns boolean indicating if the string is a complete HTML element
 */
function isCompleteHtmlTag(str: string): boolean {
  const trimmedStr = str.trim();
  return (
    COMPLETE_HTML_OPENING_TAG.test(trimmedStr) &&
    COMPLETE_HTML_CLOSING_TAG.test(trimmedStr)
  );
}

export function isMarkdown(str: string): boolean {
  if (!str?.trim()) {
    return false;
  }

  if (isCompleteHtmlTag(str)) {
    return false;
  }

  const markdownPatterns = {
    HEADERS: /^#{1,6}\s.+$|^[^\n]+\n[=-]{2,}$/m,
    // Matches markdown emphasis patterns (*italic* or _italic_) with the following rules:
    // - Must not be preceded by <, word char, or backtick (negative lookbehind)
    // - Matches either * or _ as delimiter
    // - Content must not start/end with whitespace
    // - Content can be single char or multiple chars
    // - Must not be followed by word char or > (negative lookahead)
    // This avoids matching emphasis inside HTML tags or code blocks
    EMPHASIS:
      /(?<![<\w`])\*[^\s*](?:[^*]*[^\s*])?\*(?![\w>])|(?<![<\w`])_[^\s_](?:[^_]*[^\s_])?_(?![\w>])/,
    LISTS: /^[\s]*(?:[-*+]|\d+\.)\s(?:\[[x\s]\]\s)?[^\n]+/m,
    BLOCKQUOTES: /^[\s]*(?:>[\s]*)+[^\n]+/m,
    CODE: /^(?:```[^`]*```|(?:(?: {4}|\t)[^\n]+[\n]?)+)/m,
    TABLES: /^\|[^\n]+\|\n\|(?:[-:]+[-|\s:]*)\|/m,
    LINKS: /!?\[(?:[^\]]*)\]\((?:[^)"]+(?:"[^"]*")?)?\)/,
    REFERENCE_LINKS: /!?\[(?:[^\]]*)\](?:\[[^\]]*\])?(?:\s*\[[^\]]*\])?/,
    HORIZONTAL_RULES: /^(?:[\s]*[-*_]){3,}[\s]*$/m,
  };

  const specialPatterns = {
    INLINE_CODE: /`[^`\n]+`/,
    STRIKETHROUGH: /~~(?!\s)[^~]+(?<!\s)~~/,
    HIGHLIGHT: /==(?!\s)[^=]+(?<!\s)==/,
    MATH: /\$\$[^$]+\$\$|\$[^$\n]+\$/,
    ESCAPED: /\\[\\`*_{}[\]()#+\-.!]/,
  };

  // If it's a complete HTML structure, don't check for markdown patterns
  if (hasHTMLTags(str)) {
    // Remove HTML tags and check if the remaining content has any non-whitespace characters
    const textContent = str.replace(/<[^>]+>/g, '');
    if (!textContent.trim()) {
      return false;
    }
  }

  const hasMarkdownSyntax = Object.values(markdownPatterns).some((pattern) =>
    pattern.test(str),
  );

  const hasSpecialFormatting = Object.values(specialPatterns).some((pattern) =>
    pattern.test(str),
  );

  return hasMarkdownSyntax || hasSpecialFormatting;
}

export function convertMarkdownToHtml(markdown: string): string {
  // Configure marked options if needed
  marked.setOptions({
    gfm: true, // GitHub Flavored Markdown
    breaks: true, // Convert \n to <br>
  });

  // Convert markdown to HTML and sanitize
  const rawHtml = marked.parse(markdown) as string;

  if (import.meta.env.SSR) {
    // For SSR, return raw HTML (sanitization will happen on client-side hydration)
    return rawHtml;
  }

  // If we're in a browser environment, sanitize the HTML
  return DOMPurify.sanitize(rawHtml);
}

/**
 * Converts a string to HTML. If the string is Markdown, it converts it to HTML.
 * If the string contains HTML tags, it returns the string as is.
 * Otherwise, it wraps the string in a <p> tag and replaces newlines with <br />.
 * @param str - The string to convert
 * @returns The converted HTML string
 */
export function convertToHtml(str: string): string {
  if (isMarkdown(str)) {
    return convertMarkdownToHtml(str);
  }

  if (hasHTMLTags(str)) {
    return str;
  }

  return `<p>${str.replace(/\n/g, '<br />')}</p>`;
}

const _turndownService = !import.meta.env.SSR
  ? new TurndownService({
      headingStyle: 'atx',
      codeBlockStyle: 'fenced',
      emDelimiter: '*',
      bulletListMarker: '-',
      hr: '---',
    })
  : null;

export function convertHtmlToMarkdown(html: string): string {
  if (import.meta.env.SSR) {
    // SSR doesn't support Turndown, so return the original HTML
    return html;
  }

  return _turndownService?.turndown(html);
}

export function convertToMarkdown(str: string): string {
  if (!str) return '';

  if (isMarkdown(str)) {
    return str;
  } else if (hasHTMLTags(str)) {
    return convertHtmlToMarkdown(str);
  }

  return str;
}
