import { Button, Callout, Classes } from '@blueprintjs/core';

import { Box } from '@/components/layout/flexbox';
import { BehaviorCriterionType } from '@/components/pages_logged_in/roleplays/details/evaluation-criteria/types';
import StyledDialog from '@/components/pieces/StyledDialog';

interface RemoveCriterionDialogProps {
  open: boolean;
  onClose: () => void;
  criterion: BehaviorCriterionType;
  onRemove: (criterion: BehaviorCriterionType) => void;
  loading?: boolean;
  errorMessage?: string;
}

export default function RemoveCriterionDialog({
  open,
  onClose,
  criterion,
  onRemove,
  loading = false,
  errorMessage = '',
}: RemoveCriterionDialogProps) {
  return (
    <StyledDialog
      isOpen={open}
      onClose={onClose}
      lazy={true}
      title='Remove Criterion'
      width='480px'
    >
      <Box
        py={3}
        px={24}
        css={{ lineHeight: '24px' }}
        className={Classes.DIALOG_BODY}
      >
        <p>Are you sure you want to remove this criterion?</p>
        {errorMessage && (
          <Box mb={2}>
            <Callout icon={null} intent='danger'>
              {errorMessage}
            </Callout>
          </Box>
        )}

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={onClose}
              style={{
                marginRight: '5px',
              }}
            >
              Cancel
            </Button>
            <Button
              intent='danger'
              onClick={() => onRemove(criterion)}
              loading={loading}
            >
              Remove
            </Button>
          </div>
        </div>
      </Box>
    </StyledDialog>
  );
}
