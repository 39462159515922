import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import idx from 'idx';
import uniqBy from 'lodash/uniqBy';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from '@/components/helpers/custom-hooks/use-local-storage';
import { isCoach } from '@/components/helpers/workspace/permissions';
import { INDIVIDUAL_ID } from '@/components/pages_logged_in/workspace/pieces/useWorkspaces';
import { getUser } from '@/components/session/JwtTokenManager';
import {
  ClientWorkspacesQueryDocument,
  ClientWorkspacesQueryForStaffDocument,
} from '@/graphql';

export const DEFAULT_PERSONAL_WOKSPACE = {
  id: INDIVIDUAL_ID,
  urlSlug: null,
  name: 'Personal',
  active: true,
};

const useClientWorkspaces = (storageKey: string, clientId: string) => {
  const user = getUser();
  const navigate = useNavigate();
  const [currentWorkspaceId, setCurrentWorkspaceId] = useLocalStorage(
    storageKey,
    INDIVIDUAL_ID,
  );
  const [workspaces, setWorkspaces] = useState([DEFAULT_PERSONAL_WOKSPACE]);

  const onSelectWorkspace = (wid) => {
    if (workspaces.find((item) => item.id === wid)) {
      setCurrentWorkspaceId(wid);
    } else {
      setCurrentWorkspaceId(INDIVIDUAL_ID);
    }
  };

  const isSuperuser = user ? user.isSuperuser : false;

  const getWorkspacesList = (response) => {
    if (isSuperuser) {
      const workspacesData = idx(response, (_) => _.clientWorkspaces) || [];
      return workspacesData.concat([DEFAULT_PERSONAL_WOKSPACE]);
    }
    const assignmentData = idx(response, (_) => _.assignments) || [];
    return uniqBy(
      assignmentData.map(
        (assignment) => assignment.workspace || DEFAULT_PERSONAL_WOKSPACE,
      ),
      'id',
    );
  };

  const [fetch] = useLazyQuery(
    isSuperuser
      ? ClientWorkspacesQueryForStaffDocument
      : ClientWorkspacesQueryDocument,
    {
      onCompleted: (response) => {
        const workspacesList = getWorkspacesList(response);
        const selectedWorkspaceId = workspacesList.find(
          (element) => element.id === currentWorkspaceId,
        );
        if (!selectedWorkspaceId) {
          if (workspacesList.length > 0) {
            setCurrentWorkspaceId(workspacesList[0].id);
          } else {
            if (isCoach() && clientId) {
              navigate(`/clients`);
            }
          }
        }
        setWorkspaces(workspacesList);
      },
    },
  );

  useEffect(() => {
    // Just fetch data when user is coach
    if (isCoach()) {
      fetch({
        variables: {
          clientId: clientId,
        },
      });
    }
  }, [clientId]);

  return [currentWorkspaceId, workspaces, onSelectWorkspace];
};

export default useClientWorkspaces;
