import { useCallback, useMemo } from 'react';

import { Colors, Icon } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';

import { enableReviews } from '@/components/helpers/configuration/utils';
import { isCoach } from '@/components/helpers/workspace/permissions';
import { Box, Flex } from '@/components/layout/flexbox';
import { Media } from '@/components/layout/Media';
import DesktopViewTabs from '@/components/pages_logged_in/program/components/DesktopViewTabs';
import MobileViewTabs from '@/components/pages_logged_in/program/components/MobileViewTabs';
import { BRAND_PURPLE, SUBTEXT_COLOR } from '@/css/constants';

const TAB_THRESHOLD = 3;

function TabRenderer({ tab, active }) {
  const label = active ? (
    <Box
      css={{
        fontWeight: 'bold',
        color: 'black',
      }}
    >
      {tab.label}
    </Box>
  ) : (
    tab.label
  );
  if (tab.alwaysClickable || !active) {
    return <TabLink to={tab.path}>{label}</TabLink>;
  }
  return label;
}

const TABS = [
  { key: 'dashboard', label: 'Dashboard', path: '/dashboard', TabRenderer },
  { key: 'sessions', label: 'Sessions', path: '/sessions', TabRenderer },
  { key: 'reviews', label: 'Reviews', path: '/reviews', TabRenderer },
];

const COACH_TABS = [
  ...TABS,
  {
    key: 'payouts',
    label: 'Payouts',
    path: '/payouts',
    alwaysClickable: true,
    TabRenderer,
  },
  {
    key: 'availability',
    label: 'Availability',
    path: '/availability',
    alwaysClickable: true,
    TabRenderer,
  },
  {
    key: 'services',
    label: 'Services',
    path: '/services',
    alwaysClickable: true,
    TabRenderer,
  },
];

function getAllowableTabs() {
  const tabs = isCoach() ? COACH_TABS : TABS;
  if (enableReviews()) {
    return tabs;
  }

  return tabs.filter((tab) => tab.key !== 'reviews');
}
function verifyTab(selectedTab) {
  if (enableReviews() || selectedTab !== 'reviews') {
    return selectedTab;
  }

  return 'dashboard';
}

function getTab(tabs, selectedTab) {
  const secureTab = verifyTab(selectedTab);
  return tabs.find((t) => t.key === secureTab);
}

export default function CoachingHeader({ selectedTab }) {
  const navigate = useNavigate();

  const tabs = useMemo(() => getAllowableTabs(), []);
  const tab = useMemo(() => getTab(tabs, selectedTab), [tabs, selectedTab]);
  const goToTab = useCallback((tab) => navigate(tab.path), [navigate]);
  const showMobileView = tabs.length > TAB_THRESHOLD;

  return (
    <Box className='bbs' pt={[0, 4]} bg={Colors.WHITE}>
      <Box
        className='container'
        maxWidth={900}
        m='0 auto'
        bg={Colors.WHITE}
        px={[14, 24]}
      >
        <Media greaterThan='xs'>
          <Flex alignItems='center'>
            <Icon icon='home' size={26} color={BRAND_PURPLE} />
            <Box ml={3}>
              <h1 style={{ fontSize: 28, margin: 0 }}>Dashboard</h1>
            </Box>
          </Flex>
        </Media>
        <Box mt={[0, 3]} mx={-14}>
          <Media greaterThan='xs'>
            <DesktopViewTabs
              tabs={tabs}
              currentTab={tab}
              onTabChange={goToTab}
            />
          </Media>

          <Media at='xs'>
            {showMobileView ? (
              <MobileViewTabs
                tabs={tabs}
                currentTab={tab}
                onTabChange={(x) => navigate(x)}
              />
            ) : (
              <DesktopViewTabs
                tabs={tabs}
                currentTab={tab}
                onTabChange={goToTab}
              />
            )}
          </Media>
        </Box>
      </Box>
    </Box>
  );
}

const TabLink = styled(Link)`
  color: ${SUBTEXT_COLOR};
`;
