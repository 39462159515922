import { Colors } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import { Media } from '@/components/layout/Media';
import { ActiveArticleSvgIcon } from '@/components/pieces/icons/svg/article';
import { SUBTEXT_COLOR } from '@/css/constants';

export function PublicLearnHeader() {
  return (
    <Box backgroundColor='#FFF' className='bbs'>
      <Box
        className='container'
        m='0 auto'
        maxWidth='100%'
        px={[14, 24]}
        pt={[36, 36, 48]}
        pb={36}
      >
        <Flex alignItems='center'>
          <h1 style={{ fontSize: 48, margin: 0 }}>Guides</h1>
        </Flex>
        <Box color={SUBTEXT_COLOR} mt={2}>
          Read helpful guides written by experts
        </Box>
      </Box>
    </Box>
  );
}

export default function LearnHeader() {
  return (
    <Media greaterThan='xs'>
      <Box className='bbs' py={4} bg={Colors.WHITE}>
        <Box
          className='container'
          m='0 auto'
          maxWidth='100%'
          bg={Colors.WHITE}
          px={[14, 24]}
        >
          <Flex alignItems='center'>
            <ActiveArticleSvgIcon size={26} />
            <Box ml={3}>
              <h1 style={{ fontSize: 28, margin: 0 }}>Guides</h1>
            </Box>
          </Flex>
          <Box color={SUBTEXT_COLOR} mt={2}>
            Read helpful guides written by experts
          </Box>
        </Box>
      </Box>
    </Media>
  );
}
