import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Colors, TextArea } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import { SITE_NAME } from '@/css/constants';
import { SaveCustomerFeedbackDocument } from '@/graphql';

import MenuHeader from './MenuHeader';

function MenuFeedback({ onBackLink, onClose, onComplete }) {
  const [feedback, setFeedback] = useState('');
  const [hasError, setHasError] = useState(false);

  const [sendFeedback, { loading }] = useMutation(
    SaveCustomerFeedbackDocument,
    {
      variables: {
        input: {
          content: feedback,
          sentFromPage: window.location.pathname,
        },
      },
      onError: () => {
        setHasError(true);
      },
      onCompleted: (response) => {
        const { errors } = response.saveCustomerFeedback;
        if (errors.length) {
          setHasError(true);
        } else {
          onComplete();
        }
      },
    },
  );

  return (
    <Box width={1}>
      <MenuHeader onBackLink={onBackLink} onClose={onClose} label='Feedback' />

      <Box className='text-center' py={3}>
        <Flex justifyContent='center'>
          <svg
            width='170'
            height='121'
            viewBox='0 0 170 121'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M48.7407 35.3989C48.7407 54.9492 64.1548 70.7979 83.169 70.7979C102.183 70.7979 117.597 54.9492 117.597 35.3989C117.597 15.8486 102.183 0 83.169 0C64.1548 0 48.7407 15.8486 48.7407 35.3989Z'
              fill='#DBEBFF'
            />
            <path
              d='M80.9167 53.0984C80.9167 71.0491 95.3223 85.6011 113.093 85.6011C130.863 85.6011 145.269 71.0491 145.269 53.0984C145.269 35.1477 130.863 20.5957 113.093 20.5957C95.3223 20.5957 80.9167 35.1477 80.9167 53.0984Z'
              fill='#DBEBFF'
            />
            <path
              d='M24.287 56.3165C24.287 71.4235 38.2605 83.6702 55.4977 83.6702C72.7348 83.6702 86.7083 71.4235 86.7083 56.3165C86.7083 41.2094 72.7348 28.9628 55.4977 28.9628C38.2605 28.9628 24.287 41.2094 24.287 56.3165Z'
              fill='#DBEBFF'
            />
            <path
              d='M120.815 66.9362C120.815 78.3109 130.034 87.5319 141.407 87.5319C152.78 87.5319 162 78.3109 162 66.9362C162 55.5615 152.78 46.3404 141.407 46.3404C130.034 46.3404 120.815 55.5615 120.815 66.9362Z'
              fill='#DBEBFF'
            />
            <path
              d='M23 81.4176C23 91.9036 32.5077 100.404 44.2361 100.404C55.9645 100.404 65.4722 91.9036 65.4722 81.4176C65.4722 70.9315 55.9645 62.4309 44.2361 62.4309C32.5077 62.4309 23 70.9315 23 81.4176Z'
              fill='#DBEBFF'
            />
            <path
              d='M41.0185 87.5319C41.0185 101.039 52.1109 111.989 65.794 111.989C79.4771 111.989 90.5694 101.039 90.5694 87.5319C90.5694 74.0244 79.4771 63.0745 65.794 63.0745C52.1109 63.0745 41.0185 74.0244 41.0185 87.5319Z'
              fill='#DBEBFF'
            />
            <path
              d='M62.8981 83.992C62.8981 104.431 80.473 121 102.153 121C123.833 121 141.407 104.431 141.407 83.992C141.407 63.5531 123.833 46.984 102.153 46.984C80.473 46.984 62.8981 63.5531 62.8981 83.992Z'
              fill='#DBEBFF'
            />
            <rect
              x='21'
              y='53'
              width='48.1784'
              height='8.82139'
              rx='4.41069'
              fill='white'
            />
            <rect y='69' width='95' height='9' rx='4.5' fill='white' />
            <rect x='75' y='101' width='95' height='9' rx='4.5' fill='white' />
            <path
              d='M38.058 56.8303C38.4254 60.1822 41.1405 62.8006 44.5046 63.0362C44.7829 63.0558 45.0594 63.0754 45.3357 63.0932C47.3871 63.2324 48.9817 64.9359 48.9817 66.9926V74.7664C48.9817 77.3815 51.9749 78.8655 54.055 77.2815C57.8508 74.3899 62.9009 70.0893 65.9994 65.6297C66.7326 64.5737 67.9436 63.9582 69.2299 63.9654C78.7696 64.0134 88.3095 63.7066 97.8492 63.036C100.509 62.8505 102.767 61.179 103.773 58.828C104.041 58.2108 104.219 57.5437 104.296 56.8461C105.709 43.9403 105.709 31.0327 104.296 18.1251C103.928 14.7768 101.212 12.1708 97.8528 11.9353C83.6413 10.9382 69.4314 10.7383 55.2217 11.3359C51.6505 11.4839 48.0775 11.6837 44.5064 11.9353C41.1458 12.171 38.4272 14.7772 38.0598 18.1253C36.647 31.0276 36.647 43.9281 38.058 56.8303Z'
              fill='#9DC6FB'
            />
            <path
              d='M149.878 96.569C149.488 100.124 146.609 102.901 143.04 103.151C142.745 103.172 142.452 103.193 142.159 103.212C139.983 103.359 138.291 105.166 138.291 107.348V115.593C138.291 118.366 135.117 119.941 132.911 118.26C128.884 115.193 123.528 110.632 120.242 105.902C119.464 104.782 118.18 104.129 116.815 104.137C106.697 104.188 96.5788 103.863 86.4604 103.151C83.6396 102.954 81.2443 101.182 80.1773 98.688C79.8934 98.0334 79.7044 97.3258 79.6229 96.5861C78.1244 82.8976 78.1244 69.2074 79.6229 55.5169C80.0125 51.9658 82.8941 49.2015 86.4566 48.9517C101.53 47.8941 116.601 47.6821 131.673 48.316C135.461 48.473 139.25 48.685 143.038 48.9517C146.603 49.2013 149.486 51.9656 149.876 55.5169C151.374 69.2016 151.374 82.8845 149.878 96.569Z'
              fill='#FFC250'
            />
            <path
              d='M105.539 71.5519C105.539 68.6006 104.093 66.1997 102.316 66.1997C100.539 66.1997 99.0933 68.6008 99.0933 71.5519C99.0933 74.503 100.539 76.9041 102.316 76.9041C104.093 76.9041 105.539 74.5032 105.539 71.5519Z'
              fill='#454045'
            />
            <path
              d='M127.183 66.1997C125.406 66.1997 123.96 68.6008 123.96 71.5519C123.96 74.503 125.406 76.9041 127.183 76.9041C128.96 76.9041 130.406 74.503 130.406 71.5519C130.406 68.6008 128.96 66.1997 127.183 66.1997Z'
              fill='#454045'
            />
            <path
              d='M125.941 80.5783C125.064 80.2096 124.056 80.6197 123.687 81.4952C122.167 85.1 118.659 87.4292 114.749 87.4292C110.84 87.4292 107.332 85.1 105.812 81.4952C105.442 80.6197 104.433 80.2093 103.558 80.5785C102.683 80.9477 102.272 81.9565 102.641 82.832C104.7 87.7149 109.453 90.8699 114.749 90.8699C120.046 90.8699 124.799 87.7149 126.857 82.832C127.227 81.9563 126.816 80.9472 125.941 80.5783Z'
              fill='#454045'
            />
            <path
              d='M87.1842 25.4153H48.291C47.341 25.4153 46.5708 24.6452 46.5708 23.6951C46.5708 22.745 47.341 21.9749 48.291 21.9749H87.1842C88.1343 21.9749 88.9044 22.745 88.9044 23.6951C88.9044 24.6452 88.1343 25.4153 87.1842 25.4153Z'
              fill='#F9F6F9'
            />
            <path
              d='M87.1842 35.4893H48.291C47.341 35.4893 46.5708 34.7191 46.5708 33.7691C46.5708 32.819 47.341 32.0488 48.291 32.0488H87.1842C88.1343 32.0488 88.9044 32.819 88.9044 33.7691C88.9044 34.7191 88.1343 35.4893 87.1842 35.4893Z'
              fill='#F9F6F9'
            />
            <path
              d='M62.1685 45.5628H48.291C47.341 45.5628 46.5708 44.7926 46.5708 43.8426C46.5708 42.8925 47.341 42.1223 48.291 42.1223H62.1685C63.1186 42.1223 63.8888 42.8925 63.8888 43.8426C63.8888 44.7926 63.1186 45.5628 62.1685 45.5628Z'
              fill='#F9F6F9'
            />
          </svg>
        </Flex>

        <Box mt={3}>
          <b>Help us improve {SITE_NAME}</b>
        </Box>
        <Box mt={2}>Have an idea? Find a bug? Let us know!</Box>
      </Box>

      <Box style={{ backgroundColor: Colors.WHITE }} p={24}>
        <TextArea
          onChange={(e) => setFeedback(e.currentTarget.value)}
          value={feedback}
          large={true}
          fill={true}
          placeholder='Let us know what’s on your mind'
          rows={3}
          autoFocus={true}
          autoResize={true}
        />
        <Box my={24}>
          {hasError ? (
            <Box mb={3} style={{ color: Colors.ORANGE3 }}>
              An error occurred. Please try again.
            </Box>
          ) : null}
          <Button
            intent='primary'
            text='Send'
            large={true}
            disabled={!feedback}
            loading={loading}
            onClick={sendFeedback}
            fill={true}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default MenuFeedback;
