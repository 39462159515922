import { ReactNode, useState, KeyboardEvent, useRef, useEffect } from 'react';

import { Colors, Icon } from '@blueprintjs/core';
import styled from '@emotion/styled';

export interface MultiselectProps<T> {
  items: T[];
  selectedItems: T[];
  onItemSelect: (item: T) => void;
  onRemove: (item: T, index: number) => void;
  itemRenderer: (item: T) => ReactNode;
  tagRenderer: (item: T) => ReactNode;
  query: string;
  onQueryChange: (query: string) => void;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  fill?: boolean;
  onClearAll: () => void;
  inputStyle?: React.CSSProperties;
}

export function Multiselect<T>({
  items,
  selectedItems,
  onItemSelect,
  onRemove,
  itemRenderer,
  tagRenderer,
  query,
  onQueryChange,
  disabled = false,
  placeholder = 'Search...',
  autoFocus = false,
  onClearAll,
  inputStyle,
}: MultiselectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (items.length > 0 && query) {
      setActiveIndex(0);
    }
  }, [items]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onQueryChange(value);
    setIsOpen(true);
    setActiveIndex(-1);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex((prev) => (prev < items.length - 1 ? prev + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex((prev) => (prev > 0 ? prev - 1 : items.length - 1));
    } else if (e.key === 'Enter' && activeIndex >= 0) {
      e.preventDefault();
      onItemSelect(items[activeIndex]);
      setIsOpen(false);
      setActiveIndex(-1);
    } else if (e.key === 'Escape') {
      setIsOpen(false);
      setActiveIndex(-1);
    }
  };

  const handleItemClick = (item: T) => {
    onItemSelect(item);
    setIsOpen(false);
    setActiveIndex(-1);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Container>
      <InputContainer disabled={disabled}>
        <TagsContainer>
          {selectedItems.map((item, index) => (
            <Tag key={index}>
              {tagRenderer(item)}
              <RemoveButton
                onClick={() => !disabled && onRemove(item, index)}
                disabled={disabled}
              >
                <Icon icon='small-cross' color={Colors.GRAY1} />
              </RemoveButton>
            </Tag>
          ))}
          <Input
            ref={inputRef}
            type='text'
            value={query}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsOpen(true)}
            placeholder={selectedItems.length === 0 ? placeholder : ''}
            disabled={disabled}
            autoFocus={autoFocus}
            style={inputStyle}
          />
        </TagsContainer>
        {selectedItems.length > 0 && (
          <ClearButton
            onClick={() => !disabled && onClearAll?.()}
            disabled={disabled}
          >
            <Icon icon='cross' color={Colors.GRAY1} />
          </ClearButton>
        )}
      </InputContainer>
      {isOpen && items.length > 0 && (
        <Dropdown ref={dropdownRef}>
          {items.map((item, index) => (
            <DropdownItem
              key={index}
              active={index === activeIndex}
              onClick={() => handleItemClick(item)}
            >
              {itemRenderer(item)}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Container>
  );
}

const Container = styled.div<{ fill?: boolean }>`
  position: relative;
  width: ${({ fill }) => (fill ? '100%' : 'auto')};
`;

const InputContainer = styled.div<{ disabled?: boolean }>`
  border: 1px solid ${Colors.LIGHT_GRAY1};
  border-radius: 6px;
  background: white;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  display: flex;
  align-items: flex-start;
  position: relative;

  &:focus-within {
    border-color: ${Colors.BLUE5};
    box-shadow: 0 0 0 1px ${Colors.BLUE5};
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  min-height: 46px;
  padding: 4px 8px;
  flex: 1;
`;

const Tag = styled.span`
  display: inline-flex;
  align-items: center;
  background: ${Colors.LIGHT_GRAY4};
  padding: 18px 12px;
  font-size: 16px;
  gap: 4px;
  height: 24px;
  border-radius: 4px;
`;

const RemoveButton = styled.button<{ disabled?: boolean }>`
  background: none;
  border: none;
  color: ${Colors.GRAY1};
  font-size: 16px;
  padding: 0 2px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.8)};
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 4px;
  flex: 1;
  min-width: 60px;
  min-height: 24px;
  background: transparent;

  &:disabled {
    cursor: not-allowed;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: white;
  border-radius: 3px;
  box-shadow:
    0 0 0 1px rgba(16, 22, 26, 0.1),
    0 2px 4px rgba(16, 22, 26, 0.2);
  max-height: 320px;
  overflow-y: auto;
  z-index: 1000;
  padding: 4px;
`;

const DropdownItem = styled.div<{ active: boolean }>`
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  background: ${({ active }) => (active ? Colors.BLUE3 : 'transparent')};
  color: ${({ active }) => (active ? 'white' : Colors.BLACK)};

  &:hover {
    background: ${({ active }) => (active ? Colors.BLUE3 : Colors.LIGHT_GRAY3)};
  }
`;

const ClearButton = styled.button<{ disabled?: boolean }>`
  background: none;
  border: none;
  color: ${Colors.GRAY1};
  padding: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  right: 8px;
  min-width: 24px;
  min-height: 24px;

  &:hover {
    background: ${Colors.LIGHT_GRAY3};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.8)};
  }
`;
