import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Callout, Classes, Icon, Spinner } from '@blueprintjs/core';
import styled from '@emotion/styled';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { APP_PROFILE } from '@/components/helpers/configuration/constants';
import { Box, Flex } from '@/components/layout/flexbox';
import { GREEN, SITE_NAME, SUBTEXT_COLOR } from '@/css/constants';
import {
  CheckoutDocument,
  CheckoutQuery,
  CheckoutQueryVariables,
  ConfirmOrderDocument,
  PaymentMethodTypes,
  PendingOrderQuery,
} from '@/graphql';

import PaymentMethodSelector from './PaymentMethodSelector';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

interface PaymentFormProps {
  order: PendingOrderQuery['pendingOrder'];
  onOrderConfirmed: any;
  setOrder: any;
  loading: boolean;
  syncing: boolean;
  allowBuyCredits?: boolean;
  disabled?: boolean;
}

function BitsPaymentForm({
  order,
  onOrderConfirmed,
  loading,
  allowBuyCredits = true,
  disabled = false,
  syncing = false,
}: PaymentFormProps) {
  const [selectedMethodId, setSelectedMethodId] = useState(null);
  const [billingName, setBillingName] = useState('');
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedType, setSelectedType] = useState('CARD');
  const stripe = useStripe();
  const elements = useElements();

  // Decide which number to use for our total
  const totalInCredits = order && order.creditTotal;
  const totalInDollars = order && (order.total || order.creditTotalInDollars);

  // Ensure the user has enough credits
  const sufficientCredits =
    selectedMethodId !== 'CREDITS' ||
    totalInCredits === 0 ||
    totalInCredits === null ||
    (order && order.user.availableCredits >= totalInCredits);

  const { data, loading: fetching } = useQuery<
    CheckoutQuery,
    CheckoutQueryVariables
  >(CheckoutDocument);
  const [confirmOrder] = useMutation(ConfirmOrderDocument, {
    onCompleted: (data) => {
      const { confirmOrder } = data || { confirmOrder: null };
      const { error, ok } = confirmOrder || { error: null, ok: false };
      const message = error && error.message;
      if (message || ok === false) {
        setErrorMessage(
          message ||
            `Checkout failed. Please contact ${APP_PROFILE.helloEmail} to support`,
        );
        setProcessing(false);
        setSuccessful(false);
      } else {
        // Send event to Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'conversion',
          conversionValue: totalInDollars,
          transactionId: order.id,
        });
        onOrderConfirmed(order.id);
      }
    },
    onError: () => {
      setErrorMessage(
        `Checkout failed. Please contact ${APP_PROFILE.helloEmail} to support`,
      );
      setProcessing(false);
      setSuccessful(false);
    },
  });

  // Set a default payment method if we have one
  useEffect(() => {
    if (data && data.paymentMethods && data.paymentMethods.length) {
      setSelectedMethodId(data.paymentMethods[0].stripeId);
    } else if (data) {
      setSelectedMethodId('NEW');
    }
  }, [data]);

  function handleSuccessfulPayment(paymentMethod) {
    confirmOrder({
      variables: {
        id: order.id,
        paymentMethod: paymentMethod,
      },
    });
  }

  function handleFailedPayment(message) {
    setProcessing(false);
    setErrorMessage(message);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setErrorMessage(null);
    let fPaymentMethodId = selectedMethodId;
    if (fPaymentMethodId === 'NEW') {
      if (!billingName) {
        handleFailedPayment('Please provide the name on card');
        return;
      }
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingName,
        },
      });
      if (error) {
        handleFailedPayment(error.message);
        return;
      }

      fPaymentMethodId = paymentMethod.id;
    } else if (fPaymentMethodId === 'CREDITS') {
      setProcessing(true);
      setSuccessful(false);
      confirmOrder({
        variables: {
          id: order.id,
          paymentMethod: PaymentMethodTypes.Credits,
        },
      });
      return;
    }

    // Card payments
    stripe
      .confirmCardPayment(order.stripeClientSecret, {
        payment_method: fPaymentMethodId,
        setup_future_usage: 'off_session',
      })
      .then(function (result) {
        if (result.error) {
          handleFailedPayment(result.error.message);
        } else {
          handleSuccessfulPayment(PaymentMethodTypes.Stripe);
        }
      });
  };

  const handlePaymentRequest = async (e) => {
    setProcessing(true);
    setErrorMessage(null);
    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(
        order.stripeClientSecret,
        { payment_method: e.paymentMethod.id },
        { handleActions: false },
      );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      e.complete('fail');
      handleFailedPayment(confirmError.message);
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      e.complete('success');
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow.
      if (paymentIntent.status === 'requires_action') {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(
          order.stripeClientSecret,
        );
        if (error) {
          handleFailedPayment(error.message);
        } else {
          handleSuccessfulPayment(PaymentMethodTypes.Stripe);
        }
      } else {
        handleSuccessfulPayment(PaymentMethodTypes.Stripe);
      }
    }
  };

  // This is for Apple Pay, Google Pay, etc.
  useEffect(() => {
    // We can't create a PaymentRequest until Stripe.js loads.
    // We also can't create a payment request if we don't have a total
    if (!stripe || !order || !totalInDollars) {
      return;
    }

    // Try to create a PaymentRequest (Apple Pay)
    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: `${SITE_NAME}: Bits`,
        amount: Math.round(totalInDollars * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    pr.canMakePayment().then((canMakePaymentRes) => {
      if (canMakePaymentRes) {
        setPaymentRequest(pr);
      } else {
        setPaymentRequest(null);
      }
    });
    pr.on('paymentmethod', handlePaymentRequest);
  }, [stripe, order]);

  return (
    <>
      <Box>
        <PaymentMethodSelector
          loading={fetching || syncing}
          paymentMethods={data ? data.paymentMethods : null}
          paymentRequest={paymentRequest}
          selectedMethodId={selectedMethodId}
          setSelectedMethodId={(id) => {
            setErrorMessage('');
            setSelectedMethodId(id);
          }}
          billingName={billingName}
          setBillingName={setBillingName}
          orderCreditTotal={totalInCredits}
          user={order ? order.user : null}
          handleSubmit={handleSubmit}
          allowBuyCredits={allowBuyCredits}
          selectedType={selectedType}
          onChangePaymentType={setSelectedType}
        />

        {errorMessage ? (
          <Box mt={4}>
            <Callout intent='warning' title='Please check payment details'>
              {errorMessage}
            </Callout>
          </Box>
        ) : null}
      </Box>

      <Box mt={4}>
        <Button
          intent='primary'
          large={true}
          style={{
            fontSize: 22,
            padding: '16px 48px',
            backgroundColor: successful ? GREEN : undefined,
          }}
          disabled={
            !order ||
            !sufficientCredits ||
            loading ||
            fetching ||
            successful ||
            processing ||
            disabled
          }
          onClick={handleSubmit}
        >
          <Flex alignItems='center'>
            <div>
              {successful
                ? 'Success'
                : processing
                  ? 'Processing'
                  : 'Complete Purchase'}
            </div>
            {processing || successful ? (
              <Box fontSize={18} ml={3} opacity={0.6}>
                {successful ? (
                  <Icon
                    icon='tick'
                    color='#FFF'
                    size={20}
                    style={{ marginRight: 12 }}
                  />
                ) : (
                  <WhiteSpinner size={20} />
                )}
              </Box>
            ) : null}
          </Flex>
        </Button>
      </Box>
      <Box mt={2} color={SUBTEXT_COLOR}>
        By completing purchase, you are agreeing to our{' '}
        <a href='/tos' target='_BLANK'>
          Terms of Service
        </a>
      </Box>
    </>
  );
}

export default (props) => {
  return (
    <Elements stripe={stripePromise}>
      <BitsPaymentForm {...props} />
    </Elements>
  );
};

const WhiteSpinner = styled(Spinner)`
  &.${Classes.SPINNER} .${Classes.SPINNER_HEAD} {
    stroke: #fff;
  }
  &.${Classes.SPINNER} .${Classes.SPINNER_TRACK} {
    stroke: rgba(255, 255, 255, 0.4);
  }
`;
