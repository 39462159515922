import { useMutation } from '@apollo/client';
import { Button } from '@blueprintjs/core';

import Card from '@/components/layout/Card';
import { Box, Flex } from '@/components/layout/flexbox';
import Subtext from '@/components/pieces/form/Subtext';
import { LIGHT_BACKGROUND, NAV_Z_INDEX, RED } from '@/css/constants';
import { ResumesDocument, SaveResumeDocument } from '@/graphql';

export default function DeleteConfirm({ resume, onCompleted, onCancel }) {
  const [archive, { loading }] = useMutation(SaveResumeDocument, {
    variables: {
      id: resume.id,
      archived: true,
    },
    onCompleted,
    refetchQueries: [{ query: ResumesDocument }],
    awaitRefetchQueries: true,
  });

  return (
    <Flex
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: NAV_Z_INDEX,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        textAlign: 'center',
      }}
      justifyContent='center'
      alignItems='center'
    >
      <Card elevation={2} p={16}>
        <b>Delete resume?</b>
        <Subtext my={3}>This cannot be undone</Subtext>
        <Flex mx={-2} mt={24}>
          <Box width={1 / 2} p={2}>
            <Button
              fill={true}
              large={true}
              style={{ backgroundColor: LIGHT_BACKGROUND }}
              onClick={onCancel}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
          <Box width={1 / 2} p={2}>
            <Button
              fill={true}
              large={true}
              intent='danger'
              style={{ backgroundColor: RED }}
              onClick={archive}
              loading={loading}
            >
              Delete
            </Button>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
}
