import { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Button,
  Callout,
  Card,
  FormGroup,
  InputGroup,
} from '@blueprintjs/core';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { useWorkspaceSlug } from '@/components/helpers/custom-hooks/use-workspace-slug';
import { buildUrl, getHash } from '@/components/helpers/url-utils';
import {
  isAssist,
  isCoach,
  isWorkspaceAgent,
} from '@/components/helpers/workspace/permissions';
import { Box } from '@/components/layout/flexbox';
import PageTitle from '@/components/pieces/onboarding/PageTitle';
import {
  LoggedInUserType,
  setTokens,
} from '@/components/session/JwtTokenManager';
import { SITE_NAME } from '@/css/constants';
import { Login, LoginDocument, MiniWorkspace } from '@/graphql';
import { getWorkspaceHost } from '@/lib/workspace-utilities';

import { WorkspaceSelector } from './WorkspaceSelector';

const DEFAULT_WOKSPACE = {
  domain: '',
  logo: '',
  name: 'Personal Account',
  role: '',
};

function atBaseDomain() {
  const { host } = window.location;
  const isPublicHost = host.startsWith('www');
  const isLocalHost = host.startsWith('localhost');
  return isPublicHost || isLocalHost;
}

function redirectToWorkspace(
  currentWorkspaceDomain,
  currentWorkspaceRole,
  sesameQuery,
  nextPath,
  searchParams,
) {
  let redirectApp = WEB_APP;
  if (currentWorkspaceDomain && !searchParams.get('noWorkspace')) {
    redirectApp = getWorkspaceHost(redirectApp, currentWorkspaceDomain);
  }

  let redirectPath = '/';
  if (nextPath) {
    if (nextPath.includes('?')) {
      redirectPath = nextPath + '&' + sesameQuery.slice(1);
    } else {
      redirectPath = nextPath + sesameQuery;
    }
  } else if (isWorkspaceAgent(currentWorkspaceRole)) {
    redirectPath = '/workspace/users' + sesameQuery;
  } else if (isCoach()) {
    redirectPath = '/clients';
  } else if (isAssist()) {
    redirectPath = '/assist';
  } else {
    redirectPath = '/dashboard' + sesameQuery;
  }

  window.location.href = redirectApp + redirectPath;
}

export function isSingleAccount(workspaces, hasIndividualAccount) {
  return (
    workspaces.length === 0 ||
    (!hasIndividualAccount && workspaces.length === 1)
  );
}

export default function LoginForm({
  nextPath,
  onComplete,
  signUpLink = null,
  inlineForm = false,
}: {
  nextPath?: string;
  signUpLink?: string;
  onComplete?: (response) => {};
  inlineForm?: boolean;
}) {
  const navigate = useNavigate();
  const workspaceSlug = useWorkspaceSlug();
  const [searchParams] = useSearchParams();

  let next = nextPath;
  if (!nextPath && searchParams.has('next')) {
    next = searchParams.get('next');
  }

  const [email, setEmail] = useState(searchParams.get('email') || '');
  const [password, setPassword] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);
  const [failureMessage, setFailureMessage] = useState('');
  const [loginResponsePayload, setLoginResponsePayload] = useState<Login>();

  function handleComplete(response, skipSelectWorkspace = false) {
    const {
      sesameQuery,
      currentWorkspaceDomain,
      currentWorkspaceRole,
      workspaces = [],
      user = {},
    } = response.login || {};
    const { hasIndividualAccount = true } = user;

    // If there are multiple workspaces, we need to select one
    // before we can proceed, so we set the login response payload
    // which will trigger the WorkspaceSelector to render
    if (
      !skipSelectWorkspace &&
      !isSingleAccount(workspaces, hasIndividualAccount)
    ) {
      setLoginResponsePayload(response.login);
    } else if (onComplete) {
      setTokens({
        ...response.login,
        success: () => {
          onComplete(response);
        },
        error: handleError,
      });
    } else if (
      sesameQuery &&
      ((currentWorkspaceDomain && atBaseDomain()) ||
        !currentWorkspaceDomain ||
        !atBaseDomain())
    ) {
      redirectToWorkspace(
        currentWorkspaceDomain,
        currentWorkspaceRole,
        sesameQuery,
        next,
        searchParams,
      );
    } else {
      setTokens({
        ...response.login,
        success: () => {
          let path;
          if (isWorkspaceAgent(response.login.currentWorkspaceRole)) {
            path = '/workspace/users';
          } else if (isCoach()) {
            path = '/clients';
          } else {
            if (next && next !== '/app') {
              path = next;
            } else {
              path = '/dashboard';
            }
          }

          const appLink = buildUrl(path, {
            hash: getHash(),
          });
          if (window.location.href.endsWith(appLink)) {
            window.location.reload();
          } else {
            navigate(appLink, { replace: true });
          }
        },
        error: handleError,
      });
    }
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      login();
    }
  }

  function handleError(error) {
    const incorrectCreds = error.message.includes('enter valid credentials');
    const isUnexpectedError = error.message.includes('Unexpected error');
    let failureMessage = error?.graphQLErrors?.[0]?.message;

    if (incorrectCreds) {
      failureMessage =
        'The e-mail address and/or password you specified are not correct';
    }
    if (isUnexpectedError || !failureMessage) {
      failureMessage =
        'Sorry, something went wrong on our server. We are investigating ASAP. Please refresh the page and try again.';
    }

    if (error.graphQLErrors) {
      const temporarilyLocked = error.graphQLErrors.find(
        ({ context }) => context && context.status === 429,
      );
      if (temporarilyLocked) {
        failureMessage = temporarilyLocked.message;
      }
    }
    setLoginFailed(true);
    setFailureMessage(failureMessage);
  }

  const [login, { loading }] = useMutation(LoginDocument, {
    variables: {
      email,
      password,
      workspace: workspaceSlug,
    },
    onCompleted: (response) => {
      const newResponse = response;
      const { workspaces = [], user = {} } =
        (newResponse && newResponse.login) || {};
      const { hasIndividualAccount = true } = user;
      if (
        isSingleAccount(workspaces, hasIndividualAccount) &&
        workspaces.length === 1 &&
        !newResponse.login.currentWorkspaceDomain
      ) {
        newResponse.login.currentWorkspaceDomain = workspaces[0].domain;
        newResponse.login.currentWorkspaceRole = workspaces[0].role;
      }
      return handleComplete(newResponse, false);
    },
    onError: handleError,
  });

  const resetLink = buildUrl('/forgot-password', {
    data: {
      email,
      next,
    },
    hash: getHash(),
  });

  const { workspaces = [], user = {} } = loginResponsePayload || {};
  const { hasIndividualAccount = true } = user as LoggedInUserType;

  // Only display the workspace selector if a user has more than 1 option, and is not trying to login to a workspace
  if (!isSingleAccount(workspaces, hasIndividualAccount)) {
    const onLogin = onComplete || handleComplete;
    const items: MiniWorkspace[] = workspaces.concat(
      hasIndividualAccount ? [DEFAULT_WOKSPACE] : [],
    );
    const onClick = (item) => {
      const response = { ...loginResponsePayload };
      response.currentWorkspaceDomain = item.domain;
      response.currentWorkspaceRole = item.role;
      onLogin({ login: response }, true);
    };
    return (
      <WorkspaceSelector
        items={items}
        onClick={onClick}
        inlineForm={inlineForm}
      />
    );
  }

  return (
    <Box width={['95%', inlineForm ? '100%' : 400]} margin='0 auto'>
      {!inlineForm && (
        <PageTitle
          title={`Login to ${SITE_NAME}`}
          style={{ textAlign: 'center' }}
        />
      )}
      <Card
        style={{ padding: inlineForm ? 0 : 20 }}
        elevation={inlineForm ? 0 : 2}
      >
        <FormGroup label='Email'>
          <InputGroup
            placeholder='you@gmail.com'
            large={true}
            value={email}
            name='email'
            type='email'
            onChange={(e) => {
              setEmail(e.target.value);
              setLoginFailed(false);
            }}
            disabled={loading}
            onKeyDown={handleKeyPress}
            autoFocus={!email}
            leftIcon='envelope'
          />
        </FormGroup>
        <FormGroup label='Password'>
          <InputGroup
            placeholder='Enter your password...'
            type='password'
            large={true}
            value={password}
            name='password'
            onChange={(e) => {
              setPassword(e.target.value);
              setLoginFailed(false);
            }}
            disabled={loading}
            onKeyDown={handleKeyPress}
            leftIcon='key'
            autoFocus={!!email}
          />
        </FormGroup>
        {loginFailed ? (
          <Box my={4}>
            <Callout intent='warning' icon='error'>
              {failureMessage}
            </Callout>
          </Box>
        ) : null}
        <Button
          intent='primary'
          text='Login'
          large={true}
          loading={loading}
          onClick={login}
          className='full-width'
        />

        <Box pt={4} className='text-center'>
          <Link to={resetLink}>Forgot your password?</Link>
        </Box>
      </Card>
      {signUpLink && (
        <Box mt={5} p={3} className='round-corners text-center'>
          {`Don't have an account yet? `}
          <Link to={signUpLink}>Sign up here</Link>
        </Box>
      )}
    </Box>
  );
}
