import { getJwtToken } from '@/components/session/JwtTokenManager';

export function postRequest(path, params, method = 'post') {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (Object.hasOwn(params, key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export function downloadResume(resume) {
  return postRequest(`${API_HOST}/resume/${resume.id}`, {
    token: getJwtToken(),
  });
}
