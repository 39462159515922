import { useCallback, useEffect, useRef } from 'react';

import { Button, ButtonGroup, Divider } from '@blueprintjs/core';
import styled from '@emotion/styled';
import Blockquote from '@tiptap/extension-blockquote';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import debounce from 'lodash/debounce';

import { convertToHtml } from '@/components/helpers/string-utils';

// Styled components (keep your existing styles)
const EditorContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%;

  &:focus-within {
    outline: none;
    border-color: #007bff;
  }

  .ProseMirror {
    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }
  }
`;

const ToolbarContainer = styled.div`
  padding: 5px;
  border-bottom: 1px solid #ccc;
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const ToolbarGroup = styled(ButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
`;

const EditorContentContainer = styled.div`
  padding: 10px;
  min-height: 200px; // Set a minimum height

  .ProseMirror {
    min-height: 180px; // Slightly less than the container to account for padding
    outline: none;
  }
`;

function Toolbar({ editor }) {
  if (!editor) {
    return null;
  }

  return (
    <ToolbarContainer>
      <ToolbarGroup minimal={true}>
        <Button
          icon='bold'
          active={editor.isActive('bold')}
          onClick={() => editor.chain().focus().toggleBold().run()}
        />
        <Button
          icon='italic'
          active={editor.isActive('italic')}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        />
      </ToolbarGroup>
      <Divider />
      <ToolbarGroup minimal={true}>
        <Button
          icon='header-two'
          active={editor.isActive('heading', { level: 2 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        />
        <Button
          icon='header-three'
          active={editor.isActive('heading', { level: 3 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        />
      </ToolbarGroup>
      <Divider />
      <ToolbarGroup minimal={true}>
        <Button
          icon='properties'
          active={editor.isActive('bulletList')}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        />
        <Button
          icon='numbered-list'
          active={editor.isActive('orderedList')}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        />
      </ToolbarGroup>
    </ToolbarContainer>
  );
}

const CustomBlockquote = Blockquote.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'blockquote',
      {
        ...HTMLAttributes,
        style:
          'border-left: 4px solid #ccc; margin-left: 0; padding-left: 16px; color: #666;',
      },
      0,
    ];
  },
});

// TODO: Expose editor state directly
export default function Tiptap({
  content,
  onChange,
  placeholder = 'Write your announcement...',
  onEditorReady,
  contentContainerStyle,
}: {
  content: string;
  onChange: (content: string) => void;
  placeholder?: string;
  onEditorReady?: (editor: Editor) => void;
  contentContainerStyle?: any;
}) {
  // Add a ref to track if the content is being updated internally
  const isInternalUpdate = useRef(false);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const extensions = [
    StarterKit.configure({
      blockquote: false, // Disable the default blockquote
    }),
    CustomBlockquote,
    Placeholder.configure({ placeholder }),
    Link.configure({
      openOnClick: false,
    }),
    Image,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
  ];

  const debouncedOnChange = useCallback(
    debounce((html) => {
      isInternalUpdate.current = true;
      onChange(html);
      // Reset the flag after the state update has likely propagated
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
        isInternalUpdate.current = false;
        timeoutIdRef.current = null;
      }, 0);
    }, 300),
    [onChange],
  );

  const editor = useEditor({
    extensions,
    content: convertToHtml(content),
    onUpdate: ({ editor }) => {
      debouncedOnChange(editor.getHTML());
    },
  });

  useEffect(() => {
    // Only update content from props when it's not an internal update
    // and when the content actually differs from the editor content
    if (editor && !isInternalUpdate.current && content !== editor.getHTML()) {
      editor.commands.setContent(convertToHtml(content));
    }
  }, [content, editor]);

  useEffect(() => {
    if (editor && onEditorReady) {
      onEditorReady(editor);
    }
  }, [editor, onEditorReady]);

  if (!editor) {
    return null;
  }

  return (
    <EditorContainer>
      <Toolbar editor={editor} />
      <EditorContentContainer css={contentContainerStyle}>
        <EditorContent editor={editor} />
      </EditorContentContainer>
    </EditorContainer>
  );
}
