import moment from 'moment';

import { pluralize } from '@/components/helpers/string-utils';
import { Tooltip } from '@/components/helpers/ui/blueprint-overrides/Tooltip';

export const DEFAULT_DATE_TIME_FORMAT = 'MMM D, YYYY [at] h:mm A';

function getDateTimeString(time: Date | moment.Moment, formatString: string) {
  let fullDateTime = 'Invalid Date';
  let displayText = 'Invalid Date';
  try {
    if (time instanceof Date || typeof time === 'string') {
      fullDateTime = moment(time).format('MMM D, YYYY [at] h:mm A Z');
      displayText = moment(time).format(formatString);
    } else if (time instanceof moment) {
      fullDateTime = time.format('MMM D, YYYY [at] h:mm A Z');
      displayText = time.format(formatString);
    }
  } catch (error) {
    console.error('Error formatting date:', error);
  }
  return { fullDateTime, displayText };
}

export function DateTimeDisplay({
  formatString = DEFAULT_DATE_TIME_FORMAT,
  time,
  showDuration,
  duration,
}: {
  formatString?: string;
  time: Date | moment.Moment;
  showDuration?: boolean;
  duration?: number;
}) {
  const { fullDateTime, displayText } = getDateTimeString(time, formatString);

  const text =
    showDuration && duration
      ? `${displayText} ・ ${pluralize(Math.round(duration), 'min', 'mins')}`
      : displayText;

  return <Tooltip content={fullDateTime}>{text}</Tooltip>;
}
