import {
  Configure,
  InstantSearch,
  InstantSearchServerState,
  InstantSearchSSRProvider,
} from 'react-instantsearch';

import { getSearchClient } from '@/components/helpers/api/algolia-utils';
import { Box } from '@/components/layout/flexbox';
import { ARTICLE_SERIES_INDEX } from '@/components/pieces/algolia/indexes';
import { ArticleWithReadStatus } from '@/components/pieces/learn/ArticleCard';
import ArticleSeriesBlock from '@/components/pieces/learn/search/ArticleSeriesBlock';

interface RelatedArticleSeriesSSRProps {
  relatedArticleSeriesResultsState?: InstantSearchServerState;
  searchState?: any;
  relatedArticleSeries?: ArticleWithReadStatus[];
  excludeArticleSlug?: string;
}

export function SeoRelatedArticleSeries({
  searchState,
  relatedArticleSeries = [],
  excludeArticleSlug,
}: RelatedArticleSeriesSSRProps) {
  return (
    <InstantSearch
      searchClient={getSearchClient()}
      indexName={ARTICLE_SERIES_INDEX}
      initialUiState={searchState}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure hitsPerPage={0} />
      {relatedArticleSeries.map((articleSeries, i) => (
        <Box key={articleSeries.id} width={1}>
          {i === 0 && <Box className='bts' width={1} mb={4} />}
          <ArticleSeriesBlock
            urlSlug={articleSeries.urlSlug}
            excludeArticleSlug={excludeArticleSlug}
            articleSeriesId={articleSeries.id}
            title={articleSeries.title}
          />
        </Box>
      ))}
    </InstantSearch>
  );
}

export function RelatedArticleSeriesSSR(props: RelatedArticleSeriesSSRProps) {
  return (
    <InstantSearchSSRProvider {...props.relatedArticleSeriesResultsState}>
      <SeoRelatedArticleSeries {...props} />
    </InstantSearchSSRProvider>
  );
}
