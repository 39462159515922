import { ReactNode, useState } from 'react';

import styled from '@emotion/styled';
import { Hit } from 'instantsearch.js';

import { getSearchClient } from '@/components/helpers/api/algolia-utils';
import { Multiselect } from '@/components/helpers/ui/blueprint-overrides/Multiselect';

export interface AlgoliaMultilselectPropsType {
  indexName: string | string[];
  onChange: (selections: Hit[]) => void;

  renderSuggestion?: (suggestion: Hit, isTag?: boolean) => ReactNode;

  // Value handlers
  getSuggestionValue?: (suggestion: Hit) => string;
  onQueryChange?: (query: string) => void;
  onCreateArtificialObject?: (value: string) => any;

  // Configuration
  disabled?: boolean;
  selections?: Hit[];
  autoFocus?: boolean;
  placeholder?: string;

  // Updated to support filters for multiple indices
  filters?: string | Record<string, string>;

  // Option handling
  optionFromQuery?: (value: string, results: Hit[]) => Hit | undefined;
  optionFromQueryPosition?: 'append' | 'prepend';
  suggestionConverter?: (suggestion: Hit) => Hit;

  inputStyle?: React.CSSProperties;
}

export default function AlgoliaMultiselect({
  renderSuggestion = (hit: Hit) => String(hit.objectID),
  getSuggestionValue = (hit: Hit) => String(hit.objectID),
  onChange: onSelectedItemsChange,
  disabled,
  selections = [],
  autoFocus,
  placeholder,
  indexName,
  filters,
  onQueryChange,
  optionFromQuery,
  optionFromQueryPosition = 'append',
  suggestionConverter = (suggestion) => ({
    ...suggestion,
    id: suggestion.objectID,
  }),
  inputStyle = {},
}: AlgoliaMultilselectPropsType) {
  const [suggestions, setSuggestions] = useState<Hit[]>([]);
  const [query, setQuery] = useState('');
  const searchClient = getSearchClient();

  const _indexNames = Array.isArray(indexName) ? indexName : [indexName];

  const fetchSuggestions = async (inputValue: string) => {
    if (!inputValue.trim()) {
      setSuggestions([]);
      return;
    }

    try {
      // Handle different filter formats
      const getFilterForIndex = (indexName: string): string | undefined => {
        if (typeof filters === 'string') return filters;
        if (typeof filters === 'object')
          return indexName in filters ? filters[indexName] : undefined;
        return undefined;
      };

      // Search across all specified indices
      const searchPromises = _indexNames.map((indexName) =>
        searchClient
          .searchSingleIndex({
            indexName,
            searchParams: {
              query: inputValue.trim().toLowerCase(),
              filters: getFilterForIndex(indexName),
              hitsPerPage: 10,
              removeStopWords: true,
            },
          })
          .then((result) => {
            const hits = result?.hits as Hit[];
            return hits.map((hit) => ({
              ...hit,
              _indexName: indexName,
            }));
          }),
      );

      const hitsArrays = await Promise.all(searchPromises);
      const allHits = hitsArrays.flat();

      const customResponse = optionFromQuery?.(inputValue, allHits);

      const processedResults = customResponse
        ? optionFromQueryPosition === 'append'
          ? [...allHits, customResponse]
          : [customResponse, ...allHits]
        : allHits;

      setSuggestions(processedResults);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  };

  const handleQueryChange = (newQuery: string) => {
    setQuery(newQuery);
    if (onQueryChange) onQueryChange(newQuery);
    fetchSuggestions(newQuery);
  };

  const handleItemSelect = (item: Hit) => {
    if (
      !selections.find(
        (s) => getSuggestionValue(s) === getSuggestionValue(item),
      )
    ) {
      const updatedSelections = [...selections, suggestionConverter(item)];
      onSelectedItemsChange(updatedSelections);
      setQuery('');
      setSuggestions([]);
    }
  };

  const handleTagRemove = (_value: Hit, index: number) => {
    if (disabled) return;
    const updatedSelections = selections.filter((_, i) => i !== index);
    onSelectedItemsChange(updatedSelections);
  };

  const handleClearAll = () => {
    if (disabled) return;
    onSelectedItemsChange([]);
    setQuery('');
    setSuggestions([]);
  };

  return (
    <StyledContainer>
      <Multiselect<Hit>
        items={suggestions}
        selectedItems={selections}
        onItemSelect={handleItemSelect}
        onRemove={handleTagRemove}
        itemRenderer={(item) => renderSuggestion(item)}
        tagRenderer={(item) => renderSuggestion(item, true)}
        query={query}
        onQueryChange={handleQueryChange}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        fill={true}
        onClearAll={handleClearAll}
        inputStyle={inputStyle}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  width: 100%;
`;
