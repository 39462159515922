import { useState } from 'react';

import { Callout } from '@blueprintjs/core';

import { Box } from '@/components/layout/flexbox';
import { ScenariosPageType } from '@/components/pages_logged_in/roleplays/scenarios/const';
import ScenariosList from '@/components/pages_logged_in/roleplays/scenarios/ScenariosList';
import StyledDialog from '@/components/pieces/StyledDialog';
import { RoleplayDocument } from '@/graphql';

import { useAddAndRemoveCollectionScenarioMutation } from './api';

function AddExistingScenarioDialog({
  isOpen,
  onClose,
  roleplaySlug,
  onFinished,
  scenarios = [],
}: {
  isOpen: boolean;
  onClose: () => void;
  roleplaySlug: string;
  onFinished?: () => void;
  scenarios?: any[];
}) {
  const [err, setErr] = useState('');
  const [loadingItem, setLoadingItem] = useState({ loading: false, id: null });
  const type = ScenariosPageType.All;

  const { addCollectionScenario, removeCollectionScenario } =
    useAddAndRemoveCollectionScenarioMutation({
      onCompleted: () => {
        onFinished && onFinished();
        setLoadingItem({ ...loadingItem, loading: false });
      },
      onError: (error) => {
        setErr(error);
        setLoadingItem({ ...loadingItem, loading: false });
      },
      refetchQueries: [
        { query: RoleplayDocument, variables: { slug: roleplaySlug } },
      ],
    });

  const selectedScenarioIds = scenarios.map((s) => s.id);

  return (
    <StyledDialog
      canOutsideClickClose={true}
      isOpen={isOpen}
      onClose={onClose}
      lazy={true}
      title={<Box fontSize={18}>Add Scenarios</Box>}
      width='700px'
    >
      <Box p={24}>
        {err && (
          <Box mb={3}>
            <Callout intent='danger' icon={null}>
              {err}
            </Callout>
          </Box>
        )}
        <ScenariosList
          type={type}
          pageLoading={false}
          scopeFilter={-1}
          showShortDesc={true}
          showRank={false}
          showDifficulty={false}
          showRoleplay={false}
          iconSize={80}
          allowClickOnCard={false}
          showPlayButton={false}
          onSelect={(scenario) => {
            setLoadingItem({ loading: true, id: scenario.id });
            const isSelected = selectedScenarioIds.includes(scenario.id);
            if (isSelected) {
              removeCollectionScenario({
                roleplaySlug: roleplaySlug,
                scenarioIds: [scenario.id],
              });
            } else {
              addCollectionScenario({
                roleplaySlug: roleplaySlug,
                scenarioIds: [scenario.id],
              });
            }
          }}
          loadingItem={loadingItem}
          pageSize={5}
          selectedScenarioIds={selectedScenarioIds}
        />
      </Box>
    </StyledDialog>
  );
}

export default AddExistingScenarioDialog;
