import { useMemo } from 'react';

import { DocumentNode, useQuery } from '@apollo/client';
import uniq from 'lodash/uniq';

import { useLocalStorage } from '@/components/helpers/custom-hooks/use-local-storage';

export const INDIVIDUAL_OPTION_ID = '-1';
export const VIEW_ALL_OPTION_ID = '-2';

interface SelectorOption {
  id: string;
  name: string;
}

interface UseSelectorProps<TData> {
  storageKey?: string;
  allowIndividual?: boolean;
  labelAll?: string;
  labelIndividual?: string;
  queryDocument: DocumentNode;
  getResponseDataFn: (response: TData) => SelectorOption[];
}

export function useSelector<TData>({
  storageKey = 'DEFAULT_SELECTOR_KEY',
  allowIndividual = false,
  labelAll = 'View All',
  labelIndividual = 'Individual',
  queryDocument,
  getResponseDataFn,
}: UseSelectorProps<TData>): [
  SelectorOption[],
  string[],
  boolean,
  (id: string) => void,
] {
  const [selectedOptionIds, setSelectedOptionIds] = useLocalStorage(
    storageKey,
    [VIEW_ALL_OPTION_ID],
  );
  const { data, loading } = useQuery(queryDocument, {
    onCompleted: (response = {}) => {
      const responseOptions = getResponseDataFn(response) || [];
      const optionIds: string[] = responseOptions.map(({ id }) => id);
      setSelectedOptionIds((ids = []) => {
        let newIds = [];
        const viewAllActive = ids.find((item) => item === VIEW_ALL_OPTION_ID);

        if (viewAllActive) {
          return [VIEW_ALL_OPTION_ID];
        }

        const allowedIds =
          allowIndividual && ids.find((item) => item === INDIVIDUAL_OPTION_ID)
            ? optionIds.concat([INDIVIDUAL_OPTION_ID])
            : optionIds;
        // correct last remember Ids

        if (ids.length) {
          newIds = ids.filter((id) => allowedIds.includes(id));
        }
        return uniq(newIds);
      });
    },
  });

  const [activeOptions, activeIds, activeSelectedIds] = useMemo(() => {
    const basedOptions = data ? getResponseDataFn(data) : [];
    const allOption = { id: VIEW_ALL_OPTION_ID, name: labelAll };
    const individualOption = {
      id: INDIVIDUAL_OPTION_ID,
      name: labelIndividual,
    };
    const extraOptions = allowIndividual
      ? [allOption, individualOption]
      : [allOption];

    const activeOptions = extraOptions.concat(basedOptions);
    const activeIds = activeOptions.map((item) => item.id);
    const activeSelectedIds = selectedOptionIds.includes(VIEW_ALL_OPTION_ID)
      ? activeIds
      : selectedOptionIds;

    return [activeOptions, activeIds, activeSelectedIds];
  }, [data, selectedOptionIds]);

  const toggleOption = (id) => {
    setSelectedOptionIds((ids) => {
      const isAlreadySelectedAll = ids.includes(VIEW_ALL_OPTION_ID);
      if (id === VIEW_ALL_OPTION_ID) {
        return isAlreadySelectedAll ? [] : [VIEW_ALL_OPTION_ID];
      }

      if (isAlreadySelectedAll) {
        // just unchecked an options.
        return activeIds.filter((x) => x !== id && x !== VIEW_ALL_OPTION_ID);
      }

      const unchecked = ids.includes(id);

      if (unchecked) {
        return ids.filter((x) => x !== id);
      }

      if (activeOptions.length - ids.length === 2) {
        return [VIEW_ALL_OPTION_ID];
      }

      return ids.concat([id]);
    });
  };

  return [activeOptions, activeSelectedIds, loading, toggleOption];
}
