import { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { Box } from '@/components/layout/flexbox';
import MenuItem from '@/components/pieces/menu/MenuItem';
import {
  BillingSessionDocument,
  NewSubscriptionDocument,
  SubscriptionDocument,
} from '@/graphql';

import MembershipSvg from './MembershipSvg';

export default function SubscriptionSetting() {
  const { data } = useQuery(SubscriptionDocument);
  const [redirecting, setRedirecting] = useState(false);
  const [createBillingSession] = useMutation(BillingSessionDocument, {
    onCompleted: (response) => {
      window.location.href = response.billingSession.url;
    },
  });
  const [newSubscription] = useMutation(NewSubscriptionDocument, {
    variables: {
      successUrl: window.location.href,
      cancelUrl: window.location.href,
    },
    onCompleted: (response) => {
      window.location.href = response.newSubscription.url;
    },
  });

  if (!data || !data.currentUser) return null;
  const { subscription } = data.currentUser;
  if (!subscription || subscription === 'canceled') {
    return null;
  }

  function handleSwitchPlans() {
    setRedirecting(true);
    createBillingSession({
      variables: { returnUrl: window.location.href },
    });
  }

  function handleStartSubscription() {
    setRedirecting(true);
    newSubscription();
  }

  const menuItem = subscription.stripeSubscriptionId ? (
    <MenuItem
      icon={<MembershipSvg />}
      label='Manage Subscription'
      loading={redirecting}
      onClick={handleSwitchPlans}
    />
  ) : (
    <MenuItem
      icon={<MembershipSvg />}
      label='Start Subscription'
      loading={redirecting}
      onClick={handleStartSubscription}
    />
  );
  return (
    <Box my={4}>
      <Box px={3} pb={1} className='small-caps' fontWeight={500}>
        Subscription
      </Box>
      <Box>{menuItem}</Box>
    </Box>
  );
}
