import { useState } from 'react';

import { Button, IconName, Menu, MenuItem } from '@blueprintjs/core';

import { Popover } from '@/components/helpers/ui/blueprint-overrides/Popover';
import { useSaveAttendingStatus } from '@/components/pieces/coaching/meetings/MeetingAttendees/api';
import {
  AttendingStatus,
  FullMeetingQuery,
  MeetingAttendeesDocument,
} from '@/graphql';

import { AttendeeAttendingStatus } from './AttendingStatusIcon';

interface AttendingDropdownButtonProps {
  attendingStatus?: AttendingStatus;
  onChange?: (status: AttendingStatus) => void;
  loading?: boolean;
}

export function AttendingDropdownButton({
  attendingStatus,
  onChange,
  loading,
}: AttendingDropdownButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const activeStatus = attendingStatus || AttendingStatus.MayBe;
  const activeIcon = AttendeeAttendingStatus[activeStatus];

  const menuContent = (
    <Menu>
      {[
        AttendingStatus.Attending,
        AttendingStatus.NotAttending,
        AttendingStatus.MayBe,
      ]
        .map((status) => AttendeeAttendingStatus[status])
        .map((iconMap) => (
          <MenuItem
            active={attendingStatus === iconMap.status}
            icon={iconMap.icon as IconName}
            intent={iconMap.intent}
            key={iconMap.status}
            text={iconMap.text}
            onClick={() => {
              onChange(iconMap.status);
              setIsOpen(false);
            }}
          />
        ))}
    </Menu>
  );

  return (
    <Popover
      content={menuContent}
      placement='bottom'
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Button
        outlined={true}
        icon={activeIcon.icon as IconName}
        intent={activeIcon.intent}
        text={activeIcon.text}
        loading={loading}
        onClick={() => setIsOpen(!isOpen)}
      />
    </Popover>
  );
}

interface AttendingDropdownButtonContainerProps {
  attendee?: FullMeetingQuery['meeting']['attendee'];
  refechQueries?: any[];
  onSaved?: (data) => void;
  onError?: (error) => void;
}

function AttendingDropdownButtonContainer({
  attendee,
  refechQueries,
  onSaved,
  onError,
}: AttendingDropdownButtonContainerProps) {
  const [saveAttendingStatus, loading] = useSaveAttendingStatus({
    onCompleted: onSaved,
    onError:
      onError ||
      ((error) => {
        alert(error);
      }),
    refetchQueries: refechQueries || [
      {
        query: MeetingAttendeesDocument,
        variables: { meetingId: attendee.meetingId, start: 0, limit: 4 },
      },
    ],
  });

  const updateAttendingStatus = (status: AttendingStatus) => {
    if (typeof saveAttendingStatus === 'function') {
      return saveAttendingStatus(attendee?.id, status);
    }
  };
  return (
    <AttendingDropdownButton
      onChange={(status) => {
        updateAttendingStatus(status);
      }}
      attendingStatus={attendee?.attendingStatus}
      loading={loading}
    />
  );
}

export default AttendingDropdownButtonContainer;
