import { useState } from 'react';

import { Icon } from '@blueprintjs/core';
import uniqBy from 'lodash/uniqBy';

import { Box } from '@/components/layout/flexbox';
import { useValidateInviteEmailsLazyQuery } from '@/components/pages_logged_in/workspace/api';
import EmailMultiselect from '@/components/pieces/form/EmailMultiselect';
import { SUBTEXT_COLOR } from '@/css/constants';

interface ShareFormProps {
  onChange: (memberEmails: string[]) => void;
}

export default function ShareForm({ onChange }: ShareFormProps) {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [existedEmails, setExistedEmails] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [errMsg, setErrMsg] = useState('');

  const { fetch: validateEmails } = useValidateInviteEmailsLazyQuery({
    onCompleted: (data) => {
      if (data) {
        setExistedEmails(data?.validateInviteEmails?.existedEmails || []);
        setInvalidEmails(data?.validateInviteEmails?.invalidEmails || []);
        setErrMsg('');
      }
    },
    onError: () => {
      setErrMsg("Couldn't validate emails");
    },
  });

  const onValidateEmails = (value) => {
    const inputEmails = [
      ...new Set(value.map((x) => x?.email.trim() || '').filter((x) => x)),
    ].join(',');
    validateEmails(inputEmails, null);
  };

  const handleSubmit = (emails) => {
    const uniqueUsers = uniqBy(emails, 'email');
    setSelectedEmails(uniqueUsers);
    onValidateEmails(uniqueUsers);
    onChange(uniqueUsers.map((x: any) => x?.email));
  };

  const hasSelectedEmails = selectedEmails?.length > 0;

  return (
    <Box css={{ position: 'relative' }}>
      <Icon
        icon='search'
        color={SUBTEXT_COLOR}
        style={{
          position: 'absolute',
          left: 10,
          top: 15,
          zIndex: 99,
          display: hasSelectedEmails ? 'none' : 'block',
        }}
      />
      <EmailMultiselect
        selections={selectedEmails}
        onChange={handleSubmit}
        existedEmails={existedEmails}
        invalidEmails={invalidEmails}
        placeholder='Add people'
        canBeInvitedEmails={[]}
        pendingInvEmails={[]}
        inputStyle={{ fontSize: 16, paddingLeft: hasSelectedEmails ? 0 : 30 }}
      />
      {errMsg && (
        <Box color='red' fontSize={12} mt={2}>
          {errMsg}
        </Box>
      )}
    </Box>
  );
}
