import { Button, Classes } from '@blueprintjs/core';
import styled from '@emotion/styled';

import { breakpoints } from '@/components/layout/Media';
import { BP_NS, INNER_BORDER_COLOR, LIGHT_PURPLE } from '@/css/constants';

const StyledButton = styled(Button)`
  &.${Classes.BUTTON}:not([class*='${BP_NS}-intent-']) {
    transition: all 50ms ease;
    border-radius: 6px;
    font-weight: 600;
    padding: 10px 14px;
    border: 1px solid ${INNER_BORDER_COLOR};
    box-shadow: none;
    background-image: none;
    &:hover {
      box-shadow: none;
      border: 1px solid ${LIGHT_PURPLE};
    }
  }
  :focus {
    outline: none;
    box-shadow: none;
    outline-offset: 0;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    &.${Classes.BUTTON}:not([class*='${BP_NS}-intent-']) {
      padding: 6px 10px;
      font-size: 15px;
    }
  }
`;

function PrimaryButton({ children, ...rest }) {
  return <StyledButton {...rest}>{children}</StyledButton>;
}

export default PrimaryButton;
