import { useState } from 'react';

import { Button, Callout, Intent } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';

import { Box } from '@/components/layout/flexbox';
import { useRemoveCollectionMutation } from '@/components/pages_logged_in/roleplays/api';
import StyledDialog from '@/components/pieces/StyledDialog';

export default function RoleplayDeleteConfirm({
  open,
  onClose,
  roleplay,
  onFinished,
  shouldNavigate = true,
}) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const { removeCollection, loading } = useRemoveCollectionMutation({
    onCompleted: () => {
      onClose();
      onFinished();
      if (shouldNavigate) {
        navigate('/roleplays', { state: { refetch: true } });
      }
    },
    onError: () => {
      setErrorMessage(
        'An error occurred while removing the collection. Please try again later.',
      );
    },
  });

  return (
    <StyledDialog
      isOpen={open}
      centered={true}
      lazy={true}
      title={<Box fontSize={18}>Remove Collection</Box>}
      width='540px'
      onClose={onClose}
      onOpening={() => {
        setErrorMessage(null);
      }}
    >
      <Box p={24}>
        {errorMessage && (
          <Box mb={3}>
            <Callout icon={null} intent={Intent.DANGER}>
              {errorMessage}
            </Callout>
          </Box>
        )}
        <Box>{`Are you sure you want to remove "${roleplay?.name}"`}</Box>
        <Box mt={3} mb={'5px'}>
          <Button
            intent={Intent.DANGER}
            large={true}
            disabled={loading}
            onClick={() => {
              removeCollection(roleplay.id);
            }}
            loading={loading}
          >
            Yes, Remove
          </Button>
          <Button
            large={true}
            disabled={loading}
            onClick={onClose}
            style={{ marginLeft: 12 }}
          >
            Nevermind
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
}
