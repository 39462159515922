import { KeyboardEvent as ReactKeyboardEvent } from 'react';

import capitalize from 'lodash/capitalize';

import AlgoliaMultiselect from '@/components/pieces/algolia/AlgoliaMultiselect';
import { PopularIndustriesQuery } from '@/graphql';

import type { Hit } from 'instantsearch.js';

type Industry = PopularIndustriesQuery['popularIndustries'][0];

interface IndustryMultiselectProps {
  // Required props based on usage
  onChange: (selections: Industry[]) => void;
  selections: Industry[];

  // Optional props based on usage
  disabled?: boolean;
  large?: boolean;
  autoFocus?: boolean;
  filters?: string;
  onKeyPress?: (event: ReactKeyboardEvent<HTMLElement>) => void;
}

export default function IndustryMultiselect({
  selections = [],
  filters: propFilters,
  onChange,
  ...props
}: IndustryMultiselectProps) {
  const filters = selections.map((s) => `NOT objectID:${s.id}`);
  if (propFilters) {
    filters.push(`(${propFilters})`);
  }

  const algoliaSelections = selections.map((s) => ({
    ...s,
    objectID: s.id,
    __position: 0,
  })) as Hit[];

  return (
    <AlgoliaMultiselect
      indexName='industry_index'
      filters={filters.join(' AND ')}
      renderSuggestion={(suggestion) => (
        <div>{suggestion.name.replace(/\w+/g, capitalize)}</div>
      )}
      getSuggestionValue={(suggestion) =>
        suggestion.name.replace(/\w+/g, capitalize)
      }
      placeholder='ie. Finance'
      selections={algoliaSelections}
      onChange={(hits: Hit[]) =>
        onChange(hits.map((hit) => ({ id: hit.objectID, name: hit.name })))
      }
      {...props}
    />
  );
}
