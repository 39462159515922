import { defaultDataIdFromObject } from '@apollo/client/cache';

export const sharedCache: Record<string, any> = {};
export const cacheOption = {
  dataIdFromObject,
};

function dataIdFromObject(object) {
  const dataId = defaultDataIdFromObject(object);
  if (object.__typename === 'Article' && object.public) {
    sharedCache[dataId] = object;
  }
  return dataId;
}
