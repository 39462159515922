import { Colors, Icon } from '@blueprintjs/core';

import { Box } from '@/components/layout/flexbox';
import { SideNavContent } from '@/components/pages_logged_in/workspace/nav/SideNav';
import SideMenuItem from '@/components/pages_logged_in/workspace/nav/SideNav/SideMenuItem';
import Clickable from '@/components/pieces/interaction/Clickable';
import MenuItem from '@/components/pieces/menu/MenuItem';
import { logout } from '@/components/session/JwtTokenManager';

export default function MobileMenu({
  user,
  onClose,
  onMenuChange,
  pageLoading,
}) {
  return (
    <Box style={{ color: Colors.GRAY1 }}>
      <SideNavContent pageLoading={pageLoading} />
      <Clickable onClick={() => onMenuChange('settings')}>
        <Box>
          <SideMenuItem icon='person' label={user && user.name} />
        </Box>
      </Clickable>
      <Box width={1} mt={4}>
        <MenuItem
          icon='log-out'
          label='Sign Out'
          onClick={() => {
            onClose();
            logout({});
          }}
        />
      </Box>
      <Box style={{ position: 'absolute', top: 0, right: 0 }}>
        <Clickable onClick={onClose}>
          <Box p={3}>
            <Icon icon='cross' size={24} color={Colors.DARK_GRAY3} />
          </Box>
        </Clickable>
      </Box>
    </Box>
  );
}
