import { useMutation } from '@apollo/client';

import {
  AddMeetingAttendeesDocument,
  AttendingStatus,
  RemoveMeetingAttendeesDocument,
  SaveAttendingStatusDocument,
} from '@/graphql';

export const useAddAttendees = () => {
  const [addAttendees, { loading }] = useMutation(AddMeetingAttendeesDocument);
  return {
    addAttendees,
    loading,
  };
};

export const useRemoveAttendees = () => {
  const [removeAttendees, { loading }] = useMutation(
    RemoveMeetingAttendeesDocument,
  );
  return {
    removeAttendees,
    loading,
  };
};

interface SaveAttendingStatusParamsType {
  onCompleted?: (data) => void;
  onError?: (error) => void;
  refetchQueries?: any[];
}

export function useSaveAttendingStatus({
  onCompleted,
  onError,
  refetchQueries = null,
}: SaveAttendingStatusParamsType): [
  (
    attendeeId: string | number,
    attendingStatus: AttendingStatus,
  ) => Promise<any>,
  boolean,
] {
  const [saveAttendingStatus, { loading }] = useMutation(
    SaveAttendingStatusDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        const ok = data?.saveAttendingStatus?.ok;
        if (ok && onCompleted) {
          onCompleted(data);
        } else if (!ok && onError) {
          onError(
            data?.saveAttendingStatus?.msg || 'Error saving attending status',
          );
        }
      },
      onError: (error) => {
        onError && onError(error);
      },
    },
  );

  const handleSaveAttendingStatus = (
    attendeeId: string | number,
    attendingStatus: AttendingStatus,
  ) => {
    return saveAttendingStatus({
      variables: {
        attendeeId,
        attendingStatus,
      },
    });
  };

  return [handleSaveAttendingStatus, loading];
}
