export const get = <T>(key: string, defaultValue?: T) => {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? (JSON.parse(item) as T) : defaultValue;
  } catch (error) {
    console.error(error);
    return defaultValue;
  }
};

export const getString = (key: string): string => {
  return window.localStorage.getItem(key);
};

export const set = <T>(key: string, value: T) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const setString = (key: string, value: string) => {
  window.localStorage.setItem(key, value);
};

interface WithExpiryTime<T> {
  value: T;
  expiryTime: number;
}

export const getWithExpiryTime = <T>(key: string, defaultValue?: T) => {
  try {
    const item = window.localStorage.getItem(key);
    const parsedItem = JSON.parse(item) as WithExpiryTime<T>;
    const { value, expiryTime } = parsedItem;
    if (new Date().getTime() > expiryTime) {
      removeItem(key);
      return defaultValue;
    }
    return value;
  } catch (error) {
    console.error(error);
    return defaultValue;
  }
};

export const setWithExpiryTime = <T>(
  key: string,
  value: T,
  expiryTime: number,
) => {
  const item: WithExpiryTime<T> = {
    value,
    expiryTime,
  };
  window.localStorage.setItem(key, JSON.stringify(item));
};

export const clear = () => {
  window.localStorage.clear();
};

export const removeItem = (key: string) => {
  window.localStorage.removeItem(key);
};
