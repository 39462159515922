import { Classes, Colors, Dialog, Icon } from '@blueprintjs/core';
import styled from '@emotion/styled';

import LazyImg from '@/components/helpers/ui/LazyImg';
import { RichContentRenderer } from '@/components/helpers/ui/RichContentRenderer';
import { Box, Flex } from '@/components/layout/flexbox';
import Clickable from '@/components/pieces/interaction/Clickable';
import { HEADING_FONT, MIDTEXT_COLOR, SUBTEXT_COLOR } from '@/css/constants';
import { StandardCredentialType } from '@/graphql';

interface StandardCredentialsDialogProps {
  visible: boolean;
  standardCredential: StandardCredentialType;
  onClose: (boolean) => any;
}

function StandardCredentialsDialog({
  visible,
  onClose,
  standardCredential,
}: StandardCredentialsDialogProps) {
  const { name, description, requirements, badgeUrl, issuer } =
    standardCredential || { name: '' };
  return (
    <StyledDialog
      isOpen={visible}
      className={IS_EXEC ? 'exec-page' : ''}
      onClose={() => onClose(false)}
      lazy={true}
      style={{ width: 600 }}
    >
      <Flex py={16} px={20}>
        <Box>
          {badgeUrl && (
            <LazyImg
              src={badgeUrl}
              width={80}
              height={80}
              style={{ width: '80px', height: '80px', marginRight: '8px' }}
            />
          )}
        </Box>
        <Box css={{ flex: 1 }}>
          <Box
            fontSize={24}
            fontWeight={600}
            fontFamily={HEADING_FONT}
            lineHeight={'24px'}
          >
            {name}
          </Box>
          <Box mt={2} color={SUBTEXT_COLOR}>
            Issued by {issuer}
          </Box>
        </Box>
        <Clickable
          onClick={() => {
            onClose(false);
          }}
        >
          <Icon color={MIDTEXT_COLOR} icon='cross' size={24}></Icon>
        </Clickable>
      </Flex>
      <Box className='bbs'></Box>
      <Box pt={2} pb={16} px={20} fontSize={16}>
        {description && (
          <>
            <h4>Certification information</h4>
            <Box>{description}</Box>
          </>
        )}
        {requirements && (
          <>
            <Box className='bbs' my={3}></Box>
            <h4>Certification requirements</h4>
            <RichContentRenderer content={requirements} />
          </>
        )}
      </Box>
    </StyledDialog>
  );
}

export default StandardCredentialsDialog;

const StyledDialog = styled(Dialog)`
  &.${Classes.DIALOG} {
    max-width: 90%;
    padding-bottom: 0;
    .${Classes.DIALOG_HEADER} {
      padding: 16px 24px;
      background-color: ${Colors.WHITE};
      .${Classes.HEADING} {
        font-size: 24px;
      }
    }
  }
`;
