import { Classes } from '@blueprintjs/core';
import styled from '@emotion/styled';

import { Box } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import RoleplayDetails from '@/components/pages_logged_in/roleplays/RoleplayDetails';
import { VisibilityScope } from '@/graphql';

import { RoleplayViewMode } from './types';

const SEGMENT_TITLES = {
  [VisibilityScope.User]: 'My Roleplays',
  [VisibilityScope.Workspace]: 'Workspace Roleplays',
  [VisibilityScope.Global]: 'Core Roleplays',
};

function RoleplaySegment({
  roleplays,
  title,
  onChoose,
  showNumScenarios,
  showProgress,
  showActionBar,
  viewMode = RoleplayViewMode.Column,
  refetchRoleplays,
}) {
  return (
    <Box my={24}>
      {title && (
        <Box fontSize={18} fontWeight={500} my={3}>
          {title}
        </Box>
      )}
      <List
        className={
          viewMode === RoleplayViewMode.List
            ? 'roleplay-list-view'
            : 'roleplay-column-view'
        }
      >
        {roleplays.map((roleplay) => (
          <RoleplayDetails
            key={roleplay.id}
            roleplay={roleplay}
            onChoose={onChoose}
            showNumScenarios={showNumScenarios}
            showProgress={showProgress}
            showActionBar={showActionBar}
            viewMode={viewMode}
            refetchRoleplays={refetchRoleplays}
          />
        ))}
      </List>
    </Box>
  );
}

export default function RoleplayList({
  roleplays = null,
  loading = false,
  onChoose = null,
  showNumScenarios = true,
  showProgress = true,
  showActionBar = false,
  viewMode = RoleplayViewMode.Column,
  visibleTitle = true,
  refetchRoleplays = null,
}) {
  if (loading) {
    return (
      <List
        className={
          viewMode === RoleplayViewMode.List
            ? 'roleplay-list-view'
            : 'roleplay-column-view'
        }
      >
        <Box height={210} className={Classes.SKELETON} />
        <Box height={210} className={Classes.SKELETON} />
        <Box height={210} className={Classes.SKELETON} />
        <Box height={210} className={Classes.SKELETON} />
      </List>
    );
  }

  const segmentedRoleplays = Object.keys(VisibilityScope).reduce(
    (acc, scope) => {
      const filteredRoleplays = roleplays.filter(
        (s) => s.visibilityScope === scope,
      );
      if (filteredRoleplays.length > 0) {
        acc[scope] = filteredRoleplays;
      }
      return acc;
    },
    {},
  );

  const showTitles = Object.keys(segmentedRoleplays).length > 1;

  return (
    <Box>
      {Object.keys(SEGMENT_TITLES).map((scope) => {
        if (segmentedRoleplays[scope]) {
          return (
            <Box mt={viewMode === RoleplayViewMode.List ? 0 : 4} key={scope}>
              <RoleplaySegment
                roleplays={segmentedRoleplays[scope]}
                title={showTitles && visibleTitle ? SEGMENT_TITLES[scope] : ''}
                onChoose={onChoose}
                showNumScenarios={showNumScenarios}
                showProgress={showProgress}
                showActionBar={showActionBar}
                viewMode={viewMode}
                refetchRoleplays={refetchRoleplays}
              />
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
}

export const List = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 0;

  &.roleplay-list-view {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: ${breakpoints.ml - 1}px) {
    grid-template-columns: 1fr;
  }
`;
