import { getUser } from '@/components/session/JwtTokenManager';
import { FullMeetingQuery } from '@/graphql';

export function isPendingBookingRequest(meeting) {
  return !meeting?.eventAt && meeting.isBookingRequest && !meeting?.canceledAt;
}

export const couldManageAttendees = (
  meeting: FullMeetingQuery['meeting'],
): boolean => {
  const user = getUser();
  const organizer = meeting?.customer;
  const isOrganizer = user?.id === organizer?.id;
  if (
    isOrganizer ||
    user?.isSuperuser ||
    user?.isWorkspaceAgent ||
    user?.assistOnUserIds?.map((id) => `${id}`).includes(`${organizer?.id}`)
  ) {
    return true;
  }
  return false;
};
