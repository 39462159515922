import { Button, Classes, Colors } from '@blueprintjs/core';
import styled from '@emotion/styled';
import noop from 'lodash/noop';
import { Link } from 'react-router-dom';

import { Flex } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import Filter from '@/components/pages_logged_in/workspace/pieces/Filter';
import Clickable from '@/components/pieces/interaction/Clickable';
import {
  STANDARD_NAV_HEIGHT,
  SUBTEXT_COLOR,
  TAB_BORDER_COLOR,
} from '@/css/constants';

export const TAB_HEIGHT = STANDARD_NAV_HEIGHT;

const TextLink = styled('a')`
  color: inherit;
  :hover {
    color: inherit;
    text-decoration: inherit;
    cursor: inherit;
  }
  :focus {
    color: inherit;
    text-decoration: inherit;
  }
`;

function SwitchedTab({ tabs, tab, tabRendererProps, onTabChange = noop }) {
  const TabRenderer = tab.TabRenderer || DefaultTabRenderer;
  const isSelectedFn = (id) => id === tab.key;
  const tabByKey = tabs.reduce((res, aTab) => {
    res[aTab.key] = aTab;
    return res;
  }, {});
  const onSelect = (key) => onTabChange(tabByKey[key]);
  return (
    <Flex
      width={1}
      px={3}
      height={TAB_HEIGHT}
      alignItems='center'
      justifyContent='space-between'
    >
      <TabRenderer tab={tab} active={true} {...tabRendererProps} />
      <StyledFilter
        items={tabs.map((t) => {
          const Text = t.TabRenderer || DefaultTabRenderer;

          return {
            id: t.key,
            text: (
              <Text
                tab={t}
                active={isSelectedFn(t.key)}
                {...tabRendererProps}
                forMenu={true}
              />
            ),
          };
        })}
        button={
          <Button style={{ boxShadow: 'none' }} rightIcon='menu' small={true} />
        }
        isSelectedFn={isSelectedFn}
        onSelect={onSelect}
      />
    </Flex>
  );
}

function ListTab({
  tabs,
  tabRendererProps,
  tab = null,
  onTabChange = noop,
  tabClassName = null,
}) {
  return (
    <>
      {tabs.map((t) => {
        const tabChangeValue = t;
        if (typeof t === 'string') {
          t = {
            key: t,
            label: t,
          };
        }

        const TabRenderer = t.TabRenderer || DefaultTabRenderer;
        const active = t.key === tab?.key;
        return (
          <Tab
            key={t.key}
            display={
              <TabRenderer tab={t} active={active} {...tabRendererProps} />
            }
            active={active}
            href={t.href}
            linkTo={t.linkTo}
            onActivate={() => onTabChange(tabChangeValue)}
            tabClassName={tabClassName}
          />
        );
      })}
    </>
  );
}

export default function Tabs({
  tabs,
  tab,
  tabRendererProps = undefined,
  onTabChange = noop,
  backgroundColor = Colors.WHITE,
  dropdownSwitch = false,
  style = {},
  className = undefined,
  tabClassName = undefined,
}) {
  if (typeof tab === 'string') {
    tab = {
      key: tab,
      label: tab,
    };
  }
  return (
    <TabContainer
      style={{
        backgroundColor,
        boxShadow: '0px 2px 4px -2px #E1DEEE',
        borderTop: `1px solid ${Colors.LIGHT_GRAY4}`,
        ...style,
      }}
      alignItems='center'
      className={className}
    >
      {dropdownSwitch ? (
        <SwitchedTab
          tab={tab}
          tabs={tabs}
          tabRendererProps={tabRendererProps}
          onTabChange={onTabChange}
        />
      ) : (
        <ListTab
          tab={tab}
          tabs={tabs}
          tabRendererProps={tabRendererProps}
          onTabChange={onTabChange}
          tabClassName={tabClassName}
        />
      )}
    </TabContainer>
  );
}

function Tab({ display, active, onActivate, href, linkTo, tabClassName }) {
  const content = (
    <TabTitle
      className={tabClassName}
      css={{
        transition: 'all 0.5s ease',
        borderBottom: '3px solid',
        borderColor: active ? TAB_BORDER_COLOR : 'transparent',
        color: active ? TAB_BORDER_COLOR : SUBTEXT_COLOR,
      }}
      mx={3}
      height={TAB_HEIGHT}
      alignItems='center'
    >
      {display}
    </TabTitle>
  );

  if (linkTo) {
    return (
      <StyledLink to={linkTo} style={{ color: 'inherit' }}>
        {content}
      </StyledLink>
    );
  } else if (href) {
    return <TextLink href={href}>{content}</TextLink>;
  }

  return <Clickable onClick={active ? null : onActivate}>{content}</Clickable>;
}

function DefaultTabRenderer({ tab, active }) {
  if (active) {
    return <b>{tab.label}</b>;
  } else {
    return tab.label;
  }
}

const StyledFilter = styled(Filter)`
  & .${Classes.MENU} {
    min-width: 130px;
  }
`;

const TabContainer = styled(Flex)`
  &.how-it-work-container {
    overflow-x: inherit;
    @media screen and (max-width: ${breakpoints.sm}px) {
      overflow-x: auto;
    }
  }
`;

const TabTitle = styled(Flex)`
  &.how-it-work-tabs {
    flex: 1;
    justify-content: center;
    min-width: auto;
    @media screen and (max-width: ${breakpoints.sm}px) {
      min-width: 190px;
    }
  }
`;

const StyledLink = styled(Link)`
  :hover {
    text-decoration: inherit;
  }
  :focus {
    text-decoration: inherit;
  }
`;
