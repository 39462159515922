import { useState } from 'react';

import { get, set } from '@/components/helpers/storage/local-storage-utils';

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      return get(key, initialValue);
    } catch {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    if (typeof window === 'undefined') {
      return;
    }

    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      setTimeout(() => {
        set(key, valueToStore);
      });
    } catch {
      // A more advanced implementation would handle the error case
    }
  };

  return [storedValue, setValue];
}
