import { useState } from 'react';

import { Icon, Portal, Spinner } from '@blueprintjs/core';
import styled from '@emotion/styled';
import VideoPlayer from 'react-player/file';
import { useMediaQuery } from 'react-responsive';

import { Box } from '@/components/layout/flexbox';
import { isMobileQuery } from '@/components/layout/Media';
import BackDrop from '@/components/pieces/BackDrop';
import Clickable from '@/components/pieces/interaction/Clickable';
import ScrollableModal from '@/components/pieces/ScrollableModal';
import { PORTAL_Z_INDEX } from '@/css/constants';

interface VideoWithPopoutProps {
  url: string;
  playing?: boolean;
  onClick?: () => void;
  borderRadius?: number;
  thumbnail?: string;
  title?: string;
}

function VideoWithPopout({
  url,
  playing,
  onClick,
  borderRadius = 0,
  thumbnail,
  title = null,
}: VideoWithPopoutProps) {
  const [localPlaying, setLocalPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isPlaying =
    playing === undefined && onClick === undefined ? localPlaying : playing;
  const isMobile = useMediaQuery(isMobileQuery);
  const togglePlaying =
    onClick === undefined ? () => setLocalPlaying(!localPlaying) : onClick;

  let config = undefined;
  let thumbnailUrl = null;
  if (thumbnail) {
    thumbnailUrl =
      typeof thumbnail === 'string' && isMobile
        ? thumbnail.replace('upload/w_750', 'upload/w_300')
        : thumbnail;
    config = {
      file: { attributes: { poster: thumbnailUrl } },
    };
  }

  const video = (
    <VideoPlayer
      url={url.startsWith('http') ? url : `${API_HOST}${url}`}
      playing={isPlaying}
      width='100%'
      height='100%'
      loop={false}
      pip={false}
      config={config}
      controls={isPlaying}
      onReady={() => setIsLoading(false)}
      onError={() => setIsLoading(false)}
      onBuffer={() => setIsLoading(true)}
      onBufferEnd={() => setIsLoading(false)}
      onEnded={() => togglePlaying()}
    />
  );

  return (
    <>
      <VideoContainer borderRadius={borderRadius}>
        {isPlaying ? (
          thumbnailUrl ? (
            <img src={thumbnailUrl} height={182} />
          ) : (
            <Box height={182} backgroundColor='#000' />
          )
        ) : (
          video
        )}
        <Clickable onClick={togglePlaying}>
          {isPlaying && isLoading ? (
            <TransparentOverlay>
              <OverlayIcon>
                <Spinner size={72} />
              </OverlayIcon>
            </TransparentOverlay>
          ) : (
            <Overlay playing={isPlaying} borderRadius={borderRadius}>
              <OverlayIcon>
                <Icon
                  icon={isPlaying ? 'pause' : 'play'}
                  size={72}
                  color='#FFF'
                />
              </OverlayIcon>
            </Overlay>
          )}
        </Clickable>
      </VideoContainer>
      <Portal>
        <BackDrop visible={isPlaying} zIndex={PORTAL_Z_INDEX} opacity={0.6} />
        <ScrollableModal
          visible={isPlaying}
          onClose={() => togglePlaying()}
          verticallyCenter={true}
          canOutsideClickClose={true}
        >
          <Box
            backgroundColor='#FFF'
            className='round-corners'
            width={650}
            maxWidth='100%'
            p={24}
          >
            {title && (
              <Box fontWeight={600} fontSize={18} mb={3}>
                {title}
              </Box>
            )}
            {video}
          </Box>
        </ScrollableModal>
      </Portal>
    </>
  );
}

const VideoContainer = styled('div')<{ borderRadius: number }>`
  position: relative;
  width: 100%;
  height: 100%;
  line-height: 0;
  video {
    border-bottom-right-radius: ${(props) => props.borderRadius}px;
    border-bottom-left-radius: ${(props) => props.borderRadius}px;
    overflow: hidden;
  }
`;

const Overlay = styled('div')<{ playing: boolean; borderRadius: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: pointer;
  opacity: ${(props) => (props.playing ? 0 : 0.5)};
  border-bottom-right-radius: ${(props) => props.borderRadius}px;
  border-bottom-left-radius: ${(props) => props.borderRadius}px;
  overflow: hidden;

  &:hover {
    opacity: 1;
  }
`;

const OverlayIcon = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 72px;
  height: 72px;
`;

const TransparentOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default VideoWithPopout;
