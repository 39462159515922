import { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Callout, Classes, Intent } from '@blueprintjs/core';
import moment from 'moment-timezone';
import ReactMarkdown from 'react-markdown';

import { Tooltip } from '@/components/helpers/ui/blueprint-overrides/Tooltip';
import { Box, Flex } from '@/components/layout/flexbox';
import Clickable from '@/components/pieces/interaction/Clickable';
import { MIDTEXT_COLOR, SUBTEXT_COLOR } from '@/css/constants';
import {
  DeleteGptResponseDocument,
  GptResponseDocument,
  RetryGptResponseDocument,
} from '@/graphql';

import RateResponse from './RateResponse';

export default function GPTResponse({
  entry,
  gptResponse,
  onLoadFinished,
  hideForm,
  open,
  toggleOpen,
  removeGptResponse,
  autoScrollUuid,
  afterAutoScroll,
}) {
  const [showingDeleteConfirm, setShowingDeleteConfirm] = useState(false);
  const { data, startPolling, stopPolling } = useQuery(GptResponseDocument, {
    variables: { gptResponseUuid: gptResponse.uuid },
  });
  const [deleteGptResponse, { loading: deletePending }] = useMutation(
    DeleteGptResponseDocument,
    {
      variables: {
        gptResponseUuid: gptResponse.uuid,
      },
      onCompleted: () => {
        setShowingDeleteConfirm(false);
        removeGptResponse(gptResponse);
      },
    },
  );
  const [retryGptResponse, { loading: retryPending }] = useMutation(
    RetryGptResponseDocument,
    {
      variables: {
        gptResponseUuid: gptResponse.uuid,
      },
      onCompleted: () => {
        startPolling(1000);
        hideForm();
      },
    },
  );

  const response = (data && data.gptResponse) || gptResponse || {};
  const answers = JSON.parse(response.answers) || {};
  const date = useMemo(
    () =>
      moment(response.createdAt).tz(moment.tz.guess()).format('MMM D, h:mm A'),
    [response.createdAt],
  );

  // This is a bit obtuse. What we're doing here is making a list of
  // unique prompt entry IDs and maintaing the sort order that contentful
  // provided to us. It is possible for the answers that came from the
  // database to contain entry IDs that have been deprecated. As a result,
  // we take the logical union of the current entry IDs and the keys we received.
  // This sorts the deprecated fields to the end.
  const answerIds = [
    ...new Set([
      ...entry.fields.user_prompts.map((p) => p.id),
      ...Object.keys(answers),
    ]),
  ];

  useEffect(() => {
    startPolling(1000);
  }, []);

  const scrollToRef = useCallback(
    (node) => {
      if (node && autoScrollUuid === response.uuid) {
        afterAutoScroll();
        node.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    [autoScrollUuid],
  );

  function handleDeleteClick(e) {
    e.stopPropagation();
    setShowingDeleteConfirm(true);
  }

  useEffect(() => {
    if (
      data &&
      data.gptResponse &&
      (data.gptResponse.response || data.gptResponse.errorCode)
    ) {
      stopPolling();
      onLoadFinished();
    }
  }, [data]);

  return (
    <Box
      mt={3}
      backgroundColor='rgba(255, 255, 255, 0.8)'
      className='round-corners'
      ref={scrollToRef}
      style={{ scrollMarginTop: 70 }}
    >
      <Clickable onClick={toggleOpen}>
        <Flex
          p={3}
          className='bbs'
          alignItems='center'
          justifyContent='space-between'
          fontSize={16}
        >
          <Box color={MIDTEXT_COLOR} fontWeight={600}>
            Version: {date}
          </Box>
          <Flex mt={[1, 0]} alignItems='center'>
            <Tooltip content='Delete'>
              <Button minimal={true} icon='trash' onClick={handleDeleteClick} />
            </Tooltip>
            <Box ml={2}>
              <Button
                minimal={true}
                icon={open ? 'chevron-up' : 'chevron-down'}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleOpen();
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Clickable>
      {open ? (
        <Box p={3}>
          {answerIds.map((id, i) =>
            answers[id] ? (
              <Box key={id} mt={i > 0 ? 24 : 0}>
                <Box fontWeight='bold' className='prompt-title'>
                  {answers[id].prompt}
                </Box>
                <Box
                  mt={1}
                  color={MIDTEXT_COLOR}
                  className='prompt-help-text'
                  style={{ whiteSpace: 'break-spaces' }}
                >
                  <Answer answer={answers[id].answer} />
                </Box>
              </Box>
            ) : null,
          )}
          <Box mt={3} pt={3} className='bts'>
            <Box fontWeight='bold' className='prompt-title'>
              Answer From Our AI
            </Box>
            <Box color={SUBTEXT_COLOR} className='prompt-help-text'>
              This is new technology, please use your judgment!
            </Box>
          </Box>
          <Box
            className='bas round-corners'
            backgroundColor='#FFF'
            p={3}
            mt={2}
          >
            {response.response ? (
              <div style={{ whiteSpace: 'break-spaces' }}>
                <ReactMarkdown disallowedElements={['a']}>
                  {response.response}
                </ReactMarkdown>
              </div>
            ) : response.errorCode ? (
              <Callout
                intent={Intent.WARNING}
                title='Our AI is currently overwhelmed'
              >
                <Box>
                  Please wait a little while and try hit the button below to try
                  again. This will not use another Bit.
                </Box>
                <Box mt={3}>
                  <Button
                    icon='repeat'
                    onClick={retryGptResponse}
                    loading={retryPending}
                    disabled={retryPending}
                  >
                    Retry
                  </Button>
                </Box>
              </Callout>
            ) : (
              <Box>
                <Box className={Classes.SKELETON} height={22} width='85%' />
                <Box
                  className={Classes.SKELETON}
                  mt={2}
                  height={22}
                  width='97%'
                />
                <Box
                  className={Classes.SKELETON}
                  mt={2}
                  height={22}
                  width='47%'
                />
                <Box mt={1} color={SUBTEXT_COLOR} fontSize={14}>
                  Thinking... this may take a minute
                </Box>
              </Box>
            )}
          </Box>
          {response.response ? (
            <Box mt={3}>
              <RateResponse
                gptResponseUuid={gptResponse.uuid}
                defaultRating={gptResponse.rating}
              />
            </Box>
          ) : null}
        </Box>
      ) : null}
      <Alert
        cancelButtonText='Cancel'
        confirmButtonText={'Delete'}
        loading={deletePending}
        icon='trash'
        intent={Intent.DANGER}
        isOpen={showingDeleteConfirm}
        onCancel={() => {
          setShowingDeleteConfirm(false);
        }}
        onConfirm={() => deleteGptResponse()}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
      >
        <p>Are you sure you want to delete this?</p>
      </Alert>
    </Box>
  );
}

function Answer({ answer }) {
  const [showAll, setShowAll] = useState(false);

  return !showAll && answer.length > 280 ? (
    <Box>
      {answer.substring(0, 280)}...{' '}
      <a
        onClick={(e) => {
          e.stopPropagation();
          setShowAll(true);
        }}
      >
        Show All
      </a>
    </Box>
  ) : (
    <Box>{answer}</Box>
  );
}
