import { Box, Flex } from '@/components/layout/flexbox';
import { PORTAL_Z_INDEX } from '@/css/constants';

const animationSpeed = '0.2s';

export default function ScrollableModal({
  visible,
  children,
  verticallyCenter = undefined,
  onClose,
  canOutsideClickClose = true,
  style = undefined,
}) {
  return (
    <Flex
      justifyContent='center'
      alignItems={verticallyCenter ? 'center' : undefined}
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        zIndex: PORTAL_Z_INDEX,
        visibility: visible ? 'visible' : 'hidden',
        transform: visible ? 'translateY(0%)' : 'translateY(120%)',
        transition: visible
          ? `transform ${animationSpeed}, z-index ${animationSpeed}`
          : `transform ${animationSpeed}, z-index ${animationSpeed}, visibility 0s ${animationSpeed}`,
      }}
      onClick={canOutsideClickClose ? onClose : null}
    >
      <Box onClick={(e) => e.stopPropagation()} style={style} py={3}>
        {children}
      </Box>
    </Flex>
  );
}
