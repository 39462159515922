import { Box } from '@/components/layout/flexbox';
import { Media } from '@/components/layout/Media';
import { CARD_CLASSES } from '@/css/constants';

export default function CardAboveXs({ children, ...boxProps }) {
  return (
    <>
      <Media at='xs'>{children}</Media>
      <Media greaterThan='xs'>
        <Box
          py={30}
          px={24}
          backgroundColor='#FFF'
          className={CARD_CLASSES}
          {...boxProps}
        >
          {children}
        </Box>
      </Media>
    </>
  );
}
