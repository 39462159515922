import React from 'react';

import { Callout, Classes, Icon } from '@blueprintjs/core';
import styled from '@emotion/styled';

import { RichContentRenderer } from '@/components/helpers/ui/RichContentRenderer';
import { Box, Flex } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import CreateCollectionDialog from '@/components/pages_logged_in/roleplays/CreateCollectionDialog';
import EvaluationCriteriaManager from '@/components/pages_logged_in/roleplays/details/evaluation-criteria/EvaluationCriteriaManager';
import RoleplayTabs from '@/components/pages_logged_in/roleplays/details/RoleplayTabs';
import RoleplayScenarios from '@/components/pages_logged_in/roleplays/RoleplayScenarios';
import { Container } from '@/components/pieces/bootstrap';
import CardAboveXs from '@/components/pieces/CardAboveXs';
import IconCircle from '@/components/pieces/IconCircle';
import {
  CTA_COLOR,
  LIGHTEST_BACKGROUND,
  LIGHT_BLUE,
  MIDTEXT_COLOR,
  SUBTEXT_COLOR,
} from '@/css/constants';
import { VisibilityScope } from '@/graphql';

import { RoleplayPageType } from './roleplay-types';
import RoleplayHeader from './RoleplayHeader';

const CONTAINER_MAX_WIDTH = 1000;

function GetStartedCard({
  roleplay,
  onCreateCollectionFinished,
  canEdit = false,
}) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const isWorkspaceScope =
    roleplay.visibilityScope === VisibilityScope.Workspace;
  const hasScenariosForWorkspace =
    roleplay.scenarios?.filter(
      (scenario) =>
        scenario.visibilityScope === VisibilityScope.Workspace ||
        scenario.visibilityScope === VisibilityScope.Global,
    ).length > 0;
  const visibleCallout = isWorkspaceScope && !hasScenariosForWorkspace;

  const introduction = roleplay.introduction;

  return (
    <Box>
      {visibleCallout && (
        <Box mb={3}>
          <Callout intent='warning' icon='warning-sign'>
            This collection remains private to you until you include scenarios
            (workspace scenarios, core scenarios) and evaluation criteria.
          </Callout>
        </Box>
      )}
      <CreateCollectionDialog
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        roleplay={roleplay}
        onFinished={onCreateCollectionFinished}
      />
      <Flex fontWeight={600} fontSize={20} mb={3} alignItems='center'>
        <IconCircle
          icon='bookmark'
          backgroundColor={LIGHT_BLUE}
          color={CTA_COLOR}
          size={32}
          mr={2}
        />
        <div>Overview</div>
      </Flex>
      <CardAboveXs>
        <Box
          mb={roleplay.introduction ? 32 : 0}
          className={Classes.RUNNING_TEXT}
        >
          {roleplay.introduction || !canEdit ? (
            <RichContentRenderer content={introduction} />
          ) : (
            <Box
              p={24}
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              style={{
                border: '1px dashed #E2E2E2',
                borderRadius: '6px',
              }}
            >
              <Box
                color='#394B59'
                style={{ maxWidth: '400px', textAlign: 'center' }}
              >
                Add an overview to help others understand what they'll achieve
                in this collection
              </Box>
              <StyledLinkButton
                onClick={() => setIsDialogOpen(true)}
                alignItems='center'
                mt={'10px'}
                style={{ gap: 6 }}
              >
                <Icon icon='plus' tagName='a' />
                <a>Add overview</a>
              </StyledLinkButton>
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <Box fontWeight={500}>What You'll Practice:</Box>
          {roleplay.evaluationCriteria?.length ? (
            <SkillsGrid>
              {roleplay.evaluationCriteria.map((criteria, i) => (
                <li key={i}>
                  <Icon icon='tick' color={SUBTEXT_COLOR} /> {criteria.name}
                </li>
              ))}
            </SkillsGrid>
          ) : (
            <Box mt={16} color={'#778397'} fontSize={14}>
              Skills and competencies will appear here once you add your first
              evaluation criteria
            </Box>
          )}
        </Box>
      </CardAboveXs>
      <Box mt={48}>
        <Flex fontWeight={600} fontSize={20} mb={3} alignItems='center'>
          <IconCircle
            icon='lightning'
            backgroundColor={LIGHT_BLUE}
            color={CTA_COLOR}
            size={32}
            mr={2}
          />
          <div>Scenario</div>
        </Flex>
        <RoleplayScenarios
          scenarios={roleplay.scenarios}
          roleplay={roleplay}
          canEdit={canEdit}
        />
      </Box>
    </Box>
  );
}

export default function RoleplayContent({
  roleplay = null,
  loading = false,
  view = 'details',
  onCreateCollectionFinished = null,
}) {
  const tabComponent =
    roleplay &&
    (view === 'details' ? (
      <GetStartedCard
        roleplay={roleplay}
        onCreateCollectionFinished={onCreateCollectionFinished}
        canEdit={roleplay.canEdit}
      />
    ) : (
      <EvaluationCriteriaManager
        roleplayId={roleplay?.id}
        canEdit={roleplay?.canEdit}
        isDefault
        showBanner={false}
      />
    ));

  return (
    <>
      <RoleplayHeader
        page={RoleplayPageType.RoleplayDetail}
        loading={loading}
        roleplay={roleplay}
        maxWidth={CONTAINER_MAX_WIDTH}
      />

      <RoleplayTabs
        roleplay={roleplay}
        scenario={null}
        roleplaySession={null}
        maxWidth={CONTAINER_MAX_WIDTH}
        page={RoleplayPageType.RoleplayDetail}
        view={view}
      />

      <Box mt={[24, 36]} mb={5}>
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          {tabComponent || (
            <>
              <Box className={Classes.SKELETON} height={350} />
              <Box className={Classes.SKELETON} height={350} mt={24} />
            </>
          )}
        </Container>
      </Box>
    </>
  );
}

const SkillsGrid = styled('ul')`
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 16px;

  @media (max-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
  }

  li {
    background-color: ${LIGHTEST_BACKGROUND};
    padding: 10px 16px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    align-items: center;
    display: flex;
    color: ${MIDTEXT_COLOR};

    span {
      margin-right: 8px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      background-color: #fff;
    }
  }
`;

const StyledLinkButton = styled(Flex)`
  font-size: 14px;
  font-weight: 600;
  color: #3788ee;
`;
