import { Box } from '@/components/layout/flexbox';
import Uploader from '@/components/pieces/uploader';

import MenuHeader from './MenuHeader';

export default function MenuAvatar({ onClose, onBackLink, onComplete }) {
  return (
    <Box width={1}>
      <MenuHeader onBackLink={onBackLink} onClose={onClose} label='Photo' />

      <Uploader
        endpoint={`${API_HOST}/avatars`}
        fieldName='photo'
        onComplete={onComplete}
      />
    </Box>
  );
}
