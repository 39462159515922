import { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Callout, FormGroup, InputGroup } from '@blueprintjs/core';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getSignUpParams } from '@/components/helpers/signup/sign-up-params';
import { Box } from '@/components/layout/flexbox';
import Checkbox from '@/components/pieces/form/Checkbox';
import handleSignUpComplete, {
  sendErrorToSentry,
} from '@/components/pieces/signup/handle-sign-up-complete';
import { SignUpDocument } from '@/graphql';
import { GetCSRFQuery } from '@/graphql/cachedVarsQueries';

interface SignUpFormProps {
  onEmailChange?: (email: string) => void;
  nextPath?: string;
  onCompleted?: () => void;
  asWorkspaceAdmin?: boolean;
  fields?: string[];
  cta?: string;
}

export default function SignUpForm({
  onEmailChange,
  nextPath,
  onCompleted,
  asWorkspaceAdmin = false,
  fields = ['email', 'password'],
  cta = 'Create Account',
}: SignUpFormProps) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [giftCode, setGiftCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [tosChecked, setTosChecked] = useState(false);
  const [error, setError] = useState(null);

  const handleError = (error) => {
    setLoading(false);
    if (error.message === 'invalid email') {
      setError('Please enter a valid email address.');
    } else if (error.message === 'this email already exists') {
      setError('This email already exists, try logging in.');
    } else if (error.message.includes('gift code')) {
      setError(error.message);
    } else if (error.message.includes('Workspace already has members')) {
      setError(
        'This Workspace already has users in it. You are not allow to signup to this workspace',
      );
    } else {
      setError(
        'Something went very wrong. We are looking into this ASAP. Please try again later.',
      );
      sendErrorToSentry(error, email);
    }
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleComplete = (response) => {
    handleSignUpComplete({
      response,
      handleError,
      nextPath,
      onCompleted,
      asWorkspaceAdmin,
      navigate,
      searchParams,
    });
  };

  const { data: csrfData } = useQuery(GetCSRFQuery);

  const [submit] = useMutation(SignUpDocument, {
    variables: getSignUpParams(
      email,
      name,
      password,
      giftCode,
      asWorkspaceAdmin,
    ),
    onCompleted: handleComplete,
    onError: handleError,
  });

  const canSubmit =
    (!!name || !fields.includes('name')) &&
    (!!email || !fields.includes('email')) &&
    (!!password || !fields.includes('password')) &&
    (!!giftCode || !fields.includes('giftCode')) &&
    tosChecked;

  function handleSubmit() {
    submit();
    setLoading(true);
  }

  return (
    <>
      <input
        hidden={true}
        name='X-CSRF-TOKEN'
        readOnly={true}
        value={csrfData && csrfData.csrf}
      />
      {fields.includes('name') ? (
        <FormGroup label='Name'>
          <InputGroup
            placeholder='Your full name'
            large={true}
            value={name}
            name='name'
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={loading}
            leftIcon='person'
            autoFocus={true}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
        </FormGroup>
      ) : null}
      <FormGroup label='Email'>
        <InputGroup
          placeholder='you@gmail.com'
          large={true}
          value={email}
          name='email'
          type='email'
          onChange={(e) => {
            setError(null);
            const { value } = e.target;
            setEmail(value);
            if (onEmailChange) {
              onEmailChange(value);
            }
          }}
          disabled={loading}
          leftIcon='envelope'
          autoFocus={fields[0] === 'email'}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
      </FormGroup>

      {fields.includes('password') ? (
        <FormGroup label='Password'>
          <InputGroup
            placeholder='Enter your password...'
            large={true}
            value={password}
            type='password'
            name='password'
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            disabled={loading}
            leftIcon='key'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
        </FormGroup>
      ) : null}

      {fields.includes('giftCode') ? (
        <FormGroup label='Gift Code'>
          <InputGroup
            placeholder='Enter gift code'
            large={true}
            value={giftCode}
            name='giftCode'
            onChange={(e) => {
              setError(null);
              const { value } = e.target;
              setGiftCode(value);
            }}
            disabled={loading}
            leftIcon='key'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
        </FormGroup>
      ) : null}

      <Box mb={24}>
        <Checkbox
          checked={tosChecked}
          onChange={() => setTosChecked(!tosChecked)}
          hasBorders={true}
          disabled={loading}
          style={{ fontSize: 16, whiteSpace: 'wrap' }}
          label={
            <>
              I agree to the{' '}
              <a target='_BLANK' href='/tos'>
                Terms of Service
              </a>{' '}
              and{' '}
              <a target='_BLANK' href='/privacy-policy'>
                Privacy Policy
              </a>
            </>
          }
        />
      </Box>

      {error ? (
        <Box>
          <Callout intent='warning' icon='error'>
            {error}
          </Callout>
        </Box>
      ) : null}

      <Box mt={30}>
        <Button
          onClick={handleSubmit}
          intent='primary'
          text={cta}
          large={true}
          loading={loading}
          disabled={!canSubmit}
          fill={true}
        />
      </Box>
    </>
  );
}
