import { useEffect, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Colors, FormGroup, Icon, InputGroup } from '@blueprintjs/core';

import { Box } from '@/components/layout/flexbox';
import Subtext from '@/components/pieces/form/Subtext';
import { getUser } from '@/components/session/JwtTokenManager';
import { CTA_COLOR, RED } from '@/css/constants';
import { ResumesDocument, SaveResumeDocument } from '@/graphql';

import { downloadResume } from './download-resume';

export default function Resume({ resume, onUpload, onDelete, onSaved }) {
  const user = getUser();
  const [name, setName] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const inputRef = useRef(null);

  const [saveResume, { loading }] = useMutation(SaveResumeDocument, {
    variables: {
      id: resume && resume.id.toString(),
      name,
    },
    refetchQueries: [{ query: ResumesDocument }],
    awaitRefetchQueries: true,
    onCompleted: onSaved,
  });

  useEffect(() => {
    if (!resume) {
      return;
    }

    setName(resume && resume.name);
    setTimeout(() => inputRef.current.focus(), 500);
  }, [resume]);

  useEffect(() => {
    if (downloading) {
      setTimeout(() => setDownloading(false), 1000);
    }
  }, [downloading]);

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      saveResume();
    }
  }

  function download() {
    setDownloading(true);
    downloadResume(resume);
  }

  return (
    <>
      <Box my={4} style={{ backgroundColor: Colors.WHITE }} px={3} py={24}>
        <FormGroup label={<b>Resume Label</b>}>
          <InputGroup
            large={true}
            inputRef={inputRef}
            value={name || ''}
            name='name'
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={loading}
            onKeyDown={handleKeyPress}
            autoFocus={true}
          />
        </FormGroup>
        <Subtext mt={-2}>
          This label is only visible to you. Files downloaded or shared with
          companies will be named “{user.name} Resume - [Date].pdf”.
        </Subtext>

        <Box mt={24}>
          <Button
            intent='primary'
            large={true}
            fill={true}
            disabled={resume && resume.name === name}
            loading={loading}
            onClick={saveResume}
          >
            Update
          </Button>
        </Box>
      </Box>

      <Box m={3}>
        <ActionButton
          icon={<Icon icon='download' color={CTA_COLOR} size={20} />}
          onClick={download}
          loading={downloading}
        >
          Download
        </ActionButton>
      </Box>

      <Box m={3}>
        <ActionButton
          icon={<Icon icon='cloud-upload' color={CTA_COLOR} size={20} />}
          onClick={onUpload}
          disabled={downloading}
        >
          Replace file
        </ActionButton>
      </Box>

      <Box m={3}>
        <ActionButton
          icon={<Icon icon='trash' color={RED} size={20} />}
          onClick={onDelete}
          disabled={downloading}
        >
          Delete resume
        </ActionButton>
      </Box>
    </>
  );
}

function ActionButton(props) {
  return (
    <Button
      style={{
        borderRadius: 8,
        height: 48,
        paddingLeft: 16,
      }}
      fill={true}
      large={true}
      alignText='left'
      {...props}
    />
  );
}
