import {
  ProgramComponentForStatisticQueryQuery,
  ProgramComponentQueryQuery,
  ProgramComponentsQueryQuery,
} from '@/graphql';

export type ParticipantComponentType =
  ProgramComponentsQueryQuery['programComponents']['participantsComponents'][0];
export type LeadComponentType =
  ProgramComponentsQueryQuery['programComponents']['leadsComponents'][0];
type MainProgramComponentType = ProgramComponentQueryQuery['programComponent'];
type ProgramComponentForStatisticType =
  ProgramComponentForStatisticQueryQuery['programComponentForStatistic'];
type PrerequisiteComponentType =
  ProgramComponentQueryQuery['programComponent']['prerequisiteComponent'];

export type ProgramComponentType =
  | ParticipantComponentType
  | LeadComponentType
  | MainProgramComponentType
  | ProgramComponentForStatisticType;

export const isParticipantComponent = (
  component?: ProgramComponentType | PrerequisiteComponentType,
): component is ParticipantComponentType =>
  component && 'participantSubmitAt' in component;

export const isLeadComponent = (
  component?: ProgramComponentType,
): component is LeadComponentType =>
  component &&
  'totalParticipants' in component &&
  'isSetupComplete' in component;

export interface DesignProgramPanelData {
  icon: string;
  title: string;
  description: string;
}
