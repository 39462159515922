import { Colors } from '@blueprintjs/core';

import { Box } from '@/components/layout/flexbox';
import Clickable from '@/components/pieces/interaction/Clickable';

export default function BackDrop({
  visible,
  onClick,
  zIndex = 1300,
  opacity = 0.5,
}: {
  visible: boolean;
  onClick?: () => void;
  zIndex?: number;
  opacity?: number;
}) {
  return (
    <Clickable onClick={onClick}>
      <Box
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex,
          backgroundColor: Colors.BLACK,
          opacity: visible ? opacity : 0,
          pointerEvents: visible ? 'all' : 'none',
          transition: 'all 0.5 ease-in-out',
        }}
      />
    </Clickable>
  );
}
