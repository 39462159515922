import { ReactNode, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  Button,
  Callout,
  Classes,
  Colors,
  Drawer,
  Icon,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import styled from '@emotion/styled';

import { useWorkspaceSlug } from '@/components/helpers/custom-hooks/use-workspace-slug';
import { Box, Flex } from '@/components/layout/flexbox';
import {
  useSwitchPersonalityModeMutation,
  useUpdateScenarioCharacterMutation,
} from '@/components/pages_logged_in/roleplays/api';
import PersonalityTraitSelector from '@/components/pages_logged_in/roleplays/details/scenario/ScenarioEditCharacter/PersonalityTraitsSelector';
import BulletPointInput from '@/components/pieces/form/BulletPointInput';
import Clickable from '@/components/pieces/interaction/Clickable';
import UploadImageController, {
  ButtonPosition,
  ImageFrameType,
} from '@/components/pieces/uploader/UploadImageController';
import { BRAND_PURPLE, SUBTEXT_COLOR } from '@/css/constants';
import {
  PersonalityAttributesQuery,
  ScenarioDocument,
  ScenarioType,
} from '@/graphql';

import VoiceSelector from './VoiceSelector';

interface SectionProps {
  title: string;
  icon?: ReactNode;
  children?: ReactNode;
}

function Section({ title, icon, children = null }: SectionProps) {
  return (
    <Box mb={36}>
      <Flex fontSize={18} fontWeight={600} alignItems='center'>
        {icon}
        <Box ml={2}>{title}</Box>
      </Flex>
      {children}
    </Box>
  );
}

function ScenarioEditCharacterDialog({
  open,
  onClose,
  scenario,
}: {
  open: boolean;
  onClose: () => void;
  scenario: ScenarioType;
}) {
  const workspaceSlug = useWorkspaceSlug();
  const [personaName, setPersonaName] = useState('');
  const [isTraitSelector, setIsTraitSelector] = useState(true);
  const { personaPhotoUrl = '', slug = '' } = scenario || {};
  const [personalityCharacteristics, setPersonalityCharacteristics] = useState<
    string[]
  >([]);
  const [personalityTraits, setPersonalityTraits] = useState<
    PersonalityAttributesQuery['personalityAttributes']
  >({} as PersonalityAttributesQuery['personalityAttributes']);
  const [voiceId, setVoiceId] = useState<string | null>(null);
  const [error, setError] = useState(null);
  const [shouldParseScenario, setShouldParseScenario] = useState(true);
  const [shouldParsePersonality, setShouldParsePersonality] = useState(true);

  const { refetch: refetchScenario } = useQuery(ScenarioDocument, {
    variables: { scenarioSlug: slug },
    skip: !slug,
  });

  const { updateScenarioCharacter, loading: updating } =
    useUpdateScenarioCharacterMutation({
      onCompleted: () => {
        onClose();
        refetchScenario();
      },
      onError: (err) => {
        setError(err.message);
      },
      refetchQueries: [
        {
          query: ScenarioDocument,
          variables: { scenarioSlug: slug },
        },
      ],
    });

  const { switchPersonalityMode, loading: switchingPersonalityMode } =
    useSwitchPersonalityModeMutation({
      onCompleted: () => {
        refetchScenario();
        setShouldParsePersonality(true);
      },
      onError: (err) => {
        setError(err.message);
      },
      refetchQueries: [
        {
          query: ScenarioDocument,
          variables: { scenarioSlug: slug },
        },
      ],
    });

  useEffect(() => {
    if (scenario) {
      const {
        personaName,
        personalityCharacteristics,
        voiceId,
        personalityTraits,
      } = scenario;
      const parsePersonality = shouldParsePersonality || shouldParseScenario;

      if (shouldParseScenario) {
        setPersonaName(personaName);
        setVoiceId(voiceId);
        setError(null);
        setShouldParseScenario(false);
      }

      if (!parsePersonality) {
        return;
      }

      if (personalityCharacteristics) {
        const personalityCharacteristicLines = personalityCharacteristics
          .split('\n')
          .map((line) => line.replace(/^[-*]\s+/, '').trim());
        setIsTraitSelector(false);
        setPersonalityCharacteristics(personalityCharacteristicLines);
        setShouldParsePersonality(false);
      } else if (personalityTraits && personalityTraits?.length > 0) {
        setIsTraitSelector(true);
        setPersonalityTraits(
          personalityTraits
            .filter(
              (trait): trait is NonNullable<typeof trait> =>
                trait !== null && trait !== undefined,
            )
            .reduce(
              (personalityTraitAccumulator, trait) => ({
                ...personalityTraitAccumulator,
                [trait.attributeId]: trait.levelId,
              }),
              {} as PersonalityAttributesQuery['personalityAttributes'],
            ),
        );
      }
      setShouldParsePersonality(false);
    }
  }, [scenario, open, shouldParseScenario]);

  const validateInput = ({
    personaName,
    voiceId,
    isTraitSelector,
    personalityCharacteristics,
    personalityTraits,
  }: {
    personaName: string;
    voiceId: string | null;
    isTraitSelector: boolean;
    personalityCharacteristics: string[];
    personalityTraits: PersonalityAttributesQuery['personalityAttributes'];
  }) => {
    if (!personaName) {
      setError('Character name is required');
      return false;
    }
    if (!voiceId) {
      setError('Voice is required');
      return false;
    }

    if (!isTraitSelector && !personalityCharacteristics?.[0]) {
      setError('Personality characteristics are required');
      return false;
    }

    if (isTraitSelector && Object.keys(personalityTraits).length === 0) {
      setError('Personality traits are required');
      return false;
    }
    return true;
  };

  const onSave = () => {
    const trimmedPersonalityCharacteristics = personalityCharacteristics.filter(
      (char) => char.trim() !== '',
    );

    const validationInput = {
      personaName,
      voiceId,
      isTraitSelector,
      personalityCharacteristics: trimmedPersonalityCharacteristics,
      personalityTraits,
    };

    if (!validateInput(validationInput)) {
      return;
    }
    setError(null);

    const updatedPersonalityTraits = isTraitSelector
      ? Object.entries(personalityTraits).map(([key, value]) => ({
          attributeId: key,
          levelId: value,
        }))
      : null;
    const updatedPersonalityCharacteristics = !isTraitSelector
      ? `- ${trimmedPersonalityCharacteristics.join('\n- ')}`
      : null;

    updateScenarioCharacter({
      variables: {
        slug,
        personaName,
        personalityCharacteristics: updatedPersonalityCharacteristics,
        personalityTraits: updatedPersonalityTraits,
        voiceId,
      },
    });
  };

  const disableSave = useMemo(() => {
    setError(null);
    return !validateInput({
      personaName,
      voiceId,
      isTraitSelector,
      personalityCharacteristics,
      personalityTraits,
    });
  }, [
    personaName,
    voiceId,
    isTraitSelector,
    personalityCharacteristics,
    personalityTraits,
  ]);

  const handlePhotoUploadComplete = async () => {
    await refetchScenario();
  };

  const loading = updating || switchingPersonalityMode;

  return (
    <Drawer
      isOpen={open}
      lazy={true}
      onClose={() => {
        onClose();
        setShouldParseScenario(true);
        setShouldParsePersonality(true);
      }}
      style={{
        backgroundColor: '#F6F6FB',
        overflow: 'auto',
        maxWidth: '600px',
        width: '100%',
        height: '100%',
      }}
    >
      <Box pb={98}>
        <Flex justifyContent='flex-end'>
          <Flex
            width={78}
            height={62}
            justifyContent='center'
            alignItems='center'
          >
            <Button
              minimal={true}
              icon={<Icon icon='cross' size={36} color={SUBTEXT_COLOR} />}
              onClick={onClose}
            />
          </Flex>
        </Flex>
        <Box px={24}>
          <Section
            title='Character'
            icon={<Icon icon='person' size={20} color={BRAND_PURPLE} />}
          >
            <Box
              backgroundColor={Colors.WHITE}
              p={3}
              className='bas round-corners'
              mt={12}
            >
              <Flex mb={24}>
                <UploadImageController
                  logoSrc={personaPhotoUrl}
                  endpoint={`${API_HOST}/scenario_character_photos/${slug}?workspace_slug=${workspaceSlug}`}
                  fieldName='photo'
                  imagesSize={150}
                  buttonLabel='Change Photo'
                  onComplete={handlePhotoUploadComplete}
                  imageFrameType={ImageFrameType.Circle}
                  buttonPosition={ButtonPosition.Top}
                />
              </Flex>
              <Box mb={24}>
                <Box fontWeight={600} mb={12}>
                  Name
                </Box>
                <InputGroup
                  onChange={(e) => {
                    setPersonaName(e.target.value);
                  }}
                  value={personaName}
                  large={true}
                  fill={true}
                  placeholder='Program Name'
                  autoFocus={true}
                  disabled={loading}
                />
              </Box>
              <Box>
                <Box fontWeight={600} mb={12}>
                  Voice
                </Box>
                <VoiceSelector voiceId={voiceId} onChangeVoiceId={setVoiceId} />
              </Box>
            </Box>
          </Section>

          <Section
            title='Personality Configuration'
            icon={<Icon icon='cog' size={20} color={BRAND_PURPLE} />}
          >
            {isTraitSelector ? (
              <PersonalityTraitSelector
                personalityTraits={personalityTraits}
                onChangePersonalityTraits={(traits) =>
                  setPersonalityTraits(traits)
                }
              />
            ) : (
              <Box my={16}>
                <Box my={16}>
                  This character is using a custom personality description. You
                  can edit it directly or{' '}
                  <Clickable onClick={() => switchPersonalityMode(slug)}>
                    <span style={{ color: BRAND_PURPLE }}>
                      switch to using structured traits
                    </span>
                  </Clickable>
                  .
                </Box>
                <BulletPointInput
                  values={personalityCharacteristics}
                  onChange={setPersonalityCharacteristics}
                />
              </Box>
            )}

            {error ? (
              <Box mt={4}>
                <Callout intent='danger' icon='error'>
                  {error}
                </Callout>
              </Box>
            ) : null}
          </Section>
        </Box>

        <Flex
          mt={4}
          p={24}
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
          }}
          backgroundColor={Colors.WHITE}
          className='bas'
        >
          <StyledButton
            intent={Intent.PRIMARY}
            disabled={disableSave}
            onClick={onSave}
            loading={loading}
          >
            Save
          </StyledButton>
        </Flex>
      </Box>
    </Drawer>
  );
}

interface StyledButtonProps {
  padding?: string;
  borderRadius?: string;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  &.${Classes.BUTTON} {
    font-size: 16px;
    padding: ${(props) => props?.padding || '13px 40px'};
    border-radius: ${(props) => props?.borderRadius || '4px'};
    min-width: 160px;
  }
`;

export default ScenarioEditCharacterDialog;
