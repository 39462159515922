import { Overlay2 } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import {
  getBuildVersion,
  getConflictVersion,
  getUser,
} from '@/components/session/JwtTokenManager';

export default function AppVersionGuardOverlay() {
  if (import.meta.env.SSR) {
    return null;
  }
  const versionMetaTag = document.querySelector(
    'meta[name="build-version"]',
  ) as HTMLMetaElement;
  const currentVersion = versionMetaTag && versionMetaTag.content;
  const showGuard =
    getUser() && (currentVersion !== getBuildVersion() || getConflictVersion());
  return (
    <Overlay2
      isOpen={showGuard}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      backdropClassName='pageDisabled'
    >
      <Flex alignItems='center' justifyContent='center' width={1}>
        <Flex
          mt={3}
          py={2}
          width={[320, 450]}
          alignItems='center'
          justifyContent='center'
          flexWrap='wrap'
          css={{
            background: '#FFE4A0',
            borderRadius: '6px',
            border: '1x solid gray',
          }}
        >
          <strong>We upgraded the app</strong>
          <Box mx={1}>
            Please{' '}
            <a href='javascript:window.location.reload(true)'>
              <strong>refresh page</strong>
            </a>{' '}
            to get the latest version
          </Box>
        </Flex>
      </Flex>
    </Overlay2>
  );
}
