import { useLocation, useSearchParams } from 'react-router-dom';

export function useStoreSignUpParams() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const storeParams = () => {
    const params = {
      first: location.pathname,
      ...Object.fromEntries(searchParams.entries()),
    };

    const overwrite = true;
    const noOverwrite = false;
    const storageKeys = {
      first: noOverwrite,
      refcode: noOverwrite,
      utm_source: overwrite,
      utm_medium: overwrite,
      utm_campaign: overwrite,
      utm_term: overwrite,
      utm_content: overwrite,
      gclid: overwrite,
      ws: overwrite,
      coach: overwrite,
    };

    for (const key in storageKeys) {
      const shouldWrite = storageKeys[key] || !localStorage.getItem(key);
      if (shouldWrite && params[key] !== undefined) {
        localStorage.setItem(key, params[key]);
      }
    }
  };

  return { storeParams };
}
