import { ReactNode } from 'react';

import { Helmet } from 'react-helmet-async';

interface SEOHelmetProps {
  title?: string;
  description?: string;
  canonicalDomain?: string;
  canonicalPath?: string;
  image?: string;
  bodyAttributes?: any;
  additionalHeadTags?: ReactNode;
}

const DEFAULT_TITLE = IS_EXEC
  ? 'Exec – Talent Development Platform'
  : 'Placement — Career, Leadership, and Life Coaching';

const DEFAULT_DESCRIPTION = IS_EXEC
  ? 'Exec is the platform for all your talent development programs. Launch personalized coaching, training programs, and assessments that fit any budget.'
  : 'Unlock your potential. Access top quality career, leadership, and life coaching at Placement.';

const DEFAULT_IMAGE = IS_EXEC
  ? 'https://placement-pub.s3.us-east-2.amazonaws.com/exec-home-meta.jpg'
  : 'https://placement-pub.s3.us-east-2.amazonaws.com/meta-img.png';

const DEFAULT_CANONICAL_PATH = '';
const DEFAULT_BODY_ATTRIBUTES = {};

function SEOHelmet({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  canonicalDomain,
  canonicalPath = DEFAULT_CANONICAL_PATH,
  image = DEFAULT_IMAGE,
  bodyAttributes = DEFAULT_BODY_ATTRIBUTES,
  additionalHeadTags,
}: SEOHelmetProps) {
  const canonicalLink = `${canonicalDomain || WEB_APP}${canonicalPath}`;

  return (
    <Helmet bodyAttributes={bodyAttributes}>
      <title>{title}</title>
      <meta name='description' content={description} />
      <link rel='canonical' href={canonicalLink} />

      {/* Open Graph / Facebook */}
      <meta property='og:type' content='website' />
      <meta property='og:url' content={canonicalLink} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />

      {/* Twitter */}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content={canonicalLink} />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:image' content={image} />

      {additionalHeadTags}
    </Helmet>
  );
}

export default SEOHelmet;
