import { ReactElement, ReactNode } from 'react';

import { Icon, IconName } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Box, Flex } from '@/components/layout/flexbox';
import { BRAND_PURPLE, HOVER_BACKGROUND, MIDTEXT_COLOR } from '@/css/constants';

interface SideMenuItemProps {
  icon: IconName | ReactElement;
  label: ReactNode;
  active?: boolean;
  link?: string;
}

export default function SideMenuItem({
  icon,
  label,
  active = undefined,
  link = undefined,
}: SideMenuItemProps) {
  const content = (
    <Flex
      className={`menuItem ${active ? 'active' : ''}`}
      p={12}
      alignItems='center'
      mb={2}
    >
      {typeof icon === 'string' ? <Icon icon={icon} size={16} /> : icon}
      <Box ml={12}>{label}</Box>
    </Flex>
  );

  return (
    <Container>{link ? <Link to={link}>{content}</Link> : content}</Container>
  );
}

const Container = styled(Box)`
  .menuItem {
    transition: all 200ms ease;
    color: ${MIDTEXT_COLOR};
    border-radius: 4px;

    svg {
      color: #bfbfbf;
    }
    &:hover {
      background-color: ${HOVER_BACKGROUND};
      color: ${BRAND_PURPLE};
    }
    &.active {
      background-color: ${HOVER_BACKGROUND};
      color: ${BRAND_PURPLE};
      font-weight: bold;

      svg {
        color: ${BRAND_PURPLE};
      }
    }
  }
`;
