import { useEffect, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { TextArea } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useLocalStorage } from '@/components/helpers/custom-hooks/use-local-storage';
import { unescape } from '@/components/helpers/string-utils';
import { Tooltip } from '@/components/helpers/ui/blueprint-overrides/Tooltip';
import { buildUrl } from '@/components/helpers/url-utils';
import { Box } from '@/components/layout/flexbox';
import { breakpoints } from '@/components/layout/Media';
import { getUser } from '@/components/session/JwtTokenManager';
import { LIGHT_BACKGROUND, SUBTEXT_COLOR } from '@/css/constants';
import { PromptAnswerDocument, SavePromptAnswerDocument } from '@/graphql';

export function PromptContent({
  entry,
  answerCallback = null,
  disabled = false,
  showSignUp = true,
}) {
  const user = getUser();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const answerToSaveRef = useRef(null);
  const timeoutRef = useRef(null);

  const [answer, setAnswer] = useLocalStorage(`prompt-${entry.id}`, '');
  const [saved, setSaved] = useState(false);
  const [lastSavedAnswer, setLastSavedAnswer] = useState(answer);

  const signUpLink = buildUrl('/signup', {
    data: {
      jsu: 1,
      nextPath: location.pathname,
    },
  });

  // if the user Just Signed Up we need to refresh the page
  const jsu = searchParams.get('jsu');
  if (jsu) {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('jsu');
    window.location.href = `${location.pathname}${newParams.toString() ? `?${newParams.toString()}` : ''}`;
  }

  function handleSetAnswer(answer) {
    setAnswer(answer);
    setLastSavedAnswer(answer);
  }

  useQuery(PromptAnswerDocument, {
    skip: !user,
    variables: { entryId: entry.id },
    onCompleted: (response) => {
      if (!answer && response && response.promptAnswer) {
        const answer = unescape(response.promptAnswer.answer);
        handleSetAnswer(answer);
      }
    },
  });
  const [saveAnswer] = useMutation(SavePromptAnswerDocument, {
    onCompleted: (response) => {
      setSaved(true);
      setLastSavedAnswer(response.savePromptAnswer.promptAnswer.answer);
    },
    refetchQueries: function (response) {
      if (
        response.data &&
        response.data.savePromptAnswer &&
        response.data.savePromptAnswer.created
      ) {
        return [
          {
            query: PromptAnswerDocument,
            variables: { entryId: entry.id },
          },
        ];
      }
    },
  });

  const handleSave = () => {
    saveAnswer({
      variables: {
        entryId: entry.id,
        answer: answerToSaveRef.current,
      },
    });
    answerToSaveRef.current = null;
  };

  useEffect(() => {
    answerCallback && answerCallback({ prompt: entry.fields.prompt, answer });

    clearTimeout(timeoutRef.current);
    if (answer === null || answer === lastSavedAnswer || !user) {
      return;
    }
    answerToSaveRef.current = answer;
    timeoutRef.current = setTimeout(handleSave, 2000);
  }, [answer]);

  // Save on full component unmount if there
  // is a pending timeout
  useEffect(() => {
    return () => {
      if (timeoutRef.current && answerToSaveRef.current) {
        clearTimeout(timeoutRef.current);
        handleSave();
      }
    };
  }, []);

  return (
    <>
      <Box mb={2} justifyContent='space-between' flexWrap='wrap'>
        <Box fontWeight='bold' className='prompt-title'>
          {entry.fields.prompt}
        </Box>
        {entry.fields.help_text ? (
          <Box mt={1} color={SUBTEXT_COLOR} className='prompt-help-text'>
            {entry.fields.help_text}
          </Box>
        ) : null}
      </Box>
      <TextArea
        fill={true}
        placeholder={
          entry.fields.placeholder
            ? entry.fields.placeholder
            : 'Write your answer here'
        }
        value={answer}
        onChange={(e) => handleSetAnswer(e.target.value)}
        autoResize={true}
        rows={entry.fields.long_answer ? 5 : 2}
        disabled={disabled}
      />
      {!user && answer && showSignUp ? (
        <Box mt={2} color={SUBTEXT_COLOR} fontSize={14}>
          ✨ <a href={signUpLink}>Create an account</a> to save your answers for
          later
        </Box>
      ) : null}
      {saved ? (
        <Box color={SUBTEXT_COLOR} fontSize={14}>
          <Tooltip content='Your answers are automatically saved for later'>
            Saved
          </Tooltip>
        </Box>
      ) : null}
    </>
  );
}

export default function Prompt({ entry }) {
  return (
    <PromptCard>
      <PromptContent entry={entry} />
    </PromptCard>
  );
}

const PromptCard = styled('aside')`
  position: relative;
  padding: 24px 32px;
  margin: 48px -32px;
  border-left: 3px solid;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  background-color: ${LIGHT_BACKGROUND};
  border-color: #ddd9ee;

  .prompt-help-text {
    line-height: 1.4;
  }

  & + & {
    margin-top: -30px;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    padding: 16px;
  }

  @media screen and (max-width: 1350px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
