import { BRAND_PURPLE } from '@/css/constants';

export default function PlaybookSvg({ size = 26, color = BRAND_PURPLE }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.5998 0.174805L22.6249 2.3881L25.9998 6.07645L23.9746 8.28975L20.5998 4.6014L17.2249 8.28975L15.1998 6.07645L18.5746 2.3881L20.5998 0.174805ZM8.14976 20.4248C8.14976 21.7503 7.07524 22.8248 5.74976 22.8248C4.42428 22.8248 3.34976 21.7503 3.34976 20.4248C3.34976 19.0993 4.42428 18.0248 5.74976 18.0248C7.07524 18.0248 8.14976 19.0993 8.14976 20.4248ZM11.1498 20.4248C11.1498 23.4071 8.7321 25.8248 5.74976 25.8248C2.76742 25.8248 0.34976 23.4071 0.34976 20.4248C0.34976 17.4425 2.76742 15.0248 5.74976 15.0248C8.7321 15.0248 11.1498 17.4425 11.1498 20.4248ZM22.9162 15.0682L25.0375 17.1896L22.2973 19.9298L25.1096 22.7421L22.9883 24.8635L20.1759 22.0511L17.3636 24.8635L15.2423 22.7421L18.0546 19.9298L15.3144 17.1896L17.4357 15.0682L20.1759 17.8085L22.9162 15.0682ZM9.79522 2.44612L7.6739 0.324799L4.93367 3.06503L2.19343 0.324799L0.0721141 2.44612L2.81235 5.18635L0 7.9987L2.12132 10.12L4.93367 7.30767L7.74602 10.12L9.86734 7.9987L7.05499 5.18635L9.79522 2.44612Z'
        fill={color}
      />
      <path
        d='M20.4969 3.32471C22.4984 19.8247 7.5 6.82471 6 16.8247'
        stroke={color}
        strokeWidth='3'
      />
    </svg>
  );
}
