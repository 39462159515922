import styled from '@emotion/styled';

import { Flex } from '@/components/layout/flexbox';

export const StyledRoundedBox = styled(Flex)`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 700;
`;
