import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Classes, Dialog, FormGroup, TextArea } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import DefaultButton from '@/components/pieces/button/DefaultButton';
import PrimaryButton from '@/components/pieces/button/PrimaryButton';
import { isRefundable } from '@/components/pieces/coaching/meetings/utils';
import Checkbox from '@/components/pieces/form/Checkbox';
import { SUPPORT_EMAIL } from '@/css/constants';
import {
  CancelCoachMeetingDocument,
  FullMeetingQuery,
  SetExceptionalRefundDocument,
} from '@/graphql';

export function ClientCancelRefundableMeeting() {
  return (
    <Box mb={3}>
      <strong>This meeting is refundable. </strong>Canceling this meeting will
      result in a full refund.
    </Box>
  );
}

function ClientCancelNonRefundableMeeting() {
  return (
    <>
      <Box mb={3}>
        <strong>This meeting is nonrefundable. </strong>Canceling this meeting
        will not result in a refund.
      </Box>
      <Box mb={3}>
        If your coach is canceling this meeting, please reach out to{' '}
        <a href={`mailto:${SUPPORT_EMAIL}`} target='_BLANK'>
          {SUPPORT_EMAIL}
        </a>{' '}
        for a refund.
      </Box>
    </>
  );
}

export function CoachCancelRefundableMeeting() {
  return (
    <Box mb={3}>
      <strong>This meeting is refundable. </strong>Cancelling this meeting will
      result in a full refund to the client and you will not be compensated.
    </Box>
  );
}

function CoachCancelNonRefundableMeeting({ refund, onRefundChecked, loading }) {
  return (
    <>
      <Box mb={3}>
        This meeting <b>does not qualify</b> for an automated refund because it
        is happening too soon. As the coach, you have the option to refund this
        meeting anyways.
      </Box>
      <Box mb={3}>
        You will not be compensated for this meeting if you choose to refund it.
      </Box>
      <Box mb={3}>
        <Checkbox
          checked={refund}
          onChange={onRefundChecked}
          hasBorders={true}
          disabled={loading}
          label='Refund the customer'
        />
      </Box>
    </>
  );
}

function CancelMeetingConfirmDialog({
  meeting,
  isCoach,
  isOpen,
  onCancel,
  onConfirm,
  customerId = null,
}: {
  meeting: FullMeetingQuery['meeting'];
  isCoach: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  customerId?: number;
}) {
  const [cancelReason, setCancelReason] = useState<string>('');
  const [refund, setRefund] = useState<boolean>(false);
  const [exceptionalRefund, { loading: refunding }] = useMutation(
    SetExceptionalRefundDocument,
    {
      variables: {
        meetingId: meeting.id,
      },
      onCompleted: () => {
        processCancel();
      },
    },
  );
  const [processCancel, { loading: canceling }] = useMutation(
    CancelCoachMeetingDocument,
    {
      variables: {
        meetingId: String(meeting.id),
        cancelReason,
        customerId: customerId ? String(customerId) : customerId,
      },
      onCompleted: () => {
        onConfirm();
      },
    },
  );

  const handleConfirm = () => {
    if (isCoach && refund) {
      exceptionalRefund();
    } else {
      processCancel();
    }
  };

  const loading = canceling || refunding;
  const refundable = isRefundable(meeting);

  return (
    <>
      <Dialog
        isOpen={isOpen}
        icon='trash'
        title={<Box py={2}>Confirm cancellation?</Box>}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        style={{ maxWidth: 550, width: '95%' }}
        onClose={onCancel}
      >
        <Box p={24}>
          <Box className={`${Classes.DIALOG_BODY} ${Classes.RUNNING_TEXT}`}>
            {isCoach ? (
              refundable ? (
                <CoachCancelRefundableMeeting />
              ) : (
                <CoachCancelNonRefundableMeeting
                  refund={refund}
                  onRefundChecked={setRefund}
                  loading={loading}
                />
              )
            ) : refundable ? (
              <ClientCancelRefundableMeeting />
            ) : (
              <ClientCancelNonRefundableMeeting />
            )}

            <Box mb={3}>
              <FormGroup>
                <TextArea
                  onChange={(e) => {
                    setCancelReason(e.currentTarget.value);
                  }}
                  disabled={loading}
                  value={cancelReason}
                  large={true}
                  fill={true}
                  placeholder='Reason for canceling'
                  rows={4}
                  autoResize={true}
                />
              </FormGroup>
            </Box>
          </Box>
          <Flex className={Classes.DIALOG_FOOTER}>
            <Box>
              <DefaultButton disabled={loading} onClick={onCancel}>
                Nevermind
              </DefaultButton>
            </Box>
            <Box ml={3}>
              <PrimaryButton
                intent='primary'
                disabled={loading}
                loading={loading}
                onClick={() => handleConfirm()}
              >
                {refundable
                  ? 'Cancel Session'
                  : isCoach && refund
                    ? 'Cancel and Refund'
                    : 'Cancel Without Refund'}
              </PrimaryButton>
            </Box>
          </Flex>
        </Box>
      </Dialog>
    </>
  );
}

export default CancelMeetingConfirmDialog;
