import { useEffect, useState } from 'react';

import { Button, Colors, Icon } from '@blueprintjs/core';
import moment from 'moment';

import { Box, Flex } from '@/components/layout/flexbox';
import Subtext from '@/components/pieces/form/Subtext';
import Clickable from '@/components/pieces/interaction/Clickable';
import Truncate from '@/components/pieces/Truncate';
import { CTA_COLOR } from '@/css/constants';

import { downloadResume } from './download-resume';

export default function ResumeList({ resumes, onSelect = undefined }) {
  const [downloading, setDownloading] = useState(null);

  useEffect(() => {
    if (downloading) {
      setTimeout(() => setDownloading(null), 1000);
    }
  }, [downloading]);

  function download(resume, e) {
    setDownloading(resume);
    downloadResume(resume);
    e.stopPropagation();
  }

  return (
    <Box style={{ backgroundColor: Colors.WHITE }}>
      {resumes.map((resume, index) => {
        const { id, name, updatedAt } = resume;
        return (
          <Clickable
            key={id}
            onClick={onSelect ? () => onSelect(resume) : null}
          >
            <Flex
              justifyContent='space-between'
              alignItems='center'
              p={3}
              className={index > 0 ? 'bts' : null}
            >
              <Box>
                <Truncate mb={1}>
                  <b>{name}</b>
                </Truncate>
                <Subtext>{moment(updatedAt).format('MMM D, YYYY')}</Subtext>
              </Box>
              <Box>
                <Button
                  icon={<Icon icon='download' color={CTA_COLOR} size={20} />}
                  large={true}
                  style={{ borderRadius: 8, padding: '10px 20px' }}
                  onClick={(e) => download(resume, e)}
                  loading={downloading === resume}
                />
              </Box>
            </Flex>
          </Clickable>
        );
      })}
    </Box>
  );
}
