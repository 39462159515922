export default function World({ size = 262 }) {
  const height = (214 * size) / 262;
  return (
    <svg
      width={size}
      height={height}
      viewBox='0 0 271 197'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='114' y='134' width='125' height='12' rx='6' fill='white' />
      <circle
        cx='131'
        cy='97'
        r='91.5'
        stroke='#00A468'
        strokeOpacity='0.05'
        strokeWidth='7'
      />
      <rect x='35' y='68' width='125' height='12' rx='6' fill='white' />
      <circle
        cx='131'
        cy='97'
        r='82.5'
        stroke='#00A468'
        strokeOpacity='0.2'
        strokeWidth='3'
      />
      <rect x='25' y='94' width='125' height='12' rx='6' fill='white' />
      <rect x='97' y='114' width='125' height='12' rx='6' fill='white' />
      <path
        d='M176.912 38.9934C176.577 96.2469 130.247 142.576 72.9937 142.912C90.6666 165.258 119.601 175.469 147.384 169.167C175.169 162.864 196.865 141.169 203.168 113.384C209.469 85.6008 199.258 56.6663 176.912 38.9934V38.9934Z'
        fill='#83D8F4'
      />
      <path
        d='M176.911 38.9926C147.472 15.6671 105.224 18.1066 78.6654 44.6651C52.1068 71.2237 49.6674 113.472 72.9928 142.911C130.246 142.576 176.576 96.2461 176.911 38.9926V38.9926Z'
        fill='#91DCF5'
      />
      <path
        d='M166.723 53.624C161.084 56.1756 157.972 53.1655 153.965 56.1756C149.015 59.9008 147.026 69.5464 151.414 76.5882C154.52 81.5493 160.089 82.794 166.377 84.1669C172.741 71.1113 176.304 56.8708 176.838 42.3562C174.983 47.2388 171.378 51.2556 166.723 53.624Z'
        fill='#00BB77'
      />
      <path
        d='M177.717 39.6265C177.487 40.5571 177.189 41.4691 176.827 42.3562C176.293 56.8708 172.729 71.1113 166.366 84.1669L166.723 84.2429C175.475 86.1566 178.869 82.1 187.135 84.2429C190.823 85.3007 194.283 87.0312 197.341 89.3461C200.886 92.2702 203.506 96.1611 204.882 100.545C204.938 99.3692 204.996 98.1931 204.996 97.0008C205.016 74.7418 194.993 53.6626 177.717 39.6265V39.6265Z'
        fill='#00A468'
      />
      <path
        d='M128.449 127.62C133.501 133.207 134.828 142.649 131.001 148.032C127.888 152.421 123.627 150.915 120.794 155.687C118.651 159.67 118.651 164.463 120.794 168.445C121.188 169.165 121.658 169.839 122.196 170.458C125.117 170.811 128.058 170.99 131.001 170.996C157.984 171.004 182.829 156.317 195.834 132.674C191.193 129.569 183.943 125.462 176.929 125.068C169.74 124.365 162.529 126.168 156.516 130.171C148.99 135.683 147.51 143.491 143.758 142.929C141.105 142.546 139.651 138.259 138.655 135.274C136.377 128.477 137.612 123.877 137.591 120.064C134.475 122.557 131.22 124.87 127.84 126.989C128.103 127.247 128.319 127.475 128.449 127.62Z'
        fill='#00A468'
      />
      <path
        d='M136.104 114.862C133.476 111.188 128.577 110.499 123.346 109.759C112.374 108.227 108.062 112.948 102.933 109.759C98.7531 106.975 96.7223 101.901 97.8299 97.0007C99.9741 90.5969 109.338 92.766 120.794 84.2429C124.494 81.5131 133.297 74.956 133.552 66.3818C133.807 57.987 125.617 55.5887 123.346 45.9692C122.359 41.8254 124.2 32.2956 128.867 23.0823C101.399 23.8585 76.6262 39.7971 64.5323 64.4706V64.4943C62.8952 73.5594 62.9388 82.8487 64.6594 91.8976C66.2168 102.181 67.6446 111.519 74.8657 119.965C78.0303 123.69 82.9029 129.355 90.1752 130.171C98.2386 131.065 108.878 125.91 110.588 125.068C112.874 123.567 115.513 122.687 118.243 122.516C121.89 122.749 125.317 124.345 127.84 126.989C131.22 124.87 134.475 122.558 137.591 120.065C137.677 118.214 137.155 116.387 136.104 114.862Z'
        fill='#00BB77'
      />
      <path
        d='M110.588 43.4175C110.588 54.6953 90.1753 81.6911 90.1753 81.6911C90.1753 81.6911 77.4436 64.8505 72.085 52.2209C70.7656 49.4576 69.9782 46.4712 69.7627 43.4175C69.7627 32.1447 78.9025 23.0049 90.1753 23.0049C101.449 23.0049 110.588 32.1447 110.588 43.4175Z'
        fill='#4249DA'
      />
      <path
        d='M97.83 40.8659C97.83 45.0932 94.4025 48.5206 90.1752 48.5206C85.9479 48.5206 82.5205 45.0932 82.5205 40.8659C82.5205 36.6386 85.9479 33.2112 90.1752 33.2112C94.4025 33.2112 97.83 36.6386 97.83 40.8659Z'
        fill='#F1F2F2'
      />
      <path
        d='M187.135 112.31C186.575 117.096 185.019 121.71 182.568 125.859C177.886 134.469 172.591 142.732 166.723 150.584C166.723 150.584 160.037 141.73 154.296 132.034C152.181 128.517 150.288 124.869 148.632 121.114C147.313 118.35 146.526 115.364 146.31 112.31C146.31 101.037 155.45 91.8975 166.723 91.8975C177.997 91.8975 187.135 101.037 187.135 112.31V112.31Z'
        fill='#FAD03E'
      />
      <path
        d='M174.377 109.758C174.377 113.986 170.95 117.413 166.723 117.413C162.495 117.413 159.068 113.986 159.068 109.758C159.068 105.531 162.495 102.104 166.723 102.104C170.95 102.104 174.377 105.531 174.377 109.758Z'
        fill='#F1F2F2'
      />
    </svg>
  );
}
