import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Box } from '@/components/layout/flexbox';

const ProGradient = `
background: -webkit-linear-gradient(top, #ffc712, #e09d02);
background: linear-gradient(to bottom, #ffc712, #e09d02);
`;

export const ProText = styled(Box)`
  ${ProGradient}
  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ProButtonStyles = `
  ${ProGradient}
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  text-align: center;
  box-shadow: 0 1px transparent, 0 1px 3px rgba(0, 0, 0, 0.09);
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.4;
  user-select: none;
  position: relative;
  border: none;
  transition: all 200ms ease;
  font-size: 18px;
  font-weight: 600;
  max-width: 100%;
  width: ${(props) => props.width || 'auto'};
  padding: 12px 20px;
  border-radius: 6px;
  z-index: 1;

  &:hover {
    background: -webkit-linear-gradient(top, #ffc712, #e09d02);
    background: linear-gradient(to bottom, #ffd756, #e09d02);
  }

  &:active, &:focus {
    color: #000;
    text-decoration: none;
  }
`;

export const ProButton = styled('button')`
  ${ProButtonStyles}
`;

export const ExternalProLink = styled('a')`
  ${ProButtonStyles}
`;

export function ProLink({ to, width, children }) {
  return (
    <Link to={to} style={{ maxWidth: '100%' }}>
      <ProButton style={{ width: width || '350px' }}>{children}</ProButton>
    </Link>
  );
}
