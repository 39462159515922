import { useEffect, useState } from 'react';

import DOMPurify from 'dompurify';
import ReactMarkdown from 'react-markdown';

import { hasHTMLTags, isMarkdown } from '@/components/helpers/string-utils';

interface RichContentRendererProps {
  content: string;
  className?: string;
}

export function RichContentRenderer({
  content,
  className,
}: RichContentRendererProps) {
  const [sanitizedContent, setSanitizedContent] = useState(content);

  useEffect(() => {
    // Only run DOMPurify in the browser environment
    if (typeof window !== 'undefined') {
      setSanitizedContent(DOMPurify.sanitize(content));
    }
  }, [content]);

  if (!content) return null;

  if (isMarkdown(content))
    return <ReactMarkdown className={className}>{content}</ReactMarkdown>;

  if (hasHTMLTags(content))
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: sanitizedContent,
        }}
      />
    );

  // Handle plain text
  return (
    <div
      className={className}
      style={{
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
      }}
    >
      {content}
    </div>
  );
}
