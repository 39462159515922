import { createContext, useEffect, useMemo, useState } from 'react';

export const PageSizeContext = createContext({
  isFullSize: false,
  windowHeight: null,
  windowWidth: null,
});

export function PageSizeProvider({ children }) {
  const [isFullSize, setIsFullSize] = useState(false);
  const [windowHeight, setWindowHeight] = useState(null);
  const [windowWidth, setWindowWidth] = useState(null);

  useEffect(() => {
    let prevWindowHeight;

    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);

    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    prevWindowHeight = window.innerHeight;

    function onResize() {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);

      if (iOS && !(window.navigator as any).standalone) {
        setIsFullSize(
          window.innerHeight > prevWindowHeight && window.innerHeight > 600,
        );
      }
      prevWindowHeight = window.innerHeight;
    }

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const value = useMemo(
    () => ({ isFullSize, windowHeight, windowWidth }),
    [isFullSize, windowHeight, windowWidth],
  );

  return (
    <PageSizeContext.Provider value={value}>
      {children}
    </PageSizeContext.Provider>
  );
}
