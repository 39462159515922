import { useState } from 'react';

import styled from '@emotion/styled';

import { Box } from '@/components/layout/flexbox';

function replacePatternToComponent(text, pattern, Component) {
  if (!text) return null;
  const splitText = text.split(pattern);
  const matches = text.match(pattern);

  if (splitText.length <= 1) {
    return text;
  }

  return splitText.reduce((arr, element) => {
    if (!element) return arr;

    if (matches.includes(element)) {
      return [...arr, <Component key={element} value={element} />];
    }

    return [...arr, element];
  }, []);
}

export default function Truncate(props) {
  const { width = '100%', style, children } = props;
  return (
    <Truncation
      {...props}
      width={(width + '').endsWith('%') ? width : `${width}px`}
      style={style}
    >
      {children}
    </Truncation>
  );
}

const Truncation = styled(Box)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

function LinkReplace({ value }) {
  if (!value) return;
  const urlReg = new RegExp(/((?:(?:https?:\/\/)|(?:www\.))[^\s]+)/g);
  function URLLink({ value }) {
    return (
      <a href={value} target='_blank' rel='noopener noreferrer'>
        {value}
      </a>
    );
  }
  return <>{replacePatternToComponent(value, urlReg, URLLink)}</>;
}

export function ExpandableAnswer({
  value,
  replaceLinks = false,
  maxLength = 85,
  maxNewLines = 10,
}) {
  const newLineCount = (value?.match(/\n/g) || []).length;
  const tValue = (
    value && newLineCount > maxNewLines
      ? value.split('\n').slice(0, maxNewLines).join('\n')
      : value
  ).substring(0, maxLength);
  const shouldTrunc = value?.length >= maxLength || newLineCount > maxNewLines;
  const [showingAll, showAll] = useState(!shouldTrunc);

  if (!value) {
    return null;
  }

  return showingAll ? (
    <>
      {replaceLinks ? <LinkReplace value={value} /> : value}
      {shouldTrunc ? (
        <Box>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault();
              showAll(false);
            }}
          >
            Show Less
          </a>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {tValue}
      {shouldTrunc ? (
        <>
          {'...'}
          <Box>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault();
                showAll(true);
              }}
            >
              Show All
            </a>
          </Box>
        </>
      ) : (
        ''
      )}
    </>
  );
}
