import { useRef, useState } from 'react';

import { InputGroup } from '@blueprintjs/core';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';

import { Box, Flex } from '@/components/layout/flexbox';
import { BP_NS, SUBTEXT_COLOR } from '@/css/constants';

function SearchBox({
  currentRefinement = null,
  refine,
  autoFocus = false,
  throttledDuration = 100,
  searchPlaceholder = 'Filter Users...',
  style = {},
  disabled = false,
  className = '',
}) {
  const throttledHandler = useRef(
    debounce((value) => refine(value), throttledDuration),
  );
  const [query, setQuery] = useState(currentRefinement || '');

  function handleChange(value) {
    setQuery(value);
    throttledHandler.current(value);
  }

  return (
    <Container justifyContent='space-between'>
      <Box flex={1}>
        <InputGroup
          className={className}
          disabled={disabled}
          style={style}
          autoFocus={autoFocus}
          type='search'
          leftIcon='search'
          placeholder={searchPlaceholder}
          fill={true}
          large={true}
          value={query}
          onChange={(e) => {
            const value = e.currentTarget.value;
            if (value !== currentRefinement) {
              handleChange(value);
            }
          }}
        />
      </Box>
    </Container>
  );
}

export default SearchBox;

const Container = styled(Flex)`
  input {
    box-shadow: none;
    border: none;
    :focus {
      border: none;
      box-shadow: none;
    }
    ::placeholder {
      color: ${SUBTEXT_COLOR};
    }
  }
  .icon-center {
    .${BP_NS}-icon {
      margin: 0px !important;
      top: 50%;
      left: 14px !important;
      transform: translate(0%, -50%);
    }
  }
`;
