import { Emoji } from 'emoji-picker-react';

import { Box } from '@/components/layout/flexbox';
import { RoleplayType } from '@/graphql';

export function CollectionTitle({ collection }: { collection: RoleplayType }) {
  return (
    <Box>
      <Box display='inline-block' pr={2}>
        <Emoji unified={collection.emojiUnicode.toLowerCase()} size={24} />
      </Box>
      {collection.name}
    </Box>
  );
}
