import execWhiteLogo from '@/../../hypetrain/static/img/exec-wm-white.png';
import whiteLogo from '@/../../hypetrain/static/img/logo-white.png';
import { Link } from 'react-router-dom';

import { APP_PROFILE } from '@/components/helpers/configuration/constants';
import LazyImg from '@/components/helpers/ui/LazyImg';
import { Col, Container, Row } from '@/components/pieces/bootstrap';

function PublicFooter() {
  const helloEmail = APP_PROFILE.helloEmail;

  if (IS_EXEC) {
    return (
      <div>
        <div className='public-footer exec'>
          <Container>
            <Row>
              <Col width={[1, 1, 2 / 3]}>
                <div className='footer-sections'>
                  <div className='footer-section'>
                    <div className='footer-title'>Solutions</div>
                    <ul className='list-unstyled'>
                      <li>
                        <Link to='/exec-development'>
                          Executive Development
                        </Link>
                      </li>
                      <li>
                        <Link to='/manager-growth'>Manager Growth</Link>
                      </li>
                      <li>
                        <Link to='/hipo-program'>High Potential Programs</Link>
                      </li>
                      <li>
                        <Link to='/offsites'>Offsites & Events</Link>
                      </li>
                      {IS_EXEC && (
                        <li>
                          <Link to='/ai-roleplays'>AI Roleplays</Link>
                        </li>
                      )}
                      <li>
                        <Link to='/for-individuals'>For Individuals</Link>
                      </li>
                      <li>
                        <Link to='/outplacement'>Outplacement</Link>
                      </li>
                    </ul>
                  </div>
                  <div className='footer-section'>
                    <div className='footer-title'>Resources</div>
                    <ul className='list-unstyled'>
                      <li>
                        <Link to='/learn'>Guides</Link>
                      </li>
                      {IS_EXEC && (
                        <li>
                          <Link to='/sales-comp'>Sales Comp Calculator</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className='footer-section'>
                    <div className='footer-title'>Company</div>
                    <ul className='list-unstyled'>
                      <li>
                        <Link to='/about-us'>About Us</Link>
                      </li>
                      <li>
                        <Link to='/for-coaches'>Join as Coach</Link>
                      </li>
                      <li>
                        <Link to='/enterprise-privacy'>Enterprise Privacy</Link>
                      </li>
                      <li>
                        <Link to='/faq'>FAQ</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col width={[1, 1, 1 / 3]} className='footer-logo'>
                <LazyImg
                  style={{ marginBottom: IS_EXEC ? 10 : 0 }}
                  src={IS_EXEC ? execWhiteLogo : whiteLogo}
                  width='180'
                  height='40'
                />
                <ul className='list-unstyled'>
                  <li>
                    <a href={`mailto:${helloEmail}`}>{helloEmail}</a>
                  </li>
                  <li>Built in San Francisco</li>
                  <li style={{ fontSize: 12 }}>
                    Mailing Address (PO Box):
                    <br />
                    440 N Barranca Ave #1890
                    <br />
                    Covina, CA 91723
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='sub-footer exec'>
          <Container>
            <Row>
              <Col width={[1, 1, 1 / 2]}>
                ©{new Date().getFullYear()} Exec Holdings, Inc.
              </Col>
              <Col width={[1, 1, 1 / 2]} textAlign='right'>
                <Link to='/privacy-policy'>Privacy Policy</Link>{' '}
                <span style={{ margin: 'auto 1rem' }}>•</span>{' '}
                <Link to='/tos'>Terms of Service</Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className='public-footer'>
        <Container>
          <Row>
            <Col width={[1, 1, 4 / 5]}>
              <div className='footer-sections'>
                <div className='footer-section'>
                  <div className='footer-title'>Solutions</div>
                  <ul className='list-unstyled'>
                    <li>
                      <a href='https://www.exec.com'>For Business</a>
                    </li>
                    <li>
                      <a href='https://www.exec.com/outplacement'>
                        Outplacement
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='footer-section'>
                  <div className='footer-title'>Coaching</div>
                  <ul className='list-unstyled'>
                    <li>
                      <Link to='/leadership-coaching'>Leadership Coaching</Link>
                    </li>
                    <li>
                      <Link to='/career-coaching'>Career Coaching</Link>
                    </li>
                    <li>
                      <Link to='/life-coaching'>Life Coaching</Link>
                    </li>
                    <li>
                      <Link to='/job-search-coaching'>Job Search Coaching</Link>
                    </li>
                  </ul>
                </div>
                <div className='footer-section'>
                  <div className='footer-title'>Resources</div>
                  <ul className='list-unstyled'>
                    <li>
                      <Link to='/learn'>Guides</Link>
                    </li>
                  </ul>
                </div>
                <div className='footer-section'>
                  <div className='footer-title'>Company</div>
                  <ul className='list-unstyled'>
                    <li>
                      <a href='https://www.exec.com/about-us'>About Us</a>
                    </li>
                    <li>
                      <a href='https://www.exec.com/for-coaches'>
                        Join as Coach
                      </a>
                    </li>
                    <li>
                      <a href='https://apply.workable.com/exec/'>Careers</a>
                    </li>
                    <li>
                      <Link to='/faq'>FAQ</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col width={[1, 1, 1 / 5]} className='footer-logo'>
              <LazyImg src={whiteLogo} width='180' height='36' />
              <ul className='list-unstyled'>
                <li>
                  <a href={`mailto:${helloEmail}`}>{helloEmail}</a>
                </li>
                <li>Made in San Francisco</li>
                <li style={{ fontSize: 12 }}>
                  Mailing Address (PO Box):
                  <br />
                  440 N Barranca Ave #1890
                  <br />
                  Covina, CA 91723
                </li>
                <li>
                  <a href='https://twitter.com/withexec'>Twitter</a>
                </li>
                <li>
                  <a href='https://www.linkedin.com/company/withexec/'>
                    LinkedIn
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='sub-footer'>
        <Container>
          <Row>
            <Col width={[1, 1, 1 / 2]}>©2023 Exec Holdings, Inc.</Col>
            <Col width={[1, 1, 1 / 2]} textAlign='right'>
              <Link to='/privacy-policy'>Privacy Policy</Link>{' '}
              <span style={{ margin: 'auto 1rem' }}>•</span>{' '}
              <Link to='/tos'>Terms of Service</Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default PublicFooter;
