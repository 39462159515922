import CreditSVG from '@/../../hypetrain/static/img/credit.svg';
import { useQuery } from '@apollo/client';
import { Classes } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { findNextEvenWeekTuesday } from '@/components/helpers/date-utils';
import { pluralize } from '@/components/helpers/string-utils';
import { Tooltip } from '@/components/helpers/ui/blueprint-overrides/Tooltip';
import EmbedSvg from '@/components/helpers/ui/EmbedSvg';
import { Box, Flex } from '@/components/layout/flexbox';
import { Media } from '@/components/layout/Media';
import FlexibleButton from '@/components/pieces/button/FlexibleButton';
import { ProLink, ProText } from '@/components/pieces/Pro';
import {
  CARD_CLASSES,
  INNER_BORDER_COLOR,
  SUBTEXT_COLOR,
} from '@/css/constants';
import { AvailableCreditsDocument } from '@/graphql';

export function CTAButton({ link, label }) {
  return (
    <Box width={[1, 'auto']}>
      <Media lessThan='md'>
        <ProLink to={link} width='100%'>
          {label}
        </ProLink>
      </Media>
      <Media greaterThan='sm'>
        <ProLink width={250} to={link}>
          {label}
        </ProLink>
      </Media>
    </Box>
  );
}

function CreditBalanceBox({
  label,
  amount,
  tooltip = null,
  linkComponent = null,
  showLinks = false,
  ...props
}) {
  return (
    <CreditBalanceTag {...props}>
      <Box fontSize={14} fontWeight={600} color={SUBTEXT_COLOR}>
        {label}
        {tooltip && (
          <Tooltip
            content={
              <Box maxWidth='100%' width={300}>
                {tooltip}
              </Box>
            }
            autoFocus
          >
            <CreditTooltip>?</CreditTooltip>
          </Tooltip>
        )}
      </Box>
      <Box fontSize={18} fontWeight={500} mt={1}>
        {pluralize(Math.max(0, amount), 'Credit', 'Credits', true)}
      </Box>
      {showLinks && (
        <Flex mt={24} textAlign='center' fontSize={14}>
          {linkComponent}
        </Flex>
      )}
    </CreditBalanceTag>
  );
}

export function WorkspaceDashboardCreditsContainer({
  user,
  ctaButton,
  secondaryButton,
}) {
  const { availableCredits, availableBudgets } = user;

  return (
    <Box width={'100%'}>
      <Flex pb={3} alignItems='center' justifyContent='space-between'>
        <Flex fontSize={20} fontWeight={600} alignItems='center'>
          <EmbedSvg src={CreditSVG} height={24} width={24} />
          <Box ml={2}>Credit Overview</Box>
        </Flex>
        <Box>
          {ctaButton}
          {secondaryButton}
        </Box>
      </Flex>
      <Flex flexDirection={['column', 'row']}>
        <CreditBalanceBox
          label='CREDIT BALANCE'
          tooltip='These credits have been purchased and are unspent'
          amount={availableCredits}
          mr={[0, 2]}
          mb={[2, 0]}
        />
        <CreditBalanceBox
          label='TOTAL BUDGET ALLOCATED'
          tooltip='The total credits budget allowed across all of your users'
          amount={availableBudgets}
          ml={[0, 2]}
        />
      </Flex>
    </Box>
  );
}

export function CoachDashboardCreditsContainer({ user, showLinks = true }) {
  const { availableCredits, receivableCredits } = user;

  return (
    <Box width={'100%'} py={2}>
      <Flex pb={3} fontSize={20} fontWeight={600} alignItems='center'>
        <EmbedSvg src={CreditSVG} height={24} width={24} />
        <Box ml={2}>Credit Overview</Box>
      </Flex>
      <Flex flexDirection={['column', 'row']}>
        <CreditBalanceBox
          label='PENDING PAYOUT'
          tooltip={`Payouts are processed every other Tuesday. The next one is on ${findNextEvenWeekTuesday()}`}
          amount={receivableCredits}
          showLinks={showLinks}
          linkComponent={
            <>
              <Link to='/payouts'>Payout History</Link>
              <Box mx={2}>·</Box>
              <Link to='/transfer'>Transfer Credits</Link>
            </>
          }
          mr={[0, 2]}
          mb={[2, 0]}
        />
        <CreditBalanceBox
          label='AVAILABLE TO SPEND'
          tooltip='You can either transfer credits you have earned or buy credits
                  to spend them on the platform.'
          amount={availableCredits}
          showLinks={showLinks}
          linkComponent={<Link to='/buy'>Buy Credits</Link>}
          ml={[0, 2]}
        />
      </Flex>
    </Box>
  );
}

export function AvailableCreditsContainer({
  ctaButton,
  secondaryButton,
  title = 'AVAILABLE TO YOU',
  creditAmount = 0,
  loading = false,
}) {
  return (
    <>
      <Flex flexWrap='wrap' alignItems='center'>
        <Media at='xs'>
          <EmbedSvg src={CreditSVG} height={36} width={36} />
        </Media>
        <Media greaterThan='xs'>
          <EmbedSvg src={CreditSVG} height={57} width={57} />
        </Media>
        <Box py={2} ml={3}>
          <ProText fontSize={14}>{title}</ProText>
          <Box mt={1} fontSize={[18, 20]} fontWeight={600}>
            {loading ? (
              <Box className={Classes.SKELETON}>3.0</Box>
            ) : (
              pluralize(Math.max(0, creditAmount), 'Credit', 'Credits', true)
            )}
          </Box>
        </Box>
      </Flex>
      <Flex alignItems='center' flexWrap='wrap'>
        {ctaButton}
        {secondaryButton}
      </Flex>
    </>
  );
}

export function AvailableCreditsInner({
  user,
  ctaLabel = null,
  ctaLink = null,
  secondaryLabel = null,
  secondaryLink = null,
  title = 'AVAILABLE TO YOU',
  showBudgetCredit = false,
  ...props
}) {
  const ctaButton =
    ctaLink && ctaLabel ? (
      <Box mt={[3, 0]} width={[1, 'auto']}>
        <FlexibleButton as={Link} to={ctaLink}>
          {ctaLabel}
        </FlexibleButton>
      </Box>
    ) : null;
  const secondaryButton =
    secondaryLabel && secondaryLink ? (
      <Box ml={[0, 3]} mt={[3, 0]} width={[1, 'auto']}>
        <FlexibleButton as={Link} to={secondaryLink}>
          {secondaryLabel}
        </FlexibleButton>
      </Box>
    ) : null;

  return (
    <Flex
      py={3}
      px={[3, 24]}
      className={CARD_CLASSES}
      backgroundColor='#FFF'
      flexWrap='wrap'
      alignItems='center'
      justifyContent='space-between'
      {...props}
    >
      {showBudgetCredit ? (
        <WorkspaceDashboardCreditsContainer
          user={user}
          ctaButton={ctaButton}
          secondaryButton={secondaryButton}
        />
      ) : user?.isAgent ? (
        <CoachDashboardCreditsContainer user={user} />
      ) : (
        <AvailableCreditsContainer
          title={title}
          creditAmount={user ? user.availableCredits : 0}
          ctaButton={ctaButton}
          secondaryButton={secondaryButton}
        />
      )}
    </Flex>
  );
}

export default function AvailableCredits({ customerId, ...props }) {
  const { data, loading } = useQuery(AvailableCreditsDocument, {
    variables: {
      customerId,
    },
    fetchPolicy: 'cache-and-network',
  });
  if (loading || !data) {
    return <Box className={Classes.SKELETON} height={100} />;
  }
  const user = customerId ? data.customer : data.currentUser;
  return <AvailableCreditsInner user={user} {...props} />;
}

const CreditBalanceTag = styled(Flex)`
  flex-direction: column;
  border: 1px solid ${INNER_BORDER_COLOR};
  border-radius: 8px;
  padding: 20px;
  white-space: nowrap;
  color: #000;
  flex: 1;
`;
const CreditTooltip = styled(Flex)`
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #748498;
  margin-left: 10px;
`;
