import { useEffect, useState } from 'react';

import { Button, Callout, H4, Intent } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import { PromptContent } from '@/components/pieces/article/Prompt';
import BitIcon from '@/components/pieces/BitIcon';
import FadeInBox from '@/components/pieces/FadeInBox';
import Clickable from '@/components/pieces/interaction/Clickable';
import { getUser } from '@/components/session/JwtTokenManager';
import { MIDTEXT_COLOR } from '@/css/constants';

import { NEW_USER_BIT_BONUS } from './constants';

export default function GPTBotForm({
  entry,
  handleSubmit,
  loading,
  error,
  visible,
  showPurchase,
  bits,
  isConfirmed,
  showSignup,
  showConfirm,
}) {
  const [answers, setAnswers] = useState({});
  const [showingCta, setShowingCta] = useState(false);
  const user = getUser();
  function updateAnswer(id, answer) {
    setAnswers((prevState) => {
      return {
        ...prevState,
        [id]: answer,
      };
    });
  }

  useEffect(() => {
    if (
      !showingCta &&
      Object.keys(answers).reduce((agg, k) => agg + answers[k].answer.length, 0)
    ) {
      setShowingCta(true);
    }
  }, [answers]);

  if (!visible) return null;

  return (
    <Box
      mt={3}
      backgroundColor='rgba(255, 255, 255, 0.8)'
      className='round-corners'
      p={3}
    >
      {entry.fields.user_prompts.map((userPrompt, i) => (
        <Box key={i} mt={i > 0 ? 24 : 0}>
          <PromptContent
            entry={userPrompt}
            disabled={loading}
            answerCallback={(answer) => updateAnswer(userPrompt.id, answer)}
            showSignUp={false}
          />
        </Box>
      ))}
      {error ? (
        <Box mt={3}>
          {error.code === 'OVERWHELMED' ? (
            <Callout
              intent={Intent.WARNING}
              title='Our AI is currently overwhelmed'
            >
              Please wait a little while and try again
            </Callout>
          ) : error.code === 'PUBLIC_OUT_OF_BITS' ? (
            <Callout
              intent={Intent.WARNING}
              title='Oops, we hit our free, public usage for the day'
            >
              Please{' '}
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  showSignup();
                }}
              >
                create an account
              </a>{' '}
              to use our AI. You will get {NEW_USER_BIT_BONUS} Bits when you
              confirm your email address!
            </Callout>
          ) : error.code === 'PRIVATE_OUT_OF_BITS' ? (
            <Callout intent={Intent.WARNING}>
              <H4>
                Oops, you are out of <BitIcon width={18} height={18} /> Bits
              </H4>
              <Box>
                {isConfirmed ? (
                  <>
                    To keep going, please{' '}
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault();
                        showPurchase();
                      }}
                    >
                      purchase more Bits
                    </a>
                    .
                  </>
                ) : (
                  <>
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault();
                        showConfirm();
                      }}
                    >
                      Confirm your email address
                    </a>{' '}
                    to get 50 Bits for free!
                  </>
                )}
              </Box>
            </Callout>
          ) : error.code === 'GENERIC_ERROR' || !error.code ? (
            <Callout
              intent={Intent.WARNING}
              title='Oops, we are experiencing some difficulty'
            >
              Please try again in a little while
            </Callout>
          ) : null}
        </Box>
      ) : null}
      {showingCta ? (
        <FadeInBox>
          <Flex
            mt={3}
            alignItems='center'
            justifyContent='space-between'
            flexWrap='wrap'
          >
            <Button
              loading={loading}
              disabled={loading}
              intent='primary'
              large={true}
              onClick={() => handleSubmit(answers)}
            >
              {entry.fields.cta}
            </Button>
            <Box width={['100%', 'auto']} mt={[3, 0]}>
              <Clickable onClick={showPurchase}>
                <Flex alignItems='center' fontSize={16} color={MIDTEXT_COLOR}>
                  <BitIcon width={18} height={18} />
                  <Box ml={1}>
                    Uses 1 Bit{' '}
                    {bits !== null ? (
                      <>({bits.toLocaleString().split('.')[0]} available)</>
                    ) : (
                      <>(Using public allowance)</>
                    )}
                  </Box>
                </Flex>
              </Clickable>
            </Box>
          </Flex>
          {!isConfirmed && user ? (
            <Box mt={3}>
              <Callout intent={Intent.PRIMARY}>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    showConfirm();
                  }}
                >
                  Confirm your email address
                </a>{' '}
                to get 50 Bits for free!
              </Callout>
            </Box>
          ) : null}
        </FadeInBox>
      ) : null}
    </Box>
  );
}
