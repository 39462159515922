import {
  Checkbox as BlueprintCheckbox,
  Classes,
  Colors,
} from '@blueprintjs/core';
import styled from '@emotion/styled';

import Subtext from '@/components/pieces/form/Subtext';
import Clickable from '@/components/pieces/interaction/Clickable';
import { CTA_COLOR } from '@/css/constants';

export default function Checkbox({
  checked,
  onChange,
  label = null,
  subtext = null,
  style = {},
  disabled = false,
  hasBorders = true,
  backgroundColor = Colors.WHITE,
  checkedColor = CTA_COLOR,
  labelElement = null,
}) {
  const StyledContainer = styled('div')`
    background-color: ${backgroundColor};
    border-width: 2px;
    border-style: ${hasBorders ? 'solid' : 'none'};
    padding: ${hasBorders ? '10px 15px' : 0};
    border-color: ${checked ? checkedColor : Colors.LIGHT_GRAY3};
    label {
      margin-bottom: 0;
    }
    ,
    label:hover {
      color: ${checked ? 'inherit' : Colors.GRAY3};
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background-color: ${checked ? checkedColor : 'inherit'};
        :before {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
        }
      }
    }
  `;

  return (
    <Clickable
      onClick={
        disabled
          ? null
          : () => {
              onChange(!checked);
            }
      }
    >
      <StyledContainer>
        <BlueprintCheckbox
          large={true}
          checked={checked}
          disabled={disabled}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          label={label}
          style={{ whiteSpace: 'nowrap', marginTop: 0, ...style }}
          labelElement={labelElement}
        />
        {subtext ? <Subtext pt={1}>{subtext}</Subtext> : null}
      </StyledContainer>
    </Clickable>
  );
}
