import { Colors } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import { useWorkspace } from '@/components/helpers/custom-hooks/use-workspace';
import {
  isAssist,
  isCoach,
  isWorkspaceAgent,
  isWorkspaceGroupAdmin,
} from '@/components/helpers/workspace/permissions';
import { Box, Flex } from '@/components/layout/flexbox';
import SideNavSkeleton from '@/components/pages_logged_in/workspace/nav/SideNav/SideNavSkeleton';
import WorkspaceSwitching from '@/components/pages_logged_in/workspace/nav/SideNav/WorkspaceSwitching';
import FlipIcon from '@/components/pieces/icons/FlipIcon';
import { CustomIcon } from '@/components/pieces/icons/Icons';
import AnalyticsSvg from '@/components/pieces/icons/svg/analytics';
import {
  ClientsDeselected as ClientsDeselectedIcon,
  ClientsSelected as ClientsSelectedIcon,
} from '@/components/pieces/icons/svg/clients';
import CoachingSvg from '@/components/pieces/icons/svg/coaching';
import PlaybookSvg from '@/components/pieces/icons/svg/playbook';
import PracticeSvg from '@/components/pieces/icons/svg/practice';
import { getUser } from '@/components/session/JwtTokenManager';
import { BRAND_PURPLE, LIGHT_BRAND_PURPLE, NAV_Z_INDEX } from '@/css/constants';

import SideMenuItem from './SideMenuItem';

export function SideNavContent({ pageLoading }) {
  const user = getUser();
  const workspace = useWorkspace();
  const location = useLocation();

  if (!user) {
    return <SideNavSkeleton />;
  }

  let activeMenu = null;
  const { pathname } = location;
  const isInWorkspace = !!workspace;

  if (pathname === '/dashboard') {
    activeMenu = 'dashboard';
  } else if (pathname === '/referrals') {
    activeMenu = 'referrals';
  } else if (pathname.startsWith('/todo')) {
    activeMenu = 'todo';
  } else if (pathname.startsWith('/client')) {
    activeMenu = 'client';
  } else if (pathname.startsWith('/assist')) {
    activeMenu = 'assist';
  } else if (pathname.startsWith('/roleplays')) {
    activeMenu = 'roleplays';
  } else if (pathname.startsWith('/messages')) {
    activeMenu = 'chat';
  } else if (pathname.startsWith('/learn')) {
    activeMenu = 'learn';
  } else if (pathname.startsWith('/analytics')) {
    activeMenu = 'analytics';
  } else if (
    pathname.startsWith('/sessions') ||
    pathname.startsWith('/reviews') ||
    pathname.startsWith('/book') ||
    pathname.startsWith('/schedule') ||
    pathname.startsWith('/coaching') ||
    pathname.startsWith('/buy') ||
    pathname.startsWith('/services') ||
    pathname.startsWith('/payouts') ||
    pathname.startsWith('/availability')
  ) {
    activeMenu = 'dashboard';
  } else if (pathname.startsWith('/workspace')) {
    activeMenu = 'workspace';
  } else if (pathname.startsWith('/programs')) {
    activeMenu = 'program';
  }

  const programItem =
    user?.isSuperuser || workspace?.programsEnabled ? (
      <SideMenuItem
        icon={
          <PlaybookSvg
            size={16}
            color={activeMenu === 'program' ? BRAND_PURPLE : '#BFBFBF'}
          />
        }
        label='Programs'
        active={activeMenu === 'program'}
        link='/programs'
      />
    ) : null;

  const roleplaysItem =
    !isInWorkspace || workspace?.roleplaysEnabled ? (
      <SideMenuItem
        icon={
          <PracticeSvg
            size={16}
            color={activeMenu === 'roleplays' ? BRAND_PURPLE : '#BFBFBF'}
          />
        }
        label='Roleplays'
        active={activeMenu === 'roleplays'}
        link='/roleplays'
      />
    ) : null;

  const workspaceItems = (
    <>
      {isWorkspaceAgent() || isAssist() ? <hr /> : null}
      {isWorkspaceGroupAdmin() && !isWorkspaceAgent() && (
        <WorkspaceNavItem label='Groups' active={activeMenu === 'workspace'} />
      )}
      {isAssist() && (
        <SideMenuItem
          icon={
            <CoachingSvg
              size={16}
              color={activeMenu === 'assist' ? BRAND_PURPLE : '#BFBFBF'}
            />
          }
          label='Assist'
          active={activeMenu === 'assist'}
          link='/assist'
        />
      )}
      {isWorkspaceAgent() &&
        user?.workspaces?.some((obj) => obj.analyticsEnabled) && (
          <AnalyticsNavItem active={activeMenu === 'analytics'} />
        )}
      {isWorkspaceAgent() && (
        <WorkspaceNavItem active={activeMenu === 'workspace'} />
      )}
    </>
  );

  const coachItems = (
    <>
      <DashboardNavItem active={activeMenu === 'dashboard'} />
      <SideMenuItem
        icon={<ClientIcon active={activeMenu === 'client'} />}
        label='Clients'
        active={activeMenu === 'client'}
        link='/clients'
      />
      <hr />
      {programItem}
      {roleplaysItem}
      <LearnNavItem active={activeMenu === 'learn'} />
    </>
  );

  const clientItems = (
    <>
      <DashboardNavItem active={activeMenu === 'dashboard'} />
      {programItem}
      {roleplaysItem}
      <LearnNavItem active={activeMenu === 'learn'} />
    </>
  );

  return (
    <Flex flexDirection='column' justifyContent='space-between' height='100%'>
      <Box>
        <WorkspaceSwitching pageLoading={pageLoading} />
        <Box px={2} py={3}>
          {isCoach() ? coachItems : clientItems}
          {isInWorkspace &&
          (isWorkspaceAgent() || isAssist() || isWorkspaceGroupAdmin())
            ? workspaceItems
            : null}
        </Box>
      </Box>
      {!isInWorkspace ? (
        <Box px={2}>
          <SideMenuItem
            icon={
              <CustomIcon
                icon='gift'
                iconSize={16}
                color={activeMenu === 'referrals' ? BRAND_PURPLE : '#bfbfbf'}
              />
            }
            label='Referrals'
            active={activeMenu === 'referrals'}
            link='/referrals'
          />
        </Box>
      ) : null}
    </Flex>
  );
}

function DashboardNavItem({ active }) {
  return (
    <SideMenuItem
      icon='home'
      label='Dashboard'
      active={active}
      link={'/dashboard'}
    />
  );
}

function AnalyticsNavItem({ active }) {
  return (
    <SideMenuItem
      icon={
        <AnalyticsSvg
          size={16}
          mainColor={active ? BRAND_PURPLE : '#BFBFBF'}
          lighterColor={active ? LIGHT_BRAND_PURPLE : '#E1E1E1'}
        />
      }
      label='Analytics'
      active={active}
      link={'/analytics'}
    />
  );
}

function WorkspaceNavItem({ active, label = 'Workspace' }) {
  return (
    <SideMenuItem
      icon='cog'
      label={label}
      active={active}
      link={isWorkspaceAgent() ? '/workspace/users' : '/workspace/groups'}
    />
  );
}

function LearnNavItem({ active }) {
  return (
    <SideMenuItem icon='manual' label='Guides' active={active} link='/learn' />
  );
}

function ClientIcon({ active }) {
  return (
    <FlipIcon
      activeIcon={<ClientsSelectedIcon size={16} />}
      inactiveIcon={<ClientsDeselectedIcon size={16} />}
      isActive={active}
    />
  );
}

const SideNavContainer = styled(Box)`
  position: fixed;
  border-right: 1px solid ${Colors.LIGHT_GRAY3};
  top: 0;
  bottom: 0;
  left: 0;
  width: ${(props) => props.width}px;
  z-index: ${NAV_Z_INDEX};
  background-color: ${Colors.WHITE};
  overflow-y: auto;
`;

export default function SideNav(props) {
  return (
    <SideNavContainer width={props.width}>
      <SideNavContent {...props} />
    </SideNavContainer>
  );
}
