import { useQuery } from '@apollo/client';
import { Classes } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import { useWorkspaceSlug } from '@/components/helpers/custom-hooks/use-workspace-slug';
import { Box } from '@/components/layout/flexbox';
import Logo from '@/components/nav/Logo';
import { PublicWorkspaceDocument } from '@/graphql';

export default function WorkspaceLogo() {
  const workplaceSlug = useWorkspaceSlug();
  const { data, loading } = useQuery(PublicWorkspaceDocument, {
    skip: !workplaceSlug,
    variables: { urlSlug: workplaceSlug },
  });

  let logoSrc = null;
  if (data && data.publicWorkspace) {
    const { logo } = data.publicWorkspace;
    logoSrc = logo.startsWith('/media') ? `${API_HOST}${logo}` : logo;
  }

  return (
    <>
      {workplaceSlug ? (
        <Box>
          {!loading ? (
            <img src={logoSrc} style={{ maxHeight: 100, maxWidth: 200 }} />
          ) : (
            <span
              style={{ display: 'inline-block', height: 100, width: 100 }}
              className={Classes.SKELETON}
            />
          )}
        </Box>
      ) : (
        <Link to='/'>
          <Logo />
        </Link>
      )}
    </>
  );
}
