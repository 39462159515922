import clone from '@/../../hypetrain/static/img/clone.svg';
import editpen from '@/../../hypetrain/static/img/edit-pen.svg';
import anger2 from '@/../../hypetrain/static/img/exec/anger-icon2.svg';
import balance2 from '@/../../hypetrain/static/img/exec/balance-icon2.svg';
import change2 from '@/../../hypetrain/static/img/exec/change-icon2.svg';
import decisionMaking from '@/../../hypetrain/static/img/exec/decision-making.svg';
import degree2 from '@/../../hypetrain/static/img/exec/degree-icon2.svg';
import figure2 from '@/../../hypetrain/static/img/exec/figure-icon2.svg';
import find2 from '@/../../hypetrain/static/img/exec/find-icon2.svg';
import grow2 from '@/../../hypetrain/static/img/exec/grow-icon2.svg';
import idea2 from '@/../../hypetrain/static/img/exec/idea-icon2.svg';
import linkedin2 from '@/../../hypetrain/static/img/exec/linkedin-icon2.svg';
import mending2 from '@/../../hypetrain/static/img/exec/mending-icon2.svg';
import mission2 from '@/../../hypetrain/static/img/exec/mission-icon2.svg';
import money2 from '@/../../hypetrain/static/img/exec/money-icon2.svg';
import navigate2 from '@/../../hypetrain/static/img/exec/navigate-icon2.svg';
import parenting2 from '@/../../hypetrain/static/img/exec/parenting-icon2.svg';
import pass2 from '@/../../hypetrain/static/img/exec/pass-icon2.svg';
import quit2 from '@/../../hypetrain/static/img/exec/quit-icon2.svg';
import remote2 from '@/../../hypetrain/static/img/exec/remote-icon2.svg';
import resume2 from '@/../../hypetrain/static/img/exec/resume-icon2.svg';
import team2 from '@/../../hypetrain/static/img/exec/team-icon2.svg';
import anger3 from '@/../../hypetrain/static/img/exec/white/anger-icon3.svg';
import balance3 from '@/../../hypetrain/static/img/exec/white/balance-icon3.svg';
import change3 from '@/../../hypetrain/static/img/exec/white/change-icon3.svg';
import change4 from '@/../../hypetrain/static/img/exec/white/change-icon4.svg';
import degree3 from '@/../../hypetrain/static/img/exec/white/degree-icon3.svg';
import direction4 from '@/../../hypetrain/static/img/exec/white/direction-icon4.svg';
import figure3 from '@/../../hypetrain/static/img/exec/white/figure-icon3.svg';
import find3 from '@/../../hypetrain/static/img/exec/white/find-icon3.svg';
import grow3 from '@/../../hypetrain/static/img/exec/white/grow-icon3.svg';
import idea3 from '@/../../hypetrain/static/img/exec/white/idea-icon3.svg';
import mending3 from '@/../../hypetrain/static/img/exec/white/mending-icon3.svg';
import mission3 from '@/../../hypetrain/static/img/exec/white/mission-icon3.svg';
import mission4 from '@/../../hypetrain/static/img/exec/white/mission-icon4.svg';
import money3 from '@/../../hypetrain/static/img/exec/white/money-icon3.svg';
import navigate3 from '@/../../hypetrain/static/img/exec/white/navigate-icon3.svg';
import parenting3 from '@/../../hypetrain/static/img/exec/white/parenting-icon3.svg';
import pass3 from '@/../../hypetrain/static/img/exec/white/pass-icon3.svg';
import pass4 from '@/../../hypetrain/static/img/exec/white/pass-icon4.svg';
import quit3 from '@/../../hypetrain/static/img/exec/white/quit-icon3.svg';
import remote3 from '@/../../hypetrain/static/img/exec/white/remote-icon3.svg';
import resume3 from '@/../../hypetrain/static/img/exec/white/resume-icon3.svg';
import resume4 from '@/../../hypetrain/static/img/exec/white/resume-icon4.svg';
import shield4 from '@/../../hypetrain/static/img/exec/white/shield-icon4.svg';
import team3 from '@/../../hypetrain/static/img/exec/white/team-icon3.svg';
import anger from '@/../../hypetrain/static/img/placement/anger-icon.svg';
import balance from '@/../../hypetrain/static/img/placement/balance-icon.svg';
import change from '@/../../hypetrain/static/img/placement/change-icon.svg';
import degree from '@/../../hypetrain/static/img/placement/degree-icon.svg';
import figure from '@/../../hypetrain/static/img/placement/figure-icon.svg';
import find from '@/../../hypetrain/static/img/placement/find-icon.svg';
import grow from '@/../../hypetrain/static/img/placement/grow-icon.svg';
import idea from '@/../../hypetrain/static/img/placement/idea-icon.svg';
import mending from '@/../../hypetrain/static/img/placement/mending-icon.svg';
import mission from '@/../../hypetrain/static/img/placement/mission-icon.svg';
import money from '@/../../hypetrain/static/img/placement/money-icon.svg';
import navigate from '@/../../hypetrain/static/img/placement/navigate-icon.svg';
import parenting from '@/../../hypetrain/static/img/placement/parenting-icon.svg';
import pass from '@/../../hypetrain/static/img/placement/pass-icon.svg';
import quit from '@/../../hypetrain/static/img/placement/quit-icon.svg';
import remote from '@/../../hypetrain/static/img/placement/remote-icon.svg';
import resume from '@/../../hypetrain/static/img/placement/resume-icon.svg';
import team from '@/../../hypetrain/static/img/placement/team-icon.svg';

const ITEMS = [
  { key: 'team-building', icon: team, icon2: team2, icon3: team3 },
  { key: 'virtual-leadership', icon: remote, icon2: remote2, icon3: remote3 },
  { key: 'feedback', icon: idea, icon2: idea2, icon3: idea3 },
  { key: 'grow-manager', icon: resume, icon2: resume2, icon3: resume3 },
  { key: 'exec-presence', icon: pass, icon2: pass2, icon3: pass3 },
  { key: 'imposter-syndrome', icon: find, icon2: find2, icon3: find3 },
  { key: 'manage-up', icon: figure, icon2: figure2, icon3: figure3 },
  { key: 'conflict', icon: balance, icon2: balance2, icon3: balance3 },
  { key: 'room-to-grow', icon: grow, icon2: grow2, icon3: grow3 },
  {
    key: 'change-roles',
    icon: change,
    icon2: change2,
    icon3: change3,
    icon4: change4,
  },
  { key: 'find-career', icon: find, icon2: find2, icon3: find3 },
  { key: 'quit-boss', icon: quit, icon2: quit2, icon3: quit3 },
  { key: 'whats-next', icon: figure, icon2: figure2, icon3: figure3 },
  { key: 'interviews', icon: pass, icon2: pass2, icon3: pass3, icon4: pass4 },
  { key: 'go-remote', icon: remote, icon2: remote2, icon3: remote3 },
  { key: 'new-industry', icon: idea, icon2: idea2, icon3: idea3 },
  { key: 'more-money', icon: money, icon2: money2, icon3: money3 },
  {
    key: 'resume',
    icon: resume,
    icon2: resume2,
    icon3: resume3,
    icon4: resume4,
  },
  { key: 'use-education', icon: degree, icon2: degree2, icon3: degree3 },
  {
    key: 'find-mission',
    icon: mission,
    icon2: mission2,
    icon3: mission3,
    icon4: mission4,
  },
  { key: 'find-direction', icon: navigate, icon2: navigate2, icon3: navigate3 },
  { key: 'work-life', icon: balance, icon2: balance2, icon3: balance3 },
  { key: 'anger', icon: anger, icon2: anger2, icon3: anger3 },
  { key: 'parenting', icon: parenting, icon2: parenting2, icon3: parenting3 },
  { key: 'emotion', icon: mending, icon2: mending2, icon3: mending3 },
  { key: 'linkedin', icon: linkedin2, icon2: linkedin2, icon3: linkedin2 },
  { key: 'direction', icon: direction4, icon2: direction4, icon3: direction4 },
  {
    key: 'shield',
    icon: shield4,
    icon2: shield4,
    icon3: shield4,
    icon4: shield4,
  },
  {
    key: 'decision-making',
    icon: decisionMaking,
    icon2: decisionMaking,
    icon3: decisionMaking,
  },
  {
    key: 'clone',
    icon: clone,
    icon2: clone,
  },
  {
    key: 'editpen',
    icon: editpen,
    icon2: editpen,
  },
];

export default function SvgIcon({ name, isWhite = false, hasBorder = false }) {
  const item = ITEMS.find((item) => item.key == name);
  if (item) {
    if (isWhite) {
      if (hasBorder && item?.icon4) {
        return item?.icon4;
      }
      return item.icon3;
    }
    return IS_EXEC ? item.icon2 : item.icon;
  }
  return ITEMS[0].icon;
}
