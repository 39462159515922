import { getUser } from '@/components/session/JwtTokenManager';
import { UserCurrentSite } from '@/graphql';

import { FEATURE_CONFIGS } from './constants';

export function enableFeature(
  featureName = '',
  defaultValue = true,
  customer = null,
) {
  const user = customer ? customer : getUser();
  if (user && user.configuration) {
    if (featureName in user.configuration) {
      return user.configuration[featureName];
    }
  }
  return defaultValue;
}

export function enableReviews(customer = null) {
  return enableFeature(FEATURE_CONFIGS.enableReviews, true, customer);
}

export function enableJobSearch(customer = null) {
  return enableFeature(FEATURE_CONFIGS.enableJobSearch, true, customer);
}

export function getCurrentSite() {
  return IS_EXEC ? UserCurrentSite.Exec : UserCurrentSite.Placement;
}
