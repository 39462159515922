import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Flex } from '@/components/layout/flexbox';
import { BRAND_PURPLE } from '@/css/constants';

const LPButtonStyles = `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  text-align: center;
  box-shadow: 0 1px transparent, 0 1px 3px rgba(0, 0, 0, 0.09);
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.4;
  user-select: none;
  position: relative;
  border: none;
  transition: all 200ms ease;
  font-size: 18px;
  font-weight: 600;
  max-width: 100%;
  padding: 12px 20px;
  border-radius: 6px;
  z-index: 1;

  &:hover {
    opacity: 0.8
  }

  &:active, &:focus {
    text-decoration: none;
  }
`;

interface LPButtonProps {
  background: string;
  radius: number;
}

const LPButton = styled('button')<LPButtonProps>`
  ${LPButtonStyles}
  background: ${(props) => props.background};
  border-radius: ${(props) => props.radius};
`;

const LPHref = styled('a')<LPButtonProps>`
  ${LPButtonStyles}
  background: ${(props) => props.background};
  border-radius: ${(props) => props.radius};
`;

export default function LPLink({
  to,
  radius = 6,
  text,
  color = BRAND_PURPLE,
  style = {},
  onCtaClick = null,
}) {
  const defaultFlexParams = {
    py: 2,
    fontSize: 22,
    width: 260,
    ...style,
  };
  const isInternalLink = to && to[0] === '/';
  if (isInternalLink) {
    return (
      <Link to={to}>
        <LPButton background={color} radius={radius}>
          <Flex justifyContent='center' color='white' {...defaultFlexParams}>
            {text}
          </Flex>
        </LPButton>
      </Link>
    );
  }
  if (onCtaClick) {
    return (
      <LPButton background={color} radius={radius} onClick={onCtaClick}>
        <Flex justifyContent='center' color='white' {...defaultFlexParams}>
          {text}
        </Flex>
      </LPButton>
    );
  }
  return (
    <LPHref href={to} background={color} radius={radius}>
      <Flex justifyContent='center' color='white' {...defaultFlexParams}>
        {text}
      </Flex>
    </LPHref>
  );
}
