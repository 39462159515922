import { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';

import { DeviceInfoQuery } from '@/graphql/cachedVarsQueries';

interface DeviceInfo {
  screenWidth: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
}

export const breakpoints = {
  xs: 0,
  sm: 640,
  md: 832,
  lg: 1024,
  ml: 1280,
  xl: 1440,
} as const;

export const isMobileQuery = { query: '(max-width: 767px)' };

export const isXsQuery = { query: `(max-width: ${breakpoints.sm - 1}px)` };
export const isSmQuery = {
  query: `(min-width: ${breakpoints.sm}px) and (max-width: ${
    breakpoints.md - 1
  }px)`,
};
export const isMdQuery = {
  query: `(min-width: ${breakpoints.md}px) and (max-width: ${
    breakpoints.lg - 1
  }px)`,
};
export const isXsToMdQuery = {
  query: `(max-width: ${breakpoints.md - 1}px)`,
};
export const isLgQuery = { query: `(min-width: ${breakpoints.lg}px)` };
export const isXsToLgQuery = {
  query: `(max-width: ${breakpoints.lg - 1}px)`,
};
export const isXsToXlQuery = {
  query: `(max-width: ${breakpoints.xl - 1}px)`,
};
export const isSmToMlQuery = {
  query: `(min-width: ${breakpoints.sm}px) and (max-width: ${
    breakpoints.ml - 1
  }px)`,
};
export const isMlQuery = { query: `(min-width: ${breakpoints.ml}px)` };
export const isXlQuery = { query: `(min-width: ${breakpoints.xl}px)` };

/**
 * Hook that handles device detection with SSR support
 * @param initialDeviceInfo - Initial device info from server-side
 * @returns DeviceInfo object with various device properties
 *
 * Generated by Copilot
 */
export function useSSRMediaQuery(): DeviceInfo {
  // Get initial device info from SSR
  const { data } = useQuery<any>(DeviceInfoQuery);
  const initialDeviceInfo = data?.deviceInfo || {};
  const initialScreenWidth = initialDeviceInfo?.screenWidth || 0;

  const [screenWidth, setScreenWidth] = useState<number>(initialScreenWidth);

  // Use react-responsive for breakpoint detection
  const isXs = useMediaQuery(isXsQuery);
  const isSm = useMediaQuery(isSmQuery);
  const isMd = useMediaQuery(isMdQuery);
  const isLg = useMediaQuery(isLgQuery);
  const isXl = useMediaQuery(isXlQuery);

  useEffect(() => {
    // Only run on client side
    if (typeof window === 'undefined') return;

    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Set initial client-side value
    updateScreenWidth();

    // Update on resize
    window.addEventListener('resize', updateScreenWidth);

    // Cleanup
    return () => window?.removeEventListener('resize', updateScreenWidth);
  }, []);

  return {
    screenWidth,
    isMobile: !isMd,
    isTablet: isMd && !isLg,
    isDesktop: isLg,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
  };
}
