import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Alignment, Navbar } from '@blueprintjs/core';
import { useParams } from 'react-router-dom';

import lazyFetch from '@/components/helpers/graphql/lazy-fetch';
import { Box, Flex } from '@/components/layout/flexbox';
import { Media, SizeBreakpoint } from '@/components/layout/Media';
import ImpersonatingMessage from '@/components/nav/ImpersonatingMessage';
import SearchButton from '@/components/pieces/search/SearchButton';
import {
  NAV_Z_INDEX,
  PADDING_BREAKPOINT_MAP,
  SIDE_NAV_WIDTH,
  STANDARD_NAV_HEIGHT,
} from '@/css/constants';
import { WorkspaceNavQueryDocument, WorkspaceNavQueryQuery } from '@/graphql';

import HeaderProfile from './HeaderProfile';
import MessageNav from './MessageNav';
import SideNav from './SideNav';

interface WorkspaceNavProps {
  customerId?: string;
  prerenderNavSection?: string;
  sizeNavBreakpoint?: SizeBreakpoint;
  pageLoading?: boolean;
}

export default function WorkspaceNav({
  customerId,
  prerenderNavSection,
  sizeNavBreakpoint = 'sm',
  pageLoading = true,
}: WorkspaceNavProps) {
  const { userId } = useParams();
  const effectiveCustomerId = customerId || userId;

  const [fetch, { data }] = useLazyQuery<WorkspaceNavQueryQuery>(
    WorkspaceNavQueryDocument,
  );

  useEffect(() => {
    if (!pageLoading) {
      const timeoutRef = lazyFetch(fetch);
      return () => clearTimeout(timeoutRef);
    }
  }, [pageLoading, fetch]);

  return (
    <>
      <Navbar
        fixedToTop={true}
        style={{
          zIndex: NAV_Z_INDEX,
          height: STANDARD_NAV_HEIGHT,
          paddingLeft: 0,
        }}
      >
        <Box pl={PADDING_BREAKPOINT_MAP[sizeNavBreakpoint]} ml={3} pr={2}>
          <Navbar.Group
            align={Alignment.LEFT}
            style={{ height: STANDARD_NAV_HEIGHT }}
          >
            <Flex alignItems='center'>
              <SearchButton
                disabled={pageLoading}
                customerId={effectiveCustomerId}
                forceXs={false}
              />
              <Media greaterThan='md'>
                <ImpersonatingMessage />
              </Media>
            </Flex>
          </Navbar.Group>
          <Navbar.Group
            align={Alignment.RIGHT}
            style={{ height: STANDARD_NAV_HEIGHT }}
          >
            <Flex alignItems='center' ml={2}>
              <MessageNav />
              <Box>
                <HeaderProfile
                  data={data}
                  prerenderNavSection={prerenderNavSection}
                  pageLoading={pageLoading}
                  sizeNavBreakpoint={sizeNavBreakpoint}
                />
              </Box>
            </Flex>
          </Navbar.Group>
        </Box>
      </Navbar>
      <Media greaterThanOrEqual={sizeNavBreakpoint}>
        <SideNav width={SIDE_NAV_WIDTH} pageLoading={pageLoading} />
      </Media>
    </>
  );
}
