import { Box, Flex } from '@/components/layout/flexbox';
import PaginationButton from '@/components/pieces/form/PaginationButton';

export default function Pagination({
  nbPages,
  currentPage,
  totalCount,
  countPerPage,
  onPrev,
  onNext,
}) {
  return (
    <Flex
      alignItems='center'
      flexWrap='nowrap'
      style={{ minWidth: 160 }}
      justifyContent='flex-end'
    >
      <PageCount
        currentPage={Math.max(1, currentPage)}
        nbPages={nbPages}
        totalCount={totalCount}
        countPerPage={countPerPage}
      />
      <Box mx={2}>
        <PaginationButton
          icon='chevron-left'
          disabled={currentPage <= 1}
          onClick={onPrev}
          style={{ paddingLeft: 10, paddingRight: 10 }}
        />
      </Box>
      <Box>
        <PaginationButton
          icon='chevron-right'
          disabled={currentPage >= nbPages}
          onClick={onNext}
          style={{ paddingLeft: 10, paddingRight: 10 }}
        />
      </Box>
    </Flex>
  );
}

function PageCount({ currentPage, nbPages, totalCount, countPerPage }) {
  const validCurrentPage = Math.max(1, currentPage);
  const isLastPage = validCurrentPage === nbPages;
  const min = Math.max(1, (validCurrentPage - 1) * countPerPage + 1);
  const max = Math.min(
    isLastPage ? totalCount : countPerPage * validCurrentPage,
    totalCount,
  );

  let description = `${min} - ${max} of ${totalCount}`;
  if (min === max) {
    description = `${min} of ${totalCount}`;
  }
  if (totalCount === 0) {
    description = '0 of 0';
  }

  return <Box style={{ whiteSpace: 'nowrap' }}>{description}</Box>;
}
