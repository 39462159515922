import { Classes, Divider } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import { Media } from '@/components/layout/Media';
import {
  CARD_IMG_HEIGHT,
  MAX_CONTENT_WIDTH,
} from '@/components/pieces/article';
import { PUBLIC_CONTENT_MAX_WIDTH } from '@/css/constants';

export default function ArticleSkeleton() {
  return (
    <div className='content-page'>
      <div
        style={{
          padding: '60px 40px',
          maxWidth: PUBLIC_CONTENT_MAX_WIDTH,
          margin: '0px auto',
        }}
      >
        <Media greaterThan='xs'>
          <Box width={1}>
            <HeaderSkeleton />
            <Flex width={1}>
              <Box width={1} maxWidth={MAX_CONTENT_WIDTH}>
                <HeroImageSkeleton />
                {[1, 2, 3].map((x) => (
                  <Box
                    key={x}
                    width={1}
                    mt={24}
                    height={150}
                    className={Classes.SKELETON}
                  />
                ))}
              </Box>
              <Box flexShrink='0' flexGrow='1' flexBasis={170} pl={20}>
                <Box width={1} height={450} className={Classes.SKELETON} />
              </Box>
            </Flex>
          </Box>
        </Media>
        <Media at='xs'>
          <Box width={1}>
            <HeaderSkeleton />
            <HeroImageSkeleton />
            <Box width={1} mt={14} height={24} className={Classes.SKELETON} />
            <Box width={1} mt={14} height={300} className={Classes.SKELETON} />
            <Box width={1} mt={14} height={300} className={Classes.SKELETON} />
            <Box width={1} mt={14} height={300} className={Classes.SKELETON} />
          </Box>
        </Media>
      </div>
    </div>
  );
}

function HeaderSkeleton() {
  return (
    <>
      <Box width={['100%', '70%']} height={70} className={Classes.SKELETON} />
      <Box
        my={[14, 24]}
        className={Classes.SKELETON}
        width={[150, 200]}
        height={24}
      />
      <Box width={1} height={[20, 24]}>
        <Divider />
      </Box>
    </>
  );
}

function HeroImageSkeleton() {
  return (
    <Box
      className={Classes.SKELETON}
      height={[150, CARD_IMG_HEIGHT]}
      mb={[0, 24]}
      width={1}
      maxWidth={[null, MAX_CONTENT_WIDTH]}
      style={{
        borderRadius: '6px',
      }}
    />
  );
}
