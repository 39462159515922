import { useContext, useEffect, useState } from 'react';

import { Classes } from '@blueprintjs/core';
import { motion } from 'framer-motion';

import SEOHelmet from '@/components/helpers/seo/SEOHelmet';
import Card from '@/components/layout/Card';
import { Box, Flex } from '@/components/layout/flexbox';
import Nav from '@/components/nav/Nav';
import PageContainer from '@/components/pages_flexible/PageContainer';
import World from '@/components/pieces/icons/svg/world';
import { ResponseContext } from '@/components/ResponseContext';
import { SIDE_NAV_WIDTH, STANDARD_NAV_HEIGHT } from '@/css/constants';

function NotFoundContent() {
  const [loaded, setLoaded] = useState(false);
  const { res } = useContext(ResponseContext);

  useEffect(() => {
    if (res?.status) {
      res.status(404);
    }
    setLoaded(true);
  }, []);

  return (
    <Flex
      justifyContent='space-around'
      style={{
        minHeight: '100vh',
        paddingTop: 100,
      }}
      flexWrap='wrap'
    >
      <Box mt={4} pt={3} mb={3} pl={2} pr={2} style={{ maxWidth: '100%' }}>
        <Card
          p={0}
          style={{
            width: 400,
            maxWidth: '100%',
          }}
          elevation={3}
        >
          <Box p={2} style={{ backgroundColor: '#EDF6FF' }} className='rtr rtl'>
            <Flex py={2} justifyContent='center'>
              {loaded ? (
                <motion.div
                  animate={{
                    rotate: [-2, 2, -2],
                    x: [-3, 3, -3],
                  }}
                  transition={{
                    duration: 2.5,
                    repeat: Infinity,
                    repeatType: 'reverse',
                    ease: 'easeInOut',
                  }}
                >
                  <World />
                </motion.div>
              ) : (
                <div
                  style={{ width: 220, height: 220, borderRadius: '100%' }}
                  className={Classes.SKELETON}
                />
              )}
            </Flex>
          </Box>
          <Box p={3}>
            <Box mb={1} className={Classes.RUNNING_TEXT}>
              <Box mb={1}>
                <b>Where in the world has the page gone?</b>
              </Box>
              <Box>
                We searched high and low, but can't find the page you're looking
                for. Perhaps check the URL for typos or other mistakes.
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </Flex>
  );
}

export default function NotFoundPage() {
  return (
    <>
      <SEOHelmet
        title='Page Not Found'
        description='Unlock your potential. Access top quality career, leadeship, and life coaching.'
      />
      <PageContainer sizeNavBreakpoint='md' maxWidth='100%' layout='marketing'>
        <NotFoundContent />
      </PageContainer>
    </>
  );
}

export function NotFoundInAppPage() {
  return (
    <>
      <Nav />
      <Box ml={[0, 0, 0, SIDE_NAV_WIDTH]} mt={-STANDARD_NAV_HEIGHT}>
        <NotFoundContent />
      </Box>
    </>
  );
}

export function NotFound() {
  return <NotFoundContent />;
}
