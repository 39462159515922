import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Classes, Icon, MenuItem } from '@blueprintjs/core';
import styled from '@emotion/styled';
import idx from 'idx';

import { Box, Flex } from '@/components/layout/flexbox';
import CreateWorkspaceCard from '@/components/pieces/create-workspace';
import StyledDialog from '@/components/pieces/StyledDialog';
import { SUBTEXT_COLOR } from '@/css/constants';
import { AssignInitialWorkspaceAdminDocument } from '@/graphql';

export function CreateWorkspaceOption({ setDialogVisible }) {
  if (!IS_EXEC) return null;

  const plusIcon = <Icon icon={'plus'} size={18} color={SUBTEXT_COLOR} />;
  return (
    <MenuItem
      key={'create-workspace-button'}
      onClick={() => setDialogVisible(true)}
      text={
        <Flex
          justifyContent='space-between'
          alignItems='center'
          fontSize={16}
          minWidth={200}
        >
          {plusIcon}
          <Box
            width={1}
            css={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            Create New Workspace
          </Box>
        </Flex>
      }
    />
  );
}

export function CreateWorkspaceDialog({ visible = false, setVisible }) {
  const [errorText, setErrorText] = useState(null);
  const [mutate, { loading }] = useMutation(
    AssignInitialWorkspaceAdminDocument,
    {
      onCompleted: function (data) {
        setVisible(false);
        const urlSlug = idx(data, (_) => _.assignInitialWorkspaceAdmin.urlSlug);
        if (urlSlug) {
          window.location.href = `/to-workspace/${urlSlug}`;
        }
      },
      onError: () => {
        setErrorText(
          'We ran into a technical issue while finalizing your workspace setup. Please contact support.',
        );
      },
    },
  );

  const assignInitialWorkspaceAdminApiCall = (workspaceSignedToken) => {
    mutate({
      variables: {
        workspaceSignedToken: workspaceSignedToken,
      },
    });
  };

  const onSlugChange = () => {
    setErrorText(null);
  };

  return (
    <ExtraStyledDialog
      isOpen={visible}
      onClose={() => setVisible(false)}
      lazy={true}
      title='Create Workspace'
      width='480px'
    >
      <CreateWorkspaceCard
        errorText={errorText}
        loading={loading}
        redirectToSignup={false}
        onSlugChange={onSlugChange}
        onCreateWorkspaceComplete={assignInitialWorkspaceAdminApiCall}
      />
    </ExtraStyledDialog>
  );
}

const ExtraStyledDialog = styled(StyledDialog)`
  &.${Classes.DIALOG} {
    max-width: 90%;
    padding-bottom: 0;
  }
`;
