import { useEffect, useState } from 'react';

import { Icon, Portal } from '@blueprintjs/core';

import { Box } from '@/components/layout/flexbox';
import { DefaultLinkBtn } from '@/components/pieces/bootstrap';
import Clickable from '@/components/pieces/interaction/Clickable';

import NavLinks from './NavLinks';

export default function MobileNav({ hideLogin = false }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Clickable onClick={() => setOpen(!open)}>
        <Box p={1}>
          <Icon icon={open ? 'cross' : 'menu'} size={24} />
        </Box>
      </Clickable>
      {open ? (
        <Portal>
          <div
            className='bts'
            style={{
              position: 'fixed',
              backgroundColor: 'white',
              top: 52,
              left: 0,
              width: '100%',
              height: 'calc(100vh - 52px)',
            }}
          >
            <Box p={3}>
              {hideLogin ? null : (
                <Box mb={3}>
                  <DefaultLinkBtn style={{ width: '100%' }} to='/login'>
                    Login
                  </DefaultLinkBtn>
                </Box>
              )}
              <NavLinks />
            </Box>
          </div>
        </Portal>
      ) : null}
    </>
  );
}
