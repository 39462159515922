import { Icon } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import { Box, Flex } from '@/components/layout/flexbox';

function BackLink({
  to = undefined,
  label = undefined,
  py = 24,
  px = 3,
  color = 'inherit',
}) {
  const ContainerComponent = to ? Link : Box;

  return (
    <ContainerComponent to={to} style={{ color }} className='text-center'>
      <Flex alignItems='center'>
        <Flex
          px={px}
          py={py}
          style={{ lineHeight: '10px' }}
          justifyContent='center'
        >
          <Icon icon='arrow-left' size={20} color={color} />
        </Flex>
        {label ? (
          <Box>
            <h4>{label}</h4>
          </Box>
        ) : null}
      </Flex>
    </ContainerComponent>
  );
}

export default BackLink;
