import { Colors } from '@blueprintjs/core';

import SEOHelmet from '@/components/helpers/seo/SEOHelmet';
import { Box, Flex } from '@/components/layout/flexbox';
import { Media } from '@/components/layout/Media';
import Nav from '@/components/nav/Nav';
import ScenariosList from '@/components/pages_logged_in/roleplays/scenarios/ScenariosList';
import { Container } from '@/components/pieces/bootstrap';
import PracticeSvg from '@/components/pieces/icons/svg/practice';
import {
  BRAND_PURPLE,
  LIGHTEST_BACKGROUND,
  SIDE_NAV_WIDTH,
} from '@/css/constants';
import { QueryScenariosStatus } from '@/graphql';

import { ScenariosPageType } from './const';

export const ScenariosPageMetadata = {
  [ScenariosPageType.Assigned]: {
    title: 'Assigned Scenarios',
    defaultFilter: QueryScenariosStatus.Incomplete,
  },
  [ScenariosPageType.Personal]: {
    title: 'Personal Scenarios',
    defaultFilter: QueryScenariosStatus.All,
  },
};

function Header() {
  return (
    <Media greaterThan='xs'>
      <Box width={1} className='bbs' py={4} bg={Colors.WHITE}>
        <Container maxWidth={1000}>
          <Flex alignItems='flex-end'>
            <Box css={{ flex: 1 }}>
              <Flex alignItems='center'>
                <PracticeSvg size={24} color={BRAND_PURPLE} />
                <Box ml={3} fontSize={28} fontWeight={600}>
                  Roleplays
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Media>
  );
}

export function ScenariosPageContent({
  type = ScenariosPageType.Assigned,
  pageLoading = false,
}) {
  return (
    <Box>
      <Header />
      <Box pb={6} bg={LIGHTEST_BACKGROUND}>
        <Container maxWidth={1000} mt={36} mb={5}>
          <ScenariosList
            type={type}
            title={ScenariosPageMetadata?.[type]?.title}
            pageLoading={pageLoading}
            statusFilter={ScenariosPageMetadata?.[type]?.defaultFilter}
            showRoleplay={false}
          />
        </Container>
      </Box>
    </Box>
  );
}

export default function ScenariosPageContainer({
  type = ScenariosPageType.Assigned,
}: {
  type?: ScenariosPageType;
  pageLoading?: boolean;
}) {
  return (
    <>
      <SEOHelmet title={`${ScenariosPageMetadata?.[type]?.title} - Exec`} />
      <Nav sizeNavBreakpoint='md' />
      <Box ml={[0, 0, SIDE_NAV_WIDTH]}>
        <ScenariosPageContent type={type} />
      </Box>
    </>
  );
}
