import { useCallback } from 'react';

import { Colors, Icon } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';

import { useUnreadMessageCount } from '@/components/helpers/custom-hooks/use-unread-message-count';
import {
  CTA_COLOR,
  INNER_BORDER_COLOR,
  LIGHT_BRAND_PURPLE,
} from '@/css/constants';

export default function MessageNav() {
  const isMessagesPage = useLocation().pathname.startsWith('/messages');
  const onError = useCallback((error: Error) => {
    console.error('Error fetching unread message count', error);
  }, []);
  const { unreadCount } = useUnreadMessageCount({ onError });

  const showPlus = unreadCount >= 10;
  return (
    <Link to='/messages'>
      <Container isMessagesPage={isMessagesPage}>
        <Icon icon='chat' size={20} />
        {unreadCount > 0 && <Badge>{showPlus ? '9+' : unreadCount}</Badge>}
      </Container>
    </Link>
  );
}

const Container = styled.div<{ isMessagesPage: boolean }>`
  &:hover {
    box-shadow:
      0 1px 1px rgba(24, 32, 38, 0.1),
      0 2px 6px rgba(24, 32, 38, 0.05);
    color: ${LIGHT_BRAND_PURPLE};
    background-color: #f8fafe;
  }
  color: ${({ isMessagesPage }) =>
    isMessagesPage ? LIGHT_BRAND_PURPLE : Colors.GRAY5};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid ${INNER_BORDER_COLOR};
`;

const Badge = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${CTA_COLOR};
  color: ${Colors.WHITE};
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
