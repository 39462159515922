import { useCallback, useMemo, useState } from 'react';

import { Button, Callout, Classes, Colors } from '@blueprintjs/core';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { Box, Flex } from '@/components/layout/flexbox';
import { isXsQuery } from '@/components/layout/Media';
import { CollectionTitle } from '@/components/pages_logged_in/roleplays/CollectionTitle';
import Filter from '@/components/pages_logged_in/workspace/pieces/Filter';
import FilterButton from '@/components/pages_logged_in/workspace/pieces/FilterButton';
import Paging from '@/components/pages_logged_in/workspace-admin/Paging';
import SearchBox from '@/components/pages_logged_in/workspace-admin/SearchBox';
import Checkbox from '@/components/pieces/form/Checkbox';
import IconCircle from '@/components/pieces/IconCircle';
import SettingSvg from '@/components/pieces/icons/svg/setting';
import StyledDialog from '@/components/pieces/StyledDialog';
import { BLUE_TEXT_COLOR, CARD_CLASSES } from '@/css/constants';
import {
  usePaginatedRoleplaysQuery,
  useGetEvaluationCriteriaQuery,
  VisibilityScope,
  useCopyEvaluationCriteriaToScenarioMutation,
  GetEvaluationCriteriaDocument,
  useCopyEvaluationCriteriaToCollectionMutation,
  TargetType,
} from '@/graphql';

const pageSize = 5;

// Preview mode components
function PreviewCriterionItem({
  criterion,
  isSelected,
  onToggleSelection,
}: {
  criterion: any;
  isSelected: boolean;
  onToggleSelection: (id: string) => void;
}) {
  return (
    <Flex
      flexDirection='column'
      style={{ gap: 16, borderColor: isSelected ? BLUE_TEXT_COLOR : undefined }}
      className={CARD_CLASSES}
      p={24}
      mr={12}
    >
      <Checkbox
        checked={isSelected}
        onChange={() => onToggleSelection(criterion.id)}
        label={criterion.name}
        hasBorders={false}
        style={{ fontSize: 18, fontWeight: 600 }}
      />
      <Flex style={{ gap: 16 }} pl={30} flexDirection='column'>
        {criterion?.items?.map((item) => (
          <Flex key={item.id}>
            <IconCircle
              icon='tick'
              backgroundColor='#D3F2E5'
              color='#0D8050'
              size={24}
              iconSize={14}
            />
            <Box ml={12} css={{ flex: 1 }}>
              {item.name}
            </Box>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

function PreviewCriteriaList({
  collection,
  selectedCriteriaIds,
  onChangeSelectedCriteriaIds,
  onAddToTarget,
  target,
}: {
  collection: any;
  selectedCriteriaIds: string[];
  onChangeSelectedCriteriaIds: (ids: string[]) => void;
  target: { type: TargetType; id: string };
  onAddToTarget: () => void;
}) {
  const { data, loading } = useGetEvaluationCriteriaQuery({
    variables: { roleplayId: collection.id },
    onCompleted: (data) => {
      onChangeSelectedCriteriaIds(
        data.evaluationCriteria ? data.evaluationCriteria.map((c) => c.id) : [],
      );
    },
  });

  const handleToggleCriterion = useCallback(
    (id: string) => {
      onChangeSelectedCriteriaIds(
        selectedCriteriaIds.includes(id)
          ? selectedCriteriaIds.filter((critId) => critId !== id)
          : [...selectedCriteriaIds, id],
      );
    },
    [selectedCriteriaIds, onChangeSelectedCriteriaIds],
  );

  if (loading) {
    return (
      <Flex flexDirection='column' style={{ gap: 16 }} pr={12} overflowY='auto'>
        <Flex
          flex='0 0 140px'
          className={classNames(Classes.SKELETON, 'round-corners')}
        />
        <Flex
          flex='0 0 140px'
          className={classNames(Classes.SKELETON, 'round-corners')}
        />
      </Flex>
    );
  }

  if (!data?.evaluationCriteria || data.evaluationCriteria.length === 0) {
    return <Box p={24}>No evaluation criteria found in this collection.</Box>;
  }

  return (
    <Flex
      flexDirection='column'
      justifyContent='space-between'
      p={24}
      pr={12} // Allows for the scrollbar to not overlap with the cards
      style={{ gap: 16 }}
      minHeight={0}
      height='100%'
    >
      <Flex flexDirection='column' style={{ gap: 16 }} overflowY='auto'>
        {data.evaluationCriteria.map((criterion) => (
          <PreviewCriterionItem
            key={criterion.id}
            criterion={criterion}
            isSelected={selectedCriteriaIds.includes(criterion.id)}
            onToggleSelection={handleToggleCriterion}
          />
        ))}
      </Flex>

      <Flex justifyContent='flex-start'>
        <Button
          large={true}
          intent='primary'
          text={
            target.type === TargetType.Scenario
              ? 'Add to Scenario'
              : 'Add to Collection'
          }
          onClick={onAddToTarget}
          disabled={selectedCriteriaIds.length === 0}
          style={{ marginRight: 16, width: '300px' }}
        />
      </Flex>
    </Flex>
  );
}

const scopes = [
  { id: null, name: 'All' },
  { id: VisibilityScope.User, name: 'Personal' },
  { id: VisibilityScope.Workspace, name: 'Workspace' },
  { id: VisibilityScope.Global, name: 'Core' },
];

function CollectionCard({
  collection,
  onPreview,
}: {
  collection: any;
  onPreview: (collection: any) => void;
}) {
  const isXs = useMediaQuery(isXsQuery);
  return (
    <Flex
      justifyContent='space-between'
      alignItems='center'
      p={24}
      mr={12} // Allows for the scrollbar to not overlap with the cards
      className='bas round-corners'
      style={{ gap: 32 }}
      flexDirection={isXs ? 'column' : 'row'}
    >
      <Flex flexDirection='column' style={{ gap: 8 }}>
        <Box fontSize={18} fontWeight={600}>
          <CollectionTitle collection={collection} />
        </Box>
        <Box fontSize={16} color={Colors.GRAY3}>
          {collection.description}
        </Box>
      </Flex>
      <Flex minWidth={130} justifyContent='stretch'>
        <Button
          text='Preview'
          style={{
            backgroundColor: Colors.LIGHT_GRAY5,
            color: Colors.DARK_GRAY1,
            width: '100%',
          }}
          onClick={() => onPreview(collection)}
        />
      </Flex>
    </Flex>
  );
}

function CollectionsList({
  currentPage,
  query,
  visibilityScope,
  onDataLoaded,
  onPreview,
  excludedIds,
}: {
  currentPage: number;
  query: string;
  visibilityScope: VisibilityScope | null;
  onDataLoaded: (data: { totalRecords: number }) => void;
  onPreview: (collection: any) => void;
  excludedIds: string[];
}) {
  const { data, loading } = usePaginatedRoleplaysQuery({
    variables: {
      currentPage,
      pageSize,
      query,
      scope: visibilityScope || undefined,
      excludedIds,
    },
    onCompleted: (data) => {
      onDataLoaded({ totalRecords: data.paginatedRoleplays.totalRecords });
    },
  });
  if (loading) {
    return (
      <Flex flexDirection='column' style={{ gap: 16 }} pr={12} overflowY='auto'>
        <Flex
          flex='0 0 140px'
          className={classNames(Classes.SKELETON, 'round-corners')}
        />
        <Flex
          flex='0 0 140px'
          className={classNames(Classes.SKELETON, 'round-corners')}
        />
        <Flex
          flex='0 0 140px'
          className={classNames(Classes.SKELETON, 'round-corners')}
        />
        <Flex
          flex='0 0 140px'
          className={classNames(Classes.SKELETON, 'round-corners')}
        />
      </Flex>
    );
  }
  if (!data?.paginatedRoleplays) {
    return <Box>No collections found</Box>;
  }
  return (
    <Flex flexDirection='column' style={{ gap: 16 }} overflowY='auto'>
      {data.paginatedRoleplays.data.map((collection) => (
        <CollectionCard
          key={collection.id}
          collection={collection}
          onPreview={onPreview}
        />
      ))}
    </Flex>
  );
}

function CollectionsController({
  onSelectCollection,
  excludedIds,
}: {
  onSelectCollection: (collection: any) => void;
  excludedIds: string[];
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [visibilityScope, setVisibilityScope] =
    useState<VisibilityScope | null>(null);

  const refine = useCallback(
    (query: string) => {
      setQuery(query);
      setCurrentPage(1);
    },
    [setQuery, setCurrentPage],
  );

  const handleDataLoaded = useCallback(
    ({ totalRecords }: { totalRecords: number }) => {
      setTotalRecords(totalRecords);
    },
    [setTotalRecords],
  );

  const handleScopeChange = useCallback(
    (scope: VisibilityScope | null) => {
      setVisibilityScope(scope);
      setCurrentPage(1);
    },
    [setVisibilityScope, setCurrentPage],
  );

  const handlePreview = useCallback(
    (collection: any) => {
      onSelectCollection(collection);
    },
    [onSelectCollection],
  );

  const scopeMenuLabel =
    scopes.find((scope) => scope.id === visibilityScope)?.name || 'All';
  const borderStyle = { border: '0.5px solid #E2E2E2', borderRadius: 6 };
  const style = { padding: '10px 16px' };

  return (
    <Flex
      flexDirection='column'
      p={24}
      pr={12} // Allows for the scrollbar to not overlap with the cards
      alignItems='flex-start'
      style={{ gap: 16 }}
      minHeight={0}
      height='100%'
    >
      <Flex width='100%' style={{ gap: 8 }}>
        <Filter
          button={
            <FilterButton
              style={{ ...style, ...borderStyle }}
              rightIcon={<SettingSvg />}
              text={scopeMenuLabel}
            />
          }
          items={scopes.map(({ id, name }) => ({
            id,
            text: name,
          }))}
          isSelectedFn={(x) => x === visibilityScope}
          multiple={false}
          onSelect={(id) => handleScopeChange(id as VisibilityScope)}
          menuPosition='bottom-left'
          viewMode='radio'
          fullHeight={true}
        />
        <SearchBox
          style={{
            borderRadius: 6,
            border: '0.5px solid #E2E2E2',
            padding: '22px 0px 22px 36px',
            fontSize: 14,
            flexGrow: 1,
          }}
          className='icon-center'
          refine={refine}
          throttledDuration={400}
          searchPlaceholder='Search Collections...'
        />
      </Flex>
      <Flex
        flexDirection='column'
        style={{ gap: 16 }}
        justifyContent='space-between'
        flex={1}
        minHeight={0}
        width='100%'
      >
        <CollectionsList
          currentPage={currentPage}
          query={query}
          visibilityScope={visibilityScope}
          onDataLoaded={handleDataLoaded}
          onPreview={handlePreview}
          excludedIds={excludedIds}
        />
        <Paging
          currentPage={currentPage}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          totalRecords={totalRecords}
        />
      </Flex>
    </Flex>
  );
}

export function ImportFromCollectionDialog({
  open,
  onClose,
  target,
}: {
  open: boolean;
  onClose: () => void;
  target: { type: TargetType; id: string };
}) {
  // State for preview mode
  const [selectedCollection, setSelectedCollection] = useState<any>(null);
  const [selectedCriteriaIds, setSelectedCriteriaIds] = useState<string[]>([]);

  const handleClose = useCallback(() => {
    setSelectedCollection(null);
    setSelectedCriteriaIds([]);
    onClose();
  }, [onClose, setSelectedCollection, setSelectedCriteriaIds]);
  const [error, setError] = useState<string | null>(null);

  const [copyEvaluationCriteriaToScenario] =
    useCopyEvaluationCriteriaToScenarioMutation();

  const [copyEvaluationCriteriaToCollection] =
    useCopyEvaluationCriteriaToCollectionMutation();

  const excludedIds = useMemo(() => {
    if (target.type === TargetType.Collection) {
      return [target.id];
    }
    return [];
  }, [target]);

  const handleAddToTarget = useCallback(() => {
    if (target.type === TargetType.Scenario) {
      copyEvaluationCriteriaToScenario({
        variables: {
          evaluationCriterionIds: selectedCriteriaIds,
          scenarioId: target.id,
        },
        refetchQueries: [
          {
            query: GetEvaluationCriteriaDocument,
            variables: {
              scenarioId: target.id,
              roleplayId: null,
              roleplaySlug: null,
            },
          },
        ],
        onCompleted: (data) => {
          if (!data.copyEvaluationCriteriaToScenario.ok) {
            setError(
              data.copyEvaluationCriteriaToScenario.errors
                .map((e) => e.messages)
                .join(', '),
            );
          } else {
            handleClose();
          }
        },
        onError: (error) => {
          setError(error.message);
        },
      });
    } else {
      copyEvaluationCriteriaToCollection({
        variables: {
          evaluationCriterionIds: selectedCriteriaIds,
          collectionId: target.id,
        },
        refetchQueries: [
          {
            query: GetEvaluationCriteriaDocument,
            variables: {
              roleplayId: target.id,
              scenarioId: null,
              roleplaySlug: null,
            },
          },
        ],
        onCompleted: (data) => {
          if (!data.copyEvaluationCriteriaToCollection.ok) {
            setError(
              data.copyEvaluationCriteriaToCollection.errors
                .map((e) => e.messages)
                .join(', '),
            );
          } else {
            handleClose();
          }
        },
        onError: (error) => {
          setError(error.message);
        },
      });
    }
  }, [selectedCriteriaIds, target, onClose]);

  const handleBack = useCallback(() => {
    setError(null);
    setSelectedCollection(null);
    setSelectedCriteriaIds([]);
  }, [setSelectedCollection, setSelectedCriteriaIds, setError]);

  const handleSelectCollection = useCallback(
    (collection: any) => {
      setError(null);
      setSelectedCollection(collection);
      setSelectedCriteriaIds([]);
    },
    [setSelectedCollection, setSelectedCriteriaIds, setError],
  );

  const handleSetSelectedCriteriaIds = useCallback(
    (ids: string[]) => {
      setError(null);
      setSelectedCriteriaIds(ids);
    },
    [setSelectedCriteriaIds, setError],
  );

  const isPreviewMode = selectedCollection !== null;
  const dialogTitle = isPreviewMode ? (
    <Flex alignItems='center'>
      <Button
        icon='arrow-left'
        minimal={true}
        small={true}
        onClick={handleBack}
        style={{ marginRight: 8 }}
      />
      <Box fontSize={18}>Preview Criteria</Box>
    </Flex>
  ) : (
    <Box fontSize={18}>Import from Collection</Box>
  );

  return (
    <StyledDialog
      isOpen={open}
      onClose={handleClose}
      width='700px'
      title={dialogTitle}
      style={{ height: '85vh' }}
    >
      {error && (
        <Box m={3} mb={0}>
          <Callout intent='danger' icon={null}>
            {error}
          </Callout>
        </Box>
      )}

      {isPreviewMode ? (
        <PreviewCriteriaList
          collection={selectedCollection}
          selectedCriteriaIds={selectedCriteriaIds}
          onChangeSelectedCriteriaIds={handleSetSelectedCriteriaIds}
          onAddToTarget={handleAddToTarget}
          target={target}
        />
      ) : (
        <CollectionsController
          onSelectCollection={handleSelectCollection}
          excludedIds={excludedIds}
        />
      )}
    </StyledDialog>
  );
}
