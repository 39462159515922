import { Button, Classes } from '@blueprintjs/core';
import styled from '@emotion/styled';

import { BP_NS, LIGHT_BACKGROUND } from '@/css/constants';

export default styled(Button)`
  &.${Classes.BUTTON}:not([class*='${BP_NS}-intent-']) {
    box-shadow: none;
    background: ${LIGHT_BACKGROUND};
    color: #394b59;
    height: 44px;
    width: 38px;
  }
  &.${Classes.BUTTON}:not([class*='${BP_NS}-intent-']).${Classes.DISABLED},
    &.${Classes.BUTTON}:not([class*='${BP_NS}-intent-']):disabled {
    color: #e2e2e2;
    background: transparent;
  }
`;
