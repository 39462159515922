import React, { CSSProperties } from 'react';

import styled from '@emotion/styled';
import { Emoji, EmojiStyle } from 'emoji-picker-react';

type GetEmojiUrl = (unified: string, emojiStyle: EmojiStyle) => string;

export interface EmojiCircleProps extends React.HTMLAttributes<HTMLDivElement> {
  unified?: string;
  emojiStyle?: EmojiStyle;
  size?: number;
  backgroundColor?: string;
  lazyLoad?: boolean;
  getEmojiUrl?: GetEmojiUrl;
  emojiUrl?: string;
  as?: React.ElementType;
  to?: string; // for when it's used as a Link
  style?: CSSProperties;
}

interface EmojiCircleWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  size: number;
  bgcolor: string;
}

const EmojiCircleWrapper = styled.div<EmojiCircleWrapperProps>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  background-color: ${(props) => props.bgcolor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: inherit;
`;

export function EmojiCircle({
  size = 48,
  unified = '1f600',
  emojiStyle = EmojiStyle.APPLE,
  backgroundColor = '#fff',
  ...restProps
}: EmojiCircleProps): React.ReactElement {
  return (
    <EmojiCircleWrapper size={size} bgcolor={backgroundColor} {...restProps}>
      {unified && (
        <Emoji
          unified={unified.toLowerCase()}
          emojiStyle={emojiStyle}
          size={Math.floor(size / 2)}
        />
      )}
    </EmojiCircleWrapper>
  );
}

export default EmojiCircle;
