import { Classes } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';

export default function SideNavSkeleton() {
  return (
    <Box>
      <Flex justifyContent='center' p={12} className='bbs'>
        <Box style={{ flex: 1, height: 36 }} className={Classes.SKELETON}></Box>
      </Flex>

      <Box mt={3}>
        <Box height={34} mx={3} my={16} className={Classes.SKELETON} />
        <Box height={34} mx={3} my={16} className={Classes.SKELETON} />
        <Box height={34} mx={3} my={16} className={Classes.SKELETON} />
        <Box height={34} mx={3} my={16} className={Classes.SKELETON} />
        <Box height={34} mx={3} my={16} className={Classes.SKELETON} />
        <Box height={34} mx={3} my={16} className={Classes.SKELETON} />
        <Box height={34} mx={3} my={16} className={Classes.SKELETON} />
      </Box>
    </Box>
  );
}
