import { useCallback, useState } from 'react';

import { Colors, Callout, Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { Box, Flex } from '@/components/layout/flexbox';
import { isXsQuery } from '@/components/layout/Media';
import { useAddAndRemoveCollectionScenarioMutation } from '@/components/pages_logged_in/roleplays/api';
import { CollectionTitle } from '@/components/pages_logged_in/roleplays/CollectionTitle';
import Paging from '@/components/pages_logged_in/workspace-admin/Paging';
import SearchBox from '@/components/pages_logged_in/workspace-admin/SearchBox';
import { AddButton } from '@/components/pieces/button/AddButton';
import StyledDialog from '@/components/pieces/StyledDialog';
import {
  MyCollectionsDocument,
  ScenarioDocument,
  ScenarioType,
  usePaginatedRoleplaysQuery,
} from '@/graphql';

const pageSize = 5;

function CollectionCard({
  collection,
  scenario,
  onError,
}: {
  collection: any;
  scenario?: ScenarioType;
  onError: (error: string) => void;
}) {
  const inScenario = Boolean(
    collection.scenarios?.some((s) => s.id === scenario?.id),
  );
  const {
    addCollectionScenario,
    removeCollectionScenario,
    addLoading,
    removeLoading,
  } = useAddAndRemoveCollectionScenarioMutation({
    onCompleted: () => {
      onError(null);
    },
    onError,
    refetchQueries: [
      { query: MyCollectionsDocument },
      { query: ScenarioDocument, variables: { scenarioSlug: scenario?.slug } },
    ],
  });

  const handleAddToCollection = () => {
    addCollectionScenario({
      scenarioIds: [scenario.id],
      roleplaySlug: collection.slug,
    });
  };

  const handleRemoveFromCollection = () => {
    removeCollectionScenario({
      scenarioIds: [scenario.id],
      roleplaySlug: collection.slug,
    });
  };
  const isXs = useMediaQuery(isXsQuery);
  return (
    <Flex
      justifyContent='space-between'
      alignItems='center'
      p={24}
      mr={12} // Allows for the scrollbar to not overlap with the cards
      className='bas round-corners'
      style={{ gap: 32 }}
      flexDirection={isXs ? 'column' : 'row'}
    >
      <Flex flexDirection='column' style={{ gap: 8 }}>
        <Box fontSize={18} fontWeight={600}>
          <CollectionTitle collection={collection} />
        </Box>
        <Box fontSize={16} color={Colors.GRAY3}>
          {collection.description}
        </Box>
      </Flex>
      <Flex minWidth={130} justifyContent='stretch'>
        <AddButton
          isAdded={inScenario}
          isLoading={addLoading || removeLoading}
          onAdd={handleAddToCollection}
          onRemove={handleRemoveFromCollection}
        />
      </Flex>
    </Flex>
  );
}

function CollectionsList({
  currentPage,
  query,
  onError,
  scenario,
  onDataLoaded,
}: {
  currentPage: number;
  query: string;
  scenario?: ScenarioType;
  onError: (error: string) => void;
  onDataLoaded: (data: { totalRecords: number }) => void;
}) {
  const { data, loading } = usePaginatedRoleplaysQuery({
    variables: {
      currentPage,
      pageSize,
      isOwner: true,
      query,
    },
    onCompleted: (data) => {
      onDataLoaded({ totalRecords: data.paginatedRoleplays.totalRecords });
    },
  });
  if (loading) {
    return (
      <Flex flexDirection='column' style={{ gap: 16 }} pr={12} overflowY='auto'>
        <Flex
          flex='0 0 140px'
          className={classNames(Classes.SKELETON, 'round-corners')}
        />
        <Flex
          flex='0 0 140px'
          className={classNames(Classes.SKELETON, 'round-corners')}
        />
        <Flex
          flex='0 0 140px'
          className={classNames(Classes.SKELETON, 'round-corners')}
        />
        <Flex
          flex='0 0 140px'
          className={classNames(Classes.SKELETON, 'round-corners')}
        />
      </Flex>
    );
  }
  if (!data?.paginatedRoleplays) {
    return <Box>No collections found</Box>;
  }
  return (
    <Flex flexDirection='column' style={{ gap: 16 }} overflowY='auto'>
      {data.paginatedRoleplays.data.map((collection) => (
        <CollectionCard
          key={collection.id}
          collection={collection}
          scenario={scenario}
          onError={onError}
        />
      ))}
    </Flex>
  );
}

function CollectionsController({
  scenario,
  onError,
}: {
  scenario?: ScenarioType;
  onError: (error: string) => void;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const refine = useCallback(
    (query: string) => {
      setQuery(query);
      setCurrentPage(1);
    },
    [setQuery, setCurrentPage],
  );
  const handleDataLoaded = useCallback(
    ({ totalRecords }: { totalRecords: number }) => {
      setTotalRecords(totalRecords);
    },
    [setTotalRecords],
  );
  return (
    <Flex
      flexDirection='column'
      p={24}
      pr={12} // Allows for the scrollbar to not overlap with the cards
      alignItems='flex-start'
      style={{ gap: 16 }}
      height='80vh'
    >
      <SearchBox
        style={{
          borderRadius: 6,
          border: '0.5px solid #E2E2E2',
          padding: '22px 0px 22px 36px',
          fontSize: 14,
        }}
        className='icon-center'
        refine={refine}
        throttledDuration={400}
        searchPlaceholder='Search Collections...'
      />
      <Flex
        flexDirection='column'
        style={{ gap: 16 }}
        justifyContent='space-between'
        flex={1}
        minHeight={0}
        width='100%'
      >
        <CollectionsList
          currentPage={currentPage}
          query={query}
          scenario={scenario}
          onError={onError}
          onDataLoaded={handleDataLoaded}
        />
        <Paging
          currentPage={currentPage}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          totalRecords={totalRecords}
        />
      </Flex>
    </Flex>
  );
}

export function AddToCollectionDialog({
  open,
  onClose,
  scenario,
}: {
  open: boolean;
  onClose: () => void;
  scenario?: ScenarioType;
}) {
  const [error, setError] = useState<string | null>(null);
  const handleError = (error: string) => {
    setError(error);
  };
  return (
    <StyledDialog
      isOpen={open}
      onClose={onClose}
      width='700px'
      title={<Box fontSize={18}>Add to Collection</Box>}
    >
      {error && (
        <Box m={3}>
          <Callout intent='danger' icon={null}>
            {error}
          </Callout>
        </Box>
      )}
      <CollectionsController scenario={scenario} onError={handleError} />
    </StyledDialog>
  );
}
