import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Classes } from '@blueprintjs/core';

import { Box, Flex } from '@/components/layout/flexbox';
import { CTA_COLOR } from '@/css/constants';
import { FeatureIntroducedDocument, IntroduceFeatureDocument } from '@/graphql';

const featureType = 'ROLEPLAYS';

function RoleplayIntroComponent({ onDismiss }) {
  return (
    <Flex
      flexWrap='wrap'
      style={{ borderRadius: 6, borderLeft: `6px solid ${CTA_COLOR}`, gap: 30 }}
      bg='#F1F5FF'
      alignItems='center'
      p={24}
    >
      <Box flex={[null, 1]}>
        <Box lineHeight='30px' fontSize='20px' fontWeight={600}>
          Practice Conversations That Matter
        </Box>
        <Box mt={2} mb={24} className={Classes.RUNNING_TEXT}>
          Engage in realistic voice-based dialogues with our AI. Gain instant,
          in-depth analysis of your performance and receive specific tips to
          enhance your communication skills.
        </Box>
        <Button onClick={onDismiss}>Got It</Button>
      </Box>
      <Box width={[1, 1, 1, 1 / 2]}>
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            src='https://player.vimeo.com/video/1007869798?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;autoplay=1'
            allow='autoplay; fullscreen; picture-in-picture'
            allowFullScreen={true}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: 6,
              backgroundColor: '#F1F5FF',
              border: 0,
            }}
            title='Exec Roleplays'
          />
          <script src='https://player.vimeo.com/api/player.js' />
        </div>
      </Box>
    </Flex>
  );
}

export default function RoleplayIntro({ show = false }) {
  const [submitted, setSubmitted] = useState(false);
  const [introduceFeature] = useMutation(IntroduceFeatureDocument, {
    variables: { featureType },
    refetchQueries: [
      { query: FeatureIntroducedDocument, variables: { featureType } },
    ],
  });

  if (submitted || !show) {
    return null;
  }

  function submit() {
    setSubmitted(true);
    introduceFeature();
  }

  return <RoleplayIntroComponent onDismiss={submit} />;
}
