import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useHotkeys } from 'react-hotkeys-hook';

import { Box } from '@/components/layout/flexbox';
import { Media, SizeBreakpoint } from '@/components/layout/Media';
import MenuAssistant from '@/components/pieces/menu/MenuAssistant';
import MenuAvatar from '@/components/pieces/menu/MenuAvatar';
import MenuFeedback from '@/components/pieces/menu/MenuFeedback';
import { MENU_BACKGROUND } from '@/components/pieces/menu/MenuHeader';
import MenuNotifications from '@/components/pieces/menu/MenuNotifications';
import MenuPage from '@/components/pieces/menu/MenuPage';
import MenuPersonal from '@/components/pieces/menu/MenuPersonal';
import MenuResumes from '@/components/pieces/menu/MenuResumes';
import { getUser } from '@/components/session/JwtTokenManager';
import {
  CurrentWorkspaceDocument,
  LoggedInNavDataQueryQuery,
  WorkspaceNavQueryQuery,
} from '@/graphql';

import MenuMain from './MenuMain';
import MenuSettings from './MenuSettings';

let completionTimeout = null;

interface WorkspaceMenuProps {
  data: LoggedInNavDataQueryQuery | WorkspaceNavQueryQuery;
  onClose: () => void;
  isOpen: boolean;
  pageLoading: boolean;
  prerenderNavSection: string;
  sizeNavBreakpoint: SizeBreakpoint;
}

function WorkspaceMenu({
  data,
  onClose,
  isOpen,
  pageLoading,
  prerenderNavSection,
  sizeNavBreakpoint,
}: WorkspaceMenuProps) {
  const [menuPage, setMenuPage] = useState(prerenderNavSection);
  const [completion, setCompletion] = useState(null);

  const user = getUser();
  const workspaceRole = user ? user.workspaceRole : null;

  const { data: workspaceData } = useQuery(CurrentWorkspaceDocument, {
    skip: pageLoading,
  });

  useEffect(() => {
    clearTimeout(completionTimeout);
    if (completion) {
      completionTimeout = setTimeout(() => {
        setCompletion(null);
      }, 2500);
    }
  }, [completion]);

  useEffect(() => {
    return () => clearTimeout(completionTimeout);
  }, []);

  useEffect(() => {
    if (menuPage) {
      clearTimeout(completionTimeout);
    }
  }, [menuPage]);

  function handleBackLink() {
    setMenuPage(menuPage === 'settings' ? null : 'settings');
    setCompletion(null);
  }

  function handleClose() {
    onClose();
    setTimeout(() => setMenuPage(null), 1000);
  }

  useHotkeys(
    'escape',
    (e) => {
      if (isOpen && !e.defaultPrevented) {
        handleClose();
        e.stopPropagation();
      }
    },
    {},
    [isOpen],
  );

  const { currentUser } = data;
  const currentUserWorkspaceMember =
    'currentUserWorkspaceMember' in data
      ? data.currentUserWorkspaceMember
      : undefined;
  const currentWorkspace = workspaceData && workspaceData.currentWorkspace;

  if (!currentWorkspace) {
    return null;
  }

  const menuSettings = (
    <MenuSettings
      user={currentUser}
      completion={completion}
      onClose={handleClose}
      onMenuChange={setMenuPage}
      onBackLink={handleBackLink}
      workspaceRole={workspaceRole}
      sizeNavBreakpoint={sizeNavBreakpoint}
    />
  );

  return (
    <Box
      width={1}
      style={{
        backgroundColor: MENU_BACKGROUND,
        position: 'relative',
        overflow: 'hidden',
        minHeight: '100vh',
      }}
    >
      <MenuPage
        visible={!menuPage}
        withHeader={false}
        slideTo='left'
        backgroundColor={MENU_BACKGROUND}
      >
        <Media lessThan='lg'>
          <MenuMain
            data={data}
            onClose={handleClose}
            onMenuChange={setMenuPage}
            pageLoading={pageLoading}
          />
        </Media>
        <Media greaterThanOrEqual='lg'>{menuSettings}</Media>
      </MenuPage>

      <MenuPage
        visible={menuPage === 'settings'}
        withHeader={false}
        slideTo={menuPage ? 'left' : 'right'}
      >
        {menuSettings}
      </MenuPage>

      <MenuPage visible={menuPage === 'personal'}>
        <MenuPersonal
          user={currentUser}
          onClose={handleClose}
          onBackLink={handleBackLink}
          onComplete={() => {
            setCompletion('personal');
            setMenuPage(null);
          }}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'assistants'}>
        <MenuAssistant
          member={currentUserWorkspaceMember}
          onClose={() => {
            setCompletion('assistants');
            setMenuPage(null);
            handleClose();
          }}
          onBackLink={handleBackLink}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'notifications'}>
        <MenuNotifications
          user={currentUser}
          onClose={() => {
            setCompletion('notifications');
            setMenuPage(null);
            handleClose();
          }}
          onBackLink={handleBackLink}
          isOpen={isOpen && menuPage === 'notifications'}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'resumes'}>
        <MenuResumes
          onClose={handleClose}
          onBackLink={handleBackLink}
          isOpen={isOpen && menuPage === 'resumes'}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'feedback'}>
        <MenuFeedback
          onClose={handleClose}
          onBackLink={handleBackLink}
          onComplete={() => {
            setCompletion('feedback');
            setMenuPage(null);
          }}
        />
      </MenuPage>

      <MenuPage visible={menuPage === 'avatar'}>
        {completion === 'avatar' ? null : (
          <MenuAvatar
            onClose={handleClose}
            onBackLink={handleBackLink}
            onComplete={() => {
              setCompletion('avatar');
              setMenuPage(null);
            }}
          />
        )}
      </MenuPage>
    </Box>
  );
}

export default WorkspaceMenu;
