import { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import Uppy from '@uppy/core';
import Dropbox from '@uppy/dropbox';
import GoogleDrive from '@uppy/google-drive';
import OneDrive from '@uppy/onedrive';
import { Dashboard } from '@uppy/react';
import Url from '@uppy/url';
import XHRUpload from '@uppy/xhr-upload';

import { Box } from '@/components/layout/flexbox';
import { getJwtToken } from '@/components/session/JwtTokenManager';

import '@uppy/core/dist/style.min.css';
import '@uppy/url/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

function Uploader({
  allowMultipleUploads = false,
  uppyDashboardProps = {},
  meta = {},
  ...props
}) {
  const [uppy, setUppy] = useState(null);

  useEffect(() => {
    const companionUrl = UPPY_HOST;
    setUppy(
      new Uppy({
        allowMultipleUploads: allowMultipleUploads,
        restrictions: {
          maxNumberOfFiles: allowMultipleUploads ? 10 : 1,
          minNumberOfFiles: 1,
        },
        meta: meta,
      })
        .use(Dropbox, { companionUrl })
        .use(GoogleDrive, { companionUrl })
        .use(OneDrive, { companionUrl })
        .use(Url, { companionUrl })
        .use(XHRUpload, {
          endpoint: props.endpoint,
          fieldName: props.fieldName,
          headers: {
            Authorization: `JWT ${getJwtToken()}`,
          },
        }),
    );
    return () => (uppy ? uppy.close() : null);
  }, []);

  useEffect(() => {
    if (!uppy) return;
    uppy.on('complete', props.onComplete);
    uppy.on('upload-success', props.onUploadSuccess);
    uppy.on('file-added', props.onFileAdded);
    uppy.on('file-removed', props.onFileRemoved);
    uppy.on('error', props?.onError);

    if (props.uppyRef) {
      props.uppyRef(uppy);
    }
  }, [uppy]);

  return (
    <UppyContainer>
      {uppy ? (
        <Dashboard
          {...uppyDashboardProps}
          waitForThumbnailsBeforeUpload={true}
          height={400}
          plugins={['GoogleDrive', 'Dropbox', 'OneDrive', 'Url']}
          proudlyDisplayPoweredByUppy={false}
          uppy={uppy}
        />
      ) : null}
    </UppyContainer>
  );
}

const UppyContainer = styled(Box)`
  [data-uppy-drag-drop-supported='true'] .uppy-Dashboard-AddFiles {
    border: none;
  }
`;

export default Uploader;
