const webApp = typeof WEB_APP !== 'undefined' ? WEB_APP : process.env.WEB_APP;

export const PERSONAL_WOKSPACE = 'personal-mode';

export function isWorkspaceUrl(host: string): boolean {
  return !webApp.endsWith(host) || host.includes('.localhost');
}

export function getWorkspaceSlug(host: string): string | null {
  if (!webApp.endsWith(host)) {
    return host.split('.')[0];
  }

  return null;
}

export function getWorkspaceHost(host: string, slug: string): string {
  const isProd =
    typeof IS_PROD !== 'undefined'
      ? IS_PROD
      : process.env.NODE_ENV === 'production';

  // Support testing production on Localhost
  if ((isProd && host.includes('.localhost')) || !isProd) {
    return host.replace('localhost', `${slug}.localhost`);
  }

  return host.replace('www.', `${slug}.`);
}
