import { Colors, Icon } from '@blueprintjs/core';
import classnames from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import { Highlight } from 'react-instantsearch';
import { useNavigate } from 'react-router-dom';

import { Box, Flex } from '@/components/layout/flexbox';
import Clickable from '@/components/pieces/interaction/Clickable';
import Truncate from '@/components/pieces/Truncate';

export function EntityResult({
  selected,
  hit,
  onClose,
  onSelect,
  avatar,
  link,
  titleHighlightKey,
  descriptionHighlightKey,
}) {
  const navigate = useNavigate();
  const containerCls = classnames('resultItem', {
    selected,
  });

  function goToLink() {
    onSelect(hit);
    navigate(link);
    onClose();
  }
  useHotkeys(
    'enter',
    () => {
      if (selected) {
        goToLink();
      }
    },
    {},
    [selected, link],
  );

  return (
    <Clickable
      onClick={() => {
        goToLink();
      }}
    >
      <Flex alignItems='center' className={containerCls}>
        <Box pl={[2, 3]} py={12}>
          {avatar}
        </Box>
        <Box ml={[2, 3]} py={12} pr={2} flex={1} className='resultItemDetails'>
          <Truncate style={{ color: Colors.GRAY3 }}>
            <Highlight
              hit={hit}
              attribute={titleHighlightKey ? titleHighlightKey : 'name'}
            />
          </Truncate>
          <Box className='secondary' style={{ color: Colors.GRAY3 }}>
            <Truncate style={{ color: Colors.GRAY3 }}>
              <Highlight
                hit={hit}
                attribute={
                  descriptionHighlightKey
                    ? descriptionHighlightKey
                    : 'description'
                }
              />
            </Truncate>
          </Box>
        </Box>
        <Box pl={2} pr={3} className='arrow'>
          <Icon icon='arrow-right' />
        </Box>
      </Flex>
    </Clickable>
  );
}
