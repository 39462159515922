/**
 * Validates if the given string is a valid email address.
 * @param email - The string to validate as an email address
 * @returns boolean - True if the string is a valid email address, false otherwise
 */
export function validateEmail(email: string): boolean {
  // Basic email regex pattern
  const emailPattern = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  if (!email || typeof email !== 'string') {
    return false;
  }
  // Trim whitespace and convert to lowercase
  const normalizedEmail = email.trim().toLowerCase();
  return emailPattern.test(normalizedEmail);
}
